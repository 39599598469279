.columnMain {
}
.stepHolder {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.stepContent {
  flex: 1 1 auto;
  overflow: auto;
  padding: 15px;
}
.stepContent :global(.field + .field) {
  margin-top: 25px;
}
.stepActions {
  padding: 15px;
}

.stepActions :global(.ui.button) {
  margin: 0;
}

.stepActions :global(.ui.button):not(:last-child) {
  margin-right: 10px;
}

.verticalMiddle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.verticalMiddle:global(.center) {
  justify-content: center;
}

.verticalMiddle:global(.right) {
  justify-content: flex-end;
}

.stepCount {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-light);
}

.stepTitle:global(.ui.header) {
  padding: 0;
  margin: 15px 0 30px 0;
}

.headerOverlayHolder {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.headerOverlayHeader {
  border-bottom: 1px solid var(--color-border);
  box-shadow: 0 0 5px var(--color-shadow);
  background: #fff;
}

.headerOverlayBody {
  flex: 1 1;
}

.headerOverlayBody :global(.ui.left.sidebar) {
  width: 100%;
}

.headerOverlayBody :global(.pusher) {
  display: flex;
}

.mapHolder {
  flex: 1 1 auto;
  position: relative;
}

.mapButtons {
  position: absolute;
  z-index: 100;
  left: 30px;
  top: 30px;
}

.mapWarningMessage {
  padding: 5px;
}

.mapWarningMessage .warningButtons {
  float: right;
}

.mapWarningMessage .warningIcon {
  color: var(--color-warning);
}

:global(#farm-map) {
  height: 100%;
}

:global(.gm-style > div.gmnoprint:nth-last-child(2):not(.gm-bundled-control)),
:global(.gm-style > div.gmnoprint:last-child:not(.gm-bundled-control)) {
  margin-top: 10px !important;
}

:global(.gm-style > div.gmnoprint:nth-last-child(2):not(.gm-bundled-control) > div),
:global(.gm-style > div.gmnoprint:last-child:not(.gm-bundled-control) > div) {
  height: 40px;
  min-width: 40px;
  cursor: pointer;
}

:global(.gm-style > div.gmnoprint:nth-last-child(2):not(.gm-bundled-control) > div > div),
:global(.gm-style > div.gmnoprint:last-child:not(.gm-bundled-control) > div > div) {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  :global(.ui.left.sidebar),
  :global(.ui.left.visible.sidebar) {
    box-shadow: none;
  }
  .headerOverlayBody :global(.ui.left.sidebar) {
    width: 450px;
  }

  .headerOverlayBody :global(.pusher) {
    margin-left: 450px;
  }
}
