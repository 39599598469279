:global(.ui.button).backIconWrapper {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

:global(.ui.button).printButton {
  height: 28px;
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  min-width: 100px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).printButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).printButton:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).printButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}
