.resizer {
  display: inline-block;
  width: 36px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  touch-action: none;
  cursor: col-resize !important;
}

.groupedCell {
  display: flex;
}

.groupNumberOfItems {
  padding-left: 7px;
}

:not(:last-child).resizer {
  transform: translateX(50%);
}

:last-child.resizer {
  width: 18px;
}

.messageHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
