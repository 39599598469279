@import "../../assets/css/variables.css";

.logo-and-title > div {
  padding-left: 15px;
}

.symbol-tuf {
  background: url("../../assets/img/symbol.svg") no-repeat left;
  background-size: contain;
  height: 35px;
  width: 35px;
}

.vertical-middle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-weight: bold;
}

.logo-column .title {
  font-weight: 600;
  margin-left: 15px;
  font-size: 16px;
  font-family: "Montserrat", "Lato", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
}

.vertical-middle.center {
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.vertical-middle.right {
  justify-content: flex-end;
}

.ui.grid > .row > .column.column-close {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.column-close .icon {
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

.logo-holder-small {
  margin: -12px 0;
  height: 50px;
}

.logo-holder-small img {
  width: 50px;
}

.close-icon {
  font-size: 24px;
  cursor: pointer;
  color: var(--color-header);
}

.with-footer .column-close {
  order: 1;
}

.with-footer .logo-column {
  order: 2;
}

.ui.grid.with-footer .column.column-buttons {
  order: 3;
  margin: -5px 0;
}

.ui.grid.with-footer .column.column-buttons .ui.button.large {
  font-size: 1rem;
}

.ui.grid.with-footer [class*="right floated"].column.column-close {
  margin-left: 0;
  border-left: none;
  border-right: 1px solid var(--color-border);
  padding: 0 15px;
}

.ui.grid.with-footer > .row {
  flex-wrap: nowrap;
}

.ui.grid.with-footer > .row > .column {
  width: auto !important;
  flex: 0 0 auto;
}

.ui.grid.with-footer > .row > .column.logo-column {
  flex: 1 1 auto;
}

@media screen and (min-width: 768px) {
  .symbol-tuf {
    height: 48px;
    width: 48px;
  }
}
