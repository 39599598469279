.multiSelectDropdown {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
}

.multiSelectDropdown:hover {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;
}
.multiSelectDropdown:global(.ui.dropdown .menu) {
  width: 100%;
  min-width: auto;
}

.multiSelectDropdown:global(.ui.dropdown .scrolling.menu) {
  height: 215px;
}

.multiSelectDropdown i {
  float: right;
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  left: 15px;
}

.dropdownItemSuffix {
  font-family: Roboto, sans-serif;
  font-size: 10px !important;
  font-style: normal;
  margin-left: 25px;
  font-weight: 400 !important;
  line-height: 12px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
}

:global(.dropdown) :global(.menu) > .clearButton {
  cursor: pointer;
  text-transform: none !important;
  color: var(--color-error-delete) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
}

.search {
  padding-right: 20px;
}
.search input {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  padding-right: 20px;
}
