:global(.ui.button).addButton {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.actionCells {
  overflow: initial !important;
}

:global(.ui.button).addButton:hover {
  background-color: #156941 !important;
}

:global(.ui.basic.green.button).cancelButton {
  color: var(--color-green) !important;
  margin-right: 10px;
}

:global(.ui.basic.green.button).actionButton {
  height: 32px;
  display: flex;
  margin-right: 8px;
  font-weight: 500 !important;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

:global(.ui.basic.green.button).actionButton:hover {
  color: #ffffff !important;
  background-color: #156941 !important;
}

:global(.ui.basic.green.button).actionButton:hover i > svg > path {
  fill: #ffffff !important;
}

:global(.ui.basic.red.button).actionButton {
  height: 32px;
  display: flex;
  align-items: baseline;
  font-weight: 500 !important;
  color: var(--color-error-delete) !important;
  box-shadow: 0 0 0 1px var(--color-error-delete) inset !important;
}

:global(.ui.basic.red.button).actionButton:hover {
  background-color: #8c0d24 !important;
  color: #ffffff !important;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scrollableTableWidth:global(.ui.table) {
  width: 120%;
}
