.root {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.root :global(.ui.segment) {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.contentWrapper {
  height: 100%;
  padding-right: 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebarHeader {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 16px !important;
}

.headerColumn {
  display: flex;
}

:global(.ui.header).name {
  font-size: 28px;
  font-weight: 700;
}

:global(.ui.header).roundsName {
  margin-top: 0px !important;
  font-size: 28px;
  font-weight: 700;
}

.closeButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

:global(.icon).closeButton:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.closeButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.overAllSummaryWrapper {
  padding-top: 32px;
}

.roundsInfoWrapper {
  padding-top: 32px;
}

.editModalSegment {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}

.subSideBarheader {
  display: flex !important;
  flex-direction: row !important;
  justify-content: start !important;
  align-items: center !important;
}

.backButton {
  background: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}

.backButton i {
  font-size: 20px !important;
  display: flex;
  align-items: center;
}

.totalDisabledField {
  opacity: 1 !important;
}

.disabledLabel {
  color: #b3b3b3 !important;
}

.barcodesDropdown :global(.default.text) {
  color: #212121 !important;
}

.addRoundBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.addBtn {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}

.buttonContainer {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}
