@import "../../assets/css/variables.css";

:global(.ui.button.button-text).buttonMore {
  margin-left: 5px;
  margin-top: 3px;
  margin-right: 0;
}

:global(.ui.button.button-text).buttonMore:hover {
  background: none;
}

:global(.ui.basic.segment).tasksListRow {
  color: var(--color-title);
  border-top: 1px solid var(--color-border);
  margin: 0;
}

:global(.ui.basic.segment).tasksListRow:nth-child(2n + 1) {
  background: var(--color-row-stripped);
}

:global(.ui.basic.segment).tasksListRow:last-child {
  border-bottom: 1px solid var(--color-border);
}

:global(.ui.grid).tasksListRowGrid {
}

:global(.ui.grid).tasksListRowGrid:last-child {
  border-bottom: 1px solid var(--color-border);
}

:global(.ui.button).readFull {
  color: var(--color-green);
  margin-top: 10px;
  margin-bottom: 15px;
}

:global(.ui.grid > .row > .column).columnActions {
  position: absolute;
  top: 15px;
  right: 15px;
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
}

:global(.ui.grid > .row > .column).columnActions :global(.icon) {
  font-size: 18px;
  color: var(--color-icon);
}

:global(.ui.grid > .row > .column).columnActions :global(.icon.print) {
  color: var(--color-green);
}

.printHolder {
  display: none;
}
.printHolder :global(.icon) {
  cursor: pointer;
}

.columnBlock,
.columnSize,
.columnProgress {
  padding-top: 15px;
  padding-bottom: 15px;
}

.hasDone div {
  padding-left: 15px;
}

.progressHolder {
  text-align: center;
}

.statusOPEN {
  color: var(--color-open);
}

.statusIN_PROGRESS {
  color: var(--color-in-progress);
}
.statusCOMPLETED {
  color: var(--color-completed);
}

:global(i.icon).iconEye {
  font-size: 18px;
  margin-top: 5px;
  display: block;
}

.employeeIcons {
  display: inline-block;
}

.plusMore {
  display: inline-block;
  font-weight: bold;
}

.employeeIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  background: var(--color-background-cell);
  border: 1px solid var(--color-border);
  border-radius: 50%;
}

.employeeIconOverlay {
  float: left;
  margin-left: -17px;
}

.chemicalName,
.blockName {
  overflow-wrap: break-word;
  position: relative;
}

.blockName :global(i.icon) {
  position: absolute;
  left: -5px;
  top: 0;
}

.newTask {
  background: var(--color-grass-light);
}

@media screen and (min-width: 768px) {
  .hasDone div {
    padding-left: 0;
  }

  .blockName :global(i.icon) {
    left: -20px;
  }

  .columnBlock,
  .columnSize,
  .columnProgress {
    padding-top: 0;
    padding-bottom: 0;
  }

  .columnProgress > div {
    margin: 0 auto;
  }
  .printHolder {
    display: inline-block;
  }

  :global(.ui.basic.segment).tasksListRow {
    border: none;
  }
  :global(.ui.basic.segment).tasksListRow:last-child {
    border-bottom: none;
  }
  :global(.ui.basic.segment).tasksListRow:hover {
    background: var(--color-row-hover);
  }

  :global(.ui.grid > .row > .column).columnActions {
    position: inherit;
    top: 0;
    right: 0;
  }

  .columnAvatar {
    text-align: center;
  }

  :global(.column).columnBlock {
    padding-left: 15px;
  }

  :global(i.icon).iconEye {
    display: inline-block;
    margin-left: 15px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  :global(.ui).popup {
    display: none !important;
  }

  :global(i.icon).iconEye {
    margin-left: 1rem;
    position: absolute;
    left: 50%;
    top: 0;
    margin-top: 0;
  }
}

:global(.ui.grid).tasksListRowGrid :global(.column) {
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 992px) and (max-width: 1919px) {
  :global(.ui.grid).tasksListRowGrid :global(.column:nth-child(1)) {
    width: 9% !important;
  }
  :global(.ui.grid).tasksListRowGrid :global(.column:nth-child(4)) {
    width: 9.75% !important;
  }
}

@media screen and (min-width: 1600px) {
  :global(i.icon).iconEye {
    vertical-align: middle;
    display: inline-block;
  }
  :global(.ui.grid > .row > .column).columnActions :global(.icon.print) {
    margin-right: 10px;
  }

  :global(.ui.button.button-text).buttonMore {
    margin-left: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  :global(.ui.grid).tasksListRowGrid :global(.column):not(.columnBlock) {
    padding-left: 5px;
  }

  :global(.ui.grid).tasksListRowGrid :global(.column) {
    padding-right: 5px;
    font-size: 12px;
  }

  :global(i.icon).iconEye {
    display: block;
    margin: 0 auto;
  }
}

.sprayTaskColumns {
}
/* 
.areasColumn {
  padding-left: 20px !important;
}
 */
