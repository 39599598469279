.root {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.contentWrapper {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.headerContainer {
  height: 7% !important;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.header {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.formContainer {
  height: 70%;
  background-color: white;
  border-radius: 8px;
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding: 16px;
}

.buttonContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #ffffff;
  height: 8% !important;
  margin-top: 24px;
  position: fixed;
  width: 100% !important;
  z-index: 2;
  bottom: 5px;
}

.buttonWrapper {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 16px;
  height: 5%;
}

.cancelButton {
  width: 84px !important;
  height: 36px;
  margin-right: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.saveButton {
  width: 71px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
