@import '../../../../assets/css/variables.css';

.stepHolder {
  composes: stepHolder from '../AddNewFarm.module.css';
  background: var(--color-background-cell);
}

.stepContent {
  composes: stepContent from '../AddNewFarm.module.css';
}

.stepActions {
  composes: stepActions from '../AddNewFarm.module.css';
}

.stepCount {
  composes: stepCount from '../AddNewFarm.module.css';
}

.stepTitle {
  composes: stepTitle from '../AddNewFarm.module.css';
}

.verticalMiddle {
  composes: verticalMiddle from '../AddNewFarm.module.css';
}

.blocksHolder {
  margin-top: 10px;
}

.deleteBlock {
  background-color: #dcdcdc !important;
}
.deleteActionButton {
  margin: 0 0.75em !important;
  min-width: 8em;
}

.archiveToggle {
  display: flex;
  align-items: center;
}

.archiveToggle > span {
  padding-right: 10px;
  padding-left: 20px;
}

.archiveToggle.wide > div:first-child {
  flex: auto;
}
