:global(.basicCell) {
  overflow-wrap: break-word;
}
:global(.basicCell):not(.expanded) {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.expandButton {
  color: #f3bd6c;
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
}
