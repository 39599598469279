.modalHeader {
  display: flex !important;
  align-items: center !important;
}

.modalHeaderIconWrapper {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0e9354;
}

.modalHeaderNameWrapper {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}

.modalActionsButton {
  display: flex !important;
  justify-content: flex-end !important;
}

.containerStyles {
  width: "500px" !important;
  height: "280px" !important;
  margin: "0 auto" !important;
}

.exportButtonWrapper {
  width: 96px !important;
  height: 36px !important;
  background-color: green !important;
  color: white !important;
  display: flex !important;
  justify-content: center;
  border-radius: 4px !important;
}

.exportButtonWrapper a {
  color: white !important;
}

.exportButton {
  padding: 5px !important;
  font-size: 14px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  color: white !important;
  font-size: 13px !important;
}

.exportButton i {
  font-size: 12px !important;
  color: white !important;
}

.exportButtonHeader {
  color: white !important;
  padding-left: 7px !important;
  padding-top: 5px !important;
}

.confirmModal {
  width: 400px !important;
}
