:root {
  /*
   * colors
   * ======================================================================== */
  --color-background: #f5f7f7;
  --color-background-secondary: #d1dce3;
  --color-background-green: #0e9354;
  --color-background-green-light: #a4dfc4;
  --color-background-green-medium: #2cb673;
  --color-background-label: #677c8c;
  --color-chapter: #24303a;
  --color-dark: #5b666f;
  --color-middle: #9a9ca3;
  --color-light: #999;
  --color-light-grey: #e8e8e8;
  --color-grass: #758a00;
  --color-grass-meduim: #84bf41;
  --color-grass-light: #e2ecee;
  --color-border: rgba(34, 36, 38, 0.15);
  --color-icon: #99adbc;
  --color-background-cell: #f6f7f8;
  --color-background-button: #f3f3f3;
  --color-black: #000;
  --color-shadow: rgba(34, 36, 38, 0.15);
  --color-active: #6a6a6a;
  --color-title: #0c0033;
  --color-header: #9a9ca3;
  --color-input: rgba(0, 0, 0, 0.87);
  --color-text: #24303a;
  --color-brown-grey: #525252;
  --color-pink: #ea5151;
  --color-error: #e0b4b4;
  --color-green: #0e9354;
  --color-violet: #4000ff;
  --color-border-blue: #9cd0dc;
  --color-vacant-blue: #3b9eb5;
  --color-background-blue: #f4f8f9;
  --color-open: #d5dd2d;
  --color-completed: #099254;
  --color-in-progress: #50c1cf;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  --box-shadow-small: 0 0 3px rgba(0, 0, 0, 0.3);
  --box-shadow-mini: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  --box-shadow-flowly: 0 0 15px rgba(209, 220, 227, 0.5);
  --color-bg-pink: #f5efef;
  --color-border-sui: #a4dfc4;
  --color-border-sui-light: #d4f0e2;
  --color-table-header: #5b666f;
  --color-row-hover: #dff3ea;
  --color-row-stripped: #f6f7f8;
  --color-warning: #ffcc00;
  --color-secondary-button-background: #e0e1e2;
  --color-secondary-button-background-hover: #cacbcd;
  --color-actional-button: #59b655;
  --color-error-delete: #b00020;
}
