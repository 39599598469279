@import '../../assets/css/variables.css';

:global(.ui.selection.dropdown).selectStatus {
  min-width: auto;
  white-space: nowrap;
}
:global(.ui.selection.dropdown).selectOPEN {
  background: var(--color-open);
  border-color: var(--color-open);
  color: #fff;
}

:global(.ui.selection.dropdown).selectIN_PROGRESS {
  background: var(--color-in-progress);
  border-color: var(--color-in-progress);
  color: #fff;
}

:global(.ui.selection.dropdown).selectCOMPLETED {
  background: var(--color-completed);
  border-color: var(--color-completed);
  color: #fff;
}

.colorOPEN {
  color: var(--color-open);
}

.colorIN_PROGRESS {
  color: var(--color-in-progress);
}

.colorCOMPLETED {
  color: var(--color-completed);
}

:global(.ui.selection.dropdown).selectStatus > :global(.text:not(.default)) {
  color: #fff;
}
