:global(.ui.table).sprayTable {
  border: none;
  color: var(--color-dark);
}

:global(.ui.table).sprayTable thead th {
  background: none !important;
  border: none;
  color: #aaaaaa;
  font-weight: normal;
  border-bottom: 2px solid var(--color-border-sui-light);
}

:global(.ui.table).areas.sprayTable tbody th {
  height: 48px;
}

:global(.ui.table).sprayTable tbody tr.lastRow {
  background: var(--color-background-cell);
}
:global(.ui.table).sprayTable tbody tr.lastRow td {
  font-weight: bold;
}

:global(.ui.table).sprayTable tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: var(--color-border-sui-light);
  border-width: 2px;
}

:global(.ui.table).sprayTable tbody td.status,
:global(.ui.table).sprayTable thead th.status {
  text-align: right;
  width: 1%;
  white-space: nowrap;
}

:global(.ui.table).sprayTable thead th {
  line-height: 1;
}

.variety {
  font-size: 11px;
  color: gray;
  font-style: italic;
}

.vacant {
  color: var(--color-vacant-blue);
}

:global(.ui.table).sprayTable thead th:last-child,
:global(.ui.table).sprayTable tbody td:last-child {
  display: none;
}

.tanksAdditionalInfo {
  font-weight: bold;
}

@media (min-width: 767px) {
  :global(.ui.table).sprayTable tbody td.status {
    width: auto;
  }

  :global(.ui.table).sprayTable thead th,
  :global(.ui.table).sprayTable tbody td {
    white-space: nowrap;
  }

  :global(.ui.table).sprayTable tbody td.withInput {
    width: 20%;
  }
}

@media (max-width: 767px) {
  :global(.ui.table).sprayTable thead th,
  :global(.ui.table).sprayTable tbody td {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }
  :global(.ui.table).sprayTable tbody td.status,
  :global(.ui.table).sprayTable thead th.checkboxHeaderCol {
    display: none;
  }

  :global(.ui.table).sprayTable thead th.status span {
    display: inline;
  }

  .sprayTable :global(.ui.fitted.toggle.checkbox),
  .sprayTable :global(.ui.fitted.toggle.checkbox) > input,
  .sprayTable :global(.ui.fitted.toggle.checkbox) > label:before {
    width: 30px;
  }
  .sprayTable
    :global(.ui.fitted.toggle.checkbox)
    > input:checked
    ~ label:after {
    left: 10px;
  }

  :global(.ui.table).sprayTable .areaName {
    word-break: break-word;
  }

  :global(.ui.table).sprayTable :global(.ui.mini) input {
    min-width: 40px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
