.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebarWrapper {
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dededf;
}

.header {
  font-family: Roboto !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: #212121;
}

.editBtn {
  cursor: pointer;
  color: #0e9354;
  margin-right: 24px !important;
}

.closeBtn {
  cursor: pointer;
  color: #666666;
}

.contentWrapper {
  overflow: auto;
  flex: 0 1 100%;
  height: 75%;
  overflow-y: auto;
}

.blockContainer {
  padding-top: 0px !important;
}

.blockContainer:nth-child(4) {
  margin-bottom: 15px;
}

.sectionTable {
  margin: 0px !important;
}

.headerSection {
  height: 40px !important;
  vertical-align: baseline;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.valueSection {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

:global(.ui.button).restoreBtn {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).restoreBtn:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).restoreBtn:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).restoreBtn:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.iconWrapper {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.btnWrpper {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}
