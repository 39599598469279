@import '../../../../assets/css/variables.css';

.formHolder {
  background: var(--color-background-cell);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
}

.formHolderIn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formHolderBody {
  padding: 30px;
  overflow: auto;
}
