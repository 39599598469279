.layout :global(.pageBreak) {
  margin: 0;
  width: 297mm;
  padding: 10mm 30mm;
}

.layout :global(.barcodeHolder) {
  margin: 1.75mm 2mm;
  height: 38.1mm;
  width: 21.2mm;
}

.layout :global(.barcodeDataHolder) {
}

.layout :global(.barcode) {
  width: 100mm;
  height: 60mm;
}

.layout :global(.qr-code) {
  padding: 10px;
}

.layout :global(.textHolder) {
  /*margin-top: 3px;*/
  /*line-height: 8px;*/
  /*font-size: 8px;*/
}

.layout :global(.text) {
  /*padding: 1px;*/
}
