.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

:global(.ui.grid .row).taskField {
  padding: 5px 0;
}

:global(.ui.grid .row).taskField :global(.ui.header).reportedTitle {
  margin: 0;
  font-size: 14px;
}

.taskField :global(.ui.header).areaTitle {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.areaTitle > :global(.dropdown.icon) {
  color: var(--color-actional-button);
  width: 15px !important;
  font-size: 24px !important;
}

.root .tabs {
  padding: 10px 0 0;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  display: flex;
}

:global(.ui.secondary.pointing.menu).tabsMenu a {
  flex: 1;
  text-align: center;
  display: block;
}

@media screen and (min-width: 768px) {
  :global(.ui.secondary.pointing.menu).tabsMenu :global(.active.item) {
    border-color: var(--color-green);
  }
}

:global(.ui.segment).issueContainer {
  border: 1px solid #59b655 !important;
  border-radius: 4px;
  padding-block: 5px;
}

:global(.ui.segment).issueContainer :global(.title).issueTitle {
  color: #59b655;
  font-weight: bold;
  font-size: 14px;
}

.image {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
  object-fit: cover;
}

.mapContainer {
  height: 445px;
}

.reportWarningIcon {
  margin-right: 10px;
}

.root .buttonContainer {
  padding-right: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  gap: 20px;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

:global(.ui.header).generalCommentsHeader {
  padding-top: 12px;
}
