.root :global(.ui.segment) {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
}

.root {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.sidebarHeader {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 10px !important;
}

.headerColumn {
  display: flex;
}

:global(.ui.header).name {
  font-size: 28px;
  font-weight: 700;
}

.closeButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

:global(.icon).closeButton:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.closeButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.headerSection {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.infoContainer {
  padding: 0em 1em !important;
  padding-top: 9px !important;
  overflow-y: auto;
  scrollbar-width: none;
  height: calc(100% - 100px);
}

.infoContainer::-webkit-scrollbar {
  display: none;
}

.infoContainer:global(.ui.grid) {
  margin-bottom: 0;
}

.infoContainer:global(.ui.grid > *) {
  padding-right: 0em;
  padding-left: 0em;
  padding-top: 0px !important;
}

.generalInfoContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.taskDetailsTable {
  width: 98% !important;
}

.btnWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
}

.editButton {
  color: #0e9354 !important;
  background: none !important;
}

.segment {
  margin-left: 10px;
  width: 98%;
  margin-left: 10px !important;
  padding-top: 20px !important;
  min-height: 68px !important;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  height: auto !important;
}

.rowFieldWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.nameRowField {
  width: 70%;
  padding-left: 10px !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
}

.btnContainer {
  width: 25%;
}

.iconRowField {
  width: 5%;
}

.rowChevronIcon {
  cursor: pointer;
  color: #666666;
}

:global(.ui.button).clockOutBtn {
  display: flex;
  align-items: center;
  height: 28px !important;
  background: none !important;
  border: 1px solid var(--color-green) !important;
  color: var(--color-green);
}

.buttonContainer {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.clockoutBtn {
  background-color: #0e9354 !important;
  color: #ffffff !important;
}
