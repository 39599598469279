.header {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
}

.systemInformationHeader {
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}

.informationWrapper {
  margin-top: 24px;
}

.informationFieldContainer {
  display: flex;
  padding-bottom: 16px;
}

.informationWrapperHeader {
  width: 30%;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.informationWrapperValue {
  width: 70%;
}

.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.companyInformation {
  margin-top: 24px;
}

.businessInformationWrapper {
  display: flex;
  padding-bottom: 16px;
}

.businessInformationHeader {
  width: 30% !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.businessInformationValue {
  width: 70%;
}

.companyLogo {
  width: 70px !important;
  height: 70px !important;
}

.companyDisabledLogo {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50px;
  background-color: #ebf6e9 !important;
}

.saveButton {
  background-color: #0e9354 !important;
  color: white !important;
}

.cancelBtn {
  margin-right: 16px !important;
  background: none !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
}

.nameField {
  padding: 0px !important;
}

.abnField {
  padding: 0px !important;
}

.label {
  padding-left: 8px;
}

.logoField {
  display: flex;
}

.fileChooserWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 24px;
}

.generalAttachmentsContainer {
  display: flex !important;
  margin-left: 0px !important;
}

.generalAttachmentsButton {
  text-align: inherit !important;
  width: 135px !important;
  margin-right: 14px !important;
}

.buttonWrapper {
  display: flex !important;
  align-items: center;
}

.removeBtn {
  padding: 0 !important;
  text-align: inherit !important;
  font-size: 14px !important;
  background: none !important;
  width: 100px !important;
  color: #0e9354 !important;
}

.dot {
  height: 5px !important;
  width: 5px !important;
  background-color: #bbb !important;
  border-radius: 50% !important;
  display: inline-block !important;
  margin-right: 14px !important;
}

.seasonInfo {
  margin-bottom: 24px;
}

.rowContainer {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.seasonsSegment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.newSeasonsRow {
  display: flex !important;
  padding: 0 !important;
  align-items: center !important;
  height: 40px !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.addButtonContainer {
  padding-top: 16px;
  text-align: end;
}

.buttonContainer {
  width: 100%;
  text-align: end;
}

.saveBtn {
  padding: 0px !important;
  color: #0e9354 !important;
  background: none !important;
}

:global(.ui.button).cancelButton {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

:global(.ui.button).cancelDltBtn {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

:global(.ui.button).cancelDltBtn:hover {
  background-color: #156941;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
}

:global(.ui.button).deleteBtn {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).deleteBtn:hover {
  background: #8c0d24;
  border-radius: 4px;
  color: white;
}

.valueColumn {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  padding-bottom: 5px !important;
}

.newSeasonssButtonCantainer {
  width: 5% !important;
  display: flex !important;
  height: 40px;
}

.deleteButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #b00020 !important;
}

:global(.icon).deleteButton:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.deleteButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
  margin-bottom: 10px !important;
}

.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

:global(.ui.labeled.input > .label) {
  height: 40px !important;
}

.addButtonWrapper {
  padding-top: 8px !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.deleteMessageWrapper {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  text-align: center !important;
  color: #212121 !important;
  flex: none !important;
  order: 0 !important;
  align-self: stretch !important;
  flex-grow: 0 !important;
}

.deleteModal {
  margin-top: 13% !important;
}

.updateBtnContainer {
  display: flex;
  justify-content: flex-end;
}

.updateBtn {
  background-color: #0e9354 !important;
  color: white !important;
  font-weight: 500;
  line-height: 21px;
}

.updateInfoWrapper {
  margin-top: 0px !important;
}
