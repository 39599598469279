.barcode {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  background: white;
  font-size: 20px;
}

.top {
  padding-top: 10px;
  height: 50%;
  border-bottom: 2px solid black;

  overflow: hidden;
}

.top span {
  width: 100%;
  color: black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 50px;
  text-transform: uppercase;
  overflow-wrap: break-word;
  text-align: center;
}

.variety {
  width: 100%;
  color: black !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 34px !important;
  line-height: 50px !important;
  text-transform: uppercase !important;
  overflow-wrap: break-word !important;
  text-align: center !important;
}

.bottom {
  height: 50%;
  display: flex;
  flex-direction: row;
}

.left,
.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left {
  border-right: 1px solid black;
}

.right {
  border-left: 1px solid black;
}

.left span {
  max-width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.right span {
  max-width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  overflow-wrap: break-word;
}

.qrCode {
}
