.modalHeader {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

.formSmallField {
  width: 16.5%;
}

.formSmallDropDown {
  max-width: 100% !important;
  margin: 0 !important;
}

.formSmallDropDown div {
  min-width: 16.5% !important;
}

.workersRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.workersRowHead {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.rowContainer {
}

.closeIcon {
  margin-bottom: 5px;
}

.dateRow {
  border-bottom: 1px solid #dededf !important;
}

.firstStepForm {
  padding-left: 9px !important;
  width: 100% !important;
}

.scanDateField {
  padding-bottom: 14px;
}

.horizontalLine {
  border: none;
  background-color: #dededf;
  height: 1px;
  color: #dededf !important;
}

.tableGroup {
  display: block !important;
  padding-left: 5px !important;
}

.searchInput {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-left: 8px !important;
  gap: 8px;
}

.searchAndFiltersContainer {
  display: flex !important;
}

.toggleIcon {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.openTable {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.tbody {
  display: block !important;
  vertical-align: middle !important;
  width: 100%;
}

.icomNameContainer {
  width: 100% !important;
}

.chevronContainer {
  width: 100% !important;
}

.patchRow {
  display: block !important;
}

.patchCell {
  display: block !important;
  padding: 0 !important;
  height: 32px !important;
  padding-left: 25px !important;
  border: none !important;
}

.blockName {
  padding-left: 10px !important;
}

.patchName {
  padding-left: 10px !important;
}

.closeTable {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.iconNameWrapper {
  display: flex;
  align-items: center !important;
}

.selectAllButton {
  display: flex !important;
  align-items: center !important;
  height: 30px;
  background: #28b573 !important;
  color: white !important;
  cursor: pointer !important;
  padding: 11px !important;
}
