@import "../../../assets/css/variables.css";

.p1em {
  padding: 1em;
}

.backIconWrapper {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.filter {
  margin-top: -0.7rem !important;
}

.filtersGrid {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 1rem !important;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.archivedArea {
  filter: grayscale(100%);
  opacity: 0.5;
}

.expandedTabelRow {
  padding: 0px 20px 0px 10px !important;
  border: none !important;
}
