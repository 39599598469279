.overForm {
  height: 100%;
}

.inputHidden {
  height: 0;
}

.accordionHolder {
  max-width: 900px;
  margin: 0 auto;
}

.buttonsFooter {
  text-align: right;
  padding: 5px 15px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.row .ui.input {
  max-width: 300px;
}

.minusButton {
  cursor: pointer;
  margin-left: 10px !important;
  border: 1px solid #9dacba;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: transparent;

  width: 40px;
  height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumnCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .buttonsFooter {
    padding: 0;
  }

  .buttonsFooter :global(.ui.button) {
    margin: 0;
  }
}

@media (min-width: 768px) {
  :global(.ui.grid).gridAvatar > :global(.row) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .avatarHolder {
    position: absolute;
    left: 0;
    top: 1.3rem;
  }
}
