@import '../../assets/css/variables.css';

:global(.ui.button.button-text).buttonMore {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 0;
}

:global(.ui.button.button-text).buttonMore:hover {
  background: none;
}

:global(.ui.basic.segment).tasksListRow {
  color: var(--color-title);
  border-top: 1px solid var(--color-border);
}

:global(.ui.basic.segment).tasksListRow:last-child {
  border-bottom: 1px solid var(--color-border);
}

:global(.ui.grid).tasksListRowGrid {
}

:global(.ui.button).readFull {
  color: var(--color-violet);
  margin-top: 10px;
  margin-bottom: 15px;
}

:global(.ui.grid > .row > .column).columnActions {
  position: absolute;
  top: 15px;
  right: 15px;
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
}

.printHolder {
  display: none;
}

.columnBlock,
.columnSize,
.columnProgress {
  padding-top: 15px;
  padding-bottom: 15px;
}

.hasDone div {
  padding-left: 15px;
}

.progressHolder {
  max-width: 60px;
}

.statusOPEN {
  color: #ffc000;
}

.statusIN_PROGRESS {
  color: #3b5998;
}
.statusCOMPLETED {
  color: #afc644;
}

.rowOPEN {
  background: #dbe7f6;
  font-weight: bold;
}

:global(i.icon).iconEye {
  font-size: 18px;
  margin-top: 5px;
  display: block;
}

.employeeIcons {
  display: inline-block;
}

.plusMore {
  display: inline-block;
  font-weight: bold;
}

.employeeIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  background: var(--color-background-cell);
  border: 1px solid var(--color-border);
  border-radius: 50%;
}

.employeeIconOverlay {
  float: left;
  margin-left: -17px;
}

.chemicalName,
.blockName {
  overflow-wrap: break-word;
  position: relative;
}

.blockName :global(i.icon) {
  position: absolute;
  left: -5px;
  top: 0;
}

.newTask {
  background: var(--color-grass-light);
}

@media screen and (min-width: 768px) {
  .hasDone div {
    padding-left: 0;
  }

  .blockName :global(i.icon) {
    left: -20px;
  }

  .columnBlock,
  .columnSize,
  .columnProgress {
    padding-top: 0;
    padding-bottom: 0;
  }
  .printHolder {
    display: inline-block;
  }

  :global(.ui.basic.segment).tasksListRow {
    border: none;
  }
  :global(.ui.basic.segment).tasksListRow:last-child {
    border-bottom: none;
  }
  :global(.ui.basic.segment).tasksListRow:hover {
    background: var(--color-background-cell);
  }

  :global(.ui.grid > .row > .column).columnActions {
    position: inherit;
    top: 0;
    right: 0;
  }

  .columnAvatar {
    text-align: center;
  }

  :global(.column).columnBlock {
    padding-left: 15px;
  }

  :global(i.icon).iconEye {
    display: inline-block;
    margin-left: 15px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  :global(.ui).popup {
    display: none !important;
  }
}
