@import "../../assets/css/variables.css";

:global(.ui.segment).promptHolder {
  background: #ffffff;
  height: 70vh;
  border-radius: 5px;
}

:global(.ui.segment).promptHolderRight {
  padding: 0;
  background: #ffffff;
  border-radius: 5px;
}

:global(.ui.segment).promptHolderRight :global(.rw-box) {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  flex: 1 1 auto;
}

:global(.ui.segment).promptHolderRight :global(.rw-main.type-5days) {
  flex: 1 1 auto;
}

:global(.ui.segment).promptHolderRight :global(.rw-main.type-5days),
:global(.ui.segment).promptHolderRight :global(.rw-box-days) {
  border-radius: 0;
}

:global(.ui.segment).promptHolder > div[id="farm-map"] {
  height: 100%;
}

.blocksCount {
  margin-top: 5px;
}

.blocksCount span {
  color: var(--color-title);
}

:global(.ui.table).tableSummary {
  min-width: 700px;
}

:global(.ui.table).tableSummary thead tr th {
  font-weight: normal;
}

:global(.ui.table).tableSummary tbody tr td {
  border-bottom: none;
  border-top: none;
}

.tableHolder {
  overflow: auto;
  margin-top: 20px;
}

:global(.ui.label).colorOPEN {
  color: #fff;
  background: var(--color-open);
}

:global(.ui.label).colorIN_PROGRESS {
  color: #fff;
  background: var(--color-in-progress);
}

:global(.ui.label).colorCOMPLETED {
  color: #fff;
  background: var(--color-completed);
}

.vacant {
  font-style: italic;
  color: var(--color-vacant-blue);
}

:global(.ui.header).farmName {
  cursor: pointer;
}

:global(.ui.header).farmName:hover {
  color: var(--color-green);
}

.farmSelector {
  width: calc(100% - 100px);

  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 100;

  display: flex;
  flex-direction: row;
}

.mapFilter {
  top: 50px;
  left: unset;
}

@media screen and (min-width: 768px) {
  :global(.ui.segment).promptHolderRight {
    min-height: 580px;
    height: 60vh;
  }

  :global(.ui.segment).promptHolder {
    min-height: 580px;
    height: 60vh;
  }
}

@media screen and (min-width: 580px) {
  .mapFilter {
    top: unset;
    left: 230px;
  }
}

.filterButton {
  display: flex !important;
  align-items: center !important;
  height: 40px;
  padding: 10px 10px 10px 10px !important;
  background: #ffffff;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  width: 220px;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  justify-content: space-between;
}
.filterButton > :global(input.search) {
  display: none !important;
}
.filterButton > :global(.sizer) {
  display: none !important;
}

.filterButton > :global(.text) {
  display: contents !important;
  margin: 0 !important;
  padding-right: 14px !important;
}
.filterButton > :global(.filtered.text) {
  visibility: inherit !important;
}

.filterButton > :global(i.icon) {
  position: relative;
  margin-right: 0;
  padding-bottom: 20px;
}

.farmFilterMenu {
  min-height: 290px !important;
  max-height: none !important;
}
.scrolingMenu {
  min-height: 200px;
}

.activeFilterDropDownIcons {
  display: inline-flex;
  align-items: center;
  justify-content: space-between !important;
  width: 75%;
}
.filterDropDownIcons {
  justify-content: flex-end;
}
.filterDropDownIcons span {
  margin-right: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: #c5bbbb;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.filterDropDownIcons i {
  margin-bottom: 5px !important;
}
