.header {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
  margin-bottom: 24px;
}

.taskTypesHolder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  background-color: transparent;
}

.taskCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);
  background-color: #ffffff;
  margin: 0 24px 24px 0;
  padding: 28px 33px;
  text-align: center;
  border-radius: 8px;
}

.taskCard:hover {
  cursor: pointer;
  background-color: #c9cfd072;
}

.taskCard img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
}

.taskCard .caption {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: var(--color-text);

  width: 120px;
}

.checkboxWrapper {
  margin-top: 16px;
  height: 20px;
}
