.selectAllHolder {
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.selectAllHolder :global(.ui.button) {
}

.dropdownHolder {
  position: relative;
}

.dropdownHolder :global(.icon.sliders) {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.dropdownHolder :global(.icon.delete).clearAll {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

:global(.ui.dropdown).dropdown :global(.item) span {
  display: inline-block;
  color: var(--color-black);
}

:global(.ui.dropdown).dropdown :global(.item) i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

:global(.ui.dropdown).dropdown :global(.item i.vacant) {
  color: var(--color-vacant-blue);
}

:global(.ui.dropdown).dropdown.showBar :global(.menu),
:global(.ui.dropdown).dropdown.showBar :global(.menu:hover),
:global(.ui.dropdown).dropdown.showBar :global(.menu:active),
:global(.ui.dropdown).dropdown.showBar:hover :global(.menu) {
  box-shadow: none;
  border-bottom: 0;
}

.selectAllFooter {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
  border: 1px solid var(--color-border-sui);
  border-top: none;
  border-radius: 0 0 3px 3px;
}
