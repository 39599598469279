/* App.css */
.customTable {
  width: 100%;
  margin: 20px 0;
  font-size: 1em;
  min-width: 400px;
  border-collapse: collapse;
}

.customTable thead tr {
  color: #5b666f;
  font-size: 14px;
  text-align: left;
}

.customTable th,
.customTable td {
  padding: 12px 15px;
  /* border: 1px solid #dddddd; */
}

.customTable tbody tr {
}

.customTable tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.customTable tbody tr:last-of-type {
}

.customTable tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.customRow {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.customRow input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.sidePanel {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #ccc;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.sidePanel h2 {
  margin-top: 0;
}

.sidePanel button {
  background-color: #009879;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
}

.sidePanel button:hover {
  background-color: #007f68;
}
