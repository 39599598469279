@import "../../../assets/css/variables.css";

:global(.ui.segment).mainHolder {
  padding: 0;
}

:global(.ui.header).taskName {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.dropdownHeaderMenu {
}

:global(.ui.dropdown).dropdownHeaderMenu {
  padding-left: 0;
}

:global(.ui.scrolling.dropdown).dropdownHeaderMenu :global(.menu) {
  max-height: 350px;
}

:global(.ui.dropdown).dropdownHeaderMenu > :global(.text) {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: "Montserrat", "Lato", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
}

.barHolder {
  display: flex;
  margin: 0 -5px;
  align-items: center;
  flex-wrap: wrap;
}

.barHolder > div {
  flex: 0 0 auto;
  padding: 5px;
}

.barHolder :global(.ui.button:not(.icon) > .icon:not(.button):not(.dropdown)) {
  margin: 0;
}

.barHolder
  :global(.ui.button:not(.icon) > .icon:not(.button):not(.dropdown))
  + span {
  margin-left: 10px;
}

.barHolderIn {
  display: flex;
  align-items: center;
}

.barHolderIn > div {
  flex: 0 0 auto;
  padding: 0 5px;
  max-width: 450px;
}

.dateInput {
  width: 120px;
}

:global(.ReactTable).dataTable {
  overflow: auto;
  border: none;
}

:global(.ReactTable).dataTable :global(.rt-tbody .rt-tr:hover .rt-td) {
  background: var(--color-row-hover) !important;
}

:global(.ui.dropdown).areasDropdown {
  padding-right: 5em;
}

:global(.ui.dropdown).areasDropdown :global(.item) span {
  display: inline-block;
  color: var(--color-black);
}

:global(.ui.dropdown).areasDropdown :global(.item) i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

@media screen and (min-width: 768px) {
  :global(.ui.dropdown).areasDropdown {
    width: 500px;
  }
}

@media screen and (max-width: 767px) {
  .headerTasks :global(.ui.grid > .row) {
    flex-wrap: nowrap;
    width: 100%;
  }

  .headerTasks :global(.ui.grid > .row > .column) {
    width: auto !important;
    flex: auto;
  }

  :global(.ui.dropdown).areasDropdown {
    width: 275px;
  }
}
