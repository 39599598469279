.root {
  width: 600px !important;
  background: #ffffff;
  height: 100%;
  padding: 16px;
  font-family: Lato, serif;
}

.root .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

.root .taskField {
  padding: 5px 0;
}

.root .tabsMenu {
  display: flex;
}

.root .tabsMenu .tab {
  flex: 1;
  text-align: center;
  display: block;
  justify-content: center;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.active.item) {
  border-color: var(--color-background-green);
}

.root .dropDownButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.root .dropDownButton > :global(i.icon) {
  margin-right: 0 !important;
}

.root .buttonsWrapper {
  display: inline-flex !important;
  gap: 16px;
  flex-direction: row !important;
  align-items: center;
}

.root .buttonsWrapper .button,
.root .buttonsWrapper .dropDownButton {
  width: 120px;
}

.root .buttonsWrapper .closeIcon {
  margin-right: 0;
  margin-left: auto;
}

.root .editableHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.root .editIcon {
  color: var(--color-green);
  margin-right: 0 !important;
}

.root .machineryDataField {
  display: flex;
  justify-content: space-between;
}

:global(.ui.message).attachmentCard {
  color: var(--color-green);
  background-color: #ebf7e9;
  border: 1px solid var(--color-green);
  padding-right: 0 !important;
}

.textarea {
  width: 100%;
  resize: none;
}

:global(.header).chemicalHeader {
  margin-bottom: 10px !important;
  text-transform: uppercase;
  color: #9a9ca3 !important;
}

:global(.header).chemicalValue {
  margin-top: 0;
  margin-bottom: 20px !important;
  color: #263039 !important;
  font-weight: normal;
}

.buttonsContainer {
  margin-top: 30px;
}

.typeReasonButton {
  text-decoration: underline;
}

.root .trashIcon {
  color: var(--color-dark) !important;
  padding-left: 20px;
}

.actionIcon,
.editIcon,
.trashIcon {
  cursor: pointer;
}
