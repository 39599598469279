.root {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.contentWrapper {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px !important;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.header {
  display: flex;
  align-items: center;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.formContainer {
  overflow-y: hidden !important;
  height: calc(100vh - 100px);
  background-color: white;
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding-top: 0px !important;
  margin-top: 24px !important;
}

.backButtonContainer {
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 36px;
  height: 36px;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 9px 0px #0000001a;
  background: #ffffff;
  margin-right: 16px;
}

.backButton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
}

.taskName {
  font-family: Roboto;
  font-size: 34px;
  font-weight: 300;
  line-height: 51px;
  text-align: left;
  color: #212121;
  margin-left: 16px;
}

.statusWrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.tabSection {
  box-shadow: 0px 4px 9px 0px #0000001a !important;
  padding: 16px 16px 10px 16px;
  gap: 16px;
}

.infoSection {
  display: flex;
  justify-content: space-between;
}

.switchButton {
  margin-top: 16px !important;
  width: 200px !important;
}

.completedBtn {
  margin-top: 16px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  background-color: #f6f7f8;
  color: #b3b3b3;
}

.activeCompletedBtn {
  margin-top: 16px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  background-color: #0e9354 !important;
  color: white !important;
}

.progressLineWrapper {
  width: calc(100% - 265px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 16px;
}

.tableSection {
  margin-top: 16px;
}

.sidebarWrapper:global(.ui.sidebar) {
  width: 600px;
}

.sidebarHolder {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 15px;
  height: 100%;
}

:global(.ui.button).activeBtn {
  border: 1px solid #0e9354 !important;
  background: none !important;
  color: #0e9354;
}

:global(.ui.button).activeBtn:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  border-radius: 4px !important;
}

.areatableLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
