.table-container {
  padding: 1em;
  width: auto !important;
  overflow-x: auto !important;
}

.table-container .ui.table {
  border-collapse: collapse;
  width: 100%;
}

.table-container .ui.table thead tr {
  background-color: #f9fafb;
}

.table-container .ui.table thead th {
  border-bottom: 2px solid #ddd;
  padding: 10px;
  text-align: left;
  font-weight: normal;
}

.table-container .ui.table tbody tr {
  border-bottom: 1px solid #ddd;
}

.table-container .ui.table tbody td {
  padding: 10px;
}

.table-container .ui.table tbody tr:nth-child(odd) {
  background: rgb(39 39 39 / 3%);
}

.total-row {
  background-color: #ffffff !important;
  font-weight: bold;
}

.total-row th {
  background-color: #ffffff !important;
  padding: 10px;
}