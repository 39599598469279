.chartHolder {
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
}

.chartHolder + .chartHolder {
  margin-top: 2rem;
}

.labelAxisYLeft {
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left center;
  transform-box: fill-box;
}

.labelAxisYRight {
  transform: rotate(-90deg) translateX(50%) translateY(-10px);
  transform-origin: right center;
  transform-box: fill-box;
}

.chart {
  margin-top: 1rem;
}
