.layout :global(.block) {
}

.layout :global(.page) {
}

.layout :global(.pageBreak) {
  margin: 0;
  width: 80mm;
  height: 85mm;
  padding: 0;
}

.layout :global(.barcodeHolder) {
  margin: 1.5mm;
  height: 13mm;
  width: 23mm;
}

.layout :global(.barcodeDataHolder) {
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.layout :global(.textHolder) {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.layout :global(.text) {
  padding: 1px;
}
