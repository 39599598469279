@import '../../assets/css/variables.css';
.headerTasks {
}

.headerTasks :global(.ui.grid > .row) {
  padding: 0;
}

.headerTasks :global(.ui.grid > .row > .column):first-child {
  padding-left: 0;
}

.headerTasks :global(.ui.grid > .row > .column):last-child {
  padding-right: 0;
}

.headerTasks :global(.ui.grid > .row > .column:last-child .ui.button:last-child) {
  margin-right: 0;
}

:global(.ui.dropdown).dropdownHeaderMenu {
  padding-left: 0;
}

:global(.ui.scrolling.dropdown).dropdownHeaderMenu :global(.menu) {
  max-height: 350px;
}

:global(.ui.header).dropdownHeaderMenu {
  padding: 0.14em 0;
  margin: 0 20px 0.45238095em 0;
  line-height: 1.21428571em;
  display: inline-block;
}

:global(.ui.header).taskName {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.farmsHolder :global(.pusher) {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.farmsHolder :global(.ui.right.overlay.sidebar) {
  background: var(--color-background);
  width: 100%;
  padding: 15px;
  overflow: auto;
  box-shadow: var(--box-shadow);
}
.sidebarHolder {
  position: relative;
}

:global(.ui.segment).mainHolder {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .farmsHolder :global(.ui.sidebar) {
    width: 70%;
    max-width: 570px;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .headerTasks :global(.ui.grid > .row) {
    flex-wrap: nowrap;
    width: 100%;
  }
  .headerTasks :global(.ui.grid > .row > .column) {
    width: auto !important;
    flex: auto;
  }
}
