@import "../../../../../../../assets/css/variables.css";

:global(.ui.form).ageForm :global(.inline.fields) {
  margin: 0 -10px;
}

:global(.ui.form).ageForm :global(.inline.fields .field) {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

:global(.ui.form).ageForm :global(.inline.fields .field) > label {
  font-weight: normal;
  display: block;
  flex: 0 1 auto;
}

:global(.ui.form).ageForm :global(.inline.fields .field) > div {
  display: block;
  flex: 1 1 auto;
}

:global(.ui.form).ageForm :global(.inline.fields .field) > div input {
  width: 100%;
  max-width: 60px;
}

.areaDropDownHolder {
  position: relative;
}

.areaDropDownHolder :global(.icon.sliders) {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.areaDropDownHolder :global(.icon.delete).clearAll {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.filtersHolder {
  position: absolute;
  width: 100%;
  top: 100%;
}

.filtersHolder :global(.ui.segment) {
  margin: 0;
}

.filtersBar {
  position: absolute;
  top: 100%;
  width: 100%;
  background: var(--color-grass-light);
  padding: 0 15px;
  box-shadow: var(--box-shadow);
}

.filtersFooter {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
}

.filtersFooter :global(.ui.button) {
  border-radius: 0;
}

:global(.ui.form).ageForm :global(.inline.fields) {
  margin: 0 -10px;
}

:global(.ui.form).ageForm :global(.inline.fields .field) {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

:global(.ui.form).ageForm :global(.inline.fields .field) > label {
  font-weight: normal;
  display: block;
  flex: 0 1 auto;
}

:global(.ui.form).ageForm :global(.inline.fields .field) > div {
  display: block;
  flex: 1 1 auto;
}

:global(.ui.form).ageForm :global(.inline.fields .field) > div input {
  width: 100%;
  max-width: 60px;
}

:global(.ui.grid).filtersGrid button + button {
  margin-left: 15px;
}

:global(.ui.grid).filtersGrid :global(.menu) i {
  margin-left: 15px;
  display: inline-block;
  color: var(--color-middle);
}

:global(.ui.grid).labelsGrid {
  margin-top: 0;
  margin-bottom: 0;
}

:global(.ui.grid).labelsGrid :global(.row .column) {
  padding-bottom: 0;
}

:global(.ui.grid).labelsGrid :global(.ui.label) {
  margin-top: 2px;
  margin-bottom: 2px;
}

:global(.ui.grid).labelsGrid :global(.button-text) {
  color: var(--color-grass);
}

:global(.ui.grid).labelsGrid :global(.button-text + .button-text) {
  margin-left: 15px;
}

:global(.ui.dropdown).areasDropdown {
  padding-right: 5em;
}

:global(.ui.dropdown).areasDropdown :global(.item) span {
  display: inline-block;
  color: var(--color-black);
}

:global(.ui.dropdown).areasDropdown :global(.item) i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

.optional label:after {
  display: none;
}
