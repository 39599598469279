formWrapper {
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 100% !important;
}

.binsDetails {
  width: 100%;
  height: 51px;
  background-color: #f5f7f7;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  align-items: center !important;
  padding-top: 17px;
  padding-right: 15px;
}

.secondStepForm {
  display: block !important;
}

.container {
  display: block !important;
  width: 100% !important;
}

.ui.checkbox input:checked ~ label:before {
  background-color: #36d021 !important;
}

.formSegment {
  margin-top: 20px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  padding: 15px !important;
}

.superVisorRow {
  display: flex !important;
  padding-bottom: 15px;
}

.workersRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.workersRowHead {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.closeIcon {
  margin-bottom: 5px;
}

.binsDetailsHaeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #212121;
  padding: 20px;
  padding-bottom: 33px !important;
}

.binsDetails
  :global(.ui.fitted.toggle.checkbox)
  > input:checked
  ~ label::before {
  background-color: #0e9354 !important;
}
