.datedModal {
  width: 400px !important;
}

.dateModalContent {
  padding: 20px !important;
}

.dateModalHeader {
  color: #212121 !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: center !important;
  padding-bottom: 8px;
}

.dateModalSubHeader {
  color: #212121 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  padding-bottom: 16px;
}

.calendarLabel {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
}

.dateModalBody {
  display: flex;
  flex-direction: column;
}

.taskDateCalendar {
  width: 100% !important;
  height: 40px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  border: 1px 0px 0px 0px !important;
  border: 1px solid #dededf !important;
  margin-top: 4px !important;
}

.taskDateCalendar div {
  border: none !important;
}

.taskDateCalendar input:focus-visible {
  outline: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}
