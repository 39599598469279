.root {
  margin-top: 24px;
}

.container {
  border-radius: 4px;
  border: 1px solid #dededf;
  padding: 16px;
  margin-bottom: 16px;
}

.formField {
  margin-bottom: 40px !important;
}

.selectWorkersField {
  padding: 8px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #dededf;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.selectWorkersField :global(text) {
  width: 90% !important;
  height: 25px !important;
}

:global(.ui.modal).messageModal {
  width: 400px !important;
  overflow: auto;
}

:global(.ui.modal).messageModal :global(.content) {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

:global(.ui.modal).messageModal :global(.actions) {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

:global(.ui.modal.messageModal > .actions) {
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

:global(.ui.large.modal.messageModal > .header) {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.addCurrButton {
  background: none !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.addCurrButton:hover {
  background-color: #076a3c !important;
  color: white !important;
}

.addAllButton {
  background-color: #0e9354 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.addAllButton:hover {
  background-color: #076a3c !important;
}

.btnWrapper {
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  margin-bottom: 15px;
}
