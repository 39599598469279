.layout :global(.page) {
  height: 48mm;
  width: 80mm;
}

.layout :global(.block) {
}

.layout :global(.block *) {
}

.layout :global(.labelHolder) {
  height: 100%;
  width: 100%;
  padding: 5mm;
  display: flex;
  flex-flow: row-reverse;
}

.layout :global(.barcodeDataHolder) {
}

.layout :global(.barcode) {
  height: 17mm;
  width: 17mm;
}

.layout :global(.textHolder) {
}

.layout :global(.text) {
  margin-top: 1px;
  font-size: 12px;
}

.layout :global(.text.id) {
  margin-top: 3px;
  font-size: 9px;
}

.layout :global(.packingShed) {
  margin-top: 1px;
  font-size: 14px;
  font-weight: bold;
}
