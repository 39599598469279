.batchNumberDropDown {
  padding-right: 10px !important;
  background: #ffffff !important;
  border: 1px solid #dededf !important;
  border-radius: 3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px !important;
}

.batchNumberDropDownMenu {
  width: 320px !important;
  max-height: inherit !important;
  overflow: hidden !important;
  height: auto !important;
}
.batchNumberDropDownScrollingMenu {
  border-top-width: 1px black solid !important;
}
.addItem {
  max-width: 300px;
  height: 29px !important;
  background: #fdfdfd !important;
  border: none !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #dededf !important;
  border-radius: 24px !important;
  font-size: 14px !important;
  min-height: 30px;
  vertical-align: middle !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
  padding-top: 2px !important;
  padding-left: 8px !important;
  padding-right: 11px !important;
}

.addItem i {
  color: #b00020 !important;
  cursor: pointer !important;
  padding-left: 20px !important;
}

.labelContainer {
  max-width: 320px;
  white-space: initial !important;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  margin: 0px !important;
  padding-left: 10px !important;
  border-bottom: none !important;
}

.addButtonWrapper {
  padding: 0px !important;
}

.addButton {
  display: flex !important;
  font-weight: 400px !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
  font-size: 14px !important;
}
.addButton:hover {
  background-color: #ebf6e9 !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
}
.addButtonBatchNumber {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600px !important;
}
