.reportWrapper {
  padding: 25px;
}

.reportHeaderRow {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.logoContainet {
  width: 90px;
  height: 90px;
}

.reportHeader {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.reportSubHeader {
  background-color: none;
  border: none;
}

.reportSubBody {
  hyphens: auto;
  border: none;
  background-color: none;
}

.reportTable {
  width: 100%;
}

.blockHeaderCellTable {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.tableHeader {
  padding-left: 15px;
  padding-right: 15px;
}

.tableValue {
  padding-right: 15px;
}

.tableBody {
  padding-left: 15px;
  padding-right: 15px;
}

.blockHeaderCellTableWithBorder {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.blockSecondHeaderCellTable {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.blockHeaderValueCellTable {
  border-collapse: collapse;
  border-right: 1px solid #dededf;
}

.blockBodyTable {
  width: 100%;
}

.blockCellTable {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.blockSecondCellTable {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.patchHeaderCellTable {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.patchHeaderValueCellTable {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.patchHeaderCellTableWithBorder {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.patchSecondHeaderCellTable {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.patchCellTable {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.patchSecondCellTable {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.totalRowTitle {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.totalRowHeader {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.totalRowBody {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.cell {
  width: 13%;
}

.dateRow {
  padding-left: 16px;
}

.blockWrapper {
  background-color: #eeeeef;
  border-bottom: 2px solid black;
}

.patchWrapper {
  border-bottom: 2px solid black;
}

.patchBodyTable {
  width: 100%;
}

.patchTableHeader {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.blockTableHeader {
  width: 100%;
  border-bottom: 1px solid #dededf;
}
