.printTableWrapper {
  margin-right: 20px !important;
}

.printTableRow {
  display: flex;
}

.printTableHeader {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-right: 12px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.printTableLogo {
  height: 80px !important;
  margin-right: 12px !important;
  margin-top: 12px !important;
}

.totalRowWrapper {
  width: 95% !important;
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.totalRowHeaderWrapper {
  width: 100% !important;
  display: flex;
}

.totalRowValueWrapper {
  width: 100% !important;
  display: flex;
}

.line {
  height: 1px;
  background-color: #dededf;
}

.totalRowDateHeaderContainer {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.totalRowDateValueContainer {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.totalRowProductHeaderContainer {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.totalRowProductValueContainer {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.totalRowShedHeaderContainer {
  width: 33% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.totalRowShedsValueContainer {
  width: 33% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.container {
  border-bottom: 1px solid black !important;
  margin: 20px !important;
}

.reportTable {
  width: 100%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.reportHeader {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 20%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.reportBody {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 20%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.secondreportTable {
  width: 100%;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.secondreportHeader {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.secondreportBody {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  max-height: 50px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.secondreportBody:first-child {
  border-left: none !important;
}

.secondreportHeader:first-child {
  border-left: none !important;
}

.linkWrapper {
  display: grid;
}
