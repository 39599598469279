.layout :global(.pageBreak) {
  margin: 0;
  width: 297mm;
  padding: 3.5mm 10.7mm 3mm 10.7mm;
}

.layout :global(.barcodeHolder) {
  margin: 1.25mm 0;
  height: 38.1mm;
  width: 21.2mm;
}
