body {
  background: #fff;
}

.header-left {
  text-align: left;
}

.pr-10 {
  padding-right: 10px;
}

/* Print Page */

.print-page-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ebf6e9;
  padding: 10px 35px;
}

.print-page-header-column {
  display: flex;
  text-align: left;
  align-items: center;
}

.print-page-reversed-row {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.print-page-reversed-row .print-page-left-block {
  margin-right: 20px;
}

.print-page-table {
  width: 100%;
  font-size: 10pt;
  border-collapse: collapse;
}

.print-page-row-avoid-break {
  display: block;
  break-inside: avoid-page;
}

.print-page-table tfoot tr {
  border-top: 1px solid black;
}

.justify-content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.justify-content-end {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-1 {
  margin-right: 20px !important;
}

.mb-1 {
  margin-bottom: 20px !important;
}

.-table-summary .rt-tr-group:last-child {
  border-top: 1px solid black;
}

.link_underlined {
  color: black;
  text-decoration: underline;
}

.chemicals-costs {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.font {
  font-family: Roboto;
}

.underlined {
  text-decoration: underline;
}

.bold {
  font-weight: bold !important;
}

.medium {
  font-weight: 600 !important;
}

.slim {
  font-weight: 400 !important;
}

.print-page-root {
  padding: 0px 35px;
}

.report-image {
  border-radius: 5px;
}

.issue-section {
  break-inside: avoid;
}

.capitalize {
  text-transform: capitalize;
}

.issue-section {
  break-inside: avoid;
  padding: 25px 50px;
}

.avoid-row-splitting {
  break-inside: avoid;
}

.page-break-before {
  break-before: page;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding: 50%;
  border: 2px solid #f6f7f8;
  border-radius: 5px;
}
.image-wrapper > .image-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.image-wrapper > .image-container > .report-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.info-row {
  padding-bottom: 8px !important;
}

.issue-row-padded {
  padding: 6px 0 !important;
}

.info-header {
  padding: 20px 0 !important;
}

.block-divide-line {
  margin: 30px 0;
  color: #dededf;
  height: 1px;
  background-color: #dededf;
}

.full-width {
  width: 100%;
}

.w-45 {
  width: 45%;
}

.image-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.scouting-title {
  float: left;
}

.scouting-logo {
  float: right;
}

.info-title {
  display: inline-block;
  width: 20%;
  text-align: start;
}

.info-value {
  display: inline-block;
  width: 80%;
}

.d-flex {
  display: flex;
}

.block-name {
  margin-top: 0;
  font-size: 16px;
}

.block-issues-info {
  margin-top: 0;
  font-size: 16px;
  margin-left: 20px;
  color: #666666;
}
