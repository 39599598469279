.sprayGrid {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.sprayGrid.forModal > div {
  padding: 0;
}

.sprayGrid.forModal > div + div {
  margin-top: 15px;
}
.sprayGrid.forModal :global(.ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column) {
  margin-top: 1rem;
}

.sprayGrid:not(.forModal) :global(.ui.segment).sprayTasksSidebarIn {
  flex: auto;
}

.sprayFormFooter {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.sprayFormContainer {
  overflow: auto;
  padding: 30px 15px;
}

.sprayFormContainer > div {
  max-width: 1200px;
  margin: 0 auto;
}

.scanButton {
  font-size: 25px !important;
  vertical-align: sub;
}

.hide {
  display: none;
}

@media only screen and (max-width: 767px) {
}
