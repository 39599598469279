.rowWrapper {
  margin-top: 24px;
  border: 1px solid #dededf;
  padding: 16px;
  gap: 30px;
}

.formField {
  width: 50%;
}

.formFieldWrapper {
  display: flex;
  width: 100%;
  gap: 14px;
  margin-top: 16px;
}

.totalField {
  width: 100% !important;
}

.label {
  color: #666666;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.btnWrapper {
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}
