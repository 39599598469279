@import '../../../assets/css/variables.css';

:global(.ui.grid).paginationHolder {
  padding-top: 15px;
}
.pagination {
  font-size: 120%;
  display: inline-flex;
  align-items: center;
}

.pagination :global(.ui.button) {
  font-weight: normal;
  color: var(--color-dark);
  font-size: 16px;
}

.pagesHolder :global(.ui.button.button-text),
.pagesHolder :global(.ui.button.button-text):hover {
  margin: 0 5px;
  padding: 3px 5px;
  border-radius: 3px !important;
  display: inline-block;
}

.pagination :global(.ui.button):hover {
  color: var(--color-green);
}
.pagesHolder :global(.ui.button).active {
  background-color: var(--color-row-stripped);
  font-weight: bold;
}

.pagesHolder :global(.ui.button).active:hover {
  background-color: var(--color-background-cell);
}

.nextPageHolder {
  padding-left: 5px;
}

:global(.ui.selection.dropdown).selectShort {
  margin-left: 10px;
  width: 65px;
  min-width: 65px;
}

:global(.ui.selection.dropdown).selectShort :global(.icon) {
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  opacity: 0.8;
  margin: -6px 0 0 0;
  top: 50%;
  transition: opacity 0.1s ease;
}

.totalItems {
  display: inline-block;
  margin-right: 25px;
}

.prevPageHolder :global(.ui.button.button-text),
.nextPageHolder :global(.ui.button.button-text) {
  color: var(--color-text);
}

.prevPageHolder :global(.ui.button.button-text),
.nextPageHolder :global(.ui.button.button-text),
.prevPageHolder :global(.ui.button.button-text):hover,
.nextPageHolder :global(.ui.button.button-text):hover {
  font-size: 21px;
  padding: 3px 0;
}

.prevPageHolder :global(.ui.button .icon),
.nextPageHolder :global(.ui.button .icon) {
  margin: 0 !important;
}

.separator {
  color: var(--color-border);
  display: inline-block;
  margin: 0 15px;
}

.selectorsHolder {
  display: inline-flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
}
