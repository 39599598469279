.sprayGrid {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.sprayFormFooter {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.sprayFormContainer {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.sprayFormContainer > div {
  max-width: 900px;
  margin: 0 auto;
}

:global(.ui.table).barcodesTable thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

:global(.ui.table).barcodesTable tbody td {
  white-space: nowrap;
}

:global(.ui.table).barcodesTable tbody td input {
  padding-left: 5px;
  padding-right: 5px;
}

.barcodesTable :global(.ui.dropdown > .text) {
  white-space: nowrap;
}

:global(.ui.form) .barcodesTable :global(.field .ui.input.labeled) input {
  width: 100%;
}

.barcodesRow {
  position: relative;
}

.barcodesRowActions {
  display: flex;
}

.printed {
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
}

@media (max-width: 767px) {
  .barcodesTableHolder .barcodesTable .title {
    display: block;
  }
  .barcodesTableHolder :global(.ui.table).barcodesTable {
    border: none;
  }

  .barcodesTableHolder :global(.ui.table).barcodesTable thead {
    display: none;
  }
  :global(.ui.table).barcodesTable thead th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
  }

  :global(.ui.table).barcodesTable tbody {
    display: block;
    position: relative;
  }

  :global(.ui.table).barcodesTable tbody tr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .barcodesTableHolder :global(.ui.table).barcodesTable tbody tr td {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
  }

  .barcodesTableHolder :global(.ui.table).barcodesTable tbody tr td:nth-child(1),
  .barcodesTableHolder :global(.ui.table).barcodesTable tbody tr td:nth-child(2),
  .barcodesTableHolder :global(.ui.table).barcodesTable tbody tr td:nth-child(3) {
    width: 33.33%;
  }
}
