@import '../../../assets/css/variables.css';

.tabsAndFilters > div + div {
  margin-top: 15px;
}

.filtersHolder {
}

:global(.ui.selection.dropdown).buttonFilters,
:global(.ui.labeled.icon.button).buttonFilters {
  min-width: 143px;
  padding: 0 10px 0 40px !important;
  color: var(--color-green);
  background: var(--color-light-grey);
  height: 30px;
  min-height: 30px;
  border: none;
  font-weight: bold;
  vertical-align: middle;
}

:global(.ui.selection.dropdown).buttonFilters > input {
  cursor: pointer;
  padding: 6px 10px !important;
  opacity: 0;
}

:global(.ui.selection.dropdown).buttonFilters > :global(.icon) {
  background: none;
}

:global(.ui.selection.dropdown).buttonFilters > :global(.menu) {
  min-width: 14rem;
}
:global(.ui.selection.dropdown).buttonFilters > :global(.text) {
  display: flex;
  min-height: inherit;
  align-items: center;
  cursor: pointer;
}

:global(.ui.selection.dropdown.active).buttonFilters .countFilters {
  display: none;
}

:global(.ui.selection.dropdown.active).buttonFilters {
  box-shadow: none;
  background: var(--color-light-grey);
}

:global(.ui.selection.dropdown.active).buttonFilters > input {
  opacity: 1;
}

:global(.ui.selection.dropdown.active).buttonFilters > :global(.icon),
:global(.ui.selection.dropdown.active).buttonFilters > :global(.text) {
  opacity: 0;
}
:global(.ui.selection.dropdown.active).buttonFilters :global(.ui.label) {
  padding: 0;
  background: none;
  margin-right: 0;
  color: var(--color-icon);
}
:global(.ui.dropdown .menu) > :global(.item).selected {
  display: inline-block;
}

:global(.ui.dropdown .menu) > :global(.item).checked {
  color: var(--color-grass);
}
:global(.ui.dropdown .menu) > :global(.item).checked + :global(.item):not(.checked) {
  margin-top: 15px;
}

.nothing {
  padding: 15px;
}
.filtersHolder :global(.ui.dropdown .menu).dropdownMenu {
  top: 100%;
  bottom: auto;
}
.filtersHolder :global(.ui.dropdown .menu).dateRangeHolder {
  max-width: 300px;
  min-width: auto;
}

.filtersHolder :global(.ui.dropdown .menu).selectItemsHolder:not(.dateRangeHolder):not(.switchMenu) {
  max-height: 350px;
  overflow: auto;
}

.filtersHolder :global(.ui.dropdown .menu).switchMenu {
  display: flex;
  position: inherit;
  margin: 0 !important;
  box-shadow: none;
}

.filtersHolder :global(.ui.dropdown .menu).switchMenu :global(.item) {
  flex: 1 1 auto;
}
.filtersHolder :global(.ui.dropdown .menu).switchMenu :global(.item.active) {
  background: rgba(0, 0, 0, 0.05);
}

.filterBox {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 0.28571429rem;
  background: var(--color-row-hover);
  font-weight: bold;
  height: 30px;
}

.filterBox :global(.ui.dropdown > .text) {
  color: var(--color-green);
}

.filterBox :global(> .ui.icon.button) {
  margin: 0;
}

.filterBox :global(> .ui.icon.button > .icon) {
  color: var(--color-green);
}

.filterHolder {
  padding: 1.14285714rem 0.78571429rem;
  cursor: default;
  width: 230px;
  font-weight: normal;
}

.filterHolder:hover {
  background: none;
}

.filterHolder :global(.ui.grid) {
  margin: 0;
}

.datePicker label > div:first-child {
  margin-bottom: 5px;
}

.datePicker + .datePicker {
  margin-top: 15px;
}

.inputHolder + .inputHolder {
  margin-top: 15px;
}

.inputHolder > label > div + div {
  margin-top: 5px;
}

.filterAdd {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

.countFilters {
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background: var(--color-background-green);
  border-radius: 100%;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.clearHolder {
  display: inline-block;
  margin-top: 16px;
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid var(--color-border);
  vertical-align: top;
}

.filtersHolder :global(.ui.dropdown .menu).selectItemsHolder {
  min-width: 300px;
}
.filtersHolder :global(.ui.dropdown).selectItems {
  padding: 10px 10px 0 10px;
  border: none;
  width: auto;
  box-shadow: none;
  white-space: normal;
}

.filtersHolder :global(.ui.dropdown).selectItems > :global(.icon),
.filtersHolder :global(.ui.dropdown).selectItems > :global(.sizer) {
  display: none;
}
.filtersHolder :global(.ui.dropdown).selectItems :global(.menu),
.filtersHolder :global(.ui.dropdown).selectItems :global(.menu):hover {
  width: auto;
  position: initial;
  display: block;
  max-width: none;
  overflow: initial;
  max-height: none;
  padding-right: 0;
  margin: 10px -10px 0 -10px !important;
  box-shadow: none !important;
  border: none;
}

.filtersHolder :global(.ui.dropdown .menu > .message) {
  margin: 0;
}

.filtersItems {
  display: block;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .filtersItems {
    display: inline-block;
    margin: 0;
    vertical-align: top;
  }
}
