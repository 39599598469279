.layout :global(.page) {
  height: 297mm;
  width: 210mm;
}

.layout :global(.block) {
}

.layout :global(.block *) {
}

.layout :global(.labelHolder) {
  height: 50%;
  width: 100%;
  padding: 15mm;
}

.layout :global(.barcodeDataHolder) {
}

.layout :global(.barcode) {
  /*height: 50mm;*/
  /*width: 50mm;*/
}

.layout :global(.textHolder) {
}

.layout :global(.text) {
  /*margin-top: 3px;*/
  /*font-size: 20px;*/
}

.layout :global(.packingShed) {
  /*font-size: 25px;*/
  /*font-weight: bold;*/
}
