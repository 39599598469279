.newProductRow {
  display: flex !important;
  padding: 0 !important;
  align-items: center !important;
  height: 40px !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
  margin-top: 8px !important;
}

.productHeader {
  font-weight: 500;
  padding-left: 0px !important;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 2px !important;
}

.firstRow {
  margin-top: 16px !important;
}

.addButtonContainer {
  width: 18%;
  text-align: end;
}

.buttonContainer {
  width: 100%;
  text-align: end;
}

.calendarWrapper {
  position: fixed;
}

.saveButton {
  padding: 0px !important;
  color: #0e9354 !important;
  background: none !important;
}

:global(.ui.button).disabledBtn {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

:global(.ui.basic.disabled.buttonActive) {
  border: 1px solid red !important;
}

:global(.ui.button).cancelBtn {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

:global(.ui.button).cancelDltBtn {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

:global(.ui.button).cancelDltBtn:hover {
  background-color: #156941;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
}

:global(.ui.button).deleteBtn {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).deleteBtn:hover {
  background: #8c0d24;
  border-radius: 4px;
  color: white;
}

.totalRowLabel {
  width: 28.8% !important;
  flex-grow: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.totalRowUnitsNumber {
  width: 27.6% !important;
  flex-grow: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.totalRowTotalQty {
  width: 13.5% !important;
  flex-grow: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.removeBatchNumber {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.batchNumberSection {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.batchNumberHeader {
  margin-bottom: 0 !important;
}

.batchNumberWrapper {
  height: 40px !;
}

.rowFirstLabel {
  padding-left: 3px !important;
  padding-bottom: 0px !important;
  font-style: normal;
  font-weight: 400;
  width: 17% !important;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.lastLabel {
  width: 5% !important;
}

.rowLabel {
  padding-bottom: 0px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.firstValueColumn {
  width: 17% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.valueColumn {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.newProductsButtonCantainer {
  width: 5% !important;
  display: flex !important;
}

.deleteButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #b00020 !important;
}

:global(.icon).deleteButton:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.deleteButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
  margin-bottom: 10px !important;
}

.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

:global(.ui.labeled.input > .label) {
  height: 40px !important;
}

:global(.ui.input > .test) {
  height: 40px !important;
}

.totalRowButtonWrapper {
  padding-top: 8px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

.totalRowWrapper {
  width: 82%;
  display: flex;
}

.deleteMessageWrapper {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  text-align: center !important;
  color: #212121 !important;
  flex: none !important;
  order: 0 !important;
  align-self: stretch !important;
  flex-grow: 0 !important;
}
.labelContaner {
  padding-bottom: 0px !important;
}
.deleteModal {
  margin-top: 13% !important;
}
