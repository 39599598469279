.listTable :global(.rt-table-holder) {
}

:not(.messageShow).listTable :global(.rt-table-holder) {
  overflow: auto;
  min-height: 150px;
}

.listTable :global(.rt-table),
.listTable :global(.rt-tbody) {
  overflow: visible;
}

.listTable :global(.rt-thead.-header) {
  box-shadow: none;
}

.listTable :global(.rt-thead.-headerGroups) + :global(.rt-thead.-header) {
  font-weight: bold;
}

.listTable :global(.rt-tbody .rt-tr-group) {
  border-bottom: 1px solid var(--color-border);
}

.listTable :global(.rt-tbody .rt-tr) {
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto !important;
}

.listTable :global(.rt-tbody .rt-tr.error),
.listTable :global(.rt-tbody .rt-tr.error:hover) {
  background: var(--color-error) !important;
}

.-striped.listTable :global(.rt-tbody .rt-tr.-odd) {
  background: var(--color-row-stripped);
}

.listTable :global(.rt-tbody .rt-td) {
  padding: 7px 5px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  flex: 100 0 auto;
  display: block !important;
}

.listTable :global(.rt-thead .rt-tr) {
  border-bottom: 1px solid var(--color-border);
  width: auto !important;
}

.listTable :global(.rt-thead .rt-th) {
  border: none;
  text-align: left;
  padding: 7px 5px;
  white-space: normal;
  color: var(--color-dark);
  font-size: 16px;
  flex: 100 0 auto;
  display: block !important;
}

.listTable :global(.rt-thead .rt-th.-sort-asc),
.listTable :global(.rt-thead .rt-th.-sort-desc) {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
}

.listTable :global(.cellTitle) {
  text-transform: uppercase;
  color: var(--color-header);
  font-size: 12px;
}

.listTable :global(.textRight) {
  text-align: right;
}

.listTable :global(.actionBlock button) {
  font-size: 18px;
  color: var(--color-icon);
}
.listTable :global(.actionBlock button .pencil) {
  color: var(--color-green);
}

.listTable :global(.cellValue) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.barHolderIn {
  display: flex;
  align-items: center;
}

.barHolderIn > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.filtersHolder {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.filterItem {
  font-size: 16px !important;
  margin-right: 10px;
  margin-bottom: 5px;
}

:global(.ui.selection.dropdown).filterItem {
  min-height: unset;
}

@media screen and (max-width: 767px) {
  :global(.ui.grid > .row > .column).sortColumn {
    padding-left: 0;
  }

  .listTable :global(.rt-thead),
  .listTable :global(.rt-tbody) {
    min-width: auto !important;
  }
  .listTable :global(.rt-tbody .rt-tr) {
    position: relative;
  }

  .listTable :global(.rt-tbody .rt-td.actions) {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .listTable :global(.rt-thead.-headerGroups) {
    display: none;
  }

  .listTable :global(.rt-thead.-header) {
    display: none;
  }

  .listTable :global(.rt-thead.-headerGroups) + :global(.rt-thead.-header) {
    display: flex;
  }

  .listTable :global(.rt-tbody .rt-td.cell_auto) {
    width: auto !important;
  }
  .listTable :global(.rt-tbody .rt-td.cell_1) {
    width: 6.25% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_2) {
    width: 12.5% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_3) {
    width: 18.75% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_4) {
    width: 25% !important;
  }
  .listTable :global(.rt-tbody .rt-td.cell_5) {
    width: 31.25% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_6) {
    width: 37.5% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_7) {
    width: 43.75% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_8) {
    width: 50% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_9) {
    width: 56.25% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_10) {
    width: 62.5% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_11) {
    width: 68.75% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_12) {
    width: 75% !important;
  }
  .listTable :global(.rt-tbody .rt-td.cell_13) {
    width: 81.25% !important;
  }
  .listTable :global(.rt-tbody .rt-td.cell_14) {
    width: 87.5% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_15) {
    width: 93.75% !important;
  }

  .listTable :global(.rt-tbody .rt-td.cell_16) {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .listTable :global(.rt-tbody .rt-tr) {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .listTable :global(.rt-tbody .rt-tr:hover) {
    background: var(--color-row-hover) !important;
  }
}
