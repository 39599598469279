@import '../../../../assets/css/variables.css';

.stepHolder {
  composes: stepHolder from '../AddNewFarm.module.css';
  background: var(--color-background-cell);
}

.stepContent {
  composes: stepContent from '../AddNewFarm.module.css';
}

.stepActions {
  composes: stepActions from '../AddNewFarm.module.css';
}

.stepCount {
  composes: stepCount from '../AddNewFarm.module.css';
}

.stepTitle {
  composes: stepTitle from '../AddNewFarm.module.css';
}

.verticalMiddle {
  composes: verticalMiddle from '../AddNewFarm.module.css';
}

.addNewHolder {
  margin-top: 15px;
}

.buttonsHolder {
}

.blockRemoveIcon {
  float: right;
}

.archiveToggle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.archiveToggle > span {
  padding-right: 10px;
  padding-left: 20px;
}

.stepContent :global(.fields + .field) {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .stepContent :global(.wide.field + .wide.field) {
    margin-top: 15px;
  }
}
