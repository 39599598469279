.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root {
  width: 370px !important;
}

.modalHeader {
  font-family: Roboto, sans-serif !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0;
  text-align: center;
  padding: 20px 20px 16px 20px;
}

:global(.ui.modal > .actions) {
  background-color: transparent;
}

:global(.ui.modal).root {
  width: 400px !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.grid) {
  margin: 0;
}

.minRateInput {
  position: relative;
}

.workersCount {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 12px;
  width: 100%;
}

.workersCountEdit {
  display: inline-block;
  position: absolute;
  top: 106px;
  left: 12px;
  width: 100%;
}

.workersDropdown :global(.ui.dropdown) {
  background-color: transparent;
}

.workersDropdown a {
  display: none !important;
}

.workerElementsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-y: auto;
}

.workerElement {
  background: #f5f7f7;
  margin: 5px;
  height: 24px;
  width: auto;
  text-align: center;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 1px 8px 0 12px;
}

.workerElement span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.workerElement i {
  color: #666666;
  margin-left: 12px;
  cursor: pointer;
}

.cancelButton {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.cancelButton:global(.ui.basic.green.button):hover {
  color: #ffffff !important;
  background-color: #156941 !important;
  border-color: transparent;
}

.saveButton:global(.ui.green.button) {
  background-color: var(--color-green) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 63px;
  height: 36px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.saveButton:global(.ui.green.button):hover {
  background-color: #156941 !important;
}

.saveButton:global(.ui.button:disabled) {
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.modalContentContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 220px;
}

:global(.ui.form) .selectWorkersFields {
  margin-bottom: 3px !important;
}
