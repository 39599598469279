@import '../../../../../assets/css/variables.css';

:global(.ui.header).name {
  margin: 0 130px 0 0;
}

.deleteButton {
  position: absolute;
  top: 4px;
}

.addHolder.wide {
  text-align: center;
}

:global(.ui.grid > .row).tableItem {
  padding-top: 5px;
  padding-bottom: 5px;
}

.inputHidden {
  display: none;
}
.inputHidden > :global(.ui.label) {
  white-space: nowrap !important;
}

@media screen and (max-width: 767px) {
}
@media screen and (min-width: 768px) {
}
