.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}
.root :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
:global(.ui.modal).confirmModal {
  margin: 30%;
}
.root :global(.ui.grid) {
  margin: 0;
}
.root :global(.content > :not(:first-child)) {
  margin-top: 16px !important;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}
.ui.grid > .row > .column.column-close {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}
.modalContentContainer {
  min-height: 70vh;
  scrollbar-width: none;
}

.modalContentContainer::-webkit-scrollbar {
  display: none;
}
