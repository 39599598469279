.reportWrapper {
  padding: 25px !important;
}

.reportHeaderRow {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.reportHeader {
  width: 930px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.logoContainet {
  width: 90px;
  height: 50px;
  margin-left: 12px !important;
}

.headerContainer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.totalHeader {
  height: 35px;
  border-bottom: 1px solid #dededf;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  vertical-align: text-bottom;
  padding-bottom: 4px !important;
}

.totalValue {
  height: 35px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  vertical-align: text-top;
  padding-top: 4px !important;
}

.tableHeaderCell {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
  background-color: white !important;
}

.tableBodyCell {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
}

.tableBodyExpectedInventoryCell {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #b00020 !important;
}
