.modalHeader {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

.formField {
  width: 33%;
}

.formSmallField {
  width: 16.5%;
}

.formSmallDropDown {
  max-width: 100% !important;
  margin: 0 !important;
}

.formSmallDropDown div {
  min-width: 16.5% !important;
}

.workersRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.workersRowHead {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.closeIcon {
  margin-bottom: 5px;
}

.editModalSegment {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}
.imageSegment {
  flex-wrap: wrap !important;
}
.imageSegmentHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #212121;
}

.headerContainer {
  display: flex !important;
  justify-content: space-between;
}

.deleteButton {
  background: none !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #b00020 !important;
}
.addImageContainer {
  width: 136px !important;
  height: 136px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px dashed #b3b3b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.ui.floating.label {
}
