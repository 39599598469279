.signatureModal {
  width: 440px !important;
}

.signatureModal_header {
  border-bottom: none !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  line-height: 33px !important;
  text-align: center !important;
}

.tabSection {
  display: flex;
  justify-content: center;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  display: flex;
  width: 60%;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.item) {
  color: black;
  text-align: center;
  flex: 1;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.item.active) {
  text-align: center;
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}

.typeIcon {
  width: 20px;
  height: 14px;
  padding-right: 8px;
}

.drawIcon {
  width: 22px;
  height: 15px;
  padding-right: 8px;
}

.content {
}

.signatureModal_footer {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.cancelButton {
  box-shadow: none !important;
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.cancelButton:hover {
  box-shadow: none !important;
  color: #ffffff !important;
  background: #156941 !important;
}

.saveButton {
  box-shadow: none !important;
  color: #ffffff !important;
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.saveButton:hover {
  box-shadow: none !important;
  background: #156941 !important;
}

.saveButton:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}
