.layout :global(.page) {
}

.layout :global(.pageBreak) {
  margin: 0;
  width: 80mm;
  height: 80mm;
  padding: 0;
}

.layout :global(.barcodeHolder) {
  margin: 1.5mm;
  width: 80mm;
  height: 80mm;
}

.layout :global(.barcodeDataHolder) {
  margin-top: 75px;
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.layout :global(.barcode) {
  height: 100%;
  width: 100%;
}

.layout :global(.textHolder) {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.layout :global(.text) {
  padding: 1px;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
}
