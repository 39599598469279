.layout :global(.pageBreak) {
  margin: 0;
  width: 70mm;
  height: 60mm;
  padding: 0;
}

.layout :global(.barcodeHolder) {
  margin: 1.5mm;
  width: 70mm;
  height: 60mm;
}

.layout :global(.barcodeDataHolder) {
  margin-top: 60px;
  transform: translate(50%, -13%);
  left: unset;
  top: unset;
}

.layout :global(.barcode) {
  height: 100%;
  width: 100%;
}

.layout :global(.textHolder) {
  margin-top: 5px !important;
  line-height: 6px;
  font-size: 6px;
}

.layout :global(.text) {
  padding: 1px;
  font-size: 14px !important;
  letter-spacing: 3px;
}
