.errorBar {
  margin: 0 0 15px 0;
}

:global(.icon-label) {
  display: flex;
  align-items: center;
}

:global(.icon-label > .icon) {
  color: var(--color-icon);
  font-size: 21px;
  text-align: center;
}

:global(.icon-label > div) {
  flex: auto;
}

:global(.ui.search.dropdown > .text) {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shedsDropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  min-width: 14em;
  min-height: 2.71428571em;
  background: #ffffff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}
