/* global app styles*/
html {
  scroll-behavior: smooth;
}

.relative-wrapper {
  position: relative;
}
.ui.grid.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}
.ui.grid .rt-table,
.ui.grid .rt-tbody {
  overflow: visible;
}

a {
  color: var(--color-green);
}

a:hover {
  color: var(--color-background-green);
}

.cursor-pointer,
a {
  cursor: pointer;
}

.square-image img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.square-image {
  padding-bottom: 100%;
  height: 0;
  position: relative;
}

.show-sm,
.show-md,
.show-lg,
.show-sm-inline,
.show-md-inline,
.show-lg-inline,
.show-sm-inline-block,
.show-md-inline-block,
.show-lg-inline-block {
  display: none !important;
}

.hide-xs {
  display: none !important;
}

.hide-sm,
.hide-md,
.hide-lg {
  display: block !important;
}

.hide-sm-inline,
.hide-md-inline,
.hide-lg-inline {
  display: inline-block !important;
}

.hide-sm-inline-block,
.hide-md-inline-block,
.hide-lg-inline-block {
  display: inline-block !important;
}
.ui.form .field > label {
  position: relative;
}

.ui.form .field > label > div + .ui,
.ui.form .field > label > div + input,
.ui.form .field > label > div + select,
.ui.form .field > label > div + textarea {
  margin-top: 5px;
}

.ui.button.button-text,
.ui.button.button-text:hover,
.ui.button.button-text:active {
  padding: 0;
  display: inline-block;
  width: auto;
  background: none;
  box-shadow: none;
}

.ui.button.button-text:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin-left: 0;
}

.ui.icon.input > i.icon.text {
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.primary.buttons .button,
.ui.primary.button {
  background: #28b573;
}
.ui.primary.buttons .button:hover,
.ui.primary.buttons .button.active,
.ui.primary.buttons .active.button:active,
.ui.primary.buttons .button:active,
.ui.primary.buttons .button:focus,
.ui.primary.button:hover,
.ui.primary.button:active,
.ui.primary.button:focus {
  background: #099254;
}

.ui.icon.button.transparent {
  padding: 0;
  background: transparent;
}

.ui.button.color-green {
  color: var(--color-green);
}

.ui.button.color-green:hover {
  color: var(--color-background-green-medium);
}

.ui.modal > .actions {
  background-color: var(--color-background);
}
.ui.selection.dropdown.select-rounded {
  border-radius: 1.35em;
}

.ui.selection.dropdown.select-rounded .menu {
  border-bottom-left-radius: 1.35em;
  border-bottom-right-radius: 1.35em;
}

.error.field .ui.pointing.label {
  margin-top: 6px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  color: red;
  background: #e8e8e8;
}

.ui.selection.dropdown.large {
  font-size: 1.14285714em;
}

.field.dropdown .ui.labeled.input > .label {
  padding-top: 0;
  padding-bottom: 0;
}
.field.dropdown .ui.labeled.input > .label .ui.dropdown.label {
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  /*
  border: 1px solid var(--color-border);
  border-left: none;

   */
}

.field.dropdown.error .ui.labeled.input > .label .ui.dropdown.label {
  border: 1px solid var(--color-border);
  border-left: none;
}

.field.dropdown .ui.labeled.input > .label .ui.dropdown.label > .text {
  flex: 1 1 auto;
}

.ui.form .error.field {
  position: relative;
}

.ui.form .error.field > .ui.label {
  /*white-space: nowrap;*/
}

.ui.selection.dropdown.small {
  min-height: 2em;
  padding-top: 0.67857143em;
  padding-bottom: 0.67857143em;
  font-size: 0.92857143em;
  line-height: 1.21428571em;
}

.field.dropdown .ui.labeled.input.small > .label .ui.dropdown.label {
  font-size: 0.92857143em;
}

.ui.form .field {
  margin-bottom: 0;
}

.ui.form .field.small textarea {
  font-size: 0.92857143em;
  padding-top: 0.67857143em;
  padding-bottom: 0.67857143em;
}

.ui.form .field .ui.input.labeled input {
  flex: 1 1 auto;
}

.ui.visible.overlay.sidebar {
  transform: none !important;
}

.ui.checkbox.check-box label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.checkbox.check-box label:before,
.ui.checkbox.check-box input:focus ~ label:before,
.ui.checkbox.check-box input:active ~ label:before {
  border-radius: 100%;
  width: 23px;
  height: 23px;
  border-color: var(--color-background-green-medium);
}

.ui.checkbox.check-box label:after,
.ui.checkbox.check-box input:focus ~ label:after,
.ui.checkbox.check-box input:active ~ label:after {
  font-family: tufIcons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: '\f104';
  opacity: 1;
  color: var(--color-background-green-medium);
  width: 23px;
  height: 23px;
  top: 5px;
  border-radius: 100%;
}

.ui.checkbox.check-box input:checked ~ label:before,
.ui.checkbox.check-box input:checked:focus ~ label:before,
.ui.checkbox.check-box input:checked:active ~ label:before {
  border-color: var(--color-green);
  background-color: var(--color-green);
}

.ui.checkbox.check-box input:checked ~ label:after,
.ui.checkbox.check-box input:checked:focus ~ label:after,
.ui.checkbox.check-box input:checked:active ~ label:after {
  content: '\f106';
  color: #fff;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .show-sm {
    display: block !important;
  }
  .show-sm-inline {
    display: inline !important;
  }
  .show-sm-inline-block {
    display: inline-block !important;
  }
  .hide-sm,
  .hide-sm-inline,
  .hide-sm-inline-block {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .show-md {
    display: block !important;
  }
  .show-md-inline {
    display: inline !important;
  }
  .show-md-inline-block {
    display: inline-block !important;
  }
  .hide-md,
  .hide-md-inline,
  .hide-md-inline-block {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .show-lg {
    display: block !important;
  }
  .show-lg-inline {
    display: inline !important;
  }
  .show-lg-inline-block {
    display: inline-block !important;
  }
  .hide-lg,
  .hide-lg-inline,
  .hide-lg-inline-block {
    display: none !important;
  }
}

.ui.label.colorOPEN {
  color: #fff;
  background: var(--color-open);
}

.ui.label.colorIN_PROGRESS {
  color: #fff;
  background: var(--color-in-progress);
}

.ui.label.colorCOMPLETED {
  color: #fff;
  background: var(--color-completed);
}

.ui.header {
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: var(--color-chapter);
}

.ui.striped.table tbody tr:nth-child(2n) {
  background: #f6f7f8;
}

.ui.selectable.table tbody tr:hover {
  background: #dff3ea !important;
}

.ui.segment {
  box-shadow: none;
  border: none;
}

.text-right {
  text-align: right;
}

.field-tiny {
  max-width: 100px;
}
.field-short {
  max-width: 150px;
}

.field-medium {
  max-width: 250px;
}

textarea {
  border-radius: 5px;
  padding: 10px 15px;
  resize: vertical;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

textarea:hover {
  border-color: rgba(34, 36, 38, 0.3);
}
textarea:focus,
.ui.input > input:focus,
.ui[class*='right labeled'].input > input:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: var(--color-background-green-light) !important;
  outline: none;
}

.tuf-holder .ui.toggle.checkbox input:checked ~ label:before {
  background: var(--color-background-green) !important;
}

.tuf-holder .ui.toggle.checkbox input:focus:checked ~ label:before {
  filter: grayscale(30%);
}

.tuf-holder .ui.dropdown .ui.label {
  background: var(--color-background-label);
  border: none;
  box-shadow: none;
  color: #fff;
}

.tuf-holder .ui.dropdown .ui.label:hover {
  background: var(--color-dark);
  color: #fff;
}

.tuf-holder .ui.dropdown .ui.label .icon {
  opacity: 1;
}

.tuf-holder .ui.table thead th,
.tuf-holder .ui.striped.table tbody tr:nth-child(2n) {
  background: var(--color-background-cell);
}

.field.error .ui.menu {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

@media print {
  .hide-print {
    display: none !important;
  }
}
