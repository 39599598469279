.InventoryModal_root__2WBp- :not(i.icon),
.InventoryModal_root__2WBp- .ui.header {
  font-family: Roboto, sans-serif !important;
}

.InventoryModal_root__2WBp- .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.InventoryModal_root__2WBp- .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.InventoryModal_root__2WBp- .ui.grid {
  margin: 0;
}

.InventoryModal_minRateInput__M-mTH {
  position: relative;
}

.InventoryModal_minRateInput__M-mTH:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.InventoryModal_minRateInput__M-mTH div {
  height: 40px !important;
}

.InventoryModal_maxRateInput__1lR36 div {
  height: 40px !important;
}

.InventoryModal_modalContentContainer__R40rn {
  min-height: 70vh;
  scrollbar-width: none;
}

.InventoryModal_modalContentContainer__R40rn::-webkit-scrollbar {
  display: none;
}

.InventoryModal_removeActiveIngredient__3p5L7 {
  color: #b00020;
  margin-right: 0 !important;
}

.InventoryModal_textButton__1LJOq.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.InventoryModal_textButton__1LJOq.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.InventoryModal_textButtonContainer__zfUBc {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.InventoryModal_ingredientHeader__3iK1R {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.InventoryModal_ingredientSection__2hD8F {
  margin-bottom: 8px !important;
  margin-top: 0px !important;
  display: flex;
  justify-content: space-between;
}

.InventoryModal_activeIngredientMessage__euF-s .ui.header {
  margin: 0 !important;
}

.InventoryModal_attachmentLabel__lLy4W {
  margin-right: auto !important;
}

.InventoryModal_generalAttachmentsContainer__33Y3D {
  display: flex !important;
  flex-wrap: wrap;
}

.InventoryModal_generalAttachmentsButton__ei5eB {
  margin-top: 5px !important;
  margin-left: auto !important;
}

.InventoryModal_root__2WBp- .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.InventoryModal_root__2WBp- .InventoryModal_chemicalPropertyColumn__2eZE2 {
  padding-left: 0 !important;
}

.InventoryModal_root__2WBp- .InventoryModal_chemicalPropertyColumn__2eZE2 .field {
  margin-bottom: 8px !important;
}
.InventoryModal_root__2WBp- .InventoryModal_activeIngredientChemicalPropertyColumn__2Il9N {
  padding-left: 0 !important;
}

.InventoryModal_root__2WBp- .InventoryModal_activeIngredientChemicalPropertyColumn__2Il9N .field {
  margin-bottom: 16px !important;
}

.InventoryModal_root__2WBp- .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.InventoryModal_confirmModal__oHwcz {
  margin: 30%;
}

.ui.modal.InventoryModal_root__2WBp- .actions {
  background-color: white !important;
}

.InventoryModal_cancelButton__2EbfN {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.InventoryModal_cancelButton__2EbfN:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.InventoryModal_saveButton___Y7AN {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.InventoryModal_saveButton___Y7AN:hover {
  background: #156941 !important;
}

.InventoryModal_saveButton___Y7AN:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.InventoryModal_trackInventoryButtonYes__3jV81 {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.InventoryModal_trackInventoryButtonNo__Sj9WI {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.InventoryModal_noButtonIcon__2njNi {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.InventoryModal_yesButtonIcon__1EP3Y {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.InventoryModal_poisonScheduleField__3Tof7 {
  margin-bottom: 16px;
}

.A4_65_Labels_38mm_x_21mm_layout__3WmX9 .pageBreak {
  margin: 0;
  width: 297mm;
  padding: 0.9mm 8.7mm 0.9mm 12.7mm;
}

.A4_65_Labels_38mm_x_21mm_layout__3WmX9 .barcodeHolder {
  margin: 1.75mm 0;
  height: 38.1mm;
  width: 21.2mm;
}

.A4_65_Labels_EU30019_layout__mqPva .pageBreak {
  margin: 0;
  width: 297mm;
  padding: 3.5mm 10.7mm 3mm 10.7mm;
}

.A4_65_Labels_EU30019_layout__mqPva .barcodeHolder {
  margin: 1.25mm 0;
  height: 38.1mm;
  width: 21.2mm;
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .block {
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .page {
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .pageBreak {
  margin: 0;
  width: 100mm;
  padding: 0;
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .barcodeHolder {
  margin-bottom: 1.35mm;
  margin-top: 1.35mm;
  margin-left: 1.35mm;
  margin-right: 1.35mm;
  height: 37mm;
  width: 22.3mm;
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .barcodeDataHolder {
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .barcode {
  /*height: 15mm;*/
  /*width: 15mm;*/
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .textHolder {
  /*margin-top: 3px;*/
  /*line-height: 8px;*/
  /*font-size: 8px;*/
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__3LaMQ .text {
  /*padding: 1px;*/
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .block {
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .page {
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .pageBreak {
  margin: 0;
  width: 78mm;
  padding: 0;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .barcodeHolder {
  margin-bottom: 1.5mm;
  margin-top: 1.5mm;
  margin-left: 1.5mm;
  margin-right: 1.5mm;
  height: 13mm;
  width: 23mm;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .barcodeDataHolder {
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .barcode {
  height: 11mm;
  width: 11mm;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .textHolder {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__2IwGt .text {
  padding: 1px;
}

.page_80mm_40mm_1_label_layout__3Q5F6 .page {
}

.page_80mm_40mm_1_label_layout__3Q5F6 .pageBreak {
  margin: 0;
  width: 80mm;
  height: 40mm;
  padding: 0;
}

.page_80mm_40mm_1_label_layout__3Q5F6 .barcodeHolder {
  margin: 1.5mm;
  width: 80mm;
  height: 40mm;
}

.page_80mm_40mm_1_label_layout__3Q5F6 .barcodeDataHolder {
  margin-top: 1px;
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_80mm_40mm_1_label_layout__3Q5F6 .barcode {
  height: 100%;
  width: 100%;
}

.page_80mm_40mm_1_label_layout__3Q5F6 .textHolder {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.page_80mm_40mm_1_label_layout__3Q5F6 .text {
  padding: 1px;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
}

.page_80mm_80mm_1_label_layout__1SaCQ .page {
}

.page_80mm_80mm_1_label_layout__1SaCQ .pageBreak {
  margin: 0;
  width: 80mm;
  height: 80mm;
  padding: 0;
}

.page_80mm_80mm_1_label_layout__1SaCQ .barcodeHolder {
  margin: 1.5mm;
  width: 80mm;
  height: 80mm;
}

.page_80mm_80mm_1_label_layout__1SaCQ .barcodeDataHolder {
  margin-top: 75px;
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_80mm_80mm_1_label_layout__1SaCQ .barcode {
  height: 100%;
  width: 100%;
}

.page_80mm_80mm_1_label_layout__1SaCQ .textHolder {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.page_80mm_80mm_1_label_layout__1SaCQ .text {
  padding: 1px;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
}

.page_70mm_60mm_1_label_layout__35-pq .pageBreak {
  margin: 0;
  width: 70mm;
  height: 60mm;
  padding: 0;
}

.page_70mm_60mm_1_label_layout__35-pq .barcodeHolder {
  margin: 1.5mm;
  width: 70mm;
  height: 60mm;
}

.page_70mm_60mm_1_label_layout__35-pq .barcodeDataHolder {
  margin-top: 60px;
  transform: translate(50%, -13%);
  left: unset;
  top: unset;
}

.page_70mm_60mm_1_label_layout__35-pq .barcode {
  height: 100%;
  width: 100%;
}

.page_70mm_60mm_1_label_layout__35-pq .textHolder {
  margin-top: 5px;
  line-height: 6px;
  font-size: 12px;
}

.page_70mm_60mm_1_label_layout__35-pq .text {
  padding: 1px;
  font-size: 14px !important;
  letter-spacing: 3px;
}

.page_40mm_28mm_1_label_layout__yupra .page {
}

.page_40mm_28mm_1_label_layout__yupra .pageBreak {
  margin: 1.5mm;
  width: 40mm;
  height: 28mm;
  padding: 0;
}

.page_40mm_28mm_1_label_layout__yupra .barcodeHolder {
  width: 40mm;
  height: 28mm;
}

.page_40mm_28mm_1_label_layout__yupra .barcodeDataHolder {
  width: 26mm;
  height: 38mm;
  margin-top: 1px;
  transform: translate(27%, -14%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_40mm_28mm_1_label_layout__yupra .barcode {
  height: 50%;
  width: 65%;
}

.page_40mm_28mm_1_label_layout__yupra .textHolder {
  margin-top: 1px;
  line-height: 4px;
  font-size: 6px;
}

.page_40mm_28mm_1_label_layout__yupra .text {
  padding: 0.05rem;
  font-size: 13px;
  margin-top: 8px;
  font-weight: bold;
}

.CustomEmployeesBarcodesPrintLayout_block__38hU- {
  box-sizing: border-box;
  justifycontent: space-around;
  display: grid;
}

.CustomEmployeesBarcodesPrintLayout_block__38hU- * {
  box-sizing: inherit;
}

.CustomEmployeesBarcodesPrintLayout_page__1sk2b {
}

.CustomEmployeesBarcodesPrintLayout_pageBreak__1LkG1 {
  margin: 0;
}

.CustomEmployeesBarcodesPrintLayout_barcodeHolder__l39nM {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.CustomEmployeesBarcodesPrintLayout_barcodeDataHolder__2zVlY {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.CustomEmployeesBarcodesPrintLayout_barcode__1hMb6 {
  height: 15mm;
  width: 15mm;
}

.CustomEmployeesBarcodesPrintLayout_textHolder__1KnJg {
  margin-top: 3px;
  line-height: 8px;
  font-size: 12px;
}

.CustomEmployeesBarcodesPrintLayout_text__3WZWO {
  padding: 1px;
}

@media screen {
  .CustomEmployeesBarcodesPrintLayout_pageBreak__1LkG1 {
    outline: 1px dotted black;
  }

  .CustomEmployeesBarcodesPrintLayout_barcodeHolder__l39nM {
    outline: 1px dotted black;
  }
}

@media print {
  .CustomEmployeesBarcodesPrintLayout_pageBreak__1LkG1:not(:last-child) {
    page-break-after: always;
  }
  .CustomEmployeesBarcodesPrintLayout_pageBreak__1LkG1:last-child {
    page-break-after: avoid;
  }
}

.EmployeesBarcodesPrintLayout_EmployeesBarcodesPrintLayout__3C2zg {
}

:root {
  /*--multiplier: 2;*/
  --multiplier: 1;
  /*Printer settings:*/
  /*100mm wide*/
  /*40mm high*/
  /*Speed 5cm / s*/
  /*Darkness Max*/
  /*Offset left - 3mm*/
  /*Offset top - 8mm*/
  /*[12:57, 3/6/2020] +61 400 353 278: Labels 23mm wide*/
  /*[12:57, 3/6/2020] +61 400 353 278: 37mm high*/
}

.EmployeesBarcodesPrintLayout_page__26ORx {
  width: calc(100mm * var(--multiplier));
  margin: 0;
}

.EmployeesBarcodesPrintLayout_block__T8EK3 {
  justifycontent: space-around;
  display: grid;
}

.EmployeesBarcodesPrintLayout_barcodeHolder__1Wzyb {
  /*margin-bottom: calc(2.7mm * var(--multiplier));*/
  margin-top: calc(4mm * var(--multiplier));
  margin-left: calc(1.35mm * var(--multiplier));
  margin-right: calc(1.35mm * var(--multiplier));
  /*height: calc(37mm * var(--multiplier));*/
  width: calc(22.3mm * var(--multiplier));
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.EmployeesBarcodesPrintLayout_barcodeDataHolder__1GxyS {
  text-align: center;
}

.EmployeesBarcodesPrintLayout_barcode__3UBqJ {
  height: calc(15mm * var(--multiplier));
  width: calc(15mm * var(--multiplier));
}

.EmployeesBarcodesPrintLayout_textHolder__luqlN {
  margin-top: calc(3px * var(--multiplier));
  line-height: calc(8px * var(--multiplier));
  font-size: calc(8px * var(--multiplier));
}

.EmployeesBarcodesPrintLayout_text__3MvMO {
  padding: calc(1px * var(--multiplier));
}

@media screen {
  .EmployeesBarcodesPrintLayout_barcodeHolder__1Wzyb {
    height: calc(37mm * var(--multiplier));
  }
}

@media print {
  .EmployeesBarcodesPrintLayout_pageBreak__1F7yi {
    display: block;
    page-break-before: always;
  }
}

.PalletsLabelPrintLayout_page__Zcl2t {
  margin: 0;
  line-height: normal;
}

.PalletsLabelPrintLayout_block__3g0V1 {
  box-sizing: border-box;
  justifycontent: space-around;
  display: grid;
}

.PalletsLabelPrintLayout_block__3g0V1 * {
  box-sizing: inherit;
}

.PalletsLabelPrintLayout_labelHolder__IikeD {
}

.PalletsLabelPrintLayout_barcodeDataHolder__1xR2T {
  text-align: center;
}

.PalletsLabelPrintLayout_barcode__3oTbu {
  height: 50mm;
  width: 50mm;
}

.PalletsLabelPrintLayout_textHolder__2JWGM {
  text-align: left;
}

.PalletsLabelPrintLayout_text__1J7gn {
  margin-top: 3px;
  font-size: 20px;
}

.PalletsLabelPrintLayout_dateText__2w3mr {
  margin-top: 3px;
  font-size: 25px;
}

.PalletsLabelPrintLayout_packingShed__1qr6C {
  font-size: 25px;
  font-weight: bold;
}

@media screen {
  .PalletsLabelPrintLayout_labelHolder__IikeD {
    outline: 1px dotted black;
  }
}

@media print {
  .PalletsLabelPrintLayout_pageBreak__1O_Od {
    display: block;
    page-break-before: always;
  }
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .page {
  height: 297mm;
  width: 210mm;
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .block {
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .block * {
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .labelHolder {
  height: 50%;
  width: 100%;
  padding: 15mm;
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .barcodeDataHolder {
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .barcode {
  /*height: 50mm;*/
  /*width: 50mm;*/
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .textHolder {
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .text {
  /*margin-top: 3px;*/
  /*font-size: 20px;*/
}

.A4_2_Labels_210mm_148mm_2_labels_per_pallet_layout__2DoHA .packingShed {
  /*font-size: 25px;*/
  /*font-weight: bold;*/
}

.page_80mm_48mm_1_label_per_page_layout__157nK .page {
  height: 48mm;
  width: 80mm;
}

.page_80mm_48mm_1_label_per_page_layout__157nK .block {
}

.page_80mm_48mm_1_label_per_page_layout__157nK .block * {
}

.page_80mm_48mm_1_label_per_page_layout__157nK .labelHolder {
  height: 100%;
  width: 100%;
  padding: 5mm;
  display: flex;
  flex-flow: row-reverse;
}

.page_80mm_48mm_1_label_per_page_layout__157nK .barcodeDataHolder {
}

.page_80mm_48mm_1_label_per_page_layout__157nK .barcode {
  height: 17mm;
  width: 17mm;
}

.page_80mm_48mm_1_label_per_page_layout__157nK .textHolder {
}

.page_80mm_48mm_1_label_per_page_layout__157nK .text {
  margin-top: 1px;
  font-size: 12px;
}

.page_80mm_48mm_1_label_per_page_layout__157nK .text.id {
  margin-top: 3px;
  font-size: 9px;
}

.page_80mm_48mm_1_label_per_page_layout__157nK .packingShed {
  margin-top: 1px;
  font-size: 14px;
  font-weight: bold;
}

.page_102mm_99mm_1_label_per_page_layout__353KV .page {
  height: 99mm;
  width: 102mm;
}

.page_102mm_99mm_1_label_per_page_layout__353KV .block {
}

.page_102mm_99mm_1_label_per_page_layout__353KV .block * {
}

.page_102mm_99mm_1_label_per_page_layout__353KV .labelHolder {
  height: 100%;
  width: 100%;
  margin-bottom: 2mm;
  padding: 5mm;
  justify-content: space-between;
  flex-flow: row-reverse;
}

.page_102mm_99mm_1_label_per_page_layout__353KV .barcodeDataHolder {
}

.page_102mm_99mm_1_label_per_page_layout__353KV .barcode {
  height: 30mm;
  width: 30mm;
}

.page_102mm_99mm_1_label_per_page_layout__353KV .textHolder {
}

.page_102mm_99mm_1_label_per_page_layout__353KV .text {
  margin-top: 1px;
  font-size: 20px;
}

.page_102mm_99mm_1_label_per_page_layout__353KV .text.id {
  margin-top: 3px;
  font-size: 17px;
}

.page_102mm_99mm_1_label_per_page_layout__353KV .packingShed {
  margin-top: 1px;
  font-size: 20px;
  font-weight: bold;
}

.Areas_expand__3M5WL {
  color: var(--color-green);
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
}

:not(.expanded).Areas_value__v5wEf {
  -webkit-line-clamp: 1 !important;
  min-height: unset !important;
}

:root {
  /*
   * colors
   * ======================================================================== */
  --color-background: #f5f7f7;
  --color-background-secondary: #d1dce3;
  --color-background-green: #0e9354;
  --color-background-green-light: #a4dfc4;
  --color-background-green-medium: #2cb673;
  --color-background-label: #677c8c;
  --color-chapter: #24303a;
  --color-dark: #5b666f;
  --color-middle: #9a9ca3;
  --color-light: #999;
  --color-light-grey: #e8e8e8;
  --color-grass: #758a00;
  --color-grass-meduim: #84bf41;
  --color-grass-light: #e2ecee;
  --color-border: rgba(34, 36, 38, 0.15);
  --color-icon: #99adbc;
  --color-background-cell: #f6f7f8;
  --color-background-button: #f3f3f3;
  --color-black: #000;
  --color-shadow: rgba(34, 36, 38, 0.15);
  --color-active: #6a6a6a;
  --color-title: #0c0033;
  --color-header: #9a9ca3;
  --color-input: rgba(0, 0, 0, 0.87);
  --color-text: #24303a;
  --color-brown-grey: #525252;
  --color-pink: #ea5151;
  --color-error: #e0b4b4;
  --color-green: #0e9354;
  --color-violet: #4000ff;
  --color-border-blue: #9cd0dc;
  --color-vacant-blue: #3b9eb5;
  --color-background-blue: #f4f8f9;
  --color-open: #d5dd2d;
  --color-completed: #099254;
  --color-in-progress: #50c1cf;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  --box-shadow-small: 0 0 3px rgba(0, 0, 0, 0.3);
  --box-shadow-mini: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  --box-shadow-flowly: 0 0 15px rgba(209, 220, 227, 0.5);
  --color-bg-pink: #f5efef;
  --color-border-sui: #a4dfc4;
  --color-border-sui-light: #d4f0e2;
  --color-table-header: #5b666f;
  --color-row-hover: #dff3ea;
  --color-row-stripped: #f6f7f8;
  --color-warning: #ffcc00;
  --color-secondary-button-background: #e0e1e2;
  --color-secondary-button-background-hover: #cacbcd;
  --color-actional-button: #59b655;
  --color-error-delete: #b00020;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.tuf-holder {
  height: 100%;
  color: #24303a;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.bold {
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.m-0 {
  margin: 0 !important;
}

.Splash_splashHolder__AIB8h {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.Splash_splashHolder__AIB8h > div {
  flex: 1 1 auto;
}

.Splash_logo__e-Wm1 {
  background: #fff url(/static/media/logo.ee6eb48f.svg) no-repeat center;
  background-size: contain;
  width: 200px;
  height: 150px;
  margin: 0 auto;
}

.Splash_message__2_4nh {
  max-width: 350px;
  padding: 15px 30px;
  text-align: center;
  margin: 0 auto;
}

.Splash_loader__3HZVZ {
  display: inline-block;
  margin: 0 15px 0 0;
  vertical-align: middle;
}

.Loader_loaderSpinner__34VrC {
  margin: 10px;
  width: 22px;
  height: 22px;
  font-size: 0;
  position: relative;
  border-radius: 50%;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
  border-right: 5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid #666;
  transform: translateZ(0);
  animation: Loader_load8__s3KeB 1.1s infinite linear;
}
.Loader_positionAbsolute__2dzPm {
  position: absolute;
  z-index: 11;
  left: 50%;
  top: 50%;
  margin: -11px 0 0 -11px;
  filter: brightness(0) invert(1);
}
@keyframes Loader_load8__s3KeB {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rw-main {
  color: #fff;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, #2bb673, #2bb673, #8dc63f);
  display: flex;
}

.rw-main h2 {
  margin: 0 0 10px 0;
  font-weight: 300;
  font-size: x-large;
  letter-spacing: 2px;
}

.rw-main .hr {
  width: 100%;
  height: 0;
  border-bottom: solid 1px #fff;
  opacity: 0.4;
  margin: 10px 0;
}

.rw-main p {
  margin: 0;
}

.rw-main.type-today {
  border-radius: 7px;
}

.rw-main.type-5days {
  border-radius: 7px 7px 0 0;
}

.rw-main .rw-box-left {
  padding: 25px;
  width: 60%;
}

.rw-main .rw-box-right {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 7px 7px 0;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rw-main .rw-box-right i {
  font-size: 50px;
}

.rw-main .rw-today .date {
  color: #b5def4;
}

.rw-main .rw-today .current {
  margin-top: 10px;
  line-height: 45px;
  font-size: 30px;
}

.rw-main .rw-today .range {
  color: #b5def4;
  font-size: 12px;
  margin: 10px 0 5px 2px;
}

.rw-main .rw-today .desc {
  color: #b5def4;
  font-size: 12px;
}

.rw-main .rw-today .desc i {
  font-size: 20px;
  color: #b5def4;
}

.rw-main .rw-today .info {
  color: #b5def4;
}

.rw-main .rw-today .info div {
  margin-bottom: 5px;
}

.rw-box-days {
  clear: both;
  display: flex;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  border-radius: 0 0 7px 7px;
  font-size: 11px;
  color: #777;
  background-color: #fff;
}

.rw-box-days .rw-day {
  box-sizing: border-box;
  height: 170px;
  width: calc(100% / 3);
  text-align: center;
  padding: 10px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.rw-box-days .rw-day:not(:first-child) {
  border-left: solid 1px #ddd;
}

.rw-box-days .rw-date {
  font-size: 9px;
  font-weight: bold;
}

.rw-box-days .rw-desc {
  margin: 10px 0 10px 0;
  font-size: 10px;
}

.rw-box-days .rw-range {
  font-size: 11px;
  position: absolute;
  bottom: 10px;
}

.rw-box-days .rw-info {
  margin-top: 10px;
  font-size: 12px;
}

.rw-box-days .wicon {
  margin-top: 15px;
  font-size: 35px;
  color: #8dc63f;
}

.rw-box {
  font-family: sans-serif;
  font-size: 13px;
}

:root {
  /*
   * colors
   * ======================================================================== */
  --color-background: #f5f7f7;
  --color-background-secondary: #d1dce3;
  --color-background-green: #0e9354;
  --color-background-green-light: #a4dfc4;
  --color-background-green-medium: #2cb673;
  --color-background-label: #677c8c;
  --color-chapter: #24303a;
  --color-dark: #5b666f;
  --color-middle: #9a9ca3;
  --color-light: #999;
  --color-light-grey: #e8e8e8;
  --color-grass: #758a00;
  --color-grass-meduim: #84bf41;
  --color-grass-light: #e2ecee;
  --color-border: rgba(34, 36, 38, 0.15);
  --color-icon: #99adbc;
  --color-background-cell: #f6f7f8;
  --color-background-button: #f3f3f3;
  --color-black: #000;
  --color-shadow: rgba(34, 36, 38, 0.15);
  --color-active: #6a6a6a;
  --color-title: #0c0033;
  --color-header: #9a9ca3;
  --color-input: rgba(0, 0, 0, 0.87);
  --color-text: #24303a;
  --color-brown-grey: #525252;
  --color-pink: #ea5151;
  --color-error: #e0b4b4;
  --color-green: #0e9354;
  --color-violet: #4000ff;
  --color-border-blue: #9cd0dc;
  --color-vacant-blue: #3b9eb5;
  --color-background-blue: #f4f8f9;
  --color-open: #d5dd2d;
  --color-completed: #099254;
  --color-in-progress: #50c1cf;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  --box-shadow-small: 0 0 3px rgba(0, 0, 0, 0.3);
  --box-shadow-mini: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  --box-shadow-flowly: 0 0 15px rgba(209, 220, 227, 0.5);
  --color-bg-pink: #f5efef;
  --color-border-sui: #a4dfc4;
  --color-border-sui-light: #d4f0e2;
  --color-table-header: #5b666f;
  --color-row-hover: #dff3ea;
  --color-row-stripped: #f6f7f8;
  --color-warning: #ffcc00;
  --color-secondary-button-background: #e0e1e2;
  --color-secondary-button-background-hover: #cacbcd;
  --color-actional-button: #59b655;
  --color-error-delete: #b00020;
}

.ui.slide.along.sidebar {
  position: fixed;
  background: var(--color-grass-light);
}
.ui.slide.along.sidebar {
  z-index: 3;
}

.MenuLeft_menuHolder__1BLB3 {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
  overflow: auto;
}

.ui.vertical.menu .MenuLeft_menuHolder__1BLB3 .active.item {
  background: none;
}

.ui.vertical.menu .MenuLeft_menuHolder__1BLB3 .active.item a,
.ui.vertical.menu .MenuLeft_menuHolder__1BLB3 .active.item a:hover {
  color: var(--color-background-green);
  background: var(--color-background);
  margin: 0 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.ui.vertical.menu .MenuLeft_menuHolder__1BLB3 .active.item a .icon {
  color: var(--color-grass-meduim);
  font-size: 18px;
}

.column-left .ui.icon.button.MenuLeft_buttonClose__1789j {
  width: auto;
  padding: 5px;
  min-height: auto;
  display: block;
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 5;
  background: none;
  font-size: 16px;
}

.ui.menu .MenuLeft_menuGroup__1wlqG {
  margin: 10px 0;
}
.ui.menu .MenuLeft_menuGroup__1wlqG:first-child {
  margin-top: 0;
}
.ui.menu .MenuLeft_menuGroup__1wlqG.MenuLeft_growAuto__2vova {
  flex: 1 1 auto;
}
.ui.menu .MenuLeft_menuGroup__1wlqG.MenuLeft_support__1l1BU {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  margin: 0;
}
.ui.menu .MenuLeft_menuGroup__1wlqG .item.MenuLeft_menuLink__1LkWL {
  padding: 0;
}
.ui.menu .MenuLeft_menuGroup__1wlqG .item.MenuLeft_menuLink__1LkWL:before {
  display: none;
}

.ui.menu .MenuLeft_menuGroup__1wlqG .MenuLeft_menuLink__1LkWL a {
  display: flex;
  padding: 15px 25px;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600;
  line-height: 1;
  align-items: center;
  transition: all ease-in-out 0.2s;
  height: 45px;
}

.ui.menu .MenuLeft_menuGroup__1wlqG .MenuLeft_menuLink__1LkWL a.MenuLeft_menuDisabled__dSeN8 {
  cursor: default;
}

.ui.menu .MenuLeft_menuGroup__1wlqG .MenuLeft_menuLink__1LkWL a:not(.MenuLeft_menuDisabled__dSeN8):hover {
  color: var(--color-background-green);
}

.ui.menu .MenuLeft_menuGroup__1wlqG .MenuLeft_menuLink__1LkWL a .icon {
  margin: 0 5px 0 0;
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
}
.ui.menu
  .MenuLeft_menuGroup__1wlqG
  .MenuLeft_menuLink__1LkWL
  a:not(.MenuLeft_menuDisabled__dSeN8):hover
  .icon {
  transition: all ease-in-out 0.2s;
  color: var(--color-grass-meduim);
}

.MenuLeft_icon__1VXUy {
  font-size: 21px;
}

.MenuLeft_menuGroup__1wlqG .MenuLeft_groupTitle__1CSBK {
  color: var(--color-chapter);
  text-transform: uppercase;
  margin: 10px 0;
  padding-left: 30px;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "sans-serif", " Montserrat", " Lato", " Helvetica Neue",
    Arial, Helvetica;
  font-weight: 600;
}

.MenuLeft_menuGroup__1wlqG .MenuLeft_groupTitle__1CSBK:before {
  position: absolute;
  content: "";
  top: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(34, 36, 38, 0.1);
}

.MenuLeft_ui__1y-a6.MenuLeft_menu__1cBRv .MenuLeft_menuGroup__1wlqG + .MenuLeft_menuGroup__1wlqG {
  margin-top: 30px;
}

.MenuLeft_dropdownItem__2Zqrx {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "sans-serif", " Montserrat", " Lato", " Helvetica Neue",
    Arial, Helvetica;
}
.MenuLeft_pendingOperationCount__35s51 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #f0ac47;
  font-weight: normal;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  color: white;
  margin-left: 12px;
  font-size: 11px;
  padding-top: 1px;
  padding-right: 2px;
}

.ui.menu .MenuLeft_menuGroup__1wlqG.MenuLeft_support__1l1BU .MenuLeft_groupTitle__1CSBK:before {
  display: none;
}

.ui.menu .MenuLeft_menuGroup__1wlqG.MenuLeft_support__1l1BU .MenuLeft_menuLink__1LkWL a .icon {
}
.MenuLeft_menuLink__1LkWL {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "sans-serif", " Montserrat", " Lato", " Helvetica Neue",
    Arial, Helvetica !important;
}

@media screen and (min-width: 768px) {
  .MenuLeft_menuHolder__1BLB3 {
    padding-top: 40px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

.ui.dropdown.UserMenuTop_userDropdown__3Bwv4 .menu > .item {
  display: flex;
  align-items: center;
}

.ui.dropdown.UserMenuTop_userDropdown__3Bwv4 .icon {
  height: auto;
}

.ui.dropdown.UserMenuTop_userDropdown__3Bwv4 .icon svg {
  max-width: 100%;
}

.UserMenuTop_avatarHolder__67Ili {
  display: flex;
  align-items: center;
}

.UserMenuTop_avatarHolder__67Ili:hover {
  color: var(--color-background-green);
}

.UserMenuTop_avatarHolder__67Ili .ui.avatar.image {
  width: 32px;
  height: 32px;
  margin: 0;
}

.UserMenuTop_avatar__3O5Jz {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: var(--color-background-secondary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.UserMenuTop_avatar__3O5Jz .icon {
  margin-right: 0;
}

.UserMenuTop_menuGroup__1BdZj {
  padding-top: 6px;
}

.UserMenuTop_menuGroup__1BdZj i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.UserMenuTop_menuGroup__1BdZj a {
  color: #212121;
}

.UserMenuTop_headerMenuWrapper__kBg_C {
  display: flex;
  align-items: center;
}

.logo-tuf {
  background: url(/static/media/logo.ee6eb48f.svg) no-repeat left;
  background-size: contain;
  height: 35px;
}

.ui.basic.segment.header-main {
  margin-bottom: 0;
  box-shadow: var(--box-shadow);
  z-index: 2;
}

.vertical-middle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  /* justify-content: flex-end; */
  padding-right: 33px !important;
}

.vertical-middle.center {
  justify-content: center;
}

.vertical-middle.right {
  justify-content: flex-end;
}

.column-menu {
  border-right: 1px solid var(--color-border);
  min-width: 56px;
}

.column-menu .ui.icon.button {
  background: none;
  font-size: 18px;
  height: inherit;
}

.column-bell {
  position: relative;
  text-align: center;
}

.column-bell .ui.icon.button {
  background: none;
  padding: 5px;
  color: var(--color-icon);
}

.logo-holder {
  max-height: 35px;
  padding: 0;
}

.header-main .search-input {
  width: 100%;
}

.header-main .search-input .ui.input {
  width: 100%;
}

.header-main .search-input .ui.input .prompt {
  border-radius: 0.5rem;
}

.ui.grid > .row > .column.column-search {
  padding-right: 2rem;
}

.column-search-button {
  text-align: center;
}

.column-search-button .ui.icon.button {
  padding: 5px;
  background: none;
  font-size: 18px;
  color: var(--color-icon);
}

.ui.grid > .row > .column.column-user {
  padding-left: 2rem;
}

.status-offline {
  font-size: 18px;
  color: var(--color-middle);
}

.status-offline:not(:first-child) {
  margin: 0 15px;
}

@media screen and (max-width: 992px) {
  .status-offline {
    font-size: 12px;
  }

  .ui.grid > .row > .column.column-search {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    margin: 1px 0 0 0;
    padding: 15px;
    z-index: 10;
    background-color: var(--color-background-cell);
    float: none;
    width: auto !important;
    border-bottom: 1px solid var(--color-border);
  }

  .ui.grid > .row > .column.column-search.open {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .ui.segment.header-main {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ui.basic.segment.header-main > .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ui.basic.segment.header-main > .ui.grid > .row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ui.basic.segment.header-main > .ui.grid > .row > div {
    min-height: 64px;
  }

  .logo-holder {
    margin: 7px 0 0 0;
    max-height: 48px;
    padding-left: 40px;
  }

  .logo-tuf {
    height: 48px;
    background-position: left center;
  }

  .logo-column {
    min-width: 260px;
  }
}

@media screen and (min-width: 992px) {
  .column-bell:before {
    position: absolute;
    content: "";
    top: -1rem;
    left: 0;
    bottom: -1rem;
    width: 1px;
    background: var(--color-border);
  }

  .column-bell:after {
    position: absolute;
    content: "";
    top: -1rem;
    right: 0;
    bottom: -1rem;
    width: 1px;
    background: var(--color-border);
  }
}

@media screen and (min-width: 1200px) {
}

.Layout_layoutHolder__7ZwGW {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Layout_layoutHolder__7ZwGW > div {
  flex: 1 1 auto;
}
.ui.left.slide.along.sidebar.column-left,
.ui.sidebar.column-left {
  visibility: visible;
  transition: all 0.5s ease;
  transform: translate3d(-100%, 0, 0);
  border: none;
}
.ui.left.slide.along.sidebar.column-left.visible,
.ui.sidebar.column-left.visible {
  transform: translate3d(0, 0, 0);
  z-index: 5;
}

.ui.left.sidebar.column-left ~ .pusher {
  position: relative;
}

.ui.left.sidebar.column-left ~ .pusher:after {
  transition: opacity ease-in-out 0.5s;
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
}

.ui.visible.left.sidebar.column-left ~ .pusher {
  transition: all 0.5s ease;
  transform: translate3d(0, 0, 0);
}

.ui.visible.left.sidebar.column-left ~ .pusher:after {
  opacity: 1;
  bottom: 0;
}

.Layout_columnMain__m1yIU {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.ui.segment.Layout_mainHolder__3U819 {
  margin-top: 0;
  flex: 1 1;
  overflow: auto;
  background: var(--color-background);
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .ui.left.slide.along.sidebar.column-left,
  .ui.sidebar.column-left {
    transform: translate3d(0, 0, 0);
  }
  .ui.left.sidebar.column-left ~ .pusher,
  .ui.visible.left.sidebar.column-left ~ .pusher {
    padding-right: 260px;
    transform: translate3d(260px, 0, 0);
  }

  .ui.visible.left.sidebar.column-left ~ .pusher:after {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
}

.ui.segment.Dashboard_promptHolder__3eaCF {
  background: #ffffff;
  height: 70vh;
  border-radius: 5px;
}

.ui.segment.Dashboard_promptHolderRight__2a8R5 {
  padding: 0;
  background: #ffffff;
  border-radius: 5px;
}

.ui.segment.Dashboard_promptHolderRight__2a8R5 .rw-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  flex: 1 1 auto;
}

.ui.segment.Dashboard_promptHolderRight__2a8R5 .rw-main.type-5days {
  flex: 1 1 auto;
}

.ui.segment.Dashboard_promptHolderRight__2a8R5 .rw-main.type-5days,
.ui.segment.Dashboard_promptHolderRight__2a8R5 .rw-box-days {
  border-radius: 0;
}

.ui.segment.Dashboard_promptHolder__3eaCF > div[id="farm-map"] {
  height: 100%;
}

.Dashboard_blocksCount__3Qtkp {
  margin-top: 5px;
}

.Dashboard_blocksCount__3Qtkp span {
  color: var(--color-title);
}

.ui.table.Dashboard_tableSummary__1hWmo {
  min-width: 700px;
}

.ui.table.Dashboard_tableSummary__1hWmo thead tr th {
  font-weight: normal;
}

.ui.table.Dashboard_tableSummary__1hWmo tbody tr td {
  border-bottom: none;
  border-top: none;
}

.Dashboard_tableHolder__2EiIT {
  overflow: auto;
  margin-top: 20px;
}

.ui.label.Dashboard_colorOPEN__1MHCH {
  color: #fff;
  background: var(--color-open);
}

.ui.label.Dashboard_colorIN_PROGRESS__3Dq5D {
  color: #fff;
  background: var(--color-in-progress);
}

.ui.label.Dashboard_colorCOMPLETED__3ByKx {
  color: #fff;
  background: var(--color-completed);
}

.Dashboard_vacant__1krOc {
  font-style: italic;
  color: var(--color-vacant-blue);
}

.ui.header.Dashboard_farmName__3VrtJ {
  cursor: pointer;
}

.ui.header.Dashboard_farmName__3VrtJ:hover {
  color: var(--color-green);
}

.Dashboard_farmSelector__1Z9JG {
  width: calc(100% - 100px);

  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 100;

  display: flex;
  flex-direction: row;
}

.Dashboard_mapFilter__3D3fk {
  top: 50px;
  left: unset;
}

@media screen and (min-width: 768px) {
  .ui.segment.Dashboard_promptHolderRight__2a8R5 {
    min-height: 580px;
    height: 60vh;
  }

  .ui.segment.Dashboard_promptHolder__3eaCF {
    min-height: 580px;
    height: 60vh;
  }
}

@media screen and (min-width: 580px) {
  .Dashboard_mapFilter__3D3fk {
    top: unset;
    left: 230px;
  }
}

.Dashboard_filterButton__2jK4C {
  display: flex !important;
  align-items: center !important;
  height: 40px;
  padding: 10px 10px 10px 10px !important;
  background: #ffffff;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  width: 220px;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  justify-content: space-between;
}
.Dashboard_filterButton__2jK4C > input.search {
  display: none !important;
}
.Dashboard_filterButton__2jK4C > .sizer {
  display: none !important;
}

.Dashboard_filterButton__2jK4C > .text {
  display: contents !important;
  margin: 0 !important;
  padding-right: 14px !important;
}
.Dashboard_filterButton__2jK4C > .filtered.text {
  visibility: inherit !important;
}

.Dashboard_filterButton__2jK4C > i.icon {
  position: relative;
  margin-right: 0;
  padding-bottom: 20px;
}

.Dashboard_farmFilterMenu__s2dfy {
  min-height: 290px !important;
  max-height: none !important;
}
.Dashboard_scrolingMenu__F6N1k {
  min-height: 200px;
}

.Dashboard_activeFilterDropDownIcons__2SyT0 {
  display: inline-flex;
  align-items: center;
  justify-content: space-between !important;
  width: 75%;
}
.Dashboard_filterDropDownIcons__1uYTf {
  justify-content: flex-end;
}
.Dashboard_filterDropDownIcons__1uYTf span {
  margin-right: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: #c5bbbb;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.Dashboard_filterDropDownIcons__1uYTf i {
  margin-bottom: 5px !important;
}

.ui.segment.SegmentClosable_segmentHolder__2T8x1 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.SegmentClosable_headerHolder__3HFLu {
  display: flex;
}

.SegmentClosable_chevron__2sQRY {
  width: 20px;
  height: 20px;
  font-size: 21px;
  display: inline-block;
  color: var(--color-icon);
  margin: 3px 20px 0 0;
  cursor: pointer;
}

.SegmentClosable_chevron__2sQRY:hover {
  color: var(--color-green);
}

.ui.header.SegmentClosable_headerTitle__3cn5C {
  margin: 0;
  line-height: 1;
}

.SegmentClosable_childrenHolder__39d9K {
  height: 0;
  overflow: hidden;
}

.SegmentClosable_childrenHolder__39d9K.SegmentClosable_open__lfPYt {
  margin-top: 1rem;
  height: auto;
  overflow: visible;
}

.SegmentClosable_childrenHolder__39d9K > .ui.grid:first-child {
  margin-top: -0.5rem !important;
}

.SegmentClosable_childrenHolder__39d9K .ui.grid + .ui.grid {
  margin-top: 0;
}

/* ALTER */

.ui.segment.SegmentClosable_segmentHolder__2T8x1.SegmentClosable_alter__1q63F {
  background-color: #edf0f1 !important;
}

.SegmentClosable_alter__1q63F .ui.header.SegmentClosable_headerTitle__3cn5C {
  padding-top: 7px;
}

.SegmentClosable_alter__1q63F .SegmentClosable_headerHolder__3HFLu {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.GoogleMaps_infoHolder__4Tm9c {
  width: 240px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
}

.GoogleMaps_infoHolder__4Tm9c > div {
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
}

.GoogleMaps_infoHolder__4Tm9c .GoogleMaps_blockName__1ygC3 {
  color: var(--color-grass);
}

.GoogleMaps_active__2V6rq {
  color: var(--color-pink);
}

.GoogleMaps_infoHolder__4Tm9c .GoogleMaps_buttonCreate__2ERcO {
  margin-top: 20px;
}

.GoogleMaps_cropName__1CQ6I {
  margin-top: 10px;
}

.GoogleMaps_vacant__1lIDl {
  margin-left: 5px;
  font-style: italic;
  font-size: 12px;
  color: var(--color-vacant-blue);
}

.GoogleMaps_scanCard__31hRR {
  width: 215px;
  float: left;
  margin-right: 10px;
}

.GoogleMaps_infoField__2UZds {
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}

.GoogleMaps_infoField__2UZds b {
  font-weight: bold;
  width: 40%;
  display: inline-block;
}

.GoogleMaps_infoField__2UZds span {
  width: 60%;
  font-weight: normal;
  white-space: break-spaces;
}
.GoogleMaps_rowWrapper__1RLM2 {
  display: flex;
  justify-content: space-between;
}
.GoogleMaps_label__CXgDx {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  flex: 1 1;
}
.GoogleMaps_headerLabel__2mOpI {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  flex: 1 1;
}
.GoogleMaps_value__1K-2i {
  flex: 1 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  margin-left: 20px;
}
.GoogleMaps_gm-ui-hover-effect__3g0Vy {
  display: none !important;
}

.GoogleMaps_binsWeightHolder__30cxX {
  width: 260px;
  padding-bottom: 10px;
}

.GoogleMaps_headerRowWrapper__39lI2 {
  margin-bottom: 10px;
}

.ImageSlider_holder__2R6yn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f7f7f7;
}

.ImageSlider_photo__2vtbQ {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;

  overflow: hidden;
}

.ImageSlider_photo__2vtbQ > img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.ImageSlider_active__XJJ3j {
  opacity: 1;
}

.ImageSlider_buttonsHolder__3NyZt {
  margin: auto;

  width: 100%;
  height: 20px;

  z-index: 99;
  cursor: pointer;
}

.ImageSlider_arrowLeft__2Y_UL,
.ImageSlider_arrowRight__iSiJw {
  opacity: 0.7;
  height: 20px;
  width: 20px;
  display: block;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.ImageSlider_arrowLeft__2Y_UL:hover,
.ImageSlider_arrowRight__iSiJw:hover {
  opacity: 1;
}

.ImageSlider_arrowLeft__2Y_UL {
  float: left;
}

.ImageSlider_arrowRight__iSiJw {
  float: right;
}

.ImageSlider_caption__ONlVz {
  width: 100%;
  display: inline-block;
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.gm-control-active,
.gmnoprint {
  opacity: 0.5;
}

.gmnoprint > .gm-control-active {
  opacity: 1;
}

.gm-control-active:hover,
.gmnoprint:hover {
  opacity: 1;
  background: black;
}

.cluster img {
  width: 100%;
  height: auto;
}

.cluster div {
  width: 100% !important;
  height: 100% !important;
  line-height: 30px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: white !important;
}

.MapFilter_dropdown__2Ebnk {
  width: 220px;

  position: absolute;
  height: 40px;
  padding: 9px 5px 10px 10px;

  background: #ffffff;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
}

.MapFilter_opened__2y0Ip {
  height: unset;
}

.MapFilter_headingRow__1AfPZ {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MapFilter_dropdown__2Ebnk:hover {
  border-color: rgba(34, 36, 38, 0.35);
}

.MapFilter_opened__2y0Ip .MapFilter_title__23yRJ {
  color: var(--color-completed);
}

.MapFilter_hr__3n8Fe {
  color: var(--color-border);
}

.MapFilter_checkbox__1uAd9 {
  display: flex !important;
}

.MapFilter_titleWrapper__2SW7n {
  display: flex;
  align-items: center;
}

/* .wrapper {
  position: relative;
}

.startDate {
  text-align: center;
  position: absolute;
  width: 77px;
  top: 10px;
  left: 15px;
}

.endDate {
  text-align: center;
  position: absolute;
  width: 77px;
  top: 10px;
  right: 15px;
}

.dateRangePicker {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;

  display: flex;
}



.dateRangePicker input {
  color: transparent !important;
} */

.DateRangePicker_inputHolder__1k793 {
  background-color: white;
  position: relative;
  display: block;
}

.DateRangePicker_calendarHolder__1oCZN {
  background: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--color-border);
  width: 250px;
}

.DateRangePicker_calendarHolder__1oCZN.DateRangePicker_right__39Vlg {
  left: auto;
  right: 0;
}

.DateRangePicker_calendarHolder__1oCZN .react-calendar {
  border: none;
}

.DateRangePicker_calendarHolder__1oCZN .react-calendar .react-calendar__tile--active {
  background-color: var(--color-background-green);
}

.DateRangePicker_buttonHolder__1LQ_y {
  text-align: center;
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.DateRangePicker_buttonHolder__1LQ_y button:hover {
  color: var(--color-error-delete) !important;
}

.DateRangePicker_datePickerInput__LGanp {
  height: 40px !important;
}

.DateRangePicker_datePickerInput__LGanp input {
  font-size: 12px;
}

.DatePicker_inputHolder__3cP34 {
  background-color: white;
  position: relative;
  display: block;
}

.DatePicker_calendarHolder__3AvbB {
  background: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--color-border);
  width: 250px;
}
.DatePicker_calendarHolder__3AvbB.DatePicker_right__26S-y {
  left: auto;
  right: 0;
}

.DatePicker_calendarHolder__3AvbB .react-calendar {
  border: none;
}

.DatePicker_calendarHolder__3AvbB .react-calendar .react-calendar__tile--active {
  background-color: var(--color-background-green);
}

.DatePicker_buttonHolder__1dZh4 {
  text-align: center;
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.DatePicker_buttonHolder__1dZh4 button:hover {
  color: var(--color-error-delete) !important;
}
.DatePicker_datePickerInput__1LHhu {
  height: 40px !important;
}

.ui.button.ExportToExcel_exportButton__3RiEQ > i.icon {
  position: relative;
  top: -1px;
  color: var(--color-green);
}

.ui.button.button-text.ToolTip_buttonMore__18hZ_ {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 0;
}

.ui.button.button-text.ToolTip_buttonMore__18hZ_:hover {
  background: none;
}

.ui.basic.segment.ToolTip_tasksListRow__UYRBf {
  color: var(--color-title);
  border-top: 1px solid var(--color-border);
}

.ui.basic.segment.ToolTip_tasksListRow__UYRBf:last-child {
  border-bottom: 1px solid var(--color-border);
}

.ui.grid.ToolTip_tasksListRowGrid__1KfxT {
}

.ui.button.ToolTip_readFull__ql7jf {
  color: var(--color-violet);
  margin-top: 10px;
  margin-bottom: 15px;
}

.ui.grid > .row > .column.ToolTip_columnActions__3GbSf {
  position: absolute;
  top: 15px;
  right: 15px;
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
}

.ToolTip_printHolder__3bgKM {
  display: none;
}

.ToolTip_columnBlock__2utV9,
.ToolTip_columnSize__3QbBn,
.ToolTip_columnProgress__2NwWY {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ToolTip_hasDone__1g6W7 div {
  padding-left: 15px;
}

.ToolTip_progressHolder__2A9ad {
  max-width: 60px;
}

.ToolTip_statusOPEN__1f4IF {
  color: #ffc000;
}

.ToolTip_statusIN_PROGRESS__39FWa {
  color: #3b5998;
}
.ToolTip_statusCOMPLETED__KC_J9 {
  color: #afc644;
}

.ToolTip_rowOPEN__245lL {
  background: #dbe7f6;
  font-weight: bold;
}

i.icon.ToolTip_iconEye__3kScG {
  font-size: 18px;
  margin-top: 5px;
  display: block;
}

.ToolTip_employeeIcons__3w959 {
  display: inline-block;
}

.ToolTip_plusMore__36g_1 {
  display: inline-block;
  font-weight: bold;
}

.ToolTip_employeeIcon__EwCr3 {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  background: var(--color-background-cell);
  border: 1px solid var(--color-border);
  border-radius: 50%;
}

.ToolTip_employeeIconOverlay__3s5jp {
  float: left;
  margin-left: -17px;
}

.ToolTip_chemicalName__1Jzhd,
.ToolTip_blockName__2QSj3 {
  overflow-wrap: break-word;
  position: relative;
}

.ToolTip_blockName__2QSj3 i.icon {
  position: absolute;
  left: -5px;
  top: 0;
}

.ToolTip_newTask__5jWsw {
  background: var(--color-grass-light);
}

@media screen and (min-width: 768px) {
  .ToolTip_hasDone__1g6W7 div {
    padding-left: 0;
  }

  .ToolTip_blockName__2QSj3 i.icon {
    left: -20px;
  }

  .ToolTip_columnBlock__2utV9,
  .ToolTip_columnSize__3QbBn,
  .ToolTip_columnProgress__2NwWY {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ToolTip_printHolder__3bgKM {
    display: inline-block;
  }

  .ui.basic.segment.ToolTip_tasksListRow__UYRBf {
    border: none;
  }
  .ui.basic.segment.ToolTip_tasksListRow__UYRBf:last-child {
    border-bottom: none;
  }
  .ui.basic.segment.ToolTip_tasksListRow__UYRBf:hover {
    background: var(--color-background-cell);
  }

  .ui.grid > .row > .column.ToolTip_columnActions__3GbSf {
    position: inherit;
    top: 0;
    right: 0;
  }

  .ToolTip_columnAvatar__3Frij {
    text-align: center;
  }

  .column.ToolTip_columnBlock__2utV9 {
    padding-left: 15px;
  }

  i.icon.ToolTip_iconEye__3kScG {
    display: inline-block;
    margin-left: 15px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .ui.ToolTip_popup__1Yfi9 {
    display: none !important;
  }
}

.PickedBinsReport_segmentHeader__2eP0u {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.PickedBinsReport_taskTypesFilter__2-2c- {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  min-height: 43px;
}

.PickedBinsReport_taskTypesFilterError__37QSc {
  border-color: #b00020;
}

.PickedBinsGroupsReport_segmentHeader__8G65k {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.PickedBinsGroupsReport_customTooltip__1Pxsv {
  background-color: white;
  padding: 0.5rem;
  border: 1px green solid;
}

.PickedBinsGroupsReport_loader__34EYm {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.HeaderFarms_headerFarms__FQfzN {
}
.HeaderFarms_headerFarms__FQfzN .ui.grid > .row {
  padding: 0;
}

.HeaderFarms_headerFarms__FQfzN .ui.grid > .row > .column:first-child {
  padding-left: 0;
}

.HeaderFarms_headerFarms__FQfzN .ui.grid > .row > .column:last-child {
  padding-right: 0;
}

.HeaderFarms_verticalMiddle__Ttxlu {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.HeaderFarms_verticalMiddle__Ttxlu.center {
  justify-content: center;
}

.HeaderFarms_verticalMiddle__Ttxlu.right {
  justify-content: flex-end;
}

.HeaderFarms_rightTools__5A7eV {
  display: flex;
  align-items: center;
  float: right;
}

.HeaderFarms_showMap__2q3oQ {
  vertical-align: middle;
  display: flex;
  margin-left: 30px;
}

.HeaderFarms_showMap__2q3oQ span {
  display: inline-block;
  margin-right: 15px;
}

.HeaderFarms_allFarms__2GvKi {
}

.ui.dropdown.HeaderFarms_allFarms__2GvKi {
  padding-left: 0;
}

.ui.scrolling.dropdown.HeaderFarms_allFarms__2GvKi .menu {
  max-height: 350px;
}

.ui.header.HeaderFarms_farmName__3D8TA {
  padding: 0.14em 0;
  margin: 0 20px 0.45238095em 0;
  line-height: 1.21428571em;
  display: inline-block;
}

.ui.dropdown.HeaderFarms_allFarms__2GvKi > .text {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.HeaderFarms_checkBoxes__3D2pB {
  padding: 15px;
  min-width: 150px;
}
.HeaderFarms_checkBoxes__3D2pB .ui.checkbox {
  display: block;
}

.HeaderFarms_checkBoxes__3D2pB .ui.checkbox:not(:first-child) {
  margin-top: 10px;
}

.HeaderFarms_headerFarms__FQfzN .search-input .ui.input .prompt {
  border-radius: 0.5rem;
}

.HeaderFarms_leftTools__32Nvb {
  display: flex;
  align-items: center;
}

.HeaderFarms_leftTools__32Nvb .ui.search {
  margin-right: 15px;
}

.HeaderFarms_filtersHolder__1_bpU > i.icon {
  font-size: 22px;
}

.HeaderFarms_filtersList__1CtWt {
  list-style: none;
  padding: 0;
  margin: 15px;
}

.ui.button.HeaderFarms_addNew__3yjIo > .icon {
  margin: 0 0 0 10px !important;
  font-size: 22px;
  height: 14px;
  vertical-align: middle;
  position: relative;
  top: -5px;
}

.HeaderFarms_searchInput__1pYZZ {
  width: auto;
}

.HeaderFarms_archiveToggle__3ERz5 {
  display: inline-flex;
  padding: 10px 0 10px 15px;
  float: right;
}

.HeaderFarms_archiveToggle__3ERz5 span {
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .HeaderFarms_archiveToggle__3ERz5 {
    border-left: 0;
    padding-left: 0;
  }
}

.ui.table.TableFarm_tableFarm__2Xc45 {
  border: none;
  border-radius: 5px;
  color: var(--color-text);
}
.ui.table.TableFarm_tableFarm__2Xc45.TableFarm_tableOpen__2uNQB {
}

.TableFarm_tableFarmHolder__1EO6w + .TableFarm_tableFarmHolder__1EO6w {
  margin-top: 20px;
}

.ui.table.TableFarm_tableFarm__2Xc45 tbody:nth-child(2n) {
  background: var(--color-background-cell);
}

.ui.table.TableFarm_tableFarm__2Xc45 thead tr th {
  background: #fff;
  border: none;
  font-weight: normal;
  color: var(--color-table-header);
  height: 48px;
}

.ui.table.TableFarm_tableFarm__2Xc45 thead tr:first-child > th:first-child,
.ui.table.TableFarm_tableFarm__2Xc45 tbody tr > td:first-child {
  position: relative;
  width: 50px;
}
.ui.table.TableFarm_tableFarm__2Xc45 thead tr:first-child > th:last-child,
.ui.table.TableFarm_tableFarm__2Xc45 tbody tr > td:last-child {
  white-space: nowrap;
}

.ui.table.TableFarm_tableFarm__2Xc45
  thead tr:first-child > th:last-child i.icon,
.ui.table.TableFarm_tableFarm__2Xc45 tbody tr > td:last-child i.icon {
  font-size: 18px;
  cursor: pointer;
  color: var(--color-icon);
  vertical-align: middle;
  margin: 0;
}

.ui.table.TableFarm_tableFarm__2Xc45
  thead tr:first-child > th:last-child i.icon.tuf-pen,
.ui.table.TableFarm_tableFarm__2Xc45 tbody tr > td:last-child i.icon.tuf-pen {
  color: var(--color-green);
}

.ui.table.TableFarm_tableFarm__2Xc45
  thead tr:first-child > th:last-child > i.icon:not(:last-child),
.ui.table.TableFarm_tableFarm__2Xc45
  tbody tr > td:last-child > i.icon:not(:last-child) {
  margin-right: 20px;
}
.ui.table.TableFarm_tableFarm__2Xc45 tbody tr > td:last-child i.icon.blank {
  cursor: default;
}

.ui.table.TableFarm_tableFarm__2Xc45
  tbody tr > td:last-child i.icon.tuf-archive,
.ui.table.TableFarm_tableFarm__2Xc45
  tbody tr > td:last-child i.icon.tuf-unarchive {
  margin-top: 3px;
}

.TableFarm_toggleIcon__3p_N- {
  width: 20px;
  height: 20px;
  font-size: 21px;
  display: inline-block;
  color: var(--color-icon);
  cursor: pointer;
}
.TableFarm_toggleIcon__3p_N-.TableFarm_openTable__1Vhwn {
  cursor: default;
}

.TableFarm_toggleIcon__3p_N-:not(.TableFarm_openTable__1Vhwn):hover {
  color: var(--color-green);
}

.TableFarm_archive__1tGHq {
  background: url(/static/media/symbol.7a6754ad.svg) no-repeat left;
}

.TableFarm_verticalMiddle__3rdrt {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.TableFarm_verticalMiddle__3rdrt img {
  margin-right: 15px;
}

.TableFarm_verticalMiddle__3rdrt.center {
  justify-content: center;
}

.TableFarm_verticalMiddle__3rdrt.right {
  justify-content: flex-end;
}

.TableFarm_totalBlocks__3lKmB {
  font-weight: normal;
  color: var(--color-title);
  font-size: 12px;
}
.TableFarm_totalBlocks__3lKmB span {
  display: inline-block;
  color: var(--color-text);
}
.TableFarm_totalBlocks__3lKmB span:not(:last-child) {
  margin-right: 10px;
}

.TableFarm_tableFarm__2Xc45 .ui.header {
  margin: 0;
}

.TableFarm_smallText__1ZLWq {
  color: var(--color-text);
  font-weight: normal;
  font-size: 12px;
}

.TableFarm_timer__3fa5i {
  color: var(--color-pink);
}
.TableFarm_timer__3fa5i i.icon,
.TableFarm_clock__1dAzc i.icon {
  font-size: 18px;
}

.ui.button.button-text.TableFarm_buttonMore__2f7vL:hover {
  background: none;
}

.ui.button.button-text.TableFarm_buttonMore__2f7vL .icon.ellipsis {
  font-size: 18px;
}

.ui.header.TableFarm_farmName__3yhCe {
  cursor: pointer;
}
.ui.header.TableFarm_farmName__3yhCe:hover {
  color: var(--color-green);
}

.ui.header.TableFarm_farmNameSelected__25Czj {
}

.TableFarm_tableFarm__2Xc45 .TableFarm_loader__lrjgX {
  margin: 0;
  position: absolute;
  left: 15px;
  top: 17px;
  width: 18px;
  height: 18px;
  border-width: 3px;
}

.TableFarm_tableFarm__2Xc45 tbody:not(:last-child) tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0):last-child td {
}

.ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:first-child {
  border-top: none;
}

.ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:nth-child(2) {
  padding-left: 35px;
  position: relative;
}

.ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:nth-child(2) .TableFarm_blockName__1Y0Gh {
  font-weight: normal;
}

.ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:nth-child(2):before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  content: "";
  width: 1px;
  background: var(--color-border);
}

.ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:nth-child(2):after {
  position: absolute;
  top: -1px;
  left: 0;
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
}

.ui.table tbody:nth-child(2n) tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0)
  td:nth-child(2):after {
  background: var(--color-background-cell);
}

.ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0):hover td:nth-child(2):after,
.ui.table tbody:nth-child(2n) tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0):hover
  td:nth-child(2):after {
  background: var(--color-row-hover);
}

.ui.table td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ui.table td.TableFarm_areaCell__12TfA {
  position: relative;
  padding-bottom: 25px;
}

.ui.table td.TableFarm_areaCell__12TfA:after {
  position: absolute;

  bottom: 0;
  left: 20px;
  content: "";
  width: 1px;
  height: 15px;
  background: var(--color-border);
}

.TableFarm_rowBlock__2dal0 {
}
.TableFarm_rowArea__1pnXj {
}
.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) .ui.header {
  font-size: 16px;
}

.TableFarm_vacant__2QV19 {
  font-style: italic;
  color: var(--color-vacant-blue);
}

.TableFarm_tasksListIcon__1928G {
  font-size: 21px;
  color: var(--color-icon);
  margin: -2px 15px 0 0;
  vertical-align: middle;
}

.TableFarm_farmCostBreakdownIcon__JL-Tq {
  font-size: 24px !important;
  color: var(--color-brown-grey);
  margin-right: 20px;
  cursor: pointer;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADE0lEQVRoQ+2ZzauPQRTHP9e7ZGFBXVZWoqzITkmJm5WukFiQtxJZkK1ig2uDQllYIG83ZWOrlETyH9iRsMCCLHR1np753TGemTnzzDNPbt1Z/n7n5fs958w5M/MMMcXX0BTHzzSBFhm8BixJ1JsALgCvXL2+MjCeCFgjPipCpQmUAG6TGy1JYAbwUBPKHJmSBNYBp3PAaXRLEhD/pUvoSWkCJUlsByZKEhgBnjplkJuRx8Bt22ZJAjuAB5o6zpAZL01gJ/AFOJwB0qdaZbMPAjaAD8CxlmQ2Akf7nMRSQpKBoqvvDHROpiSBki10EIjSBJYBlzsP+6TBomeh+cDP2lfjBswgVp1E++hCoTkg3WhDhMRH4Bbw2ienKaFtwJ6Io0FELLmtwH6g6b+M4A9U5wF3QgTuAbMTPblgh4GrtY2uiJwDVsZKKOfMcgV4ZhE/AmzyBEIG23PgDfCp3jOLgBXAWmB9LIBNGQiBt6MYkvsNyCAzKycgQQ4ugZgjLQFxKiVo38hitmPBbvzfJqBxkEJAHNryZ4DVrVD6lXYZAgeBLS06zSzgfoKeJkhajn+9SsQMhzpIjLytuxk4pEXYICf7SvbXYJkMhAj8APY6G/IlcLH+bQxYnpCFDPz/qFYXmlj09wHfa9WYrA9cVzPA2F8KSLuuLjQxUO+AU7WmPZhSImkTOADcTFEG5I1J7sJzXT0NAbebGBsyZE4ogaR2L6VZXQbE2C9gt8dqLINuADTynRMwBptqOdaF/isCdmRSyiJFVh19GZSyBzSnTgPATn8KqBTZGIH3wHEjpJkDbtkICXegxOq6qfTmAGuAkx7Eb4EbwOcAowUaAr4uZOy2AR+Lsub/6phuCKwCzraYpo8Uj2NdDjHBKz4Hq8/TaIjs1/oyI+ccKa2FgDwKRFfJ+4Dch79ZCGKlFgXbJJB6I7M3bwjQeeeLYhHwQsh3qc9xWH14sKIlTyszW4U3rhR82DLPInEzkxK+DZsTEJ//pM+sIQB3FSdaG8R1YHFKVGrZS8ALV0/zsNXCV38q0wT6i3Wzpz+9Dn3Y7oFy4gAAAABJRU5ErkJggg==) no-repeat center;
  background-size: contain;
}

.TableFarm_tasksEditIcon__1FQiC {
  font-size: 16px;
  color: var(--color-green);
  margin-right: 20px;
  cursor: pointer;
}

.TableFarm_tasksEditIcon__1FQiC:hover {
  opacity: 0.8;
}

.TableFarm_tasksDeleteIcon__3494V {
  font-size: 16px;
  color: var(--color-icon);
  cursor: pointer;
}

.TableFarm_tasksDeleteIcon__3494V:hover {
  opacity: 0.8;
}

.TableFarm_blockName__1Y0Gh {
  font-weight: bold;
}

.TableFarm_statusHolder__1d86k {
  display: flex;
  text-align: left;
  line-height: 1;
}
.TableFarm_statusHolder__1d86k > i:first-child {
  flex: 0 0 20px;
  margin-right: 10px;
}

.TableFarm_archivedFarm__1Z0bq {
}
tbody.TableFarm_archivedFarm__1Z0bq tr td > div:not(.dropdown),
thead.TableFarm_archivedFarm__1Z0bq tr th > div:not(.dropdown),
tr.TableFarm_archivedFarm__1Z0bq td > div:not(.dropdown) {
  filter: grayscale(100%);
  opacity: 0.5;
}

.TableFarm_archived__p8Ez_ {
  font-size: 12px;
  color: var(--color-middle);
}
@media (max-width: 767px) {
  .ui.table.TableFarm_tableFarm__2Xc45 tr {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    box-shadow: none !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 thead tr {
    position: relative;
    padding: 0;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 thead tr th {
    height: auto;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 thead th:first-child {
    width: 10% !important;
  }
  .ui.table.TableFarm_tableFarm__2Xc45 thead th:nth-child(2) {
    padding-top: 0 !important;
    width: 90% !important;
  }
  .ui.table.TableFarm_tableFarm__2Xc45 thead th:nth-child(2) .ui.header {
    line-height: 1;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 thead th:nth-child(3),
  .ui.table.TableFarm_tableFarm__2Xc45 thead th:nth-child(4),
  .ui.table.TableFarm_tableFarm__2Xc45 thead th:nth-child(5),
  .ui.table.TableFarm_tableFarm__2Xc45 thead th:nth-child(6) {
    padding-top: 15px !important;
    width: 25% !important;
  }

  .ui.table.TableFarm_tableOpen__2uNQB thead th:nth-child(3),
  .ui.table.TableFarm_tableOpen__2uNQB thead th:nth-child(4),
  .ui.table.TableFarm_tableOpen__2uNQB thead th:nth-child(5),
  .ui.table.TableFarm_tableOpen__2uNQB thead th:nth-child(6) {
    padding-bottom: 15px !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 thead th:last-child {
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 0 !important;
  }
  .ui.table.TableFarm_tableFarm__2Xc45 tbody td:last-child {
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 0 !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr {
    position: relative;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr td:first-child {
    display: none !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowBlock__2dal0 td:nth-child(2) {
    width: 45% !important;
  }
  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowBlock__2dal0 td:nth-child(3) {
    width: 55% !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowBlockWithSubArea__2CTN5 td:nth-child(1) {
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowBlockWithSubArea__2CTN5 td:nth-child(3) {
    text-align: left;
  }
  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowArea__1pnXj td:nth-child(2) {
    width: 45% !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowArea__1pnXj td:nth-child(3) {
    width: 55% !important;
    padding-right: 100px !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowArea__1pnXj td:nth-child(4) {
    order: 8;
    width: 45% !important;
    padding-top: 15px !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowArea__1pnXj td:nth-child(5) {
    width: 45% !important;
    padding-top: 15px !important;
  }
  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowArea__1pnXj td:nth-child(6) {
    width: 55% !important;
    padding-top: 15px !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowArea__1pnXj td:nth-child(7) {
    order: 9;
    padding-top: 15px !important;
  }

  .ui.table.TableFarm_tableFarm__2Xc45
    tbody
    tr.TableFarm_rowArea__1pnXj
    td:not(:last-child):not(:nth-child(2))
    .TableFarm_verticalMiddle__3rdrt {
    justify-content: left;
  }

  .TableFarm_tableFarm__2Xc45 tbody tr.TableFarm_rowBlockWithSubArea__2CTN5,
  .TableFarm_tableFarm__2Xc45 tbody:not(:last-child) tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0):last-child {
    border-bottom: 1px solid var(--color-border);
  }

  .TableFarm_verticalMiddle__3rdrt {
    justify-content: center;
  }

  .ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:nth-child(2):before,
  .ui.table tr.TableFarm_rowArea__1pnXj:not(.TableFarm_rowBlock__2dal0) td:nth-child(2):after,
  .ui.table td.TableFarm_areaCell__12TfA:after {
    display: none;
  }
}

.BlockDetails_detailsHolder__1DkFI {
}

.BlockDetails_detailsHolder__1DkFI .ui.basic.segment {
  padding: 0;
}

.BlockDetails_detailsHolder__1DkFI .ui.button.button-text {
  color: var(--color-violet);
}

.BlockDetails_detailsHolder__1DkFI .ui.button.button-text:hover {
  opacity: 0.6;
}

.BlockDetails_detailsItem__3ZrzS {
}

.BlockDetails_detailsTitle__1FxqR {
  text-transform: uppercase;
  color: var(--color-middle);
}

.BlockDetails_detailsTitle__1FxqR i.icon {
  font-size: 14px;
}

.BlockDetails_detailsText__2I8l4 {
  margin-top: 5px;
}

.BlockDetails_lineHorizontal__2Iba- {
  height: 1px;
  background: var(--color-border);
  border: none;
  margin: 30px 0;
}
.BlockDetails_tasksTotal__2JOTf {
  margin-top: 10px;
}
.BlockDetails_tableTasks__34DaD {
}
.ui.basic.table.BlockDetails_tableTasks__34DaD tbody tr {
  border: none;
}

.ui.basic.table.BlockDetails_tableTasks__34DaD tbody td {
  padding: 0 0 5px 0;
  border: none;
}

.BlockDetails_timer__MZeXY {
  color: var(--color-pink);
}
.BlockDetails_timer__MZeXY i.icon,
.BlockDetails_clock__FSjfJ i.icon {
  font-size: 18px;
}

.BlockDetails_formHolder__1VEGg {
  margin-top: 30px;
}

.BlockDetails_formHolder__1VEGg label > div:first-child {
  margin-bottom: 10px;
}

.BlockDetails_formHolder__1VEGg label .ui.input {
  width: 100%;
}

.BlockDetails_formHolder__1VEGg label textarea {
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  border-radius: 0.28571429rem;
  resize: vertical;
  min-height: 100px;
}

.BlockDetails_formHolder__1VEGg .field + .field {
  margin-top: 30px;
}

.ui.segment.AddFarmPrompt_promptHolder__1qxGo {
  background: var(--color-grass-light);
}
.ui.header.AddFarmPrompt_subTitle__rH2FN {
  color: var(--color-middle);
}

.Farms_farmsHolder__3oZgf {
}

.Farms_searchInput__1_INl {
  width: auto;
}

.Farms_archiveToggle__3HO40 {
  display: inline-flex;
  padding: 10px 0 10px 15px;
  float: left;
}

.Farms_archiveToggle__3HO40 span {
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .Farms_archiveToggle__3HO40 {
    border-left: 0;
    padding-left: 0;
  }
}

.ui.segment.Farms_mainHolder__EEoUJ {
  padding: 0;
}

.Farms_farmsHolder__3oZgf .pusher {
  padding: 1em;
  height: 100%;
  overflow: auto;
}

.Farms_sidebarHolder__1aMxI {
  position: relative;
}

i.icon.Farms_buttonClose__1qvpi {
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: pointer;
}

.Farms_farmsHolder__3oZgf .ui.sidebar {
  background: #fff;
  width: 100%;
  padding: 15px;
  overflow: auto;
}

@media screen and (min-width: 768px) {
  .Farms_farmsHolder__3oZgf .ui.sidebar {
    padding: 30px;
  }

  .Farms_farmsHolder__3oZgf .ui.sidebar {
    background: #fff;
    width: 80%;
    min-width: 720px;
    padding: 15px;
    overflow: auto;
  }
}

.Farms_farmsHolder__3oZgf .ui.sidebar > .ui.container {
  width: 100%;
}

.header-left {
  text-align: left;
}

.pr-10 {
  padding-right: 10px;
}

/* Print Page */

.print-page-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.print-page-reversed-row {
  margin-top: 30px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.print-page-reversed-row .print-page-left-block {
  margin-right: 20px;
}

.print-page-table {
  width: 100%;
  font-size: 10pt;
  border-collapse: collapse;
}

.print-page-table tfoot tr {
  border-top: 1px solid black;
}

.justify-content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.justify-content-end {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-1 {
  margin-right: 20px !important;
}

.-table-summary .rt-tr-group:last-child {
  border-top: 1px solid black;
}

.link_underlined {
  color: black;
  text-decoration: underline;
}

.irrigation {
  width: 100% !important;
}

.irrigationTable {
  width: 98% !important;
}

.TasksReport_reportWrapper__26n8_ {
  padding: 25px;
}

.TasksReport_reportHeaderRow__29pnW {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.TasksReport_reportHeader__3XA-Q {
  font-size: 16pt;
  margin-bottom: 20px;
}

.TasksReport_reportSubHeader__3e1QV {
  font-size: 11pt;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.TasksReport_tableElement__1jmi- {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  margin-bottom: 50px !important;
}

.TasksReport_tableHeader__125lu {
  font-size: 12pt;
}

.TasksReport_tableElement__1jmi- tr td {
  border: none !important;
}

.TasksReport_tableElement__1jmi- thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.9) !important;
  font-size: 8pt !important;
}

.TasksReport_tableElement__1jmi- tbody tr td,
.TasksReport_reportCreatedRow__3Hjiv {
  font-size: 7pt !important;
}

.TasksReport_tableElement__1jmi- tfoot tr th {
  font-size: 8pt !important;
  font-weight: bold !important;
}

.TasksReport_stripedRow__rDj-u {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table {
  width: 98%;
  font-size: 12px;
  text-align: left;
}

.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table thead tr th {
  padding: 10px 2px;
}

.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table tbody tr td {
  padding: 10px 2px;
}

.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table tr th:last-child,
.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table tr:first-child th:first-child {
  padding: 10px;
}

.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table tr td:last-child,
.BreakdownTable_breakdownTableContainer__2u5lG > .ui.table tr td:first-child {
  padding: 10px;
}

.BreakdownTable_breakdownTableContainerTotals__1bAoz {
  font-weight: bold;
}

.header-left {
  text-align: left;
}

.pr-10 {
  padding-right: 10px;
}

/* Print Page */

.print-page-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.print-page-reversed-row {
  margin-top: 30px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.print-page-reversed-row .print-page-left-block {
  margin-right: 20px;
}

.print-page-table {
  width: 100%;
  font-size: 10pt;
  border-collapse: collapse;
}

.print-page-table tfoot tr {
  border-top: 1px solid black;
}

.justify-content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.justify-content-end {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-1 {
  margin-right: 20px !important;
}

.-table-summary .rt-tr-group:last-child {
  border-top: 1px solid black;
}

.link_underlined {
  color: black;
  text-decoration: underline;
}

.chemicals-costs {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.filters-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0;
}

.print-wrapper {
  display: flex;
}

.table-wrapper {
  width: 100%;
  overflow-y: "scroll";
  height: "calc(100% - 180px)";
}

.application {
  width: 100% !important;
}

.operatinCostTable {
  width: 98% !important;
}

.SortDropdown_sortHolder__18jlr {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
}

.SortDropdown_sortHolder__18jlr .icon {
  color: var(--color-green);
}
.ui.dropdown.SortDropdown_dropdownSort__s0cFC {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  color: var(--color-green);
}
.ui.dropdown.SortDropdown_dropdownSort__s0cFC .menu {
  left: auto;
  right: 0;
}

.SortDropdown_sortOrder__2FLQF {
  cursor: pointer;
  color: var(--color-green);
}

.SortDropdown_sortHolder__18jlr .icon {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  i.icon.SortDropdown_sortOrder__2FLQF {
    margin-left: 0;
  }
}

.Filters_tabsAndFilters__EeKB1 > div + div {
  margin-top: 15px;
}

.Filters_filtersHolder__J1iX- {
}

.Filters_additionalButton__2hxTb {
  height: 30px;
  min-height: 30px !important;
  margin-top: 10px !important;
  line-height: 0 !important;
}

.ui.selection.dropdown.Filters_buttonFilters__2ppRM,
.ui.labeled.icon.button.Filters_buttonFilters__2ppRM {
  min-width: 143px;
  padding: 0 10px 0 40px !important;
  color: var(--color-green);
  background: var(--color-light-grey);
  height: 30px;
  min-height: 30px;
  border: none;
  font-weight: bold;
  vertical-align: middle;
}

.ui.selection.dropdown.Filters_buttonFilters__2ppRM > input {
  cursor: pointer;
  padding: 6px 10px !important;
  opacity: 0;
}

.ui.selection.dropdown.Filters_buttonFilters__2ppRM > .icon {
  background: none;
}

.ui.selection.dropdown.Filters_buttonFilters__2ppRM > .menu {
  min-width: 14rem;
}
.ui.selection.dropdown.Filters_buttonFilters__2ppRM > .text {
  display: flex;
  min-height: inherit;
  align-items: center;
  cursor: pointer;
}

.ui.selection.dropdown.active.Filters_buttonFilters__2ppRM .Filters_countFilters__3jLcu {
  display: none;
}

.ui.selection.dropdown.active.Filters_buttonFilters__2ppRM {
  box-shadow: none;
  background: var(--color-light-grey);
}

.ui.selection.dropdown.active.Filters_buttonFilters__2ppRM > input {
  opacity: 1;
}

.ui.selection.dropdown.active.Filters_buttonFilters__2ppRM > .icon,
.ui.selection.dropdown.active.Filters_buttonFilters__2ppRM > .text {
  opacity: 0;
}
.ui.selection.dropdown.active.Filters_buttonFilters__2ppRM .ui.label {
  padding: 0;
  background: none;
  margin-right: 0;
  color: var(--color-icon);
}
.ui.dropdown .menu > .item.Filters_selected__y--DE {
  display: inline-block;
}

.ui.dropdown .menu > .item.Filters_checked__24yyn {
  color: var(--color-grass);
}
.ui.dropdown .menu
  > .item.Filters_checked__24yyn
  + .item:not(.Filters_checked__24yyn) {
  margin-top: 15px;
}

.Filters_nothing__1BR8i {
  padding: 15px;
}
.Filters_filtersHolder__J1iX- .ui.dropdown .menu.Filters_dropdownMenu__3XGvb {
  top: 100%;
  bottom: auto;
}
.Filters_filtersHolder__J1iX- .ui.dropdown .menu.Filters_dateRangeHolder__2bztj {
  max-width: 300px;
  min-width: auto;
}

.Filters_filtersHolder__J1iX-
  .ui.dropdown
    .menu.Filters_selectItemsHolder__2agZs:not(.Filters_dateRangeHolder__2bztj):not(.Filters_switchMenu__3lF4S) {
  max-height: 350px;
  overflow: auto;
}

.Filters_filtersHolder__J1iX- .ui.dropdown .menu.Filters_switchMenu__3lF4S {
  display: flex;
  position: inherit;
  margin: 0 !important;
  box-shadow: none;
}

.Filters_filtersHolder__J1iX- .ui.dropdown .menu.Filters_switchMenu__3lF4S .item {
  flex: 1 1 auto;
}
.Filters_filtersHolder__J1iX- .ui.dropdown .menu.Filters_switchMenu__3lF4S .item.active {
  background: rgba(0, 0, 0, 0.05);
}

.Filters_filterBox__Xdfwo {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 0.28571429rem;
  background: var(--color-row-hover);
  font-weight: bold;
}

.Filters_filterBox__Xdfwo .ui.dropdown > .text {
  color: var(--color-green);
}

.Filters_filterBox__Xdfwo > .ui.icon.button {
  margin: 0;
}

.Filters_filterBox__Xdfwo > .ui.icon.button > .icon {
  color: var(--color-green);
}

.Filters_filterHolder__2eUMQ {
  padding: 1.14285714rem 0.78571429rem;
  cursor: default;
  width: 230px;
  font-weight: normal;
}

.Filters_filterHolder__2eUMQ:hover {
  background: none;
}

.Filters_filterHolder__2eUMQ .ui.grid {
  margin: 0;
}

.Filters_datePicker__3SFKg label > div:first-child {
  margin-bottom: 5px;
}

.Filters_datePicker__3SFKg + .Filters_datePicker__3SFKg {
  margin-top: 15px;
}

.Filters_inputHolder__1FOVJ + .Filters_inputHolder__1FOVJ {
  margin-top: 15px;
}

.Filters_inputHolder__1FOVJ > label > div + div {
  margin-top: 5px;
}

.Filters_filterAdd__2q5ZP {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

.Filters_countFilters__3jLcu {
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background: var(--color-background-green);
  border-radius: 100%;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.Filters_clearHolder__jAkwR {
  display: inline-block;
  margin-top: 16px;
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid var(--color-border);
  vertical-align: top;
}

.Filters_filtersHolder__J1iX- .ui.dropdown .menu.Filters_selectItemsHolder__2agZs {
  min-width: 300px;
}
.Filters_filtersHolder__J1iX- .ui.dropdown.Filters_selectItems__3ttC4 {
  padding: 10px 10px 0 10px;
  border: none;
  width: auto;
  box-shadow: none;
  white-space: normal;
}

.Filters_filtersHolder__J1iX- .ui.dropdown.Filters_selectItems__3ttC4 > .icon,
.Filters_filtersHolder__J1iX- .ui.dropdown.Filters_selectItems__3ttC4 > .sizer {
  display: none;
}
.Filters_filtersHolder__J1iX- .ui.dropdown.Filters_selectItems__3ttC4 .menu,
.Filters_filtersHolder__J1iX- .ui.dropdown.Filters_selectItems__3ttC4 .menu:hover {
  width: auto;
  position: initial;
  display: block;
  max-width: none;
  overflow: initial;
  max-height: none;
  padding-right: 0;
  margin: 10px -10px 0 -10px !important;
  box-shadow: none !important;
  border: none;
}

.Filters_filtersHolder__J1iX- .ui.dropdown .menu > .message {
  margin: 0;
}

.Filters_filtersItems__2aWxg {
  display: block;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .Filters_filtersItems__2aWxg {
    display: inline-block;
    margin: 0;
    vertical-align: top;
  }
}

.MultiSelectFilter_loadingPlaceHolder__v-mav {
  display: flex;
  align-items: center;
}

.MultiSelectFilter_loader__uMbID {
  margin: 1px 5px 1px 1px;
}

.SingleSelectFilter_loadingPlaceHolder__3DCEJ {
  display: flex;
  align-items: center;
}

.SingleSelectFilter_loader__1uAIK {
  margin: 1px 5px 1px 1px;
}

.ui.button.ExcelReport_exportButton__3lIMv > i.icon {
  position: relative;
  top: -1px;
  color: var(--color-green);
}

.GeneralTasksCostsBreakdown_justifyContentBetween__3XjUO {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.GeneralTasksCostsBreakdown_wrapper__YT3MV {
  width: 100% !important;
  overflow: hidden;
  height: 100%;
}

.GeneralTasksCostsBreakdown_marginBottom__fg0gm {
  margin-bottom: 1rem;
}

.GeneralTasksCostsBreakdown_mr10__mFufD {
  margin-right: 10px;
}

.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table {
  font-size: 12px;
  text-align: left;
}

.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table thead tr th {
  padding: 10px 2px;
}

.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table tbody tr td {
  padding: 10px 2px;
}

.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table tr th:last-child,
.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table tr:first-child th:first-child {
  padding: 10px;
}

.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table tr td:last-child,
.GeneralTasksCostsBreakdown_breakdownTableContainer__1xJyw > .ui.table tr td:first-child {
  padding: 10px;
}

.ui.header.GeneralTasksCostsBreakdown_noMargin__2hhQG {
  padding: 0;
  margin: 0;
}

.GeneralTasksCostsBreakdown_breakdownTableContainerTotals__2gQjC {
  font-weight: bold;
}

.PrintReport_reportWrapper__3Csdz {
  padding: 25px;
}

.PrintReport_reportHeaderRow__fNvwP {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_reportHeader__s_PQQ {
  font-size: 16pt;
  margin-bottom: 20px;
}

.PrintReport_reportSubHeader__1jl2Q {
  font-size: 11pt;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.PrintReport_tableElement__3IV8n {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  margin-bottom: 50px !important;
}

.PrintReport_tableHeader__33Pkz {
  font-size: 12pt;
}

.PrintReport_tableElement__3IV8n tr td {
  border: none !important;
}

.PrintReport_tableElement__3IV8n thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.9) !important;
  font-size: 8pt !important;
}

.PrintReport_tableElement__3IV8n tbody tr td,
.PrintReport_reportCreatedRow__2Qr8H {
  font-size: 7pt !important;
}

.PrintReport_tableElement__3IV8n tfoot tr th {
  font-size: 8pt !important;
  font-weight: bold !important;
}

.PrintReport_stripedRow__17NYI {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.info-row {
  padding: 0 0 1rem 0 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.border {
  border-bottom: 1px solid var(--color-border) !important;
}

.table {
  padding: 0px !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}

.test {
  margin-right: 10px !important;
}

.largeCell {
  width: 25%;
}

.mediumCell {
  width: 15%;
}

.smallCell {
  width: 20%;
}

.header-left {
  text-align: left;
}

.pr-10 {
  padding-right: 10px;
}

/* Print Page */

.print-page-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.print-page-reversed-row {
  margin-top: 30px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.print-page-reversed-row .print-page-left-block {
  margin-right: 20px;
}

.print-page-table {
  width: 100%;
  font-size: 10pt;
  border-collapse: collapse;
}

.print-page-table tfoot tr {
  border-top: 1px solid black;
}

.justify-content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.justify-content-end {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-1 {
  margin-right: 20px !important;
}

.-table-summary .rt-tr-group:last-child {
  border-top: 1px solid black;
}

.link_underlined {
  color: black;
  text-decoration: underline;
}

.chemicals-costs {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.filters-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0;
}

.print-wrapper {
  display: flex;
}

.table-wrapper {
  width: "100%";
  overflow-y: "scroll";
  height: "calc(100% - 180px)";
}

.totalCost {
  width: 100% !important;
}

.rows-report {
  width: 100% !important;
}

.rowsReportTable {
  width: 98% !important;
}

.irrigationTableContainet {
  width: 100% !important;
}

.irrigationTable {
  width: 98% !important;
}

.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table {
  font-size: 12px;
  text-align: left;
}

.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table thead tr th {
  padding: 10px 2px;
}

.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table tbody tr td {
  padding: 10px 2px;
}

.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table tr th:last-child,
.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table tr:first-child th:first-child {
  padding: 10px;
}

.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table tr td:last-child,
.BreakdownTable_breakdownTableContainer__1WJaJ > .ui.table tr td:first-child {
  padding: 10px;
}

.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table {
  font-size: 12px;
  text-align: left;
}

.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table thead tr th {
  padding: 10px 2px;
}

.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table tbody tr td {
  padding: 10px 2px;
}

.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table tr th:last-child,
.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table tr:first-child th:first-child {
  padding: 10px;
}

.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table tr td:last-child,
.OperatingCostBreakDown_breakdownTableContainer__-UcoE > .ui.table tr td:first-child {
  padding: 10px;
}

.OperatingCostBreakDown_breakdownTableContainerTotals__1ht9T {
  font-weight: bold;
}

.ui.container .operating {
  width: 100% !important;
}

.OperatingCostBreakDown_operatinCostTable__34T05 {
  width: 98% !important;
}

.HeaderSprayTasks_headerTasks__mY72K {
}

.HeaderSprayTasks_headerTasks__mY72K .ui.grid > .row {
  padding: 0;
}

.HeaderSprayTasks_headerTasks__mY72K .ui.grid > .row > .column:first-child {
  padding-left: 0;
}

.HeaderSprayTasks_headerTasks__mY72K .ui.grid > .row > .column:last-child {
  padding-right: 0;
}

.HeaderSprayTasks_headerTasks__mY72K .ui.grid > .row > .column:last-child .ui.button:last-child {
  margin-right: 0;
}

.HeaderSprayTasks_dropdownHeaderMenu__d7gEv {
}

.ui.dropdown.HeaderSprayTasks_dropdownHeaderMenu__d7gEv {
  padding-left: 0;
}

.ui.scrolling.dropdown.HeaderSprayTasks_dropdownHeaderMenu__d7gEv .menu {
  max-height: 350px;
}

.ui.dropdown.HeaderSprayTasks_dropdownHeaderMenu__d7gEv > .text {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.HeaderSprayTasks_verticalMiddle__2j3bN {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.HeaderSprayTasks_verticalMiddle__2j3bN.center {
  justify-content: center;
}

.HeaderSprayTasks_verticalMiddle__2j3bN.right {
  justify-content: flex-end;
}

.ui.header.HeaderSprayTasks_taskName__3V0fE {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.ui.basic.segment.HeaderSprayTasks_tasksCountHolder__vO-GP {
  border: 1px solid var(--color-border-blue);
  background: var(--color-background-blue);
  padding: 10px 15px;
  border-radius: 4px;
  display: none;
}

.HeaderSprayTasks_tasksCount__1Evq0 {
}

.HeaderSprayTasks_boxCount__t-Szu {
  display: flex;
  flex-direction: row;
}

.HeaderSprayTasks_whiteHolder__27eYx {
  background: #fff;
  width: 65px;
  height: 45px;
  border-radius: 4px;
  margin-right: 15px;
}

.HeaderSprayTasks_tasksDigits__2XqAV {
  color: var(--color-title);
  font-size: 20px;
  line-height: 25px;
}

.ui.selection.dropdown.HeaderSprayTasks_selectShort__1-79i {
  margin-left: 10px;
  width: 60px;
  min-width: 60px;
}

.HeaderSprayTasks_sortHolder__1reCK {
}

.ui.grid.HeaderSprayTasks_tasksFilters__2hcYE i {
  font-size: 18px;
}

.ui.dropdown.HeaderSprayTasks_sortHolder__1reCK {
  white-space: nowrap;
}

.ui.dropdown.HeaderSprayTasks_sortHolder__1reCK {
}

.ui.dropdown.HeaderSprayTasks_sortHolder__1reCK i {
  position: absolute;
  left: 0;
  top: 0;
}

.ui.dropdown.HeaderSprayTasks_sortHolder__1reCK .text {
  padding-left: 30px;
}

.HeaderSprayTasks_sortList__2CW0I {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 100px;
}

.HeaderSprayTasks_sortList__2CW0I li {
  padding: 5px 15px;
}
.HeaderSprayTasks_tasksFilters__2hcYE .ui.icon.button {
  height: 35px;
  margin-left: 10px;
}

.ui.grid.HeaderSprayTasks_tasksFilters__2hcYE [class*='right floated'].column {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .ui.basic.segment.HeaderSprayTasks_tasksCountHolder__vO-GP {
    display: block;
  }

  .ui.grid.HeaderSprayTasks_tasksFilters__2hcYE [class*='right floated'].column {
    margin-left: auto;
  }
}

.ui.button.button-text.TableSprayTask_buttonMore__2GuWw {
  margin-left: 5px;
  margin-top: 3px;
  margin-right: 0;
}

.ui.button.button-text.TableSprayTask_buttonMore__2GuWw:hover {
  background: none;
}

.ui.basic.segment.TableSprayTask_tasksListRow__24gYF {
  color: var(--color-title);
  border-top: 1px solid var(--color-border);
  margin: 0;
}

.ui.basic.segment.TableSprayTask_tasksListRow__24gYF:nth-child(2n + 1) {
  background: var(--color-row-stripped);
}

.ui.basic.segment.TableSprayTask_tasksListRow__24gYF:last-child {
  border-bottom: 1px solid var(--color-border);
}

.ui.grid.TableSprayTask_tasksListRowGrid__i6qUU {
}

.ui.grid.TableSprayTask_tasksListRowGrid__i6qUU:last-child {
  border-bottom: 1px solid var(--color-border);
}

.ui.button.TableSprayTask_readFull__D-U_P {
  color: var(--color-green);
  margin-top: 10px;
  margin-bottom: 15px;
}

.ui.grid > .row > .column.TableSprayTask_columnActions__1Tt_S {
  position: absolute;
  top: 15px;
  right: 15px;
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
}

.ui.grid > .row > .column.TableSprayTask_columnActions__1Tt_S .icon {
  font-size: 18px;
  color: var(--color-icon);
}

.ui.grid > .row > .column.TableSprayTask_columnActions__1Tt_S .icon.print {
  color: var(--color-green);
}

.TableSprayTask_printHolder__366nY {
  display: none;
}
.TableSprayTask_printHolder__366nY .icon {
  cursor: pointer;
}

.TableSprayTask_columnBlock__3M9gw,
.TableSprayTask_columnSize__8tTaJ,
.TableSprayTask_columnProgress__364d5 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.TableSprayTask_hasDone__2MQdO div {
  padding-left: 15px;
}

.TableSprayTask_progressHolder__2My7C {
  text-align: center;
}

.TableSprayTask_statusOPEN__1ZtGj {
  color: var(--color-open);
}

.TableSprayTask_statusIN_PROGRESS__2_T-i {
  color: var(--color-in-progress);
}
.TableSprayTask_statusCOMPLETED__1NLao {
  color: var(--color-completed);
}

i.icon.TableSprayTask_iconEye__3oqce {
  font-size: 18px;
  margin-top: 5px;
  display: block;
}

.TableSprayTask_employeeIcons__2PEna {
  display: inline-block;
}

.TableSprayTask_plusMore__39ETp {
  display: inline-block;
  font-weight: bold;
}

.TableSprayTask_employeeIcon__2FxBm {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  background: var(--color-background-cell);
  border: 1px solid var(--color-border);
  border-radius: 50%;
}

.TableSprayTask_employeeIconOverlay__38vMT {
  float: left;
  margin-left: -17px;
}

.TableSprayTask_chemicalName__3FluV,
.TableSprayTask_blockName__13Oaw {
  overflow-wrap: break-word;
  position: relative;
}

.TableSprayTask_blockName__13Oaw i.icon {
  position: absolute;
  left: -5px;
  top: 0;
}

.TableSprayTask_newTask__lfqk3 {
  background: var(--color-grass-light);
}

@media screen and (min-width: 768px) {
  .TableSprayTask_hasDone__2MQdO div {
    padding-left: 0;
  }

  .TableSprayTask_blockName__13Oaw i.icon {
    left: -20px;
  }

  .TableSprayTask_columnBlock__3M9gw,
  .TableSprayTask_columnSize__8tTaJ,
  .TableSprayTask_columnProgress__364d5 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .TableSprayTask_columnProgress__364d5 > div {
    margin: 0 auto;
  }
  .TableSprayTask_printHolder__366nY {
    display: inline-block;
  }

  .ui.basic.segment.TableSprayTask_tasksListRow__24gYF {
    border: none;
  }
  .ui.basic.segment.TableSprayTask_tasksListRow__24gYF:last-child {
    border-bottom: none;
  }
  .ui.basic.segment.TableSprayTask_tasksListRow__24gYF:hover {
    background: var(--color-row-hover);
  }

  .ui.grid > .row > .column.TableSprayTask_columnActions__1Tt_S {
    position: inherit;
    top: 0;
    right: 0;
  }

  .TableSprayTask_columnAvatar__G8rQX {
    text-align: center;
  }

  .column.TableSprayTask_columnBlock__3M9gw {
    padding-left: 15px;
  }

  i.icon.TableSprayTask_iconEye__3oqce {
    display: inline-block;
    margin-left: 15px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .ui.TableSprayTask_popup__3zqSU {
    display: none !important;
  }

  i.icon.TableSprayTask_iconEye__3oqce {
    margin-left: 1rem;
    position: absolute;
    left: 50%;
    top: 0;
    margin-top: 0;
  }
}

.ui.grid.TableSprayTask_tasksListRowGrid__i6qUU .column {
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 992px) and (max-width: 1919px) {
  .ui.grid.TableSprayTask_tasksListRowGrid__i6qUU .column:nth-child(1) {
    width: 9% !important;
  }
  .ui.grid.TableSprayTask_tasksListRowGrid__i6qUU .column:nth-child(4) {
    width: 9.75% !important;
  }
}

@media screen and (min-width: 1600px) {
  i.icon.TableSprayTask_iconEye__3oqce {
    vertical-align: middle;
    display: inline-block;
  }
  .ui.grid > .row > .column.TableSprayTask_columnActions__1Tt_S .icon.print {
    margin-right: 10px;
  }

  .ui.button.button-text.TableSprayTask_buttonMore__2GuWw {
    margin-left: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ui.grid.TableSprayTask_tasksListRowGrid__i6qUU .column:not(.TableSprayTask_columnBlock__3M9gw) {
    padding-left: 5px;
  }

  .ui.grid.TableSprayTask_tasksListRowGrid__i6qUU .column {
    padding-right: 5px;
    font-size: 12px;
  }

  i.icon.TableSprayTask_iconEye__3oqce {
    display: block;
    margin: 0 auto;
  }
}

.TableSprayTask_sprayTaskColumns__2Un2y {
}
/* 
.areasColumn {
  padding-left: 20px !important;
}
 */

.TaskSeenIcon_green__sCFTL {
  color: var(--color-green);
}

.TaskSeenIcon_grey__2KHzC {
  color: var(--color-icon);
}
@media (max-width: 767px) {
  .ui.TaskSeenIcon_popup__1T9x1 {
    display: none !important;
  }
}

.CircularProgressbar_barHolder__3M72F {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.CircularProgressbar_barHolder__3M72F .CircularProgressbar {
  position: relative;
  z-index: 2;
}

.CircularProgressbar_text__O8QfP {
  color: var(--color-green);
}

.CircularProgressbar_text__O8QfP.CircularProgressbar_zero__2XDU- {
  color: var(--color-icon);
}

.CircularProgressbar_check__1vj5J {
  color: var(--color-green);
  font-size: 29px;
  margin: 2px 0 0 2px;
}

.CircularProgressbar-trail {
  stroke-dasharray: 8, 16.3 !important;
}

.ToggleField_valueToShow__yxFwG {
  display: inline-block;
  border-bottom: 1px dashed var(--color-border);
  cursor: pointer;
}

.ToggleField_valueToShow__yxFwG.ToggleField_disabled__3hod5 {
  border: none;
  cursor: default;
}

.ToggleField_heavyHolder__QoTrk {
  background: var(--color-background-cell);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 70px 15px 30px 15px;
  z-index: 50;
  overflow: auto;
}
.ToggleField_heavyHolder__QoTrk .ui.dropdown .menu {
  max-height: 16.02857142rem;
}

.ToggleField_textArea__2IgDH {
  width: 100%;
  resize: vertical;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--color-border);
}
.ToggleField_listView__1bRVH {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ToggleField_listView__1bRVH li {
}

.ToggleField_listView__1bRVH li + li {
  margin-top: 5px;
}

@media screen and (min-width: 768px) {
  .ToggleField_heavyHolder__QoTrk {
    padding: 115px 25px 30px 25px;
  }
}

.ToggleField_textButton__3R0tu.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}
.ToggleField_textButtonContainer__20YQK {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}
.ToggleField_textButton__3R0tu.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.ToggleField_inputWrapper__1XTlS {
  display: flex;
}

.ToggleField_inputButtonWrapper__JbidM {
  text-align: end;
  margin-bottom: 10px !important;
}

.ToggleField_batchNumberInput__2yMQn {
  width: 88%;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.ToggleField_batchNumberInput__2yMQn input {
  border: none !important;
}

.ToggleField_ingredientSection__1EzpJ {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.ToggleField_removeActiveIngredient__2dcgO {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}

.CollapsibleBlock_titleContainer__bHHIw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CollapsibleBlock_titleContainer__bHHIw .CollapsibleBlock_editButtonContainer__2mCim {
  visibility: hidden;
}

.CollapsibleBlock_titleContainer__bHHIw:hover .CollapsibleBlock_editButtonContainer__2mCim {
  visibility: visible;
}

.CollapsibleBlock_editButtonContainer__2mCim {
  display: inline-flex;
  height: 28px;
  margin-right: auto;
  justify-content: start;
  align-items: center;
  margin-left: 8px;
}

.CollapsibleBlock_editButtonContainer__2mCim button {
  position: relative;
}

.CollapsibleBlock_editButtonContainer__2mCim button > i {
  position: absolute;
  left: 10px;
  bottom: 13px;
}

.CollapsibleBlock_editButtonContainer__2mCim button > i:hover {
  background-color: transparent !important;
}

.CollapsibleBlock_title__37LGy.ui.header {
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

.CollapsibleBlock_titleContainer__bHHIw i {
  border-radius: 20px;
  height: 24px;
  width: 24px;
  line-height: 22px;
}

.CollapsibleBlock_titleContainer__bHHIw i:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}

.batchNumberField_root__1CvIa :not(i.icon),
.batchNumberField_root__1CvIa .ui.header {
  font-family: Roboto, sans-serif !important;
}

.batchNumberField_root__1CvIa .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.batchNumberField_root__1CvIa .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.batchNumberField_root__1CvIa .ui.grid {
  margin: 0;
}

.batchNumberField_minRateInput__2jriq {
  position: relative;
}

.batchNumberField_minRateInput__2jriq:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.batchNumberField_minRateInput__2jriq div {
  height: 40px !important;
}

.batchNumberField_maxRateInput__17k5D div {
  height: 40px !important;
}

.batchNumberField_modalContentContainer__3ZuA1 {
  min-height: 70vh;
  scrollbar-width: none;
}

.batchNumberField_modalContentContainer__3ZuA1::-webkit-scrollbar {
  display: none;
}

.batchNumberField_removeActiveIngredient__AlWYN {
  color: #b00020;
  margin-right: 0 !important;
}

.batchNumberField_textButton__2fCFS.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.batchNumberField_textButton__2fCFS.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.batchNumberField_textButtonContainer__OW5rQ {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.batchNumberField_ingredientHeader__3ZTJ_ {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.batchNumberField_ingredientSection__12w5R {
  display: flex;
  justify-content: space-between;
}

.batchNumberField_activeIngredientMessage__3fszB .ui.header {
  margin: 0 !important;
}

.batchNumberField_attachmentLabel__2ni0F {
  margin-right: auto !important;
}

.batchNumberField_generalAttachmentsContainer__39qju {
  display: flex !important;
  flex-wrap: wrap;
}

.batchNumberField_generalAttachmentsButton__abuha {
  margin-left: auto !important;
}

.batchNumberField_root__1CvIa .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.batchNumberField_root__1CvIa .batchNumberField_chemicalPropertyColumn__1Hxs0 {
  padding-left: 0 !important;
}

.batchNumberField_root__1CvIa .batchNumberField_chemicalPropertyColumn__1Hxs0 .field {
  margin-bottom: 16px !important;
}

.batchNumberField_root__1CvIa .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.batchNumberField_confirmModal__3nGtr {
  margin: 30%;
}

.ui.modal.batchNumberField_root__1CvIa .actions {
  background-color: white !important;
}

.batchNumberField_cancelButton__OtIrB {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.batchNumberField_cancelButton__OtIrB:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.batchNumberField_saveButton__8s2Mc {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.batchNumberField_saveButton__8s2Mc:hover {
  background: #156941 !important;
}

.batchNumberField_saveButton__8s2Mc:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.batchNumberField_trackInventoryButtonYes__1StsA {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.batchNumberField_trackInventoryButtonNo__pmbWv {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.batchNumberField_noButtonIcon__AUnMP {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.batchNumberField_yesButtonIcon__2k8mt {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.batchNumberField_inputWrapper__2-CYG {
  display: flex;
}

.batchNumberField_inputButtonWrapper__2vUda {
  text-align: end;
}

.batchNumberField_batchNumberInput__2kRoW {
  margin: 10px !important;
}

.BatchNumberInput_root__1_DAa :not(i.icon),
.BatchNumberInput_root__1_DAa .ui.header {
  font-family: Roboto, sans-serif !important;
}

.BatchNumberInput_root__1_DAa .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__1_DAa .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__1_DAa .ui.grid {
  margin: 0;
}

.BatchNumberInput_minRateInput__210Ck {
  position: relative;
}

.BatchNumberInput_minRateInput__210Ck:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.BatchNumberInput_minRateInput__210Ck div {
  height: 40px !important;
}

.BatchNumberInput_maxRateInput__tU17m div {
  height: 40px !important;
}

.BatchNumberInput_modalContentContainer__ZNjKl {
  min-height: 70vh;
  scrollbar-width: none;
}

.BatchNumberInput_modalContentContainer__ZNjKl::-webkit-scrollbar {
  display: none;
}

.BatchNumberInput_removeActiveIngredient__3P-Pc {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}

.BatchNumberInput_textButton__1iCBV.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButton__1iCBV.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButtonContainer__1W_O6 {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}

.BatchNumberInput_ingredientHeader__UVidi {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.BatchNumberInput_ingredientSection__4jDmJ {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
}

.BatchNumberInput_activeIngredientMessage__26xCz .ui.header {
  margin: 0 !important;
}

.BatchNumberInput_attachmentLabel__3KfgK {
  margin-right: auto !important;
}

.BatchNumberInput_generalAttachmentsContainer__1HylY {
  display: flex !important;
  flex-wrap: wrap;
}

.BatchNumberInput_generalAttachmentsButton__1dxbX {
  margin-left: auto !important;
}

.BatchNumberInput_root__1_DAa .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.BatchNumberInput_root__1_DAa .BatchNumberInput_chemicalPropertyColumn__2qkDq {
  padding-left: 0 !important;
}

.BatchNumberInput_root__1_DAa .BatchNumberInput_chemicalPropertyColumn__2qkDq .field {
  margin-bottom: 16px !important;
}

.BatchNumberInput_root__1_DAa .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.BatchNumberInput_confirmModal__1aTm5 {
  margin: 30%;
}

.ui.modal.BatchNumberInput_root__1_DAa .actions {
  background-color: white !important;
}

.BatchNumberInput_cancelButton__35wya {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.BatchNumberInput_cancelButton__35wya:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.BatchNumberInput_saveButton__3sGsb {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.BatchNumberInput_saveButton__3sGsb:hover {
  background: #156941 !important;
}

.BatchNumberInput_saveButton__3sGsb:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.BatchNumberInput_trackInventoryButtonYes__2f4og {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.BatchNumberInput_trackInventoryButtonNo__bQy79 {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.BatchNumberInput_noButtonIcon__KIqBY {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_yesButtonIcon__1m8Dq {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_inputWrapper__O_kps {
  display: flex;
}

.BatchNumberInput_inputButtonWrapper__guzyq {
  text-align: end;
  margin-bottom: 10px !important;
}

.BatchNumberInput_batchNumberInput__1fqmb {
  width: 88%;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.BatchNumberInput_batchNumberInput__1fqmb input {
  border: none !important;
}

.batchNumberField_root__1AsUx :not(i.icon),
.batchNumberField_root__1AsUx .ui.header {
  font-family: Roboto, sans-serif !important;
}

.batchNumberField_root__1AsUx .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.batchNumberField_root__1AsUx .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.batchNumberField_root__1AsUx .ui.grid {
  margin: 0;
}

.batchNumberField_minRateInput__3gpqE {
  position: relative;
}

.batchNumberField_minRateInput__3gpqE:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.batchNumberField_minRateInput__3gpqE div {
  height: 40px !important;
}

.batchNumberField_maxRateInput__LALVs div {
  height: 40px !important;
}

.batchNumberField_modalContentContainer__1S7GT {
  min-height: 70vh;
  scrollbar-width: none;
}

.batchNumberField_modalContentContainer__1S7GT::-webkit-scrollbar {
  display: none;
}

.batchNumberField_removeActiveIngredient__2VWCK {
  color: #b00020;
  margin-right: 0 !important;
}

.batchNumberField_textButton__1YKZQ.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.batchNumberField_textButton__1YKZQ.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.batchNumberField_textButtonContainer__3cnO7 {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.batchNumberField_ingredientHeader__2MzG0 {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.batchNumberField_ingredientSection__1HhWc {
  display: flex;
  justify-content: space-between;
}

.batchNumberField_activeIngredientMessage__20VPY .ui.header {
  margin: 0 !important;
}

.batchNumberField_attachmentLabel__3c8iu {
  margin-right: auto !important;
}

.batchNumberField_generalAttachmentsContainer__3indp {
  display: flex !important;
  flex-wrap: wrap;
}

.batchNumberField_generalAttachmentsButton__12N4Q {
  margin-left: auto !important;
}

.batchNumberField_root__1AsUx .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.batchNumberField_root__1AsUx .batchNumberField_chemicalPropertyColumn__Pwiyj {
  padding-left: 0 !important;
}

.batchNumberField_root__1AsUx .batchNumberField_chemicalPropertyColumn__Pwiyj .field {
  margin-bottom: 16px !important;
}

.batchNumberField_root__1AsUx .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.batchNumberField_confirmModal__KKFMP {
  margin: 30%;
}

.ui.modal.batchNumberField_root__1AsUx .actions {
  background-color: white !important;
}

.batchNumberField_cancelButton__21fS9 {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.batchNumberField_cancelButton__21fS9:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.batchNumberField_saveButton__1W33_ {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.batchNumberField_saveButton__1W33_:hover {
  background: #156941 !important;
}

.batchNumberField_saveButton__1W33_:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.batchNumberField_trackInventoryButtonYes__-Ufbl {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.batchNumberField_trackInventoryButtonNo__3pPPp {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.batchNumberField_noButtonIcon__MTsyx {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.batchNumberField_yesButtonIcon__1ToLD {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.batchNumberField_inputWrapper__2c-qY {
  display: flex;
}

.batchNumberField_inputButtonWrapper__1G989 {
  text-align: end;
}

.batchNumberField_batchNumberInput__R9aO7 {
  margin: 10px !important;
}

.BatchNumberInput_root__1GVLg :not(i.icon),
.BatchNumberInput_root__1GVLg .ui.header {
  font-family: Roboto, sans-serif !important;
}

.BatchNumberInput_root__1GVLg .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__1GVLg .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__1GVLg .ui.grid {
  margin: 0;
}

.BatchNumberInput_minRateInput__1RNEx {
  position: relative;
}

.BatchNumberInput_minRateInput__1RNEx:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.BatchNumberInput_minRateInput__1RNEx div {
  height: 40px !important;
}

.BatchNumberInput_maxRateInput__2_Zu5 div {
  height: 40px !important;
}

.BatchNumberInput_modalContentContainer__1AbNJ {
  min-height: 70vh;
  scrollbar-width: none;
}

.BatchNumberInput_modalContentContainer__1AbNJ::-webkit-scrollbar {
  display: none;
}

.BatchNumberInput_removeActiveIngredient__1jgkD {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}

.BatchNumberInput_textButton__1usx1.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButton__1usx1.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButtonContainer__EH-XB {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}

.BatchNumberInput_ingredientHeader__3YzfY {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.BatchNumberInput_ingredientSection__r10lp {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
}

.BatchNumberInput_activeIngredientMessage__1lIJF .ui.header {
  margin: 0 !important;
}

.BatchNumberInput_attachmentLabel__x8hu7 {
  margin-right: auto !important;
}

.BatchNumberInput_generalAttachmentsContainer__2sjTK {
  display: flex !important;
  flex-wrap: wrap;
}

.BatchNumberInput_generalAttachmentsButton__Iv8Oj {
  margin-left: auto !important;
}

.BatchNumberInput_root__1GVLg .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.BatchNumberInput_root__1GVLg .BatchNumberInput_chemicalPropertyColumn__2d09j {
  padding-left: 0 !important;
}

.BatchNumberInput_root__1GVLg .BatchNumberInput_chemicalPropertyColumn__2d09j .field {
  margin-bottom: 16px !important;
}

.BatchNumberInput_root__1GVLg .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.BatchNumberInput_confirmModal__nC7WQ {
  margin: 30%;
}

.ui.modal.BatchNumberInput_root__1GVLg .actions {
  background-color: white !important;
}

.BatchNumberInput_cancelButton__wpOyg {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.BatchNumberInput_cancelButton__wpOyg:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.BatchNumberInput_saveButton__160Xr {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.BatchNumberInput_saveButton__160Xr:hover {
  background: #156941 !important;
}

.BatchNumberInput_saveButton__160Xr:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.BatchNumberInput_trackInventoryButtonYes__1PuSk {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.BatchNumberInput_trackInventoryButtonNo__2Kplu {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.BatchNumberInput_noButtonIcon__3hooo {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_yesButtonIcon__3xP3H {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_inputWrapper__2luWc {
  display: flex;
}

.BatchNumberInput_inputButtonWrapper__2y1bw {
  text-align: end;
  margin-bottom: 10px !important;
}

.BatchNumberInput_batchNumberInput__lf4el {
  width: 88%;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.BatchNumberInput_batchNumberInput__lf4el input {
  border: none !important;
}

.BatchNumberInput_root__1UAfB :not(i.icon),
.BatchNumberInput_root__1UAfB .ui.header {
  font-family: Roboto, sans-serif !important;
}

.BatchNumberInput_root__1UAfB .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__1UAfB .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__1UAfB .ui.grid {
  margin: 0;
}

.BatchNumberInput_minRateInput__1N02k {
  position: relative;
}

.BatchNumberInput_minRateInput__1N02k:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.BatchNumberInput_minRateInput__1N02k div {
  height: 40px !important;
}

.BatchNumberInput_maxRateInput__mXzjL div {
  height: 40px !important;
}

.BatchNumberInput_modalContentContainer__3wBFI {
  min-height: 70vh;
  scrollbar-width: none;
}

.BatchNumberInput_modalContentContainer__3wBFI::-webkit-scrollbar {
  display: none;
}

.BatchNumberInput_removeActiveIngredient__1ahJU {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}

.BatchNumberInput_textButton__3WwEi.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButton__3WwEi.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButtonContainer__2T-gf {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}

.BatchNumberInput_ingredientHeader__1-5Fe {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.BatchNumberInput_ingredientSection__2MYdp {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
}

.BatchNumberInput_activeIngredientMessage__G-rcL .ui.header {
  margin: 0 !important;
}

.BatchNumberInput_attachmentLabel__2VCAb {
  margin-right: auto !important;
}

.BatchNumberInput_generalAttachmentsContainer__uc8h1 {
  display: flex !important;
  flex-wrap: wrap;
}

.BatchNumberInput_generalAttachmentsButton__3fWDE {
  margin-left: auto !important;
}

.BatchNumberInput_root__1UAfB .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.BatchNumberInput_root__1UAfB .BatchNumberInput_chemicalPropertyColumn__1aJo1 {
  padding-left: 0 !important;
}

.BatchNumberInput_root__1UAfB .BatchNumberInput_chemicalPropertyColumn__1aJo1 .field {
  margin-bottom: 16px !important;
}

.BatchNumberInput_root__1UAfB .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.BatchNumberInput_confirmModal__3Hsoa {
  margin: 30%;
}

.ui.modal.BatchNumberInput_root__1UAfB .actions {
  background-color: white !important;
}

.BatchNumberInput_cancelButton__1pJiO {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.BatchNumberInput_cancelButton__1pJiO:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.BatchNumberInput_saveButton__3I-ej {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.BatchNumberInput_saveButton__3I-ej:hover {
  background: #156941 !important;
}

.BatchNumberInput_saveButton__3I-ej:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.BatchNumberInput_trackInventoryButtonYes__1HVD9 {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.BatchNumberInput_trackInventoryButtonNo__USTK5 {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.BatchNumberInput_noButtonIcon__39t9o {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_yesButtonIcon__YrP1R {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_inputWrapper__1cbPK {
  display: flex;
}

.BatchNumberInput_inputButtonWrapper__3Ud5W {
  text-align: end;
  margin-bottom: 10px !important;
}

.BatchNumberInput_batchNumberInput__11fUH {
  width: 88%;
  opacity: 1 !important;
  color: black !important;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.BatchNumberInput_batchNumberInput__11fUH input {
  border: none !important;
}

.BatchNumberInput_root__XIqRO :not(i.icon),
.BatchNumberInput_root__XIqRO .ui.header {
  font-family: Roboto, sans-serif !important;
}

.BatchNumberInput_root__XIqRO .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__XIqRO .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.BatchNumberInput_root__XIqRO .ui.grid {
  margin: 0;
}

.BatchNumberInput_minRateInput__i-NL9 {
  position: relative;
}

.BatchNumberInput_minRateInput__i-NL9:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.BatchNumberInput_minRateInput__i-NL9 div {
  height: 40px !important;
}

.BatchNumberInput_maxRateInput__36Xbn div {
  height: 40px !important;
}

.BatchNumberInput_modalContentContainer__aWQTl {
  min-height: 70vh;
  scrollbar-width: none;
}

.BatchNumberInput_modalContentContainer__aWQTl::-webkit-scrollbar {
  display: none;
}

.BatchNumberInput_removeActiveIngredient__36OFw {
  color: #b00020;
  margin-right: 0 !important;
}

.BatchNumberInput_textButton__3lJd9.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButton__3lJd9.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.BatchNumberInput_textButtonContainer__2Z5hQ {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.BatchNumberInput_ingredientHeader__P0dP_ {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.BatchNumberInput_ingredientSection__1hARZ {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}

.BatchNumberInput_activeIngredientMessage__2doJu .ui.header {
  margin: 0 !important;
}

.BatchNumberInput_attachmentLabel__1PwhH {
  margin-right: auto !important;
}

.BatchNumberInput_generalAttachmentsContainer__1qiTx {
  display: flex !important;
  flex-wrap: wrap;
}

.BatchNumberInput_generalAttachmentsButton__1eRqn {
  margin-left: auto !important;
}

.BatchNumberInput_root__XIqRO .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.BatchNumberInput_root__XIqRO .BatchNumberInput_chemicalPropertyColumn__mxWyN {
  padding-left: 0 !important;
}

.BatchNumberInput_root__XIqRO .BatchNumberInput_chemicalPropertyColumn__mxWyN .field {
  margin-bottom: 16px !important;
}

.BatchNumberInput_root__XIqRO .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.BatchNumberInput_confirmModal__a6zgP {
  margin: 30%;
}

.ui.modal.BatchNumberInput_root__XIqRO .actions {
  background-color: white !important;
}

.BatchNumberInput_cancelButton__2Kq-B {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.BatchNumberInput_cancelButton__2Kq-B:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.BatchNumberInput_saveButton__3gX2Y {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.BatchNumberInput_saveButton__3gX2Y:hover {
  background: #156941 !important;
}

.BatchNumberInput_saveButton__3gX2Y:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.BatchNumberInput_trackInventoryButtonYes__3oQoi {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.BatchNumberInput_trackInventoryButtonNo__n6Zw2 {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.BatchNumberInput_noButtonIcon__3FE_M {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_yesButtonIcon__3lZ-a {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.BatchNumberInput_inputWrapper__2q0Mc {
  display: flex;
}

.BatchNumberInput_inputButtonWrapper__1C3HA {
  text-align: end;
}

.BatchNumberInput_batchNumberInput__17RrR {
  margin: 10px !important;
}

.TaskRow_label__15ID6 {
  color: var(--color-dark);
}
.TaskRow_batchNumberLabel__l1xvC {
  height: 100% !important;
  /* padding-top: 14px !important; */
  color: var(--color-dark);
}
.TaskRow_sideBarBatchNumberLabel__2_tGr {
  height: 100% !important;
  color: var(--color-dark);
}

.ui.grid > .row.TaskRow_taskRow__g48o_ {
  padding-bottom: 0;
}

.column.TaskRow_componentHolder__2YDkl .ui.compact.menu {
  transform: translateZ(0);
}

.TaskRow_requiredField__3bHH5 {
  color: var(--color-pink);
}

.ui.grid.TaskRow_fieldGrid__2hH28 {
  margin: 0;
}

.ui.grid.TaskRow_fieldGrid__2hH28 > .row > div {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .ui.grid.TaskRow_fieldGrid__2hH28 > .row {
    flex-wrap: nowrap;
  }
  .ui.grid.TaskRow_fieldGrid__2hH28 > .row > [class*="one wide"].column {
    width: auto !important;
    flex: auto;
  }

  .ui.grid > .row.TaskRow_taskRow__g48o_.TaskRow_wideRow__lpTKc > div + div {
    margin-top: 0;
  }
}
.TaskRow_textButton__1XRsc.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}
.TaskRow_textButtonContainer__HqWo7 {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}
.TaskRow_textButton__1XRsc.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.TaskRow_inputWrapper__3EtTx {
  display: flex;
}

.TaskRow_inputButtonWrapper__1g8sk {
  text-align: end;
  margin-bottom: 10px !important;
}

.TaskRow_batchNumberInput__2zfrH {
  width: 88%;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.TaskRow_batchNumberInput__2zfrH input {
  border: none !important;
}

.TaskRow_ingredientSection__1erA- {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
}

.TaskRow_removeActiveIngredient__pB_jC {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}
.TaskRow_test__1dOn6 {
  height: 100%;
}

.AreasTable_areasTableWrapper__1lntD {
  padding: 10px;
  margin-top: 15px;
  font-size: 12px;
  background-color: white;
  overflow: auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ui.table.AreasTable_areasTable__IurGg {
  border: none;
  margin-bottom: 0;
}

.ui.table.AreasTable_areasTable__IurGg tbody tr:not(:last-of-type) td {
  border-top: none;
}

.ui.table.AreasTable_areasTable__IurGg tbody tr td.AreasTable_withInput__26G_C {
  width: 20%;
}

.ui.table.AreasTable_areasTable__IurGg thead tr th,
.ui.table.AreasTable_areasTable__IurGg tbody {
  background-color: white;
}

.ui.table.AreasTable_areasTable__IurGg tbody tr td:first-child {
  padding-left: 9.42857px !important;
}

.ui.table.AreasTable_areasTable__IurGg tbody tr td:last-child {
  padding-right: 9.42857px !important;
}

.AreasTable_lastRow__30ztC {
  font-weight: bold;
}

.AreasTable_variety__3j83W {
  display: block;
  font-size: 11px;
  color: var(--color-middle);
  font-style: italic;
}

.AreasTable_vacant__3eCxt {
  color: var(--color-vacant-blue);
}

.AreasTable_tanksAdditionalInfo__39Gpx {
  font-weight: bold;
}

.Tabs_windHolder__3u7YY {
}

.Tabs_viewSelector__3NNxR {
  text-decoration: underline;
  cursor: pointer;
  color: gray;
  font-size: 1rem;
  min-width: 20%;
  padding-left: 1rem;
  float: right;
}
.Tabs_viewSelector__3NNxR:hover {
  color: black;
}

.Tabs_tabHolder__1Lzs6 .ui.grid + .ui.header {
  margin-top: 40px;
}

.Tabs_tabHolder__1Lzs6 input {
  transform: translateZ(0);
}

.Tabs_tabHolder__1Lzs6.Tabs_fill__gMgDM {
  height: 500px;
}

.ui.compact.menu.Tabs_groupMenu__3WOGA {
  display: flex;
  flex-wrap: wrap;
}

.ui.segment.Attachments_attachments__1BTTs {
  padding: 0;
}

.Attachments_blockBox__gQjlQ {
  margin-top: 15px;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}

.Attachments_blockName__2B3ec {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.Attachments_colHead__16mhb {
  color: var(--color-header);
  font-size: 14px;
  text-transform: uppercase;
}

.Attachments_colBody__22xTc {
  color: var(--color-text);
}

.Attachments_blockCol__1Clse {
}

.Attachments_buttonsCol__3jjbv {
  text-align: right;
}

.Attachments_buttonsCol__3jjbv button.ui.button {
  padding: 0;
  background: none;
}

.Attachments_buttonsCol__3jjbv button.ui.button:first-child {
  margin-right: 10px;
}

.Attachments_listHolder__1XYFE {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.Attachments_listHolder__1XYFE a {
  font-weight: bold;
}

.Attachments_listHolder__1XYFE li {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 15px;
}

.Attachments_listHolder__1XYFE li > div:first-child {
  flex: 0 0 60px;
  text-align: center;
}

.Attachments_listHolder__1XYFE li > div:nth-child(2) {
  flex: 1 1 auto;
  padding-left: 15px;
}

.Attachments_listHolder__1XYFE li > div:nth-child(3) {
  flex: 0 0 30px;
}

.Attachments_loaderHolder__3VU2z {
  width: 60px;
  height: 60px;
}

.ui.label.Attachments_attachmentLabel__2EvmP {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
  height: 24px !important;
  display: flex;
  align-items: center;
  max-width: 145px;
  width: 145px;
  margin: 0px !important;
  margin-bottom: 2px !important;
}

.ui.label.Attachments_withoutCloseIcon__36g-5 {
  border-radius: 20px;
  height: 24px !important;
  display: flex;
  align-items: center;
  max-width: 155px;
  width: 155px;
  margin: 0px;
  margin-bottom: 2px !important;
}

.icon.Attachments_downloadButton__3J0Aw {
  width: 9px !important;
  height: 9px !important;
  color: var(--color-green) !important;
}

.icon.Attachments_attachmentIcon__32BV6 {
  color: #666666;
}

.Attachments_deleteButtonContainer__3Ivsk {
  border: 1px solid #e0e0e0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  border-left: none;
  height: 24px;
  align-items: center;
  width: 20px;
  padding-bottom: 2px;
  padding-right: 10px !important;
  cursor: pointer;
  margin-right: 4px !important;
}
.icon.Attachments_deleteButton__3g8ib {
  padding-top: 3px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--color-error-delete) !important;
  opacity: 1 !important;
}

.Attachments_attachmentTitle__1iol5 {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  padding-top: 2px;
  font-size: 12px !important;
  line-height: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400 !important;
  margin-right: auto;
}

.Attachments_uploadingAttachmentTitle__23vSR {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
  font-weight: 400 !important;
}

.Attachments_percentNumber__kx0Dg {
  color: #666666;
  margin-right: 5px;
  font-weight: 400 !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.TaskHeader_taskHeader__35TWl {
  background: #fff;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  position: relative;
  z-index: 100;
}

.ui.button.TaskHeader_buttonSave__fXpR0 {
  color: var(--color-grass);
}

.TaskHeader_taskName__tGk5h {
  text-align: center;
}
.TaskHeader_taskName__tGk5h .ui.header {
  margin-bottom: 0;
}
.TaskHeader_taskActions__3Twhl .ui.button {
  margin-right: 0;
}

.TaskHeader_taskActions__3Twhl button:not(:first-child) {
  margin-left: 20px;
}

.TaskHeader_taskActions__3Twhl .ui.button > .icon:not(.button) {
  height: auto;
  margin-right: 0 !important;
}
@media screen and (min-width: 768px) {
  .TaskHeader_taskHeader__35TWl {
    background: transparent;
    box-shadow: none;
    padding: 25px 20px 25px 25px;
    border-bottom: 1px solid var(--color-border);
  }

  .TaskHeader_taskName__tGk5h {
    text-align: left;
  }
  .TaskHeader_taskName__tGk5h .ui.header {
    font-size: 24px;
  }
}

.MachineryForm_sprayGrid__2Y-Dj {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.MachineryForm_sprayGrid__2Y-Dj.MachineryForm_forModal__3DNme > div {
  padding: 0;
}

.MachineryForm_sprayGrid__2Y-Dj.MachineryForm_forModal__3DNme > div + div {
  margin-top: 15px;
}
.MachineryForm_sprayGrid__2Y-Dj.MachineryForm_forModal__3DNme .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.MachineryForm_sprayGrid__2Y-Dj:not(.MachineryForm_forModal__3DNme) .ui.segment.MachineryForm_sprayTasksSidebarIn__1_HNT {
  flex: auto;
}

.MachineryForm_sprayFormFooter__2g49i {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.MachineryForm_sprayFormContainer__1HdBm {
  overflow: auto;
  padding: 30px 15px;
}

.MachineryForm_sprayFormContainer__1HdBm > div {
  max-width: 900px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
}

.logo-and-title > div {
  padding-left: 15px;
}

.symbol-tuf {
  background: url(/static/media/symbol.7a6754ad.svg) no-repeat left;
  background-size: contain;
  height: 35px;
  width: 35px;
}

.vertical-middle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-weight: bold;
}

.logo-column .title {
  font-weight: 600;
  margin-left: 15px;
  font-size: 16px;
  font-family: "Montserrat", "Lato", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
}

.vertical-middle.center {
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.vertical-middle.right {
  justify-content: flex-end;
}

.ui.grid > .row > .column.column-close {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.column-close .icon {
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

.logo-holder-small {
  margin: -12px 0;
  height: 50px;
}

.logo-holder-small img {
  width: 50px;
}

.close-icon {
  font-size: 24px;
  cursor: pointer;
  color: var(--color-header);
}

.with-footer .column-close {
  order: 1;
}

.with-footer .logo-column {
  order: 2;
}

.ui.grid.with-footer .column.column-buttons {
  order: 3;
  margin: -5px 0;
}

.ui.grid.with-footer .column.column-buttons .ui.button.large {
  font-size: 1rem;
}

.ui.grid.with-footer [class*="right floated"].column.column-close {
  margin-left: 0;
  border-left: none;
  border-right: 1px solid var(--color-border);
  padding: 0 15px;
}

.ui.grid.with-footer > .row {
  flex-wrap: nowrap;
}

.ui.grid.with-footer > .row > .column {
  width: auto !important;
  flex: 0 0 auto;
}

.ui.grid.with-footer > .row > .column.logo-column {
  flex: 1 1 auto;
}

@media screen and (min-width: 768px) {
  .symbol-tuf {
    height: 48px;
    width: 48px;
  }
}

.LayoutOverlay_overlayHolder__hJsjf {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.LayoutOverlay_overlayHolder__hJsjf.LayoutOverlay_noOverflow__1lxSD {
  height: auto;
}

.LayoutOverlay_overlayMain__2JMX6 {
  background: var(--color-background);
  flex: auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.LayoutOverlay_overlayMain__2JMX6 form,
.LayoutOverlay_overlayMain__2JMX6 form > .ui.form {
  flex: auto;
  display: flex;
  height: 100%;
}

.ui.grid.LayoutOverlay_sprayGrid__2mesZ {
  flex: 1 1 auto;
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  margin-left: 0;
  margin-right: 0;
}

.ui.grid.LayoutOverlay_sprayGrid__2mesZ > .row {
  padding: 0;
}

.ui.grid.LayoutOverlay_sprayGrid__2mesZ > .row > div {
  display: flex;
  padding-top: 30px;
  padding-bottom: 15px;
}

.ui.segment.LayoutOverlay_sprayTasksSidebarIn__1evFP {
  flex: auto;
  width: 100%;
}

.ui.form .LayoutOverlay_overlayMain__2JMX6 .field label,
.LayoutOverlay_overlayMain__2JMX6 .ui.form .field label,
.LayoutOverlay_overlayMain__2JMX6 .field label {
  margin-bottom: 5px;
  display: block;
  color: var(--color-dark);
  font-weight: normal;
  font-size: 1rem;
}

.ui.form .LayoutOverlay_overlayMain__2JMX6 .required.field label:after,
.LayoutOverlay_overlayMain__2JMX6 .required.field label:after {
  color: var(--color-pink);
  content: "*";
  margin-left: 5px;
}

.LayoutOverlay_overlayMain__2JMX6 .required.field .ui.checkbox label:after {
  margin-left: 0;
}

.LayoutOverlay_overlayMain__2JMX6 .ui.header {
  margin-top: 0;
}

.LayoutOverlay_overlayMain__2JMX6 .ui.grid + .ui.header,
.LayoutOverlay_overlayMain__2JMX6 .ui.grid > .row + .ui.header {
  margin-top: 2rem;
}

.LayoutOverlay_sprayFormContainer__e_FZZ {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.LayoutOverlay_overlayMain__2JMX6 div > .ui.segment + .ui.segment {
  margin-top: 1rem;
}

.LayoutOverlay_overlayMain__2JMX6 .field.disabled {
  opacity: 0.5;
}

.LayoutOverlay_overlayMain__2JMX6 .field > label {
  margin-bottom: 5px;
  display: block;
  color: var(--color-dark);
}

.LayoutOverlay_overlayMain__2JMX6 .field > label.error {
  color: var(--color-pink);
}

.LayoutOverlay_overlayMain__2JMX6 .field > label .ui.checkbox {
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.LayoutOverlay_overlayMain__2JMX6 .ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}

.LayoutOverlay_overlayMain__2JMX6 .ui.grid > .row {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.ui.menu.sprayMenu {
  margin: 0;
  min-height: auto;
  box-shadow: none;
}

.ui.fluid.menu.sprayMenu {
  display: flex;
  justify-content: space-around;
}

.ui.menu.sprayMenu .item {
  border-radius: 3px !important;
  margin: 2px;
  padding: 10px;
  justify-content: center;
}

.ui.menu.sprayMenu.rolesMenu .item {
  padding: 10px 8px;
}

.ui.fluid.menu.sprayMenu .item {
  flex: 1 1;
}

.ui.menu.sprayMenu .item:before {
  display: none;
}

.ui.menu.sprayMenu .item:not(:first-child):before {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px;
  width: 1px;
  background: var(--color-light-grey);
  display: block;
}

.ui.menu.sprayMenu .active.item {
  background: var(--color-background-label);
  color: #fff;
}

.ui.menu.sprayMenu .active.item.disabled {
  background: var(--color-light-grey) !important;
  color: #fff;
}

.ui.menu.sprayMenu .active.item:before,
.ui.menu.sprayMenu .active.item + .item:before {
  display: none;
}

.LayoutOverlay_sprayFormFooter__2fZQ4 {
  padding: 10px 0;
  border-top: 1px solid var(--color-background-secondary);
}

@media (max-width: 767px) {
  .ui.menu.sprayMenu:not(.compact) {
    display: block;
  }

  .ui.menu.sprayMenu:not(.compact) .item {
    display: block;
    float: left;
  }

  .LayoutOverlay_overlayMain__2JMX6 .ui.grid > .row > .column + .column {
    margin-top: 1rem;
  }

  .LayoutOverlay_overlayMain__2JMX6 .LayoutOverlay_sprayFormFooter__2fZQ4 .ui.grid > .row > .column + .column {
    margin-top: 0;
  }

  .ui.grid.LayoutOverlay_sprayGrid__2mesZ > .row > div:not(:first-child) {
    padding-top: 0;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .LayoutOverlay_overlayMain__2JMX6
    .ui.grid > .row > [class*="sixteen wide tablet"].column + .column {
    margin-top: 2rem;
  }
}

.Machinery_formHolder__vHH4n {
  background: var(--color-background-cell);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
}

.Machinery_formHolderIn__kBQyy {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Machinery_formHolderBody__2dn6E {
  padding: 30px;
  overflow: auto;
}

.Chemicals_formHolder__3MEpq {
  background: var(--color-background-cell);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
}

.Chemicals_formHolderIn__3mpPz {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Chemicals_formHolderBody__2NHib {
  padding: 30px 15px;
  overflow: auto;
}

.Chemicals_addHolder__11QP- {
  text-align: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--color-border);
}

.ui.form.Chemicals_chemicalForm__29ljx > .ui.segment {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .Chemicals_formHolderBody__2NHib {
    padding: 30px 25px;
  }
}

.ui.form .field > label > div + .ui.SwitchButton_buttonsGroup__2feuE {
  margin-top: 0;
}

.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE .ui.button {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  justify-content: center;
  border: none;
  position: relative;
}

.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE
  :not(div)
  + .ui.button:not(:first-child):before,
.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE
  .ui.button:not(:nth-child(-n + 2)):before {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px;
  width: 1px;
  background: var(--color-light-grey);
}

.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE .ui.button.SwitchButton_buttonActive__dZclN {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  color: var(--color-green) !important;
}

.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE .ui.button.SwitchButton_buttonActive__dZclN:before,
.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE
  .ui.button.SwitchButton_buttonActive__dZclN
  + .ui.button:before {
  display: none;
}

.ui.basic.buttons.SwitchButton_buttonsGroup__2feuE div .ui.pointing.label {
  width: -webkit-max-content;
  width: max-content;
}

.AddNewChemical_sprayGrid__281qu {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.AddNewChemical_sprayGrid__281qu.AddNewChemical_forModal__q_JHE > div {
  padding: 0;
}

.AddNewChemical_sprayGrid__281qu.AddNewChemical_forModal__q_JHE > div + div {
  margin-top: 15px;
}

.AddNewChemical_sprayGrid__281qu.AddNewChemical_forModal__q_JHE .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.AddNewChemical_sprayGrid__281qu:not(.AddNewChemical_forModal__q_JHE) .ui.segment.AddNewChemical_sprayTasksSidebarIn__2I5Wh {
  flex: auto;
}

.AddNewChemical_sprayFormFooter__2SED5 {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.AddNewChemical_sprayFormContainer__2US2q {
  overflow: auto;
  padding: 30px 15px;
}

.AddNewChemical_sprayFormContainer__2US2q > div {
  max-width: 900px;
  margin: 0 auto;
}

.AddNewChemical_buttons__2s1u8 .ui.button {
  font-size: 18px;
  color: var(--color-icon);
}

.AddNewChemical_tableHolder__3iVy7 {
  /*
    min-width: 500px;
    overflow: auto;

     */
}

.ui.table.AddNewChemical_tableCrop__3F1_3 thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

.ui.table.AddNewChemical_tableCrop__3F1_3.AddNewChemical_modalMode__3djTQ thead th {
  padding-left: 2px;
  padding-right: 2px;
  font-size: 12px;
}

.ui.table.AddNewChemical_tableCrop__3F1_3.AddNewChemical_modalMode__3djTQ tbody td {
  padding-left: 2px;
  padding-right: 2px;
}

.ui.table.AddNewChemical_tableCrop__3F1_3 tbody td input {
  padding-left: 5px;
  padding-right: 5px;
}

.ui.table.AddNewChemical_tableCrop__3F1_3 tbody td:last-child {
  padding-right: 0;
}

.AddNewChemical_signRequired__137AG {
  color: #ff0000;
}
.AddNewChemical_signRequired__137AG:after {
  content: '*';
}

.AddNewChemical_selectCrop__2K35K {
  width: 100%;
}

.AddNewChemical_inputRate__zpK6Q {
}

.AddNewChemical_tableCrop__3F1_3 .ui.dropdown > .text {
  white-space: nowrap;
}
.AddNewChemical_newButtonsWrapper__1vmwY {
  display: flex;
  justify-content: flex-end;
}

.AddNewChemical_newButtonsWrapper__1vmwY .ui.button {
  margin: 0;
}

.AddNewChemical_newButtonsWrapper__1vmwY .ui.button + .ui.button {
  margin-left: 10px;
}

.AddNewChemical_newButtonsWrapper__1vmwY .ui.button .tuf-pen {
  color: var(--color-green);
}
.ui.form .AddNewChemical_tableCrop__3F1_3 .field .ui.input.labeled input {
  width: 100%;
}

.ui.button.AddNewChemical_buttonAdd__1hAta {
  white-space: nowrap;
  margin-right: 0;
}

.ui.table.AddNewChemical_tableCrop__3F1_3.AddNewChemical_modalMode__3djTQ .ui.button.AddNewChemical_buttonAdd__1hAta {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 0.25em 0 0;
}

.AddNewChemical_inputPeriod__2yzUm .ui.input {
}
.AddNewChemical_inputPeriod__2yzUm .ui.dropdown.label {
}

.AddNewChemical_inputPeriod__2yzUm .dropdown.icon {
  float: right;
}

.ui.form .field.AddNewChemical_inputPeriod__2yzUm .ui.input input {
  width: 100%;
}

.ui.icon.button.AddNewChemical_buttonFile__31Wy- {
  font-size: 22px;
  padding: 15px;
}
.ui.form .AddNewChemical_columnsEqual__1yOTg + .AddNewChemical_columnsEqual__1yOTg {
  padding-top: 0;
}

.ui.form .AddNewChemical_columnsEqual__1yOTg.fields .field .ui.input input {
  width: 100%;
}

.AddNewChemical_inputHidden__Yyyps {
  height: 0;
}

.ui.label.AddNewChemical_daysLabel__2RdAW {
  line-height: 2.5em;
}

.ui.form .field.AddNewChemical_checkBox__3YRUn {
  margin-top: 10px;
}

.ui.table.AddNewChemical_tableCrop__3F1_3 tbody td.AddNewChemical_dashField__1QfVp {
  position: relative;
  padding-right: 5px;
}

.ui.table.AddNewChemical_tableCrop__3F1_3 tbody td.AddNewChemical_dashFieldAfter__45Oyh {
  padding-left: 5px;
}

.ui.table.AddNewChemical_tableCrop__3F1_3 tbody td:last-child {
  width: 180px;
  text-align: center;
}

.AddNewChemical_dashField__1QfVp:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -2px;
  width: 5px;
  height: 1px;
  background: #333;
  display: block;
}

.AddNewChemical_cropTableHolder__1I-lV .AddNewChemical_tableCrop__3F1_3 .AddNewChemical_title__1uNZs {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  line-height: 1;
  margin-bottom: 5px;
  display: none;
}

.AddNewChemical_chemicalOverGrid__VIXSa.AddNewChemical_forModal__q_JHE > .row > div.column + div.column {
  margin-top: 30px;
}

.AddNewChemical_buttonsFooter__1ehKA .ui.button {
  margin: 0;
}

@media (max-width: 767px) {
  .AddNewChemical_cropTableHolder__1I-lV .AddNewChemical_tableCrop__3F1_3 .AddNewChemical_title__1uNZs {
    display: block;
  }
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 {
    border: none;
  }

  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 thead {
    display: none;
  }
  .ui.table.AddNewChemical_tableCrop__3F1_3 thead th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
  }

  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody {
    display: block;
    position: relative;
  }

  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr {
    display: flex;
    flex-wrap: wrap;
  }

  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
  }

  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:nth-child(1) {
    width: 50%;
  }
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:nth-child(2) {
    width: 50%;
  }
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:nth-child(3),
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:nth-child(4),
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:nth-child(5) {
    width: 33.33%;
  }
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:nth-child(6),
  .AddNewChemical_minWidth__3Kjl_ {
    width: 100%;
  }

  .AddNewChemical_dashField__1QfVp:after {
    top: 70%;
  }

  .AddNewChemical_rowCrop__1WhIG {
    position: relative;
  }

  .AddNewChemical_rowCrop__1WhIG .AddNewChemical_dashField__1QfVp:after {
    display: none;
  }
  .AddNewChemical_cropTableHolder__1I-lV .ui.table.AddNewChemical_tableCrop__3F1_3 tbody tr td:last-child {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  }

  .AddNewChemical_cropTableHolder__1I-lV .AddNewChemical_tableCrop__3F1_3 tbody tr.AddNewChemical_rowCrop__1WhIG td:last-child {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px !important;
    flex-direction: row;
  }

  .AddNewChemical_chemicalOverGrid__VIXSa .row > div.column + div.column {
    margin-top: 30px;
  }

  .ui.button.AddNewChemical_buttonAdd__1hAta {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.AddNewChemical_editCropValidation__2G5B- {
  animation: AddNewChemical_shake__3MX4j 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;
}

.AddNewChemical_addNewChemicalButtonWrapper__2gt9w {
  width: 78px !important;
}
@keyframes AddNewChemical_shake__3MX4j {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (min-width: 767px) {

    .ui.input.SelectDilusionRate_dilutionInput__1tfxH {
        width: 100%;
        min-width: 120px;
    }

    .ui.fluid.dropdown.active.SelectDilusionRate_dilutionDropdown__1bES3 {
        width: 100%;
        min-width: 120px;
    }
    .ui.fluid.dropdown.SelectDilusionRate_dilutionDropdown__1bES3 > .text {

         }
}

.ui.labeled.input > .label.SelectDilusionRate_iconDropDown__3L6qV .icon {
margin: 0;
}


.ChemicalItem_chemicalHolder__2Q-ou {
  padding: 0 0 15px 0;
  position: relative;
}

.ChemicalItem_chemicalHolder__2Q-ou + .ChemicalItem_chemicalHolder__2Q-ou {
  margin-top: 15px;
  border-top: 1px solid var(--color-border);
}

.ChemicalItem_buttonDel__T5e9- {
  position: absolute;
  right: 0;
  top: 10px;
}

.ui.dropdown .menu > .item.restProducts {
  background-color: #0ba359 !important;
}

.ui.dropdown .menu > .item.restProductsHeaders {
  background-color: #76010d !important;
  pointer-events: none !important;
}

.ui.dropdown .menu > .item.selectedShedProductsHeaders {
  pointer-events: none !important;
}

.ChemicalItem_textButton__36psT.ui.button {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.ChemicalItem_textButton__36psT.ui.button:hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.ChemicalItem_textButtonContainer__3U1iC {
  margin-left: 0 !important;
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  width: 58% !important;
  display: flex !important;
  justify-content: end;
}

.ChemicalItem_messageCell__1oMP_ {
  margin-left: 20px !important;
  width: 93% !important;
}

.ui.selection.dropdown.StatusDropdown_selectStatus__17unj {
  min-width: auto;
  white-space: nowrap;
}
.ui.selection.dropdown.StatusDropdown_selectOPEN__igetS {
  background: var(--color-open);
  border-color: var(--color-open);
  color: #fff;
}

.ui.selection.dropdown.StatusDropdown_selectIN_PROGRESS__1mrXi {
  background: var(--color-in-progress);
  border-color: var(--color-in-progress);
  color: #fff;
}

.ui.selection.dropdown.StatusDropdown_selectCOMPLETED__ei74a {
  background: var(--color-completed);
  border-color: var(--color-completed);
  color: #fff;
}

.StatusDropdown_colorOPEN__38pnt {
  color: var(--color-open);
}

.StatusDropdown_colorIN_PROGRESS__1eFdf {
  color: var(--color-in-progress);
}

.StatusDropdown_colorCOMPLETED__3ieUv {
  color: var(--color-completed);
}

.ui.selection.dropdown.StatusDropdown_selectStatus__17unj > .text:not(.default) {
  color: #fff;
}

.ui.grid.WorkingTimes_timeHolder__1QduU {
}

.WorkingTimes_addHolder__pWVsa.WorkingTimes_wide__2I-G- {
  text-align: center;
}

.WorkingTimes_totalWorkingTime__Qfesm {
  font-weight: bold;
}

.ui.grid > .row > .column.WorkingTimes_noPadding__2-uNa {
  padding-left: 0;
  text-align: right;
}

.WorkingTimes_sprayField__gg3Gp label .WorkingTimes_title__2P52z {
  padding: 5px 5px 5px 0;
  font-weight: bold;
  color: #aaaaaa;
}

.WorkingTimes_required__2t4Yi.WorkingTimes_sprayField__gg3Gp label .WorkingTimes_title__2P52z::after {
  color: red;
  content: '*';
  margin-left: 5px;
}

.WorkingTimes_showDeleteButton__1gVdR {
  position: relative;
  margin-right: 20px;
}

.WorkingTimes_errorMessage__1K8sh {
  margin: 0 auto 10px;
  padding: 0 1rem;
  width: 100%;
}

.WorkingTimes_deleteButton__2E57v {
  position: absolute;
  right: -17px;
  top: 7px;
}

.ui.grid .WorkingTimes_plusIcon__YdMz1 {
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ui.icon.button.WorkingTimes_plusIcon__YdMz1 {
  color: green;
}

.ui.grid .row .column.WorkingTimes_spraySection__fPGzX.WorkingTimes_timeRange__1ZfcR {
  padding-right: 0;
}

.WorkingTimes_totalWorkingTime__HuyYc {
  font-weight: bold;
}

.WorkingTimes_rangePicker__g8P7M {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WorkingTimes_rangePicker__g8P7M > div {
  flex: 50% 1;
}

.WorkingTimes_rangePicker__g8P7M > div.WorkingTimes_range__1DrxV {
  flex: 5px 0;
}

.WorkingTimes_sprayGrid__10enf .WorkingTimes_sprayField__gg3Gp .WorkingTimes_rangePicker__g8P7M .WorkingTimes_timePicker__3toT1.WorkingTimes_from__2glZX {
  flex: 10 1;
}

.WorkingTimes_sprayGrid__10enf .WorkingTimes_sprayField__gg3Gp .WorkingTimes_rangePicker__g8P7M .WorkingTimes_timePicker__3toT1.WorkingTimes_to__2uArU {
  flex: 12 1;
}

.ui.icon.input.WorkingTimes_to__2uArU > input {
  padding-right: 26px !important;
}

.WorkingTimes_total__1U7fc input::-webkit-outer-spin-button,
.WorkingTimes_total__1U7fc input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='time']::-webkit-clear-button {
  display: none;
}

.WorkingTimes_total__1U7fc input {
  -moz-appearance: textfield; /* Firefox */
}

.ui.grid > .row .WorkingTimes_dateRow__3Cr3g {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 340px) {
  .ui.icon.input.WorkingTimes_total__1U7fc > input {
    padding-right: 0!important;
  }
}

.Wizard_wizardHolder__QQja1 {
  flex: 1 1 auto;
  overflow: auto;
  padding: 30px 25px;
  position: relative;
}

.Wizard_wizardHolderIn__1AWCa {
  transform: translateZ(0);
}

.Wizard_fieldsHolder__38TDd {
  margin-top: 30px;
}
.Wizard_fieldsHolder__38TDd .ui.menu .item {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-dark);
}

.Wizard_fieldsHolder__38TDd .ui.menu .active.item {
  background: var(--color-background-label);
  color: #fff;
}

.Wizard_fieldsHolder__38TDd .ui.menu .active.item:hover {
  background: var(--color-dark);
  color: #fff;
}

.Wizard_buttonsHolder__3zb4E {
  margin-top: 30px;
}

.Wizard_batchNo__1M2Lo {
  text-align: center;
  margin-top: -12px;
}

.ui.compact.menu.Wizard_groupMenu__hQBwL {
  display: flex;
  flex-wrap: wrap;
}

.Wizard_title__3rnUl {
  font-size: 16px;
}

.ui.segment.Wizard_stepsHolder__3ppKo {
  margin-top: 25px;
}

.ui.segment.Wizard_stepsHolder__3ppKo {
  margin-top: 25px;
  position: relative;
}

.ui.segment.Wizard_stepsHolder__3ppKo:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 4px;
  background: var(--color-background-green-light);
}

.Wizard_stepName__3kLpj {
  font-size: 16px;
  font-weight: bold;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step40__3CawH:before {
  width: 25%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step41__2SJUA:before {
  width: 50%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step43__3PWD1:before {
  width: 75%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step44__32MpT:before {
  width: 100%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step50__3Cxyb:before {
  width: 20%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step51__1Dgij:before {
  width: 40%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step52__14FSe:before {
  width: 60%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step53__3vlf5:before {
  width: 80%;
}

.ui.segment.Wizard_stepsHolder__3ppKo.Wizard_step54__gbZ_6:before {
  width: 100%;
}

.Wizard_rowIcon__3ZKs6 {
  color: var(--color-icon);
  font-size: 21px;
  text-align: center;
  margin-top: 10px;
}

.Wizard_doneStep__1rnV3 {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
}

.Wizard_doneStep__1rnV3 > div + div {
  margin-top: 20px;
}

.Wizard_doneStep__1rnV3 .ui.button {
  margin-top: 25px;
}

.Wizard_doneBar__3gagO {
  transform: scale(1.3);
}

.TaskDetailsMobile_taskHolder__1ODaZ {
  display: flex;
  background: var(--color-background-cell);
  height: inherit;
  flex-direction: column;
}

.TaskDetailsMobile_taskBody__3dw_Q {
  flex: 1 1 auto;
  overflow: auto;
  padding: 15px 25px 30px 25px;
  position: relative;
}

.TaskDetailsMobile_taskBody__3dw_Q .ui.grid > .row,
.TaskDetailsMobile_taskBody__3dw_Q .ui.grid > .row > .column {
  position: unset;
}

.TaskDetailsMobile_taskBody__3dw_Q .ui.checkbox {
  transform: translateZ(0);
}

.TaskDetailsMobile_showMoreHolder__29h0Z > .ui.grid {
  margin: 0;
}

.TaskDetailsMobile_slideDown__1QVmm {
  margin-left: -1rem;
  margin-right: -1rem;
}

.ui.button.TaskDetailsMobile_moreToggle__2wseo {
  display: block;
  width: 100%;
  background: none;
  position: relative;
  text-align: center;
  border-top: 1px dashed var(--color-border);
  margin-top: 15px;
}

.TaskDetailsMobile_tabsHolder__35cVw {
  margin-top: 30px;
}

.TaskDetailsMobile_tabs__1rXxP {
  margin: 0 -15px;
}

.TaskDetailsMobile_tabs__1rXxP > .ui.secondary.menu {
  background: #fff;
  margin-bottom: 0;
}

.TaskDetailsMobile_tabs__1rXxP > .ui.segment {
  margin-top: 0;
}

.TaskDetailsMobile_progressBar__EU7FW {
  max-width: 60px;
}

.ui.secondary.pointing.menu.TaskDetailsMobile_tabsMenu__1xWD7 {
  background: #fff;
  margin-left: -15px;
  margin-right: -15px;
}

.TaskDetailsMobile_tabItem__1lPz6 {
  overflow: hidden;
  height: 0;
}

.TaskDetailsMobile_tabItem__1lPz6.TaskDetailsMobile_visibleTab__2pFCr {
  height: auto;
  padding-bottom: 15px;
}

.TaskDetailsMobile_taskBody__3dw_Q .ui.menu {
  box-shadow: none;
}

.TaskDetailsMobile_taskBody__3dw_Q .ui.menu .item {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-dark);
  padding: 10px;
  justify-content: center;
}

.TaskDetailsMobile_taskBody__3dw_Q .ui.menu .item:before {
  display: none;
}

.TaskDetailsMobile_taskBody__3dw_Q .ui.menu .active.item {
  background: var(--color-background-label);
  color: #fff;
}

@media screen and (min-width: 768px) {
  .TaskDetailsMobile_statusDropDown__2rgOL {
    max-width: 165px;
  }

  .ui.secondary.pointing.menu.TaskDetailsMobile_tabsMenu__1xWD7 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .ui.secondary.pointing.menu.TaskDetailsMobile_tabsMenu__1xWD7 .item {
    padding-left: 25px;
    padding-right: 25px;
  }

  .ui.secondary.pointing.menu.TaskDetailsMobile_tabsMenu__1xWD7 .active.item {
    border-color: var(--color-background-green);
  }
}

.TaskDetailsMobile_closeDiv__3f5IQ {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-out;
}

.TaskDetailsMobile_openDiv__1OXZJ {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.7s ease-in;
}

.TaskDetailsMobile_showMoreHolder__29h0Z {
  padding: 20px;
  background: #f6f7f8 !important;
  margin-top: 10px;
}

.RouteGoogleMaps_infoHolder__V8kqB > div {
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
}

.Tasks_farmsHolder__1THh4 {
}

.ui.segment.Tasks_mainHolder__3EPV6 {
  padding: 0;
}

.Tasks_farmsHolder__1THh4 .pusher {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.Tasks_farmsHolder__1THh4 .ui.sidebar {
  background: #fff;
  width: 100%;
  padding: 0;
  overflow: auto;
}

.Tasks_sidebarHolder__19xg2 {
  position: relative;
}

.ui.button.Tasks_buttonClose__FJ3qr {
  position: absolute;
  left: 20px;
  top: 40px;
}
.ui.button.Tasks_buttonClose__FJ3qr i.icon {
  font-size: 18px;
}

.ui.button.Tasks_buttonClose__FJ3qr i.icon.close {
  display: none;
}

.ui.basic.segment.Tasks_tasksListHeader__30G6Z {
  color: var(--color-dark);
  border-bottom: 1px solid var(--color-border);
  font-size: 16px;
  display: none;
  margin-bottom: 0;
}

.Tasks_moreHolder__3KHP4 {
  text-align: center;
  color: var(--color-title);
}

.Tasks_moreHolder__3KHP4 .ui.button {
  margin-top: 15px;
  color: var(--color-title);
  border-color: var(--color-title);
}

.ui.segment.Tasks_tasksListHolder__2lfaS {
  padding: 0;
  margin: 0;
}

.Tasks_tasksListHeaderGrid__2JRvt {
}

.ui.grid.Tasks_tasksListHeaderGrid__2JRvt .column {
  padding-left: 5px;
  padding-right: 5px;
}

.ui.grid.Tasks_tasksFilters__2As3B > .row > .column {
  width: auto;
}
.ui.grid.Tasks_tasksFilters__2As3B > .row > .column:first-child {
  padding-left: 0;
}
.ui.grid.Tasks_tasksFilters__2As3B > .row > .column:last-child {
  padding-right: 0;
}

.Tasks_tasksFilters__2As3B {
}

.Tasks_filtersGrid__2FfBf {
  border-bottom: 1px solid var(--color-border);
}

.Tasks_tasksLoader__3cVLY {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .ui.button.Tasks_buttonClose__FJ3qr {
    left: auto;
    right: 20px;
  }

  .ui.button.Tasks_buttonClose__FJ3qr i.icon.chevron {
    display: none;
  }

  .ui.button.Tasks_buttonClose__FJ3qr i.icon.close {
    display: block;
  }

  .ui.basic.segment.Tasks_tasksListHeader__30G6Z {
    display: block;
  }

  .Tasks_farmsHolder__1THh4 .ui.sidebar {
    width: 70%;
    max-width: 650px;
  }
}

@media screen and (max-width: 767px) {
  .Tasks_farmsHolder__1THh4 .ui.sidebar {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .ui.Tasks_popup__30T7H {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1919px) {
  .ui.grid.Tasks_tasksListHeaderGrid__2JRvt .column {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1919px) {
  .ui.grid.Tasks_tasksListHeaderGrid__2JRvt .column:nth-child(1) {
    width: 9% !important;
  }
  .ui.grid.Tasks_tasksListHeaderGrid__2JRvt .column:nth-child(4) {
    width: 9.75% !important;
  }
}

.ui.grid.CreateSprayTaskPrompt_taskPrompt__Fxzk0 {
  margin-top: 5%;
}

.ui.grid.CreateSprayTaskPrompt_taskPrompt__Fxzk0 h1 {
  font-size: 48px;
  line-height: 48px;
  margin-top: 40px;
}

.ui.grid.CreateSprayTaskPrompt_taskPrompt__Fxzk0 button {
  margin-top: 30px;
}

.ui.grid.Pagination_paginationHolder__VBn_U {
  padding-top: 15px;
}
.Pagination_pagination__3QNT4 {
  font-size: 120%;
  display: inline-flex;
  align-items: center;
}

.Pagination_pagination__3QNT4 .ui.button {
  font-weight: normal;
  color: var(--color-dark);
  font-size: 16px;
}

.Pagination_pagesHolder__2D2AM .ui.button.button-text,
.Pagination_pagesHolder__2D2AM .ui.button.button-text:hover {
  margin: 0 5px;
  padding: 3px 5px;
  border-radius: 3px !important;
  display: inline-block;
}

.Pagination_pagination__3QNT4 .ui.button:hover {
  color: var(--color-green);
}
.Pagination_pagesHolder__2D2AM .ui.button.Pagination_active__3VGQu {
  background-color: var(--color-row-stripped);
  font-weight: bold;
}

.Pagination_pagesHolder__2D2AM .ui.button.Pagination_active__3VGQu:hover {
  background-color: var(--color-background-cell);
}

.Pagination_nextPageHolder__qEegW {
  padding-left: 5px;
}

.ui.selection.dropdown.Pagination_selectShort__1uHGt {
  margin-left: 10px;
  width: 65px;
  min-width: 65px;
}

.ui.selection.dropdown.Pagination_selectShort__1uHGt .icon {
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  opacity: 0.8;
  margin: -6px 0 0 0;
  top: 50%;
  transition: opacity 0.1s ease;
}

.Pagination_totalItems__AEDRF {
  display: inline-block;
  margin-right: 25px;
}

.Pagination_prevPageHolder__30XZ7 .ui.button.button-text,
.Pagination_nextPageHolder__qEegW .ui.button.button-text {
  color: var(--color-text);
}

.Pagination_prevPageHolder__30XZ7 .ui.button.button-text,
.Pagination_nextPageHolder__qEegW .ui.button.button-text,
.Pagination_prevPageHolder__30XZ7 .ui.button.button-text:hover,
.Pagination_nextPageHolder__qEegW .ui.button.button-text:hover {
  font-size: 21px;
  padding: 3px 0;
}

.Pagination_prevPageHolder__30XZ7 .ui.button .icon,
.Pagination_nextPageHolder__qEegW .ui.button .icon {
  margin: 0 !important;
}

.Pagination_separator__3HVQx {
  color: var(--color-border);
  display: inline-block;
  margin: 0 15px;
}

.Pagination_selectorsHolder__1KUwN {
  display: inline-flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
}

.TasksFilters_tabsAndFilters__3t9kt > div + div {
  margin-top: 15px;
}

.TasksFilters_filtersHolder__2l5Fj {
}

.ui.selection.dropdown.TasksFilters_buttonFilters__3q6EG,
.ui.labeled.icon.button.TasksFilters_buttonFilters__3q6EG {
  min-width: 143px;
  padding: 0 10px 0 40px !important;
  color: var(--color-green);
  background: var(--color-light-grey);
  height: 30px;
  min-height: 30px;
  border: none;
  font-weight: bold;
  vertical-align: middle;
}

.ui.selection.dropdown.TasksFilters_buttonFilters__3q6EG > input {
  cursor: pointer;
  padding: 6px 10px !important;
  opacity: 0;
}

.ui.selection.dropdown.TasksFilters_buttonFilters__3q6EG > .icon {
  background: none;
}

.ui.selection.dropdown.TasksFilters_buttonFilters__3q6EG > .menu {
  min-width: 14rem;
}
.ui.selection.dropdown.TasksFilters_buttonFilters__3q6EG > .text {
  display: flex;
  min-height: inherit;
  align-items: center;
  cursor: pointer;
}

.ui.selection.dropdown.active.TasksFilters_buttonFilters__3q6EG .TasksFilters_countFilters__2gBhP {
  display: none;
}

.ui.selection.dropdown.active.TasksFilters_buttonFilters__3q6EG {
  box-shadow: none;
  background: var(--color-light-grey);
}

.ui.selection.dropdown.active.TasksFilters_buttonFilters__3q6EG > input {
  opacity: 1;
}

.ui.selection.dropdown.active.TasksFilters_buttonFilters__3q6EG > .icon,
.ui.selection.dropdown.active.TasksFilters_buttonFilters__3q6EG > .text {
  opacity: 0;
}
.ui.selection.dropdown.active.TasksFilters_buttonFilters__3q6EG .ui.label {
  padding: 0;
  background: none;
  margin-right: 0;
  color: var(--color-icon);
}
.ui.dropdown .menu > .item.TasksFilters_selected__1VHap {
  display: inline-block;
}

.ui.dropdown .menu > .item.TasksFilters_checked__2BiV1 {
  color: var(--color-grass);
}
.ui.dropdown .menu > .item.TasksFilters_checked__2BiV1 + .item:not(.TasksFilters_checked__2BiV1) {
  margin-top: 15px;
}

.TasksFilters_nothing__fIBY4 {
  padding: 15px;
}
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_dropdownMenu__2U2vm {
  top: 100%;
  bottom: auto;
}
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_dateRangeHolder__2BQQl {
  max-width: 300px;
  min-width: auto;
}

.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_selectItemsHolder__30nhj:not(.TasksFilters_dateRangeHolder__2BQQl):not(.TasksFilters_switchMenu__1sbS-) {
  max-height: 350px;
  overflow: auto;
}

.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_switchMenu__1sbS- {
  display: flex;
  position: inherit;
  margin: 0 !important;
  box-shadow: none;
}

.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_switchMenu__1sbS- .item {
  flex: 1 1 auto;
}
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_switchMenu__1sbS- .item.active {
  background: rgba(0, 0, 0, 0.05);
}

.TasksFilters_filterBox__1ylIg {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 0.28571429rem;
  background: var(--color-row-hover);
  font-weight: bold;
  height: 30px;
}

.TasksFilters_filterBox__1ylIg .ui.dropdown > .text {
  color: var(--color-green);
}

.TasksFilters_filterBox__1ylIg > .ui.icon.button {
  margin: 0;
}

.TasksFilters_filterBox__1ylIg > .ui.icon.button > .icon {
  color: var(--color-green);
}

.TasksFilters_filterHolder__UaRvI {
  padding: 1.14285714rem 0.78571429rem;
  cursor: default;
  width: 230px;
  font-weight: normal;
}

.TasksFilters_filterHolder__UaRvI:hover {
  background: none;
}

.TasksFilters_filterHolder__UaRvI .ui.grid {
  margin: 0;
}

.TasksFilters_datePicker__3yaKC label > div:first-child {
  margin-bottom: 5px;
}

.TasksFilters_datePicker__3yaKC + .TasksFilters_datePicker__3yaKC {
  margin-top: 15px;
}

.TasksFilters_inputHolder__2dR5B + .TasksFilters_inputHolder__2dR5B {
  margin-top: 15px;
}

.TasksFilters_inputHolder__2dR5B > label > div + div {
  margin-top: 5px;
}

.TasksFilters_filterAdd__1Jtj9 {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

.TasksFilters_countFilters__2gBhP {
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background: var(--color-background-green);
  border-radius: 100%;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.TasksFilters_clearHolder__32Z3- {
  display: inline-block;
  margin-top: 16px;
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid var(--color-border);
  vertical-align: top;
}

.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu.TasksFilters_selectItemsHolder__30nhj {
  min-width: 300px;
}
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown.TasksFilters_selectItems__6cZIM {
  padding: 10px 10px 0 10px;
  border: none;
  width: auto;
  box-shadow: none;
  white-space: normal;
}

.TasksFilters_filtersHolder__2l5Fj .ui.dropdown.TasksFilters_selectItems__6cZIM > .icon,
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown.TasksFilters_selectItems__6cZIM > .sizer {
  display: none;
}
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown.TasksFilters_selectItems__6cZIM .menu,
.TasksFilters_filtersHolder__2l5Fj .ui.dropdown.TasksFilters_selectItems__6cZIM .menu:hover {
  width: auto;
  position: initial;
  display: block;
  max-width: none;
  overflow: initial;
  max-height: none;
  padding-right: 0;
  margin: 10px -10px 0 -10px !important;
  box-shadow: none !important;
  border: none;
}

.TasksFilters_filtersHolder__2l5Fj .ui.dropdown .menu > .message {
  margin: 0;
}

.TasksFilters_filtersItems__2d37P {
  display: block;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .TasksFilters_filtersItems__2d37P {
    display: inline-block;
    margin: 0;
    vertical-align: top;
  }
}

.AddNewFarm_columnMain__2hfJM {
}
.AddNewFarm_stepHolder__33l9y {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.AddNewFarm_stepContent__1Fmt- {
  flex: 1 1 auto;
  overflow: auto;
  padding: 15px;
}
.AddNewFarm_stepContent__1Fmt- .field + .field {
  margin-top: 25px;
}
.AddNewFarm_stepActions__2A6oB {
  padding: 15px;
}

.AddNewFarm_stepActions__2A6oB .ui.button {
  margin: 0;
}

.AddNewFarm_stepActions__2A6oB .ui.button:not(:last-child) {
  margin-right: 10px;
}

.AddNewFarm_verticalMiddle__15-Ev {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.AddNewFarm_verticalMiddle__15-Ev.center {
  justify-content: center;
}

.AddNewFarm_verticalMiddle__15-Ev.right {
  justify-content: flex-end;
}

.AddNewFarm_stepCount__3FIzq {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-light);
}

.AddNewFarm_stepTitle__z0XWf.ui.header {
  padding: 0;
  margin: 15px 0 30px 0;
}

.AddNewFarm_headerOverlayHolder__Q4Mi9 {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.AddNewFarm_headerOverlayHeader__1AWuC {
  border-bottom: 1px solid var(--color-border);
  box-shadow: 0 0 5px var(--color-shadow);
  background: #fff;
}

.AddNewFarm_headerOverlayBody__2lobo {
  flex: 1 1;
}

.AddNewFarm_headerOverlayBody__2lobo .ui.left.sidebar {
  width: 100%;
}

.AddNewFarm_headerOverlayBody__2lobo .pusher {
  display: flex;
}

.AddNewFarm_mapHolder__2yeHl {
  flex: 1 1 auto;
  position: relative;
}

.AddNewFarm_mapButtons__2ihNm {
  position: absolute;
  z-index: 100;
  left: 30px;
  top: 30px;
}

.AddNewFarm_mapWarningMessage__1_VPu {
  padding: 5px;
}

.AddNewFarm_mapWarningMessage__1_VPu .AddNewFarm_warningButtons__2LOKQ {
  float: right;
}

.AddNewFarm_mapWarningMessage__1_VPu .AddNewFarm_warningIcon__2XLKs {
  color: var(--color-warning);
}

#farm-map {
  height: 100%;
}

.gm-style > div.gmnoprint:nth-last-child(2):not(.gm-bundled-control),
.gm-style > div.gmnoprint:last-child:not(.gm-bundled-control) {
  margin-top: 10px !important;
}

.gm-style > div.gmnoprint:nth-last-child(2):not(.gm-bundled-control) > div,
.gm-style > div.gmnoprint:last-child:not(.gm-bundled-control) > div {
  height: 40px;
  min-width: 40px;
  cursor: pointer;
}

.gm-style > div.gmnoprint:nth-last-child(2):not(.gm-bundled-control) > div > div,
.gm-style > div.gmnoprint:last-child:not(.gm-bundled-control) > div > div {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .ui.left.sidebar,
  .ui.left.visible.sidebar {
    box-shadow: none;
  }
  .AddNewFarm_headerOverlayBody__2lobo .ui.left.sidebar {
    width: 450px;
  }

  .AddNewFarm_headerOverlayBody__2lobo .pusher {
    margin-left: 450px;
  }
}

.StepOne_stepHolder__mRL1v {
  background: var(--color-background-cell);
}

.StepOne_stepContent__3A7AH {
}

.StepOne_stepActions__TbRKf {
}

.StepOne_stepCount__26iMG {
}

.StepOne_stepTitle__3XpWW {
}

.StepOne_verticalMiddle__39lJ8 {
}

.StepOne_helperText__2Pgo4 {
  color: var(--color-middle);
  font-size: 12px;
  margin-top: 5px;
}

.StepOne_buttonsGroup__3jOtI {
  width: 100%;
  background: #fff;
}

.ui.basic.buttons.StepOne_buttonsGroup__3jOtI .button {
  padding-left: 3px;
  padding-right: 3px;
}

.ui.basic.buttons.StepOne_buttonsGroup__3jOtI .button.StepOne_buttonActive__2Egds {
  background: var(--color-active) !important;
  color: #fff !important;
}
.StepOne_labelLocation__1HLDK > div + div {
  margin-top: 5px;
}

.PatchFormBody_calendarHolder__2HmaQ .react-calendar {
  bottom: 100%;
  box-shadow: 0 0 10px var(--color-shadow);
}

.PatchFormBody_textarea__3J9sD {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}

.PatchFormBody_closeicon__11cqi {
  justify-content: flex-end;
  align-items: baseline;
  display: flex;
}

.PatchFormBody_addVarietyButtonContainer__2K0Yq {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.PatchFormBody_rightAlignPicker__1flwP > div {
  left: auto;
  right: 0;
}

.PatchFormBody_spinner__1zq9l input[type='number'].PatchFormBody_mod__3M6hj::-webkit-outer-spin-button,
.PatchFormBody_spinner__1zq9l input[type='number'].PatchFormBody_mod__3M6hj::-webkit-inner-spin-button {
  -webkit-appearance: button;

  width: 1em;
  border-left: 1px solid #bbb;
  opacity: 0.5; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.StepTwo_stepHolder__p17Sb {
  background: var(--color-background-cell);
}

.StepTwo_stepContent__P1hXf {
}

.StepTwo_stepActions__2tEsu {
}

.StepTwo_stepCount__Ds007 {
}

.StepTwo_stepTitle__13WRQ {
}

.StepTwo_verticalMiddle__2aQxu {
}

.StepTwo_addNewHolder__3E31l {
  margin-top: 15px;
}

.StepTwo_buttonsHolder__3EsVE {
}

.StepTwo_blockRemoveIcon__1N6Aa {
  float: right;
}

.StepTwo_archiveToggle__q0krz {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.StepTwo_archiveToggle__q0krz > span {
  padding-right: 10px;
  padding-left: 20px;
}

.StepTwo_stepContent__P1hXf .fields + .field {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .StepTwo_stepContent__P1hXf .wide.field + .wide.field {
    margin-top: 15px;
  }
}

.BlockBox_blockBox__BDRPA {
  margin-top: 15px;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}

.BlockBox_blockName__1IL3z {
  color: var(--color-title);
  font-size: 20px;
  padding-bottom: 1rem;
}

.BlockBox_colHead__21nw6 {
  color: var(--color-header);
  font-size: 14px;
  text-transform: uppercase;
}

.BlockBox_colBody__2muFy {
  color: var(--color-text);
}

.BlockBox_blockCol__15fzO {
}

.BlockBox_buttonsCol__8m4fU {
  text-align: right;
  white-space: nowrap;
}
.BlockBox_buttonsCol__8m4fU button.ui.button {
  padding: 0;
  background: none;
}

.BlockBox_buttonsCol__8m4fU button.ui.button:first-child {
  margin-right: 10px;
}

.StepThree_stepHolder__Vtvp9 {
  background: var(--color-background-cell);
}

.StepThree_stepContent__1A2Yd {
}

.StepThree_stepActions__1-e7Z {
}

.StepThree_stepCount__3G_SG {
}

.StepThree_stepTitle__kpgg2 {
}

.StepThree_verticalMiddle__32hnk {
}

.StepThree_blocksHolder__3ABwn {
  margin-top: 10px;
}

.StepThree_deleteBlock__huZks {
  background-color: #dcdcdc !important;
}
.StepThree_deleteActionButton__1djLK {
  margin: 0 0.75em !important;
  min-width: 8em;
}

.StepThree_archiveToggle__3gDM0 {
  display: flex;
  align-items: center;
}

.StepThree_archiveToggle__3gDM0 > span {
  padding-right: 10px;
  padding-left: 20px;
}

.StepThree_archiveToggle__3gDM0.StepThree_wide__3DrRE > div:first-child {
  flex: auto;
}

.SelectArea_viewSelector__2orXx {
  text-decoration: underline;
  cursor: pointer;
  color: gray;
  font-size: 1rem;
}
.SelectArea_viewSelector__2orXx:hover {
  color: black;
}

.SelectArea_viewHolder__3auwX {
  display: flex;
  flex-direction: row;
}

.SelectArea_viewLabelHolder__2nqpa {
  width: 50%;
  float: left;
}

.SelectArea_viewButtonHolder__1TjAY {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  float: right;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields {
  margin: 0 -10px;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field > label {
  font-weight: normal;
  display: block;
  flex: 0 1 auto;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field > div {
  display: block;
  flex: 1 1 auto;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field > div input {
  width: 100%;
  max-width: 60px;
}

.AreasFilters_areaDropDownHolder__38fKN {
  position: relative;
}

.AreasFilters_areaDropDownHolder__38fKN .icon.sliders {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.AreasFilters_areaDropDownHolder__38fKN .icon.delete.AreasFilters_clearAll__2OV_r {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.AreasFilters_filtersHolder__2nrH1 {
  position: absolute;
  width: 100%;
  top: 100%;
}

.AreasFilters_filtersHolder__2nrH1 .ui.segment {
  margin: 0;
}

.AreasFilters_filtersBar__1zApy {
  position: absolute;
  top: 100%;
  width: 100%;
  background: var(--color-grass-light);
  padding: 0 15px;
  box-shadow: var(--box-shadow);
}

.AreasFilters_filtersFooter__6ruvg {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
}

.AreasFilters_filtersFooter__6ruvg .ui.button {
  border-radius: 0;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields {
  margin: 0 -10px;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field > label {
  font-weight: normal;
  display: block;
  flex: 0 1 auto;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field > div {
  display: block;
  flex: 1 1 auto;
}

.ui.form.AreasFilters_ageForm__1XWOX .inline.fields .field > div input {
  width: 100%;
  max-width: 60px;
}

.ui.grid.AreasFilters_filtersGrid__Bs0iX button + button {
  margin-left: 15px;
}

.ui.grid.AreasFilters_filtersGrid__Bs0iX .menu i {
  margin-left: 15px;
  display: inline-block;
  color: var(--color-middle);
}

.ui.grid.AreasFilters_labelsGrid__1ijqT {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.grid.AreasFilters_labelsGrid__1ijqT .row .column {
  padding-bottom: 0;
}

.ui.grid.AreasFilters_labelsGrid__1ijqT .ui.label {
  margin-top: 2px;
  margin-bottom: 2px;
}

.ui.grid.AreasFilters_labelsGrid__1ijqT .button-text {
  color: var(--color-grass);
}

.ui.grid.AreasFilters_labelsGrid__1ijqT .button-text + .button-text {
  margin-left: 15px;
}

.ui.dropdown.AreasFilters_areasDropdown__3RcUz {
  padding-right: 5em;
}

.ui.dropdown.AreasFilters_areasDropdown__3RcUz .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.AreasFilters_areasDropdown__3RcUz .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

.AreasFilters_optional__36c8g label:after {
  display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sprayGrid .ui.dropdown.error .menu .item {
  color: #000000;
}

.acceptSection {
  display: flex;
  margin-top: 10px;
}

.acceptSection.error {
  color: red;
}

.acceptSection label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.acceptSection label .checkbox {
  margin-right: 5px;
}

.subsectionTitle {
  font-size: 1.2rem;
}

.ui.table.sprayTablechemicals tbody .show th {
  padding: 1.29rem 0.8rem;
}

.chemicalItem {
  display: flex;
  justify-content: space-between;
}

.createTaskButton .ui.dropdown {
  background: var(--color-secondary-button-background) !important;
}
.createTaskButton .ui.dropdown:hover {
  background: var(--color-secondary-button-background-hover) !important;
}

.ui.dropdown.relative {
  position: relative;
  z-index: 1;
}

.dropdownDilusionIcon {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  padding: 12px 0;
  padding-right: 19px;
  padding-bottom: 24px;
}

.text.areaSelectItem {
  display: flex;
}

.ui.grid.chemicalItems {
  background: #f5efef;
  margin: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #e3e1e2;
}

.ui.grid.chemicalItems .column {
  padding-bottom: 15px;
}

.chemicalItemTitle {
  margin: 10px 0;
  font-size: 12px;
  color: var(--color-header);
}

.deleteChemicalIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}

.sprayGrid .ui.dropdown:not(.button) .default.text {
  color: #000000;
}

.sprayGrid .sprayField .dropdown.dropdownDilusionIcon,
.sprayGrid .sprayField .input.dilusionInput {
  width: unset;
}

.ui.grid.sprayForm_buttonsFooter__yvgsR {
  margin: 15px 0;
}

.ui.grid.sprayForm_buttonsFooter__yvgsR .ui.button + .ui.button {
  margin-right: 15px;
}

.sprayForm_areaDropDownHolder__3YhwQ {
  position: relative;
  z-index: 10;
}

.sprayForm_areaDropDownHolder__3YhwQ .icon.sliders {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.sprayForm_areaDropDownHolder__3YhwQ .icon.delete.sprayForm_clearAll__165nn {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.sprayForm_filtersHolder__1rY1l {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  border: 1px solid var(--color-background-green-light);
  border-top: none;
  box-shadow: var(--box-shadow-mini);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sprayForm_filtersHolder__1rY1l .ui.segment {
  margin: 0;
}

.sprayForm_filtersBar__1mr6O {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  left: 1px;
  right: 1px;

  padding: 0 15px;
  border-bottom: 1px solid var(--color-border);
  z-index: 10;
}

.sprayForm_filtersFooter__1FZ81 {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
  border: 1px solid var(--color-border-sui);
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.sprayForm_allHolder__2I77q {
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.sprayForm_allHolder__2I77q .ui.button {
}

.ui.grid.sprayForm_filtersGrid__2fWQr .row + .row {
  padding-top: 0;
}
.ui.grid.sprayForm_filtersGrid__2fWQr button + button {
  margin-left: 15px;
}
.ui.grid.sprayForm_labelsGrid__GA6W_ {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.grid.sprayForm_labelsGrid__GA6W_ .row .column {
  padding-bottom: 0;
}
.ui.grid.sprayForm_labelsGrid__GA6W_ .ui.label {
  margin-top: 2px;
  margin-bottom: 2px;
}
.ui.grid.sprayForm_labelsGrid__GA6W_ .button-text {
  color: var(--color-grass);
}
.ui.grid.sprayForm_labelsGrid__GA6W_ .button-text + .button-text {
  margin-left: 15px;
}

.ui.dropdown.sprayForm_areasDropdown__nfjcn {
  padding-right: 5em;
}

.ui.dropdown.sprayForm_areasDropdown__nfjcn .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.sprayForm_areasDropdown__nfjcn .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}
.ui.dropdown.sprayForm_areasDropdown__nfjcn .item i.vacant {
  color: var(--color-vacant-blue);
}

.ui.dropdown.sprayForm_areasDropdown__nfjcn.sprayForm_gotFilters__1CiNu {
  z-index: 8;
}
.ui.dropdown.sprayForm_areasDropdown__nfjcn.sprayForm_gotFilters__1CiNu .menu {
  border: none;
  border-radius: 0;
  left: 0;
  right: 0;
  margin: 0;
  min-width: auto;
  width: auto;
}

.ui.dropdown.sprayForm_areasDropdown__nfjcn.sprayForm_gotFilters__1CiNu .menu,
.ui.dropdown.sprayForm_areasDropdown__nfjcn.sprayForm_gotFilters__1CiNu .menu:hover,
.ui.dropdown.sprayForm_areasDropdown__nfjcn.sprayForm_gotFilters__1CiNu .menu:active,
.ui.dropdown.sprayForm_areasDropdown__nfjcn.sprayForm_gotFilters__1CiNu:hover .menu {
  box-shadow: none;
}

.sprayForm_countDuplicateSelectedItems__17B54 {
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background: var(--color-light-grey);
  font-weight: bold;
  color: var(--color-dark);
  border-radius: 100%;
  display: inline-block;
  margin-left: 5px;
  margin-top: -10px;
  margin-bottom: -6px;
  top: -3px;
  position: relative;
  vertical-align: bottom;
}

.sprayForm_confirmContent__2e6Xq {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sprayForm_confirmContentLine__33Xo6 {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sprayForm_showFilters__17dR9 .ui.dropdown.sprayForm_areasDropdown__nfjcn {
  border-color: var(--color-background-green-light);
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ui.grid.sprayForm_buttonsFooter__yvgsR,
.ui.grid.sprayForm_buttonsFooter__yvgsR > div {
  margin: 0;
  padding: 0;
}

.ui.grid.sprayForm_buttonsFooter__yvgsR .ui.primary.button .icon {
  opacity: 1;
  height: 14px;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

@media (max-width: 767px) {
  .page .modal .sprayForm_modalHeader__3fwfL {
    padding-right: 0 !important;
  }

  .ui.grid.sprayForm_buttonsFooter__yvgsR > .row > .column {
    padding: 0;
  }

  .ui.grid.sprayForm_buttonsFooter__yvgsR .ui.primary.button {
    margin: 0;
  }

  .sprayForm_machineryHolder__leJSv .column.sprayForm_speedColumn__1Oy7G {
    margin-top: 0 !important;
  }
}

.ui.modal.AddNewModal_modalChemical__3Q03X {
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex !important;
  flex-direction: column;
}

.ui.modal.AddNewModal_modalChemical__3Q03X > .close {
  right: 15px;
  top: 15px;
  color: #000;
}

.ui.modal.AddNewModal_modalChemical__3Q03X .content {
  padding: 0;
  flex: auto;
  overflow: auto;
}

.ui.modal.AddNewModal_modalChemical__3Q03X .actions {
}

.SelectChemicalsForm_title__3HGUX {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-light);
  display: none;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 3px;
}

.SelectChemicalsForm_newItem__BzSJT .SelectChemicalsForm_title__3HGUX {
  color: #000;
}

.SelectChemicalsForm_buttonAdd__3rz0x {
  margin-bottom: 25px;
}

.SelectChemicalsForm_chemicalsTable__2USil.ui.table {
  border: none;
}

.SelectChemicalsForm_chemicalsTable__2USil.ui.table thead th {
  background: none;
  font-weight: normal;
  color: var(--color-middle);
}

.SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td {
  font-weight: 600;
}

.SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td.SelectChemicalsForm_actions__GFPLF {
  text-align: center;
  color: var(--color-icon);
  font-size: 18px;
}

.SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td.SelectChemicalsForm_actions__GFPLF i {
  cursor: pointer;
}

.SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td.SelectChemicalsForm_actions__GFPLF i:not(:last-child) {
  margin-right: 15px;
}

.ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_editItem__-lXia,
.ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_editItem__-lXia:hover,
.ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_newItem__BzSJT,
.ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_newItem__BzSJT:hover {
  background: var(--color-background-cell) !important;
}

.SelectChemicalsForm_chemicalsTable__2USil .ui.selection.dropdown .default.text {
  white-space: nowrap;
  /* padding-right: 25px; */
  overflow: hidden;
  max-width: 80%;
}

.SelectChemicalsForm_addTitle__3qJU8 {
  display: none;
  font-weight: bold;
}

.SelectChemicalsForm_buttonCancel__1eD7O {
  display: none;
}

.SelectChemicalsForm_buttonUpdate__1wGrF {
}

.SelectChemicalsForm_inputRate__2Bsdy {
  width: 130px;
}

.SelectChemicalsForm_addChemicalValidation__1ZS9L {
  animation: SelectChemicalsForm_shake__374B7 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes SelectChemicalsForm_shake__374B7 {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (min-width: 767px) {
  .SelectChemicalsForm_buttonsHolder__1vwBU {
    width: 125px;
  }

  .SelectChemicalsForm_buttonsNew__1yff5 .ui.button {
    padding: 12px;
    font-size: 12px;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td:last-child {
    max-width: 155px;
    text-align: center;
    white-space: nowrap;
    width: 1%;
  }

  .SelectChemicalsForm_cropAndPurposeCell__-qWiX {
    width: 15%;
  }

  .SelectChemicalsForm_dilutionCell__benMk {
    width: 3%;
  }

  .SelectChemicalsForm_chemicalCell__3Uxks {
    width: 15%;
  }

  .SelectChemicalsForm_chemicalTypeCell__zDmw7 {
    width: 10%;
  }

  .SelectChemicalsForm_dateOfManufactureCell__3G2qF {
    width: 10%;
  }

  .SelectChemicalsForm_batchNumberCell__1xfMv {
    width: 10% !important;
  }
}

.ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_message__3PnUY,
.ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_message__3PnUY:hover {
  border: none;
  background: none !important;
}

@media (max-width: 767px) {
  .SelectChemicalsForm_title__3HGUX {
    display: block;
    margin-top: 15px;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table thead {
    display: none;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr {
    display: flex !important;
    flex-wrap: wrap;
    background-color: var(--color-bg-pink);
    border: 1px solid var(--color-border);

    box-shadow: none !important;
    position: relative;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr:not(:last-child) {
    margin-bottom: 15px;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr:nth-child(2n) {
    background-color: var(--color-bg-pink);
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr.SelectChemicalsForm_newItem__BzSJT,
  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr:nth-child(2n).SelectChemicalsForm_newItem__BzSJT {
    background-color: #fff;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td {
    width: 50% !important;
    flex: 0 1 auto;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td:last-child {
    width: 100% !important;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td:not(.SelectChemicalsForm_actions__GFPLF) {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }

  .ui.table.SelectChemicalsForm_chemicalsTable__2USil tbody tr.SelectChemicalsForm_message__3PnUY {
    padding: 0;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td.SelectChemicalsForm_actions__GFPLF {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 18px;
    width: auto !important;
    text-align: right;
    padding: 0 !important;
    white-space: nowrap;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td.SelectChemicalsForm_actions__GFPLF i:not(:last-child) {
    margin-right: 15px;
  }

  .SelectChemicalsForm_buttonAdd__3rz0x button span {
    display: inline !important;
  }

  .SelectChemicalsForm_chemicalsTable__2USil.ui.table tbody tr td.SelectChemicalsForm_addTitle__3qJU8 {
    width: 100% !important;
  }

  .SelectChemicalsForm_buttonsNew__1yff5 {
    margin-top: 10px;
  }

  .SelectChemicalsForm_buttonCancel__1eD7O {
    display: inline-block;
  }
}

.SelectChemicalsForm_messageContainer__1Dfp- {
  padding-right: 25px !important;
}

.SelectChemicalsForm_messageCell__3akTa {
  border: none !important;
  max-width: 100% !important;
  text-align: start;
}

.ui.basic.segment.SelectChemicals_messageHolder__2egUA {
  margin-top: 0;
  padding-top: 0;
}
.SelectChemicals_nameWrapper__2DpsN {
  display: flex;
  flex-direction: column;
}

.ui.dropdown .menu > .item.restProducts,
.ui.dropdown .menu > .item.restProducts:hover {
  background-color: #fffaf2 !important;
}

.ui.dropdown .menu > .item.restProductsHeaders {
  background-color: #fffaf2 !important;
  pointer-events: none !important;
}
.ui.dropdown .menu > .item.selectedShedProductsHeaders {
  pointer-events: none !important;
  background-color: white !important;
}

.Table_checkbox__2wamo.ui.checkbox input:indeterminate ~ label::after,
.Table_checkbox__2wamo.ui.checkbox input:checked ~ label::after {
  color: var(--color-green) !important;
  padding-top: 1px !important;
}

.Table_checkbox__2wamo.ui.checkbox input:checked ~ label::before,
.Table_checkbox__2wamo.ui.checkbox input:indeterminate ~ label::before {
  border-color: var(--color-green) !important;
}

.Table_checkbox__2wamo.ui.checkbox input ~ label:hover::before {
  border-color: var(--color-green) !important;
}

.Table_radio__1apVl.ui.radio input:checked ~ label::after {
  background-color: var(--color-green) !important;
}

.Table_table__3VfSL thead .Table_checkboxContainer__ibqyf {
  display: flex;
  height: 100%;
}

.Table_checkboxCell__3LvAE {
  min-height: 60px;
}

.Table_selectedRow__3D7sv,
.Table_row__3fPmW:hover {
  background-color: #dff3ea;
}

.Table_selectedRowWithHeader__2qqFj,
.Table_rowWithHeader__pSYZB:hover {
  background-color: #dff3ea;
}

.Table_activeRow__2S5E2 {
  background-color: #ebf6e9 !important;
}

.Table_activeRow__2S5E2:hover {
  background-color: #dff3ea !important;
}

.Table_activeRowwithHeader__1XX1Q {
  background-color: #ebf6e9 !important;
}

.Table_activeRowwithHeader__1XX1Q:hover {
  background-color: #ebf6e9 !important;
}

.Table_table__3VfSL.ui[class*="very basic"].table th:first-child,
.Table_table__3VfSL.ui[class*="very basic"].table td:first-child {
  padding-left: 16px !important;
}

.Table_table__3VfSL thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.Table_table__3VfSL .Table_row__3fPmW td {
  vertical-align: baseline;
  padding: 16px;
}

.Table_table__3VfSL tbody {
  width: 100%;
  overflow-x: hidden !important;
  z-index: 0;
}

.Table_table__3VfSL.ui.table tr {
  width: 100%;
}

.Table_table__3VfSL.ui.table thead th {
  background: transparent !important;
}

.Table_table__3VfSL.ui.table tfoot tr td {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.Table_actionsContainer__1zFfA {
  align-items: baseline !important;
  display: flex !important;
}

.Table_searchAndFiltersContainer__38eWm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px;
}

.ui.popup {
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1) !important;
}

.Table_paginationPopup__35jwR {
  width: 240px !important;
  height: 104px !important;
  padding: 16px;
}

.Table_paginationPopup__35jwR .Table_paginationField__1XUQ8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.Table_paginationPopup__35jwR .Table_dropdown__21xdS {
  width: 90px !important;
  height: 28px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  font-size: 11px !important;
}

.Table_filterDropdown__2GAiX {
  height: 28px !important;
  padding-left: 16px !important;
}

.Table_paginationPopup__35jwR .Table_filterDropdown__2GAiX {
  width: 90px !important;
  height: 28px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  font-size: 11px !important;
}

.Table_pageCountLabel__OCBdY {
  width: 110px !important;
  height: 14px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #000000 !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  margin-right: 8px !important;
}

.Table_paginationButton__V8UTV {
  position: relative;
  padding: 5px !important;
  box-shadow: none !important;
  margin-right: 0;
  border-radius: 20px;
  height: 24px;
  width: 24px;
}

.Table_paginationButton__V8UTV.ui.basic.button:hover {
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
}

.Table_paginationButton__V8UTV.ui.basic.button .angle.left.icon {
  position: absolute;
  left: 3px;
  top: 5px;
}

.Table_paginationRightButton__3Gk0V {
  position: relative;
  padding: 5px !important;
  box-shadow: none !important;
  margin-right: 21px !important;
  border-radius: 20px;
  height: 24px;
  width: 24px;
}

.Table_paginationRightButton__3Gk0V.ui.basic.button:hover {
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
}

.Table_paginationRightButton__3Gk0V.ui.basic.button .angle.left.icon {
  position: absolute;
  left: 3px;
  top: 5px;
}

.Table_paginationRightButton__3Gk0V {
  position: relative;
  padding: 5px !important;
  box-shadow: none !important;
  margin-right: 24px !important;
  border-radius: 20px;
  height: 24px;
  width: 24px;
}

.Table_paginationRightButton__3Gk0V.ui.basic.button:hover {
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
}

.Table_paginationRightButton__3Gk0V.ui.basic.button .angle.left.icon {
  position: absolute;
  left: 3px;
  top: 5px;
}

.Table_pagination__10QKT {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-top: 4px !important;
}

.Table_paginationTitle__wEP4y {
  white-space: nowrap;
  margin-right: 8px !important;
  margin-top: 2px;
}

.Table_sortingIcon__2omZk {
  visibility: hidden;
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.Table_sortableHeader__2R12E:hover {
  background-color: transparent;
}

.Table_sortableHeader__2R12E:hover .Table_sortingIcon__2omZk {
  visibility: visible;
}

.Table_sortableHeader__2R12E.Table_sorted__2v5pv {
  background-color: var(--color-background-cell);
}

.Table_sortableHeader__2R12E.Table_sorted__2v5pv .Table_sortingIcon__2omZk {
  visibility: visible;
}

.Table_sortableHeader__2R12E {
  width: -webkit-max-content;
  width: max-content;
  padding: 4px 6px;
  border-radius: 4px;
  margin-left: -4px;
}

.Table_tableContainer__-ox62 {
  overflow-y: none !important;
  scroll-behavior: smooth !important;
  margin-top: 24px !important;
  padding-top: 0 !important;
  z-index: 1;
}

.Table_tableInModal__1mVMh {
  overflow: auto;
  padding-top: 0 !important;
  z-index: 1;
}

.Table_selectHeader__20fQc,
.Table_checkboxContainer__ibqyf {
  margin-top: 4px !important;
  width: calc(16px * 2 + 20px);
}

.Table_actionsContainerWrapper__3emeX {
  z-index: 10;
  display: flex;
  justify-content: space-between;
}

.Table_searchInput__dqcbU {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.Table_searchInput__dqcbU input {
  padding: 3px 6px 3px 16px !important;
}

.Table_searchInput__dqcbU input::placeholder {
  color: #b3b3b3;
}

.Table_searchInput__dqcbU input {
  padding: 3px 6px 3px 16px !important;
}

.Table_searchInput__dqcbU input::placeholder {
  color: #b3b3b3;
}

.Table_tableCheckbox__1RFJC.ui.checkbox label::before,
.Table_tableCheckbox__1RFJC.ui.checkbox label::after {
  width: 20px;
  height: 20px;
}

.Table_tableCheckbox__1RFJC.ui.checkbox label::after {
  font-size: 18px;
  line-height: 20px;
  top: 0px;
}

.Table_noDataContainer__1vnJA {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Table_noDataText__dZahC {
  margin-bottom: 25px;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #666666;
  word-break: break-word;
  white-space: break-spaces;
}

.ui.popup:before {
  display: none;
}

.ui.popup {
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 14px;
  letter-spacing: 0;
  text-align: center;
  left: 633px;
  top: 76px;
  border-radius: 4px;
  padding: 4px 12px 4px 12px;
}

.Table_dimmerCustomStyle__1hiZ- {
  height: 3000% !important;
  width: 150% !important;
}

.Table_loaderContainer__13Oat {
  position: relative;
}

.Table_loaderContainer__13Oat .Table_loader__fgLLp {
  position: fixed;
  left: calc(50% - 130px);
}

.Table_tableWithBorder__9zF3Z.ui[class*="very basic"].table thead th:first-child,
.Table_tableWithBorder__9zF3Z.ui[class*="very basic"].table td:first-child {
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.Table_firstColumnFixed__16wbL.ui[class*="very basic"].table th:first-child {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  background-color: white;
}

.Table_firstColumnFixed__16wbL.ui[class*="very basic"].table td:first-child {
  padding: 0px;
  height: 60px;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  background-color: white !important;
}

.Table_firstColumnFixed__16wbL.ui[class*="very basic"].table thead th:first-child {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  background-color: white !important;
  z-index: 1 !important;
}

.Table_withoutSort__3zNx0 {
  cursor: default;
}

.Table_activeRow__2S5E2 {
  background-color: #ebf6e9 !important;
}

.Table_activeRow__2S5E2:hover {
  background-color: #dff3ea !important;
}

.ui.message.Table_warrningMessages__1Vw2q {
  background-color: #fffaf2;
  border-radius: 4px;
  border: none !important;
  color: #f0ac47;
  padding-left: 13px !important;
  padding-right: 8px !important;
}

.Table_warrningMessagesRow__2HnD_ {
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}

.ui.grid.container.Table_warrningMessagesWrapper__2SzRH {
  width: 100% !important;
  margin: 0 !important;
}

.ui.grid > .row.Table_warrningMessages__1Vw2q {
  display: flex;
}

.ui.grid > .row > .column > .icon.Table_warrningMessagesIcon__3InKt {
  color: #f0ac47;
}

.Table_warrningMessagesIconWrapper__1rtK6 {
  padding-right: 15px !important;
}

.Table_warrningMessagesIcon__3InKt {
  width: 24px !important;
  height: 24px !important;
}

.Table_warrningMessage__2YL1d {
  width: 100% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex;
  align-items: center;
}

.ui.grid.Table_warrningMessagesIcon__3InKt {
  display: flex !important;
  align-items: center !important;
}

.Table_messagesCloseButtonContainer__3fNPQ {
  padding-left: 8px !important;
}

.ui.button.Table_messagesCloseButton__19sFK {
  width: 66px !important;
  height: 28px !important;
  padding: 0px;
  background: none;
  color: #0e9354;
}

.Table_noDataContainer__1vnJA {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Table_noDataText__dZahC {
  margin-bottom: 25px;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #666666;
  word-break: break-word;
  white-space: break-spaces;
}

.ui.popup:before {
  display: none;
}

.Table_dimmerCustomStyle__1hiZ- {
  height: 3000% !important;
  width: 150% !important;
}

.Table_loaderContainer__13Oat {
  position: relative;
}

.Table_loaderContainer__13Oat .Table_loader__fgLLp {
  position: fixed;
  left: calc(50% - 130px);
}

.Table_dateFilterDropDownIconsWrapper__3n5AN {
  display: inline-flex;
  align-items: flex-start;
  padding-left: 5px;
  padding-right: 12px;
}

.ui.dropdown .menu.Table_dateRangeSelectDropDown__1wOqi {
  min-height: 450px !important;
  min-width: 250px !important;
  background: none;
  border: none;
  box-shadow: none !important;
}

.ui.filterDropdown .menu.Table_dateRangeSelectDropDown__1wOqi {
  min-height: 450px !important;
  min-width: 250px !important;
  background: none;
  border: none;
  box-shadow: none !important;
}

.Table_filterHolder__2Nl4j {
  background-color: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  cursor: default;
  width: 270px;
  font-weight: normal;
}

.Table_datepickerWrapper__1P3Nu {
  padding: 16px;
}

.ui.dropdown > .text {
  min-width: 40px !important;
}

.Table_filterDropdown__2GAiX > .Table_text__3o5f4 {
  min-width: 40px !important;
  padding-left: 16px;
}

.Table_reportColumn__2_CcJ {
  padding: 0px !important;
  height: 50px !important;
  border: none;
}

.Table_lastActiveRow__2NvoA .Table_reportColumn__2_CcJ {
  padding-bottom: 20px !important;
}

.Table_subRowsLastItem__2oIJW > td {
  padding-bottom: 10px !important;
}

.Table_table__3VfSL {
  width: 98% !important;
}

.Table_filterDropdown__2GAiX ::-webkit-scrollbar-track {
  background-color: #f6f7f8 !important;
  width: 4px !important;
  border-radius: 10px !important;
}

.Table_filterDropdown__2GAiX ::-webkit-scrollbar-thumb {
  background: #b3b3b3 !important;
  border-radius: 10px !important;
  width: 4px !important;
  height: 20px !important;
  background-clip: content-box !important;
}

.Table_filterDropdown__2GAiX ::-webkit-scrollbar {
  padding: 0px !important;
  width: 4px !important;
  height: 20px !important;
}

.Table_paginationField__1XUQ8 .ui.dropdown .menu > .item {
  height: 28px;
  padding: 3.5px 16px !important;
  display: flex !important;
  align-items: center;
}

.Table_outer__2p83i {
  overflow: hidden;
}

.Table_inner__37CY9 {
  width: 100%;
  height: 99%;
  transition: margin-left 0.5s;
  overflow-y: auto !important;
  margin-left: 0px;
}

.Table_taskInner__2E4st {
  width: 100%;
  height: 99%;
  transition: margin-left 0.5s;
  overflow-y: auto !important;
  overflow-x: hidden;
  margin-left: 0px;
}

.Table_taskCardInner__3dEmz {
  width: 100%;
  height: 100% !important;
  transition: margin-left 0.5s;
  overflow-y: hidden !important;
  overflow-x: hidden;
  margin-left: 0px;
}

.Table_prevBodyState__19375 ::-webkit-scrollbar-track:vertical {
  background-color: white;
  width: 20px;
  -webkit-transition: background-color 3000ms !important;
  transition: background-color 3000ms !important;
}

.Table_prevBodyState__19375 ::-webkit-scrollbar-thumb:vertical {
  background-color: white;
  width: 20px;
  -webkit-transition: background-color 3000ms !important;
  transition: background-color 3000ms !important;
}

.Table_bodyState__3LRNY ::-webkit-scrollbar-track:vertical {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  -webkit-transition: background-color 3000ms !important;
  transition: background-color 3000ms !important;
}

.Table_bodyState__3LRNY ::-webkit-scrollbar-thumb:vertical {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-transition: background-color 3000ms !important;
  transition: background-color 3000ms !important;
}

.Table_cellStyle__3hUTb {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.Table_iconContainer__32wd_ {
  width: 70px;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Table_activeIcon__2t1fq {
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.Table_iconWrapper__umB0L {
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Table_listIcon__1BJlt {
  font-size: 17px !important;
  margin-bottom: 1px !important;
}

.Table_cardIcon__1eAfB {
  font-size: 17px !important;
  margin-bottom: 1px !important;
}

.ui.secondary.pointing.menu.Table_tabsMenu__-lRBf {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dededf !important;
}

.ui.secondary.pointing.menu.Table_tabsMenu__-lRBf .item {
  width: 33%;
  display: flex;
  justify-content: space-between;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-align: center;
  color: #666666;
}

.ui.secondary.pointing.menu.Table_tabsMenu__-lRBf .item.active {
  width: 34%;
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  border-color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;

  text-align: center;
  color: #0e9354;
}

.ui.secondary.pointing.menu.Table_tabsMenu__-lRBf {
  background: #fff;
  margin-bottom: 0;
}

.Table_taskTable__3G_26 {
  border-spacing: 8px !important;
}

.Table_taskHeaderCell__1_Cvu {
  padding-right: 4px !important;
  padding-left: 4px !important;
  background: none !important;
  border-bottom: none !important;
}

.Table_taskHeaderRow__2rfXo .Table_taskHeaderCell__1_Cvu:first-child {
  display: table-header-group;
  padding-right: 4px;
  width: 50px !important;
}

.Table_taskHeaderRow__2rfXo .Table_taskHeaderCell__1_Cvu:last-child {
  padding-right: 4px;
  width: 50px !important;
}

.Table_taskTableBodyRow__ItdqA {
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  border-radius: 8px !important;
}

.Table_taskTableBodyCell__3jVnd {
  padding: 0px !important;
  height: 50px !important;
  border-top: none !important;
}

.Table_taskTableBodyRow__ItdqA .Table_taskTableBodyCell__3jVnd:first-child {
  width: 50px !important;
  padding-left: 0px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.Table_taskTableBodyRow__ItdqA .Table_taskTableBodyCell__3jVnd:last-child {
  width: 50px !important;
  padding-right: 10px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.Table_dropdownItem__15V00:hover {
  width: 100% !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.Table_priorityIconWrapper__3Rvp4 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.Table_priorityIconWrapper__3Rvp4 i::before {
  width: 20px;
}

.Table_priorityMedium__1Mdcl {
  width: 20px;
}

.Table_withoutPriority__3vtCB {
  width: 60px !important;
  display: flex !important;
  justify-content: center;
}

.Table_highPriorityIcon__10-ZX {
  width: 20px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #b00020 !important;
}

.Table_lowPriorityIcon__Eab_y {
  width: 20px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #3a67bf !important;
}

.Table_emptyTableWrapper__2Ogd1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Table_emptyTableText__ZYFz0 {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.Table_taskDeleteIcon__2L86l {
  cursor: pointer;
  margin-right: 8px !important;
  color: #b00020 !important;
}

.Table_selectionDropdown__2vEpN > .text {
  display: none !important;
}

.Table_tasksIconWrapper__14-uD {
  display: flex;
  align-items: center;
}

.Table_archiveIcon__1eE6d {
  cursor: pointer;
  margin-right: 8px !important;
}

.ui.popup.Table_expiredPopup__2AJL2 {
  background-color: black;
  color: white;
}

.Table_infoButton__CUq-U {
  margin-left: 8.38px !important;
  color: #b00020;
  margin-bottom: 5px !important;
}

i.icon {
  margin: 0;
}

.batchNumberDropdown_batchNumberDropDown__3K5-e {
  padding-right: 10px !important;
  background: #ffffff !important;
  border: 1px solid #dededf !important;
  border-radius: 3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px !important;
}

.batchNumberDropdown_batchNumberDropDownMenu__oL_Wj {
  width: 320px !important;
  max-height: inherit !important;
  overflow: hidden !important;
  height: auto !important;
}
.batchNumberDropdown_batchNumberDropDownScrollingMenu__aFHvW {
  border-top-width: 1px black solid !important;
}
.batchNumberDropdown_addItem__1h38W {
  max-width: 300px;
  height: 29px !important;
  background: #fdfdfd !important;
  border: none !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #dededf !important;
  border-radius: 24px !important;
  font-size: 14px !important;
  min-height: 30px;
  vertical-align: middle !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
  padding-top: 2px !important;
  padding-left: 8px !important;
  padding-right: 11px !important;
}

.batchNumberDropdown_addItem__1h38W i {
  color: #b00020 !important;
  cursor: pointer !important;
  padding-left: 20px !important;
}

.batchNumberDropdown_labelContainer__O0UIE {
  max-width: 320px;
  white-space: initial !important;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  margin: 0px !important;
  padding-left: 10px !important;
  border-bottom: none !important;
}

.batchNumberDropdown_addButtonWrapper__1rEfM {
  padding: 0px !important;
}

.batchNumberDropdown_addButton__W_s-e {
  display: flex !important;
  font-weight: 400px !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
  font-size: 14px !important;
}
.batchNumberDropdown_addButton__W_s-e:hover {
  background-color: #ebf6e9 !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
}
.batchNumberDropdown_addButtonBatchNumber__2IqjR {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600px !important;
}


.ui.table.SelectedAreasTable_sprayTable__61xQr {
  border: none;
  color: var(--color-dark);
}

.ui.table.SelectedAreasTable_sprayTable__61xQr thead th {
  background: none !important;
  border: none;
  color: #aaaaaa;
  font-weight: normal;
  border-bottom: 2px solid var(--color-border-sui-light);
}

.ui.table.SelectedAreasTable_areas__3Ofo6.SelectedAreasTable_sprayTable__61xQr tbody th {
  height: 48px;
}

.ui.table.SelectedAreasTable_sprayTable__61xQr tbody tr.SelectedAreasTable_lastRow__1xcdE {
  background: var(--color-background-cell);
}
.ui.table.SelectedAreasTable_sprayTable__61xQr tbody tr.SelectedAreasTable_lastRow__1xcdE td {
  font-weight: bold;
}

.ui.table.SelectedAreasTable_sprayTable__61xQr tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: var(--color-border-sui-light);
  border-width: 2px;
}

.ui.table.SelectedAreasTable_sprayTable__61xQr tbody td.SelectedAreasTable_status__6toi0,
.ui.table.SelectedAreasTable_sprayTable__61xQr thead th.SelectedAreasTable_status__6toi0 {
  text-align: right;
  width: 1%;
  white-space: nowrap;
}

.ui.table.SelectedAreasTable_sprayTable__61xQr thead th {
  line-height: 1;
}

.SelectedAreasTable_variety__3IBCY {
  font-size: 11px;
  color: gray;
  font-style: italic;
}

.SelectedAreasTable_vacant__2Gyhf {
  color: var(--color-vacant-blue);
}

.ui.table.SelectedAreasTable_sprayTable__61xQr thead th:last-child,
.ui.table.SelectedAreasTable_sprayTable__61xQr tbody td:last-child {
  display: none;
}

.SelectedAreasTable_tanksAdditionalInfo__1xxRU {
  font-weight: bold;
}

@media (min-width: 767px) {
  .ui.table.SelectedAreasTable_sprayTable__61xQr tbody td.SelectedAreasTable_status__6toi0 {
    width: auto;
  }

  .ui.table.SelectedAreasTable_sprayTable__61xQr thead th,
  .ui.table.SelectedAreasTable_sprayTable__61xQr tbody td {
    white-space: nowrap;
  }

  .ui.table.SelectedAreasTable_sprayTable__61xQr tbody td.SelectedAreasTable_withInput__nWnTl {
    width: 20%;
  }
}

@media (max-width: 767px) {
  .ui.table.SelectedAreasTable_sprayTable__61xQr thead th,
  .ui.table.SelectedAreasTable_sprayTable__61xQr tbody td {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }
  .ui.table.SelectedAreasTable_sprayTable__61xQr tbody td.SelectedAreasTable_status__6toi0,
  .ui.table.SelectedAreasTable_sprayTable__61xQr thead th.SelectedAreasTable_checkboxHeaderCol__1lyip {
    display: none;
  }

  .ui.table.SelectedAreasTable_sprayTable__61xQr thead th.SelectedAreasTable_status__6toi0 span {
    display: inline;
  }

  .SelectedAreasTable_sprayTable__61xQr .ui.fitted.toggle.checkbox,
  .SelectedAreasTable_sprayTable__61xQr .ui.fitted.toggle.checkbox > input,
  .SelectedAreasTable_sprayTable__61xQr .ui.fitted.toggle.checkbox > label:before {
    width: 30px;
  }
  .SelectedAreasTable_sprayTable__61xQr
    .ui.fitted.toggle.checkbox
    > input:checked
    ~ label:after {
    left: 10px;
  }

  .ui.table.SelectedAreasTable_sprayTable__61xQr .SelectedAreasTable_areaName__ds1SR {
    word-break: break-word;
  }

  .ui.table.SelectedAreasTable_sprayTable__61xQr .ui.mini input {
    min-width: 40px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.createSprayTask_errorBar__l0jBM {
  margin: 0 0 15px 0;
}

.icon-label {
  display: flex;
  align-items: center;
}

.icon-label > .icon {
  color: var(--color-icon);
  font-size: 21px;
  text-align: center;
}

.icon-label > div {
  flex: auto;
}

.ui.search.dropdown > .text {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.createSprayTask_shedsDropdown__1WmLv {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  min-width: 14em;
  min-height: 2.71428571em;
  background: #ffffff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}

.ui.selection.dropdown.sprayTaskStatus {
  border-radius: 20px;
  color: white;
}

.ui.selection.dropdown.sprayTaskStatus .menu {
  margin-top: 0;
}

.sprayField .react-calendar {
  position: absolute;
  top: 100%;
  border: none;
  box-shadow: var(--box-shadow);
  z-index: 100;
  max-width: 250px;
}

textarea.comments {
  width: 100%;
  resize: none;
}

.ErrorBar_errorHolder__2S4TZ {
  margin: 15px;
  position: relative;
}

.ErrorBar_errorHolder__2S4TZ button {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
}
.ErrorBar_errorHolder__2S4TZ .ui.message {
  margin: 0;
}

.Signature_signatureModal__28Fjb {
  width: 440px !important;
}

.Signature_signatureModal_header__3sASO {
  border-bottom: none !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  line-height: 33px !important;
  text-align: center !important;
}

.Signature_tabSection__yY5Th {
  display: flex;
  justify-content: center;
}

.ui.secondary.pointing.menu.Signature_tabsMenu__1aP8R {
  display: flex;
  width: 60%;
}

.ui.secondary.pointing.menu.Signature_tabsMenu__1aP8R .item {
  color: black;
  text-align: center;
  flex: 1 1;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
}

.ui.secondary.pointing.menu.Signature_tabsMenu__1aP8R .item.active {
  text-align: center;
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}

.Signature_typeIcon__i4ZvO {
  width: 20px;
  height: 14px;
  padding-right: 8px;
}

.Signature_drawIcon__3Horr {
  width: 22px;
  height: 15px;
  padding-right: 8px;
}

.Signature_content__2g6HE {
}

.Signature_signatureModal_footer__3psWY {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.Signature_cancelButton__3PbGV {
  box-shadow: none !important;
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.Signature_cancelButton__3PbGV:hover {
  box-shadow: none !important;
  color: #ffffff !important;
  background: #156941 !important;
}

.Signature_saveButton__NCuY4 {
  box-shadow: none !important;
  color: #ffffff !important;
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.Signature_saveButton__NCuY4:hover {
  box-shadow: none !important;
  background: #156941 !important;
}

.Signature_saveButton__NCuY4:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

/* SignatureGenerator.module.css */
.SignatureGenerator_input__3Gy8B {
  width: 80%;
  outline: none !important;
  font-family: Roboto;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
}

.SignatureGenerator_canvas__3qbNl {
  border: 1px solid #ccc;
}

.SignatureGenerator_wrapper__1XpgU {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

.SignatureGenerator_deleteBtn__1oDRx {
  width: 40px;
  height: 40px;
  margin-bottom: 16px !important;
  margin-left: 8px !important;
  background-color: #b00020 !important;
}

.SignatureGenerator_canvasWrapper__1jCJa {
  display: flex;
  flex-direction: column;
}

.SignatureGenerator_pad__-Uz-o {
  width: 398px;
  height: 200px;
  border: 1px solid #dededf;
  border-radius: 3px;
}

.SignatureGenerator_pad__-Uz-o {
  width: 398px;
  height: 200px;
  border: 1px solid #dededf;
  border-radius: 3px;
}

.SignatureGenerator_padClearButtonWrapper__rdZxc {
  display: flex;
  justify-content: end;
  padding-top: 16px;
}

.SignatureGenerator_padClearButton__2NdI7 {
  cursor: pointer;
  background: none;
  color: #b00020 !important;
  border: none !important;
  margin-left: 10px;
}

.Sidebar_blockName__3B_dI {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  margin: 10px 0;
}

.Sidebar_root__ftrW3 {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.Sidebar_body__1azhS {
  flex: 0 1 100%;
  overflow: auto;
}

.ui.grid.Sidebar_itemGroupGrid__1ls0M {
  margin: 0;
  background: #f5efef;
  border: 1px solid #e3e1e2;
}

.ui.grid.Sidebar_itemGroupGrid__1ls0M + .Sidebar_itemGroupGrid__1ls0M {
  margin-top: 15px;
}

.ui.table.Sidebar_cropTable__2EkUd {
  background: none;
  border: none;
}

.ui.table.Sidebar_cropTable__2EkUd tbody tr td {
  border-color: var(--color-border-sui-light);
}

.ui.table.Sidebar_cropTable__2EkUd thead tr th,
.ui.table.Sidebar_cropTable__2EkUd tbody tr td {
  padding: 10px;
  background: none;
}

.ui.table.Sidebar_cropTable__2EkUd thead tr th {
  font-weight: normal;
  color: var(--color-header);
}

.ui.table.Sidebar_cropTable__2EkUd tbody tr:nth-child(2n + 1) {
  background: #fff;
}

.Sidebar_headerColumn__2q4-M {
  display: flex;
}

.ui.header.Sidebar_name__3rrO- {
  display: flex !important;
  align-items: center !important;
  margin-top: 0px;
  height: 42px !important;
  font-size: 28px;
  font-weight: 700;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__1xGux {
  display: flex;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__1xGux .item {
  flex: 1 1;
  display: block;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__1xGux .item.active {
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 18px !important;
}

.ui.label.Sidebar_attachmentLabel__m6I-d {
  width: 155px !important;
  height: 24px !important;
  border-radius: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.Sidebar_attachmentName__3_IGr {
  text-overflow: ellipsis !important;
  word-break: break-all !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  color: #212121;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.icon.Sidebar_downloadButton__7IQ9v {
  width: 9.3px !important;
  height: 9.3px !important;
  color: var(--color-green) !important;
}

.Sidebar_sideBarHeader__FeOUh {
  border-bottom: 1px solid #dededf !important;
  margin-bottom: 10px;
}

.Sidebar_sideBarHeader__FeOUh.ui.grid > .row {
  padding-bottom: 6px;
}

.Sidebar_headerRow__3f_KV {
  padding-top: 15px !important;
}

.ui.fixed.table td {
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.Sidebar_editButtonWrapper__2YFTc {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Sidebar_editButton__3AwUL {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Sidebar_editButton__3AwUL i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Sidebar_editButton__3AwUL:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__2UN7E i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.Sidebar_closeButton__2UN7E:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__2UN7E {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.Sidebar_blockContainer__3H2Ep {
  padding-top: 0px !important;
}

.Sidebar_blockContainer__3H2Ep:nth-child(4) {
  margin-bottom: 15px;
}

.Sidebar_sectionEditButton__2089z {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.icon.Sidebar_sectionEditButton__2089z:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_sectionEditButton__2089z i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
}

.Sidebar_infoContainer__mZgtN {
  padding: 0em 1em !important;
  padding-top: 9px !important;
  overflow-y: auto;
  scrollbar-width: none;
  height: auto;
}

.Sidebar_infoContainer__mZgtN::-webkit-scrollbar {
  display: none;
}

.Sidebar_infoContainer__mZgtN.ui.grid {
  margin-bottom: 0;
}

.Sidebar_infoContainer__mZgtN.ui.grid > * {
  padding-right: 0em;
  padding-left: 0em;
  padding-top: 0px !important;
}

.Sidebar_sectionEditButton__2089z {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Sidebar_sectionEditButton__2089z i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
}

.icon.Sidebar_sectionEditButton__2089z:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_notesCell__3n5qL {
  vertical-align: initial;
}

.Sidebar_expectedInventoryContent__3y1on {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  height: 83px;
  left: 0px !important;
  top: -35px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.Sidebar_averagePriceIconContent__2eRfz {
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  width: 200px;
  height: 83px;
  left: 0px;
  top: -35px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.Sidebar_expectedInventoryIcon__1SN2X {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.Sidebar_averagePriceIcon__1YzPm {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.Sidebar_meesageContainer__2nMqv {
  padding: 10px !important;
  position: absolute;
  width: 200px;
  left: -160px;
  top: -30px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  word-break: break-all !important;
}

.Sidebar_generalInfoContainer__34bK6 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_cropRatesSectionContainer__3rKOr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_chemicalPropertiesContainer__atn37 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_elementWeightSectionContainer__1jxMB {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_additionalInformationContainer__1rN1H {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_ui__3ZnsK.Sidebar_accordion__3Oimf .Sidebar_title__2sb1x {
  background-color: red !important;
}

.Sidebar_attachmentContainer__22TAZ {
  width: 90%;
}

.Sidebar_cropLabel__cEjkE {
  width: 106px !important;
}

.Sidebar_cropContainer__3ely2 {
  width: 106px !important;
}

.Sidebar_purposeLabel__3sulE {
  width: 85px !important;
}

.Sidebar_purposeContainer__2l_mX {
  width: 85px !important;
}

.Sidebar_rateLabel__2lKq5 {
  width: 109px !important;
}

.Sidebar_rateContainer__95VC4 {
  width: 109px !important;
}

.Sidebar_reEntryLabel__3i0-l {
  width: 85px !important;
}

.Sidebar_reEntryContainer__VkexV {
  width: 85px !important;
}

.Sidebar_whpLabel__2GhPj {
  width: 73px !important;
}

.Sidebar_whpContainer__Bh0I0 {
  width: 73px !important;
}

.Sidebar_commentsLabel__2rwRy {
  width: 104.6px !important;
}

.Sidebar_commentsContainer__1i8-Z {
  width: 104.6px !important;
}

.Sidebar_shedsNameWrapper__1nrxD {
  vertical-align: baseline !important;
  height: auto !important;
  padding-top: 9px;
  padding-bottom: 9px;
}
.Sidebar_shedsNameHeaderWrapper__dUWpn {
  vertical-align: baseline !important;
  height: auto !important;
  padding-top: 9px;
  padding-bottom: 9px;
}

.Filters_tabsAndFilters__1Vvu7 {
  flex: 1 1;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.Filters_moreButtonsIcon__2U54c {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  color: #666666;
}

.Filters_moreButtonsIcon__2U54c span {
  margin-right: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #666666;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.Filters_moreButtonsIcon__2U54c i {
  margin-bottom: 5px;
}

.Filters_filterDropDownIcons__DYC7Q {
  display: inline-flex;
  align-items: center;
}

.Filters_filterDropDownIcons__DYC7Q span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #666666;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.Filters_filterDropDownIcons__DYC7Q i {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Filters_filterButton__1VLvh {
  background-color: var(--color-background-cell);
  border-radius: 4px;
  padding: 6px 12px !important;
  font-weight: bold;
  color: #666666;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  justify-content: space-between;
}

.Filters_filterButton__1VLvh> .text {
  margin: 0 !important;
  padding-right: 14px !important;
}

.Filters_filterButton__1VLvh> i.icon {
  position: relative;
  margin-right: 0;
}

.Filters_filterButton__1VLvh:not(.Filters_selectedFilter__wGzzk):hover {
  background-color: #dededf;
}

.Filters_filterButton__1VLvh.dropdown .menu>.Filters_clearButton__DBtX8 {
  cursor: pointer;
  text-transform: none;
  color: #b00020;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.Filters_filterButton__1VLvh.Filters_selectedFilter__wGzzk {
  background-color: #666666;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.Filters_filterButton__1VLvh .Filters_filterMenu__2-50J {
  min-width: 100% !important;
}

.Filters_resetButton__14Tc- {
  color: #b3b3b3;
  cursor: pointer;
  font-family: Roboto, "sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  padding-left: 8px;
  border-left: 1px solid #b3b3b3;
}

.Filters_resetButton__14Tc-:hover {
  color: #666666;
}

.Filters_filterDropDown__2EOm1 {
  height: 32px;
  width: 85px;
  background-color: var(--color-background-cell) !important;
  color: #666666;
  cursor: pointer;
  font-family: Roboto, "sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 !important;
}

.Filters_filterDropDown__2EOm1:hover {
  color: #666666;
}

.ui.dropdown.dateRangePickerDropdown {
  min-width: 120px;
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  background-color: var(--color-background-cell);
  color: #666666;
  height: 32px;
  cursor: pointer;
}

.ui.multiple.search.dropdown > .text {
  padding-top: 2px !important;
  cursor: pointer;
}

.ui.dropdown.dateRangePickerDropdown:hover {
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  background-color: #dededf;
  color: #666666;
  height: 32px;
  cursor: pointer;
  min-width: 120px;
}

.ui.dropdown.dateRangePickerDropdown input {
  cursor: pointer !important;
}

.ui.dropdown.activeDateRangePickerDropdown {
  width: 160px !important;
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #666666;
  height: 32px;
  cursor: pointer;
}

.ui.multiple.search.dropdown > .text {
  width: 190px !important;
  padding-top: 2px !important;
  cursor: pointer;
}

.ui.dropdown.activeDateRangePickerDropdown:hover {
  width: 160px !important;
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #666666;
  height: 32px;
  cursor: pointer;
}

.ui.dropdown.activeDateRangePickerDropdown input {
  cursor: pointer !important;
}

.Filters_dateFilterDropDownIconsWrapper__1whfN {
  display: inline-flex;
  align-items: flex-start;
  padding-left: 14px;
}

.Filters_dateFilterDropDownIcons__fthFa {
  padding-top: 7px;
}

.ui.dropdown .menu.Filters_dateRangeSelectDropDown__1W2Pt {
  min-height: 450px !important;
  min-width: 250px !important;
  background: none;
  border: none;
  box-shadow: none !important;
}

.Filters_filterHolder__1FedL {
  background-color: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  cursor: default;
  width: 270px;
  font-weight: normal;
}

.Filters_datepickerWrapper__1qO2x {
  padding: 16px;
}

.Filters_secondDatepickerWrapper__3zrZm {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.Filters_filterDropDown__2EOm1 {
  color: #666666;
  background-color: var(--color-background-cell) !important;
  height: 32px !important;
  border-radius: 4px;
}

.Filters_scrollingMenu__1gfDe {
  border: none !important;
}

.Filters_scrollingMenuWrapper__GPJLH {
  margin-top: 2px !important;
}
.basicCell {
  overflow-wrap: break-word;
}
.basicCell:not(.BasicCell_expanded__yl6Xj) {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.BasicCell_expandButton__1aw7F {
  color: #f3bd6c;
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
}

.ui.button.Inventory_addButton__2x-61 {
  background-color: var(--color-green) !important;
}

.ui.basic.green.button.Inventory_cancelButton__3k7Nf {
  color: var(--color-green) !important;
}

.ui.basic.green.button.Inventory_actionButton__3USTX {
  display: flex;
  width: 103px !important;
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  align-items: baseline;
  color: #0e9354 !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding: 7.58px 16px 7.58px 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.Inventory_archiveIcon__3LFqH {
  color: #0e9354 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.Inventory_modalMessageHeader__29EPv {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}
.ui.modal.Inventory_messageModal__3KreX {
  width: 400px !important;
  overflow: auto;
}

.ui.modal.Inventory_messageModal__3KreX .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

.ui.modal.Inventory_messageModal__3KreX .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.ui.modal.PrindAndExportModal_modalPrintAndExport__26Lqm .content {
  column-count: 2;
  width: 100%;
  padding-top: 25px;
}

.ui.modal.PrindAndExportModal_modalPrintAndExport__26Lqm .PrindAndExportModal_contentWithSectionModal__1M1ol {
  column-count: 1;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 0px !important;
}

.ui.modal.PrindAndExportModal_modalPrintAndExport__26Lqm .PrindAndExportModal_childrenContentWithSectionModal__tKbXc {
  column-count: 2;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px !important;
}

.PrindAndExportModal_modalHeader__l5zki {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ui.button.PrindAndExportModal_reportsModalDisabledButton__2ehOa {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.PrindAndExportModal_modalAction__2lg20 {
  background-color: white !important;
}

.ui.button.PrindAndExportModal_reportsModalButton__18L6p {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #0e9354 !important;
  border-radius: 4px;
  color: white !important;
}

.ui.button.PrindAndExportModal_reportsModalButton__18L6p:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.PrindAndExportModal_reportsModalButton__18L6p:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.ui.button.PrindAndExportModal_reportsModalCancelButton__LSZyB {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.PrindAndExportModal_reportsModalCancelButton__LSZyB:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.PrindAndExportModal_reportsModalCancelButton__LSZyB:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.PrindAndExportModal_allButton__3-9Zd {
  padding-left: 21px;
  padding-top: 20px;
}

.PrindAndExportModal_reportsCheckBox__1IXmg label::before {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.PrindAndExportModal_reportsCheckBox__1IXmg label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.PrindAndExportModal_modalHeader__l5zki {
  text-align: center;
}

.components_section__Ud-0E {
  padding: 16px;
}

.components_tabSection__O_nZ4 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #dededf;
}

.components_customizeTabsBtn__1mwoA {
  min-width: 130px;
  background: none !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.06px !important;
  text-align: center !important;
  color: #0e9354 !important;
}

.components_tabsMenu__1E55o {
  width: calc((100% - 120px));
}

.components_typeWrapper__eJmzz {
  overflow-y: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.components_horizontalScroll__2UoVZ {
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: unset;
}

.components_horizontalScroll__2UoVZ::after {
  content: "";
  display: block;
  width: 10px;
}

.components_horizontalScroll__2UoVZ::-webkit-scrollbar {
  display: none;
}

.ui.secondary.pointing.menu.components_tabsMenu__1E55o {
  display: flex;
  align-items: flex-end;
  border-bottom: 0px !important;
}

.ui.secondary.pointing.menu.components_tabsMenu__1E55o .item {
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
  color: #666666;
}

.ui.secondary.pointing.menu.components_tabsMenu__1E55o .item.active {
  margin: 0px !important;
  border-color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: center;
  color: #0e9354;
}

.ui.secondary.pointing.menu.components_tabsMenu__1E55o {
  background: #fff;
  margin-bottom: 0;
}

.components_filterSection__2U3lD {
  margin-top: 16px;
}

.ui.basic.green.button.components_cancelButton__1Hbt4 {
  color: var(--color-green) !important;
}

.components_addButton__2eqUs {
  background-color: #0E9354;
}

.components_archivedModalContentHeader__1-QvS {
  color: #212121 !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: center !important;
}

.components_archivedModalContentList__2D6oj {
  color: #212121 !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
}

.components_taskTypeModal__3kj7p {
  height: 650px;
}

.components_taskTypeModalContentHeader__13Aig {
  height: 70px !important;
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.components_taskTypeModalContent__1zSkr {
  height: 510px;
  overflow-y: auto;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: transparent;
}

.components_taskCard__35CY1 {
  width: 180px;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);
  background-color: #ffffff;
  margin: 0 24px 24px 0;
  padding: 28px 33px;
  text-align: center;
}

.components_activeTaskCard__1ILG9 {
  width: 180px;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);
  background-color: #0E9354;
  margin: 0 24px 24px 0;
  padding: 28px 33px;
  text-align: center;
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.94px !important;
  text-align: center !important;
  color: white;
}

.components_taskCard__35CY1:hover {
  cursor: pointer;
  background-color: #2bb673;
  color: white;
}

.components_taskCard__35CY1 img {
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: white;
}

.components_taskCard__35CY1 .components_caption__3z952 {
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.94px !important;
  text-align: center !important;

}

.components_taskCard__35CY1:hover .components_caption__3z952 {
  color: white;
}

.components_btnContainer__4BW9b {
  height: 70px;
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: flex-end;
}

.components_taskTypeIcon__191uo {
  background-color: #EBF8F2;
  border-radius: 100%;
  margin-bottom: 24px;
}
.CardTable_container__3A8fQ {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.CardTable_tabContainer__11xq3 {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.CardTable_tabContainerRow__22aTR {
  display: flex;
  padding-top: 25px;
  grid-gap: 10px;
  gap: 10px;
  width: 100% !important;
  flex-wrap: nowrap;
  height: 100%;
}

.CardTable_cardContainerRow__1Nasg {
  width: 100%;
  display: flex;
  padding-top: 25px;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: nowrap;
  height: calc(100% - 50px);
}

.CardTable_columnContainer__2BWSx {
  height: 100%;
  width: 33%;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column;
  display: inline-block;
}

.CardTable_columnContent__1UoWB {
  width: 98%;
  height: 100%;
  padding: 0 !important;
  padding-left: 5px !important;
}

.CardTable_menuItem__3ouKj {
  height: 40px;
  width: 33% !important;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 4px 20px 8px 20px;
  border-bottom: 1px solid #dededf;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #666666;
  width: 315px;
}

.CardTable_card__Ih7Gh {
  width: 100%;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.CardTable_precentSection__1A9bE {
  padding-top: 3px;
  height: 18px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}

.CardTable_taskNameSection__25_to {
  padding-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
}

.CardTable_methodSection__1taDw {
}

.CardTable_method__10-Xz {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.CardTable_taskId__24y3r {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.CardTable_headerSection__2o3Eu {
  display: inline-block;
  height: 25px !important;
  width: 80px !important;
  padding-left: 16px;
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
}

.CardTable_valueSection__16gqu {
  height: 25px !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
}

.CardTable_employeeSection__12DdC {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 31px;
}

.CardTable_iconSectiom__2tNag {
  display: flex;
}

.CardTable_dropdownItem__1eiOC:hover {
  width: 100% !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.CardTable_iconWrapper__2jr7w {
  display: flex;
}

.CardTable_checkboxWrapper__2MB3d {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.CardTable_lineCheckbox__2hDi2 {
  position: unset !important;
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
}

.CardTable_lineCheckbox__2hDi2 label::after {
  top: 5px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.CardTable_lineCheckbox__2hDi2 label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.CardTable_checkMarkIcon__3TpzF {
  cursor: pointer;
  color: #0e9354;
  font-size: 24px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.CardTable_lineAllCheckbox__8Er7f {
  display: block !important;
  width: 24px !important;
  height: 24px !important;
  margin-right: 12px;
}

.CardTable_lineAllCheckbox__8Er7f label::after {
  top: 5px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.CardTable_lineAllCheckbox__8Er7f label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.CardTable_lineCheckboxMinus__3uoil {
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid #0e9354;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-right: 12px;
}

.CardTable_minusIcon__37FNx {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0e9354 !important;
}

.CardTable_minusIcon__37FNx::before {
  font-size: 15px !important;
}

.CardTable_emptyTableWrapper__Av9Ka {
  width: 100%;
  height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.CardTable_emptyTableText__12j3F {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.CardTable_taskDeleteIcon__34f1z {
  cursor: pointer;
  margin-right: 8px !important;
  color: #b00020 !important;
}

.CardTable_selectionDropdown__1KEjM > .text {
  display: none !important;
}

.CardTable_tasksIconWrapper__3myr8 {
  display: flex;
  align-items: center;
}

.CardTable_archiveIcon__1rSD6 {
  cursor: pointer;
  color: #0e9354 !important;
  margin-right: 8px !important;
}

.ui.popup.CardTable_expiredPopup__26d4v {
  background-color: black;
  color: white;
}

.CardTable_infoButton__1HNyE {
  margin-left: 8.38px !important;
  color: #b00020;
  margin-bottom: 5px !important;
}

i.icon {
  margin: 0;
}

.CardTable_dropdownStyle__qjpX_ {
  grid-gap: 8px !important;
  gap: 8px !important;
  width: 100% !important;
  display: flex !important;
  max-width: 130px;
  justify-content: space-between !important;
}

.Tasks_searchInput__35hYP {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.Tasks_searchInput__35hYP input {
  padding: 3px 6px 3px 16px !important;
}

.Tasks_searchInput__35hYP input::placeholder {
  color: #b3b3b3;
}

.Tasks_searchInput__35hYP input {
  padding: 3px 6px 3px 16px !important;
}

.Tasks_searchInput__35hYP input::placeholder {
  color: #b3b3b3;
}

.Tasks_lineCheckbox__3EeXJ {
  top: -4px;
  left: -9%;
}

.Tasks_lineCheckbox__3EeXJ label::after {
  top: 5px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.Tasks_lineCheckbox__3EeXJ label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.Tasks_lineAllCheckbox__1mpRJ {
  display: block !important;
  width: 24px !important;
  height: 24px !important;
  top: -5px !important;
  left: 15% !important;
}

.Tasks_lineAllCheckbox__1mpRJ label::after {
  top: 6px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.Tasks_lineAllCheckbox__1mpRJ label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.Tasks_lineCheckboxMinus__1y9uv {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #0e9354;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -5px;
  left: 15%;
}

.Tasks_minusIcon__1U4Vh {
  display: flex !important;
  padding-top: 2px !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0e9354 !important;
}

.Tasks_avatarsContainer__1qdGC {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Tasks_avatarsContainer__1qdGC span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.Tasks_avatarHolder__e7ouw {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.Tasks_avatarsContainer__1qdGC .Tasks_avatarHolder__e7ouw:nth-child(n + 2) {
  margin-left: -10px;
}

.ui.button.Tasks_addButton__2zrGK {
  background-color: var(--color-green) !important;
}

.ui.basic.green.button.Tasks_deleteBtn__2UjUD {
  color: #b00020 !important;
}

.Tasks_method__UEIR7 {
  color: #212121 !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  justify-content: flex-start !important;
  grid-gap: 16px;
  gap: 16px;
}

.ui.secondary.pointing.menu.Tasks_tabsMenu__2xxbi {
  display: flex;
  align-items: flex-end;
  background: #fff;
  margin-bottom: 0;
  border-bottom: 1px solid #dededf !important;
}

.ui.secondary.pointing.menu.Tasks_tabsMenu__2xxbi .item {
  width: 25%;
  display: flex;
  justify-content: space-between;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-align: center;
  color: #666666;
}

.ui.secondary.pointing.menu.Tasks_tabsMenu__2xxbi .item.active {
  width: 25%;
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  border-color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-align: center;
  color: #0e9354;
}

.ui.basic.buttons.Tasks_buttonsGroup__Rwse- .ui.button {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: center;
  border: none;
  position: relative;
  width: 48%;
}

.ui.basic.buttons.Tasks_buttonsGroup__Rwse-
  :not(div)
  + .ui.button:not(:first-child):before,
.ui.basic.buttons.Tasks_buttonsGroup__Rwse-
  .ui.button:not(:nth-child(-n + 2)):before {
  position: absolute;
  content: "";
  left: -2px;

  top: 50%;
  margin-top: -10px;
  height: 20px;
  width: 1px;
  background: var(--color-light-grey);
}

.ui.basic.buttons.Tasks_buttonsGroup__Rwse- .ui.button.Tasks_buttonActive__1AP6_ {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: var(--color-green) !important;
  width: 48% !important;
}

.ui.basic.buttons.Tasks_buttonsGroup__Rwse- .ui.button.Tasks_buttonActive__1AP6_:before,
.ui.basic.buttons.Tasks_buttonsGroup__Rwse-
  .ui.button.Tasks_buttonActive__1AP6_
  + .ui.button:before {
  display: none;
}

.ui.basic.buttons.Tasks_buttonsGroup__Rwse- div .ui.pointing.label {
  width: -webkit-max-content;
  width: max-content;
}

.Tasks_progressLineContainer__3h385 {
  width: 100%;
}

.Tasks_progressLine__1AzMR {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.Tasks_progressBar__1xVCO {
  height: 100%;
  width: 0;
  background-color: #f0ac47;
  transition: width 0.3s ease-in-out;
}

.Tasks_precent__mgIxm {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #666666;
}

.Tasks_stepLoading__2HEnf {
  width: 300px;
  height: 48px;
  background: #f5f7f7;
  display: flex;
  align-items: center !important;
}

.Tasks_iconAreasLabelWrapper__3Pep1 {
  width: 115px;
  display: flex !important;
  justify-content: center !important;
}

.Tasks_dotIcon__2Fkio {
  color: #59b655;
}

.Tasks_dotIconLabel__15Mm1 {
  color: #59b655;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.Tasks_iconLabelWrapper__23-k9 {
  width: 145px;
  display: flex !important;
  justify-content: center !important;
}

.Tasks_outLineCircel__37Bf1 {
  color: #dededf;
}

.Tasks_outLineCircel__37Bf1::before {
  color: #dededf;
}

.Tasks_outLineCircelLabel__1yfx8 {
  color: #666666;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.Tasks_hrLine__13PBd {
  border: 1px dashed #b3b3b3;
  width: 40px !important;
}

.Tasks_coplitedLine__2NgcD {
  border: 1px solid #0e9354 !important;
}

.Tasks_employeesLabelContainer__3NJOQ {
  margin-bottom: 16px;
}

.Tasks_workersRow__1z_mT {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  grid-gap: 5px !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.Tasks_workersRowHead__18cyR {
  font-family: "Roboto" !important;
  width: 90px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.Tasks_addedItem__PvLZe {
  display: flex !important;
  align-items: center !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.Tasks_addedItemValue__3jW8g {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tasks_closeIcon__KBO9h {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.Tasks_dropdownStyle__M9OiM {
  grid-gap: 8px !important;
  gap: 8px !important;
  width: 100% !important;
  display: flex !important;
  max-width: 130px;
  justify-content: space-between !important;
}

.DateModal_datedModal__3kyuD {
  width: 400px !important;
}

.DateModal_dateModalContent__27RbI {
  padding: 20px !important;
}

.DateModal_dateModalHeader__3cfSs {
  color: #212121 !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: center !important;
  padding-bottom: 8px;
}

.DateModal_dateModalSubHeader__3e4PZ {
  color: #212121 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  padding-bottom: 16px;
}

.DateModal_calendarLabel__cSCeK {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
}

.DateModal_dateModalBody__16NMI {
  display: flex;
  flex-direction: column;
}

.DateModal_taskDateCalendar__2czWO {
  width: 100% !important;
  height: 40px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  border: 1px 0px 0px 0px !important;
  border: 1px solid #dededf !important;
  margin-top: 4px !important;
}

.DateModal_taskDateCalendar__2czWO div {
  border: none !important;
}

.DateModal_taskDateCalendar__2czWO input:focus-visible {
  outline: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.calendarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.calendarContainer table,
.calendarContainer th,
.calendarContainer td,
.calendarContainer tr {
  border: none;
}

.fc {
  font-family: Arial, sans-serif;
}

.fc-toolbar {
  background-color: #f8f9fa;
  padding: 10px;
}

.fc-header-toolbar {
  background-color: white !important;
  margin-bottom: 0 !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 5px 0;
}

.fc-view-container {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
}

.fc-toolbar-title {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #666666 !important;
}

.fc-timeline-event {
  padding: 0px !important;
  height: 53px !important;
  background-color: none !important;
  border: none !important;
  border-radius: 8px;
}

.fc-event-main {
  color: #59b655 !important;
}

.fc-timeline-slot {
  border-color: #dee2e6;
}

.fc-resource {
  color: #666666;
}

.fc-license-message {
  display: none !important;
}

.fc-timeline-slot-frame a {
  color: #666666 !important;
}

.fc-button-primary {
  background-color: white !important;
  color: #666666 !important;
  border: none !important;
  box-shadow: none !important;
  margin-left: 0px !important;
}

.fc-customToday-button {
  background-color: white !important;
  color: #0e9354 !important;
  border: none !important;
  margin-left: 4px !important;
}

.fc .fc-button-active {
  background-color: white !important;
  color: #0e9354 !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.fc-customNext-button {
  margin-left: 4px !important;
}

.fc-button-group {
  padding: 2px;
  border: 1px solid #dededf;
  border-radius: 4px;
  min-width: 150px;
}

.fc-datagrid-cell-cushion {
  padding: 0px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-scroller-harness {
  border-bottom: 1px solid #dededf !important;
}

.fc-datagrid-body > tbody > tr > td .fc-datagrid-cell-frame {
  border-bottom: 1px solid #dededf;
}

.fc-datagrid-body > tbody > tr:last-child td .fc-datagrid-cell-frame {
  border-bottom: none !important;
}

.fc-timeline-body > .fc-timeline-slots > table > tbody > tr > td {
  border-left: 1px solid #dededf !important;
}

.fc-datagrid-cell-frame {
  height: 53px !important;
}

.fc-scrollgrid-sync-table tbody tr {
  height: 53px !important;
}

.fc-timeline-lane-frame {
  height: 53px !important;
}

.resourceWrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 53px;
}

.taskNameContainer {
  padding-left: 12px;
}

.taskNameWrapper {
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 301px;
}

.taskType {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.taskId {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #0e9354 !important;
}

.taskName {
  margin: 0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.precentSection {
  padding-left: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}

.fc-h-event {
  background-color: white !important;
}

.fc-day-sat,
.fc-day-sun {
  background-color: #f6f7f8 !important;
}

.fc-col-header-cell[data-day="6"],
.fc-col-header-cell[data-day="0"] {
  background-color: #f6f7f8;
}

.fc .fc-timeline-slot-frame {
  height: 53px !important;
}

.redirectLink {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.fc-theme-standard
  .fc-scrollgrid-sync-table
  .fc-day-today
  .fc-col-header-cell-cushion {
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: auto;
}

.fc-day-today div a {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
  height: 35px !important;
  background-color: #59b655 !important;
  color: white !important;
  border-radius: 100%;
}

.fc-scrollgrid-section-body .fc-day-today div {
  height: 100%;
  width: 1%;
  margin-left: 50%;
  background-color: #59b655;
}

.fc-scrollgrid thead {
  background-color: white !important;
  margin-bottom: 0 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 50px !important;
  z-index: 10 !important;
}

.TimeLine_calendarWrapper__1sSsn {
  height: 100%;
}

.TablePageHolder_root__2u5aM .TablePageHolder_pageWrapper__3z3mO {
  padding: 24px 16px;
}

.TablePageHolder_root__2u5aM :not(i.icon),
.TablePageHolder_root__2u5aM .ui.header {
  font-family: Roboto, sans-serif !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.2);
}

.TablePageHolder_root__2u5aM .TablePageHolder_pageTitle__23N0R.ui.header {
  display: flex !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
}

.TablePageHolder_root__2u5aM .TablePageHolder_table__1Y8Gb {
  overflow-x: auto !important;
}

.ui.segment.TablePageHolder_mainHolder__2EM8X {
  padding: 0;
}

.TablePageHolder_root__2u5aM .pusher {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.TablePageHolder_root__2u5aM .TablePageHolder_tableWrapper__2o5xP {
  margin-top: 25px;
}

.TablePageHolder_root__2u5aM .TablePageHolder_tableContainer__2iAXj,
.TablePageHolder_root__2u5aM .TablePageHolder_tableWrapper__2o5xP {
  flex: 0 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.TablePageHolder_tableContainer__2iAXj {
  overflow: auto;
}

.TablePageHolder_sidebarWrapper__2qczv.ui.sidebar {
  width: 600px;
}

.TablePageHolder_sidebarHolder__3VYJ6 {
  width: 100%;
  background-color: white;
  padding: 15px;
  height: 100%;
}

.TablePageHolder_root__2u5aM .TablePageHolder_breadcrumb__2ewgJ {
  margin-bottom: 10px;
}

.TablePageHolder_root__2u5aM .TablePageHolder_breadcrumb__2ewgJ .active.section {
  color: #666666;
}

.TablePageHolder_root__2u5aM .TablePageHolder_breadcrumb__2ewgJ .active.section:hover {
  color: var(--color-green) !important;
  text-decoration: underline;
}

.TablePageHolder_root__2u5aM .TablePageHolder_breadcrumb__2ewgJ .section {
  font-style: normal;
  font-weight: 500;
  color: #b3b3b3;
}

.ui.basic.button.TablePageHolder_archive__17PFc {
  color: var(--color-green) !important;
  margin-right: 10px;
}

.TablePageHolder_textButton__2vFFV.ui.button {
  color: var(--color-green) !important;
  height: 25px;
  width: 25px;
  padding: 0;
  box-shadow: none;
  position: relative;
  margin: 0 15px 0 0 !important;
}

.TablePageHolder_textButton__2vFFV.ui.button i {
  position: absolute;
  bottom: 8px;
  right: 7px;
}

.TablePageHolder_restoreButton__bSKHO i {
  position: absolute;
  right: 62px;
}

.TablePageHolder_deleteButton__27bvl.ui.button {
  color: var(--color-error-delete) !important;
  box-shadow: none;
  padding: 0;
  height: 25px;
  width: 25px;
  margin: 0 15px 0 0 !important;
}

.TablePageHolder_deleteButton__27bvl.ui.button:hover {
  color: var(--color-error-delete) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 20px;
  box-shadow: none !important;
}

.TablePageHolder_textButton__2vFFV.ui.button:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 20px;
  box-shadow: none !important;
}

.TablePageHolder_textButtonContainer__2AgyK {
  display: flex !important;
  justify-content: end;
}

.TablePageHolder_textButtonContainer__2AgyK i {
  height: 14px;
  width: 12px;
}

.TablePageHolder_editButton__1Y11t i {
  margin-bottom: 4px;
}

.TablePageHolder_archiveTooltipPosition__3yYDp {
  right: 10px;
  min-width: 156px !important;
  width: auto;
}

.TablePageHolder_editButtonTooltipPosition__3duN_ {
  right: 2px !important;
}

.TablePageHolder_restoreButtonTooltipPosition__RqvlW {
  right: -5px !important;
}

.TablePageHolder_noPermissionTooltipPosition__ct6_t {
  right: -38px !important;
  width: 156px !important;
}

.TablePageHolder_flattenBtn__rZ0Oi {
  background: none !important;
  width: 25px !important;
  height: 25px !important;
}

.TablePageHolder_flattenBtnIcon__2oTzt {
  background: none !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 15px !important;
}
.CustomTooltip_elementContainer__3yBdS {
  position: relative;
  display: inline-flex;
}

.CustomTooltip_tooltipBody__PhaTF {
  position: absolute;
  padding: 5px 12px;
  border-radius: 4px;
  background: black;
  color: white;
  text-align: center;
  min-width: 50px;
  top: 35px;
  right: 0;
  z-index: 50;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
}

.CustomTooltip_elementContainer__3yBdS .CustomTooltip_tooltipBody__PhaTF {
  display: none;
}

.CustomTooltip_elementContainer__3yBdS:hover .CustomTooltip_tooltipBody__PhaTF {
  display: flex;
}

.CropRatesSection_comment__1TTQB {
  width: 100%;
}

.CropRatesSection_root__y6qQZ .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
}

.CropRatesSection_cropRatesForm__HOfnk {
  margin-top: 8px !important;
  padding: 16px !important;
}

.CropRatesSection_editButton__fs77f,
.CropRatesSection_deleteButton__2AG_b {
  cursor: pointer;
}

.CropRatesSection_buttonContainer__2gZf4 {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.CropRatesSection_formButtonContainer__15brB {
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
}

.CropRatesSection_rowCrop___Hbq6 {
  width: 180px !important;
  padding: 0px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CropRatesSection_rowPurpose__2iZvj {
  width: 180px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CropRatesSection_rowRate__396ZS {
  width: 109px !important;
  padding-left: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CropRatesSection_rowReentr__3GCVa {
  width: 80px !important;
  padding-left: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CropRatesSection_rowWHP__31bSG {
  width: 80px !important;
  padding-left: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CropRatesSection_rowComments__D1nLo {
  width: 223px !important;
  padding: 0px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CropRatesSection_rowButtonCell__2Sg9n {
  display: flex !important;
  padding-top: 10px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: center !important;
  width: 84px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.CropRatesSection_rowLabelCrop__3_zrS {
  width: 196px !important;
  padding: 0px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.CropRatesSection_rowLabelPurpose__1Ggcq {
  width: 164px !important;
  padding: 0px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.CropRatesSection_rowLabelRate__3hWXx {
  padding: 0px !important;
  width: 109px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.CropRatesSection_rowLabelReentr__2Vqoe {
  padding: 0px !important;
  width: 80px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.CropRatesSection_rowLabelWHP__mGn2e {
  padding: 0px !important;
  width: 80px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.CropRatesSection_rowLabelComments__oma-5 {
  padding: 0px !important;
  width: 307px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.CropRatesSection_meesageContainer__1IoTJ {
  padding: 10px !important;
  position: absolute;
  width: 200px;
  left: -160px;
  top: -5px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  word-break: break-all !important;
}

.CropRatesSection_editButtonWrapper__2syXG {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}

.CropRatesSection_editButtonWrapper__2syXG {
  display: flex;
  margin-left: auto;
}

.CropRatesSection_editButton__fs77f {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.CropRatesSection_editButton__fs77f i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.CropRatesSection_editButton__fs77f:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.CropRatesSection_closeButton__2d0FD i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.CropRatesSection_closeButton__2d0FD:hover {
  color: var(--color-error-delete) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.CropRatesSection_closeButton__2d0FD {
  height: 12px;
  width: 12px;
  background-color: transparent !important;
  color: var(--color-error-delete) !important;
  position: relative;
}

.ui.form .field > div.CropRatesSection_withholdingPeriod__iDCmF {
  height: 40px !important;
}

.ui.form .field > div.CropRatesSection_reentry__2APF7 {
  height: 40px !important;
}

.ui.form .field > div.CropRatesSection_crop__3v_RZ {
  height: 40px !important;
}

.ui.form .field > div.CropRatesSection_purpose__CEifb {
  height: 40px !important;
}

.ui.form .field > div.CropRatesSection_reentry__2APF7 {
  height: 40px !important;
}

.ui.form .field > div.CropRatesSection_reentry__2APF7 {
  height: 40px !important;
}

.ui.form .field > div.CropRatesSection_reentry__2APF7 {
  height: 40px !important;
}

.ui.button.CropRatesSection_cancelBtn__37Ep- {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.CropRatesSection_rowContainer__1ZQRu {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0px !important;
  height: 40px !important;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.CropRatesSection_labelContainer__-4jnG {
  padding: 8px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}

.CropRatesSection_errorMessage__1Swlo {
  padding-top: 4px !important;
  width: 113px;
  height: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #b00020 !important;
}

.ui.labeled.reentryWarningStyle > .label {
  background-color: #b00020 !important;
  border: 1px solid #b00020 !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  color: #ffffff !important;
  border-radius: 0px 3px 3px 0px !important;
}

.ui.input.reentryWarningStyle > input {
  background: #ffffff !important;
  border: 1px solid #b00020 !important;
  box-sizing: border-box !important;
}

.CropRatesSection_reentryWarningStyle_label__30GDw {
  color: #b00020 !important;
}

.ui.withholdingPeriodWarningStyle > .label {
  background-color: #b00020 !important;
  border: 1px solid #b00020 !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  color: #ffffff !important;
  border-radius: 0px 3px 3px 0px !important;
}

.ui.input.withholdingPeriodWarningStyle > input {
  background: #ffffff !important;
  border: 1px solid #b00020 !important;
  box-sizing: border-box !important;
}

.CropRatesSection_withholdingPeriodWarningStyle_label__1e_Lj {
  color: #b00020 !important;
}

.CropRatesSection_editFormContainer__2gJK8 {
  margin-bottom: 8px !important;
}

.CropRatesSection_ratesContainer__tU4Ac {
  margin-bottom: 0px !important;
}

.ui.segment.CropRatesSection_cropRatesForm__HOfnk {
  margin-top: 8px !important;
}

.CropRatesSection_purposeErrorMessage__2L4N7 {
  width: 50%;
  margin-right: 15px !important;
}

.CropRatesSection_messageContainer__3buUg {
  width: 70%;
  margin-right: 15px !important;
}

.CropRatesSection_addButtonContainer__Lga8m {
  display: flex;
  justify-content: end;
  padding-top: 8px;
}

.CropRatesSection_cropFieldRow__32BnI {
  margin-bottom: 16px !important;
}

.ActionMessage_deletedMessage__38BMN .ActionMessage_undoButton__3XZ3m {
  color: var(--color-green);
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.ActionMessage_deletedMessage__38BMN {
  position: fixed;
  left: 0;
  top: 80px;
  padding: 0 1rem;
  width: 100%;
  box-shadow: none;
}

.ActionMessage_deletedMessageIn__xJDnK {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border: transparent;
  box-shadow: none;
}

.ActionMessage_deletedMessage__38BMN .ActionMessage_closeButton__3_SjN {
  color: #666666;
}

.ActionMessage_deletedMessage__38BMN .ActionMessage_closeButton__3_SjN:hover {
  cursor: pointer;
  color: #000000;
}

.Sidebar_blockName__EYeeO {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  margin: 10px 0;
}

.Sidebar_root__hyioE .ui.segment {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
}

.Sidebar_root__hyioE {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.Sidebar_body__kTBDc {
  flex: 0 1 100%;
  height: 75%;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 5px !important;
}

.ui.grid.Sidebar_itemGroupGrid__3fJkb {
  margin: 0;
  background: #f5efef;
  border: 1px solid #e3e1e2;
}

.ui.grid.Sidebar_itemGroupGrid__3fJkb + .Sidebar_itemGroupGrid__3fJkb {
  margin-top: 15px;
}

.Sidebar_headerColumn__1RSFp {
  display: flex;
}

.ui.header.Sidebar_name__2Hwlj {
  font-size: 28px;
  font-weight: 700;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__35cSF {
  display: flex;
  margin-top: 22px !important;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__35cSF .item {
  text-align: center;
  flex: 1 1;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__35cSF .item.active {
  text-align: center;
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}

.icon.Sidebar_editButton__3oje5,
.icon.Sidebar_downloadButton__2VA9k {
  color: var(--color-green) !important;
}

.ui.label.Sidebar_attachmentLabel__2kCpK {
  border-radius: 20px;
}

.ui.table {
  word-wrap: break-word;
}

.Sidebar_tabsHolder__6YVyu {
  width: 100%;
}

.Sidebar_tabItem__2E1rt {
  overflow: hidden;
  height: 0;
}

.Sidebar_tabItem__2E1rt.Sidebar_visibleTab__2yO89 {
  height: auto;
  height: 100%;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__35cSF {
  background: #fff;
  margin-bottom: 0;
}

.ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.Sidebar_buttonContainer__ggJ7O {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.Sidebar_closeButton__2nKy4 i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.Sidebar_closeButton__2nKy4:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__2nKy4 {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.Sidebar_editButtonWrapper__163b- {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Sidebar_editButton__3oje5 {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Sidebar_editButton__3oje5 i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Sidebar_editButton__3oje5:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_addressRow__TiJ8E {
  height: 40px !important;
  padding: 9.5px 0px 9.5px 16px !important;
  /* padding: 0px !important; */
}

.Sidebar_comentsRow__128a6 {
  height: 40px !important;
  vertical-align: text-top;
  padding: 9.5px 0px 9.5px 16px !important;
}

.Sidebar_addressValueRow__1xr2E {
  height: 40px !important;
  padding: 9.5px 0px 9.5px 16px !important;
}

.Sidebar_comentsValueRow__2Hcok {
  height: 40px !important;
  padding: 9.5px 0px 9.5px 16px !important;
}

.Sidebar_sidebarHeader__22Hoa {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 10px !important;
}

.Sidebar_headerWrapper__wbpCy {
  display: flex;
  padding-left: 38px !important;
  line-height: 15px;
  align-items: center;
}

.Sidebar_countWrapper__3dYVY {
  display: flex;
  margin-left: 8px !important;
  justify-content: center;
  align-items: center;
  background: #f0ac47;
  border-radius: 10px;
  width: 18px !important;
  height: 18px;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.Inventory_root__3Rwcr .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 0px 0px 8px 0px !important;
}

.ui.header.Inventory_titleContainer__3Ykrh {
  display: flex;
  justify-content: inherit;
}

.Inventory_rowContainer__apWnX {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center !important;
  /* height: 40px; */
  /* margin-bottom: 8px !important; */
}

.Inventory_addFormContainer__2z2_x {
  margin-top: 15px !important;
  border: 1px solid #dededf;
  box-shadow: none;
}

.Inventory_filterContainer__255mY {
  display: flex;
  align-items: center;
  margin: 24px 0 25px;
  grid-gap: 8px;
  gap: 8px;
}

.Inventory_editButton__1a-6V,
.Inventory_deleteButton__3N7R_ {
  cursor: pointer;
}

.Inventory_buttonContainer__3e66c {
  margin-top: 20px !important;
  display: flex;
  justify-content: end;
}

.ui.grid .column.Inventory_rowLabel__3w5K6 {
  padding-bottom: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.grid .column.Inventory_rowLabel__3w5K6:not(:first-child) {
  padding-left: 28px !important;
}

.ui.grid > .column.Inventory_chevronContainer__3b3CP {
  width: 2%;
  padding-bottom: 0;
  margin-right: 10px;
}

.ui.grid > .column.Inventory_productNameItem__2lyIe {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 30%;
  padding-bottom: 0;
  padding-left: 0px;
}

ui.modal.Inventory_productEditModalContainer__3f3Mk {
  background-color: red !important;
}

.ui.grid > .column.Inventory_productTotalCostItem__2Rdmb {
  height: 40px;
  width: 31%;
  padding: 0;
  padding-right: 14px !important;
  display: flex;
  align-items: center;
}

.Inventory_productTotalCostItem__2Rdmb span {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 15px !important;
}

.ui.grid > .column.Inventory_productExpectedInventoryItem__1x475 {
  width: 21%;
  padding: 0;
  padding-right: 14px !important;
  display: flex;
  align-items: center;
}

.ui.grid > .column.Inventory_sideBarProductButonContainer__1SHiH {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px !important;
}

.ui.grid > .column.Inventory_productButonContainer__awNxK {
  display: flex !important;
  justify-content: flex-end;
  height: 40px !important;
  padding-top: 0 !important;
  display: flex;
  width: 13%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

.ui.table thead tr th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ui.grid > div .column > .icon.Inventory_infoButton__36NVq {
  height: 16px;
  color: #b3b3b3;
  display: flex;
  align-items: center;
}

.ui.popup.Inventory_popup__24jUu {
  background-color: black;
  color: white;
}

.ui.modal > .content.Inventory_productEditModal__o7scI {
  padding: 0px !important;
}

.Inventory_productEditModalRow__1lm39 {
  padding: 0 !important;
}

.ui.button.Inventory_disabledBtn__3WDLM {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.table.Inventory_tableFarm__1TRqf thead tr th {
  background: transparent !important;
}

.ui.table.Inventory_tableFarm__1TRqf thead tr:first-child > th:first-child,
.ui.table.Inventory_tableFarm__1TRqf tbody tr > td:first-child {
  position: relative;
  /* width: 25%; */
}

.ui.table.Inventory_tableFarm__1TRqf
  thead tr:first-child > th:last-child i.icon,
.ui.table.Inventory_tableFarm__1TRqf tbody tr > td:last-child i.icon {
  cursor: pointer;
}

.Inventory_toggleIcon__2aH9G {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 20px;
  height: 20px;
  font-size: 15px;
  display: inline-block;
  color: #666666;
  cursor: pointer;
}

.Inventory_toggleIcon__2aH9G.Inventory_openTable__xHZPT {
  padding: 0px !important;
  cursor: pointer;
}

.Inventory_editForm__3MOSg {
  width: 100%;
}

.ui.labeled.editCurrentInventoryField {
  margin-top: 18px !important;
}

.ui.labeled.editCurrentInventoryField > .label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7f8 !important;
  border: 1px solid #dededf;
  color: #212121;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ui.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}

.ui.basic.disabled.buttonActive {
  border: 1px solid red !important;
}

.ui.labeled.warningStyle > .label {
  background-color: #b00020;
  border: 1px solid #b00020;
  color: #ffffff;
  border-radius: 0px 3px 3px 0px !important;
}

.ui.input.warningStyle > input {
  background: #ffffff;
  border: 1px solid #b00020;
  box-sizing: border-box;
}

.ui.button.Inventory_deleteBtn__qVYLJ {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Inventory_deleteBtn__qVYLJ:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Inventory_cancelDltBtn__1_uW5 {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.Inventory_warningStyle_label__caoi3 {
  color: #b00020 !important;
}

.ui.button.Inventory_cancelBtn__BD2bj {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.Inventory_inventoryRowTable__2nCUP thead tr th {
  background: transparent !important;
}

.Inventory_root__3Rwcr .Inventory_negativeElement__3lJPZ {
  color: #b00020;
}

.ui.labeled.negativeElement {
  margin-top: 18px !important;
}

.ui.form .field .ui.input.labeled.negativeElement input {
  color: #b00020;
}

.ui.segment.Inventory_sideBarInventoryForm__1vja- {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 15px 0px 14px 0px !important;
}

.ui.segment.Inventory_sideBarInventory___Oui2 {
  padding: 0;
}

.Inventory_messageRowContainer__17URQ {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  display: flex;
  justify-content: center !important;
}

.Inventory_warrningMessages__2xie- {
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  background: #fffaf2 !important;
}

.Inventory_warrningMessagesContentWrapper__15pLZ {
  padding-left: 10px !important;
  padding-right: 0px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.Inventory_messagesCloseButtonContainer__eg7Na {
  width: 66px !important;
  height: 28px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 0px !important;
  padding-right: 6px !important;
  margin-left: 8px !important;
}

.ui.grid > .row > .column.Inventory_warrningMessagesIconWrapper__zx94n {
  width: 24px !important;
  height: 100%;
  padding: 0 !important;
  padding-left: 6px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.grid > .row > .column > .icon.Inventory_warrningMessagesIcon__18pqO {
  height: 24px !important;
  width: 24px !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  color: #f0ac47;
  background: none;
}

.ui.grid.Inventory_warrningMessagesIcon__18pqO {
  display: flex !important;
  align-items: center !important;
}

.ui.button.Inventory_messagesCloseButton__34XGl {
  padding: 0px;
  margin-right: 0px;
  background: none;
  color: #0e9354;
}

.Inventory_deleteButton__3N7R_ i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020;
}

.icon.Inventory_deleteButton__3N7R_:hover {
  color: #b00020;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Inventory_deleteButton__3N7R_ {
  height: 32px;
  width: 32px;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: #b00020;
  position: relative;
}

.Inventory_editButtonWrapper__30vSV {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Inventory_editButton__1a-6V {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Inventory_editButton__1a-6V i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Inventory_editButton__1a-6V:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Inventory_infoTooltipWrapper__Dxqll {
  text-align: start;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.Inventory_daterow__3KcMr {
  width: 100px !important;
}

.Inventory_pricerow__3VRoX {
  width: 103px !important;
  padding-left: 6px !important;
}

.Inventory_expInventoryrow__1Jw47 {
  width: 125px !important;
}

.Inventory_iconrow__1TAyc {
  width: 50px !important;
}

.Inventory_icomNameContainer__1ij4P {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  width: 32.5% !important;
}

.Inventory_labelWrapper__2R_NZ {
  display: block !important;
  padding-bottom: 8px !important;
  font-size: 12px;
}

.Inventory_productRowLabel__2L8hJ {
  padding-left: 0px !important;
  width: 30% !important;
}

.Inventory_priceRowLabel__3fG2M {
  width: 31% !important;
}

.Inventory_expectedInventoryRowLabel__1Lthp {
  width: 35% !important;
}

.Inventory_positiveProductHeader__TOkt8 {
  font-family: "Roboto" !important;
  margin-top: 26px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}

.Inventory_negativeProductHeader__gs6r0 {
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}

.Inventory_productNameItem__2lyIe {
  display: flex;
  align-items: center !important;
}

.Inventory_productNameItem__2lyIe span {
  min-width: 10px !important;
  max-width: 100px !important;
  padding-right: 8px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 15px !important;
}

.Inventory_ui__2gC_o.Inventory_table__2zgAr th.Inventory_two__2-f-I.Inventory_wide__2G4wd,
.Inventory_ui__2gC_o.Inventory_table__2zgAr td.Inventory_two__2-f-I.Inventory_wide__2G4wd {
  width: 0 !important;
}

.Inventory_prinButtonContainer__1ai1b {
  display: flex;
  color: #0e9354;
  /* padding-left: 180px; */
}
.Inventory_filterbuttonWrapper__3NqmH {
  display: flex;
  justify-content: space-between;
}

.Inventory_iconWrapper__no0JX {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-left: 17px;
}
.Inventory_iconWrapper__no0JX i {
  cursor: pointer;
}

.Inventory_root__1aqBD .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

.ui.header.Inventory_titleContainer__3CEN7 {
  display: flex;
  justify-content: inherit;
}

.Inventory_rowContainer__13vtk {
  margin-top: 8px;
  padding: 0 !important;
}

.Inventory_editButton__3HjAE,
.Inventory_deleteButton__3KpQv {
  cursor: pointer;
}

.Inventory_buttonContainer__292mg {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}

.ui.grid .column.Inventory_rowLabel__2dc-X {
  width: 16.5%;
  padding-bottom: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.grid > .column.Inventory_chevronContainer__3g8dV {
  width: 2%;
  padding-bottom: 0;
}

.ui.grid > .column.Inventory_productNameItem__3uCIQ {
  padding-top: 0 !important;
  height: 40px !important;
  width: 31%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

.ui.grid > .column.Inventory_productTotalCostItem__1Onah {
  height: 40px !important;
  padding-top: 0 !important;
  width: 32%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

.ui.grid > .column.Inventory_productExpectedInventoryItem__3T1Ar {
  height: 40px !important;
  padding-top: 0 !important;
  width: 20%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

.ui.grid > .column.Inventory_productButonContainer__3uX6u {
  display: flex !important;
  justify-content: flex-end;
  height: 40px !important;
  padding-top: 0 !important;
  display: flex;
  width: 13%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

.ui.table thead tr th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ui.dropdown .menu > .item:hover {
  background-color: #ebf6e9 !important;
}

.ui.grid > .row > .column > .icon.Inventory_infoButton__1l6Tx {
  margin-left: 10.38px;
  color: #b3b3b3;
}

.ui.popup.Inventory_popup__PKsbY {
  background-color: black;
  color: white;
}

.ui.button.Inventory_cancelBtn__7y5SN {
  width: 75px;
  height: 36px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 16px;
  background: none;
  color: #0e9354;
  border: 1px solid #0e9354;
}

.ui.button.Inventory_saveBtn__2aRPX {
  height: 36px !important;
  width: 63px !important;
  border-radius: 4px;
  padding: 0px;
  margin-top: 16px;
  background-color: #0e9354;
  color: #ffffff;
}

.ui.button.Inventory_disabledBtn__2ngjE {
  height: 36px !important;
  width: 63px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 16px;
  color: #b3b3b3;
}

.ui.grid > .column > .icon.Inventory_infoButton__1l6Tx {
  margin-left: 8.38px;
  color: #b3b3b3;
  margin-bottom: 5px !important;
}

.Inventory_warningMessagesInModal__23saJ {
  box-shadow: none !important;
}

.ui.popup.Inventory_popup__PKsbY {
  background-color: black;
  color: white;
}

.ui.table.Inventory_tableFarm__2ANdS thead tr:first-child > th:first-child,
.ui.table.Inventory_tableFarm__2ANdS tbody tr > td:first-child {
  position: relative;
  width: 25%;
}

.Inventory_confirmModal__26QG1 {
  width: 45% !important;
}

.ui.table.Inventory_tableFarm__2ANdS
  thead tr:first-child > th:last-child i.icon,
.ui.table.Inventory_tableFarm__2ANdS tbody tr > td:last-child i.icon {
  cursor: pointer;
}

.Inventory_toggleIcon__270tI {
  width: 20px;
  height: 20px;
  font-size: 15px;
  display: inline-block;
  color: #666666;
  cursor: pointer;
}

.Inventory_toggleIcon__270tI.Inventory_openTable__QYQS8 {
  cursor: pointer;
}

.Inventory_editForm__3QnpB {
  width: 100%;
  padding: 16px !important;
  border-radius: 8px !important;
}

.Inventory_productHeader__1NJVZ {
  display: flex !important;
  justify-content: center !important;
  font-size: 22px !important;
  margin-bottom: 24px !important;
}

.Inventory_productRow__1LBE_ {
  height: 45px !important;
  margin-bottom: 16px !important;
}

.Inventory_productEditButtonContainer__-6wNB {
  border-top: 1px solid #dededf;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.ui.labeled.editCurrentInventoryField {
  margin-top: 18px !important;
  opacity: 1 !important;
}

.ui.labeled.editCurrentInventoryField input {
  opacity: 1 !important;
}

.ui.labeled.editCurrentInventoryField > .label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7f8 !important;
  border: 1px solid #dededf;
  color: #212121;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ui.labeled.negativeElement {
  margin-top: 18px !important;
  opacity: 1 !important;
}

.ui.labeled.negativeElement input {
  opacity: 1 !important;
}

.ui.labeled.negativeElement > .label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7f8 !important;
  border: 1px solid #dededf;
  color: #212121;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ui.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}

.ui.basic.disabled.buttonActive {
  height: 40px !important;
}

.ui.labeled.warningStyle > .label {
  height: 40px !important;
  background-color: #b00020;
  border: 1px solid #b00020;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  color: #ffffff;
  border-radius: 0px 3px 3px 0px !important;
}

.ui.input.warningStyle > input {
  height: 40px !important;
  background: #ffffff;
  border: 1px solid #b00020;
  box-sizing: border-box;
}

.Inventory_warningStyle_label__3K1Uv {
  color: #b00020 !important;
}

.ui.modal.Inventory_deleteModal__bXxPR {
  margin: 30%;
}

.ui.button.Inventory_deleteBtn__2RcFG {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Inventory_deleteBtn__2RcFG:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Inventory_cancelDltBtn__3IJWb {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.Inventory_root__1aqBD .Inventory_negativeElement___1eul {
  color: #b00020;
}

.ui.input.negativeElement > .input {
  color: #b00020;
  opacity: 1 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.Inventory_deleteButton__3KpQv i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

.icon.Inventory_deleteButton__3KpQv:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Inventory_deleteButton__3KpQv {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}

.Inventory_editButtonWrapper__22ZZu {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Inventory_editButton__3HjAE {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Inventory_editButton__3HjAE i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Inventory_editButton__3HjAE:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Inventory_infoTooltipWrapper__1vNZL {
  text-align: start;
}

.Inventory_ui__3HIyn.Inventory_form__ZAbhY .Inventory_field__3jfqO .Inventory_ui__3HIyn.Inventory_input__2LGND.Inventory_labeled__3S7Ty input {
  opacity: 1 !important;
}

.Inventory_reasonMessage__1CpB0 {
  color: rgba(240, 172, 71, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-top: 4px !important;
}

.Inventory_switchButton__1-KhT {
  padding-left: 0px !important;
}

.Inventory_formDisabledBtn__1CqW9 {
  padding: 0px !important;
  background: none !important;
  color: #b3b3b3 !important;
}

.Inventory_formCancelBtn__MwQVz {
  padding: 0px !important;
  margin-right: 16px !important;
  background: none !important;
  color: #b00020 !important;
}

.ui.modal.printoutTypeModal_modalPrintAndExport__1Qqc8 {
  width: 400px !important;
}

.ui.modal.printoutTypeModal_modalPrintAndExport__1Qqc8 .content {
  padding-top: 25px;
}

.ui.button.printoutTypeModal_reportsModalDisabledButton__3rt9t {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.printoutTypeModal_modalAction__3XLB3 {
  background-color: white !important;
}

.ui.button.printoutTypeModal_reportsModalButton__2vwx4 {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #0e9354 !important;
  border-radius: 4px;
  color: white !important;
}

.ui.button.printoutTypeModal_reportsModalButton__2vwx4:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.printoutTypeModal_reportsModalButton__2vwx4:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.ui.button.printoutTypeModal_reportsModalCancelButton__3BUDJ {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.printoutTypeModal_reportsModalCancelButton__3BUDJ:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.printoutTypeModal_reportsModalCancelButton__3BUDJ:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.printoutTypeModal_allButton__ii_5Y {
  padding-left: 21px;
  padding-top: 20px;
}

.printoutTypeModal_reportsCheckBox__3IVN1 label::before {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.printoutTypeModal_reportsCheckBox__3IVN1 label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.printoutTypeModal_modalHeader__1SspH {
  text-align: center;
  border: none !important;
}

.printoutTypeModal_checkboxWrapper__2PHPG {
  display: flex;
  flex-direction: column;
}

.printoutTypeModal_inventoryBox__1gnMw {
  display: flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}

.printoutTypeModal_inventoryBox__1gnMw label {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  /* line-height: 27px !important; */
}

.printoutTypeModal_inventoryBox__1gnMw input {
  padding-top: 5px !important;
}

.printoutTypeModal_stocktake__-kS3W {
  display: flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}

.printoutTypeModal_stocktake__-kS3W label {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  /* line-height: 27px !important; */
}

.printoutTypeModal_stocktake__-kS3W input {
  padding-top: 5px;
}

.printoutTypeModal_inventoryCheckboxContainer__3Z0hY {
  height: 27px !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 8px !important;
}

.printoutTypeModal_stocktakeCheckboxContainer__f8CC8 {
  height: 27px !important;
  display: flex !important;
  align-items: center !important;
}

.PrintoutInventory_reportWrapper__32QTo {
  padding: 25px !important;
}

.PrintoutInventory_reportHeaderRow__15mWu {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.PrintoutInventory_reportHeader__2GqJH {
  width: 930px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintoutInventory_logoContainet__2dfan {
  width: 90px;
  height: 50px;
  margin-left: 12px !important;
}

.PrintoutInventory_headerContainer__av7zX {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.PrintoutInventory_totalHeader__25Vgu {
  height: 35px;
  border-bottom: 1px solid #dededf;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  vertical-align: text-bottom;
  padding-bottom: 4px !important;
}

.PrintoutInventory_totalValue__Npttt {
  height: 35px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  vertical-align: text-top;
  padding-top: 4px !important;
}

.PrintoutInventory_tableHeaderCell__9-qhn {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
  background-color: white !important;
}

.PrintoutInventory_tableBodyCell__1ejn0 {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
}

.PrintoutInventory_tableBodyExpectedInventoryCell__2mQsZ {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #b00020 !important;
}

.PhysicalStocktake_reportWrapper__2dD-b {
  padding: 25px !important;
}

.PhysicalStocktake_reportHeaderRow__1x2Je {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.PhysicalStocktake_reportHeader__zz9Ts {
  width: 930px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PhysicalStocktake_logoContainet__2uXMO {
  width: 90px;
  height: 50px;
  margin-left: 12px !important;
}

.PhysicalStocktake_headerContainer__3H8J3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.PhysicalStocktake_totalHeader__aeA8t {
  height: 35px;
  border-bottom: 1px solid #dededf;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  vertical-align: text-bottom;
  padding-bottom: 4px !important;
}

.PhysicalStocktake_totalValue__ZjmY0 {
  height: 35px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  vertical-align: text-top;
  padding-top: 4px !important;
}

.PhysicalStocktake_tableHeaderCell__JY_u3 {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
  background-color: white !important;
}

.PhysicalStocktake_tableBodyCell__2MV8w {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
}

.PhysicalStocktake_tableBodyExpectedInventoryCell__1D3u8 {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #b00020 !important;
}

.Farms_comment__37QLr {
  width: 100%;
}

.Farms_root__JPpKA .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 8px 0px;
}

.ui.segment.Farms_SideBarFarmform__mwr20 {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 15px 0px 14px 0px !important;
}

.ui.segment.Farms_sideBarFarm__ZqZR7 {
  padding-top: 30px !important;
  padding: 0;
}

.ui.grid > .row > .column.Farms_farmColumn__OcS-7 {
  width: 15%;
}

.ui.grid > .row > .column.Farms_farmHeader__27MUS {
  padding: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.Farms_editButton__31eCJ,
.Farms_deleteButton__1soMb {
  cursor: pointer;
}

.Farms_buttonContainer__1X_5v {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}

.ui.button.Farms_cancelBtn__3eUmT {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.ui.button.Farms_disabledBtn__3Hb3N {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.button.Farms_cancelDltBtn__2aMIk {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.Farms_deleteBtn__49aPK {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Farms_deleteBtn__49aPK:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.grid > .row > .column > .icon.Farms_infoButton__1PZAN {
  margin-left: 8.38px;
  color: #b3b3b3;
}

.ui.popup.Farms_popup__3utHn {
  background-color: black;
  color: white;
}

.ui.grid > .row > .column > .icon.Farms_infoButton__1PZAN {
  margin-left: 8.38px;
  color: #b3b3b3;
}

.ui.popup.Farms_popup__3utHn {
  background-color: black;
  color: white;
}
.Farms_deleteButton__1soMb i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020;
}

.icon.Farms_deleteButton__1soMb:hover {
  color: #b00020;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Farms_deleteButton__1soMb {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020;
  position: relative;
}

.Farms_rowContainer__2G_LJ {
  display: flex !important;
  align-items: center !important;
  height: 40px !important;
  padding: 0 !important;
}
.Farms_labelContainer__1Jffv {
  padding: 0px !important;
  padding-bottom: 4px !important;
}

.Threshold_comment__3mWRg {
  width: 100%;
}

.Threshold_root__RwIg7 .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 8px 0px;
}

.Threshold_styles__1pzqX.Threshold_sideBarThresholdForm__37htX {
  box-shadow: none;
  margin: 15px 0px 14px 0px !important;
}

.ui.grid > .row > .column.Threshold_thresholdColumn__2HLR5 {
  width: 21%;
}

.ui.grid > .row > .column.Threshold_thresholdHeader__3MdQy {
  width: 20%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.grid > .row > .column.Threshold_thresholdProductHeader__3XKWJ {
  padding-left: 0;
  width: 25%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.grid > .row > .column.Threshold_thresholdProduct__1AnTN {
  width: 25%;
}

.ui.button.Threshold_cancelDltBtn__35liA {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.Threshold_deleteBtn__2UTNO {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Threshold_deleteBtn__2UTNO:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.Threshold_editButton__1ME3e,
.Threshold_deleteButton__1ZbRW {
  cursor: pointer;
}

.Threshold_buttonContainer__1qaop {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}

.ui.button.Threshold_cancelBtn__3WYAb {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.ui.button.Threshold_disabledBtn__1YOAO {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.grid > .row > .column > .icon.Threshold_infoButton__1v8AD {
  margin-left: 8.38px;
  color: #b3b3b3;
}

.ui.popup.Threshold_popup__2JLR6 {
  background-color: black;
  color: white;
}

.Threshold_addFormContainer__lq34q {
  margin-top: 15px !important;
  border: 1px solid #dededf;
  box-shadow: none;
}

.ui.segment.Threshold_sideBarThresholdForm__37htX {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 15px 0px 14px 0px !important;
}

.ui.segment.Threshold_sideBarThreshold__3Jk2D {
  /* padding-top: 10px !important; */
  height: 80%;
  padding: 0;
  margin-top: 38px !important;
}

.Threshold_thresholdsbuttonContainer__3i1Rp {
  display: flex !important;
  align-items: center !important;
}

.Threshold_deleteButton__1ZbRW i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020;
}

.icon.Threshold_deleteButton__1ZbRW:hover {
  color: #b00020;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Threshold_deleteButton__1ZbRW {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020;
  position: relative;
}

.Threshold_editButtonWrapper__3f6gu {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Threshold_editButton__1ME3e {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Threshold_editButton__1ME3e i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Threshold_editButton__1ME3e:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Threshold_rowContainer__3j-YY {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px !important;
}

.Threshold_labelContainer__32uqc {
  padding: 0px !important;
  padding-bottom: 4px !important;
}

.ui.grid > .row > .column > .icon.Threshold_warrningMessagesIcon__1UcXy {
  padding-top: 50%;
  color: #f0ac47;
  background: none;
}
.Threshold_warrningMessagesContent__2VryJ {
  padding-left: 10px !important;
  padding-right: 8px !important;
}

.ui.grid.Threshold_warrningMessagesIcon__1UcXy {
  display: flex !important;
  align-items: center !important;
}

.Threshold_warrningMessages__1u05Q {
  width: 100% !important;
  margin-top: 8px !important;
  margin-bottom: 16px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.Threshold_iconTextContainer__Uuj9s {
  display: flex !important;
  align-items: center !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.ui.grid > .row > .column.Threshold_iconWrapper__3t6gX {
  margin-left: 8px !important;
  display: flex;
  width: 24px !important;
  height: 100% !important;
  padding: 0px !important;
}

.Threshold_negativeThreshold__3fWdK {
  border: 1px solid rgba(176, 0, 32, 1) !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px !important;
}

.Threshold_negativeCellThreshold__6cEbY {
  color: rgba(176, 0, 32, 1) !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px !important;
}

.ui.popup.Threshold_popup__2JLR6 {
  background-color: black;
  color: white;
}

.ui.grid > div .column > .icon.Threshold_infoNegativeButton__1v0ro {
  margin-left: 14.38px;
  margin-bottom: 4px;
  color: rgba(176, 0, 32, 1) !important;
}
.ui.grid > div .column > .icon.Threshold_infoButton__1v8AD {
  margin-left: 14.38px;
  margin-bottom: 4px;
}

.Threshold_messageContainer__20j2j {
  display: flex !important;
  align-items: flex-start !important;
  padding: 10px !important;
  position: absolute !important;
  width: 200px;
  height: 125px;
  left: 0px !important;
  top: -45px !important;
  background: #212121 !important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.Threshold_negativeThresholdMessageContainer__1sJ4n {
  display: flex !important;
  align-items: flex-start !important;
  padding: 10px !important;
  position: absolute !important;
  width: 200px;
  height: 100px !important;
  left: 0px !important;
  top: -45px !important;
  background: #212121 !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}
/* .productsWrapper {
  width: 100%;
  overflow: auto;
  height:50%;
}
 */

.ui.button.Sheds_addButton__M1qBs {
  background-color: var(--color-green) !important;
}

.ui.basic.green.button.Sheds_cancelButton__3fPha {
  color: var(--color-green) !important;
}

.ui.basic.green.button.Sheds_actionButton__6yfuM {
  width: 103px !important;
  height: 28px !important;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}
.Sheds_infoCircle__1OuXh {
  /* color: #B3B3B3 !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
  margin-right: 8px !important;
}

.ui.modal.Sheds_archivedModal__380MB {
  width: 670px;
  height: 60px;
}

.ui.modal > .content.Sheds_archivedModalContent__36B-S {
  background: #fffaf2;
  height: 60px !important;
  padding: 10px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  color: #f0ac47;
}

.Sheds_archivedModalContentButton__LyKYf {
  padding: 5px !important;
  margin-left: 15px !important;
  color: var(--color-green) !important ;
  background: none !important;
}

.ui.button.Sheds_printButton__3Yfbj {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.Sheds_printButton__3Yfbj:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.Sheds_printButton__3Yfbj:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.Sheds_printButton__3Yfbj:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.Sheds_iconWrapper__1vbwJ {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.Sheds_buttonWrapper__3H4g_ {
  display: flex;
}

.Sheds_reportWrapper__1BfqJ {
  padding: 25px !important;
}

.Sheds_reportHeaderRow__39kFZ {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.Sheds_reportHeader__1MiBQ {
  width: 930px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.Sheds_logoContainet__oKpqZ {
  width: 90px;
  height: 50px;
  margin-left: 12px !important;
}

.Sheds_headerContainer__1Lhsj {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.Sheds_totalHeader__298AG {
  height: 35px;
  border-bottom: 1px solid #dededf;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  vertical-align: text-bottom;
  padding-bottom: 4px !important;
}

.Sheds_totalValue__3CN-T {
  height: 35px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  vertical-align: text-top;
  padding-top: 4px !important;
}

.Sheds_tableHeaderCell__EkFsi {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
  background-color: white !important;
}

.Sheds_tableBodyCell__ldsSZ {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
}

.Sheds_tableBodyExpectedInventoryCell__2jBOZ {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #b00020 !important;
}

.Sheds_tableFooterFirstColumn__3Ieo9 {
  height: 40px;
  border-top: 1px solid #dededf !important;
  padding-left: 10px !important;
}
.Sheds_tableFooterEmptyColumn__1pr0E {
  height: 40px;
  border-top: 1px solid #dededf !important;
  border-right: none !important;
  border-left: none !important;
}

.Sheds_tableFooterSecondColumn__3103p {
  height: 40px;
  border-top: 1px solid #dededf !important;
  padding-left: 10px !important;
}

.Threshold_comment__16MM8 {
  width: 100%;
}

.Threshold_root__1V6YK .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

.Threshold_editButton__3J_fo,
.Threshold_deleteButton__1PXHl {
  cursor: pointer;
}

.Threshold_buttonContainer__1-79r {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}
.Threshold_rowContainer__29gLt {
  height: 40px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
}
.ui.grid .column.Threshold_rowLabel__1ZR9Q {
  padding-bottom: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.button.Threshold_cancelBtn__2vNck {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.ui.button.Threshold_disabledBtn__2-uyi {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.modal.Threshold_deleteModal__3W5iR {
  margin: 30%;
}

.ui.button.Threshold_deleteBtn__1GkQD {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Threshold_deleteBtn__1GkQD:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Threshold_cancelDltBtn__FQD5Z {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.Threshold_deleteButton__1PXHl i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

.icon.Threshold_deleteButton__1PXHl:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Threshold_deleteButton__1PXHl {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}

.Threshold_editButtonWrapper__2tnjs {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Threshold_editButton__3J_fo {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Threshold_editButton__3J_fo i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Threshold_editButton__3J_fo:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Threshold_thresholdsButtonCantainer__2PLNT {
  display: flex !important;
}

.Farm_comment__1XZwA {
  width: 100%;
}

.Farm_root__3HR8M .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

.Farm_editButton__13Xmx,
.Farm_deleteButton__1MZdN {
  cursor: pointer;
}

.Farm_buttonContainer__2VI2b {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}

.ui.grid .column.Farm_rowLabel__1VWyC {
  padding-bottom: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.button.Farm_cancelBtn__1c-am {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.ui.button.Farm_disabledBtn__1ugSF {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.modal.Farm_deleteModal__2AREQ {
  margin: 30%;
}

.ui.button.Farm_deleteBtn__2FCTK {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Farm_deleteBtn__2FCTK:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Farm_cancelDltBtn__3iquz {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.Farm_deleteButton__1MZdN i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

.icon.Farm_deleteButton__1MZdN:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Farm_deleteButton__1MZdN {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}

.Farm_rowContainer__1rKLJ {
  height: 40px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.ShedModal_root__2vAUn :not(i.icon),
.ShedModal_root__2vAUn .ui.header {
  font-family: Roboto, sans-serif !important;
}

.ShedModal_root__2vAUn .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}
.ShedModal_root__2vAUn .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
.ui.modal.ShedModal_confirmModal__GJdGD {
  margin: 30%;
}
.ShedModal_root__2vAUn .ui.grid {
  margin: 0;
}
.ShedModal_root__2vAUn .content > :not(:first-child) {
  margin-top: 16px !important;
}

.ShedModal_textButton__3LdO5.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.ShedModal_textButtonContainer__1cZ1W {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}
.ShedModal_ui__351jM.ShedModal_grid__25jop > .ShedModal_row__3-8lf > .ShedModal_column__DVnXN.ShedModal_column-close__3Xr-k {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}
.ShedModal_modalContentContainer__2GrLD {
  min-height: 70vh;
  scrollbar-width: none;
}

.ShedModal_modalContentContainer__2GrLD::-webkit-scrollbar {
  display: none;
}

.ActionMessage_deletedMessage__BiOZ2 .ActionMessage_undoButton__WQBvb {
  color: #b00020;
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.ActionMessage_deletedMessage__BiOZ2 {
  position: fixed;
  left: 0;
  top: 80px;
  padding: 0 1rem;
  width: 100%;
  box-shadow: none;
}

.ActionMessage_deletedMessageIn__1mY3z {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border: transparent;
  box-shadow: none;
}

.ActionMessage_deletedMessage__BiOZ2 .ActionMessage_closeButton__3wS7L {
  color: #666666;
}

.ActionMessage_deletedMessage__BiOZ2 .ActionMessage_closeButton__3wS7L:hover {
  cursor: pointer;
  color: #000000;
}

.PrintReports_printTableWrapper__2UheN {
  margin-right: 20px !important;
}

.PrintReports_printTableRow__2J27C {
  display: flex;
}

.PrintReports_printTableHeader__ujhmZ {
  width: 1150px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-right: 12px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_printTableLogo__3KUd4 {
  height: 80px !important;
  margin-right: 12px !important;
  margin-top: 12px !important;
}

.PrintReports_totalRowWrapper__1IdJd {
  width: 95% !important;
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.PrintReports_totalRowHeaderWrapper__22j2D {
  width: 100% !important;
  border-bottom: 1px solid #dededf !important;
  border-top: 1px solid #dededf !important;
  padding-bottom: 4px !important;
  display: flex !important;
  align-items: flex-start !important;
}

.PrintReports_totalRowHeaderContainer__3_y7V {
  width: 100% !important;
  padding-bottom: 4px !important;
  display: flex !important;
  align-items: flex-end !important;
}

.PrintReports_totalRowValueWrapper__3JyOC {
  width: 100% !important;
  display: flex;
}

.PrintReports_container__2GZ-M {
  margin: 20px !important;
}

.PrintReports_totalRowcontainer__3c1K3 {
  margin-right: 15px !important;
}

.PrintReports_cellHeader__2qpzb {
  width: 99% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  margin-bottom: 4px !important;
}

.PrintReports_cellValue__3DDWx {
  width: 99% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReports_cellTotalHeader__2nGl7 {
  width: 7.1% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-bottom: none !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReports_cellTotalValue__1JieN {
  width: 7.1% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReports_totalHeader__Q96WX {
  height: 18px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  margin-bottom: 5px !important;
}

.PrintReports_blockTable__2pq-c {
  margin-bottom: 6px !important;
}

.PrintReports_firstHeaderRow__1VWoF {
  min-height: 23px !important;
  padding: 0 !important;
}

.PrintReports_firstHeader__2pDVI {
  min-height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border: none !important;
  background-color: #eeeeef !important;
}

.PrintReports_firstTableSecondHeader__15rT_ {
  min-height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-bottom: none !important;
  border-top: none !important;
  background-color: #eeeeef !important;
  border-left: 1px solid #dededf !important;
}

.PrintReports_firstValue___uf2d {
  min-height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-bottom: none !important;
  border-top: none !important;
  background-color: #eeeeef !important;
}

.PrintReports_secondHeaderRow__2q6AS {
  min-height: 38px !important;
  padding: 0 !important;
  padding: 0 !important;
}

.PrintReports_secondHeader__3FYwB {
  width: 7.1% !important;
  min-height: 38px !important;
  padding: 0 !important;
  padding-left: 7px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  background-color: #eeeeef !important;
  border-top: 1px solid #dededf !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  vertical-align: text-top !important;
}

.PrintReports_secondHeaderFirstColumn__1D5fJ {
  width: 7.1% !important;
  min-height: 38px !important;
  padding: 0 !important;
  padding-left: 7px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  background-color: #eeeeef !important;
  border-top: 1px solid #dededf !important;
  border-bottom: 1px solid #dededf !important;
  vertical-align: text-top !important;
}

.PrintReports_blockValueRow__iyLLx {
  height: 23px !important;
  padding: 0 !important;
}

.PrintReports_blockValue__3AIhZ {
  width: 7.1% !important;
  max-height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  background-color: #eeeeef !important;
  color: #212121;
  border-left: 1px solid #dededf !important;
  border-bottom: 1px solid black !important;
  vertical-align: text-top !important;
}

.PrintReports_blockValueFirstColumn___vNAQ {
  width: 7.1% !important;
  height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 10px !important;
  background-color: #eeeeef !important;
  color: #212121;
  border-bottom: 1px solid black !important;
  vertical-align: text-top !important;
}

.PrintReports_patchTable__uQk6- {
  margin-bottom: 10px !important;
}

.PrintReports_patchTablefirstHeader__S0rWM {
  height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border: none !important;
}

.PrintReports_patchTablefirstTableSecondHeader__KPNcl {
  height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-bottom: none !important;
  border-top: none !important;
  border-left: 1px solid #dededf !important;
}

.PrintReports_patchTablefirstValue__1AFdA {
  height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-bottom: none !important;
  border-top: none !important;
}

.PrintReports_patchTablesecondHeader__2BYeW {
  width: 7.1% !important;
  height: 38px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-top: 1px solid #dededf !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  vertical-align: text-top !important;
}

.PrintReports_patchTablesecondHeaderFirstColumn__1RZr6 {
  width: 7.1% !important;
  height: 38px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-top: 1px solid #dededf !important;
  border-bottom: 1px solid #dededf !important;
  vertical-align: text-top !important;
}

.PrintReports_patchTableblockValue__Xi1Nw {
  width: 7.1% !important;
  height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-left: 1px solid #dededf !important;
  border-bottom: 1px solid black !important;
  padding-top: 4px !important;
}

.PrintReports_patchTableblockValueFirstColumn__V_rD1 {
  width: 7.1% !important;
  height: 23px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121;
  border-bottom: 1px solid black !important;
  padding-top: 4px !important;
}

.ui.button.NutrientBreakdown_printButton__2HBeD {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.NutrientBreakdown_printButton__2HBeD:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.NutrientBreakdown_printButton__2HBeD:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.NutrientBreakdown_printButton__2HBeD:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.NutrientBreakdown_iconWrapper__1MyUL {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.button.NutrientBreakdown_backIconWrapper__3vYTK {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.NutrientBreakdown_backIcon__wcHxy {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.NutrientBreakdown_totalRow__2_8-6 {
  font-weight: 600;
}
.ui.button.NutrientBreakdown_backIconWrapper__3vYTK {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.NutrientBreakdown_backIcon__wcHxy {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ui.button.NutrientBreakdown_printButtonForPage__LVo_j {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.NutrientBreakdown_printButtonForPage__LVo_j:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.NutrientBreakdown_printButtonForPage__LVo_j:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.NutrientBreakdown_printButtonForPage__LVo_j:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.NutrientBreakdown_iconWrapperForPage__2mV75 {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.button.PlantingReport_backIconWrapper__35v37 {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.PlantingReport_backIcon__5bVtY {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ui.button.PlantingReport_printButton__34b-H {
  height: 28px;
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  min-width: 100px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.PlantingReport_printButton__34b-H:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.PlantingReport_printButton__34b-H:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.PlantingReport_printButton__34b-H:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.PrintReports_printTableWrapper__2rap0 {
  margin-right: 20px !important;
}

.PrintReports_printTableRow__2HI8V {
  display: flex;
}

.PrintReports_printTableHeader__1fAQe {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-right: 12px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_printTableLogo__1zFcJ {
  height: 80px !important;
  margin-right: 12px !important;
  margin-top: 12px !important;
}

.PrintReports_totalRowWrapper__esKaZ {
  width: 95% !important;
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.PrintReports_totalRowHeaderWrapper__3pyPB {
  width: 100% !important;
  display: flex !important;
}

.PrintReports_totalRowValueWrapper__1wABB {
  width: 100% !important;
  display: flex !important;
}

.PrintReports_totalRowDateHeaderContainer__2mit8 {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReports_totalRowDateValueContainer__1-y7- {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReports_totalRowProductHeaderContainer__3v5K3 {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReports_totalRowProductValueContainer__3rRZU {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReports_totalRowFarmHeaderContainer__LbeGO {
  width: 33% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReports_totalRowFarmValueContainer__1TvLM {
  width: 33% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReports_container__2DvY_ {
  border-bottom: 1px solid black !important;
  margin: 20px !important;
}

.PrintReports_reportTable__2tEDp {
  width: 100%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReports_reportHeader__13dmD {
  background-color: #eeeeef !important;
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintReports_reportBody__3ihXP {
  background-color: #eeeeef !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: auto !important;
  max-height: 50px;
  margin-right: 20px !important;
  border-left: 1px solid #dededf;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintReports_reportBody__3ihXP:first-child {
  border-left: none !important;
}

.PrintReports_reportHeader__13dmD:first-child {
  border-left: none !important;
}

.ProductTypeUsageTable_iconWrapper__s29K3 {
  display: flex;
  justify-content: flex-start !important;
  padding-right: 0px !important;
  border: none !important;
}

.ProductTypeUsageTable_exportIconWrapper__3HRdz {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 0px !important;
}

.ProductTypeUsageTable_editButtonWrapper__1EPGF {
  display: flex;
  width: 32px;
  margin-right: 0px !important;

  padding-right: 0px !important;
}

.ProductTypeUsageTable_editButton__1Ct8d {
  height: 32px;
  width: 32px;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.ProductTypeUsageTable_editButton__1Ct8d i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.ProductTypeUsageTable_editButton__1Ct8d:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.ProductTypeUsageTable_printButton__2GtUo i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--color-green) !important;
}

.icon.ProductTypeUsageTable_printButton__2GtUo:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ProductTypeUsageTable_printButton__2GtUo {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

td:has(.ProductTypeUsageTable_valueContainer__3VOSK) {
  border: none !important;
}
.ProductTypeUsageTable_valueContainer__3VOSK {
  display: flex;
  align-items: center;
  height: 50px;
}
.ProductTypeUsageTable_chevronIcon__1JG2T {
  margin-right: 5px !important;
}

.ui.button.ProductTypeUsageTable_backIconWrapper__uhBQU {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}
.ProductTypeUsageTable_backIcon__HQRIM {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ui.button.ProductTypeUsageTable_printButtonForPage__bf80G {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.ProductTypeUsageTable_printButtonForPage__bf80G:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.ProductTypeUsageTable_printButtonForPage__bf80G:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.ProductTypeUsageTable_printButtonForPage__bf80G:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.ProductTypeUsageTable_iconWrapperForPage__1hR8p {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.PrintReport_printTableWrapper__w9dvB {
  margin-right: 20px !important;
}

.PrintReport_printTableRow__C1IvL {
  display: flex;
}

.PrintReport_printTableHeader__3hhPq {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-right: 12px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReport_printTableLogo__WoEiq {
  height: 80px !important;
  margin-right: 12px !important;
  margin-top: 12px !important;
}

.PrintReport_totalRowWrapper__3oX1I {
  width: 95% !important;
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.PrintReport_totalRowHeaderWrapper__2ZIh2 {
  width: 100% !important;
  display: flex !important;
}

.PrintReport_totalRowValueWrapper__caIjq {
  width: 100% !important;
  display: flex !important;
}

.PrintReport_totalRowDateHeaderContainer__1FE3x {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReport_totalRowDateValueContainer__1dItq {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReport_totalRowProductHeaderContainer__1oSOV {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReport_totalRowProductValueContainer__KF7nu {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}
.PrintReport_totalRowTypeHeaderContainer__lqR4Q {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReport_totalRowTypeValueContainer__1Pv4V {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReport_totalRowFarmHeaderContainer__28B8z {
  width: 25% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintReport_totalRowFarmValueContainer__3G-ac {
  width: 25% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintReport_container__d-qF9 {
  border-bottom: 1px solid black !important;
  margin: 20px !important;
}

.PrintReport_reportTable__21ff9 {
  width: 100%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReport_reportHeader___5lxi {
  background-color: #eeeeef !important;
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintReport_reportBody__1xRC6 {
  background-color: #eeeeef !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: auto !important;
  max-height: 50px;
  margin-right: 20px !important;
  border-left: 1px solid #dededf;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintReport_reportBody__1xRC6:first-child {
  border-left: none !important;
}

.PrintReport_reportHeader___5lxi:first-child {
  border-left: none !important;
}

.PrintReport_secondReportTable__36tDJ {
  width: 100%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReport_secondReportHeader__P5OTF {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintReport_secondReportBody__3EuqD {
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: auto !important;
  max-height: 50px;
  margin-right: 20px !important;
  border-left: 1px solid #dededf;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintReport_secondReportBody__3EuqD:first-child {
  height: auto !important;
  max-height: 50px;
  border-left: none !important;
  padding-left: 0 !important;
}

.PrintReport_secondReportHeader__P5OTF:first-child {
  padding-left: 10px !important;
  border-left: none !important;
}

.ProductUsageTable_iconWrapper__33O33 {
  display: flex;
  justify-content: flex-start !important;
  padding-right: 0px !important;
  border: none !important;
}

.ProductUsageTable_exportIconWrapper__22AQu {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 0px !important;
}

.ProductUsageTable_editButtonWrapper__VPwmZ {
  display: flex;
  width: 32px;
  margin-right: 0px !important;

  padding-right: 0px !important;
}

.ProductUsageTable_editButton__gVIoZ {
  height: 32px;
  width: 32px;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.ProductUsageTable_editButton__gVIoZ i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.ProductUsageTable_editButton__gVIoZ:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.ProductUsageTable_printButton__1c-bx i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--color-green) !important;
}

.icon.ProductUsageTable_printButton__1c-bx:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ProductUsageTable_printButton__1c-bx {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

td:has(.ProductUsageTable_valueContainer__16-ta) {
  border: none !important;
}
.ProductUsageTable_valueContainer__16-ta {
  display: flex;
  align-items: center;
  height: 50px;
}
.ProductUsageTable_chevronIcon__3uj1R {
  margin-right: 5px !important;
}

.ui.button.ProductUsageTable_backIconWrapper__2d4U2 {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}
.ProductUsageTable_backIcon__gPzky {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ui.button.ProductUsageTable_printButtonForPage__CJni7 {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.ProductUsageTable_printButtonForPage__CJni7:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.ProductUsageTable_printButtonForPage__CJni7:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.ProductUsageTable_printButtonForPage__CJni7:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.ProductUsageTable_iconWrapperForPage__1ZHR3 {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.popup.InventoryMovements_popup__3he_q {
  background-color: black;
  color: white;
}

.InventoryMovements_infoButton__1pnme {
  margin-left: 8.38px;
  color: #b3b3b3;
}

i.icon {
  margin: 0;
}

.InventoryMovements_infoButton__1pnme {
  margin-left: 8.38px !important;
  color: #b3b3b3 !important;
}

.InventoryMovements_messageContainer__3_C6W {
  text-align: start;
  display: flex !important;
  flex-direction: column;
  padding: 10px !important;
  position: absolute !important;
  min-width: 200px;
  width: auto;
  left: 0px !important;
  top: -45px !important;
  background: #212121 !important;
  border-radius: 4px !important;
  font-size: 14px;
  line-height: 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
}

.InventoryMovements_avatarsContainer__2Yiov {
  width: 30px;
  height: 30px;
  padding: 0 !important;
  padding-top: 2px !important;
  margin: 0;
  border-radius: 100%;
  background-size: 100%;
  background-color: #d1dce3;
  display: flex !important;
  justify-content: center !important;
  color: white;
  align-items: center !important;
}

.InventoryMovements_userAvatarsContainer__26wbl {
  width: 31px;
  height: 31px;
  padding: 0 !important;
  margin: 0;
  border-radius: 100%;
  background-size: 98%;
  background-color: #d1dce3;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}

.InventoryMovements_linkWrapper__2GRxJ {
  display: grid;
}

.InventoryMovements_avatarsIcon__1FRlI {
  width: 20px !important;
  height: 20px !important;
}

.InventoryMovements_reasonMessages__6m39v {
  padding: 0px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  -webkit-line-clamp: 1 !;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.ui.button.InventoryMovements_printButton__20Bhg {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.InventoryMovements_printButton__20Bhg:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.InventoryMovements_printButton__20Bhg:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.InventoryMovements_printButton__20Bhg:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.InventoryMovements_iconWrapper__2L5DE {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
.InventoryMovements_buttonWrapper__1ds_K {
  display: flex;
}

.PrintInventoryMovements_printTableWrapper__x_y6u {
  margin-right: 20px !important;
}

.PrintInventoryMovements_printTableRow__2Fkee {
  display: flex;
}

.PrintInventoryMovements_printTableHeader__gC0qg {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-right: 12px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintInventoryMovements_printTableLogo__1lctj {
  height: 80px !important;
  margin-right: 12px !important;
  margin-top: 12px !important;
}

.PrintInventoryMovements_totalRowWrapper__eINyP {
  width: 95% !important;
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.PrintInventoryMovements_totalRowHeaderWrapper__3wtL6 {
  width: 100% !important;
  display: flex;
}

.PrintInventoryMovements_totalRowValueWrapper__1d8Te {
  width: 100% !important;
  display: flex;
}

.PrintInventoryMovements_line__2Ejsa {
  height: 1px;
  background-color: #dededf;
}

.PrintInventoryMovements_totalRowDateHeaderContainer__1L7U6 {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintInventoryMovements_totalRowDateValueContainer__1TkJJ {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintInventoryMovements_totalRowProductHeaderContainer__PhpQU {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintInventoryMovements_totalRowProductValueContainer__rXbNx {
  width: 33% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintInventoryMovements_totalRowShedHeaderContainer__3-tPW {
  width: 33% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintInventoryMovements_totalRowShedsValueContainer__3ILIC {
  width: 33% !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintInventoryMovements_container__3wzd_ {
  border-bottom: 1px solid black !important;
  margin: 20px !important;
}

.PrintInventoryMovements_reportTable__IKyPE {
  width: 100%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintInventoryMovements_reportHeader__3cYXw {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 20%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintInventoryMovements_reportBody__AVMV2 {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 20%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintInventoryMovements_secondreportTable__UAPXe {
  width: 100%;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.PrintInventoryMovements_secondreportHeader__3I3gN {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintInventoryMovements_secondreportBody__E88wD {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  max-height: 50px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintInventoryMovements_secondreportBody__E88wD:first-child {
  border-left: none !important;
}

.PrintInventoryMovements_secondreportHeader__3I3gN:first-child {
  border-left: none !important;
}

.PrintInventoryMovements_linkWrapper__2fDe- {
  display: grid;
}

.ui.button.PendingMovements_printButton__nY0LY {
  height: 28px;
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  min-width: 100px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.PendingMovements_printButton__nY0LY:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.PendingMovements_printButton__nY0LY:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.PendingMovements_printButton__nY0LY:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.PurchaseHistoryModal_root__1oueN :not(i.icon),
.PurchaseHistoryModal_root__1oueN .ui.header {
  font-family: Roboto, sans-serif !important;
}

.PurchaseHistoryModal_root__1oueN .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.PurchaseHistoryModal_root__1oueN .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.PurchaseHistoryModal_confirmModal__3oMLh {
  margin: 30%;
}

.PurchaseHistoryModal_root__1oueN .ui.grid {
  margin: 0;
}

.PurchaseHistoryModal_textButton__1mb9G.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.PurchaseHistoryModal_textButtonContainer__3B6-1 {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.PurchaseHistoryModal_ui__37fdV.PurchaseHistoryModal_grid__2k1Jf > .PurchaseHistoryModal_row__1EaA3 > .PurchaseHistoryModal_column__31yY4.PurchaseHistoryModal_column-close__2bX9S {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.PurchaseHistoryModal_root__1oueN :not(i.icon),
.PurchaseHistoryModal_root__1oueN .ui.header {
  font-family: Roboto, sans-serif !important;
}

.PurchaseHistoryModal_root__1oueN .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 8px !important;
}

.PurchaseHistoryModal_root__1oueN .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 8px !important;
}

.PurchaseHistoryModal_root__1oueN .ui.grid {
  margin: 0;
}

.PurchaseHistoryModal_removeSuppliers__3IM00 {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.PurchaseHistoryModal_suppliersSection__2aqx2 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.PurchaseHistoryModal_suppliersHeader__MIT36 {
  margin-bottom: 0 !important;
}

.PurchaseHistoryModal_suppliersWrapper__3TK9x {
  height: 40px !important;
}

.PurchaseHistoryModal_textButton__1mb9G.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.PurchaseHistoryModal_textButtonContainer__3B6-1 {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.PurchaseHistoryModal_modalContentContainer__2cH5v {
  min-height: 70vh;
  scrollbar-width: none;
}

.PurchaseHistoryModal_modalContentContainer__2cH5v::-webkit-scrollbar {
  display: none;
}

.PurchaseHistoryModal_generalAttachmentsContainer__YkDCy {
  display: flex !important;
  flex-wrap: wrap;
  margin-bottom: 4px !important;
}

.PurchaseHistoryModal_generalAttachmentsButton__2xy4n {
  margin-left: auto !important;
}

.PurchaseHistoryModal_root__1oueN .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.PurchaseHistoryModal_root__1oueN .PurchaseHistoryModal_chemicalPropertyColumn__dKo9V {
  padding-left: 0 !important;
}

.PurchaseHistoryModal_root__1oueN .PurchaseHistoryModal_chemicalPropertyColumn__dKo9V .field {
  margin-bottom: 16px !important;
}

.PurchaseHistoryModal_editButton__3jdFX,
.PurchaseHistoryModal_deleteButton__JrOvk {
  cursor: pointer;
}

.PurchaseHistoryModal_deleteButton__JrOvk {
  cursor: pointer;
}

.PurchaseHistoryModal_upload-input__Hh8N8 {
  display: none;
  border-radius: 10px;
}

.PurchaseHistoryModal_btn__TICnF {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  width: 300px;
  height: 48px;
  border-radius: 10px;
  background: #eef1f5;
  box-shadow: none;
  margin-top: 8px;
  align-items: center;
  padding: 10px;
}

.ui.label.PurchaseHistoryModal_attachmentLabel__2foGl {
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  max-width: 155px;
}

.ui.search.dropdown > .text {
  padding: 0 !important;
}

.PurchaseHistoryModal_secondRow__TmTDl {
  margin-bottom: 18px !important;
}
.PurchaseHistoryModal_valueWrapper__6fnqA {
  padding-top: 15px !important;
}

.Product_productHeader__1HovV {
  font-weight: 500 !important;
  margin-bottom: 16px !important;
  font-size: 18px !important;
  line-height: 27px !important;
}

.Product_root__6tzrb .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

.Product_root__6tzrb :not(i.icon),
.Product_root__6tzrb .ui.header {
  font-family: Roboto, sans-serif !important;
}

.Product_root__6tzrb .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.ui.modal.Product_confirmModal__3DV8g {
  margin: 30%;
}

.Product_root__6tzrb .ui.grid {
  margin: 0;
}

.Product_root__6tzrb .content > :not(:first-child) {
  margin-top: 16px !important;
}

.Product_rowContainer__3Qb_N {
  width: 100% !important;
  padding-right: 0 !important;
  padding-top: 8px;
  padding-left: 0 !important;
}

.Product_wrapper__3t4Uo {
  width: 100% !important;
  display: flex;
}

.Product_wrapperRow__2wP1l {
  width: 100% !important;
  height: 65px !important;
  padding-right: 22px !important;
  padding-left: 16px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.Product_columnContainer__3o8a6 {
  width: 70% !important;
  display: flex;
  justify-content: space-between !important;
}

.Product_costQtyContainer__2UrqU {
  width: 62% !important;
  padding-left: 0px;
  padding-right: 0px;
  display: flex !important;
}

.Product_iconContainer__2kZoU {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Product_firstRow__1LSDO {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.Product_buttonContainer__1xZqR {
  padding-top: 2px !important;
  margin-top: 0px !important;
  width: 100%;
  text-align: end;
}
.Product_addButtonContainer__GbpwO {
  padding-top: 8px !important;
  margin-top: 0px !important;
  width: 100%;
  text-align: end;
}

.Product_calendarWrapper__1r74p {
  position: fixed;
}

.Product_saveButton__1tGgT {
  padding: 0px !important;
  color: #0e9354 !important;
  background: none !important;
}

.ui.button.Product_disabledBtn__1xnFG {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.basic.disabled.buttonActive {
  border: 1px solid red !important;
}

.ui.button.Product_cancelBtn__2545k {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.Product_editButton__2Hga-,
.Product_deleteButton__3kdpw {
  cursor: pointer;
}

.ui.button.Product_cancelDltBtn__2S43D {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.Product_cancelDltBtn__2S43D:hover {
  background-color: #156941;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
}

.ui.button.Product_deleteBtn__2Kt7B {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Product_deleteBtn__2Kt7B:hover {
  background: #8c0d24;
  border-radius: 4px;
  color: white;
}

.Product_nameColumn__ZPvhw {
  width: 250px;
  margin-right: 32px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
}

.Product_costColumn__26kLw {
  text-align: start;
  /* width: 30%;   */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #666666;
}

.Product_dotColumn__2LKvx {
  width: 4%;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Product_quantityColumn__1E0BC {
  width: 30%;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #666666;
}

.Product_formContainer__2k590 {
  display: initial !important;
  padding-top: 2px !important;
  width: 100 !important;
  padding-top: 0px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.Product_toggleIcon__2_sfr i {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  cursor: pointer;
}

.Product_toggleIcon__2_sfr.Product_openTable__3gAp- {
  cursor: pointer;
  padding: 5px;
}

.Product_productHeader__1HovV {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px !important;
}
.Product_productInformationHeader__MJh0G {
  height: 27px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 33px !important;
}

.Product_productInformationHeader__MJh0G {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 33px !important;
}

.Product_productInformationHeader__MJh0G {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 33px !important;
}

.Product_dot__3Vn-4 {
  height: 5px !important;
  width: 5px !important;
  background-color: #bbb !important;
  border-radius: 50% !important;
  display: inline-block !important;
}

.Product_removeManufacturer__2nn1H {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.Product_manufacturersSection__eK-jw {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.Product_manufacturerHeader__1ZVGw {
  margin-bottom: 0 !important;
}

.Product_manufacturerWrapper__76ka3 {
  height: 40px !important;
}

.ui.search.dropdown > .text {
  padding: 0px !important;
}

.ui.labeled.input > .label {
  height: 40px !important;
}

.ui.input.Product_totalCostField__mWlUh {
  height: 40px !important;
}

/* .manufacturerField div {
  height: 40px;
} */
.ui.form .field > .selection.dropdown {
  height: 40px;
}
.Product_newProductWrapper__1-UBs {
  margin-bottom: 14px;
}

.NewProduct_newProductRow__3vDwT {
  display: flex !important;
  padding: 0 !important;
  align-items: center !important;
  height: 40px !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
  margin-top: 8px !important;
}

.NewProduct_productHeader__1pcd6 {
  font-weight: 500;
  padding-left: 0px !important;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 2px !important;
}

.NewProduct_firstRow__2yqTc {
  margin-top: 16px !important;
}

.NewProduct_addButtonContainer__1cKjq {
  width: 18%;
  text-align: end;
}

.NewProduct_buttonContainer__2pi5e {
  width: 100%;
  text-align: end;
}

.NewProduct_calendarWrapper__1KNwz {
  position: fixed;
}

.NewProduct_saveButton__3XRRV {
  padding: 0px !important;
  color: #0e9354 !important;
  background: none !important;
}

.ui.button.NewProduct_disabledBtn__3gxVX {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

.ui.basic.disabled.buttonActive {
  border: 1px solid red !important;
}

.ui.button.NewProduct_cancelBtn__18aLE {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.NewProduct_editButton__1DUNm,
.NewProduct_deleteButton__1KzTW {
  cursor: pointer;
}

.ui.button.NewProduct_cancelDltBtn__2Qp8d {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.NewProduct_cancelDltBtn__2Qp8d:hover {
  background-color: #156941;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
}

.ui.button.NewProduct_deleteBtn__3cWq2 {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.NewProduct_deleteBtn__3cWq2:hover {
  background: #8c0d24;
  border-radius: 4px;
  color: white;
}

.NewProduct_totalRowLabel__2VlBo {
  width: 28.8% !important;
  flex-grow: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.NewProduct_totalRowUnitsNumber__2l3_h {
  width: 27.6% !important;
  flex-grow: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.NewProduct_totalRowTotalQty__3ci10 {
  width: 13.5% !important;
  flex-grow: 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.NewProduct_removeBatchNumber__32aTO {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.NewProduct_batchNumberSection__MyEWT {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.NewProduct_batchNumberHeader__362k0 {
  margin-bottom: 0 !important;
}

.NewProduct_batchNumberWrapper__X79WK {
  height: 40px !;
}

.NewProduct_rowFirstLabel__2HCzI {
  padding-left: 3px !important;
  padding-bottom: 0px !important;
  font-style: normal;
  font-weight: 400;
  width: 17% !important;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.NewProduct_lastLabel__Eshqz {
  width: 5% !important;
}

.NewProduct_rowLabel__22rH2 {
  padding-bottom: 0px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.NewProduct_firstValueColumn__3nCGa {
  width: 17% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.NewProduct_valueColumn__1-YGD {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.NewProduct_newProductsButtonCantainer__pLL8F {
  width: 5% !important;
  display: flex !important;
}

.NewProduct_deleteButton__1KzTW i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #b00020 !important;
}

.icon.NewProduct_deleteButton__1KzTW:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.NewProduct_deleteButton__1KzTW {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
  margin-bottom: 10px !important;
}

.NewProduct_editButtonWrapper__H84xP {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.NewProduct_editButton__1DUNm {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.NewProduct_editButton__1DUNm i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.NewProduct_editButton__1DUNm:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ui.labeled.input > .label {
  height: 40px !important;
}

.ui.input > .test {
  height: 40px !important;
}

.NewProduct_totalRowButtonWrapper__3RNUK {
  padding-top: 8px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

.NewProduct_totalRowWrapper__3gZQU {
  width: 82%;
  display: flex;
}

.NewProduct_deleteMessageWrapper__2c3Y8 {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  text-align: center !important;
  color: #212121 !important;
  flex: none !important;
  order: 0 !important;
  align-self: stretch !important;
  flex-grow: 0 !important;
}
.NewProduct_labelContaner__2jFDB {
  padding-bottom: 0px !important;
}
.NewProduct_deleteModal__19ZHC {
  margin-top: 13% !important;
}

.ui.button.PurchaseHistory_addButton__1998i {
  background-color: var(--color-green) !important;
}

.PurchaseHistory_wrapper__3FgdB {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.PurchaseHistory_attachmentContainer__35SQw {
  width: 70%;
  padding-right: 36px;
}

.PurchaseHistory_iconWrapper__1BZwr {
  display: flex;
}

.PurchaseHistory_editIconContainer__2yEbh {
  padding-right: 16px;
}

.PurchaseHistory_editIcon__xqC53 {
  color: #0e9354;
  cursor: pointer;
}
.PurchaseHistory_printIcon__x09N_ {
  color: #0e9354;
  cursor: pointer;
}

.PurchaseHistory_printButton__3J-qG i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--color-green) !important;
}

.icon.PurchaseHistory_printButton__3J-qG:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.PurchaseHistory_printButton__3J-qG {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.PurchaseHistory_deleteButton__1OxAK i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

.icon.PurchaseHistory_deleteButton__1OxAK:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.PurchaseHistory_deleteButton__1OxAK {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}

.PurchaseHistory_editButtonWrapper__3BcFd {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.PurchaseHistory_editButton__1MUJA {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.PurchaseHistory_editButton__1MUJA i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.PurchaseHistory_editButton__1MUJA:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.PurchaseHistory_valueWrapper__2KK0D {
  margin-top: 5px !important;
}

.ui.button.PurchaseHistory_deleteBtn__21qFZ {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.PurchaseHistory_deleteBtn__21qFZ:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.PurchaseHistory_cancelDltBtn__cB7Hk {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.PurchaseOrderModal_modalHeaderWrapper__1fReq {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-items: flex-end;
}

.PurchaseOrderModal_modalHeader__3F2uM {
  width: 98%;
  display: flex;
  justify-content: center;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #212121 !important;
}

.PurchaseOrderModal_closeButtonContainer__3r42g {
  width: 2%;
}

.PurchaseOrderModal_closeButton__7MJkW i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.PurchaseOrderModal_closeButton__7MJkW:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.PurchaseOrderModal_closeButton__7MJkW {
  padding: 6px;
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.PurchaseOrderModal_generalInformation__1HdbR {
  display: flex !important;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  margin-bottom: 16px !important;
  justify-content: space-between;
  border: 1px solid #dededf;
  height: 40px;
  border-radius: 4px;
  width: 100%;
}

.PurchaseOrderModal_orderDate__2s99D {
  padding-left: 16px;
  width: 25%;
}

.PurchaseOrderModal_deliveryDate__363yH {
  width: 25%;
}

.PurchaseOrderModal_supplier__1wpby {
  width: 25%;
}

.PurchaseOrderModal_totalPrice__3BwIt {
  width: 25%;
}

.PurchaseOrderModal_generalInformationLabe__2x1YE {
  display: flex !important;
  justify-content: space-between;
  padding: 0px;
  padding-top: 16px;
  padding-bottom: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.PurchaseOrderModal_orderDateLabe__1MZXC {
  width: 25%;
}

.PurchaseOrderModal_deliveryDateLabe__czUer {
  width: 25%;
}

.PurchaseOrderModal_supplierLabe__as_4N {
  width: 25%;
}

.PurchaseOrderModal_totalPriceLabe__1lTTB {
  width: 25%;
}

.PurchaseOrderModal_tableWrapper__1ZW6O {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.PurchaseOrderModal_root__d6v7h {
  width: 1000px !important;
}

.PrintPurchaseOrder_printTableWrapper__2Hir7 {
  margin-right: 20px !important;
}

.PrintPurchaseOrder_printTableRow__i_2lX {
  display: flex;
}

.PrintPurchaseOrder_printTableHeader__JvMDQ {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  margin-right: 12px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintPurchaseOrder_printTableLogo__1fEKp {
  height: 80px !important;
  margin-right: 12px !important;
  margin-top: 12px !important;
}

.PrintPurchaseOrder_totalRowWrapper__3o_yg {
  width: 95% !important;
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.PrintPurchaseOrder_totalRowHeaderWrapper__2b-8I {
  width: 100% !important;
  display: flex;
}

.PrintPurchaseOrder_totalRowValueWrapper__2paoE {
  width: 100% !important;
  display: flex;
}

.PrintPurchaseOrder_line__1P-ks {
  height: 1px;
  background-color: #dededf;
}

.PrintPurchaseOrder_totalRowHeaderContainer__3UP2W {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: flex-end !important;
  padding-bottom: 4px !important;
}

.PrintPurchaseOrder_totalRowValueContainer__27l9Z {
  width: 25% !important;
  padding-left: 16px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  padding-top: 4px !important;
}

.PrintPurchaseOrder_container__3t_YS {
  border-bottom: 1px solid black !important;
  margin: 20px !important;
}

.PrintPurchaseOrder_reportTable__13CvX {
  width: 100%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintPurchaseOrder_reportHeader__3JP_C {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 20%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintPurchaseOrder_reportBody__1SoHV {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 20%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintPurchaseOrder_secondreportTable__2NJPg {
  width: 100%;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.PrintPurchaseOrder_secondreportHeader__fUBVk {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 17%;
  height: 23px !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  vertical-align: bottom !important;
  padding-bottom: 4px !important;
  color: #212121 !important;
}

.PrintPurchaseOrder_secondreportBody__1ulkA {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 12.5%;
  min-height: 23px !important;
  height: auto !important;
  margin-right: 20px !important;
  border-bottom: 1px solid #dededf !important;
  border-left: 1px solid #dededf;
  vertical-align: top !important;
  padding-top: 4px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #212121 !important;
}

.PrintPurchaseOrder_secondreportBody__1ulkA:first-child {
  border-left: none !important;
}

.PrintPurchaseOrder_secondreportHeader__fUBVk:first-child {
  border-left: none !important;
}

.PrintPurchaseOrder_linkWrapper__3smRY {
  display: grid;
}

.ui.header.Sidebar_name__1dEER {
  margin: 0 130px 0 0;
}

.Sidebar_btnWrapper__1SEWK {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__3DEyX {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__14qrU {
  cursor: pointer !important;
}
.ui.button.ShowMore_buttonMore__VX6-6 {
  margin-left: 5px;
}

.ui.header.TrackerViewer_name__3EO29 {
  margin: 0 130px 0 0;
}

.TrackerViewer_holder__3rNy9 {
  height: 500px;
  width: 100%;
}

.TrackerViewerControlBar_barHolder__3vMLP {
  display: flex;
  margin: 0 -5px;
  align-items: center;
  flex-wrap: wrap;
}

.TrackerViewerControlBar_barHolder__3vMLP > div {
  flex: 0 0 auto;
  padding: 5px;
}

.TrackerViewerControlBar_barHolderIn__3bkBq {
  display: flex;
  align-items: center;
}

.TrackerViewerControlBar_barHolderIn__3bkBq > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.TrackerViewerControlBar_dateInput__A6neC {
  width: 120px;
}

.ui.button.PackedBoxes_addButton__1mRxv {
  background-color: var(--color-green) !important;
}

.ui.basic.green.button.PackedBoxes_cancelButton__1Q975 {
  color: var(--color-green) !important;
}

.ui.basic.green.button.PackedBoxes_actionButton__34PPZ {
  width: 103px !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-items: baseline;
  color: #0e9354 !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding: 7.58px 16px 7.58px 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
}

.PackedBoxes_archiveIcon__14l3X {
  color: #0e9354 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.PackedBoxes_modalMessageHeader__3sgDo {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}

.ui.modal.PackedBoxes_messageModal__yjz5X {
  width: 400px !important;
  overflow: auto;
}

.ui.modal.PackedBoxes_messageModal__yjz5X .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

.ui.modal.PackedBoxes_messageModal__yjz5X .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.PackedBoxes_holder__3NapJ .ui.button.PackedBoxes_actionButton__34PPZ {
  font-weight: 600;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  color: var(--color-green) !important;
  width: 130px;
}

.PackedBoxes_container__10pAp {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 230px !important;
  grid-gap: 10px;
  gap: 10px;
}

.PackedBoxes_wrapper__30NwV {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.PackedBoxes_iconWrapper__EiEhW {
  display: flex;
}

.PackedBoxes_editButtonWrapper__vGQti {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.PackedBoxes_editButton__17ASS {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.PackedBoxes_editButton__17ASS i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.PackedBoxes_editButton__17ASS:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.PackedBoxes_deleteButton__3moaN i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

.icon.PackedBoxes_deleteButton__3moaN:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.PackedBoxes_deleteButton__3moaN {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}
.ReactTable.LocalDataTable_listTable__YnvJj {
  overflow: auto;
  border: none;
  min-height: 300px;
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-table,
.ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody {
  overflow: visible;
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-thead.-header {
  box-shadow: none;
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-thead.-headerGroups + .rt-thead.-header {
  font-weight: bold;
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-tr-group {
  border-bottom: 1px solid var(--color-border);
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-tr {
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ReactTable.-striped.LocalDataTable_listTable__YnvJj .rt-tbody .rt-tr.-odd {
  background: var(--color-row-stripped);
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td {
  overflow: visible;
  padding: 13px 15px;
  /*white-space: normal;*/
  border: none;
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-thead .rt-tr {
  border-bottom: 1px solid var(--color-border);
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-thead .rt-th {
  border: none;
  text-align: left;
  padding: 15px;
  white-space: normal;
  color: var(--color-dark);
  font-size: 16px;
}

.ReactTable.LocalDataTable_listTable__YnvJj .rt-thead .rt-th.-sort-asc,
.ReactTable.LocalDataTable_listTable__YnvJj .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
}

.ReactTable.LocalDataTable_listTable__YnvJj .cellTitle {
  text-transform: uppercase;
  color: var(--color-header);
  font-size: 12px;
}

.ReactTable.LocalDataTable_listTable__YnvJj .textRight {
  text-align: right;
}

.ReactTable.LocalDataTable_listTable__YnvJj .actionBlock button {
  font-size: 18px;
  color: var(--color-icon);
}
.ReactTable.LocalDataTable_listTable__YnvJj .actionBlock button .pencil {
  color: var(--color-green);
}

.ReactTable.LocalDataTable_listTable__YnvJj .actionBlock > div:not(:last-child),
.ReactTable.LocalDataTable_listTable__YnvJj .actionBlock > button:not(:last-child) {
  margin-right: 15px;
}

.ReactTable.LocalDataTable_listTable__YnvJj .cellValue {
  overflow: hidden;
  text-overflow: ellipsis;
}

.LocalDataTable_barHolderIn__NJ1f1 {
  display: flex;
  align-items: center;
}

.LocalDataTable_barHolderIn__NJ1f1 > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

@media screen and (max-width: 767px) {
  .ui.grid > .row > .column.LocalDataTable_sortColumn__2GUNc {
    padding-left: 0;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-thead,
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody {
    min-width: auto !important;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-tr {
    position: relative;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-thead.-headerGroups {
    display: none;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-thead.-header {
    display: none;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-thead.-headerGroups + .rt-thead.-header {
    display: flex;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_auto {
    width: auto !important;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_1 {
    width: 6.25% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_2 {
    width: 12.5% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_3 {
    width: 18.75% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_4 {
    width: 25% !important;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_5 {
    width: 31.25% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_6 {
    width: 37.5% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_7 {
    width: 43.75% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_8 {
    width: 50% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_9 {
    width: 56.25% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_10 {
    width: 62.5% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_11 {
    width: 68.75% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_12 {
    width: 75% !important;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_13 {
    width: 81.25% !important;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_14 {
    width: 87.5% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_15 {
    width: 93.75% !important;
  }

  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-td.cell_16 {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-thead {
    display: flex;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-tr {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .ReactTable.LocalDataTable_listTable__YnvJj .rt-tbody .rt-tr:hover {
    background: var(--color-row-hover) !important;
  }
}

.table-container {
  padding: 1em;
}

.table-container .ui.table {
  border-collapse: collapse;
  width: 100%;
}

.table-container .ui.table thead tr {
  background-color: #f9fafb;
}

.table-container .ui.table thead th {
  border-bottom: 2px solid #ddd;
  padding: 10px;
  text-align: left;
  font-weight: normal;
}

.table-container .ui.table tbody tr {
  border-bottom: 1px solid #ddd;
}

.table-container .ui.table tbody td {
  padding: 10px;
}

.table-container .ui.table tbody tr:nth-child(odd) {
  background: rgb(39 39 39 / 3%);
}

.total-row {
  background-color: #ffffff !important;
  font-weight: bold;
}

.total-row th {
  background-color: #ffffff !important;
  padding: 10px;
}
.table-container {
  padding: 1em;
  width: auto !important;
  overflow-x: auto !important;
}

.table-container .ui.table {
  border-collapse: collapse;
  width: 100%;
}

.table-container .ui.table thead tr {
  background-color: #f9fafb;
}

.table-container .ui.table thead th {
  border-bottom: 2px solid #ddd;
  padding: 10px;
  text-align: left;
  font-weight: normal;
}

.table-container .ui.table tbody tr {
  border-bottom: 1px solid #ddd;
}

.table-container .ui.table tbody td {
  padding: 10px;
}

.table-container .ui.table tbody tr:nth-child(odd) {
  background: rgb(39 39 39 / 3%);
}

.total-row {
  background-color: #ffffff !important;
  font-weight: bold;
}

.total-row th {
  background-color: #ffffff !important;
  padding: 10px;
}
.AddNewMachinery_buttonsFooter__9OCm6 .ui.button {
  margin: 0;
}

.ui.button.Employees_addButton__32Wat {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.Employees_actionCells__2YZRw {
  overflow: initial !important;
}

.ui.button.Employees_addButton__32Wat:hover {
  background-color: #156941 !important;
}

.ui.basic.green.button.Employees_cancelButton__1pRY0 {
  color: var(--color-green) !important;
  margin-right: 10px;
}

.ui.basic.green.button.Employees_actionButton__VMqgU {
  height: 32px;
  display: flex;
  margin-right: 8px;
  font-weight: 500 !important;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.ui.basic.green.button.Employees_actionButton__VMqgU:hover {
  color: #ffffff !important;
  background-color: #156941 !important;
}

.ui.basic.green.button.Employees_actionButton__VMqgU:hover i > svg > path {
  fill: #ffffff !important;
}

.ui.basic.red.button.Employees_actionButton__VMqgU {
  height: 32px;
  display: flex;
  align-items: baseline;
  font-weight: 500 !important;
  color: var(--color-error-delete) !important;
  box-shadow: 0 0 0 1px var(--color-error-delete) inset !important;
}

.ui.basic.red.button.Employees_actionButton__VMqgU:hover {
  background-color: #8c0d24 !important;
  color: #ffffff !important;
}

.Employees_buttonContainer__359K5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Employees_scrollableTableWidth__13P71.ui.table {
  width: 120%;
}

.ContractorActionButtons_modal__1x1fy {
  width: 420px !important;
}

.ui.button.ContractorActionButtons_archiveButton__3PrvV {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ui.button.ContractorActionButtons_archiveButton__3PrvV:hover {
  background-color: #156941 !important;
}

.ContractorActionButtons_modalHeaderText__33dWk {
  text-align: center;
  font-family: Roboto, sans-serif;
  white-space: break-spaces !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
}

.ContractorActionButtons_listContainer__3jMjW {
  width: 100%;
  overflow-y: scroll;
  max-height: 150px;
}

.ContractorActionButtons_listItem__2QHbf {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.ContractorActionButtons_deleteButton__3iVTD {
  background-color: var(--color-error-delete) !important;
  opacity: 1 !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ContractorActionButtons_deleteButton__3iVTD:hover {
  background-color: #8c0d24 !important;
}

.ContractorActionButtons_actionButtonContainer__1WmKN {
  width: 100% !important;
  display: flex !important;
  justify-content: end;
  margin-top: 30px;
}

.ui.button.Contractors_addButton__1rpu8 {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ui.button.Contractors_addButton__1rpu8:hover {
  background-color: #156941 !important;
}

.Contractors_addButton__1rpu8 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ui.basic.green.button.Contractors_cancelButton__27D2D {
  color: var(--color-green) !important;
  margin-right: 10px;
}

.ui.basic.green.button.Contractors_cancelButton__27D2D:hover {
  background-color: #156941 !important;
  color: #ffffff !important;
  border: none;
}

.Contractors_cancelButton__27D2D {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ui.basic.green.button.Contractors_actionButton__1gQyF {
  height: 32px;
  display: flex;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.Contractors_avatarsContainer__3WNWW {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Contractors_avatarsContainer__3WNWW span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.Contractors_avatarHolder__bQZF3 {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.Contractors_avatarsContainer__3WNWW .Contractors_avatarHolder__bQZF3:nth-child(n + 2) {
  margin-left: -10px;
}

.Contractors_morePopupContent__3AxWq {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.Contractors_popupItem__8dx3x {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.Contractors_popupItem__8dx3x .Contractors_avatarHolder__bQZF3 {
  height: 20px;
  min-width: 20px;
}

.Contractors_popupItem__8dx3x:nth-child(1) {
  margin-top: 0;
}

.Contractors_popupItem__8dx3x span {
  margin-left: 10px;
}

.Contractors_actionCells__17WPc {
  overflow: initial !important;
}

.Contractors_modal__24smH {
  width: 420px !important;
}

.Contractors_modalHeaderText__3RKtG {
  text-align: center;
  font-family: Roboto, sans-serif;
  white-space: break-spaces !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
}

.Sidebar_blockContainer__v_14C {
  margin-top: 30px;
}

.Sidebar_sectionTable__1yjfv.ui.fixed.table td {
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.Sidebar_blockContainer__v_14C:first-child {
  margin-top: 10px;
}

.Sidebar_blockContainer__v_14C:nth-child(4) {
  margin-bottom: 15px;
}

.Sidebar_infoContainer__1hvPc {
  padding: 0em 1em !important;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox 64 */
  height: auto;
}

.Sidebar_infoContainer__1hvPc::-webkit-scrollbar {
  display: none;
}

.icon.Sidebar_downloadButton__1Ml5x {
  color: var(--color-green) !important;
}

.Sidebar_infoContainer__1hvPc.ui.grid {
  margin-bottom: 0;
}

.Sidebar_avatarHolder__3tbUL {
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.Sidebar_sidebarHeader__1yJ1d {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 10px;
}

.Sidebar_sidebarHeader__1yJ1d.ui.grid > .row {
  padding-bottom: 10px;
}

.ui.header.Sidebar_name__2gjyu {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 30px 0 0;
  overflow: hidden;
  max-width: 380px;
  text-overflow: ellipsis;
}

@media screen and (max-width: 414px) {
  .ui.header.Sidebar_name__2gjyu {
    margin-right: 0;
  }
}

.Sidebar_blockName__19m63 {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  margin: 10px 0;
}

.Sidebar_root__3edlV {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.Sidebar_body__A3SMk {
  flex: 0 1 100%;
  overflow: auto;
}

.ui.grid.Sidebar_itemGroupGrid__Bliyc {
  margin: 0;
  background: #f5efef;
  border: 1px solid #e3e1e2;
}

.ui.grid.Sidebar_itemGroupGrid__Bliyc + .Sidebar_itemGroupGrid__Bliyc {
  margin-top: 15px;
}

.ui.table.Sidebar_cropTable__RNLrq {
  background: none;
  border: none;
}

.ui.table.Sidebar_cropTable__RNLrq tbody tr td {
  border-color: var(--color-border-sui-light);
}

.ui.table.Sidebar_cropTable__RNLrq thead tr th,
.ui.table.Sidebar_cropTable__RNLrq tbody tr td {
  padding: 10px;
  background: none;
}

.ui.table.Sidebar_cropTable__RNLrq thead tr th {
  font-weight: normal;
  color: var(--color-header);
}

.ui.table.Sidebar_cropTable__RNLrq tbody tr:nth-child(2n + 1) {
  background: #fff;
}

.Sidebar_headerColumn__3pPpk {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  white-space: nowrap;
}

.Sidebar_headerColumn__3pPpk .Sidebar_headerContainer__3o77T {
  margin-left: 15px;
}

.icon.Sidebar_avatarHolder__3tbUL {
  margin: 0 !important;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__1hoeo {
  display: flex;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__1hoeo .item {
  flex: 1 1;
  display: block;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__1hoeo .item.active {
  border-color: var(--color-green);
  color: var(--color-green);
}

.icon.Sidebar_editButton__w4uB9 {
  color: var(--color-green) !important;
}

.Sidebar_editButtonWrapper__381MW {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Sidebar_editButton__w4uB9 {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}
.Sidebar_sectionEditButton__8FbOk {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Sidebar_editButton__w4uB9 i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}
.Sidebar_sectionEditButton__8FbOk i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
}

.Sidebar_closeButton__ixJHB i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.Sidebar_editButton__w4uB9:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.icon.Sidebar_sectionEditButton__8FbOk:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.icon.Sidebar_closeButton__ixJHB:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__ixJHB {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.icon.Sidebar_deleteButton__tkwlt {
  color: var(--color-error-delete) !important;
}

.Sidebar_attachmentCell__rp6gi {
  height: auto !important;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
}

.ui.label.Sidebar_attachmentLabel__2fbzf {
  height: 24px;
  margin-bottom: 8px;
  line-height: 13px;
  width: 155px;
  border-radius: 20px;
  padding: 4px 12px 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sidebar_attachmentLabel__2fbzf .Sidebar_labelFileName__3ZOxi {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Sidebar_infoContainer__1hvPc.ui.grid > * {
  padding-right: 0em;
  padding-left: 0em;
}

.Sidebar_sidebarFooter__1miud {
  display: flex;
  justify-content: end;
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid #dededf;
  background: #fff;
}

.ui.basic.green.button.Sidebar_actionButton__yFEW3 {
  height: 32px;
  display: flex;
  margin-right: 8px;
  font-weight: 500 !important;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.ui.basic.green.button.Sidebar_actionButton__yFEW3:hover {
  color: #ffffff !important;
  background-color: #156941 !important;
}

.ui.basic.green.button.Sidebar_actionButton__yFEW3:hover i > svg > path {
  fill: #ffffff !important;
}

.ui.basic.red.button.Sidebar_actionButton__yFEW3 {
  height: 32px;
  display: flex;
  align-items: baseline;
  font-weight: 500 !important;
  color: var(--color-error-delete) !important;
  box-shadow: 0 0 0 1px var(--color-error-delete) inset !important;
}

.ui.basic.red.button.Sidebar_actionButton__yFEW3:hover {
  background-color: #8c0d24 !important;
  color: #ffffff !important;
}
.Sidebar_attachmentContainer__3qf5t {
  width: 50%;
  padding-right: 10px;
  padding-bottom: 10px;
}

.AddUpdateEmployeeModal_root__agqUx :not(i.icon),
.AddUpdateEmployeeModal_root__agqUx .ui.header {
  font-family: Roboto, sans-serif !important;
}

.AddUpdateEmployeeModal_modalHeader__29HyN {
  font-family: Roboto, sans-serif !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0;
  text-align: left;
}

.AddUpdateEmployeeModal_pictureDescText__x5zZK {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
}

.AddUpdateEmployeeModal_dividerLine__1ibDN {
  color: #dededf;
  height: 8px;
  width: 1px;
}

.AddUpdateEmployeeModal_collapseBlock__23hv9 .AddUpdateEmployeeModal_titleContainer__nnpld .AddUpdateEmployeeModal_title__3aHi_.ui.header {
  color: red !important;
}

.AddUpdateEmployeeModal_root__agqUx .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.AddUpdateEmployeeModal_root__agqUx .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.AddUpdateEmployeeModal_root__agqUx .ui.grid {
  margin: 0;
}

.AddUpdateEmployeeModal_minRateInput__1YwJz {
  position: relative;
}

.AddUpdateEmployeeModal_minRateInput__1YwJz:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.AddUpdateEmployeeModal_removeActiveIngredient__33uPk {
  color: #b00020;
  margin-right: 0 !important;
}

.AddUpdateEmployeeModal_textButton__1mrMl.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.AddUpdateEmployeeModal_cancelButton__17Dfc.ui.green.button {
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.AddUpdateEmployeeModal_cancelButton__17Dfc.ui.basic.green.button {
  background-color: #ffffff !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.AddUpdateEmployeeModal_cancelButton__17Dfc.ui.basic.green.button:hover {
  color: #ffffff !important;
  border: none;
}

.AddUpdateEmployeeModal_cancelButton__17Dfc.ui.green.button {
  background-color: var(--color-green) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.AddUpdateEmployeeModal_cancelButton__17Dfc.ui.green.button:hover {
  background-color: #156941 !important;
}

.AddUpdateEmployeeModal_cancelButton__17Dfc.ui.button:disabled {
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.AddUpdateEmployeeModal_customFieldLabel__3fV13 {
}
.AddUpdateEmployeeModal_textButtonContainer__3zYFA {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.AddUpdateEmployeeModal_ingredientHeader__3Y3cM {
  margin-bottom: 0 !important;
}

.AddUpdateEmployeeModal_ingredientSection__ufapP {
  display: flex;
  justify-content: space-between;
}

.AddUpdateEmployeeModal_activeIngredientMessage__2F4ZN .ui.header {
  margin: 0 !important;
}

.AddUpdateEmployeeModal_attachmentLabel__26-ey {
  margin-right: auto !important;
}

.AddUpdateEmployeeModal_fileChooserContainer__1j0ti {
  height: auto;
  display: flex !important;
  align-items: center !important;
}

.AddUpdateEmployeeModal_generalAttachmentsContainer__14viH {
  margin-top: 2px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  margin-bottom: 10px;
}

.AddUpdateEmployeeModal_attachmentItemsContainer__3Q3YI {
  display: flex !important;
  flex-wrap: wrap;
  align-items: flex-end !important;
  max-width: 70%;
  height: auto;
}

.AddUpdateEmployeeModal_attachmentItemsContainer__3Q3YI div {
  margin-right: 5px !important;
}

.AddUpdateEmployeeModal_generalAttachmentsButton___tJZ7 {
  margin-left: auto !important;
}

.AddUpdateEmployeeModal_root__agqUx .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.AddUpdateEmployeeModal_root__agqUx .ui.form .disabled.field label {
  color: black;
  opacity: 0.7;
}

.AddUpdateEmployeeModal_root__agqUx .AddUpdateEmployeeModal_chemicalPropertyColumn__3rJWL {
  padding-left: 0 !important;
}

.AddUpdateEmployeeModal_root__agqUx .AddUpdateEmployeeModal_chemicalPropertyColumn__3rJWL .field {
  margin-bottom: 16px !important;
}

.AddUpdateEmployeeModal_actionText__3zMvi {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #0e9354;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.AddUpdateEmployeeModal_actionText__3zMvi:hover {
  text-decoration: underline;
}

.AddUpdateEmployeeModal_actionText__3zMvi:disabled {
  color: #b3b3b3;
}

.AddUpdateEmployeeModal_tabsHolder__3Ez4- {
  margin-top: 10px;
}

.AddUpdateEmployeeModal_tabsHolder__3Ez4- a {
  width: 160px !important;
  justify-content: center;
}

.AddUpdateEmployeeModal_tabs__3-DSj {
  margin: 0 -15px;
}

.AddUpdateEmployeeModal_tabs__3-DSj > .ui.secondary.menu {
  background: #fff;
  margin-bottom: 0;
}

.AddUpdateEmployeeModal_tabs__3-DSj > .ui.segment {
  margin-top: 0;
}

.AddUpdateEmployeeModal_progressBar__2v1Qf {
  color: var(--color-background-green) !important;
}
.AddUpdateEmployeeModal_progressBar__2v1Qf.ui.progress > .label {
  color: #59b655 !important;
}

.ui.secondary.pointing.menu.AddUpdateEmployeeModal_tabsMenu__XKnu8 {
  background: #fff;
}

.ui.secondary.pointing.menu.AddUpdateEmployeeModal_tabsMenu__XKnu8 .active.item {
  color: var(--color-background-green);
  border-color: var(--color-background-green);
}

.AddUpdateEmployeeModal_tabItem__3yXFd {
  overflow: hidden;
  height: 0;
}

.AddUpdateEmployeeModal_tabItem__3yXFd.AddUpdateEmployeeModal_visibleTab__1L5yy {
  height: auto;
  padding-bottom: 15px;
}

.AddUpdateEmployeeModal_cancelButton__17Dfc {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.AddUpdateEmployeeModal_labelText__3MSPj {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.AddUpdateEmployeeModal_labelText__3MSPj::after {
  margin: -0.2em 0em 0em 0.2em;
  content: "*";
  color: #db2828;
}

.AddUpdateEmployeeModal_modalContentContainer__13d42 {
  min-height: 70vh;
  scrollbar-width: none;
}

.AddUpdateEmployeeModal_modalContentContainer__13d42::-webkit-scrollbar {
  display: none;
}

.AddUpdateEmployeeModal_infoIconContainer__JxMNQ {
  position: relative;
  height: 10px;
  color: #b3b3b3;
}

.AddUpdateEmployeeModal_infoIconContainer__JxMNQ i {
  position: absolute;
  right: 400px;
  top: 0;
}

@-moz-document url-prefix() {
  .AddUpdateEmployeeModal_infoIconContainer__JxMNQ i {
    position: absolute;
    right: 400px;
    top: 14px;
  }
}

.AddUpdateEmployeeModal_dataPickerField__NGTqD .ui.large.input {
  height: 38px !important;
}
.AddUpdateEmployeeModal_removeContractor__1Ws9b {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.AddUpdateEmployeeModal_contractorsSection__2GZW0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.AddUpdateEmployeeModal_contractorHeader__37JY0 {
  margin-bottom: 0 !important;
}

.AddUpdateEmployeeModal_contractorWrapper__kAvId {
  height: 40px !important;
}

.ImageInput_avatarWrapper__KENKv {
  position: relative;
  display: inline-flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  min-width: 40px;
  justify-content: center;
}

.ImageInput_avatarHolder__2rEE9 {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--color-border);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  min-width: 40px;
  justify-content: center;
}

.ImageInput_avatarHolder__2rEE9 span {
  color: white;
  font-family: Roboto, sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.ImageInput_avatarHolder__2rEE9 .icon {
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  font-size: 4em;
  margin: 0;
}

.ShowRateHistory_textButtonContainer__mJdBL {
  margin: 16px 0;
  display: flex !important;
  justify-content: end;
}

.ShowRateHistory_textButton__2wWGR.ui.button,
.ShowRateHistory_textButton__2wWGR.ui.button:focus {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none !important;
  margin: 0 !important;
}

.ShowRateHistory_textButton__2wWGR.ui.button:hover {
  color: var(--color-background-green-medium) !important;
  box-shadow: none;
}

.ActionMessage_deletedMessage__3ouQI .ActionMessage_undoButton__1NDU0 {
  color: var(--color-green);
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.ActionMessage_deletedMessage__3ouQI {
  position: fixed;
  left: 0;
  top: 80px;
  padding: 0 1rem;
  width: 100%;
  box-shadow: none;
}

.ActionMessage_deletedMessageIn__2WRst {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border: transparent;
  box-shadow: none;
}

.ActionMessage_deletedMessage__3ouQI .ActionMessage_closeButton__ZsZTv {
  color: #666666;
}

.ActionMessage_deletedMessage__3ouQI .ActionMessage_closeButton__ZsZTv:hover {
  cursor: pointer;
  color: #000000;
}

.Setting_header__1uPXG {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
}

.Setting_systemInformationHeader__x1DFz {
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}

.Setting_informationWrapper__1s4D5 {
  margin-top: 24px;
}

.Setting_informationFieldContainer__3cBN_ {
  display: flex;
  padding-bottom: 16px;
}

.Setting_informationWrapperHeader__3gGOR {
  width: 30%;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.Setting_informationWrapperValue__2LZPH {
  width: 70%;
}

.Setting_editButtonWrapper__31xv5 {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Setting_editButton__3nx2S {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Setting_editButton__3nx2S i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Setting_editButton__3nx2S:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Setting_companyInformation__1tSND {
  margin-top: 24px;
}

.Setting_businessInformationWrapper__Szvhq {
  display: flex;
  padding-bottom: 16px;
}

.Setting_businessInformationHeader__30pdv {
  width: 30% !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.Setting_businessInformationValue__yG_M0 {
  width: 70%;
}

.Setting_companyLogo__1LZ7s {
  width: 70px !important;
  height: 70px !important;
}

.Setting_companyDisabledLogo__EUvWG {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50px;
  background-color: #ebf6e9 !important;
}

.Setting_saveButton__-Ahkx {
  background-color: #0e9354 !important;
  color: white !important;
}

.Setting_cancelBtn__DyewU {
  margin-right: 16px !important;
  background: none !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
}

.Setting_nameField__2JqHd {
  padding: 0px !important;
}

.Setting_abnField__2R9BZ {
  padding: 0px !important;
}

.Setting_label__3DwBR {
  padding-left: 8px;
}

.Setting_logoField__3PBwG {
  display: flex;
}

.Setting_fileChooserWrapper__13CNG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 24px;
}

.Setting_generalAttachmentsContainer__3GrXe {
  display: flex !important;
  margin-left: 0px !important;
}

.Setting_generalAttachmentsButton__1dnUu {
  text-align: inherit !important;
  width: 135px !important;
  margin-right: 14px !important;
}

.Setting_buttonWrapper__35-f5 {
  display: flex !important;
  align-items: center;
}

.Setting_removeBtn__22Toy {
  padding: 0 !important;
  text-align: inherit !important;
  font-size: 14px !important;
  background: none !important;
  width: 100px !important;
  color: #0e9354 !important;
}

.Setting_dot__2RvcW {
  height: 5px !important;
  width: 5px !important;
  background-color: #bbb !important;
  border-radius: 50% !important;
  display: inline-block !important;
  margin-right: 14px !important;
}

.Setting_seasonInfo__3_2UC {
  margin-bottom: 24px;
}

.Setting_rowContainer__1o_oo {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.Setting_seasonsSegment__183cK {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.Setting_newSeasonsRow__w-YyN {
  display: flex !important;
  padding: 0 !important;
  align-items: center !important;
  height: 40px !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.Setting_addButtonContainer__1zems {
  padding-top: 16px;
  text-align: end;
}

.Setting_buttonContainer__2b6F7 {
  width: 100%;
  text-align: end;
}

.Setting_saveBtn__1Unxe {
  padding: 0px !important;
  color: #0e9354 !important;
  background: none !important;
}

.ui.button.Setting_cancelButton__OFT-y {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.Setting_editButton__3nx2S,
.Setting_deleteButton__2MWDh {
  cursor: pointer;
}

.ui.button.Setting_cancelDltBtn__3Y7_F {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.Setting_cancelDltBtn__3Y7_F:hover {
  background-color: #156941;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
}

.ui.button.Setting_deleteBtn__3xJhH {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.Setting_deleteBtn__3xJhH:hover {
  background: #8c0d24;
  border-radius: 4px;
  color: white;
}

.Setting_valueColumn__1DE5h {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  padding-bottom: 5px !important;
}

.Setting_newSeasonssButtonCantainer__T6VNr {
  width: 5% !important;
  display: flex !important;
  height: 40px;
}

.Setting_deleteButton__2MWDh i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #b00020 !important;
}

.icon.Setting_deleteButton__2MWDh:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Setting_deleteButton__2MWDh {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
  margin-bottom: 10px !important;
}

.Setting_editButtonWrapper__31xv5 {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.Setting_editButton__3nx2S {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Setting_editButton__3nx2S i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.Setting_editButton__3nx2S:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ui.labeled.input > .label {
  height: 40px !important;
}

.Setting_addButtonWrapper__3Q_9Z {
  padding-top: 8px !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.Setting_deleteMessageWrapper__1JAgM {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  text-align: center !important;
  color: #212121 !important;
  flex: none !important;
  order: 0 !important;
  align-self: stretch !important;
  flex-grow: 0 !important;
}

.Setting_deleteModal__2UhYs {
  margin-top: 13% !important;
}

.Setting_updateBtnContainer__VOp0e {
  display: flex;
  justify-content: flex-end;
}

.Setting_updateBtn__1U4jA {
  background-color: #0e9354 !important;
  color: white !important;
  font-weight: 500;
  line-height: 21px;
}

.Setting_updateInfoWrapper__1ThaA {
  margin-top: 0px !important;
}

.TaskCustomization_header__3fH3E {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
  margin-bottom: 24px;
}

.TaskCustomization_taskTypesHolder__1tvIZ {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  background-color: transparent;
}

.TaskCustomization_taskCard__188RW {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);
  background-color: #ffffff;
  margin: 0 24px 24px 0;
  padding: 28px 33px;
  text-align: center;
  border-radius: 8px;
}

.TaskCustomization_taskCard__188RW:hover {
  cursor: pointer;
  background-color: #c9cfd072;
}

.TaskCustomization_taskCard__188RW img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
}

.TaskCustomization_taskCard__188RW .TaskCustomization_caption__h0z9x {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: var(--color-text);

  width: 120px;
}

.TaskCustomization_checkboxWrapper__-OuXc {
  margin-top: 16px;
  height: 20px;
}

.StationGraph_chartHolder__qf-_M {
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
}

.StationGraph_chartHolder__qf-_M + .StationGraph_chartHolder__qf-_M {
  margin-top: 2rem;
}

.StationGraph_labelAxisYLeft__w9Xzw {
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left center;
  transform-box: fill-box;
}

.StationGraph_labelAxisYRight__bnPUX {
  transform: rotate(-90deg) translateX(50%) translateY(-10px);
  transform-origin: right center;
  transform-box: fill-box;
}

.StationGraph_chart__3rSAI {
  margin-top: 1rem;
}

.StationsControlBar_barHolder__2-haK {
  display: flex;
  margin: 0 -5px;
  align-items: center;
  flex-wrap: wrap;
}

.StationsControlBar_barHolder__2-haK > div {
  flex: 0 0 auto;
  padding: 5px;
}

.StationsControlBar_barHolder__2-haK .ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin: 0;
}

.StationsControlBar_barHolder__2-haK .ui.button:not(.icon) > .icon:not(.button):not(.dropdown) + span {
  margin-left: 10px;
}

.ui.button.StationsControlBar_buttonActive__R4BOV {
  background: var(--color-dark);
  color: #fff;
}

.ui.button.StationsControlBar_buttonActive__R4BOV .icon {
  opacity: 1;
}

.StationsControlBar_barHolderIn__3fD_1 {
  display: flex;
  align-items: center;
}

.StationsControlBar_barHolderIn__3fD_1 > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.StationsControlBar_dateInput__29Dsi {
  width: 120px;
}

.ui.header.Sidebar_name__2Sxte {
  margin: 0 130px 15px 0;
}

@media (max-width: 767px) {
  .Sidebar_lastComm__3P0Vk {
    display: block;
  }

  .Sidebar_lastComm__3P0Vk span {
    display: none;
  }
}

.Sidebar_btnWrapper__LbHp7 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__1zYNw {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__1fWnV {
  cursor: pointer !important;
}
.ReactTable.StationDataTable_dataTable__2pcQV {
  overflow: auto;
  border: none;
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-thead.-header {
  box-shadow: none;
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-tbody .rt-tr-group {
  border-bottom: 1px solid var(--color-border);
}

.ReactTable.-striped.StationDataTable_dataTable__2pcQV .rt-tbody .rt-tr.-odd {
  background: var(--color-row-stripped);
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-tbody .rt-td {
  overflow: visible;
  padding: 13px 15px;
  white-space: normal;
  border: none;
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-thead .rt-tr {
  border-bottom: 1px solid var(--color-border);
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-thead .rt-th {
  border: none;
  text-align: left;
  padding: 10px 15px;
  color: var(--color-dark);
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-thead .rt-th:first-child,
.ReactTable.StationDataTable_dataTable__2pcQV .rt-tbody .rt-td:first-child {
  border-right: 1px solid var(--color-border);
}

.ReactTable.StationDataTable_dataTable__2pcQV .rt-tbody .rt-tr:hover .rt-td {
  background: var(--color-row-hover) !important;
}

.StationDataTable_tableHolder__3K9H8 {
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
}

.WeatherStationForm_sprayGrid__2l4Hn {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.WeatherStationForm_sprayGrid__2l4Hn.WeatherStationForm_forModal__3KjmV > div {
  padding: 0;
}

.WeatherStationForm_sprayGrid__2l4Hn.WeatherStationForm_forModal__3KjmV > div + div {
  margin-top: 15px;
}
.WeatherStationForm_sprayGrid__2l4Hn.WeatherStationForm_forModal__3KjmV .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.WeatherStationForm_sprayGrid__2l4Hn:not(.WeatherStationForm_forModal__3KjmV) .ui.segment.WeatherStationForm_sprayTasksSidebarIn___QLkg {
  flex: auto;
}

.WeatherStationForm_sprayFormFooter__lcMb4 {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.WeatherStationForm_sprayFormContainer__-vbGV {
  overflow: auto;
  padding: 30px 15px;
}

.WeatherStationForm_sprayFormContainer__-vbGV > div {
  max-width: 900px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
}

.AddWeatherStation_buttonsFooter__jdo7_ .ui.button {
  margin: 0;
}

.ListTable_tableHolder__1Oozw {
}

.ListTable_tableTools__1PGjf {
}

.ListTable_tableTools__1PGjf .ui.segment.basic {
  padding-left: 0;
  padding-right: 0;
}

.ListTable_verticalMiddle__J9ox3 {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.ListTable_verticalMiddle__J9ox3.center {
  justify-content: center;
}

.ListTable_verticalMiddle__J9ox3.right {
  justify-content: flex-end;
}

.ui.selection.dropdown.ListTable_selectShort__3U-D7 {
  margin-left: 10px;
  width: 60px;
  min-width: 60px;
}

.ListTable_totalItems__poTpS {
  display: inline-block;
  margin-right: 15px;
}

.ReactTable.ListTable_listTable__1NU-o {
  border: none;
}

.ReactTable.ListTable_listTable__1NU-o .rt-table,
.ReactTable.ListTable_listTable__1NU-o .rt-tbody {
  overflow: visible;
}

.ReactTable.ListTable_listTable__1NU-o .rt-thead.-header {
  box-shadow: none;
}

.ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-tr-group {
  border-width: 1px;
  border-style: solid;
  border-block-color: var(--color-border);
  border-left-color: transparent;
  border-right-color: transparent;
}

.ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-tr-group:nth-child(1) {
  border-top: 1px solid var(--color-border);
}

.ReactTable.ListTable_listTable__1NU-o
  .rt-tbody .rt-tr-group.ListTable_selectedRow__1le3l:nth-child(1) {
  border-top: 1px solid var(--color-border);
}

.ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-tr {
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ReactTable.-striped.ListTable_listTable__1NU-o .rt-tbody .rt-tr.-odd {
  background: var(--color-row-stripped);
}

.ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td {
  overflow: visible;
  padding: 13px 15px;
  white-space: normal;
  border: none;
}
.ReactTable.ListTable_listTable__1NU-o .rt-thead .rt-tr {
  border-bottom: 1px solid var(--color-border);
}
.ListTable_selectedRow__1le3l,
.ListTable_selectedRow__1le3l .rt-tr {
  background: #ebf7e9 !important;
  border-color: #59b655 !important;
}

.ReactTable.ListTable_listTable__1NU-o .rt-thead .rt-th {
  border: none;
  text-align: left;
  padding: 15px;
  white-space: normal;
  color: var(--color-dark);
  font-weight: bold;
}

.ReactTable.ListTable_listTable__1NU-o .cellTitle {
  text-transform: uppercase;
  color: var(--color-header);
  font-size: 12px;
}

.ReactTable.ListTable_listTable__1NU-o .textRight {
  text-align: right;
}

.ReactTable.ListTable_listTable__1NU-o .rt-thead {
  display: none;
}

.ReactTable.ListTable_listTable__1NU-o .actionBlock button {
  font-size: 18px;
  color: var(--color-icon);
}
.ReactTable.ListTable_listTable__1NU-o .actionBlock button .pencil {
  color: var(--color-green);
}

.ReactTable.ListTable_listTable__1NU-o .actionBlock > div:not(:last-child),
.ReactTable.ListTable_listTable__1NU-o .actionBlock > button:not(:last-child) {
  margin-right: 15px;
}

.ReactTable.ListTable_listTable__1NU-o .cellValue {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable.ListTable_listTable__1NU-o input[type="checkbox"] {
  background: #ffffff;
}

.ListTable_loadingHolder__PxHCl {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
}

.ListTable_loadingHolder__PxHCl.ListTable_showLoading__3f1Rr {
  display: flex;
}

.ListTable_datePicker__11I0D {
  margin-left: 15px;
  position: relative;
}

.ListTable_datePicker__11I0D label {
  display: inline-flex;

  align-items: center;
}

.ListTable_datePicker__11I0D label > div {
  margin-right: 10px;
}

.ListTable_datePicker__11I0D .ui.large.input {
  font-size: 1em;
  width: 130px;
}

.ListTable_filtersGrid__vn-dB {
  /*border-bottom: 1px solid var(--color-border);*/
}

.ListTable_tableTools__1PGjf .ui.segment.basic.ListTable_countItems__oavpP {
  padding-bottom: 0;
  margin-bottom: 0;
}

.ui.grid > .row > .column.ListTable_sortColumn__1QNdQ {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .ui.grid > .row > .column.ListTable_sortColumn__1QNdQ {
    padding-left: 0;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-thead,
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody {
    min-width: auto !important;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-tr {
    position: relative;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_auto {
    width: auto !important;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_1 {
    width: 6.25% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_2 {
    width: 12.5% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_3 {
    width: 18.75% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_4 {
    width: 25% !important;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_5 {
    width: 31.25% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_6 {
    width: 37.5% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_7 {
    width: 43.75% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_8 {
    width: 50% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_9 {
    width: 56.25% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_10 {
    width: 62.5% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_11 {
    width: 68.75% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_12 {
    width: 75% !important;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_13 {
    width: 81.25% !important;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_14 {
    width: 87.5% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_15 {
    width: 93.75% !important;
  }

  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-td.cell_16 {
    width: 100% !important;
  }

  .ListTable_datePicker__11I0D {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
  .ListTable_datePicker__11I0D > span {
    display: block;
  }
  .ListTable_datePicker__11I0D > span + span {
    margin-top: 10px;
  }

  .ListTable_datePicker__11I0D label > div:first-child {
    width: 30px;
  }
}

@media screen and (min-width: 768px) {
  .ReactTable.ListTable_listTable__1NU-o .rt-thead {
    display: flex;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-tr {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .ReactTable.ListTable_listTable__1NU-o .rt-tbody .rt-tr:hover {
    background: var(--color-row-hover) !important;
  }
}

/* App.css */
.TestTable_customTable__2kDCv {
  width: 100%;
  margin: 20px 0;
  font-size: 1em;
  min-width: 400px;
  border-collapse: collapse;
}

.TestTable_customTable__2kDCv thead tr {
  color: #5b666f;
  font-size: 14px;
  text-align: left;
}

.TestTable_customTable__2kDCv th,
.TestTable_customTable__2kDCv td {
  padding: 12px 15px;
  /* border: 1px solid #dddddd; */
}

.TestTable_customTable__2kDCv tbody tr {
}

.TestTable_customTable__2kDCv tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.TestTable_customTable__2kDCv tbody tr:last-of-type {
}

.TestTable_customTable__2kDCv tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.TestTable_customRow__2XfxA {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.TestTable_customRow__2XfxA input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.TestTable_sidePanel___r4Eu {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #ccc;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.TestTable_sidePanel___r4Eu h2 {
  margin-top: 0;
}

.TestTable_sidePanel___r4Eu button {
  background-color: #009879;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
}

.TestTable_sidePanel___r4Eu button:hover {
  background-color: #007f68;
}

.ListHolder_headerTasks__207T_ {
}

.ListHolder_headerTasks__207T_ .ui.grid > .row {
  padding: 0;
}

.ListHolder_headerTasks__207T_ .ui.grid > .row > .column:first-child {
  padding-left: 0;
}

.ListHolder_headerTasks__207T_ .ui.grid > .row > .column:last-child {
  padding-right: 0;
}

.ListHolder_headerTasks__207T_ .ui.grid > .row > .column:last-child .ui.button:last-child {
  margin-right: 0;
}

.ui.dropdown.ListHolder_dropdownHeaderMenu__1D4hi {
  padding-left: 0;
}

.ui.scrolling.dropdown.ListHolder_dropdownHeaderMenu__1D4hi .menu {
  max-height: 350px;
}

.ui.header.ListHolder_dropdownHeaderMenu__1D4hi {
  padding: 0.14em 0;
  margin: 0 20px 0.45238095em 0;
  line-height: 1.21428571em;
  display: inline-block;
}

.ui.header.ListHolder_taskName__17n7o {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.ListHolder_farmsHolder__tVkU_ .pusher {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.ListHolder_farmsHolder__tVkU_ .ui.right.overlay.sidebar {
  background: var(--color-background);
  width: 100%;
  padding: 15px;
  overflow: auto;
  box-shadow: var(--box-shadow);
}
.ListHolder_sidebarHolder__2LUcE {
  position: relative;
}

.ui.segment.ListHolder_mainHolder__2hb0W {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .ListHolder_farmsHolder__tVkU_ .ui.sidebar {
    width: 70%;
    max-width: 570px;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .ListHolder_headerTasks__207T_ .ui.grid > .row {
    flex-wrap: nowrap;
    width: 100%;
  }
  .ListHolder_headerTasks__207T_ .ui.grid > .row > .column {
    width: auto !important;
    flex: auto;
  }
}

.SidebarHolder_sidebar__2sHpU > .ui.grid {
  margin-bottom: 0;
}

.SidebarHolder_sidebar__2sHpU > .ui.grid:not(:first-child) + .ui.header {
  margin-top: 50px;
}

.SidebarHolder_buttonsHolder__2iq8K {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  z-index: 10;
}

.SidebarHolder_buttonsHolder__2iq8K button:not(:last-child) {
  margin-right: 20px;
}
.SidebarHolder_buttonsHolder__2iq8K .ui.button > .icon:not(.button) {
  height: auto;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 414px) {
}

.AddNewClient_overForm__1j5_n {
  height: 100%;
}

.AddNewClient_inputHidden__21FkL {
  height: 0;
}

.AddNewClient_accordionHolder__151G2 {
  max-width: 900px;
  margin: 0 auto;
}

.AddNewClient_buttonsFooter__2mhGV {
  text-align: right;
  padding: 5px 15px;
}

.AddNewClient_warning__1yGes {
  margin-top: 3rem;
  color: var(--color-pink);
}

.AddNewClient_confirmText__18-fB {
  padding: 2rem;
}
.AddNewClient_confirmText__18-fB > div + div {
  margin-top: 1rem;
}

.AddNewClient_confirmText__18-fB .AddNewClient_name__2rpSY {
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .AddNewClient_buttonsFooter__2mhGV {
    padding: 0;
  }
  .AddNewClient_buttonsFooter__2mhGV .ui.button {
    margin: 0;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

.ui.segment.SprayRecords_mainHolder__22_u1 {
  padding: 0;
}

.ui.header.SprayRecords_taskName__92Om1 {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.SprayRecords_dropdownHeaderMenu__BnLXL {
}

.ui.dropdown.SprayRecords_dropdownHeaderMenu__BnLXL {
  padding-left: 0;
}

.ui.scrolling.dropdown.SprayRecords_dropdownHeaderMenu__BnLXL .menu {
  max-height: 350px;
}

.ui.dropdown.SprayRecords_dropdownHeaderMenu__BnLXL > .text {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: "Montserrat", "Lato", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
}

.SprayRecords_barHolder__1icGP {
  display: flex;
  margin: 0 -5px;
  align-items: center;
  flex-wrap: wrap;
}

.SprayRecords_barHolder__1icGP > div {
  flex: 0 0 auto;
  padding: 5px;
}

.SprayRecords_barHolder__1icGP .ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin: 0;
}

.SprayRecords_barHolder__1icGP
  .ui.button:not(.icon) > .icon:not(.button):not(.dropdown)
  + span {
  margin-left: 10px;
}

.SprayRecords_barHolderIn__2VDu- {
  display: flex;
  align-items: center;
}

.SprayRecords_barHolderIn__2VDu- > div {
  flex: 0 0 auto;
  padding: 0 5px;
  max-width: 450px;
}

.SprayRecords_dateInput__2C-UD {
  width: 120px;
}

.ReactTable.SprayRecords_dataTable__DmCZ4 {
  overflow: auto;
  border: none;
}

.ReactTable.SprayRecords_dataTable__DmCZ4 .rt-tbody .rt-tr:hover .rt-td {
  background: var(--color-row-hover) !important;
}

.ui.dropdown.SprayRecords_areasDropdown__2_o49 {
  padding-right: 5em;
}

.ui.dropdown.SprayRecords_areasDropdown__2_o49 .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.SprayRecords_areasDropdown__2_o49 .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

@media screen and (min-width: 768px) {
  .ui.dropdown.SprayRecords_areasDropdown__2_o49 {
    width: 500px;
  }
}

@media screen and (max-width: 767px) {
  .SprayRecords_headerTasks__2TYlL .ui.grid > .row {
    flex-wrap: nowrap;
    width: 100%;
  }

  .SprayRecords_headerTasks__2TYlL .ui.grid > .row > .column {
    width: auto !important;
    flex: auto;
  }

  .ui.dropdown.SprayRecords_areasDropdown__2_o49 {
    width: 275px;
  }
}

.SprayAreaReport_page__3o7AP {
  maxwidth: 297mm;
  margin: 0;
  padding: 0mm 10mm;
}

.SprayAreaReport_block__2XovV {
  justifycontent: space-around;
  display: grid;
}

.SprayAreaReport_normalText__3kUT_ {
  fontweight: normal;
}

.SprayAreaReport_table__3zzCV {
  font-size: 12px !important;
  vertical-align: top;
}

.SprayAreaReport_header__20T3t {
  text-align: left;
}

.SprayAreaReport_tbody__336qY {
  vertical-align: top;
}

.SprayAreaReport_plannedDateHeader__1aiKo {
  min-width: 125px;
}

.SprayAreaReport_workingTimes__3X9bh {
  display: inline-block;
}

.SprayAreaReport_workingTimesLabel__2iYUU {
  display: inline-block;
  verticalalign: top;
  width: 35mm;
}

.SprayAreaReport_underline__1vLjJ {
  borderbottom: 1px solid black;
}

.SprayAreaReport_moreInfoContainer__3c1R9 {
  *position: relative;
}

.SprayAreaReport_moreInfoItem__3-vvI {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
}

.SprayAreaReport_multiItemsContainer__WOgOa {
  display: inline-block;
  vertical-align: top;
  /*position: relative;*/
}

.SprayAreaReport_multiItemsItem__17ROs {
  /*display: inline-block;*/
  /*float: left;*/
}

.EmployeesBarcodesGeneratorForm_sprayGrid__2_0km {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.EmployeesBarcodesGeneratorForm_sprayFormFooter__3u75X {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.EmployeesBarcodesGeneratorForm_sprayFormContainer__2jUPR {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.EmployeesBarcodesGeneratorForm_sprayFormContainer__2jUPR > div {
  max-width: 900px;
  margin: 0 auto;
}

.ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

.ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody td {
  white-space: nowrap;
}

.ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody td input {
  padding-left: 5px;
  padding-right: 5px;
}

.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB .ui.dropdown > .text {
  white-space: nowrap;
}

.ui.form .EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB .field .ui.input.labeled input {
  width: 100%;
}

.EmployeesBarcodesGeneratorForm_barcodesRow__3P1re {
  position: relative;
}

.EmployeesBarcodesGeneratorForm_barcodesRowActions__pa3je {
  display: flex;
}

.EmployeesBarcodesGeneratorForm_printed__3TTxX {
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
}

@media (max-width: 767px) {
  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB .EmployeesBarcodesGeneratorForm_title__2Db3Q {
    display: block;
  }
  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB {
    border: none;
  }

  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB thead {
    display: none;
  }
  .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB thead th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
  }

  .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody {
    display: block;
    position: relative;
  }

  .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody tr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody tr td {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
  }

  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody tr td:nth-child(1),
  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody tr td:nth-child(2),
  .EmployeesBarcodesGeneratorForm_barcodesTableHolder__2luLv .ui.table.EmployeesBarcodesGeneratorForm_barcodesTable__I6bwB tbody tr td:nth-child(3) {
    width: 33.33%;
  }
}

.SelectWithSelectAll_selectAllHolder__1ZqnE {
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.SelectWithSelectAll_selectAllHolder__1ZqnE .ui.button {
}

.SelectWithSelectAll_dropdownHolder__3IxDJ {
  position: relative;
}

.SelectWithSelectAll_dropdownHolder__3IxDJ .icon.sliders {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.SelectWithSelectAll_dropdownHolder__3IxDJ .icon.delete.SelectWithSelectAll_clearAll__3_eQu {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV .item i.vacant {
  color: var(--color-vacant-blue);
}

.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV.SelectWithSelectAll_showBar__1AVvc .menu,
.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV.SelectWithSelectAll_showBar__1AVvc .menu:hover,
.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV.SelectWithSelectAll_showBar__1AVvc .menu:active,
.ui.dropdown.SelectWithSelectAll_dropdown__SKyuV.SelectWithSelectAll_showBar__1AVvc:hover .menu {
  box-shadow: none;
  border-bottom: 0;
}

.SelectWithSelectAll_selectAllFooter__2q0_0 {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
  border: 1px solid var(--color-border-sui);
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.GeneralBarcodesGeneratorForm_sprayGrid__-yXe- {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.GeneralBarcodesGeneratorForm_sprayFormFooter__2LzjV {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.GeneralBarcodesGeneratorForm_sprayFormContainer__Ivxng {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.GeneralBarcodesGeneratorForm_sprayFormContainer__Ivxng > div {
  max-width: 900px;
  margin: 0 auto;
}

.ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

.ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody td {
  white-space: nowrap;
}

.ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody td input {
  padding-left: 5px;
  padding-right: 5px;
}

.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 .ui.dropdown > .text {
  white-space: nowrap;
}

.ui.form .GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 .field .ui.input.labeled input {
  width: 100%;
}

.GeneralBarcodesGeneratorForm_barcodesRow__1HZWU {
  position: relative;
}

.GeneralBarcodesGeneratorForm_barcodesRowActions__ELUEZ {
  display: flex;
}

.GeneralBarcodesGeneratorForm_printed__29H79 {
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
}

@media (max-width: 767px) {
  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 .GeneralBarcodesGeneratorForm_title__2jzeO {
    display: block;
  }
  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 {
    border: none;
  }

  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 thead {
    display: none;
  }
  .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 thead th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
  }

  .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody {
    display: block;
    position: relative;
  }

  .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody tr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody tr td {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
  }

  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody tr td:nth-child(1),
  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody tr td:nth-child(2),
  .GeneralBarcodesGeneratorForm_barcodesTableHolder__1hd7x .ui.table.GeneralBarcodesGeneratorForm_barcodesTable__3EdP3 tbody tr td:nth-child(3) {
    width: 33.33%;
  }
}

.A4_18_Labels_layout__29S23 .pageBreak {
  margin: 0;
  width: 297mm;
  padding: 10mm 30mm;
}

.A4_18_Labels_layout__29S23 .barcodeHolder {
  margin: 1.75mm 2mm;
  height: 38.1mm;
  width: 21.2mm;
}

.A4_18_Labels_layout__29S23 .barcodeDataHolder {
}

.A4_18_Labels_layout__29S23 .barcode {
  width: 100mm;
  height: 60mm;
}

.A4_18_Labels_layout__29S23 .qr-code {
  padding: 10px;
}

.A4_18_Labels_layout__29S23 .textHolder {
  /*margin-top: 3px;*/
  /*line-height: 8px;*/
  /*font-size: 8px;*/
}

.A4_18_Labels_layout__29S23 .text {
  /*padding: 1px;*/
}

.A4_16_Labels_layout__3p5KF .pageBreak {
  margin: 0;
}

.A4_16_Labels_layout__3p5KF .barcodeHolder {
  width: 76mm;
  height: 112mm;
  outline: 1px solid #7d7d7d;
  background-color: #e8e8e8;
}

.A4_16_Labels_layout__3p5KF .barcodeDataHolder {
  width: 100%;
}

.A4_16_Labels_layout__3p5KF .barcode {
  width: 27mm;
  height: 27mm;
}

.A4_16_Labels_layout__3p5KF .textHolder {
  font-size: 16px;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .block {
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .page {
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .pageBreak {
  margin: 0;
  width: 78mm;
  padding: 0;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .barcodeHolder {
  margin-bottom: 1.5mm;
  margin-top: 1.5mm;
  margin-left: 1.5mm;
  margin-right: 1.5mm;
  height: 13mm;
  width: 23mm;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .barcodeDataHolder {
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .barcode {
  height: 11mm;
  width: 11mm;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .textHolder {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.page_78mm_16mm_3_labels_23mm_13mm_layout__1GnFw .text {
  padding: 1px;
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .block {
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .page {
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .pageBreak {
  margin: 0;
  width: 100mm;
  padding: 0;
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .barcodeHolder {
  margin-bottom: 1.35mm;
  margin-top: 1.35mm;
  margin-left: 1.35mm;
  margin-right: 1.35mm;
  height: 37mm;
  width: 22.3mm;
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .barcodeDataHolder {
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .barcode {
  /*height: 15mm;*/
  /*width: 15mm;*/
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .textHolder {
  /*margin-top: 3px;*/
  /*line-height: 8px;*/
  /*font-size: 8px;*/
}

.page_100mm_40mm_4_Labels_37mm_22_3mm_layout__2Yjry .text {
  /*padding: 1px;*/
}

.page_100mm_50mm_1_label_layout__2GhWD .pageBreak {
  margin: 0;
  width: 100mm;
  height: 50mm;
  padding: 0;
}

.page_100mm_50mm_1_label_layout__2GhWD .barcodeHolder {
  margin: 1.5mm;
  width: 100mm;
  height: 50mm;
}

.page_100mm_50mm_1_label_layout__2GhWD .barcodeDataHolder {
  margin-top: 22px;
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_100mm_50mm_1_label_layout__2GhWD .barcode {
  width: 100%;
  height: 50mm !important;
  font-size: 12px;
}

.page_100mm_50mm_1_label_layout__2GhWD .comments-cell {
  height: 40px !important;
}

.page_100mm_50mm_1_label_layout__2GhWD .textHolder {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.page_100mm_50mm_1_label_layout__2GhWD .text {
  padding: 1px;
}

.page_80mm_85mm_1_label_layout__3W2SH .block {
}

.page_80mm_85mm_1_label_layout__3W2SH .page {
}

.page_80mm_85mm_1_label_layout__3W2SH .pageBreak {
  margin: 0;
  width: 80mm;
  height: 85mm;
  padding: 0;
}

.page_80mm_85mm_1_label_layout__3W2SH .barcodeHolder {
  margin: 1.5mm;
  height: 13mm;
  width: 23mm;
}

.page_80mm_85mm_1_label_layout__3W2SH .barcodeDataHolder {
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_80mm_85mm_1_label_layout__3W2SH .textHolder {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.page_80mm_85mm_1_label_layout__3W2SH .text {
  padding: 1px;
}

.page_80mm_40mm_1_label_layout__eQ1u3 .pageBreak {
  margin: 0;
  width: 80mm;
  height: 40mm;
  padding: 0;
}

.page_80mm_40mm_1_label_layout__eQ1u3 .barcodeHolder {
  margin: 1.5mm;
  width: 80mm;
  height: 40mm;
}

.page_80mm_40mm_1_label_layout__eQ1u3 .barcodeDataHolder {
  margin-top: 22px;
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.page_80mm_40mm_1_label_layout__eQ1u3 .barcode {
  height: 100%;
  width: 100%;
}

.page_80mm_40mm_1_label_layout__eQ1u3 .textHolder {
  margin-top: 5px !important;
  line-height: 6px;
  font-size: 6px;
}

.page_80mm_40mm_1_label_layout__eQ1u3 .text {
  padding: 1px;
  font-size: 14px !important;
  letter-spacing: 3px;
}

.page_70mm_60mm_1_label_layout__26w56 .pageBreak {
  margin: 0;
  width: 70mm;
  height: 60mm;
  padding: 0;
}

.page_70mm_60mm_1_label_layout__26w56 .barcodeHolder {
  margin: 1.5mm;
  width: 70mm;
  height: 60mm;
}

.page_70mm_60mm_1_label_layout__26w56 .barcodeDataHolder {
  margin-top: 60px;
  transform: translate(50%, -13%);
  left: unset;
  top: unset;
}

.page_70mm_60mm_1_label_layout__26w56 .barcode {
  height: 100%;
  width: 100%;
}

.page_70mm_60mm_1_label_layout__26w56 .textHolder {
  margin-top: 5px !important;
  line-height: 6px;
  font-size: 6px;
}

.page_70mm_60mm_1_label_layout__26w56 .text {
  padding: 1px;
  font-size: 14px !important;
  letter-spacing: 3px;
}

.CustomGeneralBarcodesPrintLayout_block__2nHjZ {
  box-sizing: border-box;
  justify-content: space-around;
  display: grid;
}

.CustomGeneralBarcodesPrintLayout_block__2nHjZ * {
  box-sizing: inherit;
}

.CustomGeneralBarcodesPrintLayout_page__haOv- {
}

.CustomGeneralBarcodesPrintLayout_pageBreak__1Z7Lj {
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  page-break-before: always;
}

.CustomGeneralBarcodesPrintLayout_barcodeHolder__2zTqw {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.CustomGeneralBarcodesPrintLayout_barcodeDataHolder__MxXNG {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.CustomGeneralBarcodesPrintLayout_barcode__3XULA {
  height: 15mm;
  width: 15mm;
}

.CustomGeneralBarcodesPrintLayout_textHolder__9Q0WU {
  margin-top: 3px;
  line-height: 8px;
  font-size: 8px;
}

.CustomGeneralBarcodesPrintLayout_text__zklkI {
  padding: 1px;
}

@media screen {
  .CustomGeneralBarcodesPrintLayout_pageBreak__1Z7Lj {
    outline: 1px dotted black;
  }

  .CustomGeneralBarcodesPrintLayout_barcodeHolder__2zTqw {
    outline: 1px dotted black;
  }
}

@media print {
  .CustomGeneralBarcodesPrintLayout_pageBreak__1Z7Lj:not(:last-child) {
    page-break-after: always;
  }

  .CustomGeneralBarcodesPrintLayout_pageBreak__1Z7Lj:last-child {
    page-break-after: avoid;
  }
}

.styles_barcode__1ckQl {
  border: 2px solid black;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  background: white;
  font-size: 20px;
}

.styles_fullHeight__34j40 {
  height: 100%;
}

.styles_minimized__gf4KV {
  font-size: 14px !important;
}

.styles_barcode__1ckQl tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styles_barcode__1ckQl tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styles_barcode__1ckQl td {
  padding-left: 8px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.styles_barcode__1ckQl tr td:last-child {
  border-right: none;
}

.styles_barcode__1ckQl tr:last-child td {
  border-bottom: none;
}

.GeneralBarcodeLargeArea_barcode__399Ny {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  background: white;
  font-size: 20px;
}

.GeneralBarcodeLargeArea_top__2SOS8 {
  padding-top: 10px;
  height: 50%;
  border-bottom: 2px solid black;

  overflow: hidden;
}

.GeneralBarcodeLargeArea_top__2SOS8 span {
  width: 100%;
  color: black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 50px;
  text-transform: uppercase;
  overflow-wrap: break-word;
  text-align: center;
}

.GeneralBarcodeLargeArea_variety__mvci5 {
  width: 100%;
  color: black !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 34px !important;
  line-height: 50px !important;
  text-transform: uppercase !important;
  overflow-wrap: break-word !important;
  text-align: center !important;
}

.GeneralBarcodeLargeArea_bottom__14Rqj {
  height: 50%;
  display: flex;
  flex-direction: row;
}

.GeneralBarcodeLargeArea_left__2e0dM,
.GeneralBarcodeLargeArea_right__1vOU3 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GeneralBarcodeLargeArea_left__2e0dM {
  border-right: 1px solid black;
}

.GeneralBarcodeLargeArea_right__1vOU3 {
  border-left: 1px solid black;
}

.GeneralBarcodeLargeArea_left__2e0dM span {
  max-width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.GeneralBarcodeLargeArea_right__1vOU3 span {
  max-width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  overflow-wrap: break-word;
}

.GeneralBarcodeLargeArea_qrCode__28Hw7 {
}

.CustomGeneralPomonaBarcodesPrintLayout_block__7oJUZ {
  box-sizing: border-box;
  justify-content: space-around;
  display: grid;
}

.CustomGeneralPomonaBarcodesPrintLayout_block__7oJUZ * {
  box-sizing: inherit;
}

.CustomGeneralPomonaBarcodesPrintLayout_pageBreak__1I12K {
  height: 450mm;
  width: 320mm;
  border-top: 1mm solid black;
  border-bottom: 1mm solid black;
  border-left: 8mm solid black;
  border-right: 8mm solid black;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  page-break-before: always;
}

.CustomGeneralPomonaBarcodesPrintLayout_barcodeHolder__UzF2b {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.CustomGeneralPomonaBarcodesPrintLayout_barcode__22ECO {
  height: 15mm;
  width: 15mm;
}

.CustomGeneralPomonaBarcodesPrintLayout_textHolder__kIJvR {
  margin-top: 3px;
  line-height: 8px;
  font-size: 8px;
}

.CustomGeneralPomonaBarcodesPrintLayout_text__3z1gd {
  padding: 1px;
}

@media screen {
  .CustomGeneralPomonaBarcodesPrintLayout_pageBreak__1I12K {
    outline: 1px dotted black;
  }

  .CustomGeneralPomonaBarcodesPrintLayout_barcodeHolder__UzF2b {
    outline: 1px dotted black;
  }
}

@media print {
  .CustomGeneralPomonaBarcodesPrintLayout_pageBreak__1I12K:not(:last-child) {
    page-break-after: always;
  }

  .CustomGeneralPomonaBarcodesPrintLayout_pageBreak__1I12K:last-child {
    page-break-after: avoid;
  }
}
.CustomGeneralPomonaBarcodesPrintLayout_qrCodeWrapper__3oDqq {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.CustomGeneralPomonaBarcodesPrintLayout_dasheswrapper__36G5d {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 40px;
  padding: 0 20px 0 20px;
}
.CustomGeneralPomonaBarcodesPrintLayout_dasheswrapperBottom__u5GDG {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 40px;
  padding: 0px 20px;
  height: 35px;
  position: absolute;
  top: 90%;
  width: 100%;
}
.CustomGeneralPomonaBarcodesPrintLayout_varietySection__2Ht94 {
  display: flex;
  width: 100%;
  align-items: baseline;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 11px;
  padding-left: 40px;
  margin-top: 10px;
}
.CustomGeneralPomonaBarcodesPrintLayout_blockSection__2TCf5 {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 11px;
  padding-left: 40px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.CustomGeneralPomonaBarcodesPrintLayout_pickerSection__3j-RU {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 11px;
  padding-left: 40px;
  margin-top: 20px;
}
.CustomGeneralPomonaBarcodesPrintLayout_valueVarietyField__FiSG- {
  margin-left: 3px;
  width: 60.7%;
  border-bottom: 1px solid black;
  font-size: 18px;
  font-weight: 1000;
}
.CustomGeneralPomonaBarcodesPrintLayout_valueBlockField__1Y3MR {
  margin-left: 3px;
  width: 65%;
  border-bottom: 1px solid black;
}
.CustomGeneralPomonaBarcodesPrintLayout_valuePickerField__tdtTd {
  margin-left: 3px;
  width: 63.1%;
  border-bottom: 1px solid black;
}
.CustomGeneralPomonaBarcodesPrintLayout_datePropertyStampWrapper__3pIJq {
  display: flex;
}
.CustomGeneralPomonaBarcodesPrintLayout_datePropertySection__12TKC {
  font-family: sans-serif;
  padding-left: 6px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.CustomGeneralPomonaBarcodesPrintLayout_stampSection__2VMVu {
  width: 50mm;
  height: 30mm;
  border: 0.25mm solid;
}

.CustomGeneralPomonaBarcodesPrintLayout_dateWrapper__1gbp3 {
  font-size: 8px;
  font-weight: 800;
  letter-spacing: -0.9px;
}
.CustomGeneralPomonaBarcodesPrintLayout_propertyWrapper__dbkZC {
  padding-top: 25px;
  font-size: 8px;
  font-weight: 800;
  letter-spacing: -0.9px;
}
.CustomGeneralPomonaBarcodesPrintLayout_propertyValue__gWkkc {
  text-align: left;
  width: 65px;
  line-height: 12px;
}
.CustomGeneralPomonaBarcodesPrintLayout_squarLogoWrapper__352O6 {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  width: 100%;
}
.CustomGeneralPomonaBarcodesPrintLayout_logoContainer__ABIbo {
  width: 100%;
  height: 40px;
}

.CustomGeneralPomonaBarcodesPrintLayout_logo__3B-ja {
  width: 105px;
  height: 50px;
}
.CustomGeneralPomonaBarcodesPrintLayout_squar__19HC7 {
  width: 35px;
  height: 25px;
  border: 0.25mm solid;
  margin-left: 2px;
}

.TagBarcodesGeneratorForm_sprayGrid__3s6Lf {
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.TagBarcodesGeneratorForm_sprayFormFooter__12a4- {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.TagBarcodesGeneratorForm_sprayFormContainer__k4lfM {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.TagBarcodesGeneratorForm_sprayFormContainer__k4lfM > div {
  max-width: 900px;
  margin: 0 auto;
}

.ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

.ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W tbody td {
  white-space: nowrap;
}

.ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W tbody td input {
  padding-left: 5px;
  padding-right: 5px;
}

.TagBarcodesGeneratorForm_barcodesTable__24e9W .ui.dropdown > .text {
  white-space: nowrap;
}

.ui.form .TagBarcodesGeneratorForm_barcodesTable__24e9W .field .ui.input.labeled input {
  width: 100%;
}

.TagBarcodesGeneratorForm_barcodesRow__1GdHe {
  position: relative;
}

.TagBarcodesGeneratorForm_barcodesRowActions__90vOi {
  display: flex;
}

.TagBarcodesGeneratorForm_printed__2CC-g {
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
}

@media (max-width: 767px) {
  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK .TagBarcodesGeneratorForm_barcodesTable__24e9W .TagBarcodesGeneratorForm_title__1cfDR {
    display: block;
  }

  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W {
    border: none;
  }

  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W thead {
    display: none;
  }

  .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W thead th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
  }

  .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W tbody {
    display: block;
    position: relative;
  }

  .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W tbody tr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W tbody tr td {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
  }

  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK
    .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W
    tbody
    tr
    td:nth-child(1),
  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK
    .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W
    tbody
    tr
    td:nth-child(2),
  .TagBarcodesGeneratorForm_barcodesTableHolder__-Y4wK
    .ui.table.TagBarcodesGeneratorForm_barcodesTable__24e9W
    tbody
    tr
    td:nth-child(3) {
    width: 33.33%;
  }
}

.CustomTagBarcodesPrintLayout_block__1L2IG {
  box-sizing: border-box;
  justify-content: space-around;
  display: grid;
}

.CustomTagBarcodesPrintLayout_block__1L2IG * {
  box-sizing: inherit;
}

.CustomTagBarcodesPrintLayout_page__3G76_ {
}

.CustomTagBarcodesPrintLayout_pageBreak__181l9 {
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  page-break-before: always;
}

.CustomTagBarcodesPrintLayout_barcodeHolder__2Mp7u {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.CustomTagBarcodesPrintLayout_barcodeDataHolder__2Z7wg {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.CustomTagBarcodesPrintLayout_barcode__34DsP {
  height: 15mm;
  width: 15mm;
}

.CustomTagBarcodesPrintLayout_textHolder__25vGN {
  margin-top: 3px;
  line-height: 8px;
  font-size: 8px;
}

.CustomTagBarcodesPrintLayout_text__pgPZV {
  padding: 1px;
}

@media screen {
  .CustomTagBarcodesPrintLayout_pageBreak__181l9 {
    outline: 1px dotted black;
  }

  .CustomTagBarcodesPrintLayout_barcodeHolder__2Mp7u {
    outline: 1px dotted black;
  }
}

@media print {
  .CustomTagBarcodesPrintLayout_pageBreak__181l9:not(:last-child) {
    page-break-after: always;
  }

  .CustomTagBarcodesPrintLayout_pageBreak__181l9:last-child {
    page-break-after: avoid;
  }
}

.styles_barcode__1JRzj {
  border: 2px solid black;
  border-radius: 7px;
  width: 420px;
  height: 300px;
  border-spacing: 0;
  background: white;
  font-size: 20px;
}

.styles_fullHeight__XNGTM {
  height: 100%;
}

.styles_minimized__2Ffpk {
  font-size: 14px !important;
}

.styles_barcode__1JRzj tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styles_barcode__1JRzj tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styles_barcode__1JRzj td {
  padding-left: 8px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.styles_barcode__1JRzj tr td:last-child {
  border-right: none;
}

.styles_barcode__1JRzj tr:last-child td {
  border-bottom: none;
}

.HarvestUnitsControl_modalMessageHeader__1PBBk {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}
.ui.modal.HarvestUnitsControl_messageModal__1Hrj8 {
  width: 400px !important;
  height: 150px !important;
  border-radius: 8px !important;
  overflow: auto;
}

.ui.modal.HarvestUnitsControl_messageModal__1Hrj8 .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

.ui.modal.HarvestUnitsControl_messageModal__1Hrj8 .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.HarvestUnitsControl_editButtonWrapper__pEh-z {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.HarvestUnitsControl_editButton__29PL2 {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.HarvestUnitsControl_editButton__29PL2 i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.HarvestUnitsControl_editButton__29PL2:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.HarvestUnitsControl_avatarsContainer__3Ey-9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.HarvestUnitsControl_avatarsContainer__3Ey-9 span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.HarvestUnitsControl_avatarHolder__2azWe {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.HarvestUnitsControl_avatarsContainer__3Ey-9 .HarvestUnitsControl_avatarHolder__2azWe:nth-child(n + 2) {
  margin-left: -10px;
}

.HarvestUnitsControl_morePopupContent__1NAdx {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.HarvestUnitsControl_popupItem__2c-fm {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.HarvestUnitsControl_popupItem__2c-fm .HarvestUnitsControl_avatarHolder__2azWe {
  height: 20px;
  min-width: 20px;
}

.HarvestUnitsControl_popupItem__2c-fm:nth-child(1) {
  margin-top: 0;
}

.HarvestUnitsControl_popupItem__2c-fm span {
  margin-left: 10px;
}

.HarvestUnitsControl_actionCells__hnj70 {
  overflow: initial !important;
}

.HarvestUnitsControl_imageContainer__15jAV {
  display: flex !important;
  align-items: center !important;
}

.HarvestUnitsControl_image__1FGRH {
  width: 30px !important;
  height: 30px !important;
}

.HarvestUnitsControl_imageButton__3ko2c {
  padding: 0 !important;
  background: none !important;
}

.HarvestUnitsControl_deleteButton__4As8a {
  width: 72px !important;
  height: 36px !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !;
  box-shadow: 0 0 0 1px #b00020 inset !important;
  color: white !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background-color: #b00020 !important;
}
.HarvestUnitsControl_cancelButton__1tRJX {
  width: 75px !important;
  height: 36px !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !;
  box-shadow: 0 0 0 1px #0e9354 inset !important;
  color: #0e9354 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background: none !important;
}

.HarvestUnitsControl_deleteIconActionButton__3pe3_ {
  width: 103px !important;
  height: 28px !important;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 0 0 1px #b00020 inset !important;
  color: #b00020 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background: none !important;
}

.HarvestUnitsControl_deleteIcon__2zuQi {
  color: #b00020 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.ui.button.HarvestUnitsControl_exportButton__1JVQ3 {
  width: 96px;
  height: 29px;
  display: flex;
  margin: 0;
  margin-right: 8px !important;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.button.HarvestUnitsControl_exportButton__1JVQ3:hover {
  width: 96px;
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 29px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 8px !important;
}

.ui.button.HarvestUnitsControl_exportButton__1JVQ3:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.HarvestUnitsControl_exportButton__1JVQ3:active {
  width: 96px;
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 29px;
  border-radius: 4px;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 8px !important;
}

.HarvestUnitsControlModal_modalHeader__3Wi43 {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

.HarvestUnitsControlModal_formField__1e-WL {
  width: 33%;
}

.HarvestUnitsControlModal_formSmallField__3N4Vh {
  width: 16.5%;
}

.HarvestUnitsControlModal_formSmallDropDown__2-zcw {
  max-width: 100% !important;
  margin: 0 !important;
}

.HarvestUnitsControlModal_formSmallDropDown__2-zcw div {
  min-width: 16.5% !important;
}

.HarvestUnitsControlModal_workersRow__yZXdE {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.HarvestUnitsControlModal_workersRowHead__20on8 {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.HarvestUnitsControlModal_addedItem__3Yfd9 {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.HarvestUnitsControlModal_closeIcon__1lzZj {
  margin-bottom: 5px;
}

.HarvestUnitsControlModal_editModalSegment__1MNki {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}
.HarvestUnitsControlModal_imageSegment__3DKwK {
  flex-wrap: wrap !important;
}
.HarvestUnitsControlModal_imageSegmentHeader__2AgZe {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #212121;
}

.HarvestUnitsControlModal_headerContainer__1so8v {
  display: flex !important;
  justify-content: space-between;
}

.HarvestUnitsControlModal_deleteButton__2R4mZ {
  background: none !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #b00020 !important;
}
.HarvestUnitsControlModal_addImageContainer__1l9Xk {
  width: 136px !important;
  height: 136px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px dashed #b3b3b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.HarvestUnitsControlModal_ui__1gvD_.HarvestUnitsControlModal_floating__3SfpM.HarvestUnitsControlModal_label__NdkML {
}

.MultiSelect_multiSelectDropdown__1MCPx {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
}

.MultiSelect_multiSelectDropdown__1MCPx:hover {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;
}
.MultiSelect_multiSelectDropdown__1MCPx.ui.dropdown .menu {
  width: 100%;
  min-width: auto;
}

.MultiSelect_multiSelectDropdown__1MCPx.ui.dropdown .scrolling.menu {
  height: 215px;
}

.MultiSelect_multiSelectDropdown__1MCPx i {
  float: right;
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  left: 15px;
}

.MultiSelect_dropdownItemSuffix__39hw8 {
  font-family: Roboto, sans-serif;
  font-size: 10px !important;
  font-style: normal;
  margin-left: 25px;
  font-weight: 400 !important;
  line-height: 12px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
}

.dropdown .menu > .MultiSelect_clearButton__2-E0w {
  cursor: pointer;
  text-transform: none !important;
  color: var(--color-error-delete) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
}

.MultiSelect_search__12ZXL {
  padding-right: 20px;
}
.MultiSelect_search__12ZXL input {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  padding-right: 20px;
}

.ImageModal_modalHeader__2Ku8N {
  display: flex !important;
  align-items: center !important;
}

.ImageModal_modalHeaderIconWrapper__2VNSn {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0e9354;
}

.ImageModal_modalHeaderNameWrapper__YTNEd {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}

.ImageModal_modalActionsButton__1d0Rj {
  display: flex !important;
  justify-content: flex-end !important;
}

.ImageModal_containerStyles__3CInJ {
  width: "500px" !important;
  height: "280px" !important;
  margin: "0 auto" !important;
}

.ImageModal_exportButtonWrapper__2oFWO {
  width: 96px !important;
  height: 36px !important;
  background-color: green !important;
  color: white !important;
  display: flex !important;
  justify-content: center;
  border-radius: 4px !important;
}

.ImageModal_exportButtonWrapper__2oFWO a {
  color: white !important;
}

.ImageModal_exportButton__1avxN {
  padding: 5px !important;
  font-size: 14px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  color: white !important;
  font-size: 13px !important;
}

.ImageModal_exportButton__1avxN i {
  font-size: 12px !important;
  color: white !important;
}

.ImageModal_exportButtonHeader__15wXu {
  color: white !important;
  padding-left: 7px !important;
  padding-top: 5px !important;
}

.ImageModal_confirmModal__2l44L {
  width: 400px !important;
}

.ui.modal > .header.AddModal_modalHeader__2UAmY {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}

.AddModal_stepLoading__3xLp5 {
  height: 48px;
  background: #f5f7f7;
  margin-bottom: 32px !important;
  display: flex;
  align-items: center !important;
}

.AddModal_iconAreasLabelWrapper__1bLHG {
  width: 9%;
  display: flex !important;
  justify-content: center !important;
}

.AddModal_iconLabelWrapper__2Tul4 {
  width: 12%;
  display: flex !important;
  justify-content: center !important;
}

.AddModal_dotIcon__3RuvQ {
  color: #59b655;
}

.AddModal_dotIconLabel__19bZZ {
  color: #59b655;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.AddModal_outLineCircel__3K796 {
  background-color: white !important;
  color: #dededf;
  border-radius: 100%;
}

.AddModal_outLineCircelLabel__1g-cL {
  color: #666666;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

hr {
  border: 1px dashed #b3b3b3;
  width: 79%;
}

.AddModal_coplitedLine__1X2YQ {
  border: 1px solid #0e9354 !important;
  width: 79% !important;
}

.FirstStep_modalHeader__1d3kd {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

.FirstStep_formSmallField__hCqZ7 {
  width: 16.5%;
}

.FirstStep_formSmallDropDown__3ea3y {
  max-width: 100% !important;
  margin: 0 !important;
}

.FirstStep_formSmallDropDown__3ea3y div {
  min-width: 16.5% !important;
}

.FirstStep_workersRow__2J9Gm {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.FirstStep_workersRowHead__33gt- {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.FirstStep_addedItem__1UWp5 {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.FirstStep_rowContainer__1gmL3 {
}

.FirstStep_closeIcon__2yDP5 {
  margin-bottom: 5px;
}

.FirstStep_dateRow__gkAj3 {
  border-bottom: 1px solid #dededf !important;
}

.FirstStep_firstStepForm__3nYqj {
  padding-left: 9px !important;
  width: 100% !important;
}

.FirstStep_scanDateField__1swdg {
  padding-bottom: 14px;
}

.FirstStep_horizontalLine__ZZWSx {
  border: none;
  background-color: #dededf;
  height: 1px;
  color: #dededf !important;
}

.FirstStep_tableGroup__Lvl-R {
  display: block !important;
  padding-left: 5px !important;
}

.FirstStep_searchInput__19FXE {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.FirstStep_searchInput__19FXE input {
  padding: 3px 6px 3px 16px !important;
}

.FirstStep_searchInput__19FXE input::placeholder {
  color: #b3b3b3;
}

.FirstStep_searchInput__19FXE input {
  padding: 3px 6px 3px 16px !important;
}

.FirstStep_searchInput__19FXE input::placeholder {
  color: #b3b3b3;
}

.FirstStep_filterContainer__3xlAX {
  display: flex;
  align-items: center;
  margin-left: 8px !important;
  grid-gap: 8px;
  gap: 8px;
}

.FirstStep_searchAndFiltersContainer__2ZAEI {
  display: flex !important;
}

.FirstStep_toggleIcon__1boXp {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.FirstStep_openTable__2uWz8 {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.FirstStep_tbody__3Tnv- {
  display: block !important;
  vertical-align: middle !important;
  width: 100%;
}

.FirstStep_icomNameContainer__2-j6_ {
  width: 100% !important;
}

.FirstStep_chevronContainer__KF5oK {
  width: 100% !important;
}

.FirstStep_patchRow__ORIN5 {
  display: block !important;
}

.FirstStep_patchCell__3pSBw {
  display: block !important;
  padding: 0 !important;
  height: 32px !important;
  padding-left: 25px !important;
  border: none !important;
}

.FirstStep_blockName__1G5ZD {
  padding-left: 10px !important;
}

.FirstStep_patchName__3fRLX {
  padding-left: 10px !important;
}

.FirstStep_closeTable__3TknP {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.FirstStep_iconNameWrapper__3nDyx {
  display: flex;
  align-items: center !important;
}

.FirstStep_selectAllButton__dTOqN {
  display: flex !important;
  align-items: center !important;
  height: 30px;
  background: #28b573 !important;
  color: white !important;
  cursor: pointer !important;
  padding: 11px !important;
}

formWrapper {
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 100% !important;
}

.SecondStep_binsDetails__3IKsu {
  width: 100%;
  height: 51px;
  background-color: #f5f7f7;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  align-items: center !important;
  padding-top: 17px;
  padding-right: 15px;
}

.SecondStep_secondStepForm__oe6ky {
  display: block !important;
}

.SecondStep_container__53I3u {
  display: block !important;
  width: 100% !important;
}

.SecondStep_ui__2VFa-.SecondStep_checkbox__zTqiz input:checked ~ label:before {
  background-color: #36d021 !important;
}

.SecondStep_formSegment__24Ejs {
  margin-top: 20px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  padding: 15px !important;
}

.SecondStep_superVisorRow__ZWLKJ {
  display: flex !important;
  padding-bottom: 15px;
}

.SecondStep_workersRow__1m-8M {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.SecondStep_workersRowHead__3QX3a {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.SecondStep_addedItem__s_l7T {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.SecondStep_closeIcon__3-0vX {
  margin-bottom: 5px;
}

.SecondStep_binsDetailsHaeader__3R_eW {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #212121;
  padding: 20px;
  padding-bottom: 33px !important;
}

.SecondStep_binsDetails__3IKsu
  .ui.fitted.toggle.checkbox
  > input:checked
  ~ label::before {
  background-color: #0e9354 !important;
}

.PrintReports_reportWrapper__1h0wZ {
  padding: 25px;
}

.PrintReports_reportHeaderRow__2xhu6 {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__DzDqX {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__3ZX9a {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__-6yOi {
  background-color: none;
  border: none;
}

.PrintReports_reportSubBody__2Ve2J {
  border: none;
  background-color: none;
}

.PrintReports_reportTable__3zBrg {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__oZOSc {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tableHeader__3-e_B {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_tableValue__6bEeo {
  padding-right: 5px;
}

.PrintReports_tableBody__11cJB {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_blockHeaderCellTableWithBorder__2Ox9z {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondHeaderCellTable__14H7- {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__F58Wh {
  border-collapse: collapse;
  border-right: 1px solid #dededf;
}

.PrintReports_blockBodyTable__1HJ_2 {
  width: 100%;
}

.PrintReports_blockCellTable__3vTFs {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__3q7dv {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTable__2ngx4 {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_patchHeaderValueCellTable__1sAoc {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTableWithBorder__k_T10 {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondHeaderCellTable__1hLaX {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchCellTable__1BCZR {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondCellTable__1FfS5 {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__3W4L4 {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__1yb_k {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__3WDth {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__2CQzo {
  width: 13%;
}

.PrintReports_dateRow__393Bd {
  padding-left: 16px;
}

.PrintReports_blockWrapper__1w7NU {
  background-color: #eeeeef;
  border-bottom: 2px solid black;
}

.PrintReports_patchWrapper__3WSNs {
  border-bottom: 2px solid black;
}

.PrintReports_patchBodyTable__3C0o7 {
  width: 100%;
}

.PrintReports_patchTableHeader__8MDgJ {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.PrintReports_blockTableHeader__TnPC- {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #dededf;
}

.ui.button.HarvestByArea_printButton__2XTYJ {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.HarvestByArea_printButton__2XTYJ:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.HarvestByArea_printButton__2XTYJ:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.HarvestByArea_printButton__2XTYJ:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.HarvestByArea_iconWrapper__14Gg1 {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.button.HarvestByArea_backIconWrapper__3Nzoz {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByArea_backIcon__30hVt {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.HarvestByArea_totalRow__1VXUi {
  font-weight: 600;
}
.ui.button.HarvestByArea_backIconWrapper__3Nzoz {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByArea_backIcon__30hVt {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ui.button.PickerReport_printButton__RAeKT {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.PickerReport_printButton__RAeKT:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.PickerReport_printButton__RAeKT:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.PickerReport_printButton__RAeKT:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.PickerReport_iconWrapper__3w5S2 {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

td:has(.PickerReport_valueContainer__2bb7h) {
  border: none !important;
}

.PickerReport_valueContainer__2bb7h {
  width: 100% !important;
  display: flex;
  align-items: center;
  height: 50px;
}

.PickerReport_chevronIcon__EDpLM {
  margin-right: 5px !important;
}

.ui.button.PickerReport_backIconWrapper__MLxIZ {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.PickerReport_backIcon__3RYJT {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.PickerReport_totalRow__2hHgq {
  font-weight: 600;
}

.PrintReports_reportWrapper__2GZrV {
  padding: 25px;
}

.PrintReports_firstTable__17DXe {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.PrintReports_secondTable__31p_6 {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReports_thirdTable__vG9xn {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.PrintReports_reportHeaderRow__14Ix1 {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__1SxNj {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__2hEgU {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__2h99s {
  background-color: none;
  border: none !important;
}

.PrintReports_reportSubBody__KMeLQ {
  border: none;
  border-top: 1px solid #dededf !important;
  background-color: none;
}

.PrintReports_reportTable__1b81j {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__1fHFs {
  background-color: #eeeeef !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_secondHeaderCellTable__1gfpQ {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tirdHeaderCellTable__6vqM_ {
  height: 28px !important;
  border-bottom: 1px solid #dededf !important;
  border-right: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.PrintReports_tirdTableHeaderRow__23Qp3 :last-child {
  border-right: none !important;
}

.PrintReports_tableHeader__360ee {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_tableValue__2uZys {
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_secondTableHeader__1G3B9 {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_secondTableValue__1R4Jy {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_tirdTableHeader__3_yIO {
  height: 28px !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValue__2qnjV {
  height: 28px !important;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tableBody__1kxmh {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_blockHeaderCellTableWithBorder__3kq0C {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__35wGd {
  background-color: #eeeeef !important;
  border-bottom: 2px solid #000000 !important;
}

.PrintReports_secondHeaderValueCellTable__3Agyl {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReports_tirdHeaderValueCellTable__1Y39L {
  height: 28px !important;
  border-right: 1px solid #dededf !important;
  border-bottom: 2px solid #000000 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValueRow__TQuZy :last-child {
  border-right: none !important;
}

.PrintReports_blockBodyTable__1Lnxl {
  width: 100%;
}

.PrintReports_blockCellTable__1iEXo {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__2VeIW {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__AyXUF {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__1SRC0 {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__2sI9E {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__3N3Q_ {
  width: 13%;
}

.PrintReports_dateRow__1zydy {
  padding-left: 16px;
}

.PrintReports_totalHeaderCell__10WyR {
  border-top: 1px solid #dededf !important;
}

.ui.button.PickerReport_printButton__3qwZu {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.PickerReport_printButton__3qwZu:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.PickerReport_printButton__3qwZu:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.PickerReport_printButton__3qwZu:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.PickerReport_iconWrapper__1HpdJ {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

td:has(.PickerReport_valueContainer__1l8Zn) {
  border: none !important;
}

.PickerReport_valueContainer__1l8Zn {
  width: 100% !important;
  display: flex;
  align-items: center;
  height: 50px;
}

.PickerReport_chevronIcon__3rK3o {
  margin-right: 5px !important;
}

.ui.button.PickerReport_backIconWrapper__2clZS {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.PickerReport_backIcon__xsbyc {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.PickerReport_totalRow__18UcN {
  font-weight: 600;
}

.PrintReports_reportWrapper__1h2g- {
  padding: 25px;
}

.PrintReports_firstTable__3i-wI {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.PrintReports_secondTable__2arYr {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReports_thirdTable__2tI1d {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.PrintReports_reportHeaderRow__1Qv02 {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__3BSPN {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__3ntu1 {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__1REKt {
  background-color: none;
  border: none !important;
}

.PrintReports_reportSubBody__3BcJ1 {
  border: none;
  border-top: 1px solid #dededf !important;
  background-color: none;
}

.PrintReports_reportTable__2Hqxx {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__NxaSO {
  background-color: #eeeeef !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_secondHeaderCellTable__2INsv {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tirdHeaderCellTable__1b7vP {
  height: 28px !important;
  border-bottom: 1px solid #dededf !important;
  border-right: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.PrintReports_tirdTableHeaderRow__3dUF_ :last-child {
  border-right: none !important;
}

.PrintReports_tableHeader__23pXX {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_tableValue__25-k8 {
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_secondTableHeader__3kci2 {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_secondTableValue__1H2Za {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_tirdTableHeader__p5hSh {
  height: 28px !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValue__L5AxB {
  height: 28px !important;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tableBody__22gx0 {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_blockHeaderCellTableWithBorder__31IpX {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__1lFbH {
  background-color: #eeeeef !important;
  border-bottom: 2px solid #000000 !important;
}

.PrintReports_secondHeaderValueCellTable__d3oNI {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReports_tirdHeaderValueCellTable__3GCOI {
  height: 28px !important;
  border-right: 1px solid #dededf !important;
  border-bottom: 2px solid #000000 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValueRow__-TuD9 :last-child {
  border-right: none !important;
}

.PrintReports_blockBodyTable__3Y7Q2 {
  width: 100%;
}

.PrintReports_blockCellTable__1vUUN {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__1ah2q {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__3eJtf {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__3LSaF {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__3YYNX {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__ioMkQ {
  width: 13%;
}

.PrintReports_dateRow__2iVfy {
  padding-left: 16px;
}

.PrintReports_totalHeaderCell__1BsRi {
  border-top: 1px solid #dededf !important;
}

.ui.button.ContractorReport_printButton__2XOUS {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.ContractorReport_printButton__2XOUS:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.ContractorReport_printButton__2XOUS:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.ContractorReport_printButton__2XOUS:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.ContractorReport_iconWrapper__2StFw {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

td:has(.ContractorReport_valueContainer__7jx9v) {
  border: none !important;
}

.ContractorReport_valueContainer__7jx9v {
  width: 100% !important;
  display: flex;
  align-items: center;
  height: 50px;
}

.ContractorReport_chevronIcon__3HHw5 {
  margin-right: 5px !important;
}

.ui.button.ContractorReport_backIconWrapper__2gv0E {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.ContractorReport_backIcon__2ejAM {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ContractorReport_totalRow__z6Cg6 {
  font-weight: 600;
}

.ContractorReport_morePopupContent__1dYyd {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.ContractorReport_popupItem__3IDqh {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.ContractorReport_popupItem__3IDqh .ContractorReport_avatarHolder__28Ooe {
  height: 20px;
  min-width: 20px;
}

.ContractorReport_popupItem__3IDqh:nth-child(1) {
  margin-top: 0;
}

.ContractorReport_popupItem__3IDqh span {
  margin-left: 10px;
}

.ContractorReport_avatarHolder__28Ooe {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}
.ContractorReport_avatarsContainer__hcgej {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ContractorReport_avatarsContainer__hcgej .ContractorReport_avatarHolder__28Ooe:nth-child(n + 2) {
  margin-left: -10px;
}

.ContractorReport_avatarsContainer__hcgej span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.PrintReports_reportWrapper__17-fn {
  padding: 25px;
}

.PrintReports_firstTable__2S39O {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.PrintReports_secondTable__38L6c {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReports_thirdTable__30IAO {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.PrintReports_reportHeaderRow__1FuWO {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__3dft0 {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__3aNuv {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__2wLwg {
  background-color: none;
  border: none !important;
}

.PrintReports_reportSubBody__2H0IM {
  border: none;
  border-top: 1px solid #dededf !important;
  background-color: none;
}

.PrintReports_reportTable__1Gm9S {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__PzLB2 {
  background-color: #eeeeef !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_secondHeaderCellTable__2qYzk {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tirdHeaderCellTable__YDvF3 {
  height: 28px !important;
  border-bottom: 1px solid #dededf !important;
  border-right: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.PrintReports_tirdTableHeaderRow__2CXLo :last-child {
  border-right: none !important;
}

.PrintReports_tableHeader__8L74G {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_tableValue__3GNsc {
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_secondTableHeader__2ablo {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_secondTableValue__13qjk {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_tirdTableHeader__3-tzA {
  height: 28px !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValue__wVMyt {
  height: 28px !important;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tableBody__3k85T {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_blockHeaderCellTableWithBorder__3Lhcz {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__5yCT8 {
  background-color: #eeeeef !important;
  border-bottom: 2px solid #000000 !important;
}

.PrintReports_secondHeaderValueCellTable__3-9nB {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReports_tirdHeaderValueCellTable__2bbvx {
  height: 28px !important;
  border-right: 1px solid #dededf !important;
  border-bottom: 2px solid #000000 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValueRow__353ai :last-child {
  border-right: none !important;
}

.PrintReports_blockBodyTable__3TWyv {
  width: 100%;
}

.PrintReports_blockCellTable__2MuYC {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__2XigD {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__qo2Sv {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__2zCfD {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__7T9jf {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__1XezH {
  width: 13%;
}

.PrintReports_dateRow__2gKsI {
  padding-left: 16px;
}

.PrintReports_totalHeaderCell__2tMmu {
  border-top: 1px solid #dededf !important;
}

.ui.button.ContractorReport_printButton__jaM97 {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.ContractorReport_printButton__jaM97:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.ContractorReport_printButton__jaM97:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.ContractorReport_printButton__jaM97:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.ContractorReport_iconWrapper__YzzwJ {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

td:has(.ContractorReport_valueContainer__3Gu8O) {
  border: none !important;
}

.ContractorReport_valueContainer__3Gu8O {
  width: 100% !important;
  display: flex;
  align-items: center;
  height: 50px;
}

.ContractorReport_chevronIcon__1i6ln {
  margin-right: 5px !important;
}

.ui.button.ContractorReport_backIconWrapper__2Bx5s {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.ContractorReport_backIcon__1tgJ4 {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ContractorReport_totalRow__2KLjf {
  font-weight: 600;
}

.ContractorReport_morePopupContent__Sv3oz {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.ContractorReport_popupItem__1ThHG {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.ContractorReport_popupItem__1ThHG .ContractorReport_avatarHolder__2p5ja {
  height: 20px;
  min-width: 20px;
}

.ContractorReport_popupItem__1ThHG:nth-child(1) {
  margin-top: 0;
}

.ContractorReport_popupItem__1ThHG span {
  margin-left: 10px;
}

.ContractorReport_avatarHolder__2p5ja {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}
.ContractorReport_avatarsContainer__ci3IC {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ContractorReport_avatarsContainer__ci3IC .ContractorReport_avatarHolder__2p5ja:nth-child(n + 2) {
  margin-left: -10px;
}

.ContractorReport_avatarsContainer__ci3IC span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.PrintReports_reportWrapper__2ygbh {
  padding: 25px;
}

.PrintReports_firstTable__2RGhX {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.PrintReports_secondTable__Vq-3Z {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.PrintReports_thirdTable__1QOyM {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.PrintReports_reportHeaderRow__2iuFc {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__qLig8 {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__2xVOe {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__3wLUx {
  background-color: none;
  border: none !important;
}

.PrintReports_reportSubBody__12qoC {
  border: none;
  border-top: 1px solid #dededf !important;
  background-color: none;
}

.PrintReports_reportTable__3I73P {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__1V_WA {
  background-color: #eeeeef !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_secondHeaderCellTable__rSiEn {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tirdHeaderCellTable__2hK4W {
  height: 28px !important;
  border-bottom: 1px solid #dededf !important;
  border-right: 1px solid #dededf !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.PrintReports_tirdTableHeaderRow__1px3Z :last-child {
  border-right: none !important;
}

.PrintReports_tableHeader__3xfk0 {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_tableValue__1vH2n {
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_secondTableHeader__2ojmj {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_secondTableValue__38sPr {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 5px;
  padding-left: 5px;
}

.PrintReports_tirdTableHeader__O2L6d {
  height: 28px !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValue__2Vy5m {
  height: 28px !important;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tableBody__OwnOl {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_blockHeaderCellTableWithBorder__j4Hp9 {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__1CFoz {
  background-color: #eeeeef !important;
  border-bottom: 2px solid #000000 !important;
}

.PrintReports_secondHeaderValueCellTable__1jr0B {
  height: 28px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReports_tirdHeaderValueCellTable__3FFIc {
  height: 28px !important;
  border-right: 1px solid #dededf !important;
  border-bottom: 2px solid #000000 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.PrintReports_tirdTableValueRow__3eV7u :last-child {
  border-right: none !important;
}

.PrintReports_blockBodyTable__M22RQ {
  width: 100%;
}

.PrintReports_blockCellTable__2yh0f {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__2j_3u {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__18pch {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__2vJIF {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__1oI6o {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__2BqOQ {
  width: 13%;
}

.PrintReports_dateRow__1KXRK {
  padding-left: 16px;
}

.PrintReports_totalHeaderCell__jJExo {
  border-top: 1px solid #dededf !important;
}

.PrintReports_reportWrapper__B_N4l {
  padding: 25px;
}

.PrintReports_reportHeaderRow__2Y-0X {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__RFBGm {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__2vBtW {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__32_tr {
  background-color: none;
  border: none;
}

.PrintReports_reportSubBody__3bgh8 {
  -webkit-hyphens: auto;
          hyphens: auto;
  border: none;
  background-color: none;
}

.PrintReports_reportTable__2SQtX {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__1BHI7 {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tableHeader__10dlp {
  padding-left: 15px;
  padding-right: 15px;
}

.PrintReports_tableValue__ahm0v {
  padding-right: 15px;
}

.PrintReports_tableBody__1aIOH {
  padding-left: 15px;
  padding-right: 15px;
}

.PrintReports_blockHeaderCellTableWithBorder__3crin {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondHeaderCellTable__1dyMT {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__3RMJ7 {
  border-collapse: collapse;
  border-right: 1px solid #dededf;
}

.PrintReports_blockBodyTable__3td2g {
  width: 100%;
}

.PrintReports_blockCellTable__23H3W {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__3kcub {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTable__1JtmS {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_patchHeaderValueCellTable__O2bF9 {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTableWithBorder__3xVEv {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondHeaderCellTable__2qng3 {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchCellTable__3ZBnt {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondCellTable__2Oxbe {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__R7k_h {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__2Yb23 {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__1kjj7 {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__3hAV0 {
  width: 13%;
}

.PrintReports_dateRow__QMWj7 {
  padding-left: 16px;
}

.PrintReports_blockWrapper__16J1T {
  background-color: #eeeeef;
  border-bottom: 2px solid black;
}

.PrintReports_patchWrapper__2r2kb {
  border-bottom: 2px solid black;
}

.PrintReports_patchBodyTable__3EDLq {
  width: 100%;
}

.PrintReports_patchTableHeader__GFam6 {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.PrintReports_blockTableHeader__rcqDx {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.ui.button.HarvestByVariety_printButton__KOqPc {
  height: 28px;
  display: flex;
  padding: 0;
  align-items: center;
  opacity: 1 !important;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.HarvestByVariety_printButton__KOqPc:hover {
  color: white !important;
  opacity: 1 !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.HarvestByVariety_printButton__KOqPc:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.HarvestByVariety_printButton__KOqPc:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.HarvestByVariety_iconWrapper__3PxrR {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.button.HarvestByVariety_backIconWrapper__2-dAd {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByVariety_backIcon__E2mjc {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.HarvestByVariety_totalRow__27yhj {
  font-weight: 600;
}

.PrintReports_reportWrapper__3d5_M {
  padding: 25px;
}

.PrintReports_reportHeaderRow__3TXdg {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__3H6Sq {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__2ObM1 {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__2mH62 {
  background-color: none;
  border: none;
}

.PrintReports_reportSubBody__1kOCV {
  border: none;
  background-color: none;
}

.PrintReports_reportTable__3hvsE {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__oMSSW {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tableHeader__2eCbd {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_tableValue__12JXG {
  padding-right: 5px;
}

.PrintReports_tableBody__28eMR {
  padding-left: 5px;
  padding-right: 5px;
}

.PrintReports_blockHeaderCellTableWithBorder__1nQ12 {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondHeaderCellTable__UKbuR {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__1hRh1 {
  border-collapse: collapse;
  border-right: 1px solid #dededf;
}

.PrintReports_blockBodyTable__3S9Gc {
  width: 100%;
}

.PrintReports_blockCellTable__2nA1S {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__2ijZ7 {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTable__1li8M {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_patchHeaderValueCellTable__3Wbxb {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTableWithBorder__27E7g {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondHeaderCellTable__2jdPB {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchCellTable__2Kux7 {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondCellTable__xyCkZ {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__3o-JU {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__3v7SI {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__2KQ99 {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__Plk0f {
  width: 13%;
}

.PrintReports_dateRow__QOc8G {
  padding-left: 16px;
}

.PrintReports_blockWrapper__3D5JS {
  background-color: #eeeeef;
  border-bottom: 2px solid black;
}

.PrintReports_patchWrapper__3duhU {
  border-bottom: 2px solid black;
}

.PrintReports_patchBodyTable__2jhOY {
  width: 100%;
}

.PrintReports_patchTableHeader__eocOH {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.PrintReports_blockTableHeader__2HS5c {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #dededf;
}

.ui.button.HarvestByArea_printButton__2sdLr {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.HarvestByArea_printButton__2sdLr:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.HarvestByArea_printButton__2sdLr:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.HarvestByArea_printButton__2sdLr:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.HarvestByArea_iconWrapper__C7SvV {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.button.HarvestByArea_backIconWrapper__34683 {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByArea_backIcon__2Fel3 {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.HarvestByArea_totalRow__2sxuj {
  font-weight: 600;
}
.ui.button.HarvestByArea_backIconWrapper__34683 {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByArea_backIcon__2Fel3 {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.PrintReports_reportWrapper__1v-TE {
  padding: 25px;
}

.PrintReports_reportHeaderRow__ZW4z- {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReports_logoContainet__-04qR {
  width: 90px;
  height: 90px;
}

.PrintReports_reportHeader__2nCqL {
  width: 900px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  margin-bottom: 16px;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.PrintReports_reportSubHeader__3vwps {
  background-color: none;
  border: none;
}

.PrintReports_reportSubBody__eHBgY {
  -webkit-hyphens: auto;
          hyphens: auto;
  border: none;
  background-color: none;
}

.PrintReports_reportTable__K0G5g {
  width: 100%;
}

.PrintReports_blockHeaderCellTable__33Dcp {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_tableHeader__1JoNW {
  padding-left: 15px;
  padding-right: 15px;
}

.PrintReports_tableValue__2ODax {
  padding-right: 15px;
}

.PrintReports_tableBody__3fJ-N {
  padding-left: 15px;
  padding-right: 15px;
}

.PrintReports_blockHeaderCellTableWithBorder__O2lDB {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondHeaderCellTable__EoJFb {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockHeaderValueCellTable__2EfKX {
  border-collapse: collapse;
  border-right: 1px solid #dededf;
}

.PrintReports_blockBodyTable__2o_Vm {
  width: 100%;
}

.PrintReports_blockCellTable__3p1OK {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_blockSecondCellTable__B4nOg {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTable__bLt8I {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-collapse: collapse;
}

.PrintReports_patchHeaderValueCellTable__2shkS {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchHeaderCellTableWithBorder__38GCE {
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-left: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondHeaderCellTable__j5Lcu {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #dededf;
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchCellTable__3-UOb {
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_patchSecondCellTable__X1JON {
  border-right: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowTitle__2zj6x {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.PrintReports_totalRowHeader__11CyQ {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 15px;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  border-collapse: collapse;
}

.PrintReports_totalRowBody__2Ipi- {
  padding: 7px;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.PrintReports_cell__1Vjzh {
  width: 13%;
}

.PrintReports_dateRow__3Yz2c {
  padding-left: 16px;
}

.PrintReports_blockWrapper__3rVLR {
  background-color: #eeeeef;
  border-bottom: 2px solid black;
}

.PrintReports_patchWrapper__qtZpa {
  border-bottom: 2px solid black;
}

.PrintReports_patchBodyTable__3QeuQ {
  width: 100%;
}

.PrintReports_patchTableHeader__1U8IA {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.PrintReports_blockTableHeader__2Z9lq {
  width: 100%;
  border-bottom: 1px solid #dededf;
}

.ui.button.HarvestByVariety_printButton__nZswi {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.HarvestByVariety_printButton__nZswi:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.HarvestByVariety_printButton__nZswi:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.HarvestByVariety_printButton__nZswi:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.HarvestByVariety_iconWrapper__1b4Qm {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.ui.button.HarvestByVariety_backIconWrapper__2xHQ8 {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByVariety_backIcon__2hgg3 {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.HarvestByVariety_totalRow__1zqfy {
  font-weight: 600;
}
.ui.button.HarvestByVariety_backIconWrapper__2xHQ8 {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.HarvestByVariety_backIcon__2hgg3 {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.ui.header.Sidebar_name__1TJQD {
  margin: 0 130px 0 0;
}

.ui.table.Sidebar_barcodesTable__2yVLk {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.ui.table.Sidebar_barcodesTable__2yVLk thead {
  /* head takes the height it requires,
  and it's not scaled when :global(.ui.table).barcodesTable is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
}

.ui.table.Sidebar_barcodesTable__2yVLk tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  max-height: 172px;
  display: block;
  overflow-y: auto;
}

.ui.table.Sidebar_barcodesTable__2yVLk tbody tr {
  width: 100%;
}

.ui.table.Sidebar_barcodesTable__2yVLk thead,
.ui.table.Sidebar_barcodesTable__2yVLk tbody tr {
  display: table;
  table-layout: fixed;
}

.ui.table.Sidebar_barcodesTable__2yVLk thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

@media screen and (max-width: 767px) {
  .ui.table.Sidebar_barcodesTable__2yVLk {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .ui.table.Sidebar_barcodesTable__2yVLk {
    width: 70%;
  }
}

.Sidebar_btnWrapper__1m19y {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__5enYh {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__3Ij-b {
  cursor: pointer !important;
}
.ui.segment.FarmsRecords_mainHolder__2Obc2 {
  padding: 0;
}

.ui.header.FarmsRecords_header__1t8KQ {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.FarmsRecords_headerTasks__2pFvG {
}

.FarmsRecords_headerTasks__2pFvG .ui.grid > .row {
  padding: 0;
}

.FarmsRecords_headerTasks__2pFvG .ui.grid > .row > .column:first-child {
  padding-left: 0;
}

.FarmsRecords_headerTasks__2pFvG .ui.grid > .row > .column:last-child {
  padding-right: 0;
}

.FarmsRecords_headerTasks__2pFvG .ui.grid > .row > .column:last-child .ui.button:last-child {
  margin-right: 0;
}

.FarmsRecords_dropdownHeaderMenu__bi-_v {
}

.ui.dropdown.FarmsRecords_dropdownHeaderMenu__bi-_v {
  padding-left: 0;
}

.ui.scrolling.dropdown.FarmsRecords_dropdownHeaderMenu__bi-_v .menu {
  max-height: 350px;
}

.ui.dropdown.FarmsRecords_dropdownHeaderMenu__bi-_v > .text {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.FarmsRecords_farmsHolder__2wqSJ .pusher {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.FarmsRecords_farmsHolder__2wqSJ .ui.right.overlay.sidebar {
  background: var(--color-background);
  width: 100%;
  padding: 15px;
  overflow: auto;
  box-shadow: var(--box-shadow);
}

@media screen and (min-width: 768px) {
  .FarmsRecords_farmsHolder__2wqSJ .ui.sidebar {
    width: 70%;
    max-width: 570px;
    padding: 30px;
  }
}

.ui.header.Sidebar_name__3hmJV {
  margin: 0 0 15px 0;
}

@media screen and (max-width: 414px) {
  .ui.header.Sidebar_name__3hmJV {
    margin-right: 0;
  }
}

.Sidebar_btnWrapper__2dwlw {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__2XdfT {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__HLZCS {
  cursor: pointer !important;
}
.AddBoxUnits_buttonsFooter__3H2fZ .ui.button {
  margin: 0;
}

.BoxUnitsForm_sprayGrid__3vdJW {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.BoxUnitsForm_sprayGrid__3vdJW.BoxUnitsForm_forModal__18Mbl > div {
  padding: 0;
}

.BoxUnitsForm_sprayGrid__3vdJW.BoxUnitsForm_forModal__18Mbl > div + div {
  margin-top: 15px;
}
.BoxUnitsForm_sprayGrid__3vdJW.BoxUnitsForm_forModal__18Mbl .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.BoxUnitsForm_sprayGrid__3vdJW:not(.BoxUnitsForm_forModal__18Mbl) .ui.segment.BoxUnitsForm_sprayTasksSidebarIn__3hA_w {
  flex: auto;
}

.BoxUnitsForm_sprayFormFooter__2M-Rb {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.BoxUnitsForm_sprayFormContainer__KtkJ9 {
  overflow: auto;
  padding: 30px 15px;
}

.BoxUnitsForm_sprayFormContainer__KtkJ9 > div {
  max-width: 900px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
}

.ui.header.Sidebar_name__7f89o {
  margin: 0 130px 15px 0;
}

@media screen and (max-width: 414px) {
  .ui.header.Sidebar_name__7f89o {
    margin-right: 0;
  }
}

.Sidebar_btnWrapper__WbBpo {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__2AvjK {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__1aIVD {
  cursor: pointer !important;
}
.AddPackingSheds_buttonsFooter__38Rhr .ui.button {
  margin: 0;
}

.PackingShedsForm_sprayGrid__3eeab {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.PackingShedsForm_sprayGrid__3eeab.PackingShedsForm_forModal__g6Dg_ > div {
  padding: 0;
}

.PackingShedsForm_sprayGrid__3eeab.PackingShedsForm_forModal__g6Dg_ > div + div {
  margin-top: 15px;
}
.PackingShedsForm_sprayGrid__3eeab.PackingShedsForm_forModal__g6Dg_ .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.PackingShedsForm_sprayGrid__3eeab:not(.PackingShedsForm_forModal__g6Dg_) .ui.segment.PackingShedsForm_sprayTasksSidebarIn__1bC0h {
  flex: auto;
}

.PackingShedsForm_sprayFormFooter__16-cf {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.PackingShedsForm_sprayFormContainer__2olcG {
  overflow: auto;
  padding: 30px 15px;
}

.PackingShedsForm_sprayFormContainer__2olcG > div {
  max-width: 900px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
}

.ui.header.Sidebar_name__Ndc8A {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.Sidebar_sectionHeader__1dNaA {
  margin-top: 16px;
  margin-bottom: 0;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 414px) {
  .ui.header.Sidebar_name__Ndc8A {
    margin-right: 0;
  }
}

.Sidebar_blockName__1VbF6 {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  margin: 10px 0;
}

.Sidebar_root___SUWS {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.Sidebar_body__23aa_ {
  flex: 0 1 100%;
  overflow: auto;
}

.Sidebar_sidebarHeader__3us9L {
  border-bottom: 1px solid var(--color-border);
}

.Sidebar_sidebarHeader__3us9L.ui.grid {
  margin-bottom: 16px;
}

.Sidebar_sectionTable__P3-Wa.ui.fixed.table td {
  height: 40px !important;
  padding-top: 9px;
  padding-bottom: 9px;
}

.ui.grid.Sidebar_itemGroupGrid__OUxvj {
  margin: 0;
  background: #f5efef;
  border: 1px solid #e3e1e2;
}

.ui.grid.Sidebar_itemGroupGrid__OUxvj + .Sidebar_itemGroupGrid__OUxvj {
  margin-top: 15px;
}

.ui.table.Sidebar_cropTable__2_rRX {
  background: none;
  border: none;
}

.ui.table.Sidebar_cropTable__2_rRX tbody tr td {
  border-color: var(--color-border-sui-light);
}

.ui.table.Sidebar_cropTable__2_rRX thead tr th,
.ui.table.Sidebar_cropTable__2_rRX tbody tr td {
  padding: 10px;
  background: none;
}

.ui.table.Sidebar_cropTable__2_rRX thead tr th {
  font-weight: normal;
  color: var(--color-header);
}

.ui.table.Sidebar_cropTable__2_rRX tbody tr:nth-child(2n + 1) {
  background: #fff;
}

.Sidebar_headerColumn__2pYtS {
  display: flex;
  white-space: nowrap;
}

.ui.header.Sidebar_name__Ndc8A {
  font-size: 28px;
  font-weight: 700;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__3uDDT {
  display: flex;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__3uDDT .item {
  flex: 1 1;
  display: block;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__3uDDT .item.active {
  border-color: var(--color-green);
  color: var(--color-green);
}

.icon.Sidebar_editButton__1Mpim {
  color: var(--color-green) !important;
}

.Sidebar_editButton__1Mpim {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}
.Sidebar_editButton__1Mpim i {
  position: absolute;
  bottom: 11px;
  right: 6px;
}

.Sidebar_closeButton__BP6ri i {
  height: 12px;
  width: 12px;
  position: absolute;
  bottom: 11px;
  right: 10px;
}

.icon.Sidebar_editButton__1Mpim:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.icon.Sidebar_closeButton__BP6ri:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__BP6ri {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.Sidebar_deleteButton__3WhJO {
  color: var(--color-error-delete) !important;
  width: 32px;
  height: 32px;
  background-color: transparent !important;
}

.Sidebar_deleteButton__3WhJO:hover {
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 20px;
}

.Sidebar_deleteButton__3WhJO i {
  position: absolute;
  bottom: 11px;
  right: 21px;
}

.ui.label.Sidebar_attachmentLabel__1V7LW {
  border-radius: 20px;
}

.Sidebar_infoContainer__igsn_ {
  padding: 0em 1em !important;
  overflow-y: auto;
  height: auto;
}

.Sidebar_infoContainer__igsn_.ui.grid {
  margin-top: 0px;
}

.Sidebar_infoContainer__igsn_.ui.grid > * {
  padding-right: 0em;
  padding-left: 0em;
}

.Sidebar_sidebarFooter__2dogk {
  display: flex;
  justify-content: end;
  margin-top: auto;
  padding: 16px 0 6px;
  border-top: 1px solid #dededf;
  background: #fff;
}

.ui.basic.green.button.Sidebar_actionButton__3ljxQ {
  height: 32px;
  display: flex;
  margin-right: 8px;
  font-weight: 500 !important;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.ui.basic.green.button.Sidebar_actionButton__3ljxQ:hover {
  color: #ffffff !important;
  background-color: #156941 !important;
}

.ui.basic.green.button.Sidebar_actionButton__3ljxQ:hover i > svg > path {
  fill: #ffffff !important;
}

.ui.basic.red.button.Sidebar_actionButton__3ljxQ {
  height: 32px;
  display: flex;
  align-items: baseline;
  font-weight: 500 !important;
  color: var(--color-error-delete) !important;
  box-shadow: 0 0 0 1px var(--color-error-delete) inset !important;
}

.ui.basic.red.button.Sidebar_actionButton__3ljxQ:hover {
  background-color: #8c0d24 !important;
  color: #ffffff !important;
}

.AddEditContractorModal_root__37jeJ :not(i.icon),
.AddEditContractorModal_root__37jeJ .ui.header {
  font-family: Roboto, sans-serif !important;
}

.AddEditContractorModal_modalHeader__3YwON {
  font-family: Roboto, sans-serif !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0;
  text-align: left;
}

.AddEditContractorModal_root__37jeJ .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.AddEditContractorModal_root__37jeJ .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.AddEditContractorModal_root__37jeJ .ui.grid {
  margin: 0;
}

.AddEditContractorModal_removeActiveIngredient__3g-Xl {
  color: #b00020;
  margin-right: 0 !important;
}

.AddEditContractorModal_textButton__ggpJp.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.AddEditContractorModal_modalButton__1Durw.ui.green.button {
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.AddEditContractorModal_modalButton__1Durw.ui.basic.green.button {
  background-color: #ffffff !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.AddEditContractorModal_modalButton__1Durw.ui.basic.green.button:hover {
  color: #ffffff !important;
  border: none;
}

.AddEditContractorModal_modalButton__1Durw.ui.green.button {
  background-color: var(--color-green) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.AddEditContractorModal_modalButton__1Durw.ui.green.button:hover {
  background-color: #156941 !important;
}

.AddEditContractorModal_modalButton__1Durw.ui.button:disabled {
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.AddEditContractorModal_textButtonContainer__3D2fB {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.AddEditContractorModal_activeIngredientMessage__1TtoE .ui.header {
  margin: 0 !important;
}

.AddEditContractorModal_attachmentLabel__3wGCw {
  margin-right: auto !important;
}

.AddEditContractorModal_generalAttachmentsContainer__1O35u {
  display: flex !important;
  flex-wrap: wrap;
}

.AddEditContractorModal_generalAttachmentsButton__hFANV {
  margin-left: auto !important;
}

.AddEditContractorModal_root__37jeJ .ui.form .field > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.AddEditContractorModal_root__37jeJ .AddEditContractorModal_chemicalPropertyColumn__3EOad {
  padding-left: 0 !important;
}

.AddEditContractorModal_root__37jeJ .AddEditContractorModal_chemicalPropertyColumn__3EOad .field {
  margin-bottom: 16px !important;
}

.AddEditContractorModal_errorMessage__2_5pt {
  margin-top: 6px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  white-space: normal;
  background: #ffffff !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
}

.AddEditContractorModal_modalContentContainer__3Jntt {
  min-height: 680px;
  scroll-behavior: smooth;
}

.ContractorRate_comment__3lpCo {
  width: 100%;
}

.ContractorRate_root__14Fi3 .ui.segment {
  border: 1px solid #dededf;
  box-shadow: none;
}

.ContractorRate_editButton__3lo7I,
.ContractorRate_deleteButton__QcHbN {
  cursor: pointer;
}

.ContractorRate_buttonContainer__SzLZ4 {
  display: flex;
  justify-content: end;
}

.ContractorRate_textButton__3N-83.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.ContractorRate_rateInfoText__2ab1J {
  margin-bottom: 10px;
}

.ContractorRate_textButtonContainer__1rky7 {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.ui.basic.green.button.ContractorRate_actionButton__znQW1 {
  font-weight: 500;
  height: 28px;
  display: flex;
  align-items: center;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.ContractorRate_currentRateContainer__3ewuE {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ContractorRate_currentRateContainer__3ewuE span {
  font-weight: 500;
}

.ContractorRate_medium__35uMR {
  font-weight: 400;
}

.ContractorRate_workersCount__1MM4r {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 12px;
  width: 100%;
}

.ContractorRate_workersDropdown__1t-qs .ui.dropdown {
  background-color: transparent;
}

.ContractorRate_workersDropdown__1t-qs a {
  display: none !important;
}

.ContractorRate_workerElementsContainer__2lH5F {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ContractorRate_workerElement__2V1nk {
  background: #f5f7f7;
  margin: 5px;
  height: 24px;
  width: auto;
  text-align: center;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 1px 8px 0 12px;
}

.ContractorRate_workerElement__2V1nk span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.ContractorRate_workerElement__2V1nk i {
  color: #666666;
  margin-left: 12px;
  cursor: pointer;
}

.ContractorRate_errorMessage__3PMg7 {
  margin-top: 6px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  white-space: normal;
  background: #ffffff !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
  padding: 0.5833em 0.833em;
  display: inline-block;
  font-family: "Roboto", sans-serif !important;
}

.WorkersPayroll_p1em__3Kupk {
  padding: 1em;
}

.WorkersPayroll_exportColumn__2l6nm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0;
  padding-top: 20px;
}

.WorkersPayroll_reportTypeSelector__3lT4S {
  max-width: 160px;
  margin-right: 20px;
}

.WorkersPayroll_exportToExcel__1U83G {
}

.ui.button.WorkersPayroll_refreshButton__4HMJA {
  margin-top: 24px;
}

.icon.WorkersPayroll_printButton__iXHda {
  margin-left: 15px;
  height: 100%;
  cursor: pointer;
}

.PrintReport_reportWrapper__1mZyZ {
  padding: 25px;
}

.PrintReport_reportHeaderRow__Vihie {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_reportHeader__1BOn0 {
  font-size: 16pt;
  margin-bottom: 20px;
}

.PrintReport_reportSubHeader__1KRsG {
  font-size: 11pt;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.PrintReport_tableElement__3qKL7 {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  margin-bottom: 50px !important;
}

.PrintReport_tableElement__3qKL7 tr td {
  border: none !important;
}

.PrintReport_tableElement__3qKL7 thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.9) !important;
  font-size: 8pt !important;
}

.PrintReport_tableElement__3qKL7 tbody tr td,
.PrintReport_reportCreatedRow__39k6K {
  font-size: 7pt !important;
}

.PrintReport_tableElement__3qKL7 tfoot tr th {
  font-size: 8pt !important;
  font-weight: bold !important;
}

.PrintReport_stripedRow__15fPk {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.ScanPackedBoxesForm_sprayGrid__27vXb {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.ScanPackedBoxesForm_sprayFormContainer__2qKv9 {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.ScanPackedBoxesForm_sprayFormFooter__30UR1 {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.ScanPackedBoxesForm_sprayFormContainer__2qKv9 {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.ScanPackedBoxesForm_sprayFormContainer__2qKv9 > div {
  max-width: 900px;
  margin: 0 auto;
}

.ScanPackedBoxesForm_inputHidden__13dAg {
  position: absolute;
}

.ScanPackedBoxesForm_label__30yDm {
  color: var(--color-dark);
}

.ScanPackedBoxesForm_scanButton__c1XKB {
  font-size: 25px !important;
  vertical-align: sub;
}

.ui.grid.ScanPackedBoxesForm_hide__2SNLi {
  display: none;
}

.ScanPackedBoxesForm_qrReader__2CqjJ {
  margin: 0.5rem auto;
}

@media only screen and (max-width: 767px) {
  .ScanPackedBoxesForm_sprayFormContainer__2qKv9 .ui.grid > .row > .column + .column {
    margin-top: 0;
  }
}

.BarcodesTable_sprayGrid__2C-_f {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.BarcodesTable_sprayFormContainer__2gY5X {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 {
  width: 100%;
  display: block;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 thead,
.ui.table.BarcodesTable_barcodesTable__1-A26 tbody {
  display: block;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 tbody:nth-child(2) {
  width: 100%;
  max-height: 172px;
  overflow: auto;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 thead tr,
.ui.table.BarcodesTable_barcodesTable__1-A26 tbody tr {
  display: flex;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 thead tr th:first-child,
.ui.table.BarcodesTable_barcodesTable__1-A26 tbody tr td:first-child {
  flex: 1 1 auto;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 tbody:nth-child(2) tr td div {
  float: left;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 tbody:nth-child(2) tr td:last-child {
  text-align: right;
  padding-right: 8px !important;
}

.ui.table.BarcodesTable_barcodesTable__1-A26 tbody:last-child tr:last-child td {
  display: block;
}

.BarcodesTable_barcodesRow__3oP08 td {
  background: var(--color-error) !important;
}

.BarcodesTable_newBarcode__1zku0 {
  width: 100%;
}
.ui.basic.table tbody:nth-child(3) tr {
  border: none;
}

@media only screen and (max-width: 767px) {
}

.PackedBoxesDetails_sprayGrid__2sXbd {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.PackedBoxesDetails_sprayFormContainer__1__LZ {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.PackedBoxesDetails_sprayFormFooter__23mBk {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.PackedBoxesDetails_sprayFormContainer__1__LZ {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.PackedBoxesDetails_sprayFormContainer__1__LZ > div {
  max-width: 900px;
  margin: 0 auto;
}

.PackedBoxesDetails_inputHidden__OyH1b {
  position: absolute;
}

.PackedBoxesDetails_label__1H8dS {
  color: var(--color-dark);
}

@media only screen and (max-width: 767px) {
  .PackedBoxesDetails_sprayFormContainer__1__LZ .ui.grid > .row > .column + .column {
    margin-top: 0;
  }
}

.PackedBoxesDetails_root__gcANs :not(i.icon),
.PackedBoxesDetails_root__gcANs .ui.header {
  font-family: Roboto, sans-serif !important;
}

.PackedBoxesDetails_root__gcANs .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}
.PackedBoxesDetails_root__gcANs .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
.ui.modal.PackedBoxesDetails_confirmModal__2qvB8 {
  margin: 30%;
}
.PackedBoxesDetails_root__gcANs .ui.grid {
  margin: 0;
}
.PackedBoxesDetails_root__gcANs .content > :not(:first-child) {
  margin-top: 16px !important;
}

.PackedBoxesDetails_modalContentContainer__QyUYJ {
  min-height: 70vh;
  scrollbar-width: none;
}

.PackedBoxesDetails_modalContentContainer__QyUYJ::-webkit-scrollbar {
  display: none;
}

.PackedBoxesDetails_modalHeader__3hQ-2 {
  display: flex !important;
  justify-content: space-between !important;
}

.PackedBoxesDetails_closeIcon__u0TEK {
  cursor: pointer;
}

.ui.dropdown.SelectArea_areasDropdown__2AEet {
  padding-right: 5em;
}

.ui.dropdown.SelectArea_areasDropdown__2AEet .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.SelectArea_areasDropdown__2AEet .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}
.ui.dropdown.SelectArea_areasDropdown__2AEet .item i.vacant {
  color: var(--color-vacant-blue);
}

.SelectBoxUnit_addButton__1BVHo {
  background: none !important;
  padding-left: 0 !important;
  width: 100% !important;
  display: flex !important;
}

.SelectBoxUnit_dropdown__bCRr3 {
  display: flex !important;
  align-items: center !important;
}

.ui.grid .row.CheckedInputRow_labelRow__y6yer {
  padding-bottom: 0;
}

.ui.grid .row.CheckedInputRow_inputRow__1OEmh {
  /*padding-top: 0;*/
}

.ui.grid .row .column.CheckedInputRow_childrenColumnWithCheckbox__2aKH9 {
  padding-left: 0;
}

.CheckedInputRow_disabled__2tG4r {
  pointer-events: none;
  opacity: 0.5;
}

.QrScanner_qrReader__OSuEB {
  margin: 0.5rem auto;
}

.ScanPackedBoxes_buttonsFooter__TBTta .ui.button {
  margin: 0;
}

.EditPackedBoxes_buttonsFooter___-zHV .ui.button {
  margin: 0;
}

.EditPackedBoxesFormContent_sprayGrid__3DAZf {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.EditPackedBoxesFormContent_sprayFormContainer__1pkeH {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.EditPackedBoxesFormContent_sprayFormFooter__7HrH4 {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.EditPackedBoxesFormContent_sprayFormContainer__1pkeH {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.EditPackedBoxesFormContent_sprayFormContainer__1pkeH > div {
  max-width: 900px;
  margin: 0 auto;
}

.EditPackedBoxesFormContent_inputHidden__4ueNG {
  position: absolute;
}

.EditPackedBoxesFormContent_label__16doj {
  color: var(--color-dark);
}

@media only screen and (max-width: 767px) {
  .EditPackedBoxesFormContent_sprayFormContainer__1pkeH .ui.grid > .row > .column + .column {
    margin-top: 0;
  }
}

.ui.header.Sidebar_name__nIIJ- {
  margin: 0 130px 0 0;
}

.ui.table.Sidebar_barcodesTable__1mHsJ {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.ui.table.Sidebar_barcodesTable__1mHsJ thead {
  /* head takes the height it requires,
  and it's not scaled when :global(.ui.table).barcodesTable is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
}

.ui.table.Sidebar_barcodesTable__1mHsJ tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  max-height: 172px;
  display: block;
  overflow-y: auto;
}

.ui.table.Sidebar_barcodesTable__1mHsJ tbody tr {
  width: 100%;
}

.ui.table.Sidebar_barcodesTable__1mHsJ thead,
.ui.table.Sidebar_barcodesTable__1mHsJ tbody tr {
  display: table;
  table-layout: fixed;
}

.ui.table.Sidebar_barcodesTable__1mHsJ thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

@media screen and (max-width: 767px) {
  .ui.table.Sidebar_barcodesTable__1mHsJ {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .ui.table.Sidebar_barcodesTable__1mHsJ {
    width: 70%;
  }
}

.Sidebar_btnWrapper__28X88 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__3wT0H {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__g_SXI {
  cursor: pointer !important;
}
.AddPallet_buttonsFooter__33GAy .ui.button {
  margin: 0;
}

.AddPalletForm_sprayGrid__q7fN5 {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.AddPalletForm_sprayFormContainer__3jFKg {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.AddPalletForm_sprayFormFooter__8Iu63 {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.AddPalletForm_sprayFormContainer__3jFKg {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.AddPalletForm_sprayFormContainer__3jFKg > div {
  max-width: 900px;
  margin: 0 auto;
}

.AddPalletForm_inputHidden__1Xsek {
  position: absolute;
}

.AddPalletForm_label__7GFGk {
  color: var(--color-dark);
}

@media only screen and (max-width: 767px) {
  .AddPalletForm_sprayFormContainer__3jFKg .ui.grid > .row > .column + .column {
    margin-top: 0;
  }
}

.ui.header.Sidebar_name__1puf7 {
  margin: 0 130px 0 0;
}

@media screen and (max-width: 767px) {}

@media screen and (min-width: 768px) {}

.Sidebar_btnWrapper__9lSuC {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__kXPv6 {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__2UqD5 {
  cursor: pointer !important;
}
.ui.header.AllocatePallets_name__2WnGv {
  margin: 0 130px 0 0;
}

.AllocatePallets_deleteButton__gJavx {
  position: absolute;
  top: 4px;
}

.AllocatePallets_addHolder__T4eSw.AllocatePallets_wide__u5cdl {
  text-align: center;
}

.ui.grid > .row.AllocatePallets_tableItem__3DfIm {
  padding-top: 5px;
  padding-bottom: 5px;
}

.AllocatePallets_inputHidden__1D1pl {
  display: none;
}
.AllocatePallets_inputHidden__1D1pl > .ui.label {
  white-space: nowrap !important;
}

@media screen and (max-width: 767px) {
}
@media screen and (min-width: 768px) {
}


.AddStockItem_buttonsFooter__3guY9 .ui.button {
  margin: 0;
}

.AddStockItemForm_sprayGrid__1UU8V {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.AddStockItemForm_sprayFormContainer__3wWsw {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.AddStockItemForm_sprayFormFooter__XkFJ0 {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.AddStockItemForm_sprayFormContainer__3wWsw {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.AddStockItemForm_sprayFormContainer__3wWsw > div {
  max-width: 900px;
  margin: 0 auto;
}

.AddStockItemForm_inputHidden__3L175 {
  position: absolute;
}

.AddStockItemForm_label__HINQW {
  color: var(--color-dark);
}

@media only screen and (max-width: 767px) {
  .AddStockItemForm_sprayFormContainer__3wWsw .ui.grid > .row > .column + .column {
    margin-top: 0;
  }
}

.ExpandedLocalDataTable_listTable___tVom .rt-table-holder {
}

:not(.ExpandedLocalDataTable_messageShow__1QEvn).ExpandedLocalDataTable_listTable___tVom .rt-table-holder {
  overflow: auto;
  min-height: 300px;
}

.ExpandedLocalDataTable_listTable___tVom .rt-table,
.ExpandedLocalDataTable_listTable___tVom .rt-tbody {
  overflow: visible;
}

.ExpandedLocalDataTable_listTable___tVom .rt-thead.-header {
  box-shadow: none;
}

.ExpandedLocalDataTable_listTable___tVom .rt-thead.-headerGroups + .rt-thead.-header {
  font-weight: bold;
}

.ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-tr-group {
  border-bottom: 1px solid var(--color-border);
}

.ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-tr {
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto !important;
}

.ExpandedLocalDataTable_-striped__1v7Un.ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-tr.-odd {
  background: var(--color-row-stripped);
}

.ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td {
  padding: 7px 5px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  flex: 100 0 auto;
  display: block !important;
}

.ExpandedLocalDataTable_listTable___tVom .rt-thead .rt-tr {
  border-bottom: 1px solid var(--color-border);
  width: auto !important;
}

.ExpandedLocalDataTable_listTable___tVom .rt-thead .rt-th {
  border: none;
  text-align: left;
  padding: 7px 5px;
  white-space: normal;
  color: var(--color-dark);
  font-size: 16px;
  flex: 100 0 auto;
  display: block !important;
}

.ExpandedLocalDataTable_listTable___tVom .rt-thead .rt-th.-sort-asc,
.ExpandedLocalDataTable_listTable___tVom .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
}

.ExpandedLocalDataTable_listTable___tVom .cellTitle {
  text-transform: uppercase;
  color: var(--color-header);
  font-size: 12px;
}

.ExpandedLocalDataTable_listTable___tVom .textRight {
  text-align: right;
}

.ExpandedLocalDataTable_listTable___tVom .actionBlock button {
  font-size: 18px;
  color: var(--color-icon);
}
.ExpandedLocalDataTable_listTable___tVom .actionBlock button .pencil {
  color: var(--color-green);
}

.ExpandedLocalDataTable_listTable___tVom .cellValue {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ExpandedLocalDataTable_barHolderIn__3Jt-a {
  display: flex;
  align-items: center;
}

.ExpandedLocalDataTable_barHolderIn__3Jt-a > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.ExpandedLocalDataTable_filtersHolder__p0dHm {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.ExpandedLocalDataTable_filterItem__2cQ3T {
  font-size: 16px !important;
  margin-right: 10px;
  margin-bottom: 5px;
}

.ui.selection.dropdown.ExpandedLocalDataTable_filterItem__2cQ3T {
  min-height: unset;
}

@media screen and (max-width: 767px) {
  .ui.grid > .row > .column.ExpandedLocalDataTable_sortColumn__9xlkY {
    padding-left: 0;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-thead,
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody {
    min-width: auto !important;
  }
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-tr {
    position: relative;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-thead.-headerGroups {
    display: none;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-thead.-header {
    display: none;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-thead.-headerGroups + .rt-thead.-header {
    display: flex;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_auto {
    width: auto !important;
  }
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_1 {
    width: 6.25% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_2 {
    width: 12.5% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_3 {
    width: 18.75% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_4 {
    width: 25% !important;
  }
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_5 {
    width: 31.25% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_6 {
    width: 37.5% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_7 {
    width: 43.75% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_8 {
    width: 50% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_9 {
    width: 56.25% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_10 {
    width: 62.5% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_11 {
    width: 68.75% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_12 {
    width: 75% !important;
  }
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_13 {
    width: 81.25% !important;
  }
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_14 {
    width: 87.5% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_15 {
    width: 93.75% !important;
  }

  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-td.cell_16 {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-tr {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .ExpandedLocalDataTable_listTable___tVom .rt-tbody .rt-tr:hover {
    background: var(--color-row-hover) !important;
  }
}

.ExpandedTable_resizer__2hO1U {
  display: inline-block;
  width: 36px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  touch-action: none;
  cursor: col-resize !important;
}

.ExpandedTable_groupedCell__2qSj5 {
  display: flex;
}

.ExpandedTable_groupNumberOfItems__2wc8l {
  padding-left: 7px;
}

:not(:last-child).ExpandedTable_resizer__2hO1U {
  transform: translateX(50%);
}

:last-child.ExpandedTable_resizer__2hO1U {
  width: 18px;
}

.ExpandedTable_messageHolder__2Toae {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.ui.header.ExpandedTablePrintLayout_title__OjNtl {
  text-align: center;
  margin: 10px;
  font-weight: bold;
}

.BatchEditPackedBoxes_buttonsFooter__MX2qE .ui.button {
  margin: 0;
}

.BatchEditPackedBoxes_confirmContent__1sdXv {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.BatchEditPackedBoxes_confirmContentLine__2qdIH {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.BatchEditPackedBoxes_bold__3kXz1 {
  font-weight: bold;
}

.ScannedGeneralBarcodesReport_filterBlock__2dvup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ui.button.ScannedGeneralBarcodesReport_exportButton__3XMNK > i.icon {
  position: relative;
  top: -1px;
  color: var(--color-green);
}

.NavigateBackButton_backIconWrapper__100ke {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.NavigateBackButton_backIcon__2PgkQ {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.ui.header.Sidebar_name__3-0XM {
  margin: 0 130px 15px 0;
}

@media screen and (max-width: 414px) {
  .ui.header.Sidebar_name__3-0XM {
    margin-right: 0;
  }
}

.Sidebar_btnWrapper__1D6GE {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__3v8st {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__3-eWY {
  cursor: pointer !important;
}
.AddVendor_buttonsFooter__1Ifhi .ui.button {
  margin: 0;
}

.VendorForm_sprayGrid__2D6il {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.VendorForm_sprayGrid__2D6il.VendorForm_forModal__1RBIt > div {
  padding: 0;
}

.VendorForm_sprayGrid__2D6il.VendorForm_forModal__1RBIt > div + div {
  margin-top: 15px;
}
.VendorForm_sprayGrid__2D6il.VendorForm_forModal__1RBIt .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.VendorForm_sprayGrid__2D6il:not(.VendorForm_forModal__1RBIt) .ui.segment.VendorForm_sprayTasksSidebarIn__2fvKD {
  flex: auto;
}

.VendorForm_sprayFormFooter__aYGBv {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.VendorForm_sprayFormContainer__bFbrw {
  overflow: auto;
  padding: 30px 15px;
}

.VendorForm_sprayFormContainer__bFbrw > div {
  max-width: 900px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
}

.ui.header.Sidebar_name__7t3rQ {
  margin: 0 130px 15px 0;
}

:not(.Sidebar_messageShow__278IC).Sidebar_listTable__17yz6 .rt-table-holder {
  overflow: auto;
  min-height: unset;
}

.Sidebar_listTable__17yz6 .rt-table .rt-tbody {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 305px;
}

@media screen and (max-width: 414px) {
  .ui.header.Sidebar_name__7t3rQ {
    margin-right: 0;
  }
}

.Sidebar_btnWrapper__321Dv {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

.Sidebar_wrapper__k6fMz {
  display: flex !important;
  justify-content: space-between !important;
}

.Sidebar_closeIcon__fD_mq {
  cursor: pointer !important;
}
.SelectPalletsTable_listTable__3N7El .rt-table-holder {
}

:not(.SelectPalletsTable_messageShow__2xdg8).SelectPalletsTable_listTable__3N7El .rt-table-holder {
  overflow: auto;
  min-height: 150px;
}

.SelectPalletsTable_listTable__3N7El .rt-table,
.SelectPalletsTable_listTable__3N7El .rt-tbody {
  overflow: visible;
}

.SelectPalletsTable_listTable__3N7El .rt-thead.-header {
  box-shadow: none;
}

.SelectPalletsTable_listTable__3N7El .rt-thead.-headerGroups + .rt-thead.-header {
  font-weight: bold;
}

.SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr-group {
  border-bottom: 1px solid var(--color-border);
}

.SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr {
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto !important;
}

.SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr.error,
.SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr.error:hover {
  background: var(--color-error) !important;
}

.SelectPalletsTable_-striped__aLB2m.SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr.-odd {
  background: var(--color-row-stripped);
}

.SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td {
  padding: 7px 5px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  flex: 100 0 auto;
  display: block !important;
}

.SelectPalletsTable_listTable__3N7El .rt-thead .rt-tr {
  border-bottom: 1px solid var(--color-border);
  width: auto !important;
}

.SelectPalletsTable_listTable__3N7El .rt-thead .rt-th {
  border: none;
  text-align: left;
  padding: 7px 5px;
  white-space: normal;
  color: var(--color-dark);
  font-size: 16px;
  flex: 100 0 auto;
  display: block !important;
}

.SelectPalletsTable_listTable__3N7El .rt-thead .rt-th.-sort-asc,
.SelectPalletsTable_listTable__3N7El .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
}

.SelectPalletsTable_listTable__3N7El .cellTitle {
  text-transform: uppercase;
  color: var(--color-header);
  font-size: 12px;
}

.SelectPalletsTable_listTable__3N7El .textRight {
  text-align: right;
}

.SelectPalletsTable_listTable__3N7El .actionBlock button {
  font-size: 18px;
  color: var(--color-icon);
}
.SelectPalletsTable_listTable__3N7El .actionBlock button .pencil {
  color: var(--color-green);
}

.SelectPalletsTable_listTable__3N7El .cellValue {
  overflow: hidden;
  text-overflow: ellipsis;
}

.SelectPalletsTable_barHolderIn__3e9rP {
  display: flex;
  align-items: center;
}

.SelectPalletsTable_barHolderIn__3e9rP > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.SelectPalletsTable_filtersHolder__2oiFc {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.SelectPalletsTable_filterItem__3kRK5 {
  font-size: 16px !important;
  margin-right: 10px;
  margin-bottom: 5px;
}

.ui.selection.dropdown.SelectPalletsTable_filterItem__3kRK5 {
  min-height: unset;
}

@media screen and (max-width: 767px) {
  .ui.grid > .row > .column.SelectPalletsTable_sortColumn__3-GNN {
    padding-left: 0;
  }

  .SelectPalletsTable_listTable__3N7El .rt-thead,
  .SelectPalletsTable_listTable__3N7El .rt-tbody {
    min-width: auto !important;
  }
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr {
    position: relative;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .SelectPalletsTable_listTable__3N7El .rt-thead.-headerGroups {
    display: none;
  }

  .SelectPalletsTable_listTable__3N7El .rt-thead.-header {
    display: none;
  }

  .SelectPalletsTable_listTable__3N7El .rt-thead.-headerGroups + .rt-thead.-header {
    display: flex;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_auto {
    width: auto !important;
  }
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_1 {
    width: 6.25% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_2 {
    width: 12.5% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_3 {
    width: 18.75% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_4 {
    width: 25% !important;
  }
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_5 {
    width: 31.25% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_6 {
    width: 37.5% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_7 {
    width: 43.75% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_8 {
    width: 50% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_9 {
    width: 56.25% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_10 {
    width: 62.5% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_11 {
    width: 68.75% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_12 {
    width: 75% !important;
  }
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_13 {
    width: 81.25% !important;
  }
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_14 {
    width: 87.5% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_15 {
    width: 93.75% !important;
  }

  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-td.cell_16 {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .SelectPalletsTable_listTable__3N7El .rt-tbody .rt-tr:hover {
    background: var(--color-row-hover) !important;
  }
}

.AddDispatch_buttonsFooter__21UoN .ui.button {
  margin: 0;
}

.DispatchForm_sprayGrid__22fd2 {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.DispatchForm_sprayGrid__22fd2.DispatchForm_forModal__3Di-6 > div {
  padding: 0;
}

.DispatchForm_sprayGrid__22fd2.DispatchForm_forModal__3Di-6 > div + div {
  margin-top: 15px;
}
.DispatchForm_sprayGrid__22fd2.DispatchForm_forModal__3Di-6 .ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column {
  margin-top: 1rem;
}

.DispatchForm_sprayGrid__22fd2:not(.DispatchForm_forModal__3Di-6) .ui.segment.DispatchForm_sprayTasksSidebarIn__3Xsuv {
  flex: auto;
}

.DispatchForm_sprayFormFooter__1Kr_M {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.DispatchForm_sprayFormContainer__393co {
  overflow: auto;
  padding: 30px 15px;
}

.DispatchForm_sprayFormContainer__393co > div {
  max-width: 1200px;
  margin: 0 auto;
}

.DispatchForm_scanButton__1YSkk {
  font-size: 25px !important;
  vertical-align: sub;
}

.DispatchForm_hide__2r_GK {
  display: none;
}

@media only screen and (max-width: 767px) {
}

.ui.button.LaborGroups_addButton__2iAgG {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ui.button.LaborGroups_addButton__2iAgG:hover {
  background-color: #156941 !important;
}

.LaborGroups_assignWorkersButton__2K0Md.ui.basic.green.button {
  font-weight: 500 !important;
}

.LaborGroups_assignWorkersButton__2K0Md.ui.basic.green.button:hover {
  background-color: #156941 !important;
  color: #ffffff !important;
  border: none;
}

.ui.basic.green.button.LaborGroups_cancelButton__2FTVc {
  color: var(--color-green) !important;
  margin-right: 10px;
}

.ui.basic.green.button.LaborGroups_actionButton__1EY3- {
  height: 32px;
  display: flex;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.LaborGroups_avatarsContainer__b4y0i {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.LaborGroups_avatarsContainer__b4y0i span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.LaborGroups_avatarHolder__bnXJT {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.LaborGroups_avatarsContainer__b4y0i .LaborGroups_avatarHolder__bnXJT:nth-child(n + 2) {
  margin-left: -10px;
}

.LaborGroups_morePopupContent__IJ0MT {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.LaborGroups_popupItem__zCxoV {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.LaborGroups_popupItem__zCxoV .LaborGroups_avatarHolder__bnXJT {
  height: 20px;
  min-width: 20px;
}

.LaborGroups_popupItem__zCxoV:nth-child(1) {
  margin-top: 0;
}

.LaborGroups_popupItem__zCxoV span {
  margin-left: 10px;
}

.LaborGroups_actionCells__2D_wW {
  overflow: initial !important;
}

.Sidebar_blockName__17SWf {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  margin: 10px 0;
}

.Sidebar_sectionTable__1okYA.ui.fixed.table td {
  height: 40px !important;
  padding-top: 9px;
  padding-bottom: 9px;
}

.Sidebar_sectionTable__1okYA {
  margin-top: 8px !important;
  margin-bottom: 2px !important;
}

.Sidebar_root__1EXaq {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

#labor_group_scroll_container {
  scroll-behavior: smooth;
}

.Sidebar_sidebarHeader__Y1e8r {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 10px;
}

.Sidebar_body__1wYX4 {
  flex: 0 1 100%;
  overflow: auto;
}

.ui.grid.Sidebar_itemGroupGrid__1fv3Z {
  margin: 0;
  background: #f5efef;
  border: 1px solid #e3e1e2;
}

.ui.grid.Sidebar_itemGroupGrid__1fv3Z + .Sidebar_itemGroupGrid__1fv3Z {
  margin-top: 15px;
}

.ui.table.Sidebar_cropTable__Kq7H8 {
  background: none;
  border: none;
}

.ui.table.Sidebar_cropTable__Kq7H8 tbody tr td {
  border-color: var(--color-border-sui-light);
}

.ui.table.Sidebar_cropTable__Kq7H8 thead tr th,
.ui.table.Sidebar_cropTable__Kq7H8 tbody tr td {
  padding: 10px;
  background: none;
}

.ui.table.Sidebar_cropTable__Kq7H8 thead tr th {
  font-weight: normal;
  color: var(--color-header);
}

.ui.table.Sidebar_cropTable__Kq7H8 tbody tr:nth-child(2n + 1) {
  background: #fff;
}

.Sidebar_headerColumn__3ZLPG {
  display: flex;
  white-space: nowrap;
}

.ui.header.Sidebar_name__2_I3D {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.Sidebar_sectionHeader__2Hglh {
  margin-top: 16px;
  margin-bottom: 0px;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__3skm0 {
  display: flex;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__3skm0 .item {
  flex: 1 1;
  display: block;
}

.ui.secondary.pointing.menu.Sidebar_tabsMenu__3skm0 .item.active {
  border-color: var(--color-green);
  color: var(--color-green);
}

.Sidebar_editButton__26r0Y {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.Sidebar_editButton__26r0Y i {
  position: absolute;
  bottom: 11px;
  right: 6px;
}

.Sidebar_closeButton__i1g4U i {
  height: 12px;
  width: 12px;
  position: absolute;
  bottom: 11px;
  right: 10px;
}

.icon.Sidebar_editButton__26r0Y:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.icon.Sidebar_closeButton__i1g4U:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__i1g4U {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.Sidebar_deleteButton__1lhJg {
  color: var(--color-error-delete) !important;
  width: 32px;
  height: 32px;
  background-color: transparent !important;
}

.Sidebar_deleteButton__1lhJg:hover {
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 20px;
}

.Sidebar_deleteButton__1lhJg i {
  position: absolute;
  bottom: 11px;
  right: 21px;
}

.ui.label.Sidebar_attachmentLabel__sDfIJ {
  border-radius: 20px;
}

.Sidebar_textButton__3TqXg.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.Sidebar_textButtonContainer__3z9KT {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.Sidebar_textButtonContainer__3z9KT .Sidebar_textButton__3TqXg:hover {
  box-shadow: none !important;
}

.Sidebar_textButtonContainer__3z9KT .Sidebar_textButton__3TqXg.ui.basic.button:hover {
  color: var(--color-background-green-medium) !important;
}

.Sidebar_assignWorkersContainer__1aNtG {
  padding-bottom: 40px !important;
  height: 360px;
}

.Sidebar_assignWorkersContainer__1aNtG .Sidebar_assignWorkersColumn__TFHsy {
  padding: 0 !important;
}

.Sidebar_formContainerSegment__2awcF {
  border: 1px solid #dededf !important;
  border-radius: 5px !important;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
}

.Sidebar_buttonContainer__2RX8I {
  margin-top: 15px;
  display: flex;
  justify-content: end;
}

.Sidebar_cancelButton__2F9sv {
  color: var(--color-error-delete) !important;
  margin-right: 15px !important;
}

.Sidebar_submitButton__3jUd9 {
  color: var(--color-green) !important;
  cursor: pointer;
}

.Sidebar_submitButton__3jUd9.ui.button:disabled {
  color: #b3b3b3 !important;
}

.Sidebar_employeeListItem__UueDo {
  margin-top: 10px;
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.Sidebar_employeeListItem__UueDo:nth-child(1) {
  margin-top: 0;
}

.Sidebar_workersCount__2EIhh {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 12px;
  width: 100%;
}

.Sidebar_workersDropdown__1oI_y .ui.dropdown {
  background-color: transparent;
}

.Sidebar_workersDropdown__1oI_y a {
  display: none !important;
}

.Sidebar_workerElementsContainer__2zwB- {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Sidebar_workerElement__37QA9 {
  background: #f5f7f7;
  margin: 5px;
  height: 24px;
  width: auto;
  text-align: center;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 0 8px 0 12px;
}

.Sidebar_workerElement__37QA9 span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.Sidebar_workerElement__37QA9 i {
  color: #666666;
  margin-left: 12px;
  cursor: pointer;
}

.Sidebar_infoContainer__2VJ_V {
  padding: 0em 1em !important;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox 64 */
  height: auto;
}

.Sidebar_infoContainer__2VJ_V::-webkit-scrollbar {
  display: none;
}

.Sidebar_infoContainer__2VJ_V.ui.grid {
  margin-bottom: 0;
}

.Sidebar_infoContainer__2VJ_V.ui.grid > * {
  padding-right: 0em;
  padding-left: 0em;
}

.Sidebar_sidebarFooter__2mxGo {
  display: flex;
  justify-content: end;
  margin-top: auto;
  padding: 16px 0 6px;
  border-top: 1px solid #dededf;
  background: #fff;
}

.ui.basic.green.button.Sidebar_actionButton__3ud8L {
  height: 32px;
  display: flex;
  margin-right: 8px;
  font-weight: 500 !important;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.ui.basic.green.button.Sidebar_actionButton__3ud8L:hover {
  color: #ffffff !important;
  background-color: #156941 !important;
}

.ui.basic.green.button.Sidebar_actionButton__3ud8L:hover i > svg > path {
  fill: #ffffff !important;
}

.ui.basic.red.button.Sidebar_actionButton__3ud8L {
  height: 32px;
  display: flex;
  align-items: baseline;
  font-weight: 500 !important;
  color: var(--color-error-delete) !important;
  box-shadow: 0 0 0 1px var(--color-error-delete) inset !important;
}

.ui.basic.red.button.Sidebar_actionButton__3ud8L:hover {
  background-color: #8c0d24 !important;
  color: #ffffff !important;
}

.AddUpdateGroupModal_root__3K5Jv :not(i.icon),
.AddUpdateGroupModal_root__3K5Jv .ui.header {
  font-family: Roboto, sans-serif !important;
}

.AddUpdateGroupModal_root__3K5Jv {
  width: 370px !important;
}

.AddUpdateGroupModal_modalHeader__i2HCB {
  font-family: Roboto, sans-serif !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0;
  text-align: center;
  padding: 20px 20px 16px 20px;
}

.ui.modal > .actions {
  background-color: transparent;
}

.ui.modal.AddUpdateGroupModal_root__3K5Jv {
  width: 400px !important;
}

.AddUpdateGroupModal_root__3K5Jv .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.AddUpdateGroupModal_root__3K5Jv .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.AddUpdateGroupModal_root__3K5Jv .ui.grid {
  margin: 0;
}

.AddUpdateGroupModal_minRateInput__3zYln {
  position: relative;
}

.AddUpdateGroupModal_workersCount__2perd {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 12px;
  width: 100%;
}

.AddUpdateGroupModal_workersCountEdit__3W4-4 {
  display: inline-block;
  position: absolute;
  top: 106px;
  left: 12px;
  width: 100%;
}

.AddUpdateGroupModal_workersDropdown__1pSE0 .ui.dropdown {
  background-color: transparent;
}

.AddUpdateGroupModal_workersDropdown__1pSE0 a {
  display: none !important;
}

.AddUpdateGroupModal_workerElementsContainer__UJFXm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-y: auto;
}

.AddUpdateGroupModal_workerElement__1ITmL {
  background: #f5f7f7;
  margin: 5px;
  height: 24px;
  width: auto;
  text-align: center;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 1px 8px 0 12px;
}

.AddUpdateGroupModal_workerElement__1ITmL span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.AddUpdateGroupModal_workerElement__1ITmL i {
  color: #666666;
  margin-left: 12px;
  cursor: pointer;
}

.AddUpdateGroupModal_cancelButton__3DVbt {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.AddUpdateGroupModal_cancelButton__3DVbt.ui.basic.green.button:hover {
  color: #ffffff !important;
  background-color: #156941 !important;
  border-color: transparent;
}

.AddUpdateGroupModal_saveButton__3CEsQ.ui.green.button {
  background-color: var(--color-green) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 63px;
  height: 36px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.AddUpdateGroupModal_saveButton__3CEsQ.ui.green.button:hover {
  background-color: #156941 !important;
}

.AddUpdateGroupModal_saveButton__3CEsQ.ui.button:disabled {
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.AddUpdateGroupModal_modalContentContainer__2LWA2 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 220px;
}

.ui.form .AddUpdateGroupModal_selectWorkersFields__3Gv78 {
  margin-bottom: 3px !important;
}

.SideBar_root__lZ4gz {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.SideBar_root__lZ4gz .ui.segment {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.SideBar_root__lZ4gz .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.SideBar_contentWrapper__1ojzO {
  padding-right: 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

.SideBar_sidebarHeader__3_XNp {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 16px !important;
}

.SideBar_headerColumn__1rYwj {
  display: flex;
}

.ui.header.SideBar_name__3P_0O {
  font-size: 28px;
  font-weight: 700;
}

.ui.header.SideBar_roundsName__2rEE5 {
  margin-top: 0px !important;
  font-size: 28px;
  font-weight: 700;
}

.SideBar_qcTable__3oHE4 {
  margin-left: 0 !important;
}

.SideBar_closeButton__HN6h8 i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.SideBar_closeButton__HN6h8:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.SideBar_closeButton__HN6h8 {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.SideBar_deleteButton__1pHwu i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #b00020 !important;
}

.icon.SideBar_deleteButton__1pHwu:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.SideBar_deleteButton__1pHwu {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
  cursor: pointer;
}

.SideBar_harvestUnitInformationWrapper__d0uOv {
  padding-top: 16px;
}

.SideBar_overAllSummaryWrapper__2odUD {
  padding-top: 32px;
}

.SideBar_roundsInfoWrapper__Vav2p {
  padding-top: 32px;
}

.SideBar_rowContainer__2QCtK {
  display: flex !important;
  align-items: center !important;
  height: 40px !important;
  padding: 0 !important;
}

.SideBar_nextStepBtn__17Ggd {
  background: none !important;
}

.SideBar_nextStepBtn__17Ggd i {
  font-size: 20px;
}

.SideBar_valueRow__GN5j6 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.SideBar_subRow__30PqO {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.SideBar_avatarsContainer__2ZYK_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.SideBar_avatarsContainer__2ZYK_ span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.SideBar_avatarHolder__2dnCe {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.SideBar_avatarsContainer__2ZYK_ .SideBar_avatarHolder__2dnCe:nth-child(n + 2) {
  margin-left: -10px;
}

.SideBar_imageContainer__26xJM {
  display: flex !important;
  align-items: center !important;
}

.SideBar_image__KKJgZ {
  width: 30px !important;
  height: 30px !important;
}

.SideBar_imageButton__2fuU_ {
  cursor: pointer;
  padding: 0 !important;
  background: none !important;
}

.SideBar_columnWrapper__1Q-3X {
  padding-right: 0px !important;
}

.SideBar_subSideBarheader__3D4eL {
  display: flex !important;
  flex-direction: row !important;
  justify-content: start !important;
  align-items: center !important;
}

.SideBar_backButton__Z4J1A {
  background: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}

.SideBar_backButton__Z4J1A i {
  font-size: 20px !important;
  display: flex;
  align-items: center;
}

.SideBar_headerWrapper__2bung {
  display: flex;
  padding-left: 38px !important;
  line-height: 15px;
  align-items: center;
}

.SideBar_countWrapper__2Eduf {
  display: flex;
  margin-left: 8px !important;
  justify-content: center;
  align-items: center;
  background: #f0ac47;
  border-radius: 10px;
  width: 18px !important;
  height: 18px;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.SideBar_editModalSegment__1jkQQ {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}

.SideBar_imageSegment__343jh {
  flex-wrap: wrap !important;
}

.SideBar_addImageContainer__wJy8i {
  width: 136px !important;
  height: 136px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px dashed #b3b3b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.SideBar_editButton__VtyaU {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
  cursor: pointer;
}

.SideBar_editButton__VtyaU i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 11px;
  right: 6px;
}

.icon.SideBar_editButton__VtyaU:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.SideBar_cancelBtn__3nAg_ {
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  background: none !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.SideBar_cancelBtn__3nAg_:hover {
  background-color: #076a3c !important;
  color: white !important;
}

.SideBar_deleteBtn__1rNpE {
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  background-color: #b00020 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: white !important;
}

.SideBar_deleteBtn__1rNpE:hover {
  background-color: #900720 !important;
}

.SideBar_modalContent__2qeN3 {
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.SideBar_disabledLabel__2zWkl {
  color: #b3b3b3 !important;
}

.SideBar_totalDisabledField__i1F_o {
  opacity: 1 !important;
}

.SideBar_buttonWrapper__2B2Dd {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
}

.SideBar_btnWrapper__ft9cH {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}

.SideBar_barcodesDropdown__2UyXu .default.text {
  color: #212121 !important;
}

.SideBar_addRoundBtnWrapper__2mlKE {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.SideBar_addBtn__2RI3A {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}

.SideBar_buttonContainer__TKglk {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}

.Pictures_editModalSegment__1OTFA {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}

.Pictures_editModalSegment__1OTFA {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}

.Pictures_imageSegment__1qiXC {
  display: flex;
  flex-wrap: wrap !important;
}

.Pictures_imageButton__1rbUf {
  cursor: pointer;
  padding: 0 !important;
  background: none !important;
}

.Pictures_addImageContainer__3BGvk {
  width: 136px !important;
  height: 136px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px dashed #b3b3b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.Pictures_deleteBtnContainer__lvF2w {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Pictures_deleteBtn__2BFf6 {
  background: none !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border: none !important;
  box-shadow: none !important;
  color: #b00020 !important;
}

.Pictures_btnWrapper__2aU3c {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}

.Parametrs_checkboxWrapper__2mtub {
  width: 100%;
  height: 50px;
  padding: 12px;
  border-radius: 4px !important;
  background-color: #f5f7f7;
  margin-top: 24px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Parametrs_checkboxLabel__2pkn3 {
  color: #212121;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.Parametrs_checkbox__2b9lB {
  display: flex !important;
}

.Parametrs_parametrsTable__Ahf0q {
  margin-left: 0 !important;
}

.Parametrs_parametrsHeader__1g40S {
  background: rgba(0, 0, 0, 0.03) !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Parametrs_parametrsValue__S1ZlA {
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Parametrs_rowWrapper__2NZsR {
  display: flex;
  align-items: center;
  margin-top: 24px;
  border: 1px solid #dededf;
  padding: 16px;
  grid-gap: 30px;
  gap: 30px;
}

.Parametrs_rowsWrapper__CGqPn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  border: 1px solid #dededf;
  padding: 16px;
}

.Parametrs_rowWrapperWithError__3KtyF {
  display: flex;
  width: 100%;
  margin-top: 24px;
  grid-gap: 30px;
  gap: 30px;
}

.Parametrs_formField__1lG5e {
  width: 50%;
}

.Parametrs_formFieldWrapper__1hw4t {
  display: flex;
  width: 100%;
  grid-gap: 14px;
  gap: 14px;
  margin-top: 16px;
}

.Parametrs_editName__1qW4F {
  color: #000000 !important;
  width: 50% !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 0 !important;
}

.Parametrs_parameterNumber__3ClPu {
  display: flex;
  justify-content: space-between;
  color: #000000 !important;
  width: 100% !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 0 !important;
}

.Parametrs_editNameWithDetails__2YCKd {
  display: flex;
  justify-content: space-between;
  color: #000000 !important;
  width: 100% !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 0 !important;
}

.Parametrs_totalField__2qIr0 {
  width: 100% !important;
}

.Parametrs_totalFieldWithDetails__1ZFJ4 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
}

.Parametrs_label__3zA-M {
  color: #666666;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.Parametrs_btnWrapper__xEsGq {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}

.Parametrs_deleteButton__2Daga i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #b00020 !important;
}

.icon.Parametrs_deleteButton__2Daga:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Parametrs_deleteButton__2Daga {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
  cursor: pointer;
}

.Parametrs_addBtnWrapper__1zyxO {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.Parametrs_addBtn__134rF {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}

.ui.modal.Parametrs_messageModal__106_B {
  width: 450px !important;
  overflow: auto;
}

.ui.modal.Parametrs_messageModal__106_B .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.ui.modal.Parametrs_messageModal__106_B .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.Parametrs_deleteBtn__3Yh95 {
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  background: #b00020 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Parametrs_deleteBtn__3Yh95:hover {
  background-color: #900720 !important;
  color: white !important;
}

.Parametrs_cancelBtn__1hPbF {
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  background: transparent !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Parametrs_cancelBtn__1hPbF:hover {
  background-color: #076a3c !important;
  color: white !important;
}

.Parametrs_btnContainer__T3ulU {
  height: 72px;
  border-top: 1px solid var(--color-border);
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.Workers_root__2zI-x {
  margin-top: 24px;
}

.Workers_container__1scSc {
  border-radius: 4px;
  border: 1px solid #dededf;
  padding: 16px;
  margin-bottom: 16px;
}

.Workers_workersTable__16ckR {
  margin-top: 24px !important;
  margin-left: 0px !important;
}

.Workers_deleteBtnWrapper__3n-MP {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px !important;
}

.Workers_selectWorkersField__LH4qi {
  padding: 8px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #dededf;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.Workers_selectWorkersField__LH4qi text {
  width: 90% !important;
  height: 25px !important;
}

.Workers_textWrapper__1sZeB {
  color: #000000 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_formField__2Ywzw {
  margin-bottom: 40px !important;
}

.Workers_deleteBtn__g40HQ {
  background: none !important;
  box-shadow: none !important;
  color: #b00020 !important;
}

.Workers_btnWrapper__1kM8O {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}

.Workers_modalMessageHeader__PT6Z6 {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}

.ui.modal.Workers_messageModal__-Ynek {
  width: 400px !important;
  overflow: auto;
}

.ui.modal.Workers_messageModal__-Ynek .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.ui.modal.Workers_messageModal__-Ynek .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.Workers_deleteCurrlButton__2EUlu {
  background: none !important;
  border: 1px solid #b00020 !important;
  color: #b00020 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_deleteCurrlButton__2EUlu:hover {
  background-color: #900720 !important;
  color: white !important;
}

.Workers_deleteAllButton__2tpc8 {
  background-color: #b00020 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_deleteAllButton__2tpc8:hover {
  background-color: #900720 !important;
}

.Workers_addBtnWrapper__1KT12 {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.Workers_addBtn__1pHp2 {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}

.Workers_addCurrButton__2nYDU {
  background: none !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_addCurrButton__2nYDU:hover {
  background-color: #076a3c !important;
  color: white !important;
}

.Workers_addAllButton__2rzrZ {
  background-color: #0e9354 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_addAllButton__2rzrZ:hover {
  background-color: #076a3c !important;
}

.Workers_nothingResultMessage__2rIbs {
  margin-top: 16px;
  font-family: Roboto !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.Tabs_body__TtJo- {
  flex: 0 1 100%;
  height: 75%;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 5px !important;
}

.Tabs_tabsHolder__23buO {
  width: 100%;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__1EHIr {
  display: flex;
  margin-top: 22px !important;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__1EHIr .item {
  text-align: center;
  flex: 1 1;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__1EHIr .item.active {
  text-align: center;
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__1EHIr {
  background: #fff;
  margin-bottom: 0;
}

.Tabs_tabItem__2dAar {
  overflow: hidden;
  height: 0;
}

.Tabs_tabItem__2dAar.Tabs_visibleTab__1Gs8T {
  height: auto;
  height: 100%;
}

.Tabs_undisplayedTab__1MRn6 {
  display: none;
}

.CustomSlider_pointNumberWrapper__2y1ud {
  width: 98.5%;
  height: 22px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.CustomSlider_pointNumber__2MnAn {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 1px;
  font-family: Roboto;
  color: grey;
}

.CustomSlider_customSlider__1G9iL {
  width: 97.5% !important;
  margin-left: 10px;
}

.rc-slider {
  width: 98% !important;
}

.rc-slider-rail {
  margin-top: 1px !important;
  background-color: #f6f7f8 !important;
  height: 8px !important;
}

.rc-slider-track {
  background-color: #0e9354 !important;
  height: 10px !important;
  top: 14% !important;
  left: -1% !important;
}

.rc-slider-dot {
  width: 8px !important;
  height: 8px !important;
  background-color: #6ebe98 !important;
  bottom: -5px !important;
  border: none !important;
}

.rc-slider-handle {
  margin-left: 2px !important;
  transform: translateX(-70%) !important;
  width: 36px !important;
  height: 36px !important;
  background-color: #0e9354 !important;
  box-shadow: 0px 16px 45px 0px #431faf38 !important;
  border: 3px solid white !important;
  margin-top: -18px !important;
  opacity: 1 !important;
}

.rc-slider-dot-active {
  display: none !important;
  /*   background-color: #0E9354;
  color: #0E9354; */
}

.rc-slider-step {
  width: 98% !important;
}

.Parametrs_rowWrapper__31PKT {
  margin-top: 24px;
  border: 1px solid #dededf;
  padding: 16px;
  grid-gap: 30px;
  gap: 30px;
}

.Parametrs_formField__2lwK5 {
  width: 50%;
}

.Parametrs_formFieldWrapper__3qLC5 {
  display: flex;
  width: 100%;
  grid-gap: 14px;
  gap: 14px;
  margin-top: 16px;
}

.Parametrs_totalField__Jo3RZ {
  width: 100% !important;
}

.Parametrs_label__1bGaY {
  color: #666666;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.Parametrs_btnWrapper__kvm4Z {
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.Tabs_body__3Ouvy {
  flex: 0 1 100%;
  height: 75%;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 5px !important;
}

.Tabs_tabsHolder__3wUhn {
  width: 100%;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__10Ggi {
  display: flex;
  margin-top: 22px !important;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__10Ggi .item {
  text-align: center;
  flex: 1 1;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__10Ggi .item.active {
  text-align: center;
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}

.ui.secondary.pointing.menu.Tabs_tabsMenu__10Ggi {
  background: #fff;
  margin-bottom: 0;
}

.Tabs_tabItem__24I_7 {
  overflow: hidden;
  height: 0;
}

.Tabs_tabItem__24I_7.Tabs_visibleTab__2HLwv {
  height: auto;
  height: 100%;
}

.Tabs_undisplayedTab__Km939 {
  display: none;
}

.Tabs_addBtnWrapper__2I0Ng {
  display: flex;
  justify-content: flex-end;
}

.Tabs_addBtn__dZjZr {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}

.Pictures_imageSegment__1XngX {
  display: flex;
  flex-wrap: wrap !important;
}

.Pictures_imageButton__3bQPz {
  cursor: pointer;
  padding: 0 !important;
  background: none !important;
}

.Pictures_addImageContainer__2aIuD {
  width: 136px !important;
  height: 136px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px dashed #b3b3b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.Pictures_deleteBtnContainer__3shvx {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Pictures_deleteBtn__3dAbk {
  background: none !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border: none !important;
  box-shadow: none !important;
  color: #b00020 !important;
}

.Workers_root__T9oRJ {
  margin-top: 24px;
}

.Workers_container__9tpaJ {
  border-radius: 4px;
  border: 1px solid #dededf;
  padding: 16px;
  margin-bottom: 16px;
}

.Workers_formField__2iwi1 {
  margin-bottom: 40px !important;
}

.Workers_selectWorkersField__9Cgeg {
  padding: 8px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #dededf;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.Workers_selectWorkersField__9Cgeg text {
  width: 90% !important;
  height: 25px !important;
}

.ui.modal.Workers_messageModal__1DMIG {
  width: 400px !important;
  overflow: auto;
}

.ui.modal.Workers_messageModal__1DMIG .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.ui.modal.Workers_messageModal__1DMIG .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.Workers_addCurrButton__3jKNC {
  background: none !important;
  border: 1px solid #0e9354 !important;
  color: #0e9354 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_addCurrButton__3jKNC:hover {
  background-color: #076a3c !important;
  color: white !important;
}

.Workers_addAllButton__2honk {
  background-color: #0e9354 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.Workers_addAllButton__2honk:hover {
  background-color: #076a3c !important;
}

.Workers_btnWrapper__DuhQM {
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  margin-bottom: 15px;
}

.ui.button.QualityControl_addButton__VvQLY {
  background-color: var(--color-green) !important;
}

.AddSideBar_root__3LD1U {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.AddSideBar_root__3LD1U .ui.segment {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.AddSideBar_root__3LD1U .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.AddSideBar_contentWrapper__G9Jip {
  height: 100%;
  padding-right: 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

.AddSideBar_sidebarHeader__wiBEK {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 16px !important;
}

.AddSideBar_headerColumn__1-C1Y {
  display: flex;
}

.ui.header.AddSideBar_name__h5pdQ {
  font-size: 28px;
  font-weight: 700;
}

.ui.header.AddSideBar_roundsName__3R1Pg {
  margin-top: 0px !important;
  font-size: 28px;
  font-weight: 700;
}

.AddSideBar_closeButton__217Mb i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.AddSideBar_closeButton__217Mb:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.AddSideBar_closeButton__217Mb {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.AddSideBar_overAllSummaryWrapper__IOZzn {
  padding-top: 32px;
}

.AddSideBar_roundsInfoWrapper__2aZJl {
  padding-top: 32px;
}

.AddSideBar_editModalSegment__3d9kc {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
}

.AddSideBar_subSideBarheader__1af69 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: start !important;
  align-items: center !important;
}

.AddSideBar_backButton__3wUiH {
  background: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}

.AddSideBar_backButton__3wUiH i {
  font-size: 20px !important;
  display: flex;
  align-items: center;
}

.AddSideBar_totalDisabledField__1M_9P {
  opacity: 1 !important;
}

.AddSideBar_disabledLabel__1ozfw {
  color: #b3b3b3 !important;
}

.AddSideBar_barcodesDropdown__1sTf_ .default.text {
  color: #212121 !important;
}

.AddSideBar_addRoundBtnWrapper__11siV {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.AddSideBar_addBtn__3vDFW {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}

.AddSideBar_buttonContainer__3sQJ0 {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: fixed;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
  width: 95%;
  margin-bottom: 15px;
}

.AddEditQCForm_overForm__1dUbA {
  height: 100%;
}

.AddEditQCForm_inputHidden__18NlH {
  height: 0;
}

.AddEditQCForm_accordionHolder__1PEGO {
  max-width: 900px;
  margin: 0 auto;
}

.AddEditQCForm_buttonsFooter__39iGG {
  text-align: right;
  padding: 5px 15px;
}

.AddEditQCForm_row__UZvlQ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.AddEditQCForm_row__UZvlQ .AddEditQCForm_ui__3KUN2.AddEditQCForm_input__25BRg {
  max-width: 300px;
}

.AddEditQCForm_minusButton__1mKzV {
  cursor: pointer;
  margin-left: 10px !important;
  border: 1px solid #9dacba;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: transparent;

  width: 40px;
  height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.AddEditQCForm_flexColumnCentered__2FRBa {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .AddEditQCForm_buttonsFooter__39iGG {
    padding: 0;
  }

  .AddEditQCForm_buttonsFooter__39iGG .ui.button {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .ui.grid.AddEditQCForm_gridAvatar__1TZKD > .row {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .AddEditQCForm_avatarHolder__iUbHO {
    position: absolute;
    left: 0;
    top: 1.3rem;
  }
}

.CostEntering_addButton__3zqvN {
  width: 125px !important;
  height: 36px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background-color: #0e9354 !important;
}

.CostEntering_addButton__3zqvN:hover {
  background-color: #156941 !important;
}

.CostEntering_addButton__3zqvN:active {
  background-color: #06331e !important;
}

.CostEntering_notesWrapper__16rY_ {
  width: 160px !important;
  padding: 0px !important;
  white-space: nowrap !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.CostEntering_scrollableTableWidth__qtjrT.ui.table {
  width: 100%;
}

.CostEntering_modalWrapper__3S8Zy {
  padding: 0px;
}

.CostEntering_commentContainer__2kvqy {
  padding: 10px !important;
  position: absolute;
  width: 200px;
  left: -160px;
  top: -30px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  word-break: break-all !important;
}
.CostEntering_iconWrapper__LvpDr {
  display: flex;
}

.CostEntering_deleteButtonWrapper__3WpHY {
  display: flex;
  width: 32px;
  margin-left: auto;
  margin-right: 20px;
}

.CostEntering_deleteButton__2S9hr {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: rgba(176, 0, 32, 1) !important;
  position: relative;
}

.CostEntering_deleteButton__2S9hr i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.CostEntering_deleteButton__2S9hr:hover {
  color: rgb(148, 7, 33) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ui.button.CostEntering_printButtonForPage__1Y0qO {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.CostEntering_printButtonForPage__1Y0qO:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.CostEntering_printButtonForPage__1Y0qO:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.CostEntering_printButtonForPage__1Y0qO:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.CostEntering_iconWrapperForPage__1n0hi {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.OperatingCost_addButton__18Wer {
  width: 125px !important;
  height: 36px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background-color: #0e9354 !important;
}

.OperatingCost_addButton__18Wer:hover {
  background-color: #156941 !important;
}

.OperatingCost_addButton__18Wer:active {
  background-color: #06331e !important;
}

.OperatingCost_notesWrapper__1r8G0 {
  width: 160px !important;
  padding: 0px !important;
  white-space: nowrap !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.OperatingCost_scrollableTableWidth__27IN_.ui.table {
  width: 100%;
}

.OperatingCost_modalWrapper__43Tkp {
  padding: 0px;
}

.OperatingCost_commentContainer__16uZf {
  padding: 10px !important;
  position: absolute;
  width: 200px;
  left: -160px;
  top: -30px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  word-break: break-all !important;
}
.OperatingCost_iconWrapper__2fg25 {
  display: flex;
}

.OperatingCost_deleteButtonWrapper__1Oj32 {
  display: flex;
  width: 32px;
  margin-left: auto;
  margin-right: 20px;
}

.OperatingCost_deleteButton__S0d3k {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: rgba(176, 0, 32, 1) !important;
  position: relative;
}

.OperatingCost_deleteButton__S0d3k i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.OperatingCost_deleteButton__S0d3k:hover {
  color: rgb(148, 7, 33) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ui.button.OperatingCost_printButtonForPage__19LQ9 {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.OperatingCost_printButtonForPage__19LQ9:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.OperatingCost_printButtonForPage__19LQ9:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.OperatingCost_printButtonForPage__19LQ9:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.OperatingCost_iconWrapperForPage__TC07B {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.AddCost_root__d-TV9 {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.AddCost_contentWrapper__1AhDk {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.AddCost_headerContainer__1KjLV {
  height: 7% !important;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.AddCost_header__2Ptic {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.AddCost_formContainer__34utQ {
  height: 70%;
  background-color: white;
  border-radius: 8px;
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding: 16px;
}

.AddCost_buttonContainer__NO3TK {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #ffffff;
  height: 8% !important;
  margin-top: 24px;
  position: fixed;
  width: 100% !important;
  z-index: 2;
  bottom: 5px;
}

.AddCost_buttonWrapper__1dwRf {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 16px;
  height: 5%;
}

.AddCost_cancelButton__235as {
  width: 84px !important;
  height: 36px;
  margin-right: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.AddCost_saveButton__2dhn0 {
  width: 71px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.NewCost_root__1oxTY {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
}

.NewCost_wrapperRow__2sOMO {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.NewCost_labelRow__FIzq0 {
  border-bottom: 1px solid #dededf;
  margin-bottom: 16px;
  width: 100%;
}

.NewCost_deleteButtonContainer__3v-zM {
  display: flex !important;
  justify-content: flex-end !important;
  margin-bottom: 14px !important;
}

.NewCost_newRowbuttonContainer__26DkB {
  margin-top: 16px;
  padding-bottom: 16px;
}

.NewCost_delete__kqIHg {
  width: 95px !important;
  height: 28px !important;
  color: rgba(176, 0, 32, 1) !important;
  border: 1px solid rgba(176, 0, 32, 1) !important;
  border-radius: 4px !important;
  background: none !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  cursor: pointer;
  padding: 0px !important;
  margin-left: 10px !important;
}

.NewCost_deleteButtonIconWrapper__3w91j {
  margin-top: 5px;
  width: 100% !important;
  height: 100% !important;
}

.NewCost_deleteButtonIcon__2bOQC {
  margin-right: 8px;
  margin-bottom: 6px !important;
}

.NewCost_formWrapper__15wSL {
  display: flex;
  align-items: center;
  height: 53px;
  border-bottom: 1px solid #dededf;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.NewCost_rowWrapper__1u2fk {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dededf;
  height: 53px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.ui.scrolling.dropdown {
  width: 100% !important;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}

.NewCost_dateSelectWrapper__3yQ7p {
  width: 262px;
  margin-right: 16px;
  position: absolute;
  top: 55px;
  position: absolute;
  z-index: 99999;
}

.NewCost_selectorBody__2rvie {
  padding: 16px;
  width: 100%;
  margin-right: 100px;
  height: 222px;
  background: #ffffff;
  border: 1px solid #dededf;
  border-radius: 4px;
}

.NewCost_selectorDateRangeContainer__2FBJ7 {
  margin-top: 8px;
}

.NewCost_dateValues__2EprJ {
  width: 80%;
}

.NewCost_dateSelectIcon__1aezk {
  border: none !important;
}

.NewCost_datePlaceholder__2ZFAw {
  display: flex !important;
  justify-content: start !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #b3b3b3 !important;
}

.NewCost_placeholder__2fePR {
  display: flex;
  justify-content: end;
  color: #dededf !important;
}

.NewCost_dateInput__2BH6N :active {
  border-right: none !important;
}

.NewCost_dateInput__2BH6N :focus {
  border-right: none !important;
}

.NewCost_dateInput__2BH6N i {
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding-right: 16px;
}

.NewCost_inputWrapper__3NyLT {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 22px;
  padding: 14px;
  font-size: 15px !important;
}

.NewCost_fromDate__V_yzU {
  margin-left: 8px !important;
}

.NewCost_calendareIcon__11M-b {
  padding-left: 8px;
  padding-bottom: 4px;
}

.NewCost_rdt__3lLyv {
  width: 45%;
}

.NewCost_rdt__3lLyv input,
.NewCost_rdt__3lLyv .NewCost_form-control__30CXy {
  width: 100%;
  color: transparent !important;
  background-color: transparent !important;
  border: none !important;
}

.NewCost_wrapper__vmQ2m {
  width: 100%;
  position: relative;
}

.NewCost_startDate__cYxQD {
  text-align: center;
  position: absolute;
  width: 90px;
  font-size: 14px !important;
  padding-right: 5px !important;
  top: 30px;
  left: 15px;
}

.NewCost_radioButtonContainer__3B-09 label {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #666666 !important;
}

.ui.radio input:checked ~ label::after {
  background-color: var(--color-green) !important;
}

.NewCost_endDate__1BsM8 {
  text-align: center;
  position: absolute;
  width: 100px;
  top: 30px;
  right: 15px;
}

.NewCost_radioButtonContainer__3B-09 {
  margin-bottom: 8px;
}

.NewCost_dateRangePicker__2KiYA {
  height: 40px;
  margin: 0;
  margin-top: 4px;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
}

.NewCost_dateFieldWrapper__2gbdH {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.NewCost_dateField__2P-yA {
  width: 100%;
  flex-direction: column-reverse;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.NewCost_costtypeLabel__1y_F2 {
  display: flex !important;
}

.NewCost_checkboxWrapper__2pW7n {
  padding-left: 5px !important;
  width: 13% !important;
  margin-right: 12px !important;
}

.NewCost_dateRangeLabel__15jln {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666 !important;
}

.NewCost_dateRangeLabelDisabled__2s0ee {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #dededf !important;
}

.NewCost_deleteIconWrapper__2-eZY {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}
.NewCost_copyIconWrapper__3TTzl {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}

.NewCost_deleteButton__2Kss7 {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.NewCost_deleteButton__2Kss7 i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
  color: #b00020;
}

.icon.NewCost_deleteButton__2Kss7:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.NewCost_copyButton__2fYf3 {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.NewCost_copyButton__2fYf3 i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
  color: var(--color-green) !important;
}

.icon.NewCost_copyButton__2fYf3:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.NewCost_page__2dCQu {
  display: flex;
}

.NewCost_hamburger__1yF3l {
  background-color: #222;
  width: 100px;
  height: 100px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.NewCost_hamburger__1yF3l .NewCost_line__nqDun {
  width: 70px;
  height: 10px;
  margin-left: 10px;
  background-color: #fff;
  pointer-events: none;
}

.NewCost_hamburger__1yF3l .NewCost_line__nqDun:not(:last-child) {
  margin-bottom: 10px;
}

.NewCost_menu__3Y1co {
  display: none;
  background-color: #222;
  color: #fff;
  padding: 5px 0;
}

.NewCost_menu__3Y1co .NewCost_item__3MHwb {
  padding: 5px 15px;
  cursor: pointer;
}

.NewCost_menu__3Y1co.NewCost_active__z-05L {
  display: inline-block;
}

.NewCost_placeholderDropdownTree__g_CsY {
  position: absolute;
  top: 29%;
  padding-left: 2px;
  left: 14%;
  color: rgba(191, 191, 191, 0.87) !important;
}

.NewCost_areasLabelDropdownTree__2d19c {
  position: absolute;
  top: 29%;
  padding-left: 2px !important;
  left: 13%;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}

.ui.button.NewCost_cancelDltBtn__3I-a1 {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.NewCost_deleteBtn__ddM7u {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.NewCost_deleteBtn__ddM7u:hover {
  background: #6b0518;
  border-radius: 4px;
  color: white;
}

.NewCost_disabledField__1eNyz {
  opacity: 1 !important;
  height: 39px;
  background-color: white !important;
  border: 1px solid #dededf !important;
  font-family: Roboto !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.NewCost_disabledField__1eNyz input {
  opacity: 1 !important;
  border: none !important;
  color: #212121 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.NewCost_removeCostEnteringType__T5Gaq {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
  margin-right: 10px !important;
}

.NewCost_costEnteringTypeSection__3_f-q {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.NewCost_costEnteringTypeHeader__3bXpw {
  margin-bottom: 0 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
}

.NewCost_costEnteringTypeWrapper__e2qRh {
  height: 40px !important;
}

.NewCost_costEnteringTypeField__3Uj6B div {
  border: none !important;
  padding-left: 0px !important;
}
.NewCost_costEnteringTypeField__3Uj6B div input {
  border: none !important;
  padding-left: 0px !important;
}

.NewCost_costEnteringTypesSection__7VB5u {
  margin-bottom: 0px !important;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7px !important;
}

.NewCost_datePeriod__3BR5O {
  padding-left: 0px !important;
}
.NewCost_notes__1QWIa {
  padding-left: 5px !important;
}
.NewCost_total__oIliJ {
  padding-left: 9px !important;
}
.NewCost_rate__Taxz- {
  padding-left: 10px !important;
}
.NewCost_units__uoe47 {
  padding-left: 10px !important;
}
.NewCost_type__3p-qY {
  padding-left: 10px !important;
}
.NewCost_costEnteringTypeField__3Uj6B .NewCost_ui__3Y6PB.NewCost_active__z-05L.NewCost_selection__2hmjF.NewCost_dropdown__3v7-b {
  height: 40px;
}
.NewCost_buttonContainer__2W9sE {
  display: flex;
}

.NewCost_gtId__2du2B i {
  z-index: 5;
}
.ui.scrolling.dropdown .menu {
  margin-top: 10px;
}

.ui.button.NewCost_importButtonForPage__1OK1P {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  font-family: "Roboto" !important;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.NewCost_importButtonForPage__1OK1P:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.NewCost_importButtonForPage__1OK1P:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.NewCost_importButtonForPage__1OK1P:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.NewCost_iconWrapperForPage__1N7M_ {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.DateRangePicker_inputHolder__1yi2g {
  background-color: white;
  position: relative;
  display: block;
}

.DateRangePicker_calendarHolder__oEmy- {
  background: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--color-border);
  width: 250px;
}

.DateRangePicker_calendarHolder__oEmy-.DateRangePicker_right__310nS {
  left: auto;
  right: 0;
}

.DateRangePicker_calendarHolder__oEmy- .react-calendar {
  border: none;
}

.DateRangePicker_calendarHolder__oEmy- .react-calendar .react-calendar__tile--active {
  background-color: var(--color-background-green);
}

.DateRangePicker_buttonHolder__3V5kv {
  text-align: center;
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.DateRangePicker_buttonHolder__3V5kv button:hover {
  color: var(--color-error-delete) !important;
}

.DateRangePicker_datePickerInput__23WPZ {
  height: 40px !important;
}

.DateRangePicker_datePickerInput__23WPZ input {
  font-size: 12px;
}

.AreasDropDownTree_searchInput__16c94 input {
  display: block !important;
  border: none !important;
  width: 200px !important;
  padding: 0 !important;
  padding-left: 0px !important;
}

.ui.dropdown .menu > .item:hover {
  background-color: #ffffff !important;
}

.AreasDropDownTree_areaSelector__3q7yG input {
  display: none !important;
}

.AreasDropDownTree_areaSelector__3q7yG {
  padding-bottom: 12px !important;
}

.ui.multiple.dropdown .dropdown.icon {
  padding-left: 60px !important;
}

.AreasDropDownTree_dropDownMenuSearch__3zWdF {
  padding: 0px !important;
}

.AreasDropDownTree_searchInput__16c94 {
  display: block !important;
  margin: 0px !important;
  padding: 5px;
  box-shadow: 0px -1px 3px 0px #dededf !important;
  border-top: 1px solid #dededf;
}

.ui.dropdown .menu >.AreasDropDownTree_searchInput__16c94 input {
  display: block !important;
  margin: 0px !important;
  padding: 5px !important;
  padding-left: 3px !important;
  border-bottom: none !important;
  border-top: 1px solid #dededf;
}

.AreasDropDownTree_areaSelector__3q7yG {
  padding-right: 10px !important;
  background: #ffffff !important;
  border-radius: 3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px !important;
  border: none !important;
  box-shadow: none !important;
}

/* :global(.ui.selection.active.dropdown .menu) {
  width: 250px !important;
  border-color: var(--color-background-green-light) !important;
  box-shadow: 0px 1px 2px 2px rgb(34 36 38 / 20%) !important;
  border: none !important;
} */

.AreasDropDownTree_ui__L1SpO.AreasDropDownTree_selection__1CrI1.AreasDropDownTree_dropdown__2d8Jc .AreasDropDownTree_menu__27oaV>.AreasDropDownTree_item__1UtVE:hover {
  background-color: #ffffff !important;
}

.AreasDropDownTree_areaSelectorMenu__1W8_l {
  max-height: inherit !important;
  overflow: hidden !important;
  height: auto !important;
  border-color: #dededf !important;
  border: 1px solid #dededf !important;
}

.AreasDropDownTree_dropDownMenuSelectAllItem__1Sj1k {
  box-shadow: 1px solid #dededf !important;
}

.AreasDropDownTree_areaSelectorMenu__1W8_l :hover {
  /* border-bottom: 1px solid #dededf !important; */
}

.AreasDropDownTree_areaDropDownScrollingMenu__1hriP {
  padding: 5px !important;
}

.AreasDropDownTree_addItem__2pWnx {
  max-width: 300px;
  height: 29px !important;
  background: #fdfdfd !important;
  border: none !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #dededf !important;
  border-radius: 24px !important;
  font-size: 14px !important;
  min-height: 30px;
  vertical-align: middle !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
  padding-top: 2px !important;
  padding-left: 8px !important;
  padding-right: 11px !important;
}

.AreasDropDownTree_addItem__2pWnx i {
  color: #b00020 !important;
  cursor: pointer !important;
  padding-left: 20px !important;
}

.AreasDropDownTree_labelContainer__2TkL0 {
  max-width: 320px;
  white-space: initial !important;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  margin: 0px !important;
  padding-left: 10px !important;
  border-bottom: none !important;
}

.AreasDropDownTree_addButtonWrapper__175VV {
  padding: 0px !important;
}

.AreasDropDownTree_addButton__CMHPV {
  display: flex !important;
  font-weight: 400px !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
  font-size: 14px !important;
}

.AreasDropDownTree_addButton__CMHPV:hover {
  background-color: #ebf6e9 !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
}

.AreasDropDownTree_addButtonBatchNumber__24RL- {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600px !important;
}

.AreasDropDownTree_itemWrapper__JKkEM {
  display: flex !important;
  align-items: center;
}

.AreasDropDownTree_childrenWrapper__17UGX {
  padding-left: 35px;
  display: flex;
}

.AreasDropDownTree_childrenWrapperWithArrow__3NtHu {
  padding-left: 19px;
  display: flex;
  align-items: center;
}

.AreasDropDownTree_subChildrenWrapper__2W0La {
  padding-left: 55px;
}

.AreasDropDownTree_farmItem__10eBw {
  padding: 2px !important;
}

.AreasDropDownTree_blockItem__2shG9 {
  padding: 2px !important;
}

.AreasDropDownTree_allNoneButton__2Elez {
  background: none !important;
  width: auto !important;
  color: green !important;
  padding: 5px !important;
}

.AreasDropDownTree_areasLabelDropdownTree__FvpWY {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}
.AddCost_root__2IDj1 {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.AddCost_contentWrapper__1Rn2C {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.AddCost_headerContainer__3TMYw {
  height: 7% !important;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.AddCost_header__3k0nY {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.AddCost_formContainer__185Es {
  height: 70%;
  background-color: white;
  border-radius: 8px;
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding: 16px;
}

.AddCost_buttonContainer__2qNAT {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #ffffff;
  height: 8% !important;
  margin-top: 24px;
  position: fixed;
  width: 100% !important;
  z-index: 2;
  bottom: 5px;
}

.AddCost_buttonWrapper__1G6uC {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 16px;
  height: 5%;
}

.AddCost_cancelButton__3V-l8 {
  width: 84px !important;
  height: 36px;
  margin-right: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.AddCost_saveButton__1fboJ {
  width: 71px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.NewCost_root__1RnwW {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
}

.NewCost_wrapperRow__1uc7Z {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.NewCost_labelRow__3HwkQ {
  border-bottom: 1px solid #dededf;
  margin-bottom: 16px;
  width: 100%;
}

.NewCost_deleteButtonContainer__byesi {
  display: flex !important;
  justify-content: flex-end !important;
  margin-bottom: 14px !important;
}

.NewCost_newRowbuttonContainer___msFH {
  margin-top: 16px;
  padding-bottom: 16px;
}

.NewCost_delete__7pdMs {
  width: 95px !important;
  height: 28px !important;
  color: rgba(176, 0, 32, 1) !important;
  border: 1px solid rgba(176, 0, 32, 1) !important;
  border-radius: 4px !important;
  background: none !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  cursor: pointer;
  padding: 0px !important;
  margin-left: 10px !important;
}

.NewCost_deleteButtonIconWrapper__1l5-c {
  margin-top: 5px;
  width: 100% !important;
  height: 100% !important;
}

.NewCost_deleteButtonIcon__3zqzw {
  margin-right: 8px;
  margin-bottom: 6px !important;
}

.NewCost_formWrapper__3M49C {
  display: flex;
  align-items: center;
  height: 53px;
  border-bottom: 1px solid #dededf;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.NewCost_rowWrapper__1-GI2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dededf;
  height: 53px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.ui.scrolling.dropdown {
  width: 100% !important;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}

.NewCost_dateSelectWrapper__2DUKc {
  width: 262px;
  margin-right: 16px;
  position: absolute;
  top: 55px;
  position: absolute;
  z-index: 99999;
}

.NewCost_selectorBody__2tzbf {
  padding: 16px;
  width: 100%;
  margin-right: 100px;
  height: 222px;
  background: #ffffff;
  border: 1px solid #dededf;
  border-radius: 4px;
}

.NewCost_selectorDateRangeContainer__3yAxN {
  margin-top: 8px;
}

.NewCost_dateValues__3ru6H {
  width: 80%;
}

.NewCost_dateSelectIcon__370nd {
  border: none !important;
}

.NewCost_datePlaceholder__3hLoo {
  display: flex !important;
  justify-content: start !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #b3b3b3 !important;
}

.NewCost_placeholder__2ZkYB {
  display: flex;
  justify-content: end;
  color: #dededf !important;
}

.NewCost_dateInput__1tjxD :active {
  border-right: none !important;
}

.NewCost_dateInput__1tjxD :focus {
  border-right: none !important;
}

.NewCost_dateInput__1tjxD i {
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding-right: 16px;
}

.NewCost_inputWrapper__JESQl {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 22px;
  padding: 14px;
  font-size: 15px !important;
}

.NewCost_fromDate__TcBwx {
  margin-left: 8px !important;
}

.NewCost_calendareIcon__mpKLE {
  padding-left: 8px;
  padding-bottom: 4px;
}

.NewCost_rdt__-EuL9 {
  width: 45%;
}

.NewCost_rdt__-EuL9 input,
.NewCost_rdt__-EuL9 .NewCost_form-control__ZQhPD {
  width: 100%;
  color: transparent !important;
  background-color: transparent !important;
  border: none !important;
}

.NewCost_wrapper__1IoOO {
  width: 100%;
  position: relative;
}

.NewCost_startDate__2EM4a {
  text-align: center;
  position: absolute;
  width: 90px;
  font-size: 14px !important;
  padding-right: 5px !important;
  top: 30px;
  left: 15px;
}

.NewCost_radioButtonContainer__2M2lN label {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #666666 !important;
}

.ui.radio input:checked ~ label::after {
  background-color: var(--color-green) !important;
}

.NewCost_endDate__1eCpk {
  text-align: center;
  position: absolute;
  width: 100px;
  top: 30px;
  right: 15px;
}

.NewCost_radioButtonContainer__2M2lN {
  margin-bottom: 8px;
}

.NewCost_dateRangePicker__3YN5A {
  height: 40px;
  margin: 0;
  margin-top: 4px;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
}

.NewCost_dateFieldWrapper__2CutP {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.NewCost_dateField__Gr7vS {
  width: 100%;
  flex-direction: column-reverse;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.NewCost_costtypeLabel__3CRFY {
  display: flex !important;
}

.NewCost_checkboxWrapper__21Un- {
  padding-left: 5px !important;
  width: 13% !important;
  margin-right: 12px !important;
}

.NewCost_dateRangeLabel__10NQd {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666 !important;
}

.NewCost_dateRangeLabelDisabled__1Q6Am {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #dededf !important;
}

.NewCost_deleteIconWrapper__1TMOu {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}
.NewCost_copyIconWrapper__pHggm {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}

.NewCost_deleteButton__1o9rN {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.NewCost_deleteButton__1o9rN i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
  color: #b00020;
}

.icon.NewCost_deleteButton__1o9rN:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.NewCost_copyButton__BU3YM {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.NewCost_copyButton__BU3YM i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
  color: var(--color-green) !important;
}

.icon.NewCost_copyButton__BU3YM:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.NewCost_page__20AIO {
  display: flex;
}

.NewCost_hamburger__2gwvG {
  background-color: #222;
  width: 100px;
  height: 100px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.NewCost_hamburger__2gwvG .NewCost_line__yXQ2k {
  width: 70px;
  height: 10px;
  margin-left: 10px;
  background-color: #fff;
  pointer-events: none;
}

.NewCost_hamburger__2gwvG .NewCost_line__yXQ2k:not(:last-child) {
  margin-bottom: 10px;
}

.NewCost_menu__b5gVV {
  display: none;
  background-color: #222;
  color: #fff;
  padding: 5px 0;
}

.NewCost_menu__b5gVV .NewCost_item__1KKb0 {
  padding: 5px 15px;
  cursor: pointer;
}

.NewCost_menu__b5gVV.NewCost_active__ypgbK {
  display: inline-block;
}

.NewCost_placeholderDropdownTree__z-o_I {
  position: absolute;
  top: 29%;
  padding-left: 2px;
  left: 14%;
  color: rgba(191, 191, 191, 0.87) !important;
}

.NewCost_areasLabelDropdownTree__2dgry {
  position: absolute;
  top: 29%;
  padding-left: 2px !important;
  left: 13%;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}

.ui.button.NewCost_cancelDltBtn__Ar8rr {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.ui.button.NewCost_deleteBtn__fzFkU {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

.ui.button.NewCost_deleteBtn__fzFkU:hover {
  background: #6b0518;
  border-radius: 4px;
  color: white;
}

.NewCost_disabledField__3ERak {
  opacity: 1 !important;
  height: 39px;
  background-color: white !important;
  border: 1px solid #dededf !important;
  font-family: Roboto !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.NewCost_disabledField__3ERak input {
  opacity: 1 !important;
  border: none !important;
  color: #212121 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.NewCost_removeCostEnteringType__3RfIB {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
  margin-right: 10px !important;
}

.NewCost_costEnteringTypeSection__1T1of {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.NewCost_costEnteringTypeHeader__3T1q7 {
  margin-bottom: 0 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
}

.NewCost_costEnteringTypeWrapper__3YLRV {
  height: 40px !important;
}

.NewCost_costEnteringTypeField__3PBnM div {
  border: none !important;
  padding-left: 0px !important;
}
.NewCost_costEnteringTypeField__3PBnM div input {
  border: none !important;
  padding-left: 0px !important;
}

.NewCost_costEnteringTypesSection__3h0H- {
  margin-bottom: 0px !important;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7px !important;
}

.NewCost_datePeriod__39k8q {
  padding-left: 0px !important;
}
.NewCost_notes__rwfV_ {
  padding-left: 5px !important;
}
.NewCost_total__2NquH {
  padding-left: 9px !important;
}
.NewCost_rate__1hrZf {
  padding-left: 10px !important;
}
.NewCost_units__1EJoc {
  padding-left: 10px !important;
}
.NewCost_type__2i2WT {
  padding-left: 10px !important;
}
.NewCost_costEnteringTypeField__3PBnM .NewCost_ui__mDd3E.NewCost_active__ypgbK.NewCost_selection__G1Hyn.NewCost_dropdown__3rq5M {
  height: 40px;
}
.NewCost_buttonContainer__2xBZJ {
  display: flex;
}

.ui.button.NewCost_importButtonForPage__loiEZ {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  font-family: "Roboto" !important;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.NewCost_importButtonForPage__loiEZ:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.NewCost_importButtonForPage__loiEZ:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.NewCost_importButtonForPage__loiEZ:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.NewCost_iconWrapperForPage__3WeGP {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.AreasDropDownTree_searchInput__FTc6q input {
  display: block !important;
  border: none !important;
  width: 200px !important;
  padding: 0 !important;
  padding-left: 0px !important;
}

.ui.dropdown .menu > .item:hover {
  background-color: #ffffff !important;
}

.AreasDropDownTree_areaSelector__2lhH7 input {
  display: none !important;
}

.AreasDropDownTree_areaSelector__2lhH7 {
  padding-bottom: 12px !important;
}

.ui.multiple.dropdown .dropdown.icon {
  padding-left: 60px !important;
}

.AreasDropDownTree_dropDownMenuSearch__3qONL {
  padding: 0px !important;
}

.AreasDropDownTree_searchInput__FTc6q {
  display: block !important;
  margin: 0px !important;
  padding: 5px;
  box-shadow: 0px -1px 3px 0px #dededf !important;
  border-top: 1px solid #dededf;
}

.ui.dropdown .menu >.AreasDropDownTree_searchInput__FTc6q input {
  display: block !important;
  margin: 0px !important;
  padding: 5px !important;
  padding-left: 3px !important;
  border-bottom: none !important;
  border-top: 1px solid #dededf;
}

.AreasDropDownTree_areaSelector__2lhH7 {
  padding-right: 10px !important;
  background: #ffffff !important;
  border-radius: 3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px !important;
  border: none !important;
  box-shadow: none !important;
}

/* :global(.ui.selection.active.dropdown .menu) {
  width: 250px !important;
  border-color: var(--color-background-green-light) !important;
  box-shadow: 0px 1px 2px 2px rgb(34 36 38 / 20%) !important;
  border: none !important;
} */

.AreasDropDownTree_ui__1zi-f.AreasDropDownTree_selection__2pT4l.AreasDropDownTree_dropdown__10KTv .AreasDropDownTree_menu__3PIH1>.AreasDropDownTree_item__3P4XL:hover {
  background-color: #ffffff !important;
}

.AreasDropDownTree_areaSelectorMenu__1htnE {
  max-height: inherit !important;
  overflow: hidden !important;
  height: auto !important;
  border-color: #dededf !important;
  border: 1px solid #dededf !important;
}

.AreasDropDownTree_dropDownMenuSelectAllItem__2O_yh {
  box-shadow: 1px solid #dededf !important;
}

.AreasDropDownTree_areaSelectorMenu__1htnE :hover {
  /* border-bottom: 1px solid #dededf !important; */
}

.AreasDropDownTree_areaDropDownScrollingMenu__2qkrp {
  padding: 5px !important;
}

.AreasDropDownTree_addItem__CtRo1 {
  max-width: 300px;
  height: 29px !important;
  background: #fdfdfd !important;
  border: none !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #dededf !important;
  border-radius: 24px !important;
  font-size: 14px !important;
  min-height: 30px;
  vertical-align: middle !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
  padding-top: 2px !important;
  padding-left: 8px !important;
  padding-right: 11px !important;
}

.AreasDropDownTree_addItem__CtRo1 i {
  color: #b00020 !important;
  cursor: pointer !important;
  padding-left: 20px !important;
}

.AreasDropDownTree_labelContainer__1F2Tu {
  max-width: 320px;
  white-space: initial !important;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  margin: 0px !important;
  padding-left: 10px !important;
  border-bottom: none !important;
}

.AreasDropDownTree_addButtonWrapper__2Pp1V {
  padding: 0px !important;
}

.AreasDropDownTree_addButton__c1END {
  display: flex !important;
  font-weight: 400px !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
  font-size: 14px !important;
}

.AreasDropDownTree_addButton__c1END:hover {
  background-color: #ebf6e9 !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
}

.AreasDropDownTree_addButtonBatchNumber__3kjAF {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600px !important;
}

.AreasDropDownTree_itemWrapper__c3chw {
  display: flex !important;
  align-items: center;
}

.AreasDropDownTree_childrenWrapper__1cILl {
  padding-left: 35px;
  display: flex;
}

.AreasDropDownTree_childrenWrapperWithArrow__33FpY {
  padding-left: 19px;
  display: flex;
  align-items: center;
}

.AreasDropDownTree_subChildrenWrapper__1HElZ {
  padding-left: 55px;
}

.AreasDropDownTree_farmItem__aNdD3 {
  padding: 2px !important;
}

.AreasDropDownTree_blockItem__3yAk7 {
  padding: 2px !important;
}

.AreasDropDownTree_allNoneButton__7EpNL {
  background: none !important;
  width: auto !important;
  color: green !important;
  padding: 5px !important;
}

.AreasDropDownTree_areasLabelDropdownTree__1uhGF {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}
.styles_taskContainer__1gxdR {
  margin-top: 32px;
}

.styles_creationOptions__2_82d {
  width: 100%;

  border-bottom: 2px solid #e0e2e4;
}

.styles_creationOption__3KauI {
  padding: 0 8px 10px 8px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-chapter);

  font-family: Montserrat, sans-serif;

  background: none;

  margin-right: 20px;

  border: none;
  border-bottom: solid 4px transparent;
}

.styles_creationOption__3KauI.styles_active__W4rnx {
  border-color: var(--color-green);
  font-weight: 600;
}

.ui.segment.styles_taskTypesHolder__YqEZj {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  background-color: transparent;
}

.styles_taskCard__QRwQZ {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);

  background-color: #ffffff;

  margin: 0 24px 24px 0;
  padding: 28px 33px;

  text-align: center;
}

.styles_taskCard__QRwQZ:hover {
  cursor: pointer;
  background-color: #2bb673;
  color: white;
}

.styles_taskCard__QRwQZ img {
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: white;
}

.styles_taskCard__QRwQZ .styles_caption__33ZT1 {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: var(--color-text);

  width: 120px;
}

.styles_taskCard__QRwQZ:hover .styles_caption__33ZT1 {
  color: white;
}

.ui.header.styles_heading__2-KNB {
  margin-bottom: 20px;
}

.styles_indicator__3WcFi {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 203px;
}

.styles_digit__1mUEh {
  text-align: center;
  line-height: 24px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-bottom: 12px;
  border: 4px solid var(--color-icon);

  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--color-icon);
}

.styles_title__HpAj4 {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  text-align: center;
  color: var(--color-icon);
}

/* IN PROGRESS */

.styles_inProgress__1W03D .styles_digit__1mUEh {
  border-style: dotted;
}

/* EMPTY */

.styles_empty__18XYL .styles_digit__1mUEh {
  background-color: var(--color-icon);
  color: var(--color-background);
  border-style: solid;
}

/* DONE */

.styles_done__1QRB6 .styles_digit__1mUEh {
  border-style: solid;
  background-color: var(--color-green);
  border-color: var(--color-green);
  color: white;
}

.styles_done__1QRB6 .styles_title__HpAj4 {
  color: var(--color-dark);
}

.styles_wrapper__V1miF {
  width: calc(100% - 15px);
  padding: 0 15px 0 30px;
}

.styles_indicatorHolder__1cP5b {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ui.segment.SegmentClosableControlled_segmentHolder__Uey_r {
  padding-top: 20px;
  padding-bottom: 20px;
}

.SegmentClosableControlled_headerHolder__2vFJq {
  display: flex;
}

.SegmentClosableControlled_chevron__kdTCJ {
  width: 20px;
  height: 20px;
  font-size: 21px;
  display: inline-block;
  color: var(--color-icon);
  margin: 3px 20px 0 0;
  cursor: pointer;
}

.SegmentClosableControlled_chevron__kdTCJ:hover {
  color: var(--color-green);
}

.ui.header.SegmentClosableControlled_headerTitle__h22ok {
  margin: 0;
  line-height: 1;
}

.SegmentClosableControlled_childrenHolder__i9Jxk {
  margin-top: 1rem;
}

.SegmentClosableControlled_childrenHolder__i9Jxk > .ui.grid:first-child {
  margin-top: -0.5rem !important;
}

.SegmentClosableControlled_childrenHolder__i9Jxk .ui.grid + .ui.grid {
  margin-top: 0;
}

.ui.grid.styles_formPadding__3s2Eg {
  padding: 0 50px 0 40px;
}

.ui.button.styles_nextButton__1IQO0 {
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;

  margin-left: 10px;
}

.styles_fieldTitle__11lZ1 {
  color: #677c8c;
}

.styles_fieldValue__26MLV {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  /*word-wrap: break-word;*/
}

.styles_fieldValueLong__2gOqP {
  display: block;
  width: 100%;
  /*text-overflow: ellipsis;*/
  /*overflow: hidden;*/
  word-wrap: break-word;
}

.styles_farmfield__3vwsb {
  width: 100%;
  white-space: pre-line !important;
}

.styles_subTaskDropdown__3iMnL {
  width: 100%;
}

.dropdownTree .dropdown {
  width: 100% !important;
}

.dropdownTree .dropdown a {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 4px !important;
}

.dropdownTree .tag {
  color: black;
}

.dropdownTree .search {
  border: none !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 5px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 5px !important;
}

.dropdownTree .dropdown-content {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
}
.areaSelectorlabel {
  margin-bottom: 5px;
  display: block;
  color: var(--color-dark);
  font-size: 14px;
}

.areaSelectorlabel::after {
  color: var(--color-pink);
  content: "*";
  margin-left: 5px;
}

.dropdownTree .dropdown a.arrow.top {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  box-shadow: none !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.dropdown .custom-buttons-container {
  padding: 8px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
}

.dropdown .custom-buttons-container .select-btn {
  color: var(--color-green);
  cursor: pointer;
}

.dropdown .custom-buttons-container .select-btn.active {
  text-decoration: underline;
}

.react-dropdown-tree-select .dropdown .dropdown-content .toggle.expanded:after {
  content: "" !important;
  display: inline-flex;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #626060;
}

.react-dropdown-tree-select
  .dropdown
  .dropdown-content
  .toggle.collapsed:after {
  content: "" !important;
  display: inline-flex;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #626060;
}

.fitSearch[role="treeitem"] {
  padding-left: 0 !important;
}

.fitSearch[role="treeitem"] > i[role="button"] {
  display: none !important;
}

.notFitSearch[role="treeitem"] {
  display: none !important;
}

.ScoutingSidebar_header__2sjDq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

.ui.grid .row.ScoutingSidebar_taskField__1vsby {
  padding: 5px 0;
}

.ui.grid .row.ScoutingSidebar_taskField__1vsby .ui.header.ScoutingSidebar_reportedTitle__xgK3Q {
  margin: 0;
  font-size: 14px;
}

.ScoutingSidebar_taskField__1vsby .ui.header.ScoutingSidebar_areaTitle__2MJsJ {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.ScoutingSidebar_areaTitle__2MJsJ > .dropdown.icon {
  color: var(--color-actional-button);
  width: 15px !important;
  font-size: 24px !important;
}

.ScoutingSidebar_root__3KT9M .ScoutingSidebar_tabs__3qKAu {
  padding: 10px 0 0;
}

.ui.secondary.pointing.menu.ScoutingSidebar_tabsMenu__8ZSbL {
  display: flex;
}

.ui.secondary.pointing.menu.ScoutingSidebar_tabsMenu__8ZSbL a {
  flex: 1 1;
  text-align: center;
  display: block;
}

@media screen and (min-width: 768px) {
  .ui.secondary.pointing.menu.ScoutingSidebar_tabsMenu__8ZSbL .active.item {
    border-color: var(--color-green);
  }
}

.ui.segment.ScoutingSidebar_issueContainer__1JWYa {
  border: 1px solid #59b655 !important;
  border-radius: 4px;
  padding-block: 5px;
}

.ui.segment.ScoutingSidebar_issueContainer__1JWYa .title.ScoutingSidebar_issueTitle__1uNqU {
  color: #59b655;
  font-weight: bold;
  font-size: 14px;
}

.ScoutingSidebar_image__16CaH {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
  object-fit: cover;
}

.ScoutingSidebar_mapContainer__1Kbav {
  height: 445px;
}

.ScoutingSidebar_reportWarningIcon__1C2UX {
  margin-right: 10px;
}

.ScoutingSidebar_root__3KT9M .ScoutingSidebar_buttonContainer__ZWFL4 {
  padding-right: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.ui.header.ScoutingSidebar_generalCommentsHeader__gPDVX {
  padding-top: 12px;
}

body {
  background: #fff;
}

.header-left {
  text-align: left;
}

.pr-10 {
  padding-right: 10px;
}

/* Print Page */

.print-page-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ebf6e9;
  padding: 10px 35px;
}

.print-page-header-column {
  display: flex;
  text-align: left;
  align-items: center;
}

.print-page-reversed-row {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.print-page-reversed-row .print-page-left-block {
  margin-right: 20px;
}

.print-page-table {
  width: 100%;
  font-size: 10pt;
  border-collapse: collapse;
}

.print-page-row-avoid-break {
  display: block;
  page-break-inside: avoid;
  break-inside: avoid-page;
}

.print-page-table tfoot tr {
  border-top: 1px solid black;
}

.justify-content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.justify-content-end {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-1 {
  margin-right: 20px !important;
}

.mb-1 {
  margin-bottom: 20px !important;
}

.-table-summary .rt-tr-group:last-child {
  border-top: 1px solid black;
}

.link_underlined {
  color: black;
  text-decoration: underline;
}

.chemicals-costs {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.font {
  font-family: Roboto;
}

.underlined {
  text-decoration: underline;
}

.bold {
  font-weight: bold !important;
}

.medium {
  font-weight: 600 !important;
}

.slim {
  font-weight: 400 !important;
}

.print-page-root {
  padding: 0px 35px;
}

.report-image {
  border-radius: 5px;
}

.issue-section {
  page-break-inside: avoid;
  break-inside: avoid;
}

.capitalize {
  text-transform: capitalize;
}

.issue-section {
  page-break-inside: avoid;
  break-inside: avoid;
  padding: 25px 50px;
}

.avoid-row-splitting {
  page-break-inside: avoid;
  break-inside: avoid;
}

.page-break-before {
  page-break-before: always;
  break-before: page;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding: 50%;
  border: 2px solid #f6f7f8;
  border-radius: 5px;
}
.image-wrapper > .image-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.image-wrapper > .image-container > .report-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.info-row {
  padding-bottom: 8px !important;
}

.issue-row-padded {
  padding: 6px 0 !important;
}

.info-header {
  padding: 20px 0 !important;
}

.block-divide-line {
  margin: 30px 0;
  color: #dededf;
  height: 1px;
  background-color: #dededf;
}

.full-width {
  width: 100%;
}

.w-45 {
  width: 45%;
}

.image-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.scouting-title {
  float: left;
}

.scouting-logo {
  float: right;
}

.info-title {
  display: inline-block;
  width: 20%;
  text-align: start;
}

.info-value {
  display: inline-block;
  width: 80%;
}

.d-flex {
  display: flex;
}

.block-name {
  margin-top: 0;
  font-size: 16px;
}

.block-issues-info {
  margin-top: 0;
  font-size: 16px;
  margin-left: 20px;
  color: #666666;
}

.ui.button.Scouting_addButton__2y6mi {
  background-color: var(--color-green) !important;
}

.ui.basic.green.button.Scouting_cancelButton__2MAP4 {
  color: var(--color-green) !important;
}

.ui.basic.green.button.Scouting_actionButton__1YVTu {
  width: 103px !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-items: baseline;
  color: #0e9354 !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding: 7.58px 16px 7.58px 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
}

.Scouting_archiveIcon__wBqqD {
  color: #0e9354 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.Scouting_modalMessageHeader__2sFz2 {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}

.ui.modal.Scouting_messageModal__342Bn {
  width: 400px !important;
  overflow: auto;
}

.ui.modal.Scouting_messageModal__342Bn .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

.ui.modal.Scouting_messageModal__342Bn .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.Scouting_holder__32_iU .ui.button.Scouting_actionButton__1YVTu {
  font-weight: 600;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  color: var(--color-green) !important;
  width: 130px;
}

.Scouting_container__3EQW- {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 230px !important;
  grid-gap: 10px;
  gap: 10px;
}
.RecommendationsPrintout_root__25hOe {
  font-family: "Montserrat", "Lato", "Helvetica Neue", Arial, Helvetica,
    sans-serif !important;
  padding: 20px;
  background: var(--color-background-cell);
}

.RecommendationsPrintout_root__25hOe .RecommendationsPrintout_page__AJP5r {
  /*if enabled, adds extra page at the end of the doc*/
  /*page-break-after: always;*/
}

.RecommendationsPrintout_root__25hOe .RecommendationsPrintout_valueHeader__ZHx0u {
  padding-top: 24px;
}

.RecommendationsPrintout_root__25hOe .RecommendationsPrintout_printedTable__2XNCI {
  margin-top: 10px;
}

.RecommendationsPrintout_root__25hOe .RecommendationsPrintout_printedTable__2XNCI thead th {
  text-transform: uppercase !important;
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 500;
}

.RecommendationsPrintout_printedTable__2XNCI .ui.basic.striped.table tbody tr:nth-child(1n) {
  background-color: #ffffff !important;
}

.RecommendationsPrintout_printedTable__2XNCI .ui.basic.striped.table tbody tr:nth-child(2n) {
  background-color: var(--color-background) !important;
}

.RecommendationsPrintout_printedTable__2XNCI .ui.basic.table tr td,
.RecommendationsPrintout_printedTable__2XNCI .ui.basic.table thead th {
  border: unset !important;
}

.RecommendationsPrintout_root__25hOe .RecommendationsPrintout_operationsHeader__RFqxV {
  margin-bottom: 24px !important;
  margin-top: 20px !important;
}

.styles_tableTotalsRow__2uWSh {
  font-weight: bold;
}

.styles_explanationContainer__QSRIv {
  margin-top: 15px;
}

.styles_explanationContainer__QSRIv .styles_explanation__39uoz {
  color: #ff9800 !important;
  display: inline-block;
  margin-right: 30px;
}

.styles_explanationStar__NTWNN {
  color: #ff9800;
}

.ui.container.styles_tableWrapper__36oaq {
  overflow: auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.freeze-head-and-col tr:first-of-type th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/*Fix the first column  to the left*/
.freeze-head-and-col td:first-of-type {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: white;
}

/* Edit: corner fixed always */
.freeze-head-and-col tr:first-of-type th:first-of-type {
  z-index: 10;
  left: 0;
}

@media (min-width: 767px) {
  :global(.ui.input).dilutionInput {
    width: 100%;
    min-width: 120px;
  }

  :global(.ui.fluid.dropdown.active).dilutionDropdown {
    width: 100%;
    min-width: 120px;
  }
  :global(.ui.fluid.dropdown).dilutionDropdown > :global(.text) {
  }
}

:global(.ui.labeled.input > .label).iconDropDown :global(.icon) {
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sprayGrid .ui.dropdown.error .menu .item {
  color: #000000;
}

.acceptSection {
  display: flex;
  margin-top: 10px;
}

.acceptSection.error {
  color: red;
}

.acceptSection label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.acceptSection label .checkbox {
  margin-right: 5px;
}

.subsectionTitle {
  font-size: 1.2rem;
}

.ui.table.sprayTablechemicals tbody .show th {
  padding: 1.29rem 0.8rem;
}

.chemicalItem {
  display: flex;
  justify-content: space-between;
}

.createTaskButton .ui.dropdown {
  background: var(--color-secondary-button-background) !important;
}
.createTaskButton .ui.dropdown:hover {
  background: var(--color-secondary-button-background-hover) !important;
}

.ui.dropdown.relative {
  position: relative;
  z-index: 1;
}

.dropdownDilusionIcon {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  padding: 12px 0;
  padding-right: 19px;
  padding-bottom: 24px;
}

.text.areaSelectItem {
  display: flex;
}

.ui.grid.chemicalItems {
  background: #f5efef;
  margin: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #e3e1e2;
}

.ui.grid.chemicalItems .column {
  padding-bottom: 15px;
}

.chemicalItemTitle {
  margin: 10px 0;
  font-size: 12px;
  color: var(--color-header);
}

.deleteChemicalIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}

.sprayGrid .ui.dropdown:not(.button) .default.text {
  color: #000000;
}

.sprayGrid .sprayField .dropdown.dropdownDilusionIcon,
.sprayGrid .sprayField .input.dilusionInput {
  width: unset;
}

.ui.grid.styles_buttonsFooter__1k8A7 {
  margin: 15px 0;
}

.ui.grid.styles_buttonsFooter__1k8A7 .ui.button + .ui.button {
  margin-right: 15px;
}

.styles_areaDropDownHolder__39jFr {
  position: relative;
  z-index: 10;
}

.styles_areaDropDownHolder__39jFr .icon.sliders {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.styles_areaDropDownHolder__39jFr .icon.delete.styles_clearAll__2VwD1 {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.styles_filtersHolder__2mOnJ {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  border: 1px solid var(--color-background-green-light);
  border-top: none;
  box-shadow: var(--box-shadow-mini);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.styles_filtersHolder__2mOnJ .ui.segment {
  margin: 0;
}

.styles_filtersBar__2e6Et {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  left: 1px;
  right: 1px;

  padding: 0 15px;
  border-bottom: 1px solid var(--color-border);
  z-index: 10;
}

.styles_filtersFooter__2EAyN {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
  border: 1px solid var(--color-border-sui);
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.styles_allHolder__1U_pX {
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.styles_allHolder__1U_pX .ui.button {
}

.ui.grid.styles_filtersGrid__2BmYC .row + .row {
  padding-top: 0;
}
.ui.grid.styles_filtersGrid__2BmYC button + button {
  margin-left: 15px;
}
.ui.grid.styles_labelsGrid__jJo6T {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.grid.styles_labelsGrid__jJo6T .row .column {
  padding-bottom: 0;
}
.ui.grid.styles_labelsGrid__jJo6T .ui.label {
  margin-top: 2px;
  margin-bottom: 2px;
}
.ui.grid.styles_labelsGrid__jJo6T .button-text {
  color: var(--color-grass);
}
.ui.grid.styles_labelsGrid__jJo6T .button-text + .button-text {
  margin-left: 15px;
}

.ui.dropdown.styles_areasDropdown__3byJs {
  padding-right: 5em;
}

.ui.dropdown.styles_areasDropdown__3byJs .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.styles_areasDropdown__3byJs .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}
.ui.dropdown.styles_areasDropdown__3byJs .item i.vacant {
  color: var(--color-vacant-blue);
}

.ui.dropdown.styles_areasDropdown__3byJs.styles_gotFilters__Q7GoQ {
  z-index: 8;
}
.ui.dropdown.styles_areasDropdown__3byJs.styles_gotFilters__Q7GoQ .menu {
  border: none;
  border-radius: 0;
  left: 0;
  right: 0;
  margin: 0;
  min-width: auto;
  width: auto;
}

.ui.dropdown.styles_areasDropdown__3byJs.styles_gotFilters__Q7GoQ .menu,
.ui.dropdown.styles_areasDropdown__3byJs.styles_gotFilters__Q7GoQ .menu:hover,
.ui.dropdown.styles_areasDropdown__3byJs.styles_gotFilters__Q7GoQ .menu:active,
.ui.dropdown.styles_areasDropdown__3byJs.styles_gotFilters__Q7GoQ:hover .menu {
  box-shadow: none;
}

.styles_countDuplicateSelectedItems__2x6v8 {
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background: var(--color-light-grey);
  font-weight: bold;
  color: var(--color-dark);
  border-radius: 100%;
  display: inline-block;
  margin-left: 5px;
  margin-top: -10px;
  margin-bottom: -6px;
  top: -3px;
  position: relative;
  vertical-align: bottom;
}

.styles_confirmContent__20FAJ {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.styles_confirmContentLine__5rk28 {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.styles_showFilters__2pyKF .ui.dropdown.styles_areasDropdown__3byJs {
  border-color: var(--color-background-green-light);
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ui.grid.styles_buttonsFooter__1k8A7,
.ui.grid.styles_buttonsFooter__1k8A7 > div {
  margin: 0;
  padding: 0;
}

.ui.grid.styles_buttonsFooter__1k8A7 .ui.primary.button .icon {
  opacity: 1;
  height: 14px;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

@media (max-width: 767px) {
  .page .modal .styles_modalHeader__3BhZ_ {
    padding-right: 0 !important;
  }

  .ui.grid.styles_buttonsFooter__1k8A7 > .row > .column {
    padding: 0;
  }

  .ui.grid.styles_buttonsFooter__1k8A7 .ui.primary.button {
    margin: 0;
  }

  .styles_machineryHolder__2jhLl .column.styles_speedColumn__2oGy3 {
    margin-top: 0 !important;
  }
}

.ui.button.floating.dropdown.styles_pressureUnitDropdown__3HwcE {
  background-color: #ebf6e9;
  color: var(--color-green);
  padding-left: 10px;
  padding-right: 10px;
}

.styles_machineryRow__1Km8c .column {
  padding-top: 10px;
}

.ui.button.RecomendationNew_addButton__1grkU {
  background-color: var(--color-green) !important;
}

.ui.basic.green.button.RecomendationNew_cancelButton__fCzId {
  color: var(--color-green) !important;
}

.ui.basic.green.button.RecomendationNew_actionButton__3JooP {
  width: 103px !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-items: baseline;
  color: #0e9354 !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding: 7.58px 16px 7.58px 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
}

.RecomendationNew_archiveIcon__KwANT {
  color: #0e9354 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.RecomendationNew_modalMessageHeader__HFZwC {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}

.ui.modal.RecomendationNew_messageModal__1m-bc {
  width: 400px !important;
  overflow: auto;
}

.ui.modal.RecomendationNew_messageModal__1m-bc .content {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

.ui.modal.RecomendationNew_messageModal__1m-bc .actions {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

.ui.modal.messageModal > .actions {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

.ui.large.modal.messageModal > .header {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.RecomendationNew_holder__1CRkI .ui.button.RecomendationNew_actionButton__3JooP {
  font-weight: 600;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  color: var(--color-green) !important;
  width: 130px;
}

.RecomendationNew_container__2u9hY {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 230px !important;
  grid-gap: 10px;
  gap: 10px;
}
.RecommendationSidebar_root__3davm {
  width: 600px !important;
  background: #ffffff;
  height: 100%;
  padding: 16px;
  font-family: Lato, serif;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_header__1D4sJ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_taskField__3DGR4 {
  padding: 5px 0;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_tabsMenu__1KvtO {
  display: flex;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_tabsMenu__1KvtO .RecommendationSidebar_tab__1FQpY {
  flex: 1 1;
  text-align: center;
  display: block;
  justify-content: center;
}

.ui.secondary.pointing.menu.RecommendationSidebar_tabsMenu__1KvtO .active.item {
  border-color: var(--color-background-green);
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_dropDownButton__2zVNI {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_dropDownButton__2zVNI > i.icon {
  margin-right: 0 !important;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_buttonsWrapper__1oS32 {
  display: inline-flex !important;
  grid-gap: 16px;
  gap: 16px;
  flex-direction: row !important;
  align-items: center;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_buttonsWrapper__1oS32 .RecommendationSidebar_button__3V010,
.RecommendationSidebar_root__3davm .RecommendationSidebar_buttonsWrapper__1oS32 .RecommendationSidebar_dropDownButton__2zVNI {
  width: 120px;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_buttonsWrapper__1oS32 .RecommendationSidebar_closeIcon__1ti-Q {
  margin-right: 0;
  margin-left: auto;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_editableHeader__3qEsY {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_editIcon__2QiGC {
  color: var(--color-green);
  margin-right: 0 !important;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_machineryDataField__1DMKO {
  display: flex;
  justify-content: space-between;
}

.ui.message.RecommendationSidebar_attachmentCard__2exPs {
  color: var(--color-green);
  background-color: #ebf7e9;
  border: 1px solid var(--color-green);
  padding-right: 0 !important;
}

.RecommendationSidebar_textarea__1vUVW {
  width: 100%;
  resize: none;
}

.header.RecommendationSidebar_chemicalHeader__2G47I {
  margin-bottom: 10px !important;
  text-transform: uppercase;
  color: #9a9ca3 !important;
}

.header.RecommendationSidebar_chemicalValue__-2EhZ {
  margin-top: 0;
  margin-bottom: 20px !important;
  color: #263039 !important;
  font-weight: normal;
}

.RecommendationSidebar_buttonsContainer__3eLvB {
  margin-top: 30px;
}

.RecommendationSidebar_typeReasonButton__12CUx {
  text-decoration: underline;
}

.RecommendationSidebar_root__3davm .RecommendationSidebar_trashIcon__nyGgR {
  color: var(--color-dark) !important;
  padding-left: 20px;
}

.RecommendationSidebar_actionIcon__2_2FV,
.RecommendationSidebar_editIcon__2QiGC,
.RecommendationSidebar_trashIcon__nyGgR {
  cursor: pointer;
}

.ui.segment.styles_issue__2jyim {
  background-color: var(--color-background);
}

.ui.segment.styles_issue__2jyim .styles_issueTitle__az8gq {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.ui.segment .styles_field__1Z94Q {
  padding-bottom: 20px;
}

.ui.segment textarea.styles_field__1Z94Q {
  resize: none;
}

.ui.segment .styles_field__1Z94Q:last-child {
  padding-bottom: 50px;
}

.ui.header.styles_locationTitle__XoLkf {
  font-weight: bold;
}

.ui.segment.styles_issue__2jyim .styles_deleteIcon__rjz3o {
  margin-left: auto;
  font-size: 20px;
}

.styles_issue__2jyim .rc-slider-handle {
  box-shadow: rgba(0, 0, 0, 0.14) 0 4px 5px, rgba(0, 0, 0, 0.12) 0 1px 10px,
    rgba(0, 0, 0, 0.2) 0 2px 4px;
  border: transparent;
}

.styles_issue__2jyim .rc-slider-dot {
  background-color: #e9e9e9;
  border-radius: 0;
  width: 1px;
  margin-left: 1px;
}

.styles_issue__2jyim .rc-slider-dot-active {
  border: none;
}

.styles_buttonsFooter__1PL98 {
  text-align: right;
  padding: 5px 15px;
}

.ui.selection.dropdown.sprayTaskStatus {
  border-radius: 20px;
  color: white;
}

.ui.selection.dropdown.sprayTaskStatus .menu {
  margin-top: 0;
}

.sprayField .react-calendar {
  position: absolute;
  top: 100%;
  border: none;
  box-shadow: var(--box-shadow);
  z-index: 100;
  max-width: 250px;
}

.Sidebar_textarea__3mQKX textarea {
  width: 100%;
  resize: none;
}

.Sidebar_dropdown__3F5-9 > .default.text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields {
  margin: 0 -10px;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field > label {
  font-weight: normal;
  display: block;
  flex: 0 1 auto;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field > div {
  display: block;
  flex: 1 1 auto;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field > div input {
  width: 100%;
  max-width: 60px;
}

.AreasFilters_areaDropDownHolder__2fVM4 {
  position: relative;
}

.AreasFilters_areaDropDownHolder__2fVM4 .icon.sliders {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.AreasFilters_areaDropDownHolder__2fVM4 .icon.delete.AreasFilters_clearAll__25Evw {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.AreasFilters_filtersHolder__1I7qS {
  position: absolute;
  width: 100%;
  top: 100%;
}

.AreasFilters_filtersHolder__1I7qS .ui.segment {
  margin: 0;
}

.AreasFilters_filtersBar__22_pU {
  position: absolute;
  top: 100%;
  width: 100%;
  background: var(--color-grass-light);
  padding: 0 15px;
  box-shadow: var(--box-shadow);
}

.AreasFilters_filtersFooter__1YPJ3 {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
}

.AreasFilters_filtersFooter__1YPJ3 .ui.button {
  border-radius: 0;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields {
  margin: 0 -10px;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field > label {
  font-weight: normal;
  display: block;
  flex: 0 1 auto;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field > div {
  display: block;
  flex: 1 1 auto;
}

.ui.form.AreasFilters_ageForm__b3r3U .inline.fields .field > div input {
  width: 100%;
  max-width: 60px;
}

.ui.grid.AreasFilters_filtersGrid__8lNCF button + button {
  margin-left: 15px;
}

.ui.grid.AreasFilters_filtersGrid__8lNCF .menu i {
  margin-left: 15px;
  display: inline-block;
  color: var(--color-middle);
}

.ui.grid.AreasFilters_labelsGrid__2SmLq {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.grid.AreasFilters_labelsGrid__2SmLq .row .column {
  padding-bottom: 0;
}

.ui.grid.AreasFilters_labelsGrid__2SmLq .ui.label {
  margin-top: 2px;
  margin-bottom: 2px;
}

.ui.grid.AreasFilters_labelsGrid__2SmLq .button-text {
  color: var(--color-grass);
}

.ui.grid.AreasFilters_labelsGrid__2SmLq .button-text + .button-text {
  margin-left: 15px;
}

.ui.dropdown.AreasFilters_areasDropdown__6did1 {
  padding-right: 5em;
}

.ui.dropdown.AreasFilters_areasDropdown__6did1 .item span {
  display: inline-block;
  color: var(--color-black);
}

.ui.dropdown.AreasFilters_areasDropdown__6did1 .item i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}

.AreasFilters_optional__3ppkz label:after {
  display: none;
}

.styles_viewSelector__1Vhs6 {
  text-decoration: underline;
  cursor: pointer;
  color: gray;
  font-size: 1rem;
}
.styles_viewSelector__1Vhs6:hover {
  color: black;
}

.styles_viewHolder__21zLb {
  display: flex;
  flex-direction: row;
}

.styles_viewLabelHolder__1EzyP {
  width: 50%;
  float: left;
}

.styles_viewButtonHolder__1uPns {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  float: right;
}

.styles_areaDropDownHolder__3d1ZW {
  position: relative;
  z-index: 10;
}

.styles_areaDropDownHolder__3d1ZW .icon.sliders {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.styles_areaDropDownHolder__3d1ZW .icon.delete.styles_clearAll__3MSZu {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.ui.dropdown.styles_areasDropdown__1RJPt.styles_gotFilters__2w9cb {
  z-index: 8;
}
.ui.dropdown.styles_areasDropdown__1RJPt.styles_gotFilters__2w9cb .menu {
  border: none;
  border-radius: 0;
  left: 0;
  right: 0;
  margin: 0;
  min-width: auto;
  width: auto;
}

.ui.dropdown.styles_areasDropdown__1RJPt.styles_gotFilters__2w9cb .menu,
.ui.dropdown.styles_areasDropdown__1RJPt.styles_gotFilters__2w9cb .menu:hover,
.ui.dropdown.styles_areasDropdown__1RJPt.styles_gotFilters__2w9cb .menu:active,
.ui.dropdown.styles_areasDropdown__1RJPt.styles_gotFilters__2w9cb:hover .menu {
  box-shadow: none;
}

.styles_filtersHolder__1jJ-j {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  border: 1px solid var(--color-background-green-light);
  border-top: none;
  box-shadow: var(--box-shadow-mini);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.styles_filtersHolder__1jJ-j .ui.segment {
  margin: 0;
}

.styles_areasColumn__3kv75 {
  z-index: 100;
}

.SelectChemicalsForm_title__cmafO {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-light);
  display: none;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 3px;
}

.SelectChemicalsForm_newItem__2wAkZ .SelectChemicalsForm_title__cmafO {
  color: #000;
}

.SelectChemicalsForm_buttonAdd__3UEwz {
  margin-bottom: 25px;
}
.SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table {
  border: none;
}
.SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table thead th {
  background: none;
  font-weight: normal;
  color: var(--color-middle);
}

.SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td {
  font-weight: 600;
}

.SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td.SelectChemicalsForm_actions__2oiTs {
  text-align: center;
  color: var(--color-icon);
  font-size: 18px;
}

.SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td.SelectChemicalsForm_actions__2oiTs i {
  cursor: pointer;
}

.SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td.SelectChemicalsForm_actions__2oiTs i:not(:last-child) {
  margin-right: 15px;
}

.ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_editItem__3r1yo,
.ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_editItem__3r1yo:hover,
.ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_newItem__2wAkZ,
.ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_newItem__2wAkZ:hover {
  background: var(--color-background-cell) !important;
}

.SelectChemicalsForm_chemicalsTable__3Z1A1 .ui.selection.dropdown .default.text {
  white-space: nowrap;
  padding-right: 25px;
  overflow: hidden;
  max-width: 100%;
}

.SelectChemicalsForm_addTitle__2Z68B {
  display: none;
  font-weight: bold;
}

.SelectChemicalsForm_buttonCancel__1CSoz {
  display: none;
}

.SelectChemicalsForm_buttonUpdate__22HhS {
}

.SelectChemicalsForm_inputRate__1Hak3 {
  width: 130px;
}

.SelectChemicalsForm_addChemicalValidation__3_RXe {
  animation: SelectChemicalsForm_shake__1i4Yx 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes SelectChemicalsForm_shake__1i4Yx {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (min-width: 767px) {
  .SelectChemicalsForm_buttonsHolder__3zSOm {
    width: 125px;
  }

  .SelectChemicalsForm_buttonsNew__QDXEN .ui.button {
    padding: 12px;
    font-size: 12px;
  }

  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td:last-child {
    max-width: 155px;
    text-align: center;
    white-space: nowrap;
    width: 1%;
  }

  .SelectChemicalsForm_cropAndPurposeCell__2GGfA {
    width: 15%;
  }

  .SelectChemicalsForm_dilutionCell__1P-38 {
    width: 3%;
  }

  .SelectChemicalsForm_chemicalCell__bAizn {
    width: 15%;
  }

  .SelectChemicalsForm_chemicalTypeCell___V7TJ {
    width: 10%;
  }

  .SelectChemicalsForm_dateOfManufactureCell__oqqDP {
    width: 10%;
  }

  .SelectChemicalsForm_batchNumberCell__1DxGD {
    width: 10%;
  }
}

.ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_message__3L9lC,
.ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_message__3L9lC:hover {
  border: none;
  background: none !important;
}

@media (max-width: 767px) {
  .SelectChemicalsForm_title__cmafO {
    display: block;
    margin-top: 15px;
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table thead {
    display: none;
  }

  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr {
    display: flex !important;
    flex-wrap: wrap;
    background-color: var(--color-bg-pink);
    border: 1px solid var(--color-border);

    box-shadow: none !important;
    position: relative;
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr:not(:last-child) {
    margin-bottom: 15px;
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr:nth-child(2n) {
    background-color: var(--color-bg-pink);
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr.SelectChemicalsForm_newItem__2wAkZ,
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr:nth-child(2n).SelectChemicalsForm_newItem__2wAkZ {
    background-color: #fff;
  }

  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td {
    width: 50% !important;
    flex: 0 1 auto;
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td:last-child {
    width: 100% !important;
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td:not(.SelectChemicalsForm_actions__2oiTs) {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }

  .ui.table.SelectChemicalsForm_chemicalsTable__3Z1A1 tbody tr.SelectChemicalsForm_message__3L9lC {
    padding: 0;
  }

  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td.SelectChemicalsForm_actions__2oiTs {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 18px;
    width: auto !important;
    text-align: right;
    padding: 0 !important;
    white-space: nowrap;
  }
  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td.SelectChemicalsForm_actions__2oiTs i:not(:last-child) {
    margin-right: 15px;
  }

  .SelectChemicalsForm_buttonAdd__3UEwz button span {
    display: inline !important;
  }

  .SelectChemicalsForm_chemicalsTable__3Z1A1.ui.table tbody tr td.SelectChemicalsForm_addTitle__2Z68B {
    width: 100% !important;
  }

  .SelectChemicalsForm_buttonsNew__QDXEN {
    margin-top: 10px;
  }

  .SelectChemicalsForm_buttonCancel__1CSoz {
    display: inline-block;
  }
}

.ui.container.SelectChemicalsForm_tableWrapper__J3y5Z {
  /*overflow: auto;*/
  margin-left: 0 !important;
  margin-right: 0 !important;
}


.ui.basic.segment.SelectChemicals_messageHolder__2Pq68 {
  margin-top: 0;
  padding-top: 0;
}

.styles_errorBar__PX_gH {
  margin: 0 0 15px 0;
}

.icon-label {
  display: flex;
  align-items: center;
}

.icon-label > .icon {
  color: var(--color-icon);
  font-size: 21px;
  text-align: center;
}

.icon-label > div {
  flex: auto;
}

.ui.search.dropdown > .text {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles_footerContainer__3B1Zz {
  padding: 5px 15px;
  text-align: right;
}

.styles_root__3M2mg {
  width: 100%;
}

.styles_checkbox__3CF9d {
  margin-right: 20px;
}

.styles_buttonsContainer__3UwOv {
  margin-top: 30px;
}

.ui.container.Reports_container__1usBl {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.ui.segment.Reports_reportTypesHolder__1lbsx {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1em 0;
  background-color: transparent;
}

.Reports_header__2SWfq {
  display: inline;
}

.Reports_card__10Hys {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);

  background-color: #ffffff;

  margin: 0 24px 24px 0;
  padding: 28px 33px;

  text-align: center;
}

.Reports_card__10Hys:hover {
  background-color: #2bb673;
  color: white;
}

.Reports_card__10Hys img {
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: white;
}

.Reports_card__10Hys .Reports_caption__1EClK {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: var(--color-text);

  width: 120px;
}

.Reports_card__10Hys:hover .Reports_caption__1EClK {
  color: white;
}

.Reports_backIconWrapper__35wAT {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.Reports_backIcon__2Z4cH {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.PrintReport_reportWrapper__1EouD {
  padding: 25px;
}

.PrintReport_reportHeaderRow__2gmr2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_reportHeader__2GTSC {
  font-size: 16pt;
  margin-bottom: 20px;
}

.PrintReport_reportSubHeader__qtkcj {
  font-size: 11pt;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.PrintReport_farmHeader__2SE7C {
  font-size: 12pt;
}

.PrintReport_farmTable__3CAxb {
  margin-bottom: 0px !important;
  background-color: #eeeeef !important;
  -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
  border-radius: 0px !important;
}

.PrintReport_secondFarmTable__1aHrz {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
  background-color: #eeeeef !important;
  border-radius: 0px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid #dededf !important;
}

.PrintReport_headerCell__2pJvy {
  vertical-align: text-top !important;
  width: 5% !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: 20px !important;
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  color: #212121 !important;
}

.PrintReport_valueCell__3-6jM {
  vertical-align: text-top !important;
  width: 25% !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: 20px !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #212121 !important;
}

.PrintReport_midleHeaderCell__3eZ_O {
  vertical-align: text-top !important;
  width: 5% !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: 13px !important;
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  color: #212121 !important;
  border-left: 1px solid #dededf !important;
}

.PrintReport_midleValueCell__3uaIC {
  vertical-align: text-top !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  width: 35% !important;
  white-space: pre-line !important;
  word-break: break-all !important;
  height: 13px !important;
  border-right: 1px solid #dededf !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #212121 !important;
}

.PrintReport_secondFarmTableHeader__3Sehc {
  height: 20px;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderSmallCell__2FSFM {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 6% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderAreaCell__1tUzR {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 6.8% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderPurposeCell__3rebb {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 7.1% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderVarietyCell__1Shhc {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 9.6% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderLargeCell__VsPC0 {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 8.4% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderMiddleCell__2TKA7 {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 8% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderWHPCell__1BV3D {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 7% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderSafeHarvestCell__1RSgO {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 8.5% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableHeaderActiveIngredientLargeCell__1NJAK {
  vertical-align: text-top !important;
  height: 22px !important;
  width: 9.4% !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #dededf !important;
}

.PrintReport_secondTableValue__jPmc3 {
  vertical-align: text-top !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #212121 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.PrintReport_secondFarmTable__1aHrz tr td:first-child,
.PrintReport_secondFarmTable__1aHrz tr td:last-child {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.PrintReport_farmTable__3CAxb tr td:first-child,
.PrintReport_farmTable__3CAxb tr td:last-child {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.PrintReport_farmTable__3CAxb tr td {
  border-bottom: none !important;
  border-top: none !important;
}

.PrintReport_farmTable__3CAxb tbody tr:nth-child(odd) td {
  padding-top: 0 !important;
}

.PrintReport_farmTable__3CAxb thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.9) !important;
  font-size: 8pt !important;
}

.PrintReport_farmTable__3CAxb tbody tr td {
  font-size: 7pt !important;
}

.PrintReport_stripedRow__YtiN_ {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.PrintReport_additionalInfoRow__33CKZ {
  display: flex;
  justify-content: space-between;
}

.PrintReport_additionalInfoRow__33CKZ > div {
  display: flex;
  flex-direction: column;
  font-size: 7pt;
}

.PrintReport_additionalInfoRow__33CKZ > div > span {
  font-weight: bold;
}

.PrintReport_pageBreak__np56B {
  page-break-before: always !important;
  break-before: always !important;
}

.PrintReport_pageBreakInside__1EWZI {
  page-break-inside: avoid !important;
  break-inside: avoid !important;
}

.PrintReport_reportCreatedRow__3g3d7 {
  font-size: 7pt;
}

.PrintReport_areasContainer__CPWJs {
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}

.PrintReport_cropsContainer__3J9_Z {
  margin: 0px !important;
  padding: 0px !important;
}

.PrintReport_varietiesContainer__2Q4dI {
  margin: 0px !important;
  padding: 0px !important;
}

.PrintReport_activeIngredientsContainer__1WwTv {
  margin: 0px !important;
  padding: 0px !important;
}

.PrintReport_purposesContainer__1LTu7 {
  margin: 0px !important;
  padding: 0px !important;
}

.PrintReport_withholdingPeriodsContainer__3zGYO {
  margin: 0px !important;
  padding: 0px !important;
}

.PrintReport_actualRateAppliedContainer__1EGY6 {
  margin: 0px !important;
  padding: 0px !important;
}

.ApplicationsRecordByBlock_tasksListHolder__Mm02F {
  overflow-x: scroll;
}

.ApplicationsRecordByBlock_tasksLoader__z0tAj {
  margin-top: 30px;
}

.ApplicationsRecordByBlock_filtersGrid__1AJbC {
  border-bottom: 1px solid var(--color-border);
}

.ApplicationsRecordByBlock_sidebarHolder__Po-LR {
  position: relative;
}

.ApplicationsRecordByBlock_tasksHolder__3nCeu .pusher {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.ApplicationsRecordByBlock_tasksHolder__3nCeu .ui.right.overlay.sidebar {
  background: var(--color-background);
  width: 100%;
  padding: 15px;
  overflow: auto;
  box-shadow: var(--box-shadow);
}

.ui.segment.ApplicationsRecordByBlock_mainHolder__1lPJJ {
  padding: 0;
}

.ApplicationsRecordByBlock_backIconWrapper__AU8ds {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.ApplicationsRecordByBlock_backIcon__2ungs {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}
.ApplicationsRecordByBlock_activeIngredientsCell__1GmEW {
  width: 130px;
  padding-left: 5px;
  white-space: pre-wrap;
}

@media screen and (min-width: 768px) {
  .ApplicationsRecordByBlock_tasksHolder__3nCeu .ui.sidebar {
    width: 70%;
    max-width: 570px;
    padding: 30px;
  }
}

.TotalCostPerArea_p1em__2Z9ZK {
  padding: 1em;
}

.TotalCostPerArea_backIconWrapper__2TrEy {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.TotalCostPerArea_filter__3EVSk {
  margin-top: -0.7rem !important;
}

.TotalCostPerArea_filtersGrid__2LZ_D {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 1rem !important;
}

.TotalCostPerArea_backIcon__hDEfG {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.TotalCostPerArea_archivedArea__1mZkd {
  filter: grayscale(100%);
  opacity: 0.5;
}

.TotalCostPerArea_expandedTabelRow__3k3Bv {
  padding: 0px 20px 0px 10px !important;
  border: none !important;
}

.PrintReport_reportWrapper__16Iaq {
  padding: 25px;
  font-size: 14px;
}

.PrintReport_reportHeaderRow__v-2K4 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_boldFont__3YGSt {
  font-size: 14px;
  font-weight: bolder;
}

.PrintReport_farmTable__2yNQE {
  font-size: 14px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.9) !important;
}

.PrintReport_farmTable__2yNQE tbody .PrintReport_taskRow__3L5Wn:not(:first-child) td {
  border-top: 5px solid rgba(27, 31, 34, 0.1);
}

.PrintReport_farmTable__2yNQE tbody :not(.PrintReport_taskRow__3L5Wn) td {
  border-top: 0 !important;
}

.PrintReport_additionalInfoRow__1Ou_v {
  display: flex;
  justify-content: space-between;
}

.PrintReport_additionalInfoRow__1Ou_v > div {
  display: flex;
  flex-direction: column;
}

.PrintReport_additionalInfoRow__1Ou_v span {
  font-weight: bold;
}

.PrintReport_reportCreatedRow__3t3t0 {
  margin-left: 50px;
}

.TotalCostPerFarm_p1em__3Jijs {
  padding: 1em;
}

.TotalCostPerFarm_backIconWrapper__31rC1 {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.TotalCostPerFarm_backIcon__GrwKl {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}
.TotalCostPerFarm_expandedTabelRow__3AGzn {
  padding: 0px 20px 0px 10px !important;
  border: none !important;
}

.PrintReport_reportWrapper__3YUji {
  padding: 25px;
}

.PrintReport_reportHeaderRow__FvV5O {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_farmTable__2Mbxa {
  margin-bottom: 50px !important;
}

.PrintReport_farmTable__2Mbxa tbody .PrintReport_taskRow__12-aB:not(:first-child) td {
  border-top: 5px solid rgba(27, 31, 34, 0.1);
}

.PrintReport_farmTable__2Mbxa tbody :not(.PrintReport_taskRow__12-aB) td {
  border-top: 0 !important;
}

.PrintReport_additionalInfoRow__34KMD {
  display: flex;
  justify-content: space-between;
}

.PrintReport_additionalInfoRow__34KMD > div {
  display: flex;
  flex-direction: column;
}

.PrintReport_additionalInfoRow__34KMD span {
  font-weight: bold;
}

.PrintReport_reportCreatedRow__2p3DQ {
  margin-left: 50px;
}

.HourlyPayroll_backIconWrapper__v0LRe {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.HourlyPayroll_backIcon__1B-od {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.HourlyPayroll_loader__38Uim {
  margin-top: 30px;
}

.HourlyPayroll_payrollsTable__UCr02 {
  margin-top: 20px;
}

.HourlyPayroll_payrollsTable__UCr02 thead tr th {
  background-color: transparent !important;
  border-left: none !important;
}

.HourlyPayroll_payrollsTable__UCr02 thead tr th:first-child,
.HourlyPayroll_payrollsTable__UCr02 thead tr th:nth-child(2) {
  padding-left: 0 !important;
}

.HourlyPayroll_payrollsTable__UCr02 tbody tr td:nth-child(2) {
  padding-left: 0 !important;
}

.HourlyPayroll_payrollsTable__UCr02 tfoot tr td {
  border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
  font-weight: bold;
}

.HourlyPayroll_payrollsTable__UCr02 tfoot tr td:first-child {
  padding-left: 12px !important;
}

.TableRow_stripedRow__B1RSm {
  background: var(--color-row-stripped);
}

.TableRow_loader__2XEvb {
  margin-left: 0;
}

.TableRow_iconCell__12Mak {
  width: 35px;
  padding: 0 !important;
  text-align: center !important;
}

.TableRow_expandedRow__esQut {
  background-color: #ebf6e9;
}

.ui.selectable.table tbody tr.TableRow_additionalRow__2MMAV:hover,
.ui.selectable.table tbody tr.TableRow_additionalRow__2MMAV tr:hover {
  background-color: #ebf6e9 !important;
}

.TableRow_additionalRow__2MMAV td:first-child {
  border-top: none !important;
}

.TableRow_drawGreenLine__33mAs {
  border-left: 3px solid #0e9354;
}

.TableRow_additionalInfoTable__1TSIg thead tr th {
  background: transparent !important;
  color: #5b666f !important;
  font-weight: 500 !important;
  border-bottom: 0 !important;
}

.TableRow_additionalInfoTable__1TSIg th:last-child,
.TableRow_additionalInfoTable__1TSIg td:last-child {
  padding-right: 12px !important;
}

.TableRow_additionalInfoTable__1TSIg tbody tr td {
  border-top: 0 !important;
}

.TableRow_additionalInfoTable__1TSIg tbody tr:last-child td {
  border-top: 0 !important;
}

.TableRow_additionalInfoTable__1TSIg tbody tr td:first-child {
  border-top: none !important;
}

.TableRow_toggleIcon__3pud1 {
  margin: 0 !important;
  color: #9dacba;
  font-size: 16px !important;
  vertical-align: bottom;
  cursor: pointer;
}

.TableRow_toggleIconTurned__1WgXn {
  transform: rotate(180deg);
}

.PrintReport_reportWrapper__3WEKk {
  padding: 40px;
}

.PrintReport_reportHeaderRow__2Rtvz {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_reportHeader__3_6x9 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.PrintReport_reportSubHeader__3BeEH {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.PrintReport_reportTable__3XVp3 {
  margin-bottom: 20px !important;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.PrintReport_reportTable__3XVp3 tr td:first-child,
.PrintReport_reportTable__3XVp3 tr td:last-child,
.PrintReport_reportTable__3XVp3 tr th:first-child,
.PrintReport_reportTable__3XVp3 tr th:last-child {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.PrintReport_reportTable__3XVp3 thead tr th,
.PrintReport_reportTable__3XVp3 > tbody tr td {
  border: none !important;
}

.PrintReport_reportTable__3XVp3 > tfoot tr th {
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
  border-bottom: 2px solid rgba(199, 199, 199, 0.5) !important;
  font-size: 10pt !important;
  text-align: left;
}

.PrintReport_reportTable__3XVp3 thead tr th {
  font-size: 16px !important;
  color: #7d7d7d !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.PrintReport_reportTable__3XVp3 tr td {
  font-size: 10pt !important;
}

.PrintReport_reportTable__3XVp3 tr th {
  font-weight: 700 !important;
}

.PrintReport_reportTable__3XVp3 tr td.PrintReport_additionalInfoContainer__3UjYJ {
  padding-bottom: 0 !important;
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
}

.PrintReport_additionalInfoTable__1FGue tr td:first-child,
.PrintReport_additionalInfoTable__1FGue tr td:last-child,
.PrintReport_additionalInfoTable__1FGue tr th:first-child,
.PrintReport_additionalInfoTable__1FGue tr th:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.PrintReport_additionalInfoTable__1FGue tbody tr td {
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
}

.PrintReport_stripedRow__1jyuO {
  background-color: #ffffff !important;
}

.PrintReport_pageBreak__28R0J {
  page-break-before: always !important;
  break-before: always !important;
}

.PrintReport_pageBreakInside__3HHnY {
  page-break-inside: avoid !important;
  break-inside: avoid !important;
}

.PrintReport_reportCreatedRow__2J5a3 {
  font-size: 7pt;
}

button.ui.basic.button.PrintoutButton_btnCustomized__3iOeE,
.PrintoutButton_printoutDropdown__3ej42 {
  display: inline-flex !important;
  align-items: center;
  height: 36px;
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-green) !important;
  box-shadow: 0px 0px 0px 1px var(--color-green) inset;
}

.PrintoutButton_printoutDropdown__3ej42 {
  border-radius: 0.28571429rem;
}

.ui.dropdown.PrintoutButton_printoutDropdown__3ej42 .text {
  margin-right: 5px;
}

.ui.dropdown.PrintoutButton_printoutDropdown__3ej42 > .icon {
  display: inline;
  margin-right: 0;
  margin-left: 5px;
  line-height: 18px;
  scale: 1.2;
}

.ui.dropdown.visible.PrintoutButton_printoutDropdown__3ej42 > .icon {
  margin-top: 3px;
  transform: rotate(180deg);
}

.ui.dropdown.PrintoutButton_printoutDropdown__3ej42 .menu {
  left: auto;
  right: 0;
}

.PieceRatePayroll_backIconWrapper__3hC-W {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.PieceRatePayroll_backIcon__AHtet {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.PieceRatePayroll_loader__2x2HM {
  margin-top: 30px;
}

.PieceRatePayroll_payrollsTable__VkpIr {
  margin-top: 20px;
}

.PieceRatePayroll_payrollsTable__VkpIr thead tr th {
  background-color: transparent !important;
  border-left: none !important;
}

.PieceRatePayroll_payrollsTable__VkpIr thead tr th:first-child,
.PieceRatePayroll_payrollsTable__VkpIr thead tr th:nth-child(2) {
  padding-left: 0 !important;
}

.PieceRatePayroll_payrollsTable__VkpIr tbody tr td:nth-child(2) {
  padding-left: 0 !important;
}

.PieceRatePayroll_payrollsTable__VkpIr tfoot tr td {
  border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
  font-weight: bold;
}

.PieceRatePayroll_payrollsTable__VkpIr tfoot tr td:first-child {
  padding-left: 12px !important;
}

.TableRow_stripedRow__3Ckac {
  background: var(--color-row-stripped);
}

.TableRow_loader__3pYzP {
  margin-left: 0;
}

.TableRow_iconCell__1MAZV {
  width: 35px;
  padding: 0 !important;
  text-align: center !important;
}

.TableRow_expandedRow__2Cxsn {
  background-color: #ebf6e9;
}

.ui.selectable.table tbody tr.TableRow_additionalRow__1WusK:hover,
.ui.selectable.table tbody tr.TableRow_additionalRow__1WusK tr:hover {
  background-color: #ebf6e9 !important;
}

.TableRow_additionalRow__1WusK td:first-child {
  border-top: none !important;
}

.TableRow_drawGreenLine__17BMu {
  border-left: 3px solid #0e9354;
}

.TableRow_additionalInfoTable__1nCof thead tr th {
  background: transparent !important;
  color: #5b666f !important;
  font-weight: 500 !important;
  border-bottom: 0 !important;
}

.TableRow_additionalInfoTable__1nCof th:last-child,
.TableRow_additionalInfoTable__1nCof td:last-child {
  padding-right: 12px !important;
}

.TableRow_additionalInfoTable__1nCof tbody tr td {
  border-top: 0 !important;
}

.TableRow_additionalInfoTable__1nCof tbody tr:last-child td {
  border-top: 0 !important;
}

.TableRow_additionalInfoTable__1nCof tbody tr td:first-child {
  border-top: none !important;
}

.TableRow_toggleIcon__3dtlR {
  margin: 0 !important;
  color: #9dacba;
  font-size: 16px !important;
  vertical-align: bottom;
  cursor: pointer;
}

.TableRow_toggleIconTurned__2JVvG {
  transform: rotate(180deg);
}

.PrintReport_reportWrapper__35aZ- {
  padding: 40px;
}

.PrintReport_reportHeaderRow__m1soo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.PrintReport_reportHeader__73akn {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.PrintReport_reportSubHeader__3JYIL {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.PrintReport_reportTable__2iXsX {
  margin-bottom: 20px !important;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.PrintReport_reportTable__2iXsX tr td:first-child,
.PrintReport_reportTable__2iXsX tr td:last-child,
.PrintReport_reportTable__2iXsX tr th:first-child,
.PrintReport_reportTable__2iXsX tr th:last-child {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.PrintReport_reportTable__2iXsX thead tr th,
.PrintReport_reportTable__2iXsX > tbody tr td {
  border: none !important;
}

.PrintReport_reportTable__2iXsX > tfoot tr th {
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
  border-bottom: 2px solid rgba(199, 199, 199, 0.5) !important;
  font-size: 16px !important;
}

.PrintReport_reportTable__2iXsX thead tr th {
  font-size: 16px !important;
  color: #7d7d7d !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.PrintReport_reportTable__2iXsX tr td {
  font-size: 16px !important;
}

.PrintReport_reportTable__2iXsX tr th {
  font-weight: 700 !important;
}

.PrintReport_reportTable__2iXsX tr td.PrintReport_additionalInfoContainer__PSH25 {
  padding-bottom: 0 !important;
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
}

.PrintReport_additionalInfoTable__2hgme tr td:first-child,
.PrintReport_additionalInfoTable__2hgme tr td:last-child,
.PrintReport_additionalInfoTable__2hgme tr th:first-child,
.PrintReport_additionalInfoTable__2hgme tr th:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.PrintReport_additionalInfoTable__2hgme tbody tr td {
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
}

.PrintReport_stripedRow__3o2m0 {
  background-color: #ffffff !important;
}

.PrintReport_pageBreak__y4v_y {
  page-break-before: always !important;
  break-before: always !important;
}

.PrintReport_pageBreakInside__31Rl9 {
  page-break-inside: avoid !important;
  break-inside: avoid !important;
}

.PrintReport_reportCreatedRow__20EtT {
  font-size: 7pt;
}

.TasksCustomization_root__2EO4x {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.TasksCustomization_contentWrapper__1Q4gV {
  height: 100%;
  margin: 0px !important;
  padding: 16px !important;
  background-color: #f5f7f7;
}

.TasksCustomization_headerContainer__qI66a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7% !important;
  padding-top: 16px;
  margin-bottom: 16px;
}

.TasksCustomization_header__2YLjP {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.TasksCustomization_doneBtn__3O9CP {
  height: 36px;
  display: flex !important;
  align-items: center;
  background-color: #0e9354 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
}

.TasksCustomization_customizationContainer__5kpkn {
  height: 100vh;
  background-color: white;
  padding: 18px;
  border-radius: 8px;
}

.TasksCustomization_infoMessage__1eZS9 {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
}

.TasksCustomization_dragIcon__Papn1 {
  margin-right: 10.4px;
}

.TasksCustomization_typeContainer__1JOkG {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dededf;
  margin-bottom: 24px;
}

.TasksCustomization_typeWrapper__VwDce {
  height: 32px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.TasksCustomization_horizontalScroll__15TUT {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.TasksCustomization_horizontalScroll__15TUT::-webkit-scrollbar {
  display: none;
}

.TasksCustomization_item__2dtG3 {
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

.TasksCustomization_type__2vtIk {
  cursor: pointer;
  height: 32px;
  display: flex;
  font-size: 12px !important;
  align-items: center;
  padding: 8px 12px 8px 12px;
  border-radius: 4px 4px 0px 0px;
  border: 1px dashed #dededf;
  border-bottom: none !important;
  text-wrap: nowrap;
}

.TasksCustomization_typeDropdown__39i-V {
  width: 130px;
  height: 15px;
  padding: 0px 16px 0px 0px !important;
  grid-gap: 4px;
  gap: 4px;
  border: none !important;
  box-shadow: none !important;
  color: #0e9354 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.06px !important;
  text-align: center !important;
}

.TasksCustomization_closeIcon__3sL3z {
  display: flex !important;
  cursor: pointer;
  align-items: center;
  align-items: center;
  color: #666666 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.TasksCustomization_dropdownMenu__1df55 {
  width: 230px;
  overflow-y: auto;
  top: 40px !important;
  left: -100px !important;
}

.TasksCustomization_dropdownScrollingMenu__27W5j {
  min-height: 350px !important;
}

.TasksCustomization_dropdownScrollingMenu__27W5j::-webkit-scrollbar-track-piece {
  background-color: #d9d9d9 !important;
  width: 4px !important;
}

.TasksCustomization_dropdownScrollingMenu__27W5j::-webkit-scrollbar-track {
  background-color: #d9d9d9 !important;
  width: 4px !important;
}

.TasksCustomization_dropdownScrollingMenu__27W5j::-webkit-scrollbar-thumb {
  background-color: #666666 !important;
  width: 16px !important;
}

.TasksCustomization_firstItem__yXq49 {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 11.72px !important;
  text-align: left !important;
  color: #666666 !important;
}

.TasksCustomization_checkboxLabelWrapper__2C7Yg {
  display: flex;
}

.TasksCustomization_label__2TbMr {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.TasksCustomization_typeCheckbox__2yTs2 {
  margin-right: 12px;
}

.ui.dropdown .menu > .item.notSelected {
  border-top: 1px solid #dededf !important;
}

.TasksCustomization_ui__1iIa2.TasksCustomization_multiple__JT4ML.TasksCustomization_dropdown__2qOI4 > .TasksCustomization_text__1nYqL {
  background-color: red;
}

.TasksCustomization_typeCheckbox__2yTs2 label::before {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.TasksCustomization_typeCheckbox__2yTs2 label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.dropdown .menu > .TasksCustomization_clearButton___8lE6 {
  cursor: pointer;
  text-transform: none !important;
  color: var(--color-error-delete) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
}

.TaskPrint_printTableWrapper__ufxXp {
  margin-right: 20px !important;
}

.TaskPrint_printTableRow__B_zSq {
  display: flex;
}

.TaskPrint_printTableHeader__1fije {
  width: 1100px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #ebf6e9;
  padding-left: 21px;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px;
  text-align: left;
}

.TaskPrint_printTableLogo__2HyAP {
  display: flex;
  justify-content: flex-end;
  height: 50px !important;
  margin-left: 12px !important;
  margin-top: 12px !important;
}

.TaskPrint_contentSection__dCBcn {
  margin: 20px;
}

.TaskPrint_totalHeaderSection__3gWA- {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dededf;
}

.TaskPrint_totalHeader__1Dkgj {
  color: #666666;
  padding-bottom: 4px !important;
  width: 25%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.TaskPrint_totalValueSection__2zTqV {
  width: 100%;
  display: flex;
}

.TaskPrint_totalValue__2_OJ7 {
  color: #212121;
  padding-bottom: 4px !important;
  width: 25%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.TaskPrint_totalAssigneeHeaderSection__3W2td {
  width: 100%;
  margin-top: 16px;
  display: flex;
  border-bottom: 1px solid #dededf;
}

.TaskPrint_totalAssigneeHeader__1FlIH {
  color: #666666;
  padding-bottom: 4px !important;
  width: 100%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.TaskPrint_totalAssigneeValueSection__aUzCQ {
  width: 100%;
  display: flex;
}

.TaskPrint_totalAssigneeValue__1oBzp {
  color: #212121;
  padding-bottom: 4px !important;
  width: 100%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.TaskPrint_blockWrapper__2sFrw {
  width: 100%;
  padding-top: 7px;
  padding-left: 15px;
  background-color: #eeeeef !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
  border-bottom: 1px solid black !important;
}

.TaskPrint_subTableHeaderSection__3XM-4 {
  display: flex;
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.TaskPrint_subTableHeader__11NaA {
  height: 30px;
  padding-top: 7px;
  padding-left: 15px;
  width: calc(100% / 6);
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.TaskPrint_subTableHeader__11NaA:not(:last-of-type) {
  border-right: 1px solid #dededf;
}

.TaskPrint_subTableValueSection__3mgvX {
  display: flex;
  border-bottom: 1px solid black !important;
}

.TaskPrint_subTableValue__BxOmp:not(:last-of-type) {
  border-right: 1px solid #dededf;
}

.TaskPrint_subTableValue__BxOmp {
  height: 35px;
  padding-top: 10px;
  padding-left: 15px;
  width: calc(100% / 6);
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  border-top: 1px solid #dededf;
}

.TaskPrint_subTableActiveValue__3lS-f {
  background-color: #ebf6e9 !important;
  height: 35px;
  padding-top: 10px;
  padding-left: 15px;
  width: calc(100% / 6);
  color: #0b8b42 !important;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  border-top: 1px solid #dededf;
}

.SlashingTask_root__100I1 {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.SlashingTask_contentWrapper__2cBWt {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.SlashingTask_headerContainer__xlrji {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px !important;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.SlashingTask_header__yxyPo {
  display: flex;
  align-items: center;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.SlashingTask_formContainer__2bEeM {
  overflow-y: hidden !important;
  height: calc(100vh - 100px);
  background-color: white;
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding-top: 0px !important;
  margin-top: 24px !important;
}

.SlashingTask_backButtonContainer__2shTB {
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 36px;
  height: 36px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 9px 0px #0000001a;
  background: #ffffff;
  margin-right: 16px;
}

.SlashingTask_backButton__2EZeJ {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
}

.SlashingTask_taskName__3SR2w {
  font-family: Roboto;
  font-size: 34px;
  font-weight: 300;
  line-height: 51px;
  text-align: left;
  color: #212121;
  margin-left: 16px;
}

.SlashingTask_statusWrapper__1_wwU {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.SlashingTask_tabSection__1wP-G {
  box-shadow: 0px 4px 9px 0px #0000001a !important;
  padding: 16px 16px 10px 16px;
  grid-gap: 16px;
  gap: 16px;
}

.SlashingTask_infoSection__39AbN {
  display: flex;
  justify-content: space-between;
}

.SlashingTask_switchButton__19HBq {
  margin-top: 16px !important;
  width: 200px !important;
}

.SlashingTask_completedBtn__1xk_N {
  margin-top: 16px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  background-color: #f6f7f8;
  color: #b3b3b3;
}

.SlashingTask_activeCompletedBtn__zwssR {
  margin-top: 16px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  background-color: #0e9354 !important;
  color: white !important;
}

.SlashingTask_progressLineWrapper__3pSpk {
  width: calc(100% - 265px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 16px;
}

.SlashingTask_tableSection__3khBZ {
  margin-top: 16px;
}

.SlashingTask_sidebarWrapper__3NigW.ui.sidebar {
  width: 600px;
}

.SlashingTask_sidebarHolder__3_MGT {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 15px;
  height: 100%;
}

.ui.button.SlashingTask_activeBtn__2F7QS {
  border: 1px solid #0e9354 !important;
  background: none !important;
  color: #0e9354;
}

.ui.button.SlashingTask_activeBtn__2F7QS:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  border-radius: 4px !important;
}

.SlashingTask_areatableLoader__3Lo-_ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Table_areaTable__22fVr {
  width: calc(100% - 32px) !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  border: none !important;
  border-spacing: 8px !important;
}

.Table_allCheck__1Ep9l {
  width: 50px;
  background-color: white !important;
  border: none !important;
  display: flex;
  align-items: center;
}

.Table_header__3PvSm {
  padding-left: 0px !important;
  background-color: white !important;
  border: none !important;
}

.Table_lineCheckbox__2jC-j {
  top: -4px;
  left: -9%;
}

.Table_lineCheckbox__2jC-j label::after {
  top: 4px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.Table_lineCheckbox__2jC-j label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
}

.Table_lineAllCheckbox__2PgMp {
  display: block !important;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
  left: 12% !important;
}

.Table_lineAllCheckbox__2PgMp label::after {
  top: 4px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.Table_lineAllCheckbox__2PgMp label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
}

.Table_lineCheckboxMinus__3_jh9 {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #0e9354;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -5px;
  left: 15%;
}

.Table_minusIcon__IyJYW {
  font-size: 12px !important;
  display: flex !important;
  padding-top: 2px !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0e9354 !important;
}

.Table_areaTableBodyRow__3-Ixg {
  height: 50px;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  border-radius: 8px !important;
}

.Table_areaTableBodyCell__2xenO {
  width: 10% !important;
  padding: 0px !important;
  border-top: none !important;
}

.Table_areaTableBodyWtCell__-5UAY {
  width: 15% !important;
  padding: 0px !important;
  border-top: none !important;
}

.Table_areaTableBodyBlockCell__2mMLv {
  width: 12% !important;
  padding: 0px !important;
  border-top: none !important;
}

.Table_areaCompleteTableBodyBlockCell__1QZaW {
  width: 12% !important;
  padding: 0px !important;
  padding-left: 10px !important;
  border-top: none !important;
}

.Table_areaTableBodyRow__3-Ixg .Table_areaTableBodyCell__2xenO:first-child {
  width: 5% !important;
  padding-left: 0px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.Table_areaTableBodyRow__3-Ixg .Table_areaTableBodyCell__2xenO:last-child {
  width: 8% !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.Table_tableContainer__2VuQE {
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.Table_editIcon__CM8an {
  cursor: pointer;
  margin-right: 15px !important;
  color: #0e9354;
}

.Table_undoIcon__P-vHR {
  cursor: pointer;
  margin-right: 5px !important;
  color: #0e9354;
}

.Table_sectionTableWrapper__2TtjH {
  display: flex;
}

.Table_navigateMenu__11vce {
  width: 340px;
  box-shadow: 0px 4px 9px 0px #0000001a;
  padding: 16px;
}

.Table_generalInfoSection__39mcr {
  border-bottom: 1px solid #dededf !important;
  padding-bottom: 24px;
}

.Table_detailsSection__1p24e {
  padding-top: 24px;
}

.Table_activeMenuHeader__wYl8W {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #0e9354;
}

.Table_menuHeader__2FCZ9 {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #212121;
}

.Table_menuSubHeader__36xHq {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #666666;
}

.Table_navigateTableContainer__3jrPK {
  border-left: 1px solid #dededf;
  width: calc(100% - 354px);
  height: calc(100vh - 270px) !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.Table_sectionTable__38_AF {
  margin: 0px !important;
}

.Table_tableSegment__s0OfL {
  width: 98%;
  padding: 16px;
  margin-left: 14px;
  margin-bottom: 5px !important;
  box-shadow: 0px 1px 4px 0px #00000026;
}

.Table_headerSection__L_o18 {
  height: 40px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  vertical-align: baseline;
}

.Table_valueSection__19Pj_ {
  height: auto !important;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-wrap: wrap !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.Table_tableHeader__1P2_S {
  font-family: Roboto !important;
  padding-left: 14px !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  color: #212121 !important;
}

.Table_tableSubHeader__Ts6w3 {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
  padding-bottom: 16px !important;
}

.Table_activeMenuItem__1R3-U {
  color: #0e9354;
  /* Customize this color to your preference */
}

.Table_card__3Mszz {
  background-color: #f6f7f8;
  border: 1px solid #b3b3b3;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 10px;
  border-radius: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.Table_emptyTableWrapper__1DryV {
  width: 100%;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.Table_emptyTableText__2rRvv {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.Table_dateContainer__2na_q {
  display: flex;
  flex-direction: column;
}

.ui.modal.Table_confirmModal__1cdaK {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}

.Table_areatableLoader__1Py9b {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

.SideBar_root__1lMO_ {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SideBar_sidebarWrapper__3G15w {
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SideBar_headerWrapper__Rq1Lh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dededf;
}

.SideBar_header__1SLce {
  font-family: Roboto !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: #212121;
}

.SideBar_editBtn__1Il3- {
  cursor: pointer;
  color: #0e9354;
  margin-right: 24px !important;
}

.SideBar_closeBtn__1Et3L {
  cursor: pointer;
  color: #666666;
}

.SideBar_contentWrapper__2Hhr6 {
  overflow: auto;
  flex: 0 1 100%;
  height: 75%;
  overflow-y: auto;
}

.SideBar_blockContainer__1aiaw {
  padding-top: 0px !important;
}

.SideBar_blockContainer__1aiaw:nth-child(4) {
  margin-bottom: 15px;
}

.SideBar_sectionTable__2II1B {
  margin: 0px !important;
}

.SideBar_headerSection__3qJvt {
  height: 40px !important;
  vertical-align: baseline;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.SideBar_valueSection__2ZXRg {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.ui.button.SideBar_restoreBtn__2uvUT {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

.ui.button.SideBar_restoreBtn__2uvUT:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

.ui.button.SideBar_restoreBtn__2uvUT:hover i {
  opacity: 1 !important;
  color: white !important;
}

.ui.button.SideBar_restoreBtn__2uvUT:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.SideBar_iconWrapper__14iOI {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.SideBar_btnWrpper__3fPKb {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.ui.modal.SideBar_confirmModal__4alua {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}

.CompletionModal_root__deMYK :not(i.icon),
.CompletionModal_root__deMYK .ui.header {
  font-family: Roboto, sans-serif !important;
}

.CompletionModal_root__deMYK .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.CompletionModal_root__deMYK .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.CompletionModal_confirmModal__2T_0F {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}

.CompletionModal_root__deMYK .ui.grid {
  margin: 0;
}

.CompletionModal_textButton__1vThl.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.CompletionModal_textButtonContainer__1RKmn {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.CompletionModal_ui__cHXp0.CompletionModal_grid__1zLcH > .CompletionModal_row__6QuI1 > .CompletionModal_column__259lq.CompletionModal_column-close__CFNx7 {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.CompletionModal_modalHeader__2yNsB {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.CompletionModal_modalContentContainer__1M_3g {
  min-height: 70vh;
  scrollbar-width: none;
}

.CompletionModal_modalContentContainer__1M_3g::-webkit-scrollbar {
  display: none;
}

.CompletionModal_closeIcon__1wciC {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.CompletionModal_progressBar__15Npo {
  height: 100px;
  width: 100%;
  background-color: #f5f7f7 !important;
}

.CompletionModal_progressBarHeader__KY3Cz {
  color: #212121 !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  padding-left: 16px;
}

.CompletionModal_assigneeHeader__2AEat {
  display: flex;
  justify-content: space-between !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  border-bottom: 1px solid#DEDEDF !important;
  padding-bottom: 9px;
}

.CompletionModal_deleteBtn__2fwu7 {
  color: #8c0d24 !important;
  cursor: pointer;
}

.CompletionModal_addBtnWrapper__2q2f2 {
  display: flex;
  justify-content: flex-end;
}

.CompletionModal_addBtn__3FTMB {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #0e9354 !important;
  background: none !important;
  border: 1px solid #0e9354 !important;
}

.CompletionModal_formContainer__Jqi5D {
  margin-top: 40px !important;
}

.CompletionModal_assigneeField__3zed2 {
  padding-top: 16px;
}

.CompletionModal_assigneeAddBtn__2LJKF {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.CompletionModal_selectWorkersField__taii- {
  padding: 8px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #dededf;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.CompletionModal_selectWorkersField__taii- text {
  width: 90% !important;
  height: 25px !important;
}

.CompletionModal_comboContainer__3bOka {
  padding-top: 16px;
}

.CompletionModal_formField__5a6Or {
  width: 50%;
}

.CompletionModal_detailsBar__3CkJd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #f5f7f7 !important;
  margin-top: 32px;
  margin-bottom: 40px;
}

.CompletionModal_detailsBar__3CkJd
  .ui.fitted.toggle.checkbox
  > input:checked
  ~ label::before {
  background-color: #0e9354 !important;
}

.CompletionModal_checkBox__2Nlnk {
  display: flex !important;
  margin-right: 16px !important;
}

.CompletionModal_blockContainer__15sry {
  padding-top: 0px !important;
}

.CompletionModal_blockContainer__15sry:nth-child(4) {
  margin-bottom: 15px;
}

.CompletionModal_wrapper__1vDav {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
  padding: 0 !important;
}

.CompletionModal_wrapperRow__1kFcu {
  width: 100% !important;
  height: 65px !important;
  padding-right: 22px !important;
  padding-left: 16px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.CompletionModal_iconContainer__2k9q2 {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.CompletionModal_toggleIcon__28HNs i {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  cursor: pointer;
}

.CompletionModal_toggleIcon__28HNs.CompletionModal_openTable__6Rnb5 {
  cursor: pointer;
  padding: 5px;
}

.CompletionModal_columnContainer__3vK7S {
  width: 90% !important;
  display: flex;
  justify-content: start !important;
  align-items: center;
}

.CompletionModal_nameColumn__1_Me_ {
  margin-right: 32px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  flex: 1 1;
}

.CompletionModal_iconColumn__2d4RS {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #b00020;
  font-size: 14px !important;
}

.CompletionModal_productHeader__2Vdft {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.CompletionModal_formWrapper__18W72 {
  width: 100% !important;
}

.CompletionModal_editModalSegment__3QgWr {
  box-shadow: none !important;
}

.CompletionModal_areaWrapper__2ihe3 {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #dededf;
}

.CompletionModal_claendarWrapper__1rPLR {
  position: relative;
  display: flex;
}

.CompletionModal_startDate__2kCSh {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 15px;
}

.CompletionModal_endDate__1q5zT {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 100px;
}

.CompletionModal_placeholderStart__3GxLK {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 15px;
  color: #b3b3b3;
}

.CompletionModal_placeholderEnd__2iEGB {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 100px;
  color: #b3b3b3;
}

.CompletionModal_dateRangePicker__i5zUW {
  width: 100%;
  height: 40px;
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
}

.CompletionModal_claendarIconWrapper__2Hc_H {
  position: relative;
  top: 10px;
  right: 33px;
}

.CompletionModal_disabledField__1yt7x {
  background-color: #dededf !important;
}

.CompletionModal_generalAttachmentsContainer__2d-Hm {
  display: flex !important;
  flex-wrap: wrap;
}

.CompletionModal_generalAttachmentsButton__1pGz3 {
  margin-top: 5px !important;
  margin-left: auto !important;
}

.CompletionModal_wtAddBtnWrapper__3Blog {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 10px;
  padding-right: 14px;
}

.CompletionModal_wtAddBtn__13Bhg {
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #0e9354 !important;
}

.rmdp-container {
  height: 40px !important;
  width: 100% !important;
}

.EditModal_root__bGeEP :not(i.icon),
.EditModal_root__bGeEP .ui.header {
  font-family: Roboto, sans-serif !important;
}

.EditModal_root__bGeEP .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.EditModal_root__bGeEP .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.EditModal_root__bGeEP .ui.grid {
  margin: 0;
}

.EditModal_modalHeader__39MLV {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.EditModal_modalContentContainer__3ztdC {
  min-height: 70vh;
  scrollbar-width: none;
}

.EditModal_modalContentContainer__3ztdC::-webkit-scrollbar {
  display: none;
}

.ui.modal.EditModal_confirmModal__1ThLK {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}

.EditModal_formWrapper__2hTfq {
  width: 100% !important;
}

.EditModal_editModalSegment__25mLz {
  box-shadow: none !important;
}

.EditModal_formField__2nRTW {
  width: 50%;
}

.EditModal_disabledField__2F3iT {
  background-color: #dededf !important;
}

.EditModal_textButton___QC0v.ui.button {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.EditModal_textButtonContainer__1-SiL {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.EditModal_ui__TSuwP.EditModal_grid__1HrtR > .EditModal_row__1I5uV > .EditModal_column__1jfUp.EditModal_column-close__2NIfF {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.EditModal_closeIcon__3_kOT {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.EditModal_progressBar__3mm0A {
  height: 100px;
  width: 100%;
  background-color: #f5f7f7 !important;
}

.EditModal_progressBarHeader__3W4qQ {
  color: #212121 !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  padding-left: 16px;
}

.EditModal_assigneeHeader__3Pxtv {
  display: flex;
  justify-content: space-between !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  border-bottom: 1px solid#DEDEDF !important;
  padding-bottom: 9px;
}

.EditModal_deleteBtn__1wQFj {
  color: #8c0d24 !important;
  cursor: pointer;
}

.EditModal_addBtnWrapper__23etz {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px !important;
}

.EditModal_addBtn__2d-IV {
  height: 36px;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #0e9354 !important;
  background: none !important;
  border: 1px solid #0e9354 !important;
  display: flex !important;
  align-items: center !important;
}

.EditModal_addBtn__2d-IV i {
  padding-bottom: 20px !important;
}

.EditModal_formContainer__2Bim- {
  margin-top: 40px !important;
}

.EditModal_assigneeField__2_I7E {
  padding-top: 16px;
}

.EditModal_assigneeAddBtn__2wy4l {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.EditModal_selectWorkersField__QlvUO {
  padding: 8px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #dededf;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.EditModal_selectWorkersField__QlvUO text {
  width: 90% !important;
  height: 25px !important;
}

.EditModal_comboContainer__2HO8e {
  padding-top: 16px;
}

.EditModal_detailsBar__zHTed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #f5f7f7 !important;
  margin-top: 32px;
  margin-bottom: 40px;
}

.EditModal_detailsBar__zHTed
  .ui.fitted.toggle.checkbox
  > input:checked
  ~ label::before {
  background-color: #0e9354 !important;
}

.EditModal_checkBox__18DeV {
  display: flex !important;
  margin-right: 16px !important;
}

.EditModal_blockContainer__sessg {
  padding-top: 0px !important;
}

.EditModal_blockContainer__sessg:nth-child(4) {
  margin-bottom: 15px;
}

.EditModal_wrapper__3mps1 {
  width: 100% !important;
  display: flex;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.EditModal_blockWrapper__3f4SX {
  width: 100% !important;
  display: flex;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.EditModal_wrapperRow__1vfSA {
  width: 100% !important;
  height: 65px !important;
  padding-right: 22px !important;
  padding-left: 16px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.EditModal_iconContainer__PbnZY {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.EditModal_parentTableIconContainer__1h7HJ {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.EditModal_toggleIcon__2ydPT i {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  cursor: pointer;
}

.EditModal_toggleIcon__2ydPT.EditModal_openTable__3cgnX {
  cursor: pointer;
  padding: 5px;
}

.EditModal_columnContainer__r3OMw {
  width: 90% !important;
  display: flex;
  justify-content: start !important;
  align-items: center;
}

.EditModal_nameColumn__3ksKP {
  margin-right: 32px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  flex: 1 1;
}

.EditModal_iconColumn__2qkas {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #b00020;
  font-size: 14px !important;
}

.EditModal_productHeader__3P9TF {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.EditModal_claendarWrapper__1P5Ej {
  position: relative;
  display: flex;
}

.EditModal_startDate__11n1g {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 15px;
}

.EditModal_endDate__1u4mI {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 100px;
}

.EditModal_placeholderStart__1sxzj {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 15px;
  color: #b3b3b3;
}

.EditModal_placeholderEnd__3Wlmi {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 100px;
  color: #b3b3b3;
}

.EditModal_dateRangePicker__1gtv_ {
  width: 100%;
  height: 40px;
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
}

.EditModal_claendarIconWrapper__zEshx {
  position: relative;
  top: 10px;
  right: 33px;
}

.EditModal_generalAttachmentsContainer__3xHPr {
  display: flex !important;
  flex-wrap: wrap;
}

.EditModal_generalAttachmentsButton__3dOIJ {
  margin-top: 5px !important;
  margin-left: auto !important;
}

.EditModal_wtAddBtnWrapper__1k6YZ {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 10px;
}

.EditModal_wtAddBtn__L2_H2 {
  background: none !important;
  cursor: pointer !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #0e9354 !important;
}

.EditModal_nameWrapepr__2sIsp {
  width: 200px !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
}

.rmdp-container {
  height: 40px !important;
  width: 100% !important;
}

.AddTask_root__LIBWY {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.AddTask_areaModal__2Oaky :not(i.icon),
.AddTask_areaModal__2Oaky .ui.header {
  font-family: Roboto, sans-serif !important;
}

.AddTask_areaModal__2Oaky .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.AddTask_areaModal__2Oaky .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.AddTask_confirmModal__3ul7M {
  margin: 30%;
}

.AddTask_areaModal__2Oaky .ui.grid {
  margin: 0;
}

.AddTask_modalContent__v4Lmw {
  min-height: calc(70vh);
}

.AddTask_contentWrapper__3amgg {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.AddTask_headerContainer__NCqzU {
  height: 60px !important;
  padding-left: 16px;
  padding-top: 16px;
}

.AddTask_header__3L1PP {
  display: flex;
  align-items: center;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.AddTask_formContainer__3g2_T {
  background: transparent;
  overflow-y: hidden !important;
  height: calc(100vh - 100px);
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding-top: 0px !important;
  margin-top: 24px !important;
}

.AddTask_buttonContainer__2lJoD {
  width: 100% !important;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #ffffff;
  height: 8% !important;
  margin-top: 24px;
  position: fixed;
  z-index: 2;
  bottom: 5px;
}

.AddTask_buttonWrapper__Tv6EP {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 16px;
  height: 5%;
}

.AddTask_cancelButton__NZRF8 {
  width: 84px !important;
  height: 36px;
  margin-right: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.AddTask_saveButton__3nUgt {
  width: 71px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.AddTask_sectionTableWrapper__3d_mf {
  background: transparent;
  display: flex;
}

.AddTask_navigateMenu__cTMHb {
  border-radius: 4px;
  background-color: white;
  width: 340px;
  box-shadow: 0px 4px 9px 0px #0000001a;
  padding: 16px;
}

.AddTask_generalInfoSection__2h9t2 {
  border-bottom: 1px solid #dededf !important;
  padding-bottom: 24px;
}

.AddTask_detailsSection__PBJxk {
  padding-top: 24px;
}

.AddTask_menuHeader__Iyvk5 {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #212121;
}

.AddTask_activeMenuHeader__wl1tw {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #0e9354 !important;
}

.AddTask_menuSubHeader__2hmjg {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #666666;
}

.AddTask_activeSubHeader__gmogY {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #0e9354 !important;
}

.AddTask_navigateTableContainer__2jXLu {
  margin-left: 14px;
  border-radius: 4px;
  background-color: white;
  width: calc(100% - 354px);
  height: calc(100vh - 270px) !important;
  overflow-y: auto;
  overflow-x: hidden !important;
  padding: 16px;
  max-height: 1150px;
}

.AddTask_sectionTable__1VFlX {
  margin: 0px !important;
}

.AddTask_tableSegment__28Z4g {
  width: 100%;
  padding: 16px !important;
  margin-bottom: 16px !important;
  box-shadow: 0px 1px 4px 0px #00000026;
}

.AddTask_headerSection__Ast6x {
  height: 40px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  vertical-align: baseline;
}

.AddTask_valueSection__3Wb3Z {
  height: auto !important;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-wrap: wrap !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.AddTask_tableHeader__1WM3a {
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.AddTask_tableSubHeader__12wUC {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
  padding-bottom: 16px !important;
}

.AddTask_activeMenuItem__1E3nu {
  color: #0e9354;
  /* Customize this color to your preference */
}

.AddTask_card__Cmdhh {
  background-color: #f6f7f8;
  border: 1px solid #b3b3b3;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 10px;
  border-radius: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.AddTask_emptyTableWrapper__3_uJh {
  width: 100%;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.AddTask_emptyTableText__SOFeR {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.AddTask_dateContainer__1ZBkN {
  display: flex;
  flex-direction: column;
}

.AddTask_warnMessage__3RWMz {
  margin-top: 4px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #f0ac47 !important;
}

.AddTask_formLabel__5-Tvh {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.AddTask_dropdownStyle__36dmW {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: space-between !important;
}

.AddTask_dropdownCustomStyle__2hMe1 {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: end !important;
}

.AddTask_dropdownCustomStyle__2hMe1 .menu {
  max-height: 250px;
}

.AddTask_dropdownCustomStyle__2hMe1 input {
  display: none;
}

.AddTask_dropdownCustomStyle__2hMe1 .menu > * {
  background-color: white !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.AddTask_dropdownCustomStyle__2hMe1 .menu > .AddTask_item__1hLHB :hover {
  background-color: rgb(237, 236, 236) !important;
}

.AddTask_dropdownCustomStyle__2hMe1 .label {
  display: none !important;
}

.AddTask_dropdownCustomStyle__2hMe1 > .label ~ .text {
  display: contents !important;
}

.AddTask_generalAttachmentsContainer__2Q5B2 {
  display: flex !important;
  flex-wrap: wrap;
}

.AddTask_generalAttachmentsButton__1LANn {
  margin-top: 5px !important;
  margin-left: auto !important;
}

.AddTask_locationHeader__1Zc0S {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
}

.AddTask_locationBtnWrapper__xnYBy {
  display: flex;
  justify-content: space-between;
}

.AddTask_addBtn__325Zy {
  display: flex;
  align-items: center;
  margin-top: 16px !important;
  height: 40px !important;
  color: #0e9354 !important;
  background: none !important;
  padding-left: 0 !important;
}

.AddTask_switchButtonWrapper__2DlwA {
  margin-top: 16px !important;
  width: 200px !important;
}

.AddTask_areasFileWrapper__1qKVL {
  display: block !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.AddTask_modalHeader__W8pOb {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.AddTask_locationSection__3HMyn {
  padding-top: 24px;
  border-bottom: 1px solid #dededf;
  padding-bottom: 24px;
}

.AddTask_machinerList__Z7GTw {
  padding-top: 25px;
}

.AddTask_plusIcon__va9lt {
  display: flex;
  align-items: center;
  font-size: 12px !important;
}

.AddTask_input__1X95t {
  height: 40px !important;
}

.AddTask_calendarIcon__2r6Zv {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 36px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.AddTask_rdt__3UX9D input,
.AddTask_rdt__3UX9D .AddTask_form-control__1ZUF4 {
  color: #0e9354 !important;
}

.AddTask_slashingOpenBtn__1kVr7 {
  padding-left: 5px !important;
  font-style: italic;
  font-weight: 400;
  color: #0e9354;
  cursor: pointer;
}

.AddTask_menuContainer__2nqrG {
  overflow: hidden !important;
  top: 100% !important;
  left: 0px !important;
  width: 100.5% !important;
  min-height: 300px !important;
  border-top: none !important;
}

.AddTask_machineryMenuContainer__3T7gJ {
  overflow: hidden !important;
  top: 105% !important;
  left: 0px !important;
  width: 100.5% !important;
  z-index: 1000;
}

.AddTask_searchContainer__3AUvD {
  width: 100% !important;
  display: flex !important;
  align-items: center;
}

.AddTask_searchInput__2YBFm {
  margin: 0 !important;
  width: 100% !important;
}

.AddTask_searchInput__2YBFm input {
  display: block !important;
  width: 95% !important;
  margin: 10px !important;
  padding-left: 10px !important;
}

.AddTask_searchInput__2YBFm i {
  margin-left: 10px !important;
}

.AddTask_dropdonwIcon__3n_6H {
  position: absolute;
  left: 93%;
  top: 35%;
}

.AddTask_expirCalendar__1TREQ {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddTask_listMenu__2HXjf {
  border-top: none !important;
}

.AreasTree_modalHeader__2sydD {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

.AreasTree_formSmallField__3wPJJ {
  width: 16.5%;
}

.AreasTree_formSmallDropDown__1Bjye {
  max-width: 100% !important;
  margin: 0 !important;
}

.AreasTree_formSmallDropDown__1Bjye div {
  min-width: 16.5% !important;
}

.AreasTree_workersRow__3Gqfj {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.AreasTree_workersRowHead__1v6gW {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.AreasTree_addedItem__2dtrR {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.AreasTree_rowContainer__25xh_ {
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 10px !important;
}

.AreasTree_expandedIcon__2s6EC {
  cursor: pointer;
}

.AreasTree_closeIcon__315Ml {
  margin-bottom: 5px;
}

.AreasTree_dateRow__ydfBl {
  border-bottom: 1px solid #dededf !important;
}

.AreasTree_firstStepForm__4HpOw {
  padding-left: 9px !important;
  width: 100% !important;
}

.AreasTree_scanDateField__1Rz-a {
  padding-bottom: 14px;
}

.AreasTree_horizontalLine__3cJqp {
  border: none;
  background-color: #dededf;
  height: 1px;
  color: #dededf !important;
}

.AreasTree_tableGroup__1S2OZ {
  display: block !important;
  padding-left: 5px !important;
}

.AreasTree_searchInput__1mSJb {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.AreasTree_searchInput__1mSJb input {
  padding: 3px 6px 3px 16px !important;
}

.AreasTree_searchInput__1mSJb input::placeholder {
  color: #b3b3b3;
}

.AreasTree_searchInput__1mSJb input {
  padding: 3px 6px 3px 16px !important;
}

.AreasTree_searchInput__1mSJb input::placeholder {
  color: #b3b3b3;
}

.AreasTree_filterContainer__FOxh8 {
  display: flex;
  align-items: center;
  margin-left: 8px !important;
  grid-gap: 8px;
  gap: 8px;
}

.AreasTree_searchAndFiltersContainer__2HqX0 {
  display: flex !important;
  margin-bottom: 16px;
}

.AreasTree_toggleIcon__1xFJD {
  height: 40px;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.AreasTree_openTable__ye3bp {
  min-height: 40px;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.AreasTree_tbody__1BFH7 {
  display: block !important;
  vertical-align: middle !important;
  width: 100%;
}

.AreasTree_icomNameContainer__3BV5e {
  width: 100% !important;
}

.AreasTree_chevronContainer__3k7mp {
  width: 100% !important;
}

.AreasTree_patchRow__G_PCK {
  display: block !important;
}

.AreasTree_patchCell__3nkJ4 {
  display: block !important;
  padding: 0 !important;
  height: 32px !important;
  padding-left: 25px !important;
  border: none !important;
}

.AreasTree_blockName__1qU8Q {
  width: 25%;
  padding-left: 10px !important;
}

.AreasTree_cropField__1xu4H {
  width: 25%;
  padding-left: 40px !important;
}

.AreasTree_patchName__3ztYK {
  padding-left: 10px !important;
  width: 25% !important;
}

.AreasTree_closeTable__3iM5k {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.AreasTree_iconNameWrapper__6p0IK {
  display: flex;
  align-items: center !important;
  width: 100%;
}

.AreasTree_selectAllButton__1uj6z {
  display: flex !important;
  align-items: center !important;
  height: 30px;
  background: #28b573 !important;
  color: white !important;
  cursor: pointer !important;
  padding: 11px !important;
}

.AreasTree_expandedIcon__2s6EC {
  cursor: pointer;
}

.AreasTree_areaModal__CNQPa :not(i.icon),
.AreasTree_areaModal__CNQPa .ui.header {
  font-family: Roboto, sans-serif !important;
}

.AreasTree_areaModal__CNQPa .ui.segment {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.AreasTree_areaModal__CNQPa .ui.form .field > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.ui.modal.AreasTree_confirmModal__2-vSJ {
  margin: 30%;
}

.AreasTree_areaModal__CNQPa .ui.grid {
  margin: 0;
}

.AreasTree_modalContent__3ix3h {
  min-height: calc(70vh);
}

.AreasTree_contentWrapper__2sd9m {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.AreasTree_paginationContainer__1yiQU {
  width: 40%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.AreasTree_searchContainet__1_fnj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.AreasTree_modalActionBtn__13doh {
  display: flex !important;
  justify-content: end;
  align-items: center;
}

.AreasTree_mainDropdown__1bchl {
  min-width: 100px !important;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.AreasTree_paginationDropDown__Hm5ub {
  min-width: 120px !important;
}

.AreasTree_paginationDropDown__Hm5ub i {
  padding: 0 !important;
  padding-top: 4px !important;
}

.AreasTree_pageSelectDropdown__VZR6M {
  min-width: 120px !important;
}

.AreasTree_pageSelectDropdown__VZR6M i {
  padding: 0 !important;
  padding-top: 4px !important;
}

.AreasTree_pagination__GlbON {
  height: 38px;
  border: none !important;
  box-shadow: none !important;
}

.AreasTree_pagination__GlbON a {
  height: 38px;
  background-color: white !important;
}

.AreasTree_pagination__GlbON > a::before {
  display: none !important;
}

.AreasTree_dropdownLabel__n66KB {
  padding-right: 10px !important;
}

.AreasTree_pagination__GlbON .active.item {
  display: none !important;
}

.AreasTree_mainDropdown__1bchl .menu > .item:hover {
  z-index: 1 !important;
}

.AreasTree_sellectAllBtn__3WVYC {
  background: none !important;
  color: rgb(89, 182, 85) !important;
  border: 1px solid rgb(89, 182, 85) !important;
}

.AreasTable_tableContainer__1ebg4 {
  margin-top: 20px;
}

.AreasTable_areaTable__2UDio {
  font-family: "Roboto" !important;
  width: 100% !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  border: none !important;
  border-spacing: 8px !important;
}

.AreasTable_areaTable__2UDio th {
  border: none !important;
  background: none !important;
}

.AreasTable_header__2MMMv {
  width: 25%;
  padding-left: 0px !important;
  background-color: white !important;
  border: none !important;
}

.AreasTable_areaTableBodyRow__HLtsi {
  height: 40px;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  border-radius: 8px !important;
}

.AreasTable_areaTableBodyBlockCell__Ag27P {
  width: 25% !important;
  padding: 0px !important;
  border-top: none !important;
  padding-left: 16px !important;
}

.AreasTable_deleteIcon__2Oe1o {
  cursor: pointer;
  color: #b00020 !important;
}

.AreasTable_cleatAll__3pA_x {
  cursor: pointer;
  color: #b00020 !important;
}

.AreasTable_blockPatchWrapper__1oHtQ {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.AreasTable_iconCell__1vu_d {
  display: flex;
  justify-content: flex-end;
  padding-right: 25px !important;
}

.AreasTable_iconBodyCell__1EIAI {
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
  padding-right: 40px !important;
}

.AreasTable_areaTable__2UDio tr td {
  border-top: none !important;
}

.rmdp-container {
  height: 40px !important;
  width: 100% !important;
}

.WorkingTime_btnWrapper__2FVjK {
  display: flex;
  justify-content: center;
  align-items: center;
}

.WorkingTime_editBtn__2lFBo {
  background: none !important;
  color: #0e9354 !important;
}

.WorkingTime_doubleCheck__1mYIR {
  cursor: pointer;
  background: none !important;
  color: #0e9354 !important;
  padding: 0.78571429em 0.78571429em 0.78571429em !important;
}
.WorkingTime_checkMark__2wHSm {
  cursor: pointer;
  background: none !important;
  color: #0e9354 !important;
  padding: 0.78571429em 0.78571429em 0.78571429em !important;
}

.WorkingTime_deleteButton__W-Iu5 {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: rgba(176, 0, 32, 1) !important;
  position: relative;
  cursor: pointer;
}

.WorkingTime_deleteButton__W-Iu5 i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.WorkingTime_deleteButton__W-Iu5:hover {
  color: rgb(148, 7, 33) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.ui.button.WorkingTime_addButton__2405R {
  height: 39px;
  background-color: var(--color-green) !important;
}

.ui.button.WorkingTime_clockInButton__1Rc3w {
  background: none !important;
  border: 1px solid var(--color-green) !important;
  color: var(--color-green);
}

.ui.button.WorkingTime_clockInButton__1Rc3w:hover {
  color: var(--color-green);
}

.ui.button.WorkingTime_clockOutBtn__1jcLi {
  background: none !important;
  border: 1px solid var(--color-green) !important;
  color: var(--color-green);
}

.ui.button.WorkingTime_totalClockOutBtn__6qBv0 {
  height: 39px;
  background-color: var(--color-green) !important;
  color: white;
}

.WorkingTime_checkMarkWrapper__22ECY {
  display: flex;
  width: 43px;
}
.WorkingTime_editButtonWrapper__2Ycii {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.WorkingTime_editButton__2fX-Z {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
  cursor: pointer;
}

.WorkingTime_editButton__2fX-Z i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.icon.WorkingTime_editButton__2fX-Z:hover {
  color: var(--color-green) !important;
  background: #00000008 !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.icon.WorkingTime_editButton__2fX-Z:active {
  color: var(--color-green) !important;
  background: #0000001f !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.WorkingTime_deleteButton__W-Iu5 {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #d92d20 !important;
  position: relative;
  cursor: pointer;
}

.WorkingTime_deleteButton__W-Iu5 i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #d92d20 !important;
}

.WorkingTime_deleteButton__W-Iu5:hover {
  color: #d92d20 !important;
  background: #00000008 !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.WorkingTime_deleteButton__W-Iu5:active {
  color: #d92d20 !important;
  background: #0000001f !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_root__3OD4Z .ui.segment {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
}

.Sidebar_root__3OD4Z {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.Sidebar_sidebarHeader__1COmL {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 10px !important;
}

.Sidebar_headerColumn__2tgTj {
  display: flex;
}

.ui.header.Sidebar_name__4O4fJ {
  font-size: 28px;
  font-weight: 700;
}

.Sidebar_closeButton__2Gxg2 i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.Sidebar_closeButton__2Gxg2:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__2Gxg2 {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.Sidebar_headerSection__2DTwB {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.Sidebar_infoContainer__2WyDb {
  height: 100% !important;
  padding: 0em 1em !important;
  padding-top: 9px !important;
  overflow-y: auto;
  scrollbar-width: none;
  height: auto;
}

.Sidebar_infoContainer__2WyDb::-webkit-scrollbar {
  display: none;
}

.Sidebar_infoContainer__2WyDb.ui.grid {
  margin-bottom: 0;
}

.Sidebar_infoContainer__2WyDb.ui.grid > * {
  padding-right: 0em;
  padding-left: 0em;
  padding-top: 0px !important;
}

.Sidebar_generalInfoContainer__3E0sV {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_taskDetailsTable__RppVb {
  width: 98% !important;
}

.Sidebar_btnWrapper__2rxLG {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
}

.Sidebar_editButton__2UkvC {
  color: #0e9354 !important;
  background: none !important;
}

.Sidebar_segment__2Hg11 {
  margin-left: 10px;
  width: 98%;
  margin-left: 10px !important;
  padding-top: 20px !important;
  min-height: 68px !important;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  height: auto !important;
}

.Sidebar_rowFieldWrapper__7RtyI {
  display: flex;
  align-items: center;
  width: 100%;
}

.Sidebar_nameRowField__23MhO {
  width: 45%;
  padding-left: 10px !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
}

.Sidebar_clockRowField__eRPg5 {
  width: 5%;
}

.Sidebar_rowClockIcon__3t8bP {
  color: #f0ac47 !important;
}

.Sidebar_totalRowField__1wxPy {
  width: 17%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #666666 !important;
}

.Sidebar_iconRowField__2Vl-5 {
  width: 7%;
}

.Sidebar_rowEditIcon__3IK64 {
  cursor: pointer;
  color: #0e9354;
}

.Sidebar_rowChevronIcon__27grf {
  cursor: pointer;
  color: #666666;
}

.Sidebar_editBtn__3tc8H {
  background: none !important;
  color: #0e9354 !important;
}

.Sidebar_doubleCheck__S7yIW {
  cursor: pointer;
  background: none !important;
  color: #0e9354 !important;
  padding: 0.78571429em 0.78571429em 0.78571429em !important;
}

.Sidebar_buttonContainer__-1FrG {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.Sidebar_approvedBtn__3bimY {
  background-color: #0e9354 !important;
  color: #ffffff !important;
}

.Sidebar_root__3R6oR .ui.segment {
  border-radius: 4px;
  margin: 4px 0px 6px 0px;
}

.Sidebar_root__3R6oR {
  height: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.Sidebar_sidebarHeader__uUzT2 {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 10px !important;
}

.Sidebar_headerColumn__3_ADR {
  display: flex;
}

.ui.header.Sidebar_name__1jJ9Y {
  font-size: 28px;
  font-weight: 700;
}

.Sidebar_closeButton__19SCL i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.icon.Sidebar_closeButton__19SCL:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.Sidebar_closeButton__19SCL {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.Sidebar_headerSection__33fxw {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.Sidebar_infoContainer__uNgru {
  padding: 0em 1em !important;
  padding-top: 9px !important;
  overflow-y: auto;
  scrollbar-width: none;
  height: calc(100% - 100px);
}

.Sidebar_infoContainer__uNgru::-webkit-scrollbar {
  display: none;
}

.Sidebar_infoContainer__uNgru.ui.grid {
  margin-bottom: 0;
}

.Sidebar_infoContainer__uNgru.ui.grid > * {
  padding-right: 0em;
  padding-left: 0em;
  padding-top: 0px !important;
}

.Sidebar_generalInfoContainer__aK18E {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Sidebar_taskDetailsTable__1T4Ob {
  width: 98% !important;
}

.Sidebar_btnWrapper__1Cu-H {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
}

.Sidebar_editButton__lOUyu {
  color: #0e9354 !important;
  background: none !important;
}

.Sidebar_segment__14gLY {
  margin-left: 10px;
  width: 98%;
  margin-left: 10px !important;
  padding-top: 20px !important;
  min-height: 68px !important;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  height: auto !important;
}

.Sidebar_rowFieldWrapper__2uBXj {
  display: flex;
  align-items: center;
  width: 100%;
}

.Sidebar_nameRowField__2ttxK {
  width: 70%;
  padding-left: 10px !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
}

.Sidebar_btnContainer__zzmZ0 {
  width: 25%;
}

.Sidebar_iconRowField__1b_Oe {
  width: 5%;
}

.Sidebar_rowChevronIcon__1uMZa {
  cursor: pointer;
  color: #666666;
}

.ui.button.Sidebar_clockOutBtn__CbqNA {
  display: flex;
  align-items: center;
  height: 28px !important;
  background: none !important;
  border: 1px solid var(--color-green) !important;
  color: var(--color-green);
}

.Sidebar_buttonContainer__1f0X4 {
  border-top: 1px solid var(--color-border);
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px !important;
  padding-top: 16px;
  background-color: white !important;
  display: flex !important;
  justify-content: end !important;
}

.Sidebar_clockoutBtn__23rjN {
  background-color: #0e9354 !important;
  color: #ffffff !important;
}

.ClockOut_headerWrapper__zMnwY {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ClockOut_header__huKs0 {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
}

.ClockOut_icon__2f0qC {
  cursor: pointer;
  color: #666666 !important;
}

.ClockOut_content__3SarY {
  padding: 16px;
}

.ClockOut_fixedContentRow__3LmSR {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f7f8;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
}

.ClockOut_fixedContentRowId__1PUhd {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.ClockOut_fixedContentRowType__18FCT {
  padding-left: 24px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #666666 !important;
}

.ClockOut_fixedContentRowTotalWt__zzr1q {
  padding-left: 24px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #666666 !important;
}

.ClockOut_formLabel__1CVo6 {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.ClockOut_calendarWrapper__2ksdk {
  display: flex;
  align-items: center;
}

.ClockOut_expirCalendar__R0kmH {
  padding-left: 15px;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}

.ClockOut_expirCalendar__R0kmH input {
  border: none !important;
}

.ClockOut_calendarIcon__24t8y {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.ClockOut_fixedContentForm__3Lfy6 {
  padding-top: 32px;
}

.ClockOut_dropdownCustomStyle__1YBap {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.ClockOut_dropdownCustomStyle__1YBap .menu {
  max-height: 250px;
  overflow-y: auto !important;
}

.ClockOut_dropdownCustomStyle__1YBap .menu > * {
  background-color: white !important;
  display: flex !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.ClockOut_dropdownCustomStyle__1YBap .menu > .ClockOut_item__3XABz :hover {
  background-color: rgb(237, 236, 236) !important;
}

.ClockOut_dropdownCustomStyle__1YBap .label {
  display: none !important;
}

.ClockOut_dropdownCustomStyle__1YBap > .label ~ .text {
  color: #ce0e0e;
  display: contents !important;
}

.ClockOut_dropdownCustomStyle__1YBap div {
  color: #212121 !important;
}

.ClockOut_modalContentContainer__1icBT {
  min-height: 630px !important;
}

.ClockOut_actionButtons__1Y_j6 {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.ClockOut_card__VRB2W {
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  margin: 16px !important;
  padding: 0 !important;
}

.ClockOut_employeeRow__1WZnu {
  display: flex;
}

.ClockOut_checkIconWrapper__1Ra56 {
  width: 50px;
  height: 50px !important;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  color: white;
  font-size: 12px;
  font-family: Roboto;
}

.ClockOut_checkIcon__2D5mK {
  width: 25px;
  height: 25px !important;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ClockOut_checkIcon__2D5mK i {
  color: #59b655 !important;
}

.ClockOut_avatarWrapper__3k-vW {
  display: flex;
  align-items: center;
  padding-left: 16px;
  grid-gap: 16px;
  gap: 16px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.ClockOut_form__3uy5I {
  padding: 8px 16px;
}

.ClockOut_areasRow__3pOic {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  grid-gap: 5px !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.ClockOut_areasRowHead__2w2ia {
  font-family: "Roboto" !important;
  width: 50px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.ClockOut_addedItem__3sDxW {
  display: flex !important;
  align-items: center !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.ClockOut_addedItemValue__1u4uV {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ClockOut_closeIcon__vTFPS {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ClockOut_disabledField__2hkQR {
  background-color: #dededf !important;
}

.ClockOut_rowChevronIcon__2axtN {
  cursor: pointer;
  color: #666666;
}

.ClockOut_errorMessage__38LyY {
  color: #b00020;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.AreasList_menuContainer__2pamk {
  overflow-x: hidden;
  top: 105% !important;
  left: 0px !important;
  width: 100.5% !important;
}

.AreasList_searchContainer__vwp5q {
  width: 100% !important;
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid #dededf !important;
}

.AreasList_dropdownCustomStyle__2j2EC {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
}

.AreasList_dropdownCustomStyle__2j2EC .menu > * {
  white-space: break-spaces;
  width: 300px;
  overflow-x: hidden;
}

.AreasList_dropdownCustomStyle__2j2EC input {
  display: none !important;
}

.AreasList_dropdownCustomStyle__2j2EC div {
  color: #212121 !important;
}

.AreasList_searchInput__iao-u {
  margin: 0 !important;
  width: 100% !important;
}

.AreasList_searchInput__iao-u input {
  display: block !important;
  width: 95% !important;
  margin: 10px !important;
  padding-left: 10px !important;
}

.AreasList_searchInput__iao-u i {
  margin-left: 10px !important;
}

.AreasList_dropdonwIcon__KgSYY {
  position: absolute;
  left: 93%;
  top: 35%;
}

.ClockIn_headerWrapper__1qKvj {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ClockIn_header__29YtF {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
}

.ClockIn_icon__3bUHd {
  cursor: pointer;
  color: #666666 !important;
}

.ClockIn_actionButtons__2_Jrh {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.ClockIn_modalContentContainer__2CfSA {
  min-height: 600px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.ClockIn_contentHeader__VuSQT {
  margin-bottom: 16px;
  margin-top: 32px;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #dededf;
}

.ClockIn_areasRow__2-Xzo {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  grid-gap: 5px !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.ClockIn_areasRowHead__1p5et {
  font-family: "Roboto" !important;
  width: 50px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.ClockIn_formLabel__oBxlk {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.ClockIn_addedItem__2VJCc {
  display: flex !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.ClockIn_calendarWrapper__H-jFg {
  display: flex;
  align-items: center;
}

.ClockIn_expirCalendar__3lODE {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}

.ClockIn_expirCalendar__3lODE input {
  border: none !important;
}

.ClockIn_calendarIcon__1zXbd {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.ClockIn_searchInput__3xpXz {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.ClockIn_searchInput__3xpXz input {
  padding: 3px 6px 3px 16px !important;
}

.ClockIn_searchInput__3xpXz input::placeholder {
  color: #b3b3b3;
}

.ClockIn_searchInput__3xpXz input {
  padding: 3px 6px 3px 16px !important;
}

.ClockIn_searchInput__3xpXz input::placeholder {
  color: #b3b3b3;
}

.ClockIn_content__26nEe {
  padding: 16px;
}

.ui.basic.buttons.ClockIn_buttonsGroup__19mue {
  margin-left: 18px;
  width: 400px;
  height: 32px;
  display: flex;
  align-items: center;
}

.ui.basic.buttons.ClockIn_buttonsGroup__19mue .ui.button {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: center;
  border: none;
  position: relative;
  width: 30%;
}

.ui.basic.buttons.ClockIn_buttonsGroup__19mue
  :not(div)
  + .ui.button:not(:first-child):before,
.ui.basic.buttons.ClockIn_buttonsGroup__19mue
  .ui.button:not(:nth-child(-n + 2)):before {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px !important;
  width: 1px;
  background: var(--color-light-grey);
}

.ui.basic.buttons.ClockIn_buttonsGroup__19mue .ui.button.ClockIn_buttonActive__a_lsm {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: var(--color-green) !important;
  width: 30% !important;
  height: 28px !important;
  display: flex;
  align-items: center;
}

.ui.basic.buttons.ClockIn_buttonsGroup__19mue .ui.button.ClockIn_buttonActive__a_lsm:before,
.ui.basic.buttons.ClockIn_buttonsGroup__19mue
  .ui.button.ClockIn_buttonActive__a_lsm
  + .ui.button:before {
  display: none;
}

.ui.basic.buttons.ClockIn_buttonsGroup__19mue div .ui.pointing.label {
  width: -webkit-max-content;
  width: max-content;
}

.ClockIn_serchRow__1Mhgm {
  display: flex;
  align-items: center;
}

.ClockIn_btnContentWrapper__N5AIx {
  width: 100%;
}

.ClockIn_wrapper__XkLB6 {
  border: 1px solid #dededf;
  height: 208px;
  padding: 4px 16px 4px 8px;
  border-radius: 4px 0px 0px 0px;
  overflow-y: auto;
  margin-top: 16px !important;
}

.ClockIn_employeeRow__3ThPu {
  padding: 8px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.ClockIn_groupRow__hsMVw {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.ClockIn_checkBox__3li45 label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.ClockIn_checkboxWrapper__buyEj {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.ClockIn_segment__1m21g {
  margin-left: 10px;
  min-height: 50px !important;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  height: auto !important;
}

.ClockIn_rowFieldWrapper__1luT0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ClockIn_iconRowField__1OPxo {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.ClockIn_deleteIcon__3uEUK {
  color: #b00020;
}

.ClockIn_employeeIconWrapper__XKlEo {
  display: flex;
  align-items: center;
  grid-gap: 46px;
  gap: 46px;
}

.ClockIn_rowChevronIcon__2c4YV {
  cursor: pointer;
  color: #666666;
  padding-left: 16px;
}

.ClockIn_openRows__3iT-f {
  padding-top: 16px;
}

.ClockIn_subRows__39bpP {
  padding: 8px;
  padding-left: 35px;
}

.ClockIn_minusIconWrapper__3LWYr {
  width: 17px !important;
  height: 17px !important;
  grid-gap: 0px !important;
  gap: 0px !important;
  border-radius: 2px !important;
  border: 1px solid #dededf !important;
  opacity: 0px !important;
  color: #0e9354 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ClockIn_minusIcon__2Hvpc {
  margin-bottom: 3px;
}

.ClockIn_minusIcon__2Hvpc::before {
  font-size: 9px !important;
}

.ClockIn_nameRowField__13fV4 {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.ClockIn_dropdownCustomStyle__3ysHB {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.ClockIn_dropdownCustomStyle__3ysHB div {
  color: #212121 !important;
}

.ClockIn_dropdownCustomStyle__3ysHB input {
  padding-top: 8px !important;
}

.ClockIn_dropdownCustomStyle__3ysHB .menu {
  max-height: 250px;
  overflow-y: auto !important;
}

.ClockIn_dropdownCustomStyle__3ysHB .menu > * {
  background-color: white !important;
  display: flex !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.ClockIn_dropdownCustomStyle__3ysHB .menu > .ClockIn_item__1c8yZ :hover {
  background-color: rgb(237, 236, 236) !important;
}

.ClockIn_dropdownCustomStyle__3ysHB .label {
  display: none !important;
}

.ClockIn_dropdownCustomStyle__3ysHB > .label ~ .text {
  display: contents !important;
}

.ClockIn_dateTimeCalendar__2QA48 {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddInterval_headerWrapper__3g0j6 {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.AddInterval_header__3Zhlk {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
}

.AddInterval_icon__1YdCB {
  cursor: pointer;
  color: #666666 !important;
}

.AddInterval_actionButtons__SLExK {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.AddInterval_modalContentContainer__25dYZ {
  min-height: 600px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.AddInterval_disabledField__3MZ4Y {
  background-color: #dededf !important;
}

.AddInterval_contentHeader__PIqsu {
  margin-bottom: 16px;
  margin-top: 32px;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #dededf;
}

.AddInterval_dropdownCustomStyle__16uTZ {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  grid-gap: 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.AddInterval_dropdownCustomStyle__16uTZ .menu {
  max-height: 250px;
  overflow-y: auto !important;
}

.AddInterval_dropdownCustomStyle__16uTZ .menu > * {
  background-color: white !important;
  display: flex !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.AddInterval_dropdownCustomStyle__16uTZ .menu > .AddInterval_item__33k-1 :hover {
  background-color: rgb(237, 236, 236) !important;
}

.AddInterval_dropdownCustomStyle__16uTZ .label {
  display: none !important;
}

.AddInterval_dropdownCustomStyle__16uTZ > .label ~ .text {
  display: contents !important;
}

.AddInterval_areasRow__1ojbD {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  grid-gap: 5px !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.AddInterval_areasRowHead__1JUUf {
  font-family: "Roboto" !important;
  width: 50px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.AddInterval_formLabel__epF7p {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.AddInterval_addedItem__2jlep {
  display: flex !important;
  align-items: center !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.AddInterval_calendarWrapper__1Sdzl {
  display: flex;
  align-items: center;
}

.AddInterval_expirCalendar__2Wjyo {
  padding-left: 15px;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}

.AddInterval_expirCalendar__2Wjyo input {
  border: none !important;
}

.AddInterval_calendarIcon__30OdO {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.AddInterval_searchInput__1BjTP {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.AddInterval_searchInput__1BjTP input {
  padding: 3px 6px 3px 16px !important;
}

.AddInterval_searchInput__1BjTP input::placeholder {
  color: #b3b3b3;
}

.AddInterval_searchInput__1BjTP input {
  padding: 3px 6px 3px 16px !important;
}

.AddInterval_searchInput__1BjTP input::placeholder {
  color: #b3b3b3;
}

.AddInterval_content__3RcL2 {
  padding: 16px;
}

.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz {
  margin-left: 18px;
  width: 400px;
  height: 32px;
  display: flex;
  align-items: center;
}

.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz .ui.button {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: center;
  border: none;
  position: relative;
  width: 30%;
}

.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz
  :not(div)
  + .ui.button:not(:first-child):before,
.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz
  .ui.button:not(:nth-child(-n + 2)):before {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px !important;
  width: 1px;
  background: var(--color-light-grey);
}

.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz .ui.button.AddInterval_buttonActive__uT6I4 {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: var(--color-green) !important;
  width: 30% !important;
  height: 28px !important;
  display: flex;
  align-items: center;
}

.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz .ui.button.AddInterval_buttonActive__uT6I4:before,
.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz
  .ui.button.AddInterval_buttonActive__uT6I4
  + .ui.button:before {
  display: none;
}

.ui.basic.buttons.AddInterval_buttonsGroup__37Zlz div .ui.pointing.label {
  width: -webkit-max-content;
  width: max-content;
}

.AddInterval_serchRow__2_GoR {
  display: flex;
  align-items: center;
}

.AddInterval_btnContentWrapper__2gsi6 {
  width: 100%;
}

.AddInterval_contentSection__1ncNi {
}

.AddInterval_wrapper__NxO5E {
  border: 1px solid #dededf;
  height: 208px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px 0px 0px 0px;
  overflow-y: auto;
  margin-top: 16px !important;
}

.AddInterval_contractorWrapper__1YqlU {
  border: 1px solid #dededf;
  height: 208px;
  padding: 4px 16px 4px 8px;
  border-radius: 4px 0px 0px 0px;
  overflow-y: auto;
  margin-top: 16px !important;
}

.AddInterval_employeeRow__3tvjs {
  padding: 8px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.AddInterval_groupRow__ELlYH {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.AddInterval_checkBox__3Ed8C label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.AddInterval_checkboxWrapper__JTrqy {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.AddInterval_segment__2FD27 {
  margin-left: 10px;
  width: 98%;
  margin-left: 10px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  min-height: 60px !important;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  height: auto !important;
}

.AddInterval_rowFieldWrapper__1yIc3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.AddInterval_iconRowField__2J_vU {
  display: flex;
  justify-content: flex-end;
  width: 20%;
  cursor: pointer;
}

.AddInterval_deleteIcon__nuISb {
  color: #b00020;
}

.AddInterval_employeeIconWrapper__3pSNO {
  padding-left: 15px;
  padding-top: 10px;
  display: flex;
  grid-gap: 46px;
  gap: 46px;
}

.AddInterval_rowChevronIcon__22DX1 {
  cursor: pointer;
  color: #666666;
  padding-left: 16px;
}

.AddInterval_openRows__bb4cM {
  padding-top: 16px;
  padding-left: 16px;
}

.AddInterval_subRows__16WHS {
  padding: 8px;
  padding-left: 35px;
}

.AddInterval_minusIconWrapper__XNm1h {
  width: 17px !important;
  height: 17px !important;
  grid-gap: 0px !important;
  gap: 0px !important;
  border-radius: 2px !important;
  border: 1px solid #dededf !important;
  opacity: 0px !important;
  color: #0e9354 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AddInterval_minusIcon__3KHdY {
  margin-bottom: 3px;
}

.AddInterval_minusIcon__3KHdY::before {
  font-size: 9px !important;
}

.AddInterval_nameRowField__22aod {
  min-width: 250px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.AddInterval_dropdownCustomStyle__16uTZ div {
  color: #212121 !important;
}

.AddInterval_notSelectedIconWrapper__UZGR6 {
  background-color: #9dacba;
  width: 50px;
  height: 50px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddInterval_selectedIconWrapper__28e94 {
  background-color: #59b655;
  width: 50px;
  height: 50px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddInterval_notSelectedIcon__3NQDN {
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 100%;
}

.AddInterval_selectedIcon__2LKew {
  width: 24px !important;
  height: 24px !important;
  background-color: white;
  border-radius: 100%;
  color: #59b655;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.AddInterval_errorMessage__-5AVc {
  color: #b00020;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.AddInterval_dropdownCustomStyle__16uTZ input {
  padding-top: 8px !important;
}

.AddInterval_search__2F4pk {
  display: block !important;
}

.ConfirmationModal_confirmationModalContentHeader__11xml {
  color: #212121 !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: center !important;
}

.ui.message.OfflineMessageInfo_messageInfo__cyvri {
  position: fixed;
  left: 15px;
  bottom: 15px;
  box-shadow: 0 0 10px var(--box-shadow);
  padding: 15px;
  margin: 0;
}
.ui.message.OfflineMessageInfo_messageInfo__cyvri .ui.button {
  margin-left: 15px;
}

.ui.message.black.OfflineMessageInfo_messageInfo__cyvri .ui.button {
  color: #fff;
}

.ui.message.InfoMessage_messageInfo__3O_fX {
  margin: 0;
  display: flex !important;
  flex-wrap: nowrap;
  padding: 15px;
  box-shadow: var(--box-shadow-flowly);
}
.ui.message.InfoMessage_messageInfo__3O_fX > div {
  flex: auto;
}
.ui.message.InfoMessage_messageInfo__3O_fX > div.InfoMessage_closeButton__3qCnI {
  flex: 0 1;
  padding-left: 1rem;
}
.ui.message.InfoMessage_messageInfo__3O_fX > div.InfoMessage_closeButton__3qCnI .ui.button,
.ui.message.InfoMessage_messageInfo__3O_fX > div.InfoMessage_closeButton__3qCnI .icon {
  margin: 0 !important;
}

.ui.message.InfoMessage_messageInfo__3O_fX + .InfoMessage_messageInfo__3O_fX {
  margin-top: 10px;
}

.ui.message.black.InfoMessage_messageInfo__3O_fX .ui.button {
  color: #fff;
}

.InfoMessages_infoMessages__3EuBJ {
  position: fixed;
  left: 0;
  top: 80px;
  padding: 0 1rem;
  width: 100%;
}

.InfoMessages_infoMessagesIn__1_bbs {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.ImageViewer_modalBackdrop__29IV8 {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.85);

  z-index: 9999999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageViewer_iconWrapper__1U1qA {
  position: fixed;
  top: 20px;
  right: 20px;
}

.ImageViewer_icon__3HQYX {
  color: white;
  cursor: pointer;
  opacity: 0.8;
  font-size: 30px;
}

.ImageViewer_image__jAuJG {
  max-width: 95vw;
  max-height: 95vh;
  margin: auto;
}

.ImageViewer_imageMinimized__7m2XB {
}

.Routes_wrapper__3cVOK {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.Routes_messageContainer__2ZqF3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: 100px;
}

.Routes_message__2xUnm {
  text-align: center;
  font-size: 35px;
  line-height: 35px;
}

.Routes_logOutbtn__1sTu3 {
  width: 200px !important;
  height: 50px !important;
  color: white !important;
  background-color: #28b573 !important;
}

/* global app styles*/
html {
  scroll-behavior: smooth;
}

.relative-wrapper {
  position: relative;
}
.ui.grid.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}
.ui.grid .rt-table,
.ui.grid .rt-tbody {
  overflow: visible;
}

a {
  color: var(--color-green);
}

a:hover {
  color: var(--color-background-green);
}

.cursor-pointer,
a {
  cursor: pointer;
}

.square-image img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.square-image {
  padding-bottom: 100%;
  height: 0;
  position: relative;
}

.show-sm,
.show-md,
.show-lg,
.show-sm-inline,
.show-md-inline,
.show-lg-inline,
.show-sm-inline-block,
.show-md-inline-block,
.show-lg-inline-block {
  display: none !important;
}

.hide-xs {
  display: none !important;
}

.hide-sm,
.hide-md,
.hide-lg {
  display: block !important;
}

.hide-sm-inline,
.hide-md-inline,
.hide-lg-inline {
  display: inline-block !important;
}

.hide-sm-inline-block,
.hide-md-inline-block,
.hide-lg-inline-block {
  display: inline-block !important;
}
.ui.form .field > label {
  position: relative;
}

.ui.form .field > label > div + .ui,
.ui.form .field > label > div + input,
.ui.form .field > label > div + select,
.ui.form .field > label > div + textarea {
  margin-top: 5px;
}

.ui.button.button-text,
.ui.button.button-text:hover,
.ui.button.button-text:active {
  padding: 0;
  display: inline-block;
  width: auto;
  background: none;
  box-shadow: none;
}

.ui.button.button-text:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin-left: 0;
}

.ui.icon.input > i.icon.text {
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.primary.buttons .button,
.ui.primary.button {
  background: #28b573;
}
.ui.primary.buttons .button:hover,
.ui.primary.buttons .button.active,
.ui.primary.buttons .active.button:active,
.ui.primary.buttons .button:active,
.ui.primary.buttons .button:focus,
.ui.primary.button:hover,
.ui.primary.button:active,
.ui.primary.button:focus {
  background: #099254;
}

.ui.icon.button.transparent {
  padding: 0;
  background: transparent;
}

.ui.button.color-green {
  color: var(--color-green);
}

.ui.button.color-green:hover {
  color: var(--color-background-green-medium);
}

.ui.modal > .actions {
  background-color: var(--color-background);
}
.ui.selection.dropdown.select-rounded {
  border-radius: 1.35em;
}

.ui.selection.dropdown.select-rounded .menu {
  border-bottom-left-radius: 1.35em;
  border-bottom-right-radius: 1.35em;
}

.error.field .ui.pointing.label {
  margin-top: 6px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  color: red;
  background: #e8e8e8;
}

.ui.selection.dropdown.large {
  font-size: 1.14285714em;
}

.field.dropdown .ui.labeled.input > .label {
  padding-top: 0;
  padding-bottom: 0;
}
.field.dropdown .ui.labeled.input > .label .ui.dropdown.label {
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  /*
  border: 1px solid var(--color-border);
  border-left: none;

   */
}

.field.dropdown.error .ui.labeled.input > .label .ui.dropdown.label {
  border: 1px solid var(--color-border);
  border-left: none;
}

.field.dropdown .ui.labeled.input > .label .ui.dropdown.label > .text {
  flex: 1 1 auto;
}

.ui.form .error.field {
  position: relative;
}

.ui.form .error.field > .ui.label {
  /*white-space: nowrap;*/
}

.ui.selection.dropdown.small {
  min-height: 2em;
  padding-top: 0.67857143em;
  padding-bottom: 0.67857143em;
  font-size: 0.92857143em;
  line-height: 1.21428571em;
}

.field.dropdown .ui.labeled.input.small > .label .ui.dropdown.label {
  font-size: 0.92857143em;
}

.ui.form .field {
  margin-bottom: 0;
}

.ui.form .field.small textarea {
  font-size: 0.92857143em;
  padding-top: 0.67857143em;
  padding-bottom: 0.67857143em;
}

.ui.form .field .ui.input.labeled input {
  flex: 1 1 auto;
}

.ui.visible.overlay.sidebar {
  transform: none !important;
}

.ui.checkbox.check-box label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.checkbox.check-box label:before,
.ui.checkbox.check-box input:focus ~ label:before,
.ui.checkbox.check-box input:active ~ label:before {
  border-radius: 100%;
  width: 23px;
  height: 23px;
  border-color: var(--color-background-green-medium);
}

.ui.checkbox.check-box label:after,
.ui.checkbox.check-box input:focus ~ label:after,
.ui.checkbox.check-box input:active ~ label:after {
  font-family: tufIcons !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: '\f104';
  opacity: 1;
  color: var(--color-background-green-medium);
  width: 23px;
  height: 23px;
  top: 5px;
  border-radius: 100%;
}

.ui.checkbox.check-box input:checked ~ label:before,
.ui.checkbox.check-box input:checked:focus ~ label:before,
.ui.checkbox.check-box input:checked:active ~ label:before {
  border-color: var(--color-green);
  background-color: var(--color-green);
}

.ui.checkbox.check-box input:checked ~ label:after,
.ui.checkbox.check-box input:checked:focus ~ label:after,
.ui.checkbox.check-box input:checked:active ~ label:after {
  content: '\f106';
  color: #fff;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .show-sm {
    display: block !important;
  }
  .show-sm-inline {
    display: inline !important;
  }
  .show-sm-inline-block {
    display: inline-block !important;
  }
  .hide-sm,
  .hide-sm-inline,
  .hide-sm-inline-block {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .show-md {
    display: block !important;
  }
  .show-md-inline {
    display: inline !important;
  }
  .show-md-inline-block {
    display: inline-block !important;
  }
  .hide-md,
  .hide-md-inline,
  .hide-md-inline-block {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .show-lg {
    display: block !important;
  }
  .show-lg-inline {
    display: inline !important;
  }
  .show-lg-inline-block {
    display: inline-block !important;
  }
  .hide-lg,
  .hide-lg-inline,
  .hide-lg-inline-block {
    display: none !important;
  }
}

.ui.label.colorOPEN {
  color: #fff;
  background: var(--color-open);
}

.ui.label.colorIN_PROGRESS {
  color: #fff;
  background: var(--color-in-progress);
}

.ui.label.colorCOMPLETED {
  color: #fff;
  background: var(--color-completed);
}

.ui.header {
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: var(--color-chapter);
}

.ui.striped.table tbody tr:nth-child(2n) {
  background: #f6f7f8;
}

.ui.selectable.table tbody tr:hover {
  background: #dff3ea !important;
}

.ui.segment {
  box-shadow: none;
  border: none;
}

.text-right {
  text-align: right;
}

.field-tiny {
  max-width: 100px;
}
.field-short {
  max-width: 150px;
}

.field-medium {
  max-width: 250px;
}

textarea {
  border-radius: 5px;
  padding: 10px 15px;
  resize: vertical;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

textarea:hover {
  border-color: rgba(34, 36, 38, 0.3);
}
textarea:focus,
.ui.input > input:focus,
.ui[class*='right labeled'].input > input:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: var(--color-background-green-light) !important;
  outline: none;
}

.tuf-holder .ui.toggle.checkbox input:checked ~ label:before {
  background: var(--color-background-green) !important;
}

.tuf-holder .ui.toggle.checkbox input:focus:checked ~ label:before {
  filter: grayscale(30%);
}

.tuf-holder .ui.dropdown .ui.label {
  background: var(--color-background-label);
  border: none;
  box-shadow: none;
  color: #fff;
}

.tuf-holder .ui.dropdown .ui.label:hover {
  background: var(--color-dark);
  color: #fff;
}

.tuf-holder .ui.dropdown .ui.label .icon {
  opacity: 1;
}

.tuf-holder .ui.table thead th,
.tuf-holder .ui.striped.table tbody tr:nth-child(2n) {
  background: var(--color-background-cell);
}

.field.error .ui.menu {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

@media print {
  .hide-print {
    display: none !important;
  }
}

