.pointNumberWrapper {
  width: 98.5%;
  height: 22px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.pointNumber {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 1px;
  font-family: Roboto;
  color: grey;
}

.customSlider {
  width: 97.5% !important;
  margin-left: 10px;
}

:global(.rc-slider) {
  width: 98% !important;
}

:global(.rc-slider-rail) {
  margin-top: 1px !important;
  background-color: #f6f7f8 !important;
  height: 8px !important;
}

:global(.rc-slider-track) {
  background-color: #0e9354 !important;
  height: 10px !important;
  top: 14% !important;
  left: -1% !important;
}

:global(.rc-slider-dot) {
  width: 8px !important;
  height: 8px !important;
  background-color: #6ebe98 !important;
  bottom: -5px !important;
  border: none !important;
}

:global(.rc-slider-handle) {
  margin-left: 2px !important;
  transform: translateX(-70%) !important;
  width: 36px !important;
  height: 36px !important;
  background-color: #0e9354 !important;
  box-shadow: 0px 16px 45px 0px #431faf38 !important;
  border: 3px solid white !important;
  margin-top: -18px !important;
  opacity: 1 !important;
}

:global(.rc-slider-dot-active) {
  display: none !important;
  /*   background-color: #0E9354;
  color: #0E9354; */
}

:global(.rc-slider-step) {
  width: 98% !important;
}
