:global(.ui.grid .row).labelRow {
  padding-bottom: 0;
}

:global(.ui.grid .row).inputRow {
  /*padding-top: 0;*/
}

:global(.ui.grid .row .column).childrenColumnWithCheckbox {
  padding-left: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
