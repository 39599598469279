.errorBar {
  margin: 0 0 15px 0;
}

:global(.icon-label) {
  display: flex;
  align-items: center;
}

:global(.icon-label > .icon) {
  color: var(--color-icon);
  font-size: 21px;
  text-align: center;
}

:global(.icon-label > div) {
  flex: auto;
}

:global(.ui.search.dropdown > .text) {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footerContainer {
  padding: 5px 15px;
  text-align: right;
}

.root {
  width: 100%;
}

.checkbox {
  margin-right: 20px;
}

.buttonsContainer {
  margin-top: 30px;
}
