.layout :global(.pageBreak) {
  margin: 0;
  width: 100mm;
  height: 50mm;
  padding: 0;
}

.layout :global(.barcodeHolder) {
  margin: 1.5mm;
  width: 100mm;
  height: 50mm;
}

.layout :global(.barcodeDataHolder) {
  margin-top: 22px;
  transform: translate(50%, -13%) rotate(-90deg);
  left: unset;
  top: unset;
}

.layout :global(.barcode) {
  width: 100%;
  height: 50mm !important;
  font-size: 12px;
}

.layout :global(.comments-cell) {
  height: 40px !important;
}

.layout :global(.textHolder) {
  margin-top: 1px;
  line-height: 6px;
  font-size: 6px;
}

.layout :global(.text) {
  padding: 1px;
}
