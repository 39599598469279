.comment {
  width: 100%;
}

.root :global(.ui.segment) {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

.buttonContainer {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}

:global(.ui.grid .column).rowLabel {
  padding-bottom: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.button).cancelBtn {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

:global(.ui.button).disabledBtn {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

:global(.ui.modal).deleteModal {
  margin: 30%;
}

:global(.ui.button).deleteBtn {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).deleteBtn:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).cancelDltBtn {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.deleteButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

:global(.icon).deleteButton:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.deleteButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}

.rowContainer {
  height: 40px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
}
