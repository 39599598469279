.root .pageWrapper {
  padding: 24px 16px;
}

.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

:global(.ui.dimmer) {
  background-color: rgba(0, 0, 0, 0.2);
}

.root .pageTitle:global(.ui.header) {
  display: flex !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
}

.root .table {
  overflow-x: auto !important;
}

:global(.ui.segment).mainHolder {
  padding: 0;
}

.root :global(.pusher) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.root .tableWrapper {
  margin-top: 25px;
}

.root .tableContainer,
.root .tableWrapper {
  flex: 0 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tableContainer {
  overflow: auto;
}

.sidebarWrapper:global(.ui.sidebar) {
  width: 600px;
}

.sidebarHolder {
  width: 100%;
  background-color: white;
  padding: 15px;
  height: 100%;
}

.root .breadcrumb {
  margin-bottom: 10px;
}

.root .breadcrumb :global(.active.section) {
  color: #666666;
}

.root .breadcrumb :global(.active.section):hover {
  color: var(--color-green) !important;
  text-decoration: underline;
}

.root .breadcrumb :global(.section) {
  font-style: normal;
  font-weight: 500;
  color: #b3b3b3;
}

:global(.ui.basic.button).archive {
  color: var(--color-green) !important;
  margin-right: 10px;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  height: 25px;
  width: 25px;
  padding: 0;
  box-shadow: none;
  position: relative;
  margin: 0 15px 0 0 !important;
}

.textButton:global(.ui.button) i {
  position: absolute;
  bottom: 8px;
  right: 7px;
}

.restoreButton i {
  position: absolute;
  right: 62px;
}

.deleteButton:global(.ui.button) {
  color: var(--color-error-delete) !important;
  box-shadow: none;
  padding: 0;
  height: 25px;
  width: 25px;
  margin: 0 15px 0 0 !important;
}

.deleteButton:global(.ui.button):hover {
  color: var(--color-error-delete) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 20px;
  box-shadow: none !important;
}

.textButton:global(.ui.button):hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 20px;
  box-shadow: none !important;
}

.textButtonContainer {
  display: flex !important;
  justify-content: end;
}

.textButtonContainer i {
  height: 14px;
  width: 12px;
}

.editButton i {
  margin-bottom: 4px;
}

.archiveTooltipPosition {
  right: 10px;
  min-width: 156px !important;
  width: auto;
}

.editButtonTooltipPosition {
  right: 2px !important;
}

.restoreButtonTooltipPosition {
  right: -5px !important;
}

.noPermissionTooltipPosition {
  right: -38px !important;
  width: 156px !important;
}

.flattenBtn {
  background: none !important;
  width: 25px !important;
  height: 25px !important;
}

.flattenBtnIcon {
  background: none !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 15px !important;
}