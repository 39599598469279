.comment {
  width: 100%;
}

.root :global(.ui.segment) {
  border: 1px solid #dededf;
  box-shadow: none;
}

.cropRatesForm {
  margin-top: 8px !important;
  padding: 16px !important;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.formButtonContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
}

.rowCrop {
  width: 180px !important;
  padding: 0px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.rowPurpose {
  width: 180px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.rowRate {
  width: 109px !important;
  padding-left: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.rowReentr {
  width: 80px !important;
  padding-left: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.rowWHP {
  width: 80px !important;
  padding-left: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.rowComments {
  width: 223px !important;
  padding: 0px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.rowButtonCell {
  display: flex !important;
  padding-top: 10px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: center !important;
  width: 84px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.rowLabelCrop {
  width: 196px !important;
  padding: 0px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.rowLabelPurpose {
  width: 164px !important;
  padding: 0px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.rowLabelRate {
  padding: 0px !important;
  width: 109px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.rowLabelReentr {
  padding: 0px !important;
  width: 80px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.rowLabelWHP {
  padding: 0px !important;
  width: 80px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.rowLabelComments {
  padding: 0px !important;
  width: 307px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666 !important;
}

.meesageContainer {
  padding: 10px !important;
  position: absolute;
  width: 200px;
  left: -160px;
  top: -5px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  word-break: break-all !important;
}

.editButtonWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}

.editButtonWrapper {
  display: flex;
  margin-left: auto;
}

.editButton {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.closeButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

:global(.icon).closeButton:hover {
  color: var(--color-error-delete) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.closeButton {
  height: 12px;
  width: 12px;
  background-color: transparent !important;
  color: var(--color-error-delete) !important;
  position: relative;
}

:global(.ui.form .field > div).withholdingPeriod {
  height: 40px !important;
}

:global(.ui.form .field > div).reentry {
  height: 40px !important;
}

:global(.ui.form .field > div).crop {
  height: 40px !important;
}

:global(.ui.form .field > div).purpose {
  height: 40px !important;
}

:global(.ui.form .field > div).reentry {
  height: 40px !important;
}

:global(.ui.form .field > div).reentry {
  height: 40px !important;
}

:global(.ui.form .field > div).reentry {
  height: 40px !important;
}

:global(.ui.button).cancelBtn {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.rowContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0px !important;
  height: 40px !important;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.labelContainer {
  padding: 8px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}

.errorMessage {
  padding-top: 4px !important;
  width: 113px;
  height: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #b00020 !important;
}

:global(.ui.labeled.reentryWarningStyle > .label) {
  background-color: #b00020 !important;
  border: 1px solid #b00020 !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  color: #ffffff !important;
  border-radius: 0px 3px 3px 0px !important;
}

:global(.ui.input.reentryWarningStyle > input) {
  background: #ffffff !important;
  border: 1px solid #b00020 !important;
  box-sizing: border-box !important;
}

.reentryWarningStyle_label {
  color: #b00020 !important;
}

:global(.ui.withholdingPeriodWarningStyle > .label) {
  background-color: #b00020 !important;
  border: 1px solid #b00020 !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  color: #ffffff !important;
  border-radius: 0px 3px 3px 0px !important;
}

:global(.ui.input.withholdingPeriodWarningStyle > input) {
  background: #ffffff !important;
  border: 1px solid #b00020 !important;
  box-sizing: border-box !important;
}

.withholdingPeriodWarningStyle_label {
  color: #b00020 !important;
}

.editFormContainer {
  margin-bottom: 8px !important;
}

.ratesContainer {
  margin-bottom: 0px !important;
}

:global(.ui.segment).cropRatesForm {
  margin-top: 8px !important;
}

.purposeErrorMessage {
  width: 50%;
  margin-right: 15px !important;
}

.messageContainer {
  width: 70%;
  margin-right: 15px !important;
}

.addButtonContainer {
  display: flex;
  justify-content: end;
  padding-top: 8px;
}

.cropFieldRow {
  margin-bottom: 16px !important;
}
