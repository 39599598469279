.body {
  flex: 0 1 100%;
  height: 75%;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 5px !important;
}

.tabsHolder {
  width: 100%;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  display: flex;
  margin-top: 22px !important;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.item) {
  text-align: center;
  flex: 1;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  display: block;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.item.active) {
  text-align: center;
  border-color: var(--color-green);
  color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  background: #fff;
  margin-bottom: 0;
}

.tabItem {
  overflow: hidden;
  height: 0;
}

.tabItem.visibleTab {
  height: auto;
  height: 100%;
}

.undisplayedTab {
  display: none;
}

.addBtnWrapper {
  display: flex;
  justify-content: flex-end;
}

.addBtn {
  box-shadow: none !important;
  background: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #0e9354 !important;
  padding-right: 0px !important;
}
