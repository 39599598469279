.dropdown {
  width: 220px;

  position: absolute;
  height: 40px;
  padding: 9px 5px 10px 10px;

  background: #ffffff;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
}

.opened {
  height: unset;
}

.headingRow {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropdown:hover {
  border-color: rgba(34, 36, 38, 0.35);
}

.opened .title {
  color: var(--color-completed);
}

.hr {
  color: var(--color-border);
}

.checkbox {
  display: flex !important;
}

.titleWrapper {
  display: flex;
  align-items: center;
}
