.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: 100px;
}

.message {
  text-align: center;
  font-size: 35px;
  line-height: 35px;
}

.logOutbtn {
  width: 200px !important;
  height: 50px !important;
  color: white !important;
  background-color: #28b573 !important;
}
