@import '../../assets/css/variables.css';

.layoutHolder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layoutHolder > div {
  flex: 1 1 auto;
}
:global(.ui.left.slide.along.sidebar.column-left),
:global(.ui.sidebar.column-left) {
  visibility: visible;
  transition: all 0.5s ease;
  transform: translate3d(-100%, 0, 0);
  border: none;
}
:global(.ui.left.slide.along.sidebar.column-left.visible),
:global(.ui.sidebar.column-left.visible) {
  transform: translate3d(0, 0, 0);
  z-index: 5;
}

:global(.ui.left.sidebar.column-left ~ .pusher) {
  position: relative;
}

:global(.ui.left.sidebar.column-left ~ .pusher:after) {
  transition: opacity ease-in-out 0.5s;
  opacity: 0;
  left: 0;
  top: 0;
  right: 0;
}

:global(.ui.visible.left.sidebar.column-left ~ .pusher) {
  transition: all 0.5s ease;
  transform: translate3d(0, 0, 0);
}

:global(.ui.visible.left.sidebar.column-left ~ .pusher:after) {
  opacity: 1;
  bottom: 0;
}

.columnMain {
  display: flex;
  flex-direction: column;
  height: inherit;
}

:global(.ui.segment).mainHolder {
  margin-top: 0;
  flex: 1 1;
  overflow: auto;
  background: var(--color-background);
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  :global(.ui.left.slide.along.sidebar.column-left),
  :global(.ui.sidebar.column-left) {
    transform: translate3d(0, 0, 0);
  }
  :global(.ui.left.sidebar.column-left ~ .pusher),
  :global(.ui.visible.left.sidebar.column-left ~ .pusher) {
    padding-right: 260px;
    transform: translate3d(260px, 0, 0);
  }

  :global(.ui.visible.left.sidebar.column-left ~ .pusher:after) {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
}
