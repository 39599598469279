@media (min-width: 767px) {

    :global(.ui.input).dilutionInput {
        width: 100%;
        min-width: 120px;
    }

    :global(.ui.fluid.dropdown.active).dilutionDropdown {
        width: 100%;
        min-width: 120px;
    }
    :global(.ui.fluid.dropdown).dilutionDropdown > :global(.text) {

         }
}

:global(.ui.labeled.input > .label).iconDropDown :global(.icon) {
margin: 0;
}

