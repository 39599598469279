.layout :global(.page) {
  height: 99mm;
  width: 102mm;
}

.layout :global(.block) {
}

.layout :global(.block *) {
}

.layout :global(.labelHolder) {
  height: 100%;
  width: 100%;
  margin-bottom: 2mm;
  padding: 5mm;
  justify-content: space-between;
  flex-flow: row-reverse;
}

.layout :global(.barcodeDataHolder) {
}

.layout :global(.barcode) {
  height: 30mm;
  width: 30mm;
}

.layout :global(.textHolder) {
}

.layout :global(.text) {
  margin-top: 1px;
  font-size: 20px;
}

.layout :global(.text.id) {
  margin-top: 3px;
  font-size: 17px;
}

.layout :global(.packingShed) {
  margin-top: 1px;
  font-size: 20px;
  font-weight: bold;
}
