@import '../../../../assets/css/variables.css';

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:global(.sprayGrid .ui.dropdown.error .menu .item) {
  color: #000000;
}

:global(.acceptSection) {
  display: flex;
  margin-top: 10px;
}

:global(.acceptSection.error) {
  color: red;
}

:global(.acceptSection label) {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

:global(.acceptSection label .checkbox) {
  margin-right: 5px;
}

:global(.subsectionTitle) {
  font-size: 1.2rem;
}

:global(.ui.table.sprayTablechemicals tbody .show th) {
  padding: 1.29rem 0.8rem;
}

:global(.chemicalItem) {
  display: flex;
  justify-content: space-between;
}

:global(.createTaskButton .ui.dropdown) {
  background: var(--color-secondary-button-background) !important;
}
:global(.createTaskButton .ui.dropdown:hover) {
  background: var(--color-secondary-button-background-hover) !important;
}

:global(.ui.dropdown.relative) {
  position: relative;
  z-index: 1;
}

:global(.dropdownDilusionIcon) {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  padding: 12px 0;
  padding-right: 19px;
  padding-bottom: 24px;
}

:global(.text.areaSelectItem) {
  display: flex;
}

:global(.ui.grid.chemicalItems) {
  background: #f5efef;
  margin: 0;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #e3e1e2;
}

:global(.ui.grid.chemicalItems .column) {
  padding-bottom: 15px;
}

:global(.chemicalItemTitle) {
  margin: 10px 0;
  font-size: 12px;
  color: var(--color-header);
}

:global(.deleteChemicalIcon) {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}

:global(.sprayGrid .ui.dropdown:not(.button) .default.text) {
  color: #000000;
}

:global(.sprayGrid .sprayField .dropdown.dropdownDilusionIcon),
:global(.sprayGrid .sprayField .input.dilusionInput) {
  width: unset;
}

:global(.ui.grid).buttonsFooter {
  margin: 15px 0;
}

:global(.ui.grid).buttonsFooter :global(.ui.button + .ui.button) {
  margin-right: 15px;
}

.areaDropDownHolder {
  position: relative;
  z-index: 10;
}

.areaDropDownHolder :global(.icon.sliders) {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.areaDropDownHolder :global(.icon.delete).clearAll {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 10px;
  right: 40px;
}

.filtersHolder {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  border: 1px solid var(--color-background-green-light);
  border-top: none;
  box-shadow: var(--box-shadow-mini);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.filtersHolder :global(.ui.segment) {
  margin: 0;
}

.filtersBar {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  left: 1px;
  right: 1px;

  padding: 0 15px;
  border-bottom: 1px solid var(--color-border);
  z-index: 10;
}

.filtersFooter {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background: #fff;
  box-shadow: var(--box-shadow-mini);
  border: 1px solid var(--color-border-sui);
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.allHolder {
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.allHolder :global(.ui.button) {
}

:global(.ui.grid).filtersGrid :global(.row + .row) {
  padding-top: 0;
}
:global(.ui.grid).filtersGrid button + button {
  margin-left: 15px;
}
:global(.ui.grid).labelsGrid {
  margin-top: 0;
  margin-bottom: 0;
}

:global(.ui.grid).labelsGrid :global(.row .column) {
  padding-bottom: 0;
}
:global(.ui.grid).labelsGrid :global(.ui.label) {
  margin-top: 2px;
  margin-bottom: 2px;
}
:global(.ui.grid).labelsGrid :global(.button-text) {
  color: var(--color-grass);
}
:global(.ui.grid).labelsGrid :global(.button-text + .button-text) {
  margin-left: 15px;
}

:global(.ui.dropdown).areasDropdown {
  padding-right: 5em;
}

:global(.ui.dropdown).areasDropdown :global(.item) span {
  display: inline-block;
  color: var(--color-black);
}

:global(.ui.dropdown).areasDropdown :global(.item) i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}
:global(.ui.dropdown).areasDropdown :global(.item i.vacant) {
  color: var(--color-vacant-blue);
}

:global(.ui.dropdown).areasDropdown.gotFilters {
  z-index: 8;
}
:global(.ui.dropdown).areasDropdown.gotFilters :global(.menu) {
  border: none;
  border-radius: 0;
  left: 0;
  right: 0;
  margin: 0;
  min-width: auto;
  width: auto;
}

:global(.ui.dropdown).areasDropdown.gotFilters :global(.menu),
:global(.ui.dropdown).areasDropdown.gotFilters :global(.menu:hover),
:global(.ui.dropdown).areasDropdown.gotFilters :global(.menu:active),
:global(.ui.dropdown).areasDropdown.gotFilters:hover :global(.menu) {
  box-shadow: none;
}

.countDuplicateSelectedItems {
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background: var(--color-light-grey);
  font-weight: bold;
  color: var(--color-dark);
  border-radius: 100%;
  display: inline-block;
  margin-left: 5px;
  margin-top: -10px;
  margin-bottom: -6px;
  top: -3px;
  position: relative;
  vertical-align: bottom;
}

.confirmContent {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.confirmContentLine {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.showFilters :global(.ui.dropdown).areasDropdown {
  border-color: var(--color-background-green-light);
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:global(.ui.grid).buttonsFooter,
:global(.ui.grid).buttonsFooter > div {
  margin: 0;
  padding: 0;
}

:global(.ui.grid).buttonsFooter :global(.ui.primary.button .icon) {
  opacity: 1;
  height: 14px;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

@media (max-width: 767px) {
  :global(.page .modal) .modalHeader {
    padding-right: 0 !important;
  }

  :global(.ui.grid).buttonsFooter > :global(.row > .column) {
    padding: 0;
  }

  :global(.ui.grid).buttonsFooter :global(.ui.primary.button) {
    margin: 0;
  }

  .machineryHolder :global(.column).speedColumn {
    margin-top: 0 !important;
  }
}
