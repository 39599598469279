:global(.ui.modal).modalChemical {
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex !important;
  flex-direction: column;
}

:global(.ui.modal).modalChemical > :global(.close) {
  right: 15px;
  top: 15px;
  color: #000;
}

:global(.ui.modal).modalChemical :global(.content) {
  padding: 0;
  flex: auto;
  overflow: auto;
}

:global(.ui.modal).modalChemical :global(.actions) {
}
