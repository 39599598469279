@import "../../assets/css/variables.css";

:global(.ui.dropdown).userDropdown :global(.menu > .item) {
  display: flex;
  align-items: center;
}

:global(.ui.dropdown).userDropdown :global(.icon) {
  height: auto;
}

:global(.ui.dropdown).userDropdown :global(.icon) svg {
  max-width: 100%;
}

.avatarHolder {
  display: flex;
  align-items: center;
}

.avatarHolder:hover {
  color: var(--color-background-green);
}

.avatarHolder :global(.ui.avatar.image) {
  width: 32px;
  height: 32px;
  margin: 0;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: var(--color-background-secondary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.avatar :global(.icon) {
  margin-right: 0;
}

.menuGroup {
  padding-top: 6px;
}

.menuGroup i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.menuGroup a {
  color: #212121;
}

.headerMenuWrapper {
  display: flex;
  align-items: center;
}
