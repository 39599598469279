.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editBtn {
  background: none !important;
  color: #0e9354 !important;
}

.doubleCheck {
  cursor: pointer;
  background: none !important;
  color: #0e9354 !important;
  padding: 0.78571429em 0.78571429em 0.78571429em !important;
}
.checkMark {
  cursor: pointer;
  background: none !important;
  color: #0e9354 !important;
  padding: 0.78571429em 0.78571429em 0.78571429em !important;
}

.deleteButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: rgba(176, 0, 32, 1) !important;
  position: relative;
  cursor: pointer;
}

.deleteButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).deleteButton:hover {
  color: rgb(148, 7, 33) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

:global(.ui.button).addButton {
  height: 39px;
  background-color: var(--color-green) !important;
}

:global(.ui.button).clockInButton {
  background: none !important;
  border: 1px solid var(--color-green) !important;
  color: var(--color-green);
}

:global(.ui.button).clockInButton:hover {
  color: var(--color-green);
}

:global(.ui.button).clockOutBtn {
  background: none !important;
  border: 1px solid var(--color-green) !important;
  color: var(--color-green);
}

:global(.ui.button).totalClockOutBtn {
  height: 39px;
  background-color: var(--color-green) !important;
  color: white;
}

.checkMarkWrapper {
  display: flex;
  width: 43px;
}
.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
  cursor: pointer;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: #00000008 !important;
  border-radius: 100%;
  box-shadow: none !important;
}
:global(.icon).editButton:active {
  color: var(--color-green) !important;
  background: #0000001f !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.deleteButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #d92d20 !important;
  position: relative;
  cursor: pointer;
}

.deleteButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: #d92d20 !important;
}

.deleteButton:hover {
  color: #d92d20 !important;
  background: #00000008 !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.deleteButton:active {
  color: #d92d20 !important;
  background: #0000001f !important;
  border-radius: 100%;
  box-shadow: none !important;
}
