@import '../../../../assets/css/variables.css';
.stepHolder {
  composes: stepHolder from '../AddNewFarm.module.css';
  background: var(--color-background-cell);
}

.stepContent {
  composes: stepContent from '../AddNewFarm.module.css';
}

.stepActions {
  composes: stepActions from '../AddNewFarm.module.css';
}

.stepCount {
  composes: stepCount from '../AddNewFarm.module.css';
}

.stepTitle {
  composes: stepTitle from '../AddNewFarm.module.css';
}

.verticalMiddle {
  composes: verticalMiddle from '../AddNewFarm.module.css';
}

.helperText {
  color: var(--color-middle);
  font-size: 12px;
  margin-top: 5px;
}

.buttonsGroup {
  width: 100%;
  background: #fff;
}

:global(.ui.basic.buttons).buttonsGroup :global(.button) {
  padding-left: 3px;
  padding-right: 3px;
}

:global(.ui.basic.buttons).buttonsGroup :global(.button).buttonActive {
  background: var(--color-active) !important;
  color: #fff !important;
}
.labelLocation > div + div {
  margin-top: 5px;
}
