.stripedRow {
  background: var(--color-row-stripped);
}

.loader {
  margin-left: 0;
}

.iconCell {
  width: 35px;
  padding: 0 !important;
  text-align: center !important;
}

.expandedRow {
  background-color: #ebf6e9;
}

:global(.ui.selectable.table) tbody tr.additionalRow:hover,
:global(.ui.selectable.table) tbody tr.additionalRow tr:hover {
  background-color: #ebf6e9 !important;
}

.additionalRow td:first-child {
  border-top: none !important;
}

.drawGreenLine {
  border-left: 3px solid #0e9354;
}

.additionalInfoTable thead tr th {
  background: transparent !important;
  color: #5b666f !important;
  font-weight: 500 !important;
  border-bottom: 0 !important;
}

.additionalInfoTable th:last-child,
.additionalInfoTable td:last-child {
  padding-right: 12px !important;
}

.additionalInfoTable tbody tr td {
  border-top: 0 !important;
}

.additionalInfoTable tbody tr:last-child td {
  border-top: 0 !important;
}

.additionalInfoTable tbody tr td:first-child {
  border-top: none !important;
}

.toggleIcon {
  margin: 0 !important;
  color: #9dacba;
  font-size: 16px !important;
  vertical-align: bottom;
  cursor: pointer;
}

.toggleIconTurned {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
