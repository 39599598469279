:global(.ui.modal).modalPrintAndExport {
  width: 400px !important;
}

:global(.ui.modal).modalPrintAndExport :global(.content) {
  padding-top: 25px;
}

:global(.ui.button).reportsModalDisabledButton {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.modalAction {
  background-color: white !important;
}

:global(.ui.button).reportsModalButton {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #0e9354 !important;
  border-radius: 4px;
  color: white !important;
}

:global(.ui.button).reportsModalButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).reportsModalButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

:global(.ui.button).reportsModalCancelButton {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).reportsModalCancelButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).reportsModalCancelButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.allButton {
  padding-left: 21px;
  padding-top: 20px;
}

.reportsCheckBox label::before {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.reportsCheckBox label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.modalHeader {
  text-align: center;
  border: none !important;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
}

.inventoryBox {
  display: flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}

.inventoryBox label {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  /* line-height: 27px !important; */
}

.inventoryBox input {
  padding-top: 5px !important;
}

.stocktake {
  display: flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}

.stocktake label {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  /* line-height: 27px !important; */
}

.stocktake input {
  padding-top: 5px;
}

.inventoryCheckboxContainer {
  height: 27px !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 8px !important;
}

.stocktakeCheckboxContainer {
  height: 27px !important;
  display: flex !important;
  align-items: center !important;
}
