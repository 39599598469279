/* SignatureGenerator.module.css */
.input {
  width: 80%;
  outline: none !important;
  font-family: Roboto;
  border: 1px solid #dededf;
  border-radius: 4px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
}

.canvas {
  border: 1px solid #ccc;
}

.wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

.deleteBtn {
  width: 40px;
  height: 40px;
  margin-bottom: 16px !important;
  margin-left: 8px !important;
  background-color: #b00020 !important;
}

.canvasWrapper {
  display: flex;
  flex-direction: column;
}

.pad {
  width: 398px;
  height: 200px;
  border: 1px solid #dededf;
  border-radius: 3px;
}

.pad {
  width: 398px;
  height: 200px;
  border: 1px solid #dededf;
  border-radius: 3px;
}

.padClearButtonWrapper {
  display: flex;
  justify-content: end;
  padding-top: 16px;
}

.padClearButton {
  cursor: pointer;
  background: none;
  color: #b00020 !important;
  border: none !important;
  margin-left: 10px;
}
