.modalMessageHeader {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}
:global(.ui.modal).messageModal {
  width: 400px !important;
  height: 150px !important;
  border-radius: 8px !important;
  overflow: auto;
}

:global(.ui.modal).messageModal :global(.content) {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

:global(.ui.modal).messageModal :global(.actions) {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

:global(.ui.modal.messageModal > .actions) {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

:global(.ui.large.modal.messageModal > .header) {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.avatarsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avatarsContainer span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.avatarHolder {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.avatarsContainer .avatarHolder:nth-child(n + 2) {
  margin-left: -10px;
}

.morePopupContent {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.popupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.popupItem .avatarHolder {
  height: 20px;
  min-width: 20px;
}

.popupItem:nth-child(1) {
  margin-top: 0;
}

.popupItem span {
  margin-left: 10px;
}

.actionCells {
  overflow: initial !important;
}

.imageContainer {
  display: flex !important;
  align-items: center !important;
}

.image {
  width: 30px !important;
  height: 30px !important;
}

.imageButton {
  padding: 0 !important;
  background: none !important;
}

.deleteButton {
  width: 72px !important;
  height: 36px !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !;
  box-shadow: 0 0 0 1px #b00020 inset !important;
  color: white !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background-color: #b00020 !important;
}
.cancelButton {
  width: 75px !important;
  height: 36px !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !;
  box-shadow: 0 0 0 1px #0e9354 inset !important;
  color: #0e9354 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background: none !important;
}

.deleteIconActionButton {
  width: 103px !important;
  height: 28px !important;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 0 0 1px #b00020 inset !important;
  color: #b00020 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  background: none !important;
}

.deleteIcon {
  color: #b00020 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

:global(.ui.button).exportButton {
  width: 96px;
  height: 29px;
  display: flex;
  margin: 0;
  margin-right: 8px !important;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.ui.button).exportButton:hover {
  width: 96px;
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 29px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 8px !important;
}

:global(.ui.button).exportButton:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).exportButton:active {
  width: 96px;
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 29px;
  border-radius: 4px;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 8px !important;
}
