.splashHolder {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.splashHolder > div {
  flex: 1 1 auto;
}

.logo {
  background: #fff url('../../assets/img/logo.svg') no-repeat center;
  background-size: contain;
  width: 200px;
  height: 150px;
  margin: 0 auto;
}

.message {
  max-width: 350px;
  padding: 15px 30px;
  text-align: center;
  margin: 0 auto;
}

.loader {
  display: inline-block;
  margin: 0 15px 0 0;
  vertical-align: middle;
}
