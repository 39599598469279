.errorHolder {
  margin: 15px;
  position: relative;
}

.errorHolder button {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
}
.errorHolder :global(.ui.message) {
  margin: 0;
}
