.modal {
  width: 420px !important;
}

:global(.ui.button).archiveButton {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

:global(.ui.button).archiveButton:hover {
  background-color: #156941 !important;
}

.modalHeaderText {
  text-align: center;
  font-family: Roboto, sans-serif;
  white-space: break-spaces !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
}

.listContainer {
  width: 100%;
  overflow-y: scroll;
  max-height: 150px;
}

.listItem {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.deleteButton {
  background-color: var(--color-error-delete) !important;
  opacity: 1 !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.deleteButton:hover {
  background-color: #8c0d24 !important;
}

.actionButtonContainer {
  width: 100% !important;
  display: flex !important;
  justify-content: end;
  margin-top: 30px;
}
