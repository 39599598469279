.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.grid) {
  margin: 0;
}

.minRateInput {
  position: relative;
}

.minRateInput:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.minRateInput div {
  height: 40px !important;
}

.maxRateInput div {
  height: 40px !important;
}

.modalContentContainer {
  min-height: 70vh;
  scrollbar-width: none;
}

.modalContentContainer::-webkit-scrollbar {
  display: none;
}

.removeActiveIngredient {
  color: #b00020;
  margin-right: 0 !important;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButton:global(.ui.button):hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.ingredientHeader {
  word-break: break-word;
  margin-bottom: 0 !important;
}

.ingredientSection {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}

.activeIngredientMessage :global(.ui.header) {
  margin: 0 !important;
}

.attachmentLabel {
  margin-right: auto !important;
}

.generalAttachmentsContainer {
  display: flex !important;
  flex-wrap: wrap;
}

.generalAttachmentsButton {
  margin-left: auto !important;
}

.root :global(.ui.form .field) > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.root .chemicalPropertyColumn {
  padding-left: 0 !important;
}

.root .chemicalPropertyColumn :global(.field) {
  margin-bottom: 16px !important;
}

.root :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
}

:global(.ui.modal).root :global(.actions) {
  background-color: white !important;
}

.cancelButton {
  background: none !important;
  padding: 0px 16px !important;
  width: 75px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #0e9354 !important;
}

.cancelButton:hover {
  color: #ffffff !important;
  background: #156941 !important;
}

.saveButton {
  background: #0e9354 !important;
  padding: 0px 16px !important;
  width: 63px !important;
  height: 36px !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.saveButton:hover {
  background: #156941 !important;
}

.saveButton:disabled {
  background: #f6f7f8 !important;
  border: none !important;
  border-radius: 4px;
  color: #b3b3b3 !important;
}

.trackInventoryButtonYes {
  position: absolute !important;
  padding: 10px !important;
  width: 200px;
  /* height: 114px; */
  left: 0px !important;
  top: -45px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: start !important;
  color: #ffffff;
}

.trackInventoryButtonNo {
  position: absolute !important;
  width: 200px;
  height: 125px;
  padding: 10px !important;
  left: 0px !important;
  top: -55px !important;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.noButtonIcon {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.yesButtonIcon {
  color: #b3b3b3;
  padding-left: 9px !important;
  padding-right: 9px !important;
  width: 14px !important;
  height: 14px !important;
}

.inputWrapper {
  display: flex;
}

.inputButtonWrapper {
  text-align: end;
}

.batchNumberInput {
  margin: 10px !important;
}
