@import '../../assets/css/variables.css';

.blockBox {
  margin-top: 15px;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}

.blockName {
  color: var(--color-title);
  font-size: 20px;
  padding-bottom: 1rem;
}

.colHead {
  color: var(--color-header);
  font-size: 14px;
  text-transform: uppercase;
}

.colBody {
  color: var(--color-text);
}

.blockCol {
}

.buttonsCol {
  text-align: right;
  white-space: nowrap;
}
.buttonsCol :global(button.ui.button) {
  padding: 0;
  background: none;
}

.buttonsCol :global(button.ui.button):first-child {
  margin-right: 10px;
}
