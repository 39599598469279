.root {
  font-family: "Montserrat", "Lato", "Helvetica Neue", Arial, Helvetica,
    sans-serif !important;
  padding: 20px;
  background: var(--color-background-cell);
}

.root .page {
  /*if enabled, adds extra page at the end of the doc*/
  /*page-break-after: always;*/
}

.root .valueHeader {
  padding-top: 24px;
}

.root .printedTable {
  margin-top: 10px;
}

.root .printedTable thead th {
  text-transform: uppercase !important;
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 500;
}

.printedTable :global(.ui.basic.striped.table) tbody tr:nth-child(1n) {
  background-color: #ffffff !important;
}

.printedTable :global(.ui.basic.striped.table) tbody tr:nth-child(2n) {
  background-color: var(--color-background) !important;
}

.printedTable :global(.ui.basic.table) tr td,
.printedTable :global(.ui.basic.table) thead th {
  border: unset !important;
}

.root .operationsHeader {
  margin-bottom: 24px !important;
  margin-top: 20px !important;
}
