.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.root :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
}

.root :global(.ui.grid) {
  margin: 0;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.ui.grid > .row > .column.column-close {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 8px !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 8px !important;
}

.root :global(.ui.grid) {
  margin: 0;
}

.removeSuppliers {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.suppliersSection {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.suppliersHeader {
  margin-bottom: 0 !important;
}

.suppliersWrapper {
  height: 40px !important;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.modalContentContainer {
  min-height: 70vh;
  scrollbar-width: none;
}

.modalContentContainer::-webkit-scrollbar {
  display: none;
}

.generalAttachmentsContainer {
  display: flex !important;
  flex-wrap: wrap;
  margin-bottom: 4px !important;
}

.generalAttachmentsButton {
  margin-left: auto !important;
}

.root :global(.ui.form .field) > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.root .chemicalPropertyColumn {
  padding-left: 0 !important;
}

.root .chemicalPropertyColumn :global(.field) {
  margin-bottom: 16px !important;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

.deleteButton {
  cursor: pointer;
}

.upload-input {
  display: none;
  border-radius: 10px;
}

.btn {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  width: 300px;
  height: 48px;
  border-radius: 10px;
  background: #eef1f5;
  box-shadow: none;
  margin-top: 8px;
  align-items: center;
  padding: 10px;
}

:global(.ui.label).attachmentLabel {
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  max-width: 155px;
}

:global(.ui.search.dropdown > .text) {
  padding: 0 !important;
}

.secondRow {
  margin-bottom: 18px !important;
}
.valueWrapper {
  padding-top: 15px !important;
}
