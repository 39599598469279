@import '../../../../assets/css/variables.css';

.taskHeader {
  background: #fff;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  position: relative;
  z-index: 100;
}

:global(.ui.button).buttonSave {
  color: var(--color-grass);
}

.taskName {
  text-align: center;
}
.taskName :global(.ui.header) {
  margin-bottom: 0;
}
.taskActions :global(.ui.button) {
  margin-right: 0;
}

.taskActions button:not(:first-child) {
  margin-left: 20px;
}

.taskActions :global(.ui.button > .icon:not(.button)) {
  height: auto;
  margin-right: 0 !important;
}
@media screen and (min-width: 768px) {
  .taskHeader {
    background: transparent;
    box-shadow: none;
    padding: 25px 20px 25px 25px;
    border-bottom: 1px solid var(--color-border);
  }

  .taskName {
    text-align: left;
  }
  .taskName :global(.ui.header) {
    font-size: 24px;
  }
}
