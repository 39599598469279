.block {
  box-sizing: border-box;
  justify-content: space-around;
  display: grid;
}

.block * {
  box-sizing: inherit;
}

.page {
}

.pageBreak {
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  page-break-before: always;
}

.barcodeHolder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.barcodeDataHolder {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.barcode {
  height: 15mm;
  width: 15mm;
}

.textHolder {
  margin-top: 3px;
  line-height: 8px;
  font-size: 8px;
}

.text {
  padding: 1px;
}

@media screen {
  .pageBreak {
    outline: 1px dotted black;
  }

  .barcodeHolder {
    outline: 1px dotted black;
  }
}

@media print {
  .pageBreak:not(:last-child) {
    page-break-after: always;
  }

  .pageBreak:last-child {
    page-break-after: avoid;
  }
}
