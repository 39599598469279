:global(button.ui.basic.button).btnCustomized,
.printoutDropdown {
  display: inline-flex !important;
  align-items: center;
  height: 36px;
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-green) !important;
  box-shadow: 0px 0px 0px 1px var(--color-green) inset;
}

.printoutDropdown {
  border-radius: 0.28571429rem;
}

:global(.ui.dropdown).printoutDropdown :global(.text) {
  margin-right: 5px;
}

:global(.ui.dropdown).printoutDropdown > :global(.icon) {
  display: inline;
  margin-right: 0;
  margin-left: 5px;
  line-height: 18px;
  scale: 1.2;
}

:global(.ui.dropdown.visible).printoutDropdown > :global(.icon) {
  margin-top: 3px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

:global(.ui.dropdown).printoutDropdown :global(.menu) {
  left: auto;
  right: 0;
}
