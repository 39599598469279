.headerFarms {
}
.headerFarms :global(.ui.grid > .row) {
  padding: 0;
}

.headerFarms :global(.ui.grid > .row > .column):first-child {
  padding-left: 0;
}

.headerFarms :global(.ui.grid > .row > .column):last-child {
  padding-right: 0;
}

.verticalMiddle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.verticalMiddle:global(.center) {
  justify-content: center;
}

.verticalMiddle:global(.right) {
  justify-content: flex-end;
}

.rightTools {
  display: flex;
  align-items: center;
  float: right;
}

.showMap {
  vertical-align: middle;
  display: flex;
  margin-left: 30px;
}

.showMap span {
  display: inline-block;
  margin-right: 15px;
}

.allFarms {
}

:global(.ui.dropdown).allFarms {
  padding-left: 0;
}

:global(.ui.scrolling.dropdown).allFarms :global(.menu) {
  max-height: 350px;
}

:global(.ui.header).farmName {
  padding: 0.14em 0;
  margin: 0 20px 0.45238095em 0;
  line-height: 1.21428571em;
  display: inline-block;
}

:global(.ui.dropdown).allFarms > :global(.text) {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.checkBoxes {
  padding: 15px;
  min-width: 150px;
}
.checkBoxes :global(.ui.checkbox) {
  display: block;
}

.checkBoxes :global(.ui.checkbox):not(:first-child) {
  margin-top: 10px;
}

.headerFarms :global(.search-input .ui.input .prompt) {
  border-radius: 0.5rem;
}

.leftTools {
  display: flex;
  align-items: center;
}

.leftTools :global(.ui.search) {
  margin-right: 15px;
}

.filtersHolder > :global(i.icon) {
  font-size: 22px;
}

.filtersList {
  list-style: none;
  padding: 0;
  margin: 15px;
}

:global(.ui.button).addNew > :global(.icon) {
  margin: 0 0 0 10px !important;
  font-size: 22px;
  height: 14px;
  vertical-align: middle;
  position: relative;
  top: -5px;
}

.searchInput {
  width: auto;
}

.archiveToggle {
  display: inline-flex;
  padding: 10px 0 10px 15px;
  float: right;
}

.archiveToggle span {
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .archiveToggle {
    border-left: 0;
    padding-left: 0;
  }
}
