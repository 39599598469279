.layout :global(.pageBreak) {
  margin: 0;
  width: 297mm;
  padding: 0.9mm 8.7mm 0.9mm 12.7mm;
}

.layout :global(.barcodeHolder) {
  margin: 1.75mm 0;
  height: 38.1mm;
  width: 21.2mm;
}
