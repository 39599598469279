.barHolder {
  display: flex;
  margin: 0 -5px;
  align-items: center;
  flex-wrap: wrap;
}

.barHolder > div {
  flex: 0 0 auto;
  padding: 5px;
}

.barHolder :global(.ui.button:not(.icon) > .icon:not(.button):not(.dropdown)) {
  margin: 0;
}

.barHolder :global(.ui.button:not(.icon) > .icon:not(.button):not(.dropdown)) + span {
  margin-left: 10px;
}

:global(.ui.button).buttonActive {
  background: var(--color-dark);
  color: #fff;
}

:global(.ui.button).buttonActive :global(.icon) {
  opacity: 1;
}

.barHolderIn {
  display: flex;
  align-items: center;
}

.barHolderIn > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.dateInput {
  width: 120px;
}
