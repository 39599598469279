@import '../../assets/css/variables.css';
.green {
  color: var(--color-green);
}

.grey {
  color: var(--color-icon);
}
@media (max-width: 767px) {
  :global(.ui).popup {
    display: none !important;
  }
}
