.p1em {
  padding: 1em;
}

.exportColumn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0;
  padding-top: 20px;
}

.reportTypeSelector {
  max-width: 160px;
  margin-right: 20px;
}

.exportToExcel {
}

:global(.ui.button).refreshButton {
  margin-top: 24px;
}

:global(.icon).printButton {
  margin-left: 15px;
  height: 100%;
  cursor: pointer;
}
