.buttonsFooter :global(.ui.button) {
  margin: 0;
}

.confirmContent {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.confirmContentLine {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bold {
  font-weight: bold;
}
