:global(.ui.grid).taskPrompt {
  margin-top: 5%;
}

:global(.ui.grid).taskPrompt h1 {
  font-size: 48px;
  line-height: 48px;
  margin-top: 40px;
}

:global(.ui.grid).taskPrompt button {
  margin-top: 30px;
}
