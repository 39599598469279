:global(.ui.segment).segmentHolder {
  padding-top: 20px;
  padding-bottom: 20px;
}

.headerHolder {
  display: flex;
}

.chevron {
  width: 20px;
  height: 20px;
  font-size: 21px;
  display: inline-block;
  color: var(--color-icon);
  margin: 3px 20px 0 0;
  cursor: pointer;
}

.chevron:hover {
  color: var(--color-green);
}

:global(.ui.header).headerTitle {
  margin: 0;
  line-height: 1;
}

.childrenHolder {
  margin-top: 1rem;
}

.childrenHolder > :global(.ui.grid:first-child) {
  margin-top: -0.5rem !important;
}

.childrenHolder :global(.ui.grid + .ui.grid) {
  margin-top: 0;
}
