@import '../../../assets/css/variables.css';

.sprayGrid {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.sprayGrid.forModal > div {
  padding: 0;
}

.sprayGrid.forModal > div + div {
  margin-top: 15px;
}

.sprayGrid.forModal :global(.ui.grid > .row > [class*='sixteen wide'].column + [class*='sixteen wide'].column) {
  margin-top: 1rem;
}

.sprayGrid:not(.forModal) :global(.ui.segment).sprayTasksSidebarIn {
  flex: auto;
}

.sprayFormFooter {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.sprayFormContainer {
  overflow: auto;
  padding: 30px 15px;
}

.sprayFormContainer > div {
  max-width: 900px;
  margin: 0 auto;
}

.buttons :global(.ui.button) {
  font-size: 18px;
  color: var(--color-icon);
}

.tableHolder {
  /*
    min-width: 500px;
    overflow: auto;

     */
}

:global(.ui.table).tableCrop thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

:global(.ui.table).tableCrop.modalMode thead th {
  padding-left: 2px;
  padding-right: 2px;
  font-size: 12px;
}

:global(.ui.table).tableCrop.modalMode tbody td {
  padding-left: 2px;
  padding-right: 2px;
}

:global(.ui.table).tableCrop tbody td input {
  padding-left: 5px;
  padding-right: 5px;
}

:global(.ui.table).tableCrop tbody td:last-child {
  padding-right: 0;
}

.signRequired {
  color: #ff0000;
}
.signRequired:after {
  content: '*';
}

.selectCrop {
  width: 100%;
}

.inputRate {
}

.tableCrop :global(.ui.dropdown > .text) {
  white-space: nowrap;
}
.newButtonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.newButtonsWrapper :global(.ui.button) {
  margin: 0;
}

.newButtonsWrapper :global(.ui.button) + :global(.ui.button) {
  margin-left: 10px;
}

.newButtonsWrapper :global(.ui.button .tuf-pen) {
  color: var(--color-green);
}
:global(.ui.form) .tableCrop :global(.field .ui.input.labeled) input {
  width: 100%;
}

:global(.ui.button).buttonAdd {
  white-space: nowrap;
  margin-right: 0;
}

:global(.ui.table).tableCrop.modalMode :global(.ui.button).buttonAdd {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 0.25em 0 0;
}

.inputPeriod :global(.ui.input) {
}
.inputPeriod :global(.ui.dropdown.label) {
}

.inputPeriod :global(.dropdown.icon) {
  float: right;
}

:global(.ui.form .field).inputPeriod :global(.ui.input input) {
  width: 100%;
}

:global(.ui.icon.button).buttonFile {
  font-size: 22px;
  padding: 15px;
}
:global(.ui.form) .columnsEqual + .columnsEqual {
  padding-top: 0;
}

:global(.ui.form) .columnsEqual:global(.fields .field .ui.input input) {
  width: 100%;
}

.inputHidden {
  height: 0;
}

:global(.ui.label).daysLabel {
  line-height: 2.5em;
}

:global(.ui.form .field).checkBox {
  margin-top: 10px;
}

:global(.ui.table).tableCrop tbody td.dashField {
  position: relative;
  padding-right: 5px;
}

:global(.ui.table).tableCrop tbody td.dashFieldAfter {
  padding-left: 5px;
}

:global(.ui.table).tableCrop tbody td:last-child {
  width: 180px;
  text-align: center;
}

.dashField:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -2px;
  width: 5px;
  height: 1px;
  background: #333;
  display: block;
}

.cropTableHolder .tableCrop .title {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color-header);
  line-height: 1;
  margin-bottom: 5px;
  display: none;
}

.chemicalOverGrid.forModal > :global(.row) > div:global(.column) + div:global(.column) {
  margin-top: 30px;
}

.buttonsFooter :global(.ui.button) {
  margin: 0;
}

@media (max-width: 767px) {
  .cropTableHolder .tableCrop .title {
    display: block;
  }
  .cropTableHolder :global(.ui.table).tableCrop {
    border: none;
  }

  .cropTableHolder :global(.ui.table).tableCrop thead {
    display: none;
  }
  :global(.ui.table).tableCrop thead th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
  }

  .cropTableHolder :global(.ui.table).tableCrop tbody {
    display: block;
    position: relative;
  }

  .cropTableHolder :global(.ui.table).tableCrop tbody tr {
    display: flex;
    flex-wrap: wrap;
  }

  .cropTableHolder :global(.ui.table).tableCrop tbody tr td {
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-left: 2px;
    padding-right: 2px;
  }

  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:nth-child(1) {
    width: 50%;
  }
  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:nth-child(2) {
    width: 50%;
  }
  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:nth-child(3),
  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:nth-child(4),
  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:nth-child(5) {
    width: 33.33%;
  }
  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:nth-child(6),
  .minWidth {
    width: 100%;
  }

  .dashField:after {
    top: 70%;
  }

  .rowCrop {
    position: relative;
  }

  .rowCrop .dashField:after {
    display: none;
  }
  .cropTableHolder :global(.ui.table).tableCrop tbody tr td:last-child {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  }

  .cropTableHolder .tableCrop tbody tr.rowCrop td:last-child {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px !important;
    flex-direction: row;
  }

  .chemicalOverGrid :global(.row) > div:global(.column) + div:global(.column) {
    margin-top: 30px;
  }

  :global(.ui.button).buttonAdd {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.editCropValidation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.addNewChemicalButtonWrapper {
  width: 78px !important;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
