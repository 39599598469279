.page {
  maxwidth: 297mm;
  margin: 0;
  padding: 0mm 10mm;
}

.block {
  justifycontent: space-around;
  display: grid;
}

.normalText {
  fontweight: normal;
}

.table {
  font-size: 12px !important;
  vertical-align: top;
}

.header {
  text-align: left;
}

.tbody {
  vertical-align: top;
}

.plannedDateHeader {
  min-width: 125px;
}

.workingTimes {
  display: inline-block;
}

.workingTimesLabel {
  display: inline-block;
  verticalalign: top;
  width: 35mm;
}

.underline {
  borderbottom: 1px solid black;
}

.moreInfoContainer {
  *position: relative;
}

.moreInfoItem {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
}

.multiItemsContainer {
  display: inline-block;
  vertical-align: top;
  /*position: relative;*/
}

.multiItemsItem {
  /*display: inline-block;*/
  /*float: left;*/
}
