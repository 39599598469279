.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainer .editButtonContainer {
  visibility: hidden;
}

.titleContainer:hover .editButtonContainer {
  visibility: visible;
}

.editButtonContainer {
  display: inline-flex;
  height: 28px;
  margin-right: auto;
  justify-content: start;
  align-items: center;
  margin-left: 8px;
}

.editButtonContainer button {
  position: relative;
}

.editButtonContainer button > i {
  position: absolute;
  left: 10px;
  bottom: 13px;
}

.editButtonContainer button > i:hover {
  background-color: transparent !important;
}

.title:global(.ui.header) {
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

.titleContainer i {
  border-radius: 20px;
  height: 24px;
  width: 24px;
  line-height: 22px;
}

.titleContainer i:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
