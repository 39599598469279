.holder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f7f7f7;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;

  overflow: hidden;
}

.photo > img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.active {
  opacity: 1;
}

.buttonsHolder {
  margin: auto;

  width: 100%;
  height: 20px;

  z-index: 99;
  cursor: pointer;
}

.arrowLeft,
.arrowRight {
  opacity: 0.7;
  height: 20px;
  width: 20px;
  display: block;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.arrowLeft:hover,
.arrowRight:hover {
  opacity: 1;
}

.arrowLeft {
  float: left;
}

.arrowRight {
  float: right;
}

.caption {
  width: 100%;
  display: inline-block;
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
}
