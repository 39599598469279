.imageSegment {
  display: flex;
  flex-wrap: wrap !important;
}

.imageButton {
  cursor: pointer;
  padding: 0 !important;
  background: none !important;
}

.addImageContainer {
  width: 136px !important;
  height: 136px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  border: 1px dashed #b3b3b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
}

.deleteBtnContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
}

.deleteBtn {
  background: none !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border: none !important;
  box-shadow: none !important;
  color: #b00020 !important;
}
