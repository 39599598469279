.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.modalHeader {
  font-family: Roboto, sans-serif !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0;
  text-align: left;
}

.pictureDescText {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
}

.dividerLine {
  color: #dededf;
  height: 8px;
  width: 1px;
}

.collapseBlock .titleContainer .title:global(.ui.header) {
  color: red !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.grid) {
  margin: 0;
}

.minRateInput {
  position: relative;
}

.minRateInput:after {
  content: "-";
  display: block;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.removeActiveIngredient {
  color: #b00020;
  margin-right: 0 !important;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.cancelButton:global(.ui.green.button) {
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cancelButton:global(.ui.basic.green.button) {
  background-color: #ffffff !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.cancelButton:global(.ui.basic.green.button):hover {
  color: #ffffff !important;
  border: none;
}

.cancelButton:global(.ui.green.button) {
  background-color: var(--color-green) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.cancelButton:global(.ui.green.button):hover {
  background-color: #156941 !important;
}

.cancelButton:global(.ui.button:disabled) {
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.customFieldLabel {
}
.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.ingredientHeader {
  margin-bottom: 0 !important;
}

.ingredientSection {
  display: flex;
  justify-content: space-between;
}

.activeIngredientMessage :global(.ui.header) {
  margin: 0 !important;
}

.attachmentLabel {
  margin-right: auto !important;
}

.fileChooserContainer {
  height: auto;
  display: flex !important;
  align-items: center !important;
}

.generalAttachmentsContainer {
  margin-top: 2px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  margin-bottom: 10px;
}

.attachmentItemsContainer {
  display: flex !important;
  flex-wrap: wrap;
  align-items: flex-end !important;
  max-width: 70%;
  height: auto;
}

.attachmentItemsContainer div {
  margin-right: 5px !important;
}

.generalAttachmentsButton {
  margin-left: auto !important;
}

.root :global(.ui.form .field) > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.root :global(.ui.form .disabled.field) label {
  color: black;
  opacity: 0.7;
}

.root .chemicalPropertyColumn {
  padding-left: 0 !important;
}

.root .chemicalPropertyColumn :global(.field) {
  margin-bottom: 16px !important;
}

.actionText {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #0e9354;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.actionText:hover {
  text-decoration: underline;
}

.actionText:disabled {
  color: #b3b3b3;
}

.tabsHolder {
  margin-top: 10px;
}

.tabsHolder a {
  width: 160px !important;
  justify-content: center;
}

.tabs {
  margin: 0 -15px;
}

.tabs > :global(.ui.secondary.menu) {
  background: #fff;
  margin-bottom: 0;
}

.tabs > :global(.ui.segment) {
  margin-top: 0;
}

.progressBar {
  color: var(--color-background-green) !important;
}
.progressBar:global(.ui.progress > .label) {
  color: #59b655 !important;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  background: #fff;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.active.item) {
  color: var(--color-background-green);
  border-color: var(--color-background-green);
}

.tabItem {
  overflow: hidden;
  height: 0;
}

.tabItem.visibleTab {
  height: auto;
  padding-bottom: 15px;
}

.cancelButton {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.labelText {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.labelText::after {
  margin: -0.2em 0em 0em 0.2em;
  content: "*";
  color: #db2828;
}

.modalContentContainer {
  min-height: 70vh;
  scrollbar-width: none;
}

.modalContentContainer::-webkit-scrollbar {
  display: none;
}

.infoIconContainer {
  position: relative;
  height: 10px;
  color: #b3b3b3;
}

.infoIconContainer i {
  position: absolute;
  right: 400px;
  top: 0;
}

@-moz-document url-prefix() {
  .infoIconContainer i {
    position: absolute;
    right: 400px;
    top: 14px;
  }
}

.dataPickerField :global(.ui.large.input) {
  height: 38px !important;
}
.removeContractor {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.contractorsSection {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.contractorHeader {
  margin-bottom: 0 !important;
}

.contractorWrapper {
  height: 40px !important;
}
