@import '../../assets/css/variables.css';
.detailsHolder {
}

.detailsHolder :global(.ui.basic.segment) {
  padding: 0;
}

.detailsHolder :global(.ui.button.button-text) {
  color: var(--color-violet);
}

.detailsHolder :global(.ui.button.button-text):hover {
  opacity: 0.6;
}

.detailsItem {
}

.detailsTitle {
  text-transform: uppercase;
  color: var(--color-middle);
}

.detailsTitle :global(i.icon) {
  font-size: 14px;
}

.detailsText {
  margin-top: 5px;
}

.lineHorizontal {
  height: 1px;
  background: var(--color-border);
  border: none;
  margin: 30px 0;
}
.tasksTotal {
  margin-top: 10px;
}
.tableTasks {
}
:global(.ui.basic.table).tableTasks tbody tr {
  border: none;
}

:global(.ui.basic.table).tableTasks tbody td {
  padding: 0 0 5px 0;
  border: none;
}

.timer {
  color: var(--color-pink);
}
.timer :global(i.icon),
.clock :global(i.icon) {
  font-size: 18px;
}

.formHolder {
  margin-top: 30px;
}

.formHolder label > div:first-child {
  margin-bottom: 10px;
}

.formHolder label :global(.ui.input) {
  width: 100%;
}

.formHolder label textarea {
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  border-radius: 0.28571429rem;
  resize: vertical;
  min-height: 100px;
}

.formHolder :global(.field + .field) {
  margin-top: 30px;
}
