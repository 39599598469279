:global(.ReactTable).dataTable {
  overflow: auto;
  border: none;
}

:global(.ReactTable).dataTable :global(.rt-thead.-header) {
  box-shadow: none;
}

:global(.ReactTable).dataTable :global(.rt-tbody .rt-tr-group) {
  border-bottom: 1px solid var(--color-border);
}

:global(.ReactTable.-striped).dataTable :global(.rt-tbody .rt-tr.-odd) {
  background: var(--color-row-stripped);
}

:global(.ReactTable).dataTable :global(.rt-tbody .rt-td) {
  overflow: visible;
  padding: 13px 15px;
  white-space: normal;
  border: none;
}

:global(.ReactTable).dataTable :global(.rt-thead .rt-tr) {
  border-bottom: 1px solid var(--color-border);
}

:global(.ReactTable).dataTable :global(.rt-thead .rt-th) {
  border: none;
  text-align: left;
  padding: 10px 15px;
  color: var(--color-dark);
}

:global(.ReactTable).dataTable :global(.rt-thead .rt-th:first-child),
:global(.ReactTable).dataTable :global(.rt-tbody .rt-td:first-child) {
  border-right: 1px solid var(--color-border);
}

:global(.ReactTable).dataTable :global(.rt-tbody .rt-tr:hover .rt-td) {
  background: var(--color-row-hover) !important;
}

.tableHolder {
  padding: 1rem;
  background: #fff;
  border-radius: 5px;
}
