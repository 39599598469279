.modalHeader {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

.formSmallField {
  width: 16.5%;
}

.formSmallDropDown {
  max-width: 100% !important;
  margin: 0 !important;
}

.formSmallDropDown div {
  min-width: 16.5% !important;
}

.workersRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
}

.workersRowHead {
  width: 80px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  height: 24px !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding-right: 8px !important;
  margin-bottom: 5px !important;
}

.rowContainer {
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 10px !important;
}

.expandedIcon {
  cursor: pointer;
}

.closeIcon {
  margin-bottom: 5px;
}

.dateRow {
  border-bottom: 1px solid #dededf !important;
}

.firstStepForm {
  padding-left: 9px !important;
  width: 100% !important;
}

.scanDateField {
  padding-bottom: 14px;
}

.horizontalLine {
  border: none;
  background-color: #dededf;
  height: 1px;
  color: #dededf !important;
}

.tableGroup {
  display: block !important;
  padding-left: 5px !important;
}

.searchInput {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-left: 8px !important;
  gap: 8px;
}

.searchAndFiltersContainer {
  display: flex !important;
  margin-bottom: 16px;
}

.toggleIcon {
  height: 40px;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.openTable {
  min-height: 40px;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.tbody {
  display: block !important;
  vertical-align: middle !important;
  width: 100%;
}

.icomNameContainer {
  width: 100% !important;
}

.chevronContainer {
  width: 100% !important;
}

.patchRow {
  display: block !important;
}

.patchCell {
  display: block !important;
  padding: 0 !important;
  height: 32px !important;
  padding-left: 25px !important;
  border: none !important;
}

.blockName {
  width: 25%;
  padding-left: 10px !important;
}

.cropField {
  width: 25%;
  padding-left: 40px !important;
}

.patchName {
  padding-left: 10px !important;
  width: 25% !important;
}

.closeTable {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.iconNameWrapper {
  display: flex;
  align-items: center !important;
  width: 100%;
}

.selectAllButton {
  display: flex !important;
  align-items: center !important;
  height: 30px;
  background: #28b573 !important;
  color: white !important;
  cursor: pointer !important;
  padding: 11px !important;
}

.expandedIcon {
  cursor: pointer;
}

.areaModal :not(i:global(.icon)),
.areaModal :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.areaModal :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.areaModal :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
}

.areaModal :global(.ui.grid) {
  margin: 0;
}

.modalContent {
  min-height: calc(70vh);
}

.contentWrapper {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.paginationContainer {
  width: 40%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.searchContainet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modalActionBtn {
  display: flex !important;
  justify-content: end;
  align-items: center;
}

.mainDropdown {
  min-width: 100px !important;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.paginationDropDown {
  min-width: 120px !important;
}

.paginationDropDown i {
  padding: 0 !important;
  padding-top: 4px !important;
}

.pageSelectDropdown {
  min-width: 120px !important;
}

.pageSelectDropdown i {
  padding: 0 !important;
  padding-top: 4px !important;
}

.pagination {
  height: 38px;
  border: none !important;
  box-shadow: none !important;
}

.pagination a {
  height: 38px;
  background-color: white !important;
}

.pagination > a::before {
  display: none !important;
}

.dropdownLabel {
  padding-right: 10px !important;
}

.pagination :global(.active.item) {
  display: none !important;
}

.mainDropdown :global(.menu > .item:hover) {
  z-index: 1 !important;
}

.sellectAllBtn {
  background: none !important;
  color: rgb(89, 182, 85) !important;
  border: 1px solid rgb(89, 182, 85) !important;
}
