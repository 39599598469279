.barHolder {
  display: flex;
  margin: 0 -5px;
  align-items: center;
  flex-wrap: wrap;
}

.barHolder > div {
  flex: 0 0 auto;
  padding: 5px;
}

.barHolderIn {
  display: flex;
  align-items: center;
}

.barHolderIn > div {
  flex: 0 0 auto;
  padding: 0 5px;
}

.dateInput {
  width: 120px;
}
