.overlayHolder {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.overlayHolder.noOverflow {
  height: auto;
}

.overlayMain {
  background: var(--color-background);
  flex: auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.overlayMain form,
.overlayMain form > :global(.ui.form) {
  flex: auto;
  display: flex;
  height: 100%;
}

:global(.ui.grid).sprayGrid {
  flex: 1 1 auto;
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  margin-left: 0;
  margin-right: 0;
}

:global(.ui.grid).sprayGrid > :global(.row) {
  padding: 0;
}

:global(.ui.grid).sprayGrid > :global(.row) > div {
  display: flex;
  padding-top: 30px;
  padding-bottom: 15px;
}

:global(.ui.segment).sprayTasksSidebarIn {
  flex: auto;
  width: 100%;
}

:global(.ui.form) .overlayMain :global(.field label),
.overlayMain :global(.ui.form .field label),
.overlayMain :global(.field label) {
  margin-bottom: 5px;
  display: block;
  color: var(--color-dark);
  font-weight: normal;
  font-size: 1rem;
}

:global(.ui.form) .overlayMain :global(.required.field label:after),
.overlayMain :global(.required.field label:after) {
  color: var(--color-pink);
  content: "*";
  margin-left: 5px;
}

.overlayMain :global(.required.field .ui.checkbox label:after) {
  margin-left: 0;
}

.overlayMain :global(.ui.header) {
  margin-top: 0;
}

.overlayMain :global(.ui.grid + .ui.header),
.overlayMain :global(.ui.grid > .row + .ui.header) {
  margin-top: 2rem;
}

.sprayFormContainer {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.overlayMain div > :global(.ui.segment + .ui.segment) {
  margin-top: 1rem;
}

.overlayMain :global(.field.disabled) {
  opacity: 0.5;
}

.overlayMain :global(.field > label) {
  margin-bottom: 5px;
  display: block;
  color: var(--color-dark);
}

.overlayMain :global(.field > label.error) {
  color: var(--color-pink);
}

.overlayMain :global(.field > label .ui.checkbox) {
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.overlayMain :global(.ui.grid) {
  margin-top: 0;
  margin-bottom: 0;
}

.overlayMain :global(.ui.grid > .row) {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

:global(.ui.menu.sprayMenu) {
  margin: 0;
  min-height: auto;
  box-shadow: none;
}

:global(.ui.fluid.menu.sprayMenu) {
  display: flex;
  justify-content: space-around;
}

:global(.ui.menu.sprayMenu .item) {
  border-radius: 3px !important;
  margin: 2px;
  padding: 10px;
  justify-content: center;
}

:global(.ui.menu.sprayMenu.rolesMenu .item) {
  padding: 10px 8px;
}

:global(.ui.fluid.menu.sprayMenu .item) {
  flex: 1;
}

:global(.ui.menu.sprayMenu .item):before {
  display: none;
}

:global(.ui.menu.sprayMenu .item:not(:first-child)):before {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px;
  width: 1px;
  background: var(--color-light-grey);
  display: block;
}

:global(.ui.menu.sprayMenu .active.item) {
  background: var(--color-background-label);
  color: #fff;
}

:global(.ui.menu.sprayMenu .active.item.disabled) {
  background: var(--color-light-grey) !important;
  color: #fff;
}

:global(.ui.menu.sprayMenu .active.item):before,
:global(.ui.menu.sprayMenu .active.item + .item):before {
  display: none;
}

.sprayFormFooter {
  padding: 10px 0;
  border-top: 1px solid var(--color-background-secondary);
}

@media (max-width: 767px) {
  :global(.ui.menu.sprayMenu:not(.compact)) {
    display: block;
  }

  :global(.ui.menu.sprayMenu:not(.compact) .item) {
    display: block;
    float: left;
  }

  .overlayMain :global(.ui.grid > .row > .column + .column) {
    margin-top: 1rem;
  }

  .overlayMain .sprayFormFooter :global(.ui.grid > .row > .column + .column) {
    margin-top: 0;
  }

  :global(.ui.grid).sprayGrid > :global(.row) > div:not(:first-child) {
    padding-top: 0;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .overlayMain
    :global(.ui.grid > .row > [class*="sixteen wide tablet"].column + .column) {
    margin-top: 2rem;
  }
}
