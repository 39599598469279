@import '../../assets/css/variables.css';

:global(.ui.message).messageInfo {
  margin: 0;
  display: flex !important;
  flex-wrap: nowrap;
  padding: 15px;
  box-shadow: var(--box-shadow-flowly);
}
:global(.ui.message).messageInfo > div {
  flex: auto;
}
:global(.ui.message).messageInfo > div.closeButton {
  flex: 0;
  padding-left: 1rem;
}
:global(.ui.message).messageInfo > div.closeButton :global(.ui.button),
:global(.ui.message).messageInfo > div.closeButton :global(.icon) {
  margin: 0 !important;
}

:global(.ui.message).messageInfo + .messageInfo {
  margin-top: 10px;
}

:global(.ui.message.black).messageInfo :global(.ui.button) {
  color: #fff;
}
