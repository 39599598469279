.barHolder {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.barHolder :global(.CircularProgressbar) {
  position: relative;
  z-index: 2;
}

.text {
  color: var(--color-green);
}

.text.zero {
  color: var(--color-icon);
}

.check {
  color: var(--color-green);
  font-size: 29px;
  margin: 2px 0 0 2px;
}

:global(.CircularProgressbar-trail) {
  stroke-dasharray: 8, 16.3 !important;
}
