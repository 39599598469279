:global(.ui.popup).popup {
  background-color: black;
  color: white;
}

.infoButton {
  margin-left: 8.38px;
  color: #b3b3b3;
}

:global(i.icon) {
  margin: 0;
}

.infoButton {
  margin-left: 8.38px !important;
  color: #b3b3b3 !important;
}

.messageContainer {
  text-align: start;
  display: flex !important;
  flex-direction: column;
  padding: 10px !important;
  position: absolute !important;
  min-width: 200px;
  width: auto;
  left: 0px !important;
  top: -45px !important;
  background: #212121 !important;
  border-radius: 4px !important;
  font-size: 14px;
  line-height: 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
}

.avatarsContainer {
  width: 30px;
  height: 30px;
  padding: 0 !important;
  padding-top: 2px !important;
  margin: 0;
  border-radius: 100%;
  background-size: 100%;
  background-color: #d1dce3;
  display: flex !important;
  justify-content: center !important;
  color: white;
  align-items: center !important;
}

.userAvatarsContainer {
  width: 31px;
  height: 31px;
  padding: 0 !important;
  margin: 0;
  border-radius: 100%;
  background-size: 98%;
  background-color: #d1dce3;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}

.linkWrapper {
  display: grid;
}

.avatarsIcon {
  width: 20px !important;
  height: 20px !important;
}

.reasonMessages {
  padding: 0px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  -webkit-line-clamp: 1 !;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

:global(.ui.button).printButton {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).printButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).printButton:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).printButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.iconWrapper {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
.buttonWrapper {
  display: flex;
}
