:global(.ui.modal).modalPrintAndExport :global(.content) {
  column-count: 2;
  width: 100%;
  padding-top: 25px;
}

:global(.ui.modal).modalPrintAndExport .contentWithSectionModal {
  column-count: 1;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 0px !important;
}

:global(.ui.modal).modalPrintAndExport .childrenContentWithSectionModal {
  column-count: 2;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px !important;
}

.modalHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

:global(.ui.button).reportsModalDisabledButton {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.modalAction {
  background-color: white !important;
}

:global(.ui.button).reportsModalButton {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #0e9354 !important;
  border-radius: 4px;
  color: white !important;
}

:global(.ui.button).reportsModalButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).reportsModalButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

:global(.ui.button).reportsModalCancelButton {
  height: 28px;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).reportsModalCancelButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).reportsModalCancelButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.allButton {
  padding-left: 21px;
  padding-top: 20px;
}

.reportsCheckBox label::before {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.reportsCheckBox label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.modalHeader {
  text-align: center;
}
