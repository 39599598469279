@import "../../assets/css/variables.css";

.valueToShow {
  display: inline-block;
  border-bottom: 1px dashed var(--color-border);
  cursor: pointer;
}

.valueToShow.disabled {
  border: none;
  cursor: default;
}

.heavyHolder {
  background: var(--color-background-cell);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 70px 15px 30px 15px;
  z-index: 50;
  overflow: auto;
}
.heavyHolder :global(.ui.dropdown .menu) {
  max-height: 16.02857142rem;
}

.textArea {
  width: 100%;
  resize: vertical;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--color-border);
}
.listView {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listView li {
}

.listView li + li {
  margin-top: 5px;
}

@media screen and (min-width: 768px) {
  .heavyHolder {
    padding: 115px 25px 30px 25px;
  }
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}
.textButtonContainer {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}
.textButton:global(.ui.button):hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.inputWrapper {
  display: flex;
}

.inputButtonWrapper {
  text-align: end;
  margin-bottom: 10px !important;
}

.batchNumberInput {
  width: 88%;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.batchNumberInput input {
  border: none !important;
}

.ingredientSection {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.removeActiveIngredient {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}
