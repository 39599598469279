@import '../../assets/css/variables.css';

.sidebar > :global(.ui.grid) {
  margin-bottom: 0;
}

.sidebar > :global(.ui.grid:not(:first-child) + .ui.header) {
  margin-top: 50px;
}

.buttonsHolder {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  z-index: 10;
}

.buttonsHolder button:not(:last-child) {
  margin-right: 20px;
}
.buttonsHolder :global(.ui.button > .icon:not(.button)) {
  height: auto;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 414px) {
}
