.sprayGrid {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.sprayFormContainer {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.sprayFormFooter {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.sprayFormContainer {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.sprayFormContainer > div {
  max-width: 900px;
  margin: 0 auto;
}

.inputHidden {
  position: absolute;
}

.label {
  color: var(--color-dark);
}

@media only screen and (max-width: 767px) {
  .sprayFormContainer :global(.ui.grid > .row > .column + .column) {
    margin-top: 0;
  }
}
