.root {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
}

.wrapperRow {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.labelRow {
  border-bottom: 1px solid #dededf;
  margin-bottom: 16px;
  width: 100%;
}

.deleteButtonContainer {
  display: flex !important;
  justify-content: flex-end !important;
  margin-bottom: 14px !important;
}

.newRowbuttonContainer {
  margin-top: 16px;
  padding-bottom: 16px;
}

.delete {
  width: 95px !important;
  height: 28px !important;
  color: rgba(176, 0, 32, 1) !important;
  border: 1px solid rgba(176, 0, 32, 1) !important;
  border-radius: 4px !important;
  background: none !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  cursor: pointer;
  padding: 0px !important;
  margin-left: 10px !important;
}

.deleteButtonIconWrapper {
  margin-top: 5px;
  width: 100% !important;
  height: 100% !important;
}

.deleteButtonIcon {
  margin-right: 8px;
  margin-bottom: 6px !important;
}

.formWrapper {
  display: flex;
  align-items: center;
  height: 53px;
  border-bottom: 1px solid #dededf;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.rowWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dededf;
  height: 53px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

:global(.ui.scrolling.dropdown) {
  width: 100% !important;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}

.dateSelectWrapper {
  width: 262px;
  margin-right: 16px;
  position: absolute;
  top: 55px;
  position: absolute;
  z-index: 99999;
}

.selectorBody {
  padding: 16px;
  width: 100%;
  margin-right: 100px;
  height: 222px;
  background: #ffffff;
  border: 1px solid #dededf;
  border-radius: 4px;
}

.selectorDateRangeContainer {
  margin-top: 8px;
}

.dateValues {
  width: 80%;
}

.dateSelectIcon {
  border: none !important;
}

.datePlaceholder {
  display: flex !important;
  justify-content: start !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #b3b3b3 !important;
}

.placeholder {
  display: flex;
  justify-content: end;
  color: #dededf !important;
}

.dateInput :active {
  border-right: none !important;
}

.dateInput :focus {
  border-right: none !important;
}

.dateInput i {
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding-right: 16px;
}

.inputWrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 22px;
  padding: 14px;
  font-size: 15px !important;
}

.fromDate {
  margin-left: 8px !important;
}

.calendareIcon {
  padding-left: 8px;
  padding-bottom: 4px;
}

.rdt {
  width: 45%;
}

.rdt input,
.rdt .form-control {
  width: 100%;
  color: transparent !important;
  background-color: transparent !important;
  border: none !important;
}

.wrapper {
  width: 100%;
  position: relative;
}

.startDate {
  text-align: center;
  position: absolute;
  width: 90px;
  font-size: 14px !important;
  padding-right: 5px !important;
  top: 30px;
  left: 15px;
}

.radioButtonContainer label {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #666666 !important;
}

:global(.ui.radio) input:checked ~ label::after {
  background-color: var(--color-green) !important;
}

.endDate {
  text-align: center;
  position: absolute;
  width: 100px;
  top: 30px;
  right: 15px;
}

.radioButtonContainer {
  margin-bottom: 8px;
}

.dateRangePicker {
  height: 40px;
  margin: 0;
  margin-top: 4px;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
}

.dateFieldWrapper {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.dateField {
  width: 100%;
  flex-direction: column-reverse;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.costtypeLabel {
  display: flex !important;
}

.checkboxWrapper {
  padding-left: 5px !important;
  width: 13% !important;
  margin-right: 12px !important;
}

.dateRangeLabel {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666 !important;
}

.dateRangeLabelDisabled {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #dededf !important;
}

.deleteIconWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}
.copyIconWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 14px !important;
}

.deleteButton {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.deleteButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
  color: #b00020;
}

:global(.icon).deleteButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}
.copyButton {
  width: 15.75px;
  margin-left: 0 !important;
  width: 14px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.copyButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
  color: var(--color-green) !important;
}

:global(.icon).copyButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.page {
  display: flex;
}

.hamburger {
  background-color: #222;
  width: 100px;
  height: 100px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.hamburger .line {
  width: 70px;
  height: 10px;
  margin-left: 10px;
  background-color: #fff;
  pointer-events: none;
}

.hamburger .line:not(:last-child) {
  margin-bottom: 10px;
}

.menu {
  display: none;
  background-color: #222;
  color: #fff;
  padding: 5px 0;
}

.menu .item {
  padding: 5px 15px;
  cursor: pointer;
}

.menu.active {
  display: inline-block;
}

.placeholderDropdownTree {
  position: absolute;
  top: 29%;
  padding-left: 2px;
  left: 14%;
  color: rgba(191, 191, 191, 0.87) !important;
}

.areasLabelDropdownTree {
  position: absolute;
  top: 29%;
  padding-left: 2px !important;
  left: 13%;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}

:global(.ui.button).cancelDltBtn {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

:global(.ui.button).deleteBtn {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).deleteBtn:hover {
  background: #6b0518;
  border-radius: 4px;
  color: white;
}

.disabledField {
  opacity: 1 !important;
  height: 39px;
  background-color: white !important;
  border: 1px solid #dededf !important;
  font-family: Roboto !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.disabledField input {
  opacity: 1 !important;
  border: none !important;
  color: #212121 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.removeCostEnteringType {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
  margin-right: 10px !important;
}

.costEnteringTypeSection {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.costEnteringTypeHeader {
  margin-bottom: 0 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
}

.costEnteringTypeWrapper {
  height: 40px !important;
}

.costEnteringTypeField div {
  border: none !important;
  padding-left: 0px !important;
}
.costEnteringTypeField div input {
  border: none !important;
  padding-left: 0px !important;
}

.costEnteringTypesSection {
  margin-bottom: 0px !important;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7px !important;
}

.datePeriod {
  padding-left: 0px !important;
}
.notes {
  padding-left: 5px !important;
}
.total {
  padding-left: 9px !important;
}
.rate {
  padding-left: 10px !important;
}
.units {
  padding-left: 10px !important;
}
.type {
  padding-left: 10px !important;
}
.costEnteringTypeField .ui.active.selection.dropdown {
  height: 40px;
}
.buttonContainer {
  display: flex;
}

:global(.ui.button).importButtonForPage {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  font-family: "Roboto" !important;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).importButtonForPage:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).importButtonForPage:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).importButtonForPage:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.iconWrapperForPage {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
