@import "../../assets/css/variables.css";

:global(.ui.segment).attachments {
  padding: 0;
}

.blockBox {
  margin-top: 15px;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}

.blockName {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.colHead {
  color: var(--color-header);
  font-size: 14px;
  text-transform: uppercase;
}

.colBody {
  color: var(--color-text);
}

.blockCol {
}

.buttonsCol {
  text-align: right;
}

.buttonsCol :global(button.ui.button) {
  padding: 0;
  background: none;
}

.buttonsCol :global(button.ui.button):first-child {
  margin-right: 10px;
}

.listHolder {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.listHolder a {
  font-weight: bold;
}

.listHolder li {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 15px;
}

.listHolder li > div:first-child {
  flex: 0 0 60px;
  text-align: center;
}

.listHolder li > div:nth-child(2) {
  flex: 1 1 auto;
  padding-left: 15px;
}

.listHolder li > div:nth-child(3) {
  flex: 0 0 30px;
}

.loaderHolder {
  width: 60px;
  height: 60px;
}

:global(.ui.label).attachmentLabel {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
  height: 24px !important;
  display: flex;
  align-items: center;
  max-width: 145px;
  width: 145px;
  margin: 0px !important;
  margin-bottom: 2px !important;
}

:global(.ui.label).withoutCloseIcon {
  border-radius: 20px;
  height: 24px !important;
  display: flex;
  align-items: center;
  max-width: 155px;
  width: 155px;
  margin: 0px;
  margin-bottom: 2px !important;
}

:global(.icon).downloadButton {
  width: 9px !important;
  height: 9px !important;
  color: var(--color-green) !important;
}

:global(.icon).attachmentIcon {
  color: #666666;
}

.deleteButtonContainer {
  border: 1px solid #e0e0e0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  border-left: none;
  height: 24px;
  align-items: center;
  width: 20px;
  padding-bottom: 2px;
  padding-right: 10px !important;
  cursor: pointer;
  margin-right: 4px !important;
}
:global(.icon).deleteButton {
  padding-top: 3px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--color-error-delete) !important;
  opacity: 1 !important;
}

.attachmentTitle {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  padding-top: 2px;
  font-size: 12px !important;
  line-height: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400 !important;
  margin-right: auto;
}

.uploadingAttachmentTitle {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
  font-weight: 400 !important;
}

.percentNumber {
  color: #666666;
  margin-right: 5px;
  font-weight: 400 !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
