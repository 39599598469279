.modalHeaderWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-items: flex-end;
}

.modalHeader {
  width: 98%;
  display: flex;
  justify-content: center;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #212121 !important;
}

.closeButtonContainer {
  width: 2%;
}

.closeButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

:global(.icon).closeButton:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.closeButton {
  padding: 6px;
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #666666;
  position: relative;
}

.generalInformation {
  display: flex !important;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  margin-bottom: 16px !important;
  justify-content: space-between;
  border: 1px solid #dededf;
  height: 40px;
  border-radius: 4px;
  width: 100%;
}

.orderDate {
  padding-left: 16px;
  width: 25%;
}

.deliveryDate {
  width: 25%;
}

.supplier {
  width: 25%;
}

.totalPrice {
  width: 25%;
}

.generalInformationLabe {
  display: flex !important;
  justify-content: space-between;
  padding: 0px;
  padding-top: 16px;
  padding-bottom: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

.orderDateLabe {
  width: 25%;
}

.deliveryDateLabe {
  width: 25%;
}

.supplierLabe {
  width: 25%;
}

.totalPriceLabe {
  width: 25%;
}

.tableWrapper {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.root {
  width: 1000px !important;
}
