.layout :global(.block) {
}

.layout :global(.page) {
}

.layout :global(.pageBreak) {
  margin: 0;
  width: 100mm;
  padding: 0;
}

.layout :global(.barcodeHolder) {
  margin-bottom: 1.35mm;
  margin-top: 1.35mm;
  margin-left: 1.35mm;
  margin-right: 1.35mm;
  height: 37mm;
  width: 22.3mm;
}

.layout :global(.barcodeDataHolder) {
}

.layout :global(.barcode) {
  /*height: 15mm;*/
  /*width: 15mm;*/
}

.layout :global(.textHolder) {
  /*margin-top: 3px;*/
  /*line-height: 8px;*/
  /*font-size: 8px;*/
}

.layout :global(.text) {
  /*padding: 1px;*/
}
