.productHeader {
  font-weight: 500 !important;
  margin-bottom: 16px !important;
  font-size: 18px !important;
  line-height: 27px !important;
}

.root :global(.ui.segment) {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
}

.root :global(.ui.grid) {
  margin: 0;
}

.root :global(.content > :not(:first-child)) {
  margin-top: 16px !important;
}

.rowContainer {
  width: 100% !important;
  padding-right: 0 !important;
  padding-top: 8px;
  padding-left: 0 !important;
}

.wrapper {
  width: 100% !important;
  display: flex;
}

.wrapperRow {
  width: 100% !important;
  height: 65px !important;
  padding-right: 22px !important;
  padding-left: 16px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.columnContainer {
  width: 70% !important;
  display: flex;
  justify-content: space-between !important;
}

.costQtyContainer {
  width: 62% !important;
  padding-left: 0px;
  padding-right: 0px;
  display: flex !important;
}

.iconContainer {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.firstRow {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.buttonContainer {
  padding-top: 2px !important;
  margin-top: 0px !important;
  width: 100%;
  text-align: end;
}
.addButtonContainer {
  padding-top: 8px !important;
  margin-top: 0px !important;
  width: 100%;
  text-align: end;
}

.calendarWrapper {
  position: fixed;
}

.saveButton {
  padding: 0px !important;
  color: #0e9354 !important;
  background: none !important;
}

:global(.ui.button).disabledBtn {
  padding: 0px;
  background: none;
  color: #b3b3b3;
}

:global(.ui.basic.disabled.buttonActive) {
  border: 1px solid red !important;
}

:global(.ui.button).cancelBtn {
  padding: 0px;
  margin-right: 16px;
  background: none;
  color: #b00020;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

:global(.ui.button).cancelDltBtn {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

:global(.ui.button).cancelDltBtn:hover {
  background-color: #156941;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
}

:global(.ui.button).deleteBtn {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).deleteBtn:hover {
  background: #8c0d24;
  border-radius: 4px;
  color: white;
}

.nameColumn {
  width: 250px;
  margin-right: 32px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
}

.costColumn {
  text-align: start;
  /* width: 30%;   */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #666666;
}

.dotColumn {
  width: 4%;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantityColumn {
  width: 30%;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #666666;
}

.formContainer {
  display: initial !important;
  padding-top: 2px !important;
  width: 100 !important;
  padding-top: 0px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.toggleIcon i {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  cursor: pointer;
}

.toggleIcon.openTable {
  cursor: pointer;
  padding: 5px;
}

.productHeader {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px !important;
}
.productInformationHeader {
  height: 27px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 33px !important;
}

.productInformationHeader {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 33px !important;
}

.productInformationHeader {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 33px !important;
}

.dot {
  height: 5px !important;
  width: 5px !important;
  background-color: #bbb !important;
  border-radius: 50% !important;
  display: inline-block !important;
}

.removeManufacturer {
  cursor: pointer !important;
  color: #b00020 !important;
  margin-right: 0 !important;
  padding-bottom: 20px !important;
}

.manufacturersSection {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.manufacturerHeader {
  margin-bottom: 0 !important;
}

.manufacturerWrapper {
  height: 40px !important;
}

:global(.ui.search.dropdown > .text) {
  padding: 0px !important;
}

:global(.ui.labeled.input > .label) {
  height: 40px !important;
}

:global(.ui.input).totalCostField {
  height: 40px !important;
}

/* .manufacturerField div {
  height: 40px;
} */
:global(.ui.form .field) > :global(.selection.dropdown) {
  height: 40px;
}
.newProductWrapper {
  margin-bottom: 14px;
}
