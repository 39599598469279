.root {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.areaModal :not(i:global(.icon)),
.areaModal :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.areaModal :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.areaModal :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
}

.areaModal :global(.ui.grid) {
  margin: 0;
}

.modalContent {
  min-height: calc(70vh);
}

.contentWrapper {
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f5f7f7;
}

.headerContainer {
  height: 60px !important;
  padding-left: 16px;
  padding-top: 16px;
}

.header {
  display: flex;
  align-items: center;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.formContainer {
  background: transparent;
  overflow-y: hidden !important;
  height: calc(100vh - 100px);
  width: auto;
  overflow-y: auto;
  margin: 16px;
  padding-top: 0px !important;
  margin-top: 24px !important;
}

.buttonContainer {
  width: 100% !important;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #ffffff;
  height: 8% !important;
  margin-top: 24px;
  position: fixed;
  z-index: 2;
  bottom: 5px;
}

.buttonWrapper {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 16px;
  height: 5%;
}

.cancelButton {
  width: 84px !important;
  height: 36px;
  margin-right: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.saveButton {
  width: 71px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.sectionTableWrapper {
  background: transparent;
  display: flex;
}

.navigateMenu {
  border-radius: 4px;
  background-color: white;
  width: 340px;
  box-shadow: 0px 4px 9px 0px #0000001a;
  padding: 16px;
}

.generalInfoSection {
  border-bottom: 1px solid #dededf !important;
  padding-bottom: 24px;
}

.detailsSection {
  padding-top: 24px;
}

.menuHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #212121;
}

.activeMenuHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #0e9354 !important;
}

.menuSubHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #666666;
}

.activeSubHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #0e9354 !important;
}

.navigateTableContainer {
  margin-left: 14px;
  border-radius: 4px;
  background-color: white;
  width: calc(100% - 354px);
  height: calc(100vh - 270px) !important;
  overflow-y: auto;
  overflow-x: hidden !important;
  padding: 16px;
  max-height: 1150px;
}

.sectionTable {
  margin: 0px !important;
}

.tableSegment {
  width: 100%;
  padding: 16px !important;
  margin-bottom: 16px !important;
  box-shadow: 0px 1px 4px 0px #00000026;
}

.headerSection {
  height: 40px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  vertical-align: baseline;
}

.valueSection {
  height: auto !important;
  display: flex;
  gap: 5px;
  flex-wrap: wrap !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.tableHeader {
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.tableSubHeader {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
  padding-bottom: 16px !important;
}

.activeMenuItem {
  color: #0e9354;
  /* Customize this color to your preference */
}

.card {
  background-color: #f6f7f8;
  border: 1px solid #b3b3b3;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 10px;
  border-radius: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.emptyTableWrapper {
  width: 100%;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.emptyTableText {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.dateContainer {
  display: flex;
  flex-direction: column;
}

.warnMessage {
  margin-top: 4px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #f0ac47 !important;
}

.formLabel {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.dropdownStyle {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: space-between !important;
}

.dropdownCustomStyle {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: end !important;
}

.dropdownCustomStyle :global(.menu) {
  max-height: 250px;
}

.dropdownCustomStyle input {
  display: none;
}

.dropdownCustomStyle :global(.menu) > * {
  background-color: white !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.dropdownCustomStyle :global(.menu) > .item :hover {
  background-color: rgb(237, 236, 236) !important;
}

.dropdownCustomStyle :global(.label) {
  display: none !important;
}

.dropdownCustomStyle > :global(.label ~ .text) {
  display: contents !important;
}

.generalAttachmentsContainer {
  display: flex !important;
  flex-wrap: wrap;
}

.generalAttachmentsButton {
  margin-top: 5px !important;
  margin-left: auto !important;
}

.locationHeader {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
}

.locationBtnWrapper {
  display: flex;
  justify-content: space-between;
}

.addBtn {
  display: flex;
  align-items: center;
  margin-top: 16px !important;
  height: 40px !important;
  color: #0e9354 !important;
  background: none !important;
  padding-left: 0 !important;
}

.switchButtonWrapper {
  margin-top: 16px !important;
  width: 200px !important;
}

.areasFileWrapper {
  display: block !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.modalHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.locationSection {
  padding-top: 24px;
  border-bottom: 1px solid #dededf;
  padding-bottom: 24px;
}

.machinerList {
  padding-top: 25px;
}

.plusIcon {
  display: flex;
  align-items: center;
  font-size: 12px !important;
}

.input {
  height: 40px !important;
}

.calendarIcon {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 36px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.rdt input,
.rdt .form-control {
  color: #0e9354 !important;
}

.slashingOpenBtn {
  padding-left: 5px !important;
  font-style: italic;
  font-weight: 400;
  color: #0e9354;
  cursor: pointer;
}

.menuContainer {
  overflow: hidden !important;
  top: 100% !important;
  left: 0px !important;
  width: 100.5% !important;
  min-height: 300px !important;
  border-top: none !important;
}

.machineryMenuContainer {
  overflow: hidden !important;
  top: 105% !important;
  left: 0px !important;
  width: 100.5% !important;
  z-index: 1000;
}

.searchContainer {
  width: 100% !important;
  display: flex !important;
  align-items: center;
}

.searchInput {
  margin: 0 !important;
  width: 100% !important;
}

.searchInput input {
  display: block !important;
  width: 95% !important;
  margin: 10px !important;
  padding-left: 10px !important;
}

.searchInput i {
  margin-left: 10px !important;
}

.dropdonwIcon {
  position: absolute;
  left: 93%;
  top: 35%;
}

.expirCalendar {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listMenu {
  border-top: none !important;
}
