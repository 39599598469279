@import "../../../../../assets/css/variables.css";

.dropdownTree .dropdown {
  width: 100% !important;
}

.dropdownTree .dropdown a {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 4px !important;
}

.dropdownTree .tag {
  color: black;
}

.dropdownTree .search {
  border: none !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 5px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 5px !important;
}

.dropdownTree .dropdown-content {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
}
.areaSelectorlabel {
  margin-bottom: 5px;
  display: block;
  color: var(--color-dark);
  font-size: 14px;
}

.areaSelectorlabel::after {
  color: var(--color-pink);
  content: "*";
  margin-left: 5px;
}

.dropdownTree .dropdown a.arrow.top {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  box-shadow: none !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.dropdown .custom-buttons-container {
  padding: 8px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
}

.dropdown .custom-buttons-container .select-btn {
  color: var(--color-green);
  cursor: pointer;
}

.dropdown .custom-buttons-container .select-btn.active {
  text-decoration: underline;
}

.react-dropdown-tree-select .dropdown .dropdown-content .toggle.expanded:after {
  content: "" !important;
  display: inline-flex;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #626060;
}

.react-dropdown-tree-select
  .dropdown
  .dropdown-content
  .toggle.collapsed:after {
  content: "" !important;
  display: inline-flex;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #626060;
}

.fitSearch[role="treeitem"] {
  padding-left: 0 !important;
}

.fitSearch[role="treeitem"] > i[role="button"] {
  display: none !important;
}

.notFitSearch[role="treeitem"] {
  display: none !important;
}
