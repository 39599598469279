.backIconWrapper {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}
