.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}

.root :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}

.root :global(.ui.grid) {
  margin: 0;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.ui.grid > .row > .column.column-close {
  position: relative;
  width: 64px;
  padding: 0;
  border-left: 1px solid var(--color-border);
}

.modalHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  text-align: left !important;
  color: #212121 !important;
}

.modalContentContainer {
  min-height: 70vh;
  scrollbar-width: none;
}

.modalContentContainer::-webkit-scrollbar {
  display: none;
}

.closeIcon {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.progressBar {
  height: 100px;
  width: 100%;
  background-color: #f5f7f7 !important;
}

.progressBarHeader {
  color: #212121 !important;
  font-family: Roboto !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  padding-left: 16px;
}

.assigneeHeader {
  display: flex;
  justify-content: space-between !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  border-bottom: 1px solid#DEDEDF !important;
  padding-bottom: 9px;
}

.deleteBtn {
  color: #8c0d24 !important;
  cursor: pointer;
}

.addBtnWrapper {
  display: flex;
  justify-content: flex-end;
}

.addBtn {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #0e9354 !important;
  background: none !important;
  border: 1px solid #0e9354 !important;
}

.formContainer {
  margin-top: 40px !important;
}

.assigneeField {
  padding-top: 16px;
}

.assigneeAddBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.selectWorkersField {
  padding: 8px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #dededf;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.selectWorkersField :global(text) {
  width: 90% !important;
  height: 25px !important;
}

.comboContainer {
  padding-top: 16px;
}

.formField {
  width: 50%;
}

.detailsBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #f5f7f7 !important;
  margin-top: 32px;
  margin-bottom: 40px;
}

.detailsBar
  :global(.ui.fitted.toggle.checkbox)
  > input:checked
  ~ label::before {
  background-color: #0e9354 !important;
}

.checkBox {
  display: flex !important;
  margin-right: 16px !important;
}

.blockContainer {
  padding-top: 0px !important;
}

.blockContainer:nth-child(4) {
  margin-bottom: 15px;
}

.wrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
  padding: 0 !important;
}

.wrapperRow {
  width: 100% !important;
  height: 65px !important;
  padding-right: 22px !important;
  padding-left: 16px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.iconContainer {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.toggleIcon i {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  cursor: pointer;
}

.toggleIcon.openTable {
  cursor: pointer;
  padding: 5px;
}

.columnContainer {
  width: 90% !important;
  display: flex;
  justify-content: start !important;
  align-items: center;
}

.nameColumn {
  margin-right: 32px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  flex: 1;
}

.iconColumn {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #b00020;
  font-size: 14px !important;
}

.productHeader {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.formWrapper {
  width: 100% !important;
}

.editModalSegment {
  box-shadow: none !important;
}

.areaWrapper {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #dededf;
}

.claendarWrapper {
  position: relative;
  display: flex;
}

.startDate {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 15px;
}

.endDate {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 100px;
}

.placeholderStart {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 15px;
  color: #b3b3b3;
}

.placeholderEnd {
  text-align: center;
  position: absolute;
  width: 90px;
  top: 10px;
  left: 100px;
  color: #b3b3b3;
}

.dateRangePicker {
  width: 100%;
  height: 40px;
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.61857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  display: flex;
}

.claendarIconWrapper {
  position: relative;
  top: 10px;
  right: 33px;
}

.disabledField {
  background-color: #dededf !important;
}

.generalAttachmentsContainer {
  display: flex !important;
  flex-wrap: wrap;
}

.generalAttachmentsButton {
  margin-top: 5px !important;
  margin-left: auto !important;
}

.wtAddBtnWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 10px;
  padding-right: 14px;
}

.wtAddBtn {
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #0e9354 !important;
}
