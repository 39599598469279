.reportWrapper {
  padding: 25px;
}

.reportHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.reportHeader {
  font-size: 16pt;
  margin-bottom: 20px;
}

.reportSubHeader {
  font-size: 11pt;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.tableElement {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  margin-bottom: 50px !important;
}

.tableHeader {
  font-size: 12pt;
}

.tableElement tr td {
  border: none !important;
}

.tableElement thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.9) !important;
  font-size: 8pt !important;
}

.tableElement tbody tr td,
.reportCreatedRow {
  font-size: 7pt !important;
}

.tableElement tfoot tr th {
  font-size: 8pt !important;
  font-weight: bold !important;
}

.stripedRow {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
