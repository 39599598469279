.comment {
  width: 100%;
}

.root :global(.ui.segment) {
  border: 1px solid #dededf;
  box-shadow: none;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.rateInfoText {
  margin-bottom: 10px;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

:global(.ui.basic.green.button).actionButton {
  font-weight: 500;
  height: 28px;
  display: flex;
  align-items: center;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.currentRateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currentRateContainer span {
  font-weight: 500;
}

.medium {
  font-weight: 400;
}

.workersCount {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 12px;
  width: 100%;
}

.workersDropdown :global(.ui.dropdown) {
  background-color: transparent;
}

.workersDropdown a {
  display: none !important;
}

.workerElementsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.workerElement {
  background: #f5f7f7;
  margin: 5px;
  height: 24px;
  width: auto;
  text-align: center;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 1px 8px 0 12px;
}

.workerElement span {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.workerElement i {
  color: #666666;
  margin-left: 12px;
  cursor: pointer;
}

.errorMessage {
  margin-top: 6px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  white-space: normal;
  background: #ffffff !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
  padding: 0.5833em 0.833em;
  display: inline-block;
  font-family: "Roboto", sans-serif !important;
}
