.tabsAndFilters {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
}

.moreButtonsIcon {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  color: #666666;
}

.moreButtonsIcon span {
  margin-right: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #666666;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.moreButtonsIcon i {
  margin-bottom: 5px;
}

.filterDropDownIcons {
  display: inline-flex;
  align-items: center;
}

.filterDropDownIcons span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #666666;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.filterDropDownIcons i {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterButton {
  background-color: var(--color-background-cell);
  border-radius: 4px;
  padding: 6px 12px !important;
  font-weight: bold;
  color: #666666;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  justify-content: space-between;
}

.filterButton> :global(.text) {
  margin: 0 !important;
  padding-right: 14px !important;
}

.filterButton> :global(i.icon) {
  position: relative;
  margin-right: 0;
}

.filterButton:not(.selectedFilter):hover {
  background-color: #dededf;
}

.filterButton:global(.dropdown) :global(.menu)>.clearButton {
  cursor: pointer;
  text-transform: none;
  color: #b00020;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.filterButton.selectedFilter {
  background-color: #666666;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.filterButton .filterMenu {
  min-width: 100% !important;
}

.resetButton {
  color: #b3b3b3;
  cursor: pointer;
  font-family: Roboto, "sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  padding-left: 8px;
  border-left: 1px solid #b3b3b3;
}

.resetButton:hover {
  color: #666666;
}

.filterDropDown {
  height: 32px;
  width: 85px;
  background-color: var(--color-background-cell) !important;
  color: #666666;
  cursor: pointer;
  font-family: Roboto, "sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 !important;
}

.filterDropDown:hover {
  color: #666666;
}

:global(.ui.dropdown.dateRangePickerDropdown) {
  min-width: 120px;
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  background-color: var(--color-background-cell);
  color: #666666;
  height: 32px;
  cursor: pointer;
}

:global(.ui.multiple.search.dropdown > .text) {
  padding-top: 2px !important;
  cursor: pointer;
}

:global(.ui.dropdown.dateRangePickerDropdown):hover {
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  background-color: #dededf;
  color: #666666;
  height: 32px;
  cursor: pointer;
  min-width: 120px;
}

:global(.ui.dropdown.dateRangePickerDropdown) input {
  cursor: pointer !important;
}

:global(.ui.dropdown.activeDateRangePickerDropdown) {
  width: 160px !important;
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #666666;
  height: 32px;
  cursor: pointer;
}

:global(.ui.multiple.search.dropdown > .text) {
  width: 190px !important;
  padding-top: 2px !important;
  cursor: pointer;
}

:global(.ui.dropdown.activeDateRangePickerDropdown):hover {
  width: 160px !important;
  display: flex !important;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 0.28571429rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #666666;
  height: 32px;
  cursor: pointer;
}

:global(.ui.dropdown.activeDateRangePickerDropdown) input {
  cursor: pointer !important;
}

.dateFilterDropDownIconsWrapper {
  display: inline-flex;
  align-items: flex-start;
  padding-left: 14px;
}

.dateFilterDropDownIcons {
  padding-top: 7px;
}

:global(.ui.dropdown .menu).dateRangeSelectDropDown {
  min-height: 450px !important;
  min-width: 250px !important;
  background: none;
  border: none;
  box-shadow: none !important;
}

.filterHolder {
  background-color: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  cursor: default;
  width: 270px;
  font-weight: normal;
}

.datepickerWrapper {
  padding: 16px;
}

.secondDatepickerWrapper {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.filterDropDown {
  color: #666666;
  background-color: var(--color-background-cell) !important;
  height: 32px !important;
  border-radius: 4px;
}

.scrollingMenu {
  border: none !important;
}

.scrollingMenuWrapper {
  margin-top: 2px !important;
}