:global(.ui.dropdown).areasDropdown {
  padding-right: 5em;
}

:global(.ui.dropdown).areasDropdown :global(.item) span {
  display: inline-block;
  color: var(--color-black);
}

:global(.ui.dropdown).areasDropdown :global(.item) i {
  display: inline-block;
  margin-left: 10px;
  color: var(--color-middle);
}
:global(.ui.dropdown).areasDropdown :global(.item i.vacant) {
  color: var(--color-vacant-blue);
}
