.elementContainer {
  position: relative;
  display: inline-flex;
}

.tooltipBody {
  position: absolute;
  padding: 5px 12px;
  border-radius: 4px;
  background: black;
  color: white;
  text-align: center;
  min-width: 50px;
  top: 35px;
  right: 0;
  z-index: 50;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
}

.elementContainer .tooltipBody {
  display: none;
}

.elementContainer:hover .tooltipBody {
  display: flex;
}
