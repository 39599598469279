@import "../../../assets/css/variables.css";

.taskHolder {
  display: flex;
  background: var(--color-background-cell);
  height: inherit;
  flex-direction: column;
}

.taskBody {
  flex: 1 1 auto;
  overflow: auto;
  padding: 15px 25px 30px 25px;
  position: relative;
}

.taskBody :global(.ui.grid > .row),
.taskBody :global(.ui.grid > .row > .column) {
  position: unset;
}

.taskBody :global(.ui.checkbox) {
  transform: translateZ(0);
}

.showMoreHolder > :global(.ui.grid) {
  margin: 0;
}

.slideDown {
  margin-left: -1rem;
  margin-right: -1rem;
}

:global(.ui.button).moreToggle {
  display: block;
  width: 100%;
  background: none;
  position: relative;
  text-align: center;
  border-top: 1px dashed var(--color-border);
  margin-top: 15px;
}

.tabsHolder {
  margin-top: 30px;
}

.tabs {
  margin: 0 -15px;
}

.tabs > :global(.ui.secondary.menu) {
  background: #fff;
  margin-bottom: 0;
}

.tabs > :global(.ui.segment) {
  margin-top: 0;
}

.progressBar {
  max-width: 60px;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  background: #fff;
  margin-left: -15px;
  margin-right: -15px;
}

.tabItem {
  overflow: hidden;
  height: 0;
}

.tabItem.visibleTab {
  height: auto;
  padding-bottom: 15px;
}

.taskBody :global(.ui.menu) {
  box-shadow: none;
}

.taskBody :global(.ui.menu .item) {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-dark);
  padding: 10px;
  justify-content: center;
}

.taskBody :global(.ui.menu .item):before {
  display: none;
}

.taskBody :global(.ui.menu .active.item) {
  background: var(--color-background-label);
  color: #fff;
}

@media screen and (min-width: 768px) {
  .statusDropDown {
    max-width: 165px;
  }

  :global(.ui.secondary.pointing.menu).tabsMenu {
    margin-left: -25px;
    margin-right: -25px;
  }

  :global(.ui.secondary.pointing.menu).tabsMenu :global(.item) {
    padding-left: 25px;
    padding-right: 25px;
  }

  :global(.ui.secondary.pointing.menu).tabsMenu :global(.active.item) {
    border-color: var(--color-background-green);
  }
}

.closeDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-out;
}

.openDiv {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.7s ease-in;
}

.showMoreHolder {
  padding: 20px;
  background: #f6f7f8 !important;
  margin-top: 10px;
}
