@import "../../../assets/css/variables.css";

.inputHolder {
  background-color: white;
  position: relative;
  display: block;
}

.calendarHolder {
  background: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--color-border);
  width: 250px;
}

.calendarHolder.right {
  left: auto;
  right: 0;
}

.calendarHolder :global(.react-calendar) {
  border: none;
}

.calendarHolder :global(.react-calendar .react-calendar__tile--active) {
  background-color: var(--color-background-green);
}

.buttonHolder {
  text-align: center;
  padding: 15px;
  border-top: 1px solid var(--color-border);
}

.buttonHolder button:hover {
  color: var(--color-error-delete) !important;
}

.datePickerInput {
  height: 40px !important;
}

.datePickerInput input {
  font-size: 12px;
}
