.backIconWrapper {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}

.loader {
  margin-top: 30px;
}

.payrollsTable {
  margin-top: 20px;
}

.payrollsTable thead tr th {
  background-color: transparent !important;
  border-left: none !important;
}

.payrollsTable thead tr th:first-child,
.payrollsTable thead tr th:nth-child(2) {
  padding-left: 0 !important;
}

.payrollsTable tbody tr td:nth-child(2) {
  padding-left: 0 !important;
}

.payrollsTable tfoot tr td {
  border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
  font-weight: bold;
}

.payrollsTable tfoot tr td:first-child {
  padding-left: 12px !important;
}
