.deletedMessage .undoButton {
  color: var(--color-green);
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.deletedMessage {
  position: fixed;
  left: 0;
  top: 80px;
  padding: 0 1rem;
  width: 100%;
  box-shadow: none;
}

.deletedMessageIn {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border: transparent;
  box-shadow: none;
}

.deletedMessage .closeButton {
  color: #666666;
}

.deletedMessage .closeButton:hover {
  cursor: pointer;
  color: #000000;
}
