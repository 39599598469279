@import '../../../assets/css/variables.css';

:global(.ui.segment).mainHolder {
  padding: 0;
}

:global(.ui.header).header {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

.headerTasks {
}

.headerTasks :global(.ui.grid > .row) {
  padding: 0;
}

.headerTasks :global(.ui.grid > .row > .column):first-child {
  padding-left: 0;
}

.headerTasks :global(.ui.grid > .row > .column):last-child {
  padding-right: 0;
}

.headerTasks :global(.ui.grid > .row > .column:last-child .ui.button:last-child) {
  margin-right: 0;
}

.dropdownHeaderMenu {
}

:global(.ui.dropdown).dropdownHeaderMenu {
  padding-left: 0;
}

:global(.ui.scrolling.dropdown).dropdownHeaderMenu :global(.menu) {
  max-height: 350px;
}

:global(.ui.dropdown).dropdownHeaderMenu > :global(.text) {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.farmsHolder :global(.pusher) {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.farmsHolder :global(.ui.right.overlay.sidebar) {
  background: var(--color-background);
  width: 100%;
  padding: 15px;
  overflow: auto;
  box-shadow: var(--box-shadow);
}

@media screen and (min-width: 768px) {
  .farmsHolder :global(.ui.sidebar) {
    width: 70%;
    max-width: 570px;
    padding: 30px;
  }
}
