@import '../../../../assets/css/variables.css';

.wizardHolder {
  flex: 1 1 auto;
  overflow: auto;
  padding: 30px 25px;
  position: relative;
}

.wizardHolderIn {
  transform: translateZ(0);
}

.fieldsHolder {
  margin-top: 30px;
}
.fieldsHolder :global(.ui.menu .item) {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-dark);
}

.fieldsHolder :global(.ui.menu .active.item) {
  background: var(--color-background-label);
  color: #fff;
}

.fieldsHolder :global(.ui.menu .active.item):hover {
  background: var(--color-dark);
  color: #fff;
}

.buttonsHolder {
  margin-top: 30px;
}

.batchNo {
  text-align: center;
  margin-top: -12px;
}

:global(.ui.compact.menu).groupMenu {
  display: flex;
  flex-wrap: wrap;
}

.title {
  font-size: 16px;
}

:global(.ui.segment).stepsHolder {
  margin-top: 25px;
}

:global(.ui.segment).stepsHolder {
  margin-top: 25px;
  position: relative;
}

:global(.ui.segment).stepsHolder:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 4px;
  background: var(--color-background-green-light);
}

.stepName {
  font-size: 16px;
  font-weight: bold;
}

:global(.ui.segment).stepsHolder.step40:before {
  width: 25%;
}

:global(.ui.segment).stepsHolder.step41:before {
  width: 50%;
}

:global(.ui.segment).stepsHolder.step43:before {
  width: 75%;
}

:global(.ui.segment).stepsHolder.step44:before {
  width: 100%;
}

:global(.ui.segment).stepsHolder.step50:before {
  width: 20%;
}

:global(.ui.segment).stepsHolder.step51:before {
  width: 40%;
}

:global(.ui.segment).stepsHolder.step52:before {
  width: 60%;
}

:global(.ui.segment).stepsHolder.step53:before {
  width: 80%;
}

:global(.ui.segment).stepsHolder.step54:before {
  width: 100%;
}

.rowIcon {
  color: var(--color-icon);
  font-size: 21px;
  text-align: center;
  margin-top: 10px;
}

.doneStep {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
}

.doneStep > div + div {
  margin-top: 20px;
}

.doneStep :global(.ui.button) {
  margin-top: 25px;
}

.doneBar {
  transform: scale(1.3);
}
