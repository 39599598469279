:global(.ui.segment).segmentHolder {
  padding-top: 20px;
  padding-bottom: 20px;
}

.headerHolder {
  display: flex;
}

.chevron {
  width: 20px;
  height: 20px;
  font-size: 21px;
  display: inline-block;
  color: var(--color-icon);
  margin: 3px 20px 0 0;
  cursor: pointer;
}

.chevron:hover {
  color: var(--color-green);
}

:global(.ui.header).headerTitle {
  margin: 0;
  line-height: 1;
}

.childrenHolder {
  height: 0;
  overflow: hidden;
}

.childrenHolder.open {
  margin-top: 1rem;
  height: auto;
  overflow: visible;
}

.childrenHolder > :global(.ui.grid:first-child) {
  margin-top: -0.5rem !important;
}

.childrenHolder :global(.ui.grid + .ui.grid) {
  margin-top: 0;
}

/* ALTER */

:global(.ui.segment).segmentHolder.alter {
  background-color: #edf0f1 !important;
}

.alter :global(.ui.header).headerTitle {
  padding-top: 7px;
}

.alter .headerHolder {
  justify-content: space-between;
  flex-direction: row-reverse;
}
