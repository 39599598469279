:global(.ui.button).addButton {
  background-color: var(--color-green) !important;
  margin-left: 10px;
  height: 36px;
  width: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

:global(.ui.button).addButton:hover {
  background-color: #156941 !important;
}

.assignWorkersButton:global(.ui.basic.green.button) {
  font-weight: 500 !important;
}

.assignWorkersButton:global(.ui.basic.green.button):hover {
  background-color: #156941 !important;
  color: #ffffff !important;
  border: none;
}

:global(.ui.basic.green.button).cancelButton {
  color: var(--color-green) !important;
  margin-right: 10px;
}

:global(.ui.basic.green.button).actionButton {
  height: 32px;
  display: flex;
  align-items: baseline;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}

.avatarsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avatarsContainer span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.avatarHolder {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.avatarsContainer .avatarHolder:nth-child(n + 2) {
  margin-left: -10px;
}

.morePopupContent {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.popupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.popupItem .avatarHolder {
  height: 20px;
  min-width: 20px;
}

.popupItem:nth-child(1) {
  margin-top: 0;
}

.popupItem span {
  margin-left: 10px;
}

.actionCells {
  overflow: initial !important;
}
