@import "../../../../assets/css/variables.css";

.label {
  color: var(--color-dark);
}
.batchNumberLabel {
  height: 100% !important;
  /* padding-top: 14px !important; */
  color: var(--color-dark);
}
.sideBarBatchNumberLabel {
  height: 100% !important;
  color: var(--color-dark);
}

:global(.ui.grid > .row).taskRow {
  padding-bottom: 0;
}

:global(.column).componentHolder :global(.ui.compact.menu) {
  transform: translateZ(0);
}

.requiredField {
  color: var(--color-pink);
}

:global(.ui.grid).fieldGrid {
  margin: 0;
}

:global(.ui.grid).fieldGrid > :global(.row) > div {
  padding: 0;
}

@media screen and (max-width: 767px) {
  :global(.ui.grid).fieldGrid > :global(.row) {
    flex-wrap: nowrap;
  }
  :global(.ui.grid).fieldGrid > :global(.row > [class*="one wide"].column) {
    width: auto !important;
    flex: auto;
  }

  :global(.ui.grid > .row).taskRow.wideRow > div + div {
    margin-top: 0;
  }
}
.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}
.textButtonContainer {
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  display: flex !important;
  justify-content: end;
}
.textButton:global(.ui.button):hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.inputWrapper {
  display: flex;
}

.inputButtonWrapper {
  text-align: end;
  margin-bottom: 10px !important;
}

.batchNumberInput {
  width: 88%;
  margin-right: 10px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  height: 47px;
}
.batchNumberInput input {
  border: none !important;
}

.ingredientSection {
  padding: 0 !important;
  width: 97%;
  display: flex;
  justify-content: space-between;
}

.removeActiveIngredient {
  margin-top: 15px !important;
  color: #b00020;
  margin-right: 13px !important;
}
.test {
  height: 100%;
}
