.layout :global(.page) {
}

.layout :global(.pageBreak) {
  margin: 1.5mm;
  width: 40mm;
  height: 28mm;
  padding: 0;
}

.layout :global(.barcodeHolder) {
  width: 40mm;
  height: 28mm;
}

.layout :global(.barcodeDataHolder) {
  width: 26mm;
  height: 38mm;
  margin-top: 1px;
  transform: translate(27%, -14%) rotate(-90deg);
  left: unset;
  top: unset;
}

.layout :global(.barcode) {
  height: 50%;
  width: 65%;
}

.layout :global(.textHolder) {
  margin-top: 1px;
  line-height: 4px;
  font-size: 6px;
}

.layout :global(.text) {
  padding: 0.05rem;
  font-size: 13px;
  margin-top: 8px;
  font-weight: bold;
}
