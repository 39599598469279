.headerWrapper {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.header {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
}

.icon {
  cursor: pointer;
  color: #666666 !important;
}

.content {
  padding: 16px;
}

.fixedContentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f7f8;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
}

.fixedContentRowId {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.fixedContentRowType {
  padding-left: 24px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #666666 !important;
}

.fixedContentRowTotalWt {
  padding-left: 24px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #666666 !important;
}

.formLabel {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.calendarWrapper {
  display: flex;
  align-items: center;
}

.expirCalendar {
  padding-left: 15px;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}

.expirCalendar input {
  border: none !important;
}

.calendarIcon {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.fixedContentForm {
  padding-top: 32px;
}

.dropdownCustomStyle {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.dropdownCustomStyle :global(.menu) {
  max-height: 250px;
  overflow-y: auto !important;
}

.dropdownCustomStyle :global(.menu) > * {
  background-color: white !important;
  display: flex !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.dropdownCustomStyle :global(.menu) > .item :hover {
  background-color: rgb(237, 236, 236) !important;
}

.dropdownCustomStyle :global(.label) {
  display: none !important;
}

.dropdownCustomStyle > :global(.label ~ .text) {
  color: #ce0e0e;
  display: contents !important;
}

.dropdownCustomStyle div {
  color: #212121 !important;
}

.modalContentContainer {
  min-height: 630px !important;
}

.actionButtons {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.card {
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  margin: 16px !important;
  padding: 0 !important;
}

.employeeRow {
  display: flex;
}

.checkIconWrapper {
  width: 50px;
  height: 50px !important;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  color: white;
  font-size: 12px;
  font-family: Roboto;
}

.checkIcon {
  width: 25px;
  height: 25px !important;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkIcon i {
  color: #59b655 !important;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  padding-left: 16px;
  gap: 16px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.form {
  padding: 8px 16px;
}

.areasRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.areasRowHead {
  font-family: "Roboto" !important;
  width: 50px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.addedItemValue {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.disabledField {
  background-color: #dededf !important;
}

.rowChevronIcon {
  cursor: pointer;
  color: #666666;
}

.errorMessage {
  color: #b00020;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
