@import '../../../../../../../assets/css/variables.css';

.sprayGrid {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.sprayFormContainer {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

:global(.ui.table).barcodesTable {
  width: 100%;
  display: block;
}

:global(.ui.table).barcodesTable thead,
:global(.ui.table).barcodesTable tbody {
  display: block;
}

:global(.ui.table).barcodesTable thead th {
  font-weight: normal;
  color: var(--color-dark);
  white-space: nowrap;
  background: none !important;
}

:global(.ui.table).barcodesTable tbody:nth-child(2) {
  width: 100%;
  max-height: 172px;
  overflow: auto;
}

:global(.ui.table).barcodesTable thead tr,
:global(.ui.table).barcodesTable tbody tr {
  display: flex;
}

:global(.ui.table).barcodesTable thead tr th:first-child,
:global(.ui.table).barcodesTable tbody tr td:first-child {
  flex: 1 1 auto;
}

:global(.ui.table).barcodesTable tbody:nth-child(2) tr td div {
  float: left;
}

:global(.ui.table).barcodesTable tbody:nth-child(2) tr td:last-child {
  text-align: right;
  padding-right: 8px !important;
}

:global(.ui.table).barcodesTable tbody:last-child tr:last-child td {
  display: block;
}

.barcodesRow td {
  background: var(--color-error) !important;
}

.newBarcode {
  width: 100%;
}
:global(.ui.basic.table) tbody:nth-child(3) tr {
  border: none;
}

@media only screen and (max-width: 767px) {
}
