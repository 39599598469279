.container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.tabContainer {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tabContainerRow {
  display: flex;
  padding-top: 25px;
  gap: 10px;
  width: 100% !important;
  flex-wrap: nowrap;
  height: 100%;
}

.cardContainerRow {
  width: 100%;
  display: flex;
  padding-top: 25px;
  gap: 10px;
  flex-wrap: nowrap;
  height: calc(100% - 50px);
}

.columnContainer {
  height: 100%;
  width: 33%;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column;
  display: inline-block;
}

.columnContent {
  width: 98%;
  height: 100%;
  padding: 0 !important;
  padding-left: 5px !important;
}

.menuItem {
  height: 40px;
  width: 33% !important;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 4px 20px 8px 20px;
  border-bottom: 1px solid #dededf;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #666666;
  width: 315px;
}

.card {
  width: 100%;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.precentSection {
  padding-top: 3px;
  height: 18px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}

.taskNameSection {
  padding-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
}

.methodSection {
}

.method {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.taskId {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.headerSection {
  display: inline-block;
  height: 25px !important;
  width: 80px !important;
  padding-left: 16px;
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
}

.valueSection {
  height: 25px !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
}

.employeeSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 31px;
}

.iconSectiom {
  display: flex;
}

.dropdownItem:hover {
  width: 100% !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.iconWrapper {
  display: flex;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.lineCheckbox {
  position: unset !important;
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
}

.lineCheckbox label::after {
  top: 5px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.lineCheckbox label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.checkMarkIcon {
  cursor: pointer;
  color: #0e9354;
  font-size: 24px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.lineAllCheckbox {
  display: block !important;
  width: 24px !important;
  height: 24px !important;
  margin-right: 12px;
}

.lineAllCheckbox label::after {
  top: 5px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.lineAllCheckbox label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.lineCheckboxMinus {
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid #0e9354;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-right: 12px;
}

.minusIcon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0e9354 !important;
}

.minusIcon::before {
  font-size: 15px !important;
}

.emptyTableWrapper {
  width: 100%;
  height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.emptyTableText {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.taskDeleteIcon {
  cursor: pointer;
  margin-right: 8px !important;
  color: #b00020 !important;
}

.selectionDropdown > :global(.text) {
  display: none !important;
}

.tasksIconWrapper {
  display: flex;
  align-items: center;
}

.archiveIcon {
  cursor: pointer;
  color: #0e9354 !important;
  margin-right: 8px !important;
}

:global(.ui.popup).expiredPopup {
  background-color: black;
  color: white;
}

.infoButton {
  margin-left: 8.38px !important;
  color: #b00020;
  margin-bottom: 5px !important;
}

:global(i.icon) {
  margin: 0;
}

.dropdownStyle {
  gap: 8px !important;
  width: 100% !important;
  display: flex !important;
  max-width: 130px;
  justify-content: space-between !important;
}
