.expand {
  color: var(--color-green);
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
}

:not(:global(.expanded)).value {
  -webkit-line-clamp: 1 !important;
  min-height: unset !important;
}
