.sprayGrid {
  display: flex;
  margin: 0;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.sprayFormContainer {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.sprayFormFooter {
  padding: 15px;
  border-top: 1px solid var(--color-background-secondary);
}

.sprayFormContainer {
  overflow: auto;
  height: 100%;
  padding: 30px 15px;
}

.sprayFormContainer > div {
  max-width: 900px;
  margin: 0 auto;
}

.inputHidden {
  position: absolute;
}

.label {
  color: var(--color-dark);
}

@media only screen and (max-width: 767px) {
  .sprayFormContainer :global(.ui.grid > .row > .column + .column) {
    margin-top: 0;
  }
}

.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 4px 0px 4px 0px;
}
.root :global(.ui.form .field) > label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
:global(.ui.modal).confirmModal {
  margin: 30%;
}
.root :global(.ui.grid) {
  margin: 0;
}
.root :global(.content > :not(:first-child)) {
  margin-top: 16px !important;
}

.modalContentContainer {
  min-height: 70vh;
  scrollbar-width: none;
}

.modalContentContainer::-webkit-scrollbar {
  display: none;
}

.modalHeader {
  display: flex !important;
  justify-content: space-between !important;
}

.closeIcon {
  cursor: pointer;
}
