.page {
  margin: 0;
  line-height: normal;
}

.block {
  box-sizing: border-box;
  justifycontent: space-around;
  display: grid;
}

.block * {
  box-sizing: inherit;
}

.labelHolder {
}

.barcodeDataHolder {
  text-align: center;
}

.barcode {
  height: 50mm;
  width: 50mm;
}

.textHolder {
  text-align: left;
}

.text {
  margin-top: 3px;
  font-size: 20px;
}

.dateText {
  margin-top: 3px;
  font-size: 25px;
}

.packingShed {
  font-size: 25px;
  font-weight: bold;
}

@media screen {
  .labelHolder {
    outline: 1px dotted black;
  }
}

@media print {
  .pageBreak {
    display: block;
    page-break-before: always;
  }
}
