.addButton {
  background: none !important;
  padding-left: 0 !important;
  width: 100% !important;
  display: flex !important;
}

.dropdown {
  display: flex !important;
  align-items: center !important;
}
