@import "../../assets/css/variables.css";

:global(.ui.form .field > label > div + .ui).buttonsGroup {
  margin-top: 0;
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button) {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  justify-content: center;
  border: none;
  position: relative;
}

:global(.ui.basic.buttons).buttonsGroup
  :not(div)
  + :global(.ui.button:not(:first-child):before),
:global(.ui.basic.buttons).buttonsGroup
  :global(.ui.button:not(:nth-child(-n + 2)):before) {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px;
  width: 1px;
  background: var(--color-light-grey);
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button).buttonActive {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  color: var(--color-green) !important;
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button).buttonActive:before,
:global(.ui.basic.buttons).buttonsGroup
  :global(.ui.button).buttonActive
  + :global(.ui.button):before {
  display: none;
}

:global(.ui.basic.buttons).buttonsGroup div :global(.ui.pointing.label) {
  width: max-content;
}
