.block {
  box-sizing: border-box;
  justify-content: space-around;
  display: grid;
}

.block * {
  box-sizing: inherit;
}

.pageBreak {
  height: 450mm;
  width: 320mm;
  border-top: 1mm solid black;
  border-bottom: 1mm solid black;
  border-left: 8mm solid black;
  border-right: 8mm solid black;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  page-break-before: always;
}

.barcodeHolder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.barcode {
  height: 15mm;
  width: 15mm;
}

.textHolder {
  margin-top: 3px;
  line-height: 8px;
  font-size: 8px;
}

.text {
  padding: 1px;
}

@media screen {
  .pageBreak {
    outline: 1px dotted black;
  }

  .barcodeHolder {
    outline: 1px dotted black;
  }
}

@media print {
  .pageBreak:not(:last-child) {
    page-break-after: always;
  }

  .pageBreak:last-child {
    page-break-after: avoid;
  }
}
.qrCodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.dasheswrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 40px;
  padding: 0 20px 0 20px;
}
.dasheswrapperBottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 40px;
  padding: 0px 20px;
  height: 35px;
  position: absolute;
  top: 90%;
  width: 100%;
}
.varietySection {
  display: flex;
  width: 100%;
  align-items: baseline;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 11px;
  padding-left: 40px;
  margin-top: 10px;
}
.blockSection {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 11px;
  padding-left: 40px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.pickerSection {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 11px;
  padding-left: 40px;
  margin-top: 20px;
}
.valueVarietyField {
  margin-left: 3px;
  width: 60.7%;
  border-bottom: 1px solid black;
  font-size: 18px;
  font-weight: 1000;
}
.valueBlockField {
  margin-left: 3px;
  width: 65%;
  border-bottom: 1px solid black;
}
.valuePickerField {
  margin-left: 3px;
  width: 63.1%;
  border-bottom: 1px solid black;
}
.datePropertyStampWrapper {
  display: flex;
}
.datePropertySection {
  font-family: sans-serif;
  padding-left: 6px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.stampSection {
  width: 50mm;
  height: 30mm;
  border: 0.25mm solid;
}

.dateWrapper {
  font-size: 8px;
  font-weight: 800;
  letter-spacing: -0.9px;
}
.propertyWrapper {
  padding-top: 25px;
  font-size: 8px;
  font-weight: 800;
  letter-spacing: -0.9px;
}
.propertyValue {
  text-align: left;
  width: 65px;
  line-height: 12px;
}
.squarLogoWrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  width: 100%;
}
.logoContainer {
  width: 100%;
  height: 40px;
}

.logo {
  width: 105px;
  height: 50px;
}
.squar {
  width: 35px;
  height: 25px;
  border: 0.25mm solid;
  margin-left: 2px;
}
