.addButton {
  width: 125px !important;
  height: 36px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background-color: #0e9354 !important;
}

.addButton:hover {
  background-color: #156941 !important;
}

.addButton:active {
  background-color: #06331e !important;
}

.notesWrapper {
  width: 160px !important;
  padding: 0px !important;
  white-space: nowrap !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.scrollableTableWidth:global(.ui.table) {
  width: 100%;
}

.modalWrapper {
  padding: 0px;
}

.commentContainer {
  padding: 10px !important;
  position: absolute;
  width: 200px;
  left: -160px;
  top: -30px;
  background: #212121;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  word-break: break-all !important;
}
.iconWrapper {
  display: flex;
}

.deleteButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
  margin-right: 20px;
}

.deleteButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: rgba(176, 0, 32, 1) !important;
  position: relative;
}

.deleteButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).deleteButton:hover {
  color: rgb(148, 7, 33) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

:global(.ui.button).printButtonForPage {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).printButtonForPage:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).printButtonForPage:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).printButtonForPage:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.iconWrapperForPage {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
