@import '../../../assets/css/variables.css';
.headerTasks {
}

.headerTasks :global(.ui.grid > .row) {
  padding: 0;
}

.headerTasks :global(.ui.grid > .row > .column):first-child {
  padding-left: 0;
}

.headerTasks :global(.ui.grid > .row > .column):last-child {
  padding-right: 0;
}

.headerTasks :global(.ui.grid > .row > .column:last-child .ui.button:last-child) {
  margin-right: 0;
}

.dropdownHeaderMenu {
}

:global(.ui.dropdown).dropdownHeaderMenu {
  padding-left: 0;
}

:global(.ui.scrolling.dropdown).dropdownHeaderMenu :global(.menu) {
  max-height: 350px;
}

:global(.ui.dropdown).dropdownHeaderMenu > :global(.text) {
  font-size: 18px;
  font-weight: 600;
  margin-left: 0;
  font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.verticalMiddle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.verticalMiddle:global(.center) {
  justify-content: center;
}

.verticalMiddle:global(.right) {
  justify-content: flex-end;
}

:global(.ui.header).taskName {
  padding: 0.14em 0;
  margin: 0;
  line-height: 1.21428571em;
}

:global(.ui.basic.segment).tasksCountHolder {
  border: 1px solid var(--color-border-blue);
  background: var(--color-background-blue);
  padding: 10px 15px;
  border-radius: 4px;
  display: none;
}

.tasksCount {
}

.boxCount {
  display: flex;
  flex-direction: row;
}

.whiteHolder {
  background: #fff;
  width: 65px;
  height: 45px;
  border-radius: 4px;
  margin-right: 15px;
}

.tasksDigits {
  color: var(--color-title);
  font-size: 20px;
  line-height: 25px;
}

:global(.ui.selection.dropdown).selectShort {
  margin-left: 10px;
  width: 60px;
  min-width: 60px;
}

.sortHolder {
}

:global(.ui.grid).tasksFilters i {
  font-size: 18px;
}

:global(.ui.dropdown).sortHolder {
  white-space: nowrap;
}

:global(.ui.dropdown).sortHolder {
}

:global(.ui.dropdown).sortHolder i {
  position: absolute;
  left: 0;
  top: 0;
}

:global(.ui.dropdown).sortHolder :global(.text) {
  padding-left: 30px;
}

.sortList {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 100px;
}

.sortList li {
  padding: 5px 15px;
}
.tasksFilters :global(.ui.icon.button) {
  height: 35px;
  margin-left: 10px;
}

:global(.ui.grid).tasksFilters :global([class*='right floated'].column) {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  :global(.ui.basic.segment).tasksCountHolder {
    display: block;
  }

  :global(.ui.grid).tasksFilters :global([class*='right floated'].column) {
    margin-left: auto;
  }
}
