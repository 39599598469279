.overForm {
  height: 100%;
}

.inputHidden {
  height: 0;
}

.accordionHolder {
  max-width: 900px;
  margin: 0 auto;
}

.buttonsFooter {
  text-align: right;
  padding: 5px 15px;
}

.warning {
  margin-top: 3rem;
  color: var(--color-pink);
}

.confirmText {
  padding: 2rem;
}
.confirmText > div + div {
  margin-top: 1rem;
}

.confirmText .name {
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .buttonsFooter {
    padding: 0;
  }
  .buttonsFooter :global(.ui.button) {
    margin: 0;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
