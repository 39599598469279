.sprayField label .title {
  padding: 5px 5px 5px 0;
  font-weight: bold;
  color: #aaaaaa;
}

.required.sprayField label .title::after {
  color: red;
  content: '*';
  margin-left: 5px;
}

.showDeleteButton {
  position: relative;
  margin-right: 20px;
}

.errorMessage {
  margin: 0 auto 10px;
  padding: 0 1rem;
  width: 100%;
}

.deleteButton {
  position: absolute;
  right: -17px;
  top: 7px;
}

:global(.ui.grid) .plusIcon {
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

:global(.ui.icon.button).plusIcon {
  color: green;
}

:global(.ui.grid .row .column).spraySection.timeRange {
  padding-right: 0;
}

.totalWorkingTime {
  font-weight: bold;
}

.rangePicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rangePicker > div {
  flex: 50%;
}

.rangePicker > div.range {
  flex: 5px 0;
}

.sprayGrid .sprayField .rangePicker .timePicker.from {
  flex: 10;
}

.sprayGrid .sprayField .rangePicker .timePicker.to {
  flex: 12;
}

:global(.ui.icon.input).to > input {
  padding-right: 26px !important;
}

.total input::-webkit-outer-spin-button,
.total input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='time']::-webkit-clear-button {
  display: none;
}

.total input {
  -moz-appearance: textfield; /* Firefox */
}

:global(.ui.grid > .row) .dateRow {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 340px) {
  :global(.ui.icon.input).total > input {
    padding-right: 0!important;
  }
}
