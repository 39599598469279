.filterBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

:global(.ui.button).exportButton > :global(i.icon) {
  position: relative;
  top: -1px;
  color: var(--color-green);
}
