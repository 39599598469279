.searchInput input {
  display: block !important;
  border: none !important;
  width: 200px !important;
  padding: 0 !important;
  padding-left: 0px !important;
}

:global(.ui.dropdown .menu > .item:hover) {
  background-color: #ffffff !important;
}

.areaSelector input {
  display: none !important;
}

.areaSelector {
  padding-bottom: 12px !important;
}

:global(.ui.multiple.dropdown .dropdown.icon) {
  padding-left: 60px !important;
}

.dropDownMenuSearch {
  padding: 0px !important;
}

.searchInput {
  display: block !important;
  margin: 0px !important;
  padding: 5px;
  box-shadow: 0px -1px 3px 0px #dededf !important;
  border-top: 1px solid #dededf;
}

:global(.ui.dropdown .menu >).searchInput input {
  display: block !important;
  margin: 0px !important;
  padding: 5px !important;
  padding-left: 3px !important;
  border-bottom: none !important;
  border-top: 1px solid #dededf;
}

.areaSelector {
  padding-right: 10px !important;
  background: #ffffff !important;
  border-radius: 3px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px !important;
  border: none !important;
  box-shadow: none !important;
}

/* :global(.ui.selection.active.dropdown .menu) {
  width: 250px !important;
  border-color: var(--color-background-green-light) !important;
  box-shadow: 0px 1px 2px 2px rgb(34 36 38 / 20%) !important;
  border: none !important;
} */

.ui.selection.dropdown .menu>.item:hover {
  background-color: #ffffff !important;
}

.areaSelectorMenu {
  max-height: inherit !important;
  overflow: hidden !important;
  height: auto !important;
  border-color: #dededf !important;
  border: 1px solid #dededf !important;
}

.dropDownMenuSelectAllItem {
  box-shadow: 1px solid #dededf !important;
}

.areaSelectorMenu :hover {
  /* border-bottom: 1px solid #dededf !important; */
}

.areaDropDownScrollingMenu {
  padding: 5px !important;
}

.addItem {
  max-width: 300px;
  height: 29px !important;
  background: #fdfdfd !important;
  border: none !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #dededf !important;
  border-radius: 24px !important;
  font-size: 14px !important;
  min-height: 30px;
  vertical-align: middle !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
  padding-top: 2px !important;
  padding-left: 8px !important;
  padding-right: 11px !important;
}

.addItem i {
  color: #b00020 !important;
  cursor: pointer !important;
  padding-left: 20px !important;
}

.labelContainer {
  max-width: 320px;
  white-space: initial !important;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  margin: 0px !important;
  padding-left: 10px !important;
  border-bottom: none !important;
}

.addButtonWrapper {
  padding: 0px !important;
}

.addButton {
  display: flex !important;
  font-weight: 400px !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
  font-size: 14px !important;
}

.addButton:hover {
  background-color: #ebf6e9 !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0% !important;
  padding: 11px 16px !important;
}

.addButtonBatchNumber {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600px !important;
}

.itemWrapper {
  display: flex !important;
  align-items: center;
}

.childrenWrapper {
  padding-left: 35px;
  display: flex;
}

.childrenWrapperWithArrow {
  padding-left: 19px;
  display: flex;
  align-items: center;
}

.subChildrenWrapper {
  padding-left: 55px;
}

.farmItem {
  padding: 2px !important;
}

.blockItem {
  padding: 2px !important;
}

.allNoneButton {
  background: none !important;
  width: auto !important;
  color: green !important;
  padding: 5px !important;
}

.areasLabelDropdownTree {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #212121 !important;
}