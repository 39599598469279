@import "../../assets/css/variables.css";

:global(.ui.table).tableFarm {
  border: none;
  border-radius: 5px;
  color: var(--color-text);
}
:global(.ui.table).tableFarm.tableOpen {
}

.tableFarmHolder + .tableFarmHolder {
  margin-top: 20px;
}

:global(.ui.table).tableFarm tbody:nth-child(2n) {
  background: var(--color-background-cell);
}

:global(.ui.table).tableFarm thead tr th {
  background: #fff;
  border: none;
  font-weight: normal;
  color: var(--color-table-header);
  height: 48px;
}

:global(.ui.table).tableFarm thead tr:first-child > th:first-child,
:global(.ui.table).tableFarm tbody tr > td:first-child {
  position: relative;
  width: 50px;
}
:global(.ui.table).tableFarm thead tr:first-child > th:last-child,
:global(.ui.table).tableFarm tbody tr > td:last-child {
  white-space: nowrap;
}

:global(.ui.table).tableFarm
  :global(thead tr:first-child > th:last-child i.icon),
:global(.ui.table).tableFarm :global(tbody tr > td:last-child i.icon) {
  font-size: 18px;
  cursor: pointer;
  color: var(--color-icon);
  vertical-align: middle;
  margin: 0;
}

:global(.ui.table).tableFarm
  :global(thead tr:first-child > th:last-child i.icon.tuf-pen),
:global(.ui.table).tableFarm :global(tbody tr > td:last-child i.icon.tuf-pen) {
  color: var(--color-green);
}

:global(.ui.table).tableFarm
  :global(thead tr:first-child > th:last-child > i.icon:not(:last-child)),
:global(.ui.table).tableFarm
  :global(tbody tr > td:last-child > i.icon:not(:last-child)) {
  margin-right: 20px;
}
:global(.ui.table).tableFarm :global(tbody tr > td:last-child i.icon.blank) {
  cursor: default;
}

:global(.ui.table).tableFarm
  :global(tbody tr > td:last-child i.icon.tuf-archive),
:global(.ui.table).tableFarm
  :global(tbody tr > td:last-child i.icon.tuf-unarchive) {
  margin-top: 3px;
}

.toggleIcon {
  width: 20px;
  height: 20px;
  font-size: 21px;
  display: inline-block;
  color: var(--color-icon);
  cursor: pointer;
}
.toggleIcon.openTable {
  cursor: default;
}

.toggleIcon:not(.openTable):hover {
  color: var(--color-green);
}

.archive {
  background: url("../../assets/img/symbol.svg") no-repeat left;
}

.verticalMiddle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.verticalMiddle img {
  margin-right: 15px;
}

.verticalMiddle:global(.center) {
  justify-content: center;
}

.verticalMiddle:global(.right) {
  justify-content: flex-end;
}

.totalBlocks {
  font-weight: normal;
  color: var(--color-title);
  font-size: 12px;
}
.totalBlocks span {
  display: inline-block;
  color: var(--color-text);
}
.totalBlocks span:not(:last-child) {
  margin-right: 10px;
}

.tableFarm :global(.ui.header) {
  margin: 0;
}

.smallText {
  color: var(--color-text);
  font-weight: normal;
  font-size: 12px;
}

.timer {
  color: var(--color-pink);
}
.timer :global(i.icon),
.clock :global(i.icon) {
  font-size: 18px;
}

:global(.ui.button.button-text).buttonMore:hover {
  background: none;
}

:global(.ui.button.button-text).buttonMore :global(.icon.ellipsis) {
  font-size: 18px;
}

:global(.ui.header).farmName {
  cursor: pointer;
}
:global(.ui.header).farmName:hover {
  color: var(--color-green);
}

:global(.ui.header).farmNameSelected {
}

.tableFarm .loader {
  margin: 0;
  position: absolute;
  left: 15px;
  top: 17px;
  width: 18px;
  height: 18px;
  border-width: 3px;
}

.tableFarm tbody:not(:last-child) tr.rowArea:not(.rowBlock):last-child td {
}

:global(.ui.table tr).rowArea:not(.rowBlock) td:first-child {
  border-top: none;
}

:global(.ui.table tr).rowArea:not(.rowBlock) td:nth-child(2) {
  padding-left: 35px;
  position: relative;
}

:global(.ui.table tr).rowArea:not(.rowBlock) td:nth-child(2) .blockName {
  font-weight: normal;
}

:global(.ui.table tr).rowArea:not(.rowBlock) td:nth-child(2):before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  content: "";
  width: 1px;
  background: var(--color-border);
}

:global(.ui.table tr).rowArea:not(.rowBlock) td:nth-child(2):after {
  position: absolute;
  top: -1px;
  left: 0;
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
}

:global(.ui.table tbody:nth-child(2n) tr).rowArea:not(.rowBlock)
  td:nth-child(2):after {
  background: var(--color-background-cell);
}

:global(.ui.table tr).rowArea:not(.rowBlock):hover td:nth-child(2):after,
:global(.ui.table tbody:nth-child(2n) tr).rowArea:not(.rowBlock):hover
  td:nth-child(2):after {
  background: var(--color-row-hover);
}

:global(.ui.table td) {
  padding-top: 15px;
  padding-bottom: 15px;
}

:global(.ui.table td).areaCell {
  position: relative;
  padding-bottom: 25px;
}

:global(.ui.table td).areaCell:after {
  position: absolute;

  bottom: 0;
  left: 20px;
  content: "";
  width: 1px;
  height: 15px;
  background: var(--color-border);
}

.rowBlock {
}
.rowArea {
}
.rowArea:not(.rowBlock) :global(.ui.header) {
  font-size: 16px;
}

.vacant {
  font-style: italic;
  color: var(--color-vacant-blue);
}

.tasksListIcon {
  font-size: 21px;
  color: var(--color-icon);
  margin: -2px 15px 0 0;
  vertical-align: middle;
}

.farmCostBreakdownIcon {
  font-size: 24px !important;
  color: var(--color-brown-grey);
  margin-right: 20px;
  cursor: pointer;
  background: url("./icons/677109.png") no-repeat center;
  background-size: contain;
}

.tasksEditIcon {
  font-size: 16px;
  color: var(--color-green);
  margin-right: 20px;
  cursor: pointer;
}

.tasksEditIcon:hover {
  opacity: 0.8;
}

.tasksDeleteIcon {
  font-size: 16px;
  color: var(--color-icon);
  cursor: pointer;
}

.tasksDeleteIcon:hover {
  opacity: 0.8;
}

.blockName {
  font-weight: bold;
}

.statusHolder {
  display: flex;
  text-align: left;
  line-height: 1;
}
.statusHolder > i:first-child {
  flex: 0 0 20px;
  margin-right: 10px;
}

.archivedFarm {
}
tbody.archivedFarm :global(tr td > div:not(.dropdown)),
thead.archivedFarm :global(tr th > div:not(.dropdown)),
tr.archivedFarm :global(td > div:not(.dropdown)) {
  filter: grayscale(100%);
  opacity: 0.5;
}

.archived {
  font-size: 12px;
  color: var(--color-middle);
}
@media (max-width: 767px) {
  :global(.ui.table).tableFarm tr {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    box-shadow: none !important;
  }

  :global(.ui.table).tableFarm thead tr {
    position: relative;
    padding: 0;
  }

  :global(.ui.table).tableFarm thead tr th {
    height: auto;
  }

  :global(.ui.table).tableFarm thead th:first-child {
    width: 10% !important;
  }
  :global(.ui.table).tableFarm thead th:nth-child(2) {
    padding-top: 0 !important;
    width: 90% !important;
  }
  :global(.ui.table).tableFarm thead th:nth-child(2) :global(.ui.header) {
    line-height: 1;
  }

  :global(.ui.table).tableFarm thead th:nth-child(3),
  :global(.ui.table).tableFarm thead th:nth-child(4),
  :global(.ui.table).tableFarm thead th:nth-child(5),
  :global(.ui.table).tableFarm thead th:nth-child(6) {
    padding-top: 15px !important;
    width: 25% !important;
  }

  :global(.ui.table).tableOpen thead th:nth-child(3),
  :global(.ui.table).tableOpen thead th:nth-child(4),
  :global(.ui.table).tableOpen thead th:nth-child(5),
  :global(.ui.table).tableOpen thead th:nth-child(6) {
    padding-bottom: 15px !important;
  }

  :global(.ui.table).tableFarm thead th:last-child {
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 0 !important;
  }
  :global(.ui.table).tableFarm tbody td:last-child {
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 0 !important;
  }

  :global(.ui.table).tableFarm tbody tr {
    position: relative;
  }

  :global(.ui.table).tableFarm tbody tr td:first-child {
    display: none !important;
  }

  :global(.ui.table).tableFarm tbody tr.rowBlock td:nth-child(2) {
    width: 45% !important;
  }
  :global(.ui.table).tableFarm tbody tr.rowBlock td:nth-child(3) {
    width: 55% !important;
  }

  :global(.ui.table).tableFarm tbody tr.rowBlockWithSubArea td:nth-child(1) {
  }

  :global(.ui.table).tableFarm tbody tr.rowBlockWithSubArea td:nth-child(3) {
    text-align: left;
  }
  :global(.ui.table).tableFarm tbody tr.rowArea td:nth-child(2) {
    width: 45% !important;
  }

  :global(.ui.table).tableFarm tbody tr.rowArea td:nth-child(3) {
    width: 55% !important;
    padding-right: 100px !important;
  }

  :global(.ui.table).tableFarm tbody tr.rowArea td:nth-child(4) {
    order: 8;
    width: 45% !important;
    padding-top: 15px !important;
  }

  :global(.ui.table).tableFarm tbody tr.rowArea td:nth-child(5) {
    width: 45% !important;
    padding-top: 15px !important;
  }
  :global(.ui.table).tableFarm tbody tr.rowArea td:nth-child(6) {
    width: 55% !important;
    padding-top: 15px !important;
  }

  :global(.ui.table).tableFarm tbody tr.rowArea td:nth-child(7) {
    order: 9;
    padding-top: 15px !important;
  }

  :global(.ui.table).tableFarm
    tbody
    tr.rowArea
    td:not(:last-child):not(:nth-child(2))
    .verticalMiddle {
    justify-content: left;
  }

  .tableFarm tbody tr.rowBlockWithSubArea,
  .tableFarm tbody:not(:last-child) tr.rowArea:not(.rowBlock):last-child {
    border-bottom: 1px solid var(--color-border);
  }

  .verticalMiddle {
    justify-content: center;
  }

  :global(.ui.table tr).rowArea:not(.rowBlock) td:nth-child(2):before,
  :global(.ui.table tr).rowArea:not(.rowBlock) td:nth-child(2):after,
  :global(.ui.table td).areaCell:after {
    display: none;
  }
}
