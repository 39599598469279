.root :not(i:global(.icon)),
.root :global(.ui.header) {
  font-family: Roboto, sans-serif !important;
}

.modalHeader {
  font-family: Roboto, sans-serif !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0;
  text-align: left;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.segment) {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.root :global(.ui.grid) {
  margin: 0;
}

.removeActiveIngredient {
  color: #b00020;
  margin-right: 0 !important;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.modalButton:global(.ui.green.button) {
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.modalButton:global(.ui.basic.green.button) {
  background-color: #ffffff !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.modalButton:global(.ui.basic.green.button):hover {
  color: #ffffff !important;
  border: none;
}

.modalButton:global(.ui.green.button) {
  background-color: var(--color-green) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.modalButton:global(.ui.green.button):hover {
  background-color: #156941 !important;
}

.modalButton:global(.ui.button:disabled) {
  background-color: #f6f7f8 !important;
  color: #b3b3b3 !important;
}

.textButtonContainer {
  margin-top: 8px;
  display: flex !important;
  justify-content: end;
}

.activeIngredientMessage :global(.ui.header) {
  margin: 0 !important;
}

.attachmentLabel {
  margin-right: auto !important;
}

.generalAttachmentsContainer {
  display: flex !important;
  flex-wrap: wrap;
}

.generalAttachmentsButton {
  margin-left: auto !important;
}

.root :global(.ui.form .field) > label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.root .chemicalPropertyColumn {
  padding-left: 0 !important;
}

.root .chemicalPropertyColumn :global(.field) {
  margin-bottom: 16px !important;
}

.errorMessage {
  margin-top: 6px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  white-space: normal;
  background: #ffffff !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
}

.modalContentContainer {
  min-height: 680px;
  scroll-behavior: smooth;
}
