@import "../../assets/css/variables.css";

.infoHolder {
  width: 240px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
}

.infoHolder > div {
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
}

.infoHolder .blockName {
  color: var(--color-grass);
}

.active {
  color: var(--color-pink);
}

.infoHolder .buttonCreate {
  margin-top: 20px;
}

.cropName {
  margin-top: 10px;
}

.vacant {
  margin-left: 5px;
  font-style: italic;
  font-size: 12px;
  color: var(--color-vacant-blue);
}

.scanCard {
  width: 215px;
  float: left;
  margin-right: 10px;
}

.infoField {
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}

.infoField b {
  font-weight: bold;
  width: 40%;
  display: inline-block;
}

.infoField span {
  width: 60%;
  font-weight: normal;
  white-space: break-spaces;
}
.rowWrapper {
  display: flex;
  justify-content: space-between;
}
.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  flex: 1;
}
.headerLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  flex: 1;
}
.value {
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  margin-left: 20px;
}
.gm-ui-hover-effect {
  display: none !important;
}

.binsWeightHolder {
  width: 260px;
  padding-bottom: 10px;
}

.headerRowWrapper {
  margin-bottom: 10px;
}
