.modalBackdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.85);

  z-index: 9999999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper {
  position: fixed;
  top: 20px;
  right: 20px;
}

.icon {
  color: white;
  cursor: pointer;
  opacity: 0.8;
  font-size: 30px;
}

.image {
  max-width: 95vw;
  max-height: 95vh;
  margin: auto;
}

.imageMinimized {
}
