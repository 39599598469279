@import '../../assets/css/variables.css';

.infoMessages {
  position: fixed;
  left: 0;
  top: 80px;
  padding: 0 1rem;
  width: 100%;
}

.infoMessagesIn {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
