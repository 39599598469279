:global(.ui.button).addButton {
  background-color: var(--color-green) !important;
}

:global(.ui.basic.green.button).cancelButton {
  color: var(--color-green) !important;
}

:global(.ui.basic.green.button).actionButton {
  width: 103px !important;
  height: 28px !important;
  padding: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: var(--color-green) !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
}
.infoCircle {
  /* color: #B3B3B3 !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
  margin-right: 8px !important;
}

:global(.ui.modal).archivedModal {
  width: 670px;
  height: 60px;
}

:global(.ui.modal > .content).archivedModalContent {
  background: #fffaf2;
  height: 60px !important;
  padding: 10px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  color: #f0ac47;
}

.archivedModalContentButton {
  padding: 5px !important;
  margin-left: 15px !important;
  color: var(--color-green) !important ;
  background: none !important;
}

:global(.ui.button).printButton {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).printButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).printButton:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).printButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.iconWrapper {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

.buttonWrapper {
  display: flex;
}

.reportWrapper {
  padding: 25px !important;
}

.reportHeaderRow {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.reportHeader {
  width: 930px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16pt;
  padding-left: 25px;
  background-color: #ebf6e9;
}

.logoContainet {
  width: 90px;
  height: 50px;
  margin-left: 12px !important;
}

.headerContainer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.totalHeader {
  height: 35px;
  border-bottom: 1px solid #dededf;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #666666 !important;
  vertical-align: text-bottom;
  padding-bottom: 4px !important;
}

.totalValue {
  height: 35px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #000000 !important;
  vertical-align: text-top;
  padding-top: 4px !important;
}

.tableHeaderCell {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
  background-color: white !important;
}

.tableBodyCell {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #212121 !important;
}

.tableBodyExpectedInventoryCell {
  height: 40px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #b00020 !important;
}

.tableFooterFirstColumn {
  height: 40px;
  border-top: 1px solid #dededf !important;
  padding-left: 10px !important;
}
.tableFooterEmptyColumn {
  height: 40px;
  border-top: 1px solid #dededf !important;
  border-right: none !important;
  border-left: none !important;
}

.tableFooterSecondColumn {
  height: 40px;
  border-top: 1px solid #dededf !important;
  padding-left: 10px !important;
}
