@import "../../assets/css/variables.css";

:global(.ui.slide.along.sidebar) {
  position: fixed;
  background: var(--color-grass-light);
}
:global(.ui.slide.along.sidebar) {
  z-index: 3;
}

.menuHolder {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
  overflow: auto;
}

:global(.ui.vertical.menu) .menuHolder :global(.active.item) {
  background: none;
}

:global(.ui.vertical.menu) .menuHolder :global(.active.item a),
:global(.ui.vertical.menu) .menuHolder :global(.active.item a:hover) {
  color: var(--color-background-green);
  background: var(--color-background);
  margin: 0 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

:global(.ui.vertical.menu) .menuHolder :global(.active.item a .icon) {
  color: var(--color-grass-meduim);
  font-size: 18px;
}

:global(.column-left .ui.icon.button).buttonClose {
  width: auto;
  padding: 5px;
  min-height: auto;
  display: block;
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 5;
  background: none;
  font-size: 16px;
}

:global(.ui.menu) .menuGroup {
  margin: 10px 0;
}
:global(.ui.menu) .menuGroup:first-child {
  margin-top: 0;
}
:global(.ui.menu) .menuGroup.growAuto {
  flex: 1 1 auto;
}
:global(.ui.menu) .menuGroup.support {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  margin: 0;
}
:global(.ui.menu) .menuGroup :global(.item).menuLink {
  padding: 0;
}
:global(.ui.menu) .menuGroup :global(.item).menuLink:before {
  display: none;
}

:global(.ui.menu) .menuGroup .menuLink a {
  display: flex;
  padding: 15px 25px;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600;
  line-height: 1;
  align-items: center;
  transition: all ease-in-out 0.2s;
  height: 45px;
}

:global(.ui.menu) .menuGroup .menuLink a.menuDisabled {
  cursor: default;
}

:global(.ui.menu) .menuGroup .menuLink a:not(.menuDisabled):hover {
  color: var(--color-background-green);
}

:global(.ui.menu) .menuGroup .menuLink a :global(.icon) {
  margin: 0 5px 0 0;
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
}
:global(.ui.menu)
  .menuGroup
  .menuLink
  a:not(.menuDisabled):hover
  :global(.icon) {
  transition: all ease-in-out 0.2s;
  color: var(--color-grass-meduim);
}

.icon {
  font-size: 21px;
}

.menuGroup .groupTitle {
  color: var(--color-chapter);
  text-transform: uppercase;
  margin: 10px 0;
  padding-left: 30px;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "sans-serif", " Montserrat", " Lato", " Helvetica Neue",
    Arial, Helvetica;
  font-weight: 600;
}

.menuGroup .groupTitle:before {
  position: absolute;
  content: "";
  top: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(34, 36, 38, 0.1);
}

.ui.menu .menuGroup + .menuGroup {
  margin-top: 30px;
}

.dropdownItem {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "sans-serif", " Montserrat", " Lato", " Helvetica Neue",
    Arial, Helvetica;
}
.pendingOperationCount {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #f0ac47;
  font-weight: normal;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  color: white;
  margin-left: 12px;
  font-size: 11px;
  padding-top: 1px;
  padding-right: 2px;
}

:global(.ui.menu) .menuGroup.support .groupTitle:before {
  display: none;
}

:global(.ui.menu) .menuGroup.support .menuLink a :global(.icon) {
}
.menuLink {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "sans-serif", " Montserrat", " Lato", " Helvetica Neue",
    Arial, Helvetica !important;
}

@media screen and (min-width: 768px) {
  .menuHolder {
    padding-top: 40px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}
