.EmployeesBarcodesPrintLayout {
}

:root {
  /*--multiplier: 2;*/
  --multiplier: 1;
  /*Printer settings:*/
  /*100mm wide*/
  /*40mm high*/
  /*Speed 5cm / s*/
  /*Darkness Max*/
  /*Offset left - 3mm*/
  /*Offset top - 8mm*/
  /*[12:57, 3/6/2020] +61 400 353 278: Labels 23mm wide*/
  /*[12:57, 3/6/2020] +61 400 353 278: 37mm high*/
}

.page {
  width: calc(100mm * var(--multiplier));
  margin: 0;
}

.block {
  justifycontent: space-around;
  display: grid;
}

.barcodeHolder {
  /*margin-bottom: calc(2.7mm * var(--multiplier));*/
  margin-top: calc(4mm * var(--multiplier));
  margin-left: calc(1.35mm * var(--multiplier));
  margin-right: calc(1.35mm * var(--multiplier));
  /*height: calc(37mm * var(--multiplier));*/
  width: calc(22.3mm * var(--multiplier));
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.barcodeDataHolder {
  text-align: center;
}

.barcode {
  height: calc(15mm * var(--multiplier));
  width: calc(15mm * var(--multiplier));
}

.textHolder {
  margin-top: calc(3px * var(--multiplier));
  line-height: calc(8px * var(--multiplier));
  font-size: calc(8px * var(--multiplier));
}

.text {
  padding: calc(1px * var(--multiplier));
}

@media screen {
  .barcodeHolder {
    height: calc(37mm * var(--multiplier));
  }
}

@media print {
  .pageBreak {
    display: block;
    page-break-before: always;
  }
}
