.title {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-light);
  display: none;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 3px;
}

.newItem .title {
  color: #000;
}

.buttonAdd {
  margin-bottom: 25px;
}
.chemicalsTable:global(.ui.table) {
  border: none;
}
.chemicalsTable:global(.ui.table) thead th {
  background: none;
  font-weight: normal;
  color: var(--color-middle);
}

.chemicalsTable:global(.ui.table) tbody tr td {
  font-weight: 600;
}

.chemicalsTable:global(.ui.table) tbody tr td.actions {
  text-align: center;
  color: var(--color-icon);
  font-size: 18px;
}

.chemicalsTable:global(.ui.table) tbody tr td.actions i {
  cursor: pointer;
}

.chemicalsTable:global(.ui.table) tbody tr td.actions i:not(:last-child) {
  margin-right: 15px;
}

:global(.ui.table).chemicalsTable tbody tr.editItem,
:global(.ui.table).chemicalsTable tbody tr.editItem:hover,
:global(.ui.table).chemicalsTable tbody tr.newItem,
:global(.ui.table).chemicalsTable tbody tr.newItem:hover {
  background: var(--color-background-cell) !important;
}

.chemicalsTable :global(.ui.selection.dropdown .default.text) {
  white-space: nowrap;
  padding-right: 25px;
  overflow: hidden;
  max-width: 100%;
}

.addTitle {
  display: none;
  font-weight: bold;
}

.buttonCancel {
  display: none;
}

.buttonUpdate {
}

.inputRate {
  width: 130px;
}

.addChemicalValidation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (min-width: 767px) {
  .buttonsHolder {
    width: 125px;
  }

  .buttonsNew :global(.ui.button) {
    padding: 12px;
    font-size: 12px;
  }

  .chemicalsTable:global(.ui.table) tbody tr td:last-child {
    max-width: 155px;
    text-align: center;
    white-space: nowrap;
    width: 1%;
  }

  .cropAndPurposeCell {
    width: 15%;
  }

  .dilutionCell {
    width: 3%;
  }

  .chemicalCell {
    width: 15%;
  }

  .chemicalTypeCell {
    width: 10%;
  }

  .dateOfManufactureCell {
    width: 10%;
  }

  .batchNumberCell {
    width: 10%;
  }
}

:global(.ui.table).chemicalsTable tbody tr.message,
:global(.ui.table).chemicalsTable tbody tr.message:hover {
  border: none;
  background: none !important;
}

@media (max-width: 767px) {
  .title {
    display: block;
    margin-top: 15px;
  }
  .chemicalsTable:global(.ui.table) thead {
    display: none;
  }

  .chemicalsTable:global(.ui.table) tbody tr {
    display: flex !important;
    flex-wrap: wrap;
    background-color: var(--color-bg-pink);
    border: 1px solid var(--color-border);

    box-shadow: none !important;
    position: relative;
  }
  .chemicalsTable:global(.ui.table) tbody tr:not(:last-child) {
    margin-bottom: 15px;
  }
  .chemicalsTable:global(.ui.table) tbody tr:nth-child(2n) {
    background-color: var(--color-bg-pink);
  }
  .chemicalsTable:global(.ui.table) tbody tr.newItem,
  .chemicalsTable:global(.ui.table) tbody tr:nth-child(2n).newItem {
    background-color: #fff;
  }

  .chemicalsTable:global(.ui.table) tbody tr td {
    width: 50% !important;
    flex: 0 1 auto;
  }
  .chemicalsTable:global(.ui.table) tbody tr td:last-child {
    width: 100% !important;
  }
  .chemicalsTable:global(.ui.table) tbody tr td:not(.actions) {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }

  :global(.ui.table).chemicalsTable tbody tr.message {
    padding: 0;
  }

  .chemicalsTable:global(.ui.table) tbody tr td.actions {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 18px;
    width: auto !important;
    text-align: right;
    padding: 0 !important;
    white-space: nowrap;
  }
  .chemicalsTable:global(.ui.table) tbody tr td.actions i:not(:last-child) {
    margin-right: 15px;
  }

  .buttonAdd button span {
    display: inline !important;
  }

  .chemicalsTable:global(.ui.table) tbody tr td.addTitle {
    width: 100% !important;
  }

  .buttonsNew {
    margin-top: 10px;
  }

  .buttonCancel {
    display: inline-block;
  }
}

:global(.ui.container).tableWrapper {
  /*overflow: auto;*/
  margin-left: 0 !important;
  margin-right: 0 !important;
}
