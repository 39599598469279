@import "../../assets/css/variables.css";

.sortHolder {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
}

.sortHolder :global(.icon) {
  color: var(--color-green);
}
:global(.ui.dropdown).dropdownSort {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  color: var(--color-green);
}
:global(.ui.dropdown).dropdownSort :global(.menu) {
  left: auto;
  right: 0;
}

.sortOrder {
  cursor: pointer;
  color: var(--color-green);
}

.sortHolder :global(.icon) {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  :global(i.icon).sortOrder {
    margin-left: 0;
  }
}
