.segmentHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customTooltip {
  background-color: white;
  padding: 0.5rem;
  border: 1px green solid;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
