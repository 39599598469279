@import "../../assets/css/variables.css";

.avatarWrapper {
  position: relative;
  display: inline-flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  min-width: 40px;
  justify-content: center;
}

.avatarHolder {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--color-border);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  min-width: 40px;
  justify-content: center;
}

.avatarHolder span {
  color: white;
  font-family: Roboto, sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.avatarHolder :global(.icon) {
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  font-size: 4em;
  margin: 0;
}
