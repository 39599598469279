@import '../../../../../assets/css/variables.css';

.calendarHolder :global(.react-calendar) {
  bottom: 100%;
  box-shadow: 0 0 10px var(--color-shadow);
}

.textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}

.closeicon {
  justify-content: flex-end;
  align-items: baseline;
  display: flex;
}

.addVarietyButtonContainer {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.rightAlignPicker > div {
  left: auto;
  right: 0;
}

.spinner input[type='number'].mod::-webkit-outer-spin-button,
.spinner input[type='number'].mod::-webkit-inner-spin-button {
  -webkit-appearance: button;

  width: 1em;
  border-left: 1px solid #bbb;
  opacity: 0.5; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
