.root :global(.ui.segment) {
  border: 1px solid #dededf;
  box-shadow: none;
  margin: 4px 0px 4px 0px;
}

:global(.ui.header).titleContainer {
  display: flex;
  justify-content: inherit;
}

.rowContainer {
  margin-top: 8px;
  padding: 0 !important;
}

.editButton,
.deleteButton {
  cursor: pointer;
}

.buttonContainer {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}

:global(.ui.grid .column).rowLabel {
  width: 16.5%;
  padding-bottom: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

:global(.ui.grid > .column).chevronContainer {
  width: 2%;
  padding-bottom: 0;
}

:global(.ui.grid > .column).productNameItem {
  padding-top: 0 !important;
  height: 40px !important;
  width: 31%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

:global(.ui.grid > .column).productTotalCostItem {
  height: 40px !important;
  padding-top: 0 !important;
  width: 32%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

:global(.ui.grid > .column).productExpectedInventoryItem {
  height: 40px !important;
  padding-top: 0 !important;
  width: 20%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

:global(.ui.grid > .column).productButonContainer {
  display: flex !important;
  justify-content: flex-end;
  height: 40px !important;
  padding-top: 0 !important;
  display: flex;
  width: 13%;
  padding-bottom: 0;
  display: flex !important;
  align-items: center !important;
}

:global(.ui.table thead tr th) {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

:global(.ui.dropdown .menu > .item:hover) {
  background-color: #ebf6e9 !important;
}

:global(.ui.grid > .row > .column > .icon).infoButton {
  margin-left: 10.38px;
  color: #b3b3b3;
}

:global(.ui.popup).popup {
  background-color: black;
  color: white;
}

:global(.ui.button).cancelBtn {
  width: 75px;
  height: 36px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 16px;
  background: none;
  color: #0e9354;
  border: 1px solid #0e9354;
}

:global(.ui.button).saveBtn {
  height: 36px !important;
  width: 63px !important;
  border-radius: 4px;
  padding: 0px;
  margin-top: 16px;
  background-color: #0e9354;
  color: #ffffff;
}

:global(.ui.button).disabledBtn {
  height: 36px !important;
  width: 63px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 16px;
  color: #b3b3b3;
}

:global(.ui.grid > .column > .icon).infoButton {
  margin-left: 8.38px;
  color: #b3b3b3;
  margin-bottom: 5px !important;
}

.warningMessagesInModal {
  box-shadow: none !important;
}

:global(.ui.popup).popup {
  background-color: black;
  color: white;
}

:global(.ui.table).tableFarm thead tr:first-child > th:first-child,
:global(.ui.table).tableFarm tbody tr > td:first-child {
  position: relative;
  width: 25%;
}

.confirmModal {
  width: 45% !important;
}

:global(.ui.table).tableFarm
  :global(thead tr:first-child > th:last-child i.icon),
:global(.ui.table).tableFarm :global(tbody tr > td:last-child i.icon) {
  cursor: pointer;
}

.toggleIcon {
  width: 20px;
  height: 20px;
  font-size: 15px;
  display: inline-block;
  color: #666666;
  cursor: pointer;
}

.toggleIcon.openTable {
  cursor: pointer;
}

.editForm {
  width: 100%;
  padding: 16px !important;
  border-radius: 8px !important;
}

.productHeader {
  display: flex !important;
  justify-content: center !important;
  font-size: 22px !important;
  margin-bottom: 24px !important;
}

.productRow {
  height: 45px !important;
  margin-bottom: 16px !important;
}

.productEditButtonContainer {
  border-top: 1px solid #dededf;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
}

:global(.ui.labeled.editCurrentInventoryField) {
  margin-top: 18px !important;
  opacity: 1 !important;
}

:global(.ui.labeled.editCurrentInventoryField) input {
  opacity: 1 !important;
}

:global(.ui.labeled.editCurrentInventoryField > .label) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7f8 !important;
  border: 1px solid #dededf;
  color: #212121;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

:global(.ui.labeled.negativeElement) {
  margin-top: 18px !important;
  opacity: 1 !important;
}

:global(.ui.labeled.negativeElement) input {
  opacity: 1 !important;
}

:global(.ui.labeled.negativeElement > .label) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7f8 !important;
  border: 1px solid #dededf;
  color: #212121;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

:global(.ui.label) {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}

:global(.ui.basic.disabled.buttonActive) {
  height: 40px !important;
}

:global(.ui.labeled.warningStyle > .label) {
  height: 40px !important;
  background-color: #b00020;
  border: 1px solid #b00020;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  color: #ffffff;
  border-radius: 0px 3px 3px 0px !important;
}

:global(.ui.input.warningStyle > input) {
  height: 40px !important;
  background: #ffffff;
  border: 1px solid #b00020;
  box-sizing: border-box;
}

.warningStyle_label {
  color: #b00020 !important;
}

:global(.ui.modal).deleteModal {
  margin: 30%;
}

:global(.ui.button).deleteBtn {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).deleteBtn:hover {
  background: #b00020;
  border-radius: 4px;
  color: white;
}

:global(.ui.button).cancelDltBtn {
  background: none;
  border: 1px solid #0e9354;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e9354;
}

.root .negativeElement {
  color: #b00020;
}

:global(.ui.input.negativeElement > .input) {
  color: #b00020;
  opacity: 1 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.deleteButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

:global(.icon).deleteButton:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.deleteButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}

.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.infoTooltipWrapper {
  text-align: start;
}

.ui.form .field .ui.input.labeled input {
  opacity: 1 !important;
}

.reasonMessage {
  color: rgba(240, 172, 71, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-top: 4px !important;
}

.switchButton {
  padding-left: 0px !important;
}

.formDisabledBtn {
  padding: 0px !important;
  background: none !important;
  color: #b3b3b3 !important;
}

.formCancelBtn {
  padding: 0px !important;
  margin-right: 16px !important;
  background: none !important;
  color: #b00020 !important;
}
