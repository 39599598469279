.layout :global(.pageBreak) {
  margin: 0;
}

.layout :global(.barcodeHolder) {
  width: 76mm;
  height: 112mm;
  outline: 1px solid #7d7d7d;
  background-color: #e8e8e8;
}

.layout :global(.barcodeDataHolder) {
  width: 100%;
}

.layout :global(.barcode) {
  width: 27mm;
  height: 27mm;
}

.layout :global(.textHolder) {
  font-size: 16px;
}
