:global(.ui.container).container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

:global(.ui.segment).reportTypesHolder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1em 0;
  background-color: transparent;
}

.header {
  display: inline;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  border-radius: 4px;
  box-shadow: 0 8px 16px 1px rgba(105, 105, 105, 0.15);

  background-color: #ffffff;

  margin: 0 24px 24px 0;
  padding: 28px 33px;

  text-align: center;
}

.card:hover {
  background-color: #2bb673;
  color: white;
}

.card img {
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: white;
}

.card .caption {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;
  color: var(--color-text);

  width: 120px;
}

.card:hover .caption {
  color: white;
}

.backIconWrapper {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}
