@import "../../../assets/css/variables.css";

.tasksListHolder {
  overflow-x: scroll;
}

.tasksLoader {
  margin-top: 30px;
}

.filtersGrid {
  border-bottom: 1px solid var(--color-border);
}

.sidebarHolder {
  position: relative;
}

.tasksHolder :global(.pusher) {
  padding: 1em;
  height: 100%;
  overflow: auto;
}
.tasksHolder :global(.ui.right.overlay.sidebar) {
  background: var(--color-background);
  width: 100%;
  padding: 15px;
  overflow: auto;
  box-shadow: var(--box-shadow);
}

:global(.ui.segment).mainHolder {
  padding: 0;
}

.backIconWrapper {
  padding: 8px;
  margin-left: -8px;
  cursor: pointer;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 22px;
  margin-right: 0 !important;
}
.activeIngredientsCell {
  width: 130px;
  padding-left: 5px;
  white-space: pre-wrap;
}

@media screen and (min-width: 768px) {
  .tasksHolder :global(.ui.sidebar) {
    width: 70%;
    max-width: 570px;
    padding: 30px;
  }
}
