@import '../../assets/css/variables.css';

:global(.ui.message).messageInfo {
  position: fixed;
  left: 15px;
  bottom: 15px;
  box-shadow: 0 0 10px var(--box-shadow);
  padding: 15px;
  margin: 0;
}
:global(.ui.message).messageInfo :global(.ui.button) {
  margin-left: 15px;
}

:global(.ui.message.black).messageInfo :global(.ui.button) {
  color: #fff;
}
