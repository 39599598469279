.areaTable {
  width: calc(100% - 32px) !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  border: none !important;
  border-spacing: 8px !important;
}

.allCheck {
  width: 50px;
  background-color: white !important;
  border: none !important;
  display: flex;
  align-items: center;
}

.header {
  padding-left: 0px !important;
  background-color: white !important;
  border: none !important;
}

.lineCheckbox {
  top: -4px;
  left: -9%;
}

.lineCheckbox label::after {
  top: 4px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.lineCheckbox label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
}

.lineAllCheckbox {
  display: block !important;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
  left: 12% !important;
}

.lineAllCheckbox label::after {
  top: 4px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.lineAllCheckbox label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
}

.lineCheckboxMinus {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #0e9354;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -5px;
  left: 15%;
}

.minusIcon {
  font-size: 12px !important;
  display: flex !important;
  padding-top: 2px !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0e9354 !important;
}

.areaTableBodyRow {
  height: 50px;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  border-radius: 8px !important;
}

.areaTableBodyCell {
  width: 10% !important;
  padding: 0px !important;
  border-top: none !important;
}

.areaTableBodyWtCell {
  width: 15% !important;
  padding: 0px !important;
  border-top: none !important;
}

.areaTableBodyBlockCell {
  width: 12% !important;
  padding: 0px !important;
  border-top: none !important;
}

.areaCompleteTableBodyBlockCell {
  width: 12% !important;
  padding: 0px !important;
  padding-left: 10px !important;
  border-top: none !important;
}

.areaTableBodyRow .areaTableBodyCell:first-child {
  width: 5% !important;
  padding-left: 0px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.areaTableBodyRow .areaTableBodyCell:last-child {
  width: 8% !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.tableContainer {
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.editIcon {
  cursor: pointer;
  margin-right: 15px !important;
  color: #0e9354;
}

.undoIcon {
  cursor: pointer;
  margin-right: 5px !important;
  color: #0e9354;
}

.sectionTableWrapper {
  display: flex;
}

.navigateMenu {
  width: 340px;
  box-shadow: 0px 4px 9px 0px #0000001a;
  padding: 16px;
}

.generalInfoSection {
  border-bottom: 1px solid #dededf !important;
  padding-bottom: 24px;
}

.detailsSection {
  padding-top: 24px;
}

.activeMenuHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #0e9354;
}

.menuHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #212121;
}

.menuSubHeader {
  cursor: pointer;
  font-family: "Roboto" !important;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #666666;
}

.navigateTableContainer {
  border-left: 1px solid #dededf;
  width: calc(100% - 354px);
  height: calc(100vh - 270px) !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.sectionTable {
  margin: 0px !important;
}

.tableSegment {
  width: 98%;
  padding: 16px;
  margin-left: 14px;
  margin-bottom: 5px !important;
  box-shadow: 0px 1px 4px 0px #00000026;
}

.headerSection {
  height: 40px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
  vertical-align: baseline;
}

.valueSection {
  height: auto !important;
  display: flex;
  gap: 5px;
  flex-wrap: wrap !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.tableHeader {
  font-family: Roboto !important;
  padding-left: 14px !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  color: #212121 !important;
}

.tableSubHeader {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  color: #212121 !important;
  padding-bottom: 16px !important;
}

.activeMenuItem {
  color: #0e9354;
  /* Customize this color to your preference */
}

.card {
  background-color: #f6f7f8;
  border: 1px solid #b3b3b3;
  box-shadow: 0px 1px 4px 0px #00000026;
  margin: 10px;
  border-radius: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.emptyTableWrapper {
  width: 100%;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.emptyTableText {
  padding-top: 20px;
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.dateContainer {
  display: flex;
  flex-direction: column;
}

:global(.ui.modal).confirmModal {
  margin: 30%;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
}

.areatableLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}
