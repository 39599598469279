@import '../../../../assets/css/variables.css';

.formHolder {
  background: var(--color-background-cell);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
}

.formHolderIn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formHolderBody {
  padding: 30px 15px;
  overflow: auto;
}

.addHolder {
  text-align: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--color-border);
}

:global(.ui.form).chemicalForm :global(> .ui.segment) {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .formHolderBody {
    padding: 30px 25px;
  }
}
