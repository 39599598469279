.root {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.contentWrapper {
  height: 100%;
  margin: 0px !important;
  padding: 16px !important;
  background-color: #f5f7f7;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7% !important;
  padding-top: 16px;
  margin-bottom: 16px;
}

.header {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  height: 51px !important;
  font-size: 34px;
  line-height: 51px;
  color: #212121;
  height: 5%;
}

.doneBtn {
  height: 36px;
  display: flex !important;
  align-items: center;
  background-color: #0e9354 !important;
  color: white !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
}

.customizationContainer {
  height: 100vh;
  background-color: white;
  padding: 18px;
  border-radius: 8px;
}

.infoMessage {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
}

.dragIcon {
  margin-right: 10.4px;
}

.typeContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dededf;
  margin-bottom: 24px;
}

.typeWrapper {
  height: 32px;
  display: flex;
  gap: 8px;
}

.horizontalScroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.horizontalScroll::-webkit-scrollbar {
  display: none;
}

.item {
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

.type {
  cursor: pointer;
  height: 32px;
  display: flex;
  font-size: 12px !important;
  align-items: center;
  padding: 8px 12px 8px 12px;
  border-radius: 4px 4px 0px 0px;
  border: 1px dashed #dededf;
  border-bottom: none !important;
  text-wrap: nowrap;
}

.typeDropdown {
  width: 130px;
  height: 15px;
  padding: 0px 16px 0px 0px !important;
  gap: 4px;
  border: none !important;
  box-shadow: none !important;
  color: #0e9354 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.06px !important;
  text-align: center !important;
}

.closeIcon {
  display: flex !important;
  cursor: pointer;
  align-items: center;
  align-items: center;
  color: #666666 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.dropdownMenu {
  width: 230px;
  overflow-y: auto;
  top: 40px !important;
  left: -100px !important;
}

.dropdownScrollingMenu {
  min-height: 350px !important;
}

.dropdownScrollingMenu::-webkit-scrollbar-track-piece {
  background-color: #d9d9d9 !important;
  width: 4px !important;
}

.dropdownScrollingMenu::-webkit-scrollbar-track {
  background-color: #d9d9d9 !important;
  width: 4px !important;
}

.dropdownScrollingMenu::-webkit-scrollbar-thumb {
  background-color: #666666 !important;
  width: 16px !important;
}

.firstItem {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 11.72px !important;
  text-align: left !important;
  color: #666666 !important;
}

.checkboxLabelWrapper {
  display: flex;
}

.label {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.typeCheckbox {
  margin-right: 12px;
}

:global(.ui.dropdown .menu > .item.notSelected) {
  border-top: 1px solid #dededf !important;
}

.ui.multiple.dropdown > .text {
  background-color: red;
}

.typeCheckbox label::before {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.typeCheckbox label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

:global(.dropdown) :global(.menu) > .clearButton {
  cursor: pointer;
  text-transform: none !important;
  color: var(--color-error-delete) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
}
