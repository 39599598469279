:global(.ui.button).printButton {
  height: 28px;
  display: flex;
  padding: 0;
  opacity: 1 !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px;
  color: #0e9354 !important;
}

:global(.ui.button).printButton:hover {
  opacity: 1 !important;
  color: white !important;
  background-color: #156941 !important;
  height: 28px;
  border-radius: 4px;
}

:global(.ui.button).printButton:hover i {
  opacity: 1 !important;
  color: white !important;
}

:global(.ui.button).printButton:active {
  background-color: #06331e !important;
  opacity: 1 !important;
  height: 28px;
  border-radius: 4px;
  color: white !important;
}

.iconWrapper {
  display: flex;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}

td:has(.valueContainer) {
  border: none !important;
}

.valueContainer {
  width: 100% !important;
  display: flex;
  align-items: center;
  height: 50px;
}

.chevronIcon {
  margin-right: 5px !important;
}

:global(.ui.button).backIconWrapper {
  padding: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: none;
}

.backIcon {
  color: var(--color-background-green-medium);
  font-size: 25px !important;
  line-height: 20px;
  margin-right: 0 !important;
}

.totalRow {
  font-weight: 600;
}

.morePopupContent {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.popupItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.popupItem .avatarHolder {
  height: 20px;
  min-width: 20px;
}

.popupItem:nth-child(1) {
  margin-top: 0;
}

.popupItem span {
  margin-left: 10px;
}

.avatarHolder {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}
.avatarsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avatarsContainer .avatarHolder:nth-child(n + 2) {
  margin-left: -10px;
}

.avatarsContainer span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}
