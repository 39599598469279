.segmentHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.taskTypesFilter {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  min-height: 43px;
}

.taskTypesFilterError {
  border-color: #b00020;
}
