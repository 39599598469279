.printTableWrapper {
  margin-right: 20px !important;
}

.printTableRow {
  display: flex;
}

.printTableHeader {
  width: 1100px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #ebf6e9;
  padding-left: 21px;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px;
  text-align: left;
}

.printTableLogo {
  display: flex;
  justify-content: flex-end;
  height: 50px !important;
  margin-left: 12px !important;
  margin-top: 12px !important;
}

.contentSection {
  margin: 20px;
}

.totalHeaderSection {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dededf;
}

.totalHeader {
  color: #666666;
  padding-bottom: 4px !important;
  width: 25%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.totalValueSection {
  width: 100%;
  display: flex;
}

.totalValue {
  color: #212121;
  padding-bottom: 4px !important;
  width: 25%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.totalAssigneeHeaderSection {
  width: 100%;
  margin-top: 16px;
  display: flex;
  border-bottom: 1px solid #dededf;
}

.totalAssigneeHeader {
  color: #666666;
  padding-bottom: 4px !important;
  width: 100%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.totalAssigneeValueSection {
  width: 100%;
  display: flex;
}

.totalAssigneeValue {
  color: #212121;
  padding-bottom: 4px !important;
  width: 100%;
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.blockWrapper {
  width: 100%;
  padding-top: 7px;
  padding-left: 15px;
  background-color: #eeeeef !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
  border-bottom: 1px solid black !important;
}

.subTableHeaderSection {
  display: flex;
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.subTableHeader {
  height: 30px;
  padding-top: 7px;
  padding-left: 15px;
  width: calc(100% / 6);
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.subTableHeader:not(:last-of-type) {
  border-right: 1px solid #dededf;
}

.subTableValueSection {
  display: flex;
  border-bottom: 1px solid black !important;
}

.subTableValue:not(:last-of-type) {
  border-right: 1px solid #dededf;
}

.subTableValue {
  height: 35px;
  padding-top: 10px;
  padding-left: 15px;
  width: calc(100% / 6);
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  border-top: 1px solid #dededf;
}

.subTableActiveValue {
  background-color: #ebf6e9 !important;
  height: 35px;
  padding-top: 10px;
  padding-left: 15px;
  width: calc(100% / 6);
  color: #0b8b42 !important;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  border-top: 1px solid #dededf;
}
