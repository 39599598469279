.gm-control-active,
.gmnoprint {
  opacity: 0.5;
}

.gmnoprint > .gm-control-active {
  opacity: 1;
}

.gm-control-active:hover,
.gmnoprint:hover {
  opacity: 1;
  background: black;
}

.cluster img {
  width: 100%;
  height: auto;
}

.cluster div {
  width: 100% !important;
  height: 100% !important;
  line-height: 30px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: white !important;
}
