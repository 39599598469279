.rw-main {
  color: #fff;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, #2bb673, #2bb673, #8dc63f);
  display: flex;
}

.rw-main h2 {
  margin: 0 0 10px 0;
  font-weight: 300;
  font-size: x-large;
  letter-spacing: 2px;
}

.rw-main .hr {
  width: 100%;
  height: 0;
  border-bottom: solid 1px #fff;
  opacity: 0.4;
  margin: 10px 0;
}

.rw-main p {
  margin: 0;
}

.rw-main.type-today {
  border-radius: 7px;
}

.rw-main.type-5days {
  border-radius: 7px 7px 0 0;
}

.rw-main .rw-box-left {
  padding: 25px;
  width: 60%;
}

.rw-main .rw-box-right {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 7px 7px 0;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rw-main .rw-box-right i {
  font-size: 50px;
}

.rw-main .rw-today .date {
  color: #b5def4;
}

.rw-main .rw-today .current {
  margin-top: 10px;
  line-height: 45px;
  font-size: 30px;
}

.rw-main .rw-today .range {
  color: #b5def4;
  font-size: 12px;
  margin: 10px 0 5px 2px;
}

.rw-main .rw-today .desc {
  color: #b5def4;
  font-size: 12px;
}

.rw-main .rw-today .desc i {
  font-size: 20px;
  color: #b5def4;
}

.rw-main .rw-today .info {
  color: #b5def4;
}

.rw-main .rw-today .info div {
  margin-bottom: 5px;
}

.rw-box-days {
  clear: both;
  display: flex;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  border-radius: 0 0 7px 7px;
  font-size: 11px;
  color: #777;
  background-color: #fff;
}

.rw-box-days .rw-day {
  box-sizing: border-box;
  height: 170px;
  width: calc(100% / 3);
  text-align: center;
  padding: 10px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.rw-box-days .rw-day:not(:first-child) {
  border-left: solid 1px #ddd;
}

.rw-box-days .rw-date {
  font-size: 9px;
  font-weight: bold;
}

.rw-box-days .rw-desc {
  margin: 10px 0 10px 0;
  font-size: 10px;
}

.rw-box-days .rw-range {
  font-size: 11px;
  position: absolute;
  bottom: 10px;
}

.rw-box-days .rw-info {
  margin-top: 10px;
  font-size: 12px;
}

.rw-box-days .wicon {
  margin-top: 15px;
  font-size: 35px;
  color: #8dc63f;
}

.rw-box {
  font-family: sans-serif;
  font-size: 13px;
}
