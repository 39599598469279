@import "../../assets/css/variables.css";

.logo-tuf {
  background: url("../../assets/img/logo.svg") no-repeat left;
  background-size: contain;
  height: 35px;
}

.ui.basic.segment.header-main {
  margin-bottom: 0;
  box-shadow: var(--box-shadow);
  z-index: 2;
}

.vertical-middle {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  /* justify-content: flex-end; */
  padding-right: 33px !important;
}

.vertical-middle.center {
  justify-content: center;
}

.vertical-middle.right {
  justify-content: flex-end;
}

.column-menu {
  border-right: 1px solid var(--color-border);
  min-width: 56px;
}

.column-menu .ui.icon.button {
  background: none;
  font-size: 18px;
  height: inherit;
}

.column-bell {
  position: relative;
  text-align: center;
}

.column-bell .ui.icon.button {
  background: none;
  padding: 5px;
  color: var(--color-icon);
}

.logo-holder {
  max-height: 35px;
  padding: 0;
}

.header-main .search-input {
  width: 100%;
}

.header-main .search-input .ui.input {
  width: 100%;
}

.header-main .search-input .ui.input .prompt {
  border-radius: 0.5rem;
}

.ui.grid > .row > .column.column-search {
  padding-right: 2rem;
}

.column-search-button {
  text-align: center;
}

.column-search-button .ui.icon.button {
  padding: 5px;
  background: none;
  font-size: 18px;
  color: var(--color-icon);
}

.ui.grid > .row > .column.column-user {
  padding-left: 2rem;
}

.status-offline {
  font-size: 18px;
  color: var(--color-middle);
}

.status-offline:not(:first-child) {
  margin: 0 15px;
}

@media screen and (max-width: 992px) {
  .status-offline {
    font-size: 12px;
  }

  .ui.grid > .row > .column.column-search {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    margin: 1px 0 0 0;
    padding: 15px;
    z-index: 10;
    background-color: var(--color-background-cell);
    float: none;
    width: auto !important;
    border-bottom: 1px solid var(--color-border);
  }

  .ui.grid > .row > .column.column-search.open {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .ui.segment.header-main {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ui.basic.segment.header-main > .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ui.basic.segment.header-main > .ui.grid > .row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ui.basic.segment.header-main > .ui.grid > .row > div {
    min-height: 64px;
  }

  .logo-holder {
    margin: 7px 0 0 0;
    max-height: 48px;
    padding-left: 40px;
  }

  .logo-tuf {
    height: 48px;
    background-position: left center;
  }

  .logo-column {
    min-width: 260px;
  }
}

@media screen and (min-width: 992px) {
  .column-bell:before {
    position: absolute;
    content: "";
    top: -1rem;
    left: 0;
    bottom: -1rem;
    width: 1px;
    background: var(--color-border);
  }

  .column-bell:after {
    position: absolute;
    content: "";
    top: -1rem;
    right: 0;
    bottom: -1rem;
    width: 1px;
    background: var(--color-border);
  }
}

@media screen and (min-width: 1200px) {
}
