.reportWrapper {
  padding: 40px;
}

.reportHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.reportHeader {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.reportSubHeader {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  white-space: break-spaces;
}

.reportTable {
  margin-bottom: 20px !important;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.reportTable tr td:first-child,
.reportTable tr td:last-child,
.reportTable tr th:first-child,
.reportTable tr th:last-child {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.reportTable thead tr th,
.reportTable > tbody tr td {
  border: none !important;
}

.reportTable > tfoot tr th {
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
  border-bottom: 2px solid rgba(199, 199, 199, 0.5) !important;
  font-size: 16px !important;
}

.reportTable thead tr th {
  font-size: 16px !important;
  color: #7d7d7d !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.reportTable tr td {
  font-size: 16px !important;
}

.reportTable tr th {
  font-weight: 700 !important;
}

.reportTable tr td.additionalInfoContainer {
  padding-bottom: 0 !important;
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
}

.additionalInfoTable tr td:first-child,
.additionalInfoTable tr td:last-child,
.additionalInfoTable tr th:first-child,
.additionalInfoTable tr th:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.additionalInfoTable tbody tr td {
  border-top: 2px solid rgba(199, 199, 199, 0.5) !important;
}

.stripedRow {
  background-color: #ffffff !important;
}

.pageBreak {
  break-before: always !important;
}

.pageBreakInside {
  break-inside: avoid !important;
}

.reportCreatedRow {
  font-size: 7pt;
}
