:global(.ui.modal > .header).modalHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}

.stepLoading {
  height: 48px;
  background: #f5f7f7;
  margin-bottom: 32px !important;
  display: flex;
  align-items: center !important;
}

.iconAreasLabelWrapper {
  width: 9%;
  display: flex !important;
  justify-content: center !important;
}

.iconLabelWrapper {
  width: 12%;
  display: flex !important;
  justify-content: center !important;
}

.dotIcon {
  color: #59b655;
}

.dotIconLabel {
  color: #59b655;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.outLineCircel {
  background-color: white !important;
  color: #dededf;
  border-radius: 100%;
}

.outLineCircelLabel {
  color: #666666;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

hr {
  border: 1px dashed #b3b3b3;
  width: 79%;
}

.coplitedLine {
  border: 1px solid #0e9354 !important;
  width: 79% !important;
}
