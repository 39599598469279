.textButtonContainer {
  margin: 16px 0;
  display: flex !important;
  justify-content: end;
}

.textButton:global(.ui.button),
.textButton:global(.ui.button):focus {
  color: var(--color-green) !important;
  padding: 0;
  box-shadow: none !important;
  margin: 0 !important;
}

.textButton:global(.ui.button):hover {
  color: var(--color-background-green-medium) !important;
  box-shadow: none;
}
