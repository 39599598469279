@import '../../../../assets/css/variables.css';

:global(.ui.header).name {
  margin: 0 130px 0 0;
}

.holder {
  height: 500px;
  width: 100%;
}
