:global(.ui.button).addButton {
  background-color: var(--color-green) !important;
}

:global(.ui.basic.green.button).cancelButton {
  color: var(--color-green) !important;
}

:global(.ui.basic.green.button).actionButton {
  width: 103px !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-items: baseline;
  color: #0e9354 !important;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding: 7.58px 16px 7.58px 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
}

.archiveIcon {
  color: #0e9354 !important;
  width: 14px !important;
  height: 13px !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.modalMessageHeader {
  width: 100% !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 33px !important;
  margin-bottom: 0px !important;
}

:global(.ui.modal).messageModal {
  width: 400px !important;
  overflow: auto;
}

:global(.ui.modal).messageModal :global(.content) {
  padding: 0px !important;
  padding: 20px !important;
  width: 100% !important;
}

:global(.ui.modal).messageModal :global(.actions) {
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 100% !important;
}

:global(.ui.modal.messageModal > .actions) {
  /* background-color: var(--color-background) !important; */
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  bottom: -20px !important;
}

:global(.ui.large.modal.messageModal > .header) {
  background-color: var(--color-background) !important;
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  top: -20px !important;
}

.holder :global(.ui.button).actionButton {
  font-weight: 600;
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  color: var(--color-green) !important;
  width: 130px;
}

.container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 230px !important;
  gap: 10px;
}

.wrapper {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.iconWrapper {
  display: flex;
}

.editButtonWrapper {
  display: flex;
  width: 32px;
  margin-left: auto;
}

.editButton {
  height: 32px;
  margin-left: 0 !important;
  width: 32px;
  background-color: transparent !important;
  color: var(--color-green) !important;
  position: relative;
}

.editButton i {
  width: 16px !important;
  height: 14px !important;
  position: absolute;
  bottom: 8px;
  right: 6px;
}

:global(.icon).editButton:hover {
  color: var(--color-green) !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.deleteButton i {
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #b00020 !important;
}

:global(.icon).deleteButton:hover {
  color: #b00020 !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 100%;
  box-shadow: none !important;
}

.deleteButton {
  height: 32px;
  width: 32px;
  background-color: transparent !important;
  color: #b00020 !important;
  position: relative;
}